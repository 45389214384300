import { EmbedModes } from "./EmbedModes";

function dataIf(name: string) {
  return `{{#if ${name}}}${data(name)}{{/if}}`;
}

function data(name: string, value?: string) {
  return `data-${name}="{{${value ?? name}}}"`;
}

export const EmbedModesTemplates = {
  [EmbedModes.STANDARD]: `<div style="width: {{width}}; height:{{height}}" data-mode="${
    EmbedModes.STANDARD
  }" data-path="{{path}}" data-domain="{{domain}}" data-no-header="{{noHeader}}" ${dataIf(
    "company"
  )}></div><script src="{{scriptSrc}}"></script>`,
  [EmbedModes.FULL_PAGE]: `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <title>{{title}}</title>
    <style>*{margin:0;padding:0;} html,body,#wrapper{width:100%;height:100%;} iframe{border-radius:0 !important;}</style>
  </head>
  <body>
  <div id="wrapper" ${data("no-header", "noHeader")} data-mode="${
    EmbedModes.FULL_PAGE
  }" ${dataIf("company")} ${data("path")} ${data("domain")}></div>
  <script src="{{scriptSrc}}"></script>
  </body>
</html>`,
  [EmbedModes.POPUP]: `<button data-no-header="{{noHeader}}" ${dataIf(
    "company"
  )} data-mode="${
    EmbedModes.POPUP
  }" data-path="{{path}}" data-domain="{{domain}}" data-popup-size="{{popupSize}}" style="background-color: {{buttonColor}}; border:none; border-radius: {{roundedCorners}}px; color: {{buttonTextColor}}; cursor: pointer; font-size: {{fontSize}}px; padding: 0.5em 1em">{{buttonText}}</button><script src="{{scriptSrc}}"></script>`,
  [EmbedModes.SLIDER]: `<button data-no-header="{{noHeader}}" ${dataIf(
    "company"
  )} data-mode="${
    EmbedModes.SLIDER
  }" data-path="{{path}}"  data-domain="{{domain}}" data-slider-position="{{sliderPosition}}" style="background-color: {{buttonColor}}; border:none; border-radius: {{roundedCorners}}px; color: {{buttonTextColor}}; cursor: pointer; font-size: {{fontSize}}px; padding: 0.5em 1em">{{buttonText}}</button><script src="{{scriptSrc}}"></script>`,
  [EmbedModes.POPOVER]: `<template data-mode="${
    EmbedModes.POPOVER
  }" data-no-header="{{noHeader}}" ${dataIf(
    "company"
  )} data-button-color="{{buttonColor}}" data-path="{{path}}" data-domain="{{domain}}" data-icon-color="{{iconColor}}" data-icon-size="{{iconSize}}" data-rounded-corners="{{roundedCorners}}">{{{icon}}}</template><script src="{{scriptSrc}}"></script>`,
  [EmbedModes.SIDE_TAB]: `<template data-mode="${
    EmbedModes.SIDE_TAB
  }" data-no-header="{{noHeader}}" ${dataIf(
    "company"
  )} data-button-color="{{buttonColor}}" data-button-text="{{buttonText}}" data-button-text-color="{{buttonTextColor}}" data-button-text-size="{{buttonTextSize}}" data-path="{{path}}" data-domain="{{domain}}" data-rounded-corners="{{roundedCorners}}">{{{icon}}}</template><script src="{{scriptSrc}}"></script>`
};
export const EmbedModesTemplatesContext = {
  [EmbedModes.STANDARD]: {
    domain: "masterpeace.localhost:3040",
    company: "baluu",
    height: "100%",
    mode: EmbedModes.STANDARD,
    noHeader: true,
    path: "event/coached-painting-experience/book-now",
    width: "80%"
  },
  [EmbedModes.FULL_PAGE]: {
    domain: "masterpeace.localhost:3040",
    company: "obby",
    mode: EmbedModes.FULL_PAGE,
    noHeader: false,
    path: "event/coached-painting-experience/book-now"
  },
  [EmbedModes.POPUP]: {
    domain: "masterpeace.localhost:3040",
    company: "obby",
    buttonColor: "#03c2b9",
    buttonTextColor: "#FFFFFF",
    buttonText: "Launch me",
    fontSize: 16,
    mode: EmbedModes.POPUP,
    noHeader: true,
    path: "event/coached-painting-experience/book-now",
    popupSize: "large",
    roundedCorners: 16
  },
  [EmbedModes.SLIDER]: {
    domain: "masterpeace.localhost:3040",
    company: "obby",
    buttonColor: "#03c2b9",
    buttonText: "Launch me",
    buttonTextColor: "#FFFFFF",
    fontSize: 16,
    mode: EmbedModes.SLIDER,
    noHeader: true,
    path: "event/coached-painting-experience/book-now",
    roundedCorners: 16,
    sliderPosition: "right"
  },
  [EmbedModes.POPOVER]: {
    domain: "masterpeace.localhost:3040",
    company: "obby",
    buttonColor: "#03c2b9",
    icon:
      '<svg viewBox="0 0 24 24"><path fill-rule="evenodd" d="M3 3v8h8V3H3zm6 6H5V5h4v4zm-6 4v8h8v-8H3zm6 6H5v-4h4v4zm4-16v8h8V3h-8zm6 6h-4V5h4v4zm-6 4v8h8v-8h-8zm6 6h-4v-4h4v4z"></path></svg>',
    iconColor: "#FFFFFF",
    iconSize: 32,
    mode: EmbedModes.POPOVER,
    noHeader: true,
    path: "event/coached-painting-experience/book-now",
    roundedCorners: 50
  },
  [EmbedModes.SIDE_TAB]: {
    domain: "masterpeace.localhost:3040",
    company: "obby",
    buttonColor: "#03c2b9",
    buttonText: "Launch me",
    buttonTextColor: "#FFFFFF",
    buttonTextSize: 16,
    icon:
      '<svg viewBox="0 0 24 24"><path fill-rule="evenodd" d="M3 3v8h8V3H3zm6 6H5V5h4v4zm-6 4v8h8v-8H3zm6 6H5v-4h4v4zm4-16v8h8V3h-8zm6 6h-4V5h4v4zm-6 4v8h8v-8h-8zm6 6h-4v-4h4v4z"></path></svg>',
    mode: EmbedModes.SIDE_TAB,
    noHeader: true,
    path: "event/coached-painting-experience/book-now",
    roundedCorners: 100
  }
};
