import { Timezones } from "@obby/constants";
import { UTCDate__Add } from "./UTCDate__Add";
import { UTCDate__FormattedDate } from "./UTCDate__FormattedDate";

export function Session__DisplayTime(
  session: any,
  timezone: string = Timezones.EUROPE_LONDON,
  forceTimezone: boolean = false
) {
  const { startDateTime, duration, isOnline } = session;
  const endDateTime = UTCDate__Add(startDateTime, duration, "minutes");
  if (isOnline && !forceTimezone)
    timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? timezone;
  return (
    UTCDate__FormattedDate(startDateTime, timezone, "h:mma") +
    " - " +
    UTCDate__FormattedDate(endDateTime, timezone, "h:mma")
  );
}
