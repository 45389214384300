import { useState } from "react";

export function usePopupState(props) {
	const [open, setOpen] = useState(props.open || false);
	const [close, setClose] = useState(false);

	function onClose() {
		setClose(false);
		setOpen(false);
		if (props.onClose) props.onClose();
	}
	return [
		open,
		open => {
			if (open) setOpen(true);
			else setClose(true);
		},
		{
			onClose,
			close
		}
	];
}
