import { DiscountNatures } from "@obby/constants";
import { useEffect, useMemo, useRef, useState } from "react";
import { createContainer } from "../../configs/createContainer";
import { useDiscount } from "../../hooks/useDiscount";
import { useFormState } from "../../hooks/useFormState";
import { CourseCheckoutSchema } from "./CourseCheckout.schema";
import {
  Session__DisplayDateTimeOffset,
  Session__SessionTicketType,
  GetTicketTypePriceBasedOnSession,
  Course__Price,
  Course__IsPriceFrom,
  TicketType__IsSessionInTimeConstraint
} from "@obby/lib";
import _ from "lodash";
import { useStripePayment } from "../StripePayment";

function useCourseCheckoutPopupContainer(initialState) {
  const {
    mandatoryTickBox,
    course,
    cancellationPolicyUrl,
    courseId,
    courseThumbnail,
    courseTitle,
    courseType,
    currency,
    discount,
    isBundleEnabled,
    isNewsletterSignupEnabled,
    isOnline,
    isSendingMaterial,
    isSoldOut,
    isTrialEnabled,
    locations,
    logoUrl,
    onBeforeGetPaymentIntent,
    onConfirmFreeBooking,
    onLoadDates,
    onLogin,
    onStepChange,
    onValidateDiscount,
    onViewBundlesClick,
    onViewTrialClick,
    openWaitingListPopup,
    timezone,
    tutors,
    userInputs,
    source,
    allowPrefillOthersGuestsOnCheckout
  } = initialState;

  const stripePayment = useStripePayment();

  const page = useRef(initialState.sessions === undefined ? 0 : 1);

  const isOnSales = discount?.nature === "SALES";
  const [isUserFormValid, setIsUserFormValid] = useState(false);
  const [state, setState] = useState(() => {
    return {
      isConfirmingFreeBooking: false,
      isDiscountEnabled: false,
      isLastStep: false,
      isLoading: initialState.sessions === undefined,
      isFullyLoaded:
        initialState.sessions !== undefined &&
        initialState.sessions.length < 15,
      sessions: initialState.sessions ?? [],
      selectedSession: initialState.session,
      availability: initialState.session?.availability,
      showBundleTicketsNote:
        isBundleEnabled && initialState.session === undefined,
      showTrialTicketNote: isTrialEnabled && initialState.session === undefined,
      ticketTypes: initialState.session
        ? getSessionTicketTypes(initialState.session)
        : [],
      user: initialState.user
    };
  });

  const {
    availability,
    isConfirmingFreeBooking,
    isDiscountEnabled,
    isLastStep,
    isLoading,
    isFullyLoaded,
    selectedSession,
    sessions,
    showBundleTicketsNote,
    showTrialTicketNote,
    ticketTypes,
    user
  } = state;

  let sortedQuestions = _.sortBy(course.userQuestionForm.questions, ["order"]);
  const {
    defaults,
    errors,
    isValid,
    setFieldValue,
    setFieldValues,
    setFieldTouched,
    touched,
    values,
    submit
  } = useFormState({
    schema: CourseCheckoutSchema(isSendingMaterial),
    values: {
      inputs: userInputs.map(() => ""),
      userInputsValues: sortedQuestions.map(() => ""),
      tickets: ticketTypes.map(() => 0),
      sessionId: initialState.session?._id
    }
  });

  const totalGross = useMemo(() => {
    return values.tickets.reduce(function(total, ticket, index) {
      let selectedPrice = GetTicketTypePriceBasedOnSession(
        ticketTypes[index],
        selectedSession
      );
      return total + ticket * selectedPrice;
    }, 0);
  }, [values.tickets, ticketTypes]);

  const credit = useMemo(() => {
    if (
      totalGross < 3000 ||
      !user?.profile?.credit ||
      discount?.nature === DiscountNatures.SALES
    )
      return 0;
    return Math.min(totalGross, user.profile.credit);
  }, [user, totalGross]);

  const {
    discountCode,
    applyCode,
    getDiscount,
    getDiscountPercentage,
    isDiscountInvalid
  } = useDiscount(() => totalGross, onValidateDiscount, discount);

  useEffect(() => {
    if (initialState.sessions === undefined)
      (async () => {
        await onLoadMoreDates();
      })();
  }, []);

  function getBookingData() {
    let { personalDetails } = values;

    const { guests, deliveryAddress } = personalDetails;
    const [guest, ...additionalGuests] = guests;
    let userInputsToSend = [];
    if (course.userQuestionForm.isEnabled) {
      userInputsToSend = sortedQuestions
        .filter((question, index) => {
          return (
            values.userInputsValues[index] &&
            values.userInputsValues[index] != ""
          );
        })
        .map((question, index) => ({
          _id: question._id,
          label: question.title,
          description: question.title,
          value: values.userInputsValues[index]
        }));
    }

    console.log("userInputsToSend ", userInputsToSend);
    return {
      bookingType: "regular",
      tickets: ticketTypes
        .map((ticketType, index) => ({
          ...ticketType,
          count: values.tickets[index]
        }))
        .filter(ticket => ticket.count > 0),
      user: {
        _id: user?._id,
        profile: {
          firstName: guest.firstName,
          lastName: guest.lastName,
          phone: guest.phoneNumber
        },
        renderEmail: guest.email
      },
      marketingOptIn: guest.marketingOptIn,
      courseId: courseId,
      startDateTime: selectedSession.startDateTime,
      additionalGuests: additionalGuests,
      discountCode: credit ? "" : discountCode,
      credit,
      utm: "", //@TODO
      isRegular: true,
      sessionid: selectedSession._id,
      deliveryAddress: isSendingMaterial ? deliveryAddress : null,
      userInputs: userInputsToSend,
      timezone: getTimezone()
    };
  }

  function getFormattedDates() {
    if (!selectedSession) return [];
    const { startDateTime, duration, additionalClasses } = selectedSession;
    const dates = [{ startDateTime, duration }];
    if (additionalClasses) dates.push(...additionalClasses);

    return dates.map(date =>
      Session__DisplayDateTimeOffset({ ...date, isOnline }, timezone)
    );
  }

  function getLocation(locationId) {
    return locations.find(location => location._id === locationId);
  }

  function getSessionPrices(session) {
    return getSessionTicketTypes(session)
      .filter(sessionTicketType => sessionTicketType.isInTimeConstraint)
      .map(ticketType => {
        let ticketPrice = GetTicketTypePriceBasedOnSession(ticketType, session);
        return ticketPrice;
      })
      .sort((price1, price2) => price1 - price2);
  }

  function getSessionTicketTypes(session) {
    let sessionTicketTypes = initialState.ticketTypes.filter(ticketType => {
      const sessionTicketType = Session__SessionTicketType(
        session,
        ticketType._id
      );
      return !!sessionTicketType;
    });
    // calculate the price pro rata (this is mostly for terms)
    const { numberOfSessions = 1, number = 1 } = session ?? {};
    const fraction = (numberOfSessions - number + 1) / numberOfSessions;
    sessionTicketTypes = sessionTicketTypes.map(ticketType => ({
      ...ticketType,
      price: Math.ceil(ticketType.price * fraction),
      isInTimeConstraint: TicketType__IsSessionInTimeConstraint(
        ticketType,
        session
      )
    }));
    return sessionTicketTypes;
  }

  function getTimezone() {
    return isOnline
      ? Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? timezone
      : timezone;
  }

  function getTotal() {
    const discount = credit || getDiscount();
    return totalGross - discount;
  }

  function getTutor(tutorId) {
    return tutors.find(tutor => tutor._id === tutorId);
  }

  function isFree() {
    return values.tickets.some(ticket => ticket > 0) && getTotal() === 0;
  }

  async function onLoadMoreDates() {
    try {
      setState(state => ({
        ...state,
        isLoading: true
      }));
      const dates = await onLoadDates({
        page: ++page.current,
        limit: 15
      });

      setState(state => ({
        ...state,
        sessions: [...sessions, ...dates],
        isFullyLoaded: dates.length < 15,
        isLoading: false
      }));
    } catch (e) {
      setState(state => ({
        ...state,
        isFullyLoaded: true,
        isLoading: false
      }));
    }
  }

  function onBlur(name) {
    setFieldTouched(name);
  }

  async function onApplyDiscount(code) {
    const isValid = await submit("discountCode");
    if (isValid) await applyCode(code, values.personalDetails.guests[0].email);
  }

  function onChange(value, name) {
    setFieldValue(name, value);
  }

  function onTicketChange(index, value) {
    console.log("on ticket change");
    const ticketType = ticketTypes[index];
    const previousSeats =
      values.tickets[index] * ticketType.numberOfGuestsPerTicket;
    console.log("previous seats", previousSeats);
    const nextSeats = value * ticketType.numberOfGuestsPerTicket;
    console.log("nextSeats ", nextSeats);
    console.log("values.tickets ", values.tickets);
    const offset = values.tickets
      .slice(0, index)
      .reduce(
        (count, ticket, index) =>
          count + ticket * ticketTypes[index].numberOfGuestsPerTicket,
        0
      );

    const personalDetailsIndex = offset + Math.min(nextSeats, previousSeats);
    console.log("personalDetailsIndex ", personalDetailsIndex);
    const initialValues = new Array(Math.max(0, nextSeats - previousSeats))
      .fill(defaults("personalDetails.guests.0"))
      // we need to shallow copy each value otherwise when you change one all of them will be changed too
      .map(values => ({ ...values }));

    const guests = [...values.personalDetails.guests];
    console.log("guests ", guests);
    guests.splice(
      personalDetailsIndex,
      Math.max(0, previousSeats - nextSeats),
      ...initialValues
    );
    guests.forEach((guest, index) => (guest.guest = index > 0));
    //setFieldValue("personalDetails.guests", guests);

    const tickets = [...values.tickets];
    tickets[index] = value;

    // calculate the number of guests based on ticket
    let guestsValues = [];
    let guestDefault = {
      firstName: "",
      lastName: "",
      guest: false,
      email: "",
      marketingOptIn: false,
      mandatoryTickBox: false
    };
    tickets.map((ticketsSelected, index) => {
      let ticketType = ticketTypes[index];
      let numberOfSeats = ticketsSelected * ticketType.numberOfGuestsPerTicket;
      for (var i = 1; i <= numberOfSeats; i++) {
        guestsValues.push(guestDefault);
      }
    });
    setFieldValue("personalDetails.guests", guestsValues);
    setState({
      ...state,
      availability: availability - (nextSeats - previousSeats)
    });
    console.log("tickets ", tickets);
    console.log("state ", state);
    setFieldValue(`tickets`, tickets);
  }

  function onSessionSelect([sessionId]) {
    const session = sessions.find(session => session._id === sessionId);
    let ticketTypes = session ? getSessionTicketTypes(session) : [];

    setFieldValues({
      tickets: ticketTypes.map(() => 0),
      sessionId: sessionId
    });

    setState(state => ({
      ...state,
      ticketTypes,
      availability: session?.availability,
      selectedSession: session
    }));
  }

  async function onCardPaymentEnter() {
    await stripePayment.getPaymentIntent(getBookingData());
  }

  async function onCheckoutBeforeStepChange(index) {
    if (index === 3 && onBeforeGetPaymentIntent)
      await onBeforeGetPaymentIntent(getBookingData());
  }

  async function onCheckoutStepChange(index) {
    let userDetailsIndex = 3;
    let lastStepIndex = 4;
    setState(state => ({
      ...state,
      isDiscountEnabled: !discount && index === userDetailsIndex,
      isLastStep: index === lastStepIndex,
      showBundleTicketsNote: isBundleEnabled && index === 0,
      showTrialTicketNote: isTrialEnabled && index === 0
    }));
    onStepChange && onStepChange();
  }

  function onContinue() {
    if (isFree()) {
      setState(state => ({ ...state, isConfirmingFreeBooking: true }));
      onConfirmFreeBooking(getBookingData());
    } else stripePayment.submitPayment();
  }

  async function onPersonalDetailsEnter() {
    // proceed with the login only if the buyer details is still not filled
    if (isValid("personalDetails.guests.0")) return;

    let user = initialState.user ? initialState.user : undefined;

    if (!user && onLogin)
      try {
        user = await onLogin();
        setState(state => ({
          ...state,
          user
        }));
      } catch (e) {}

    if (user) {
      const { firstName = "", lastName = "", phone = "" } = user.profile;
      const [{ address = "" } = {}] = user.emails || [];
      setFieldValue("personalDetails.guests.0", {
        ...values.personalDetails[0],
        firstName,
        lastName,
        phoneNumber: phone,
        email: address
      });
    }
  }

  function onWaitingListClick(session) {
    openWaitingListPopup &&
      openWaitingListPopup(
        session ? sessions.find(({ _id }) => session._id === _id) : null
      );
  }

  return {
    allowPrefillOthersGuestsOnCheckout,
    course,
    isFromPrice: Course__IsPriceFrom(course),
    manualOverridePrice: Course__Price(course),
    mandatoryTickBox,
    source,
    hideDisplaysAvailableTickets:
      course.displaySettings?.hideDisplaysAvailableTickets ?? false,
    availability,
    cancellationPolicyUrl,
    courseId,
    courseThumbnail,
    courseTitle,
    courseType,
    credit,
    currency,
    discount,
    errors,
    isBundleEnabled,
    isConfirmingFreeBooking,
    isDiscountEnabled,
    isLastStep,
    isLoading,
    isFullyLoaded,
    isNewsletterSignupEnabled,
    isOnline,
    isSendingMaterial,
    isTrialEnabled,
    logoUrl,
    selectedSession,
    sessions,
    showBundleTicketsNote,
    showTrialTicketNote,
    ticketTypes,
    totalGross,
    touched,
    userInputs,
    values,
    isOnSales,
    isStripePaymentReady: stripePayment.isReady,
    isSoldOut,
    timezone,
    isUserFormValid,
    helpers: {
      getDiscount,
      getDiscountPercentage,
      getFormattedDates,
      getLocation,
      getSessionPrices,
      getTotal,
      getTutor,
      isDiscountInvalid,
      isFree,
      isValid
    },
    actions: {
      onApplyDiscount,
      onBlur,
      onCardPaymentEnter,
      onChange,
      onContinue,
      onLoadMoreDates,
      onTicketChange,
      onPersonalDetailsEnter,
      onSessionSelect,
      onViewBundlesClick,
      onViewTrialClick,
      onCheckoutBeforeStepChange,
      onCheckoutStepChange,
      openWaitingListPopup: openWaitingListPopup
        ? onWaitingListClick
        : undefined,
      setIsUserFormValid
    }
  };
}

const CourseCheckoutContainer = createContainer(
  useCourseCheckoutPopupContainer
);
export { CourseCheckoutContainer };
