import { css } from "../../sitches.config";

export const styles = {
  card: css({
    position: "relative",
    backgroundColor: "$white",
    borderRadius: "$2",
    overflow: "hidden"
    // padding: "$4"
  }),

  favoriteButton: css({
    position: "absolute",
    top: "15px",
    right: "15px",
    "@smd": {
      right: "auto",
      left: " calc(33.3% - 52px)"
    }
  }),

  header: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: "8px",
    "@sm": {
      marginBottom: "$3"
    }
  }),

  title: css({
    flex: 1,
    fontFamily: "$foundersGrotesk",
    fontWeight: 500,
    fontSize: "$18",
    lineHeight: "120%",
    letterSpacing: "0.01em",
    color: "$ebonyClay",
    maxWidth: "70%",
    "@sm": {
      fontSize: "$20"
    }
  }),

  section: css({
    marginBottom: "$2",
    "@sm": {
      marginBottom: "$3"
    },

    "&:empty, &:last-child": {
      marginBottom: 0
    }
  }),

  type: css({
    backgroundColor: "rgba(218, 218, 218, 0.44)",
    borderRadius: "20px",
    fontFamily: "$foundersGrotesk",
    fontSize: "12px",
    lineHeight: "22px",
    textAlign: "center",
    letterSpacing: "0.05em",
    textTransform: "uppercase",
    color: "$venus",
    display: "none",
    padding: "0 14px",
    margin: "0 10px 0 0",
    "@sm": {
      display: "inline-block"
    }
  }),

  priceWrapper: css({
    fontFamily: "$foundersGrotesk",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "150%",
    letterSpacing: "0.5px",
    color: "$aquamarineBlue",
    textAlign: "right",
    "@sm": {
      fontSize: "21px"
    },
    variants: {
      withDiscount: {
        true: {
          display: "inline-block",
          textDecoration: "lineThrough"
        }
      }
    }
  }),

  price: css({
    variants: {
      withDiscount: {
        true: {
          display: "inline-block",
          textDecoration: "lineThrough"
        }
      }
    }
  }),

  students: css({
    fontFamily: "$foundersGrotesk",
    fontWeight: 500,
    fontSize: "$14",
    lineHeight: "110%",
    letterSpacing: "0.03em",
    color: "$scorpion",

    "@sm": {
      fontSize: "$16"
    }
  }),

  duration: css({
    fontFamily: "$foundersGrotesk",
    fontWeight: 500,
    fontSize: "$14",
    lineHeight: "110%",
    letterSpacing: "0.03em",
    color: "$scorpion",
    textTransform: "lowercase",
    "@md": {
      fontSize: "$16"
    }
  }),

  thumbnail: css({
    display: "block",
    width: "100%",
    height: "100%",
    objectFit: "cover"
  }),

  content: css({
    flex: "1",
    display: "flex",
    flexDirection: "column",
    padding: "$4",
    "@smd": {
      paddingLeft: 0
    },
    "@md": {
      minHeight: "187px"
    },

    "&:link, &:visited, &:hover, &:active": {
      color: "inherit",
      textDecoration: "none"
    }
  }),

  description: css({
    display: "none",
    fontFamily: "$foundersGrotesk",
    fontSize: "$18",
    lineHeight: "144%",
    letterSpacing: "0.01em",
    color: "$ebonyClay",
    "@smd": {
      display: "block"
    }
  }),

  footer: css({
    flexGrow: 1,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between"
  }),

  extraInfoItem: css({
    fontFamily: "$foundersGrotesk",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "110%",
    letterSpacing: "0.03em",
    color: "#595959",
    display: "inline-block",
    "@md": {
      fontSize: "$16"
    }
  }),

  extraInfoSeparator: css({
    display: "inline-block",
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    backgroundColor: "$silverMalibu",
    margin: "0 1ex"
  }),

  locationIcon: css({
    verticalAlign: "bottom",
    marginRight: "4px"
  }),

  reviews: css({
    opacity: "0.5",
    marginLeft: "0.8ex"
  }),

  nextSessionDate: css({
    fontWeight: "500",
    lineHeight: "100%",
    color: "$ebonyClay",
    letterSpacing: "0.01em",
    fontSize: "$16",
    fontFamily: "$foundersGrotesk"
  }),

  nextSessionDateLabel: css({
    color: "$gray",
    marginRight: "$1"
  })
};
