import { css } from "../../sitches.config";

export const card = css({
  display: "flex",
  position: "relative",
  backgroundColor: "$white",
  borderRadius: "$2",
  height: "100%",
  overflow: "hidden",
  variants: {
    orientation: {
      vertical: {
        flexDirection: "column"
      },
      horizontal: {
        flexDirection: "row"
      }
    }
  }
});

export const imageWrapper = css({
  flex: "0 0 96px"
});

export const image = css({
  objectFit: "cover",
  width: "100%",
  height: "100%"
});
export const contentWrapper = css({
  flex: 1,
  fontFamily: "$foundersGrotesk"
});
export const content = css({
  color: "$ebonyClay",
  variants: {
    orientation: {
      vertical: {
        padding: "$3 $3 $4"
      },
      horizontal: {
        padding: "$3 $3 0 $2",
        "@sm": {
          padding: "$3 $3 0 $3"
        }
      }
    }
  }
});

export const title = css({
  display: "block",
  fontWeight: "500",
  fontSize: "$16",
  paddingBottom: "$2",
  "&, &:link, &:visited, &:hover, &:active": {
    textDecoration: "none",
    color: "inherit"
  },
  variants: {
    withMarginRight: {
      true: {}
    },
    orientation: {
      horizontal: {},
      vertical: {}
    },
    truncated: {
      true: {},
      false: {}
    }
  },
  compoundVariants: [
    {
      orientation: "horizontal",
      withMarginRight: true,
      css: { paddingRight: "32px" }
    },
    { orientation: "horizontal", truncated: true, css: { display: "none" } },
    { orientation: "vertical", truncated: false, css: { display: "none" } }
  ]
});

export const favouriteButtonWrapper = css({
  position: "absolute",
  top: "$3",
  right: "$3"
});

export const favouriteButton = css({
  // position: "absolute",
  // top: "$3",
  // right: "$3",
  variants: {
    isFavourite: {
      false: {}
    },
    orientation: { vertical: {}, horizontal: {} }
  },
  compoundVariants: [
    {
      orientation: "vertical",
      isFavourite: false,
      css: {
        path: {
          stroke: "white"
        }
      }
    }
  ]
});

export const detail = css({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  color: "$gray",
  variants: {
    orientation: {
      vertical: {
        marginTop: "$2",
        fontSize: "$16"
      },
      horizontal: {
        fontSize: "$14",
        marginTop: "$1",
        "@sm": {
          fontSize: "$16"
        }
      }
    }
  }
});

export const rating = css({
  color: "$ebonyClay"
});

export const price = css({
  variants: {
    lineThrough: {
      true: { textDecoration: "line-through" }
    }
  }
});

export const view = css({
  fontFamily: "$foundersGrotesk",
  backgroundColor: "$robbinsEggBlue",
  marginTop: "$1",
  textAlign: "center",
  color: "$white",
  paddingY: "$1",
  fontSize: "$14",
  textTransform: "uppercase",
  "&, &:link, &:visited, &:hover, &:active": {
    textDecoration: "none",
    color: "$white"
  },
  variants: {
    orientation: {
      vertical: {
        display: "none"
      },
      horizontal: {
        display: "block"
      }
    }
  }
});
