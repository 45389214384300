import React, { useState, useEffect } from "react";
import dynamic from "next/dynamic";
import Image from "next/image";

const InstaStories = dynamic(() => import("react-insta-stories"), {
  ssr: false
});

import { CloudinaryUrl__TransformedUrl } from "@obby/lib";
import { styles } from "./Stories.styles";

export function Stories({
  stories,
  width = 360,
  height = 640,
  interval,
  loop
}) {
  // This InstaStories component seems to not work well with ssr and i don't know exactly why.
  // For now lets just initialize InstaStories on client side after component did mount
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    const onPageLoad = () => {
      setIsMounted(true);
    };

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);
  const mountedStyleInsta = {
    opacity: 1,
    transition: "opacity 500ms ease-in",
    zIndex: 10
  };
  const unmountedStyleInsta = {
    opacity: 1,
    transition: "opacity 500ms ease-in",
    zIndex: 10
  };

  return (
    <div className={`stories-container ${styles.stories()}`}>
      {/**We keep this here just to preload the first image */}
      <div style={{ display: "none" }}>
        <Image
          alt="Mountains"
          src={CloudinaryUrl__TransformedUrl(stories[0], {
            width: width,
            height: height,
            crop: "fill"
          })}
          priority={true} // this will preload the image
          width={width}
          height={height}
          unoptimized={true}
        />
      </div>
      {isMounted && (
        <InstaStories
          stories={stories.map(story => ({
            url: CloudinaryUrl__TransformedUrl(story, {
              width: width,
              height: height,
              crop: "fill"
            }),
            type: "image"
          }))}
          defaultInterval={interval}
          width="100%"
          height="100%"
          storyStyles={{ display: "block" }}
          loop={loop}
          style={isMounted ? mountedStyleInsta : unmountedStyleInsta}
        />
      )}
    </div>
  );
}
