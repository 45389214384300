import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import RatingStars from "../RatingStars";
import Icon from "../Icon";
import * as Icons from "../Icons";

import { styles } from "./ReviewsSlider.layout";

export function ReviewsSlider({ mobile, reviews = [] }) {
  return (
    <div className={`reviews-slider ${styles.reviewsSlider}`}>
      <Carousel
        className="reviews-slider__carousel"
        infiniteLoop
        dynamicHeight
        showIndicators={false}
        showStatus={false}
        showArrows={false}
        showThumbs={false}
        renderArrowPrev={onClick => (
          <button
            className={`control-arrow control-previous`}
            onClick={onClick}
          >
            <Icon icon={Icons.ChevronPrev} height={31} />
          </button>
        )}
        renderArrowNext={onClick => (
          <button className={`control-arrow control-next`} onClick={onClick}>
            <Icon icon={Icons.ChevronNext} height={31} />
          </button>
        )}
      >
        {reviews.map((review, index) => (
          <Review
            mobile={mobile}
            key={index}
            review={review.review}
            reviewer={review.reviewer}
            rating={review.rating}
            target={review.target}
            targetUrl={review.targetUrl}
            firstName={review.firstName}
            lastName={review.lastName}
            showOnlyFirstName={review.showOnlyFirstName}
          />
        ))}
      </Carousel>
    </div>
  );
}

function Review({
  review,
  reviewer,
  rating,
  target,
  targetUrl,
  mobile,
  firstName,
  lastName,
  showOnlyFirstName
}) {
  return (
    <div
      className={`reviews-slider__review ${styles.review({
        mobile
      })}`}
    >
      {mobile && (
        <div className={`reviews-slider__review-rating ${styles.reviewRating}`}>
          <RatingStars rating={rating} color="robbinsEggBlue" />
        </div>
      )}
      <q
        className={`reviews-slider__review-content ${styles.reviewContent({
          mobile
        })}`}
      >
        {review}
      </q>
      <div className={`reviews-slider__review-footer ${styles.reviewFooter}`}>
        <span
          className={`reviews-slider__review-reviewer ${styles.reviewReviewer({
            mobile
          })}`}
        >
          {showOnlyFirstName
            ? `${firstName}`
            : firstName
            ? `${firstName} ${lastName}`
            : reviewer || "Anonymous"}{" "}
          {target && !mobile && (
            <a
              className={`reviews-slider__review-target ${styles.reviewTarget}`}
              href={targetUrl}
            >
              - {target}
            </a>
          )}
          {mobile && (
            <a
              className={`reviews-slider__review-target ${styles.reviewTarget({
                mobile
              })}`}
              href={targetUrl}
            >
              {target}
            </a>
          )}
        </span>
        {!mobile && rating !== undefined && (
          <div
            className={`reviews-slider__review-rating ${styles.reviewRating}`}
          >
            <RatingStars rating={rating} color="robbinsEggBlue" />
          </div>
        )}
      </div>
    </div>
  );
}

ReviewsSlider.propTypes = {};
