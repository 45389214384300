import React from "react";
import { PrimaryButton } from "../PrimaryButton";
import { DiscountBadge } from "../../badges/DiscountBadge";
import { styles } from "./WideBannerWithImage.styles";
import Image from "../Image";

const CategoryToButtonColorMap = {
  art: "humming-bird",
  languages: "ebonyClay",
  "drinks-and-tastings": "sun",
  crafts: "astra",
  food: "emerald",
  "performing-arts": "camelot",
  dance: "camelot",
  music: "camelot",
  "health-and-beauty": "robbinsEggBlue",
  "mindfulness-and-wellbeing": "sun",
  photography: "humming-bird",
  technology: "astra",
  ebonyClay: "robbinsEggBlue"
};

const CategoryToStickerImageUrl = {
  "pottery-and-mosaics":
    "https://res.cloudinary.com/obby/image/upload/h_120,c_fill,q_auto/v1582023359/Category%20Stickers/pottery-and-mosaics.svg",
  art:
    "https://res.cloudinary.com/obby/image/upload/h_120,c_fill,q_auto/v1582023329/Category%20Stickers/art.svg",
  languages:
    "https://res.cloudinary.com/obby/image/upload/h_120,c_fill,q_auto/v1582023351/Category%20Stickers/languages.svg",
  "drinks-and-tastings":
    "https://res.cloudinary.com/obby/image/upload/h_120,c_fill,q_auto/v1582023343/Category%20Stickers/drinks-and-tastings.svg",
  crafts:
    "https://res.cloudinary.com/obby/image/upload/h_120,c_fill,q_auto/v1582023339/Category%20Stickers/crafts.svg",
  food:
    "https://res.cloudinary.com/obby/image/upload/h_120,c_fill,q_auto/v1582023346/Category%20Stickers/food.svg",
  "performing-arts":
    "https://res.cloudinary.com/obby/image/upload/h_120,c_fill,q_auto/v1582023358/Category%20Stickers/performing-arts.svg",
  dance:
    "https://res.cloudinary.com/obby/image/upload/h_120,c_fill,q_auto/v1582023340/Category%20Stickers/dance.svg",
  music:
    "https://res.cloudinary.com/obby/image/upload/h_120,c_fill,q_auto/v1582023359/Category%20Stickers/piano.svg",
  "health-and-beauty":
    "https://res.cloudinary.com/obby/image/upload/h_120,c_fill,q_auto/v1582023349/Category%20Stickers/health-and-beauty.svg",
  "mindfulness-and-wellbeing":
    "https://res.cloudinary.com/obby/image/upload/h_120,c_fill,q_auto/v1582023353/Category%20Stickers/mindfulness-and-wellbeing.svg",
  photography:
    "https://res.cloudinary.com/obby/image/upload/h_120,c_fill,q_auto/v1582023359/Category%20Stickers/photography.svg",
  technology:
    "https://res.cloudinary.com/obby/image/upload/h_120,c_fill,q_auto/v1582023365/Category%20Stickers/technology.svg",
  ebonyClay: "robbinsEggBlue"
};

const Title__TextArray = title => {
  if (title.constructor === Array) {
    return title;
  }
  return [title];
};

export default function WideBannerWithImage(props) {
  return (
    <div
      className={styles.wideBannerWithImage({
        category: props.backgroundColor ?? props.category
      })}
    >
      {/* Black Friday Bagde */}
      {props.discount && (
        <div className={styles.discountBadge()}>
          <DiscountBadge
            value={props.discount.value}
            type={props.discount.type}
            rotate={-26}
          />
        </div>
      )}
      <div className="row">
        <div className="col-12 col-md-6 py-4">
          <div className={styles.label({ category: props.category })}>
            {props.label}
          </div>
          <h3
            className={styles.title({
              category: props.backgroundColor ?? props.category,
              hasDiscount: props.discount !== undefined
            })}
          >
            {props.title}
          </h3>
          <div
            className={styles.description({
              category: props.backgroundColor ?? props.category
            })}
          >
            {props.description}
          </div>
          <div className={styles.button()}>
            <PrimaryButton
              text={props.buttonText}
              color={
                CategoryToButtonColorMap[
                  props.category || props.backgroundColor
                ]
              }
              onClick={props.onClick}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          {" "}
          <div className={styles.imageWrap()}>
            {props.category && (
              <img
                src={CategoryToStickerImageUrl[props.category]}
                className={styles.categorySticker()}
              />
            )}
            {props.isGiftCard && (
              <img
                src="https://res.cloudinary.com/obby/image/upload/w_200,q_auto/v1582031471/Misc%20Stickers/gift-card-sticker.svg"
                className={styles.giftCardImage()}
              />
            )}
            <img src={props.image} className={styles.image()} />
          </div>
        </div>
      </div>
    </div>
  );
}
