import React from "react";
// @ts-ignore
import { CloudinaryUrl__TransformedUrl } from "@obby/lib";
import CardBackground from "../../components/CardBackground";
import TextPill from "../../components/TextPill";
import { CheckoutCardItem } from "./CheckoutCardItem";
import { CheckoutCardTotal } from "./CheckoutCardTotal";
import { CheckoutCredit } from "./CheckoutCredit";
import { CheckoutDiscount } from "./CheckoutDiscount";
import { CheckoutSalesNotice } from "./CheckoutSalesNotice";

import { styles } from "./CheckoutCard.styles";

export function CheckoutCard({
  availability,
  cancellationPolicyUrl,
  children,
  heading,
  isOnline,
  subheading,
  thumbnail,
  source
}: React.PropsWithChildren<Props>) {
  console.log("source ", source);
  return (
    <CardBackground
      className="checkout-card"
      thumbnail={
        thumbnail
          ? CloudinaryUrl__TransformedUrl(thumbnail, {
              width: 800,
              height: 450,
              crop: "fill"
            })
          : undefined
      }
      thumbnailPosition="top"
    >
      <header className={styles.header()}>
        {source == "obby" && (
          <div className="d-flex">
            {isOnline === true && <TextPill text="Live online class" />}
            {isOnline === false && (
              <TextPill text="In-person class" backgroundColor="royalBlue" />
            )}
          </div>
        )}

        <div className={styles.heading()}>{heading}</div>
        {subheading && <div className={styles.subheading()}>{subheading}</div>}
        {availability !== undefined &&
          availability <= 5 &&
          availability >= 0 && (
            <TextPill
              text={`${availability} ${
                availability === 1 ? "spot" : "spots"
              } left`}
              backgroundColor="lighterTeal"
              color="blueLagoon"
              size="small"
            />
          )}
      </header>
      <div>{children}</div>
      {cancellationPolicyUrl && (
        <footer className={styles.footer()}>
          <a className={styles.footerLink()} href={cancellationPolicyUrl}>
            Cancellation Policy
          </a>
        </footer>
      )}
    </CardBackground>
  );
}

interface Props {
  availability?: number;
  cancellationPolicyUrl?: string;
  heading: string;
  isOnline?: boolean;
  subheading?: string;
  thumbnail?: string;
  source?: string;
}

CheckoutCard.Discount = CheckoutDiscount;
CheckoutCard.Item = CheckoutCardItem;
CheckoutCard.Total = CheckoutCardTotal;
CheckoutCard.Credit = CheckoutCredit;
CheckoutCard.SalesNotice = CheckoutSalesNotice;
