import { css } from "../../sitches.config";

export const styles = {
  giftCardCard: css({
    fontFamily: "$foundersGrotesk"
  }),
  header: css({
    marginBottom: "-5px"
  }),
  body: css({
    position: "relative",
    padding: "24px",
    "@sm": {
      padding: "34px"
    },
    backgroundColor: "white",
    border: "1px solid @dark-gallery",
    borderRadius: "0 0 10px 10px"
  }),
  row: css({
    display: "flex",
    marginBottom: "20px",
    "&:last-child": {
      marginBottom: "0"
    },
    "@sm": {
      marginBottom: "30px"
    }
  }),
  field: css({
    flex: "1 1 auto"
  }),
  fieldLabel: css({
    fontSize: "16px",
    fontWeight: "bold",
    letterSpacing: "0.75px",
    textTransform: "uppercase",
    color: "$gray",
    marginBottom: "4px",
    "@sm": {
      fontSize: "16px",
      marginBottom: "2px"
    }
  }),
  fieldValue: css({
    fontSize: "18px",
    lineHeight: "16px",
    color: "$ebonyClay",
    "@sm": {
      fontSize: "18px",
      lineHeight: "24px"
    }
  })
};
