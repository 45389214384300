import * as Yup from "yup";
export function FlexitimeFormSchema() {
  const defaults = {
    date: null,
    time: null,
    guests: null
  };

  return Yup.object({
    date: Yup.date()
      .label("Date")
      .required("Please select a date to attend the class.")
      .nullable(true)
      .default(defaults.date),
    time: Yup.number()
      .label("Time")
      .min(0)
      .required("Please select the time you wish to attend the class.")
      .nullable(true)
      .default(defaults.time)
  });
}
