import { css } from "../../sitches.config";

export const styles = {
  bookingReschedulePage: css({
    // padding: "150px 20px 120px",
    // display: "flex",
    // justifyContent: "center",
    // textAlign: "center"
  }),
  title: css({
    fontFamily: "$foundersGroteskCondensed",
    fontWeight: "normal", // Font is already bold.
    textTransform: "uppercase",
    fontSize: "2.5rem",
    textAlign: "center"
  }),
  paragraph: css({
    fontFamily: "$foundersGrotesk",
    color: "$ebonyClay",
    fontSize: "$mParagraph",
    textAlign: "center"
  }),

  box: css({
    display: "flex",
    flexWrap: "wrap",
    padding: "40px 20px",
    backgroundColor: "$azalea",
    justifyContent: "center",
    borderRadius: "10px"
  }),
  boxText: css({
    width: "100%",
    fontFamily: "$foundersGrotesk",
    fontSize: "$mParagraph",
    lineHeight: "$mParagraphLh",
    color: "$textBlack",
    textAlign: "center",

    "&:not(:last-child)": {
      marginBottom: "24px"
    }
  })
};
