import React, { useContext } from "react";
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark";
import { FaIcon } from "../../icons";

import { ArrayInputContext } from "./ArrayInput.context";
import { styles } from "./ArrayInput.styles";

export function ArrayInputRemoveItemButton({ onClick }: Props) {
  const { index } = useContext(ArrayInputContext);
  return (
    <span className={styles.removeItemButton()}>
      <FaIcon icon={faXmark} onClick={() => onClick && onClick(index)} />
    </span>
  );
}

interface Props {
  onClick?: (index: number) => void;
}
