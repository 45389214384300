import { css, withBem } from "../../sitches.config";

export const styles = withBem("about-class-section", {
  aboutClassSession: css({}),
  description: css({
    lineHeight: "134%",
    letterSpacing: "0.01em",
    fontSize: "$mParagraph",
    fontFamily: "$foundersGrotesk"
  }),

  descriptionHeader: css({
    fontFamily: "$foundersGroteskCondensed",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "21px",
    lineHeight: "100%",
    letterSpacing: "0.03em",
    textTransform: "uppercase",
    color: "$ebonyClay"
  }),

  list: css({
    marginBottom: "$40",
    "&:last-child": {
      marginBottom: "0"
    },

    "@lg": {
      marginBottom: "0"
    }
  })
});
