import { css } from "../../sitches.config";

export const styles = {
  infoBanner: css({
    backgroundColor: "$white",
    padding: "$24",
    borderRadius: "$2",
    display: "flex",
    flexDirection: "column",

    "@sm": {
      padding: "$32"
    },

    "@md": {
      flexDirection: "initial"
    },
    "&__section + &__section": {
      margin: "32px 0 0 0",

      "@sm": {
        margin: "0 0 0 50px"
      }
    }
  }),
  title: css({
    fontFamily: "$foundersGroteskCondensed",
    fontSize: "28px",
    maxWidth: "100%",
    color: "$ebonyClay",
    textTransform: "uppercase",
    lineHeight: "100%",
    minWidth: "170px",
    margin: "0 0 48px 0",

    "@sm": {
      margin: "0 0 30px 0"
    },
    "@md": {
      margin: "0 32px 0 0",
      maxWidth: "394px",
      fontSize: "32px"
    },

    variants: {
      titleSize: {
        true: {
          fontSize: "$28"
        }
      }
    }
  }),
  icon: css({
    marginBottom: "8px"
  }),
  subTitle: css({
    display: "block",
    fontFamily: "$foundersGroteskCondensed",
    textTransform: "uppercase",
    fontSize: "$18",
    variants: {
      titleSize: {
        true: {
          fontSize: "$16"
        }
      }
    }
  }),
  description: css({
    lineHeight: "21px",
    marginTop: "7px"
  }),
  sections: css({
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",

    "@sm": {
      flexDirection: "initial"
    },

    variants: {
      shouldWrapSections: {
        true: {
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          columnGap: "$40",
          rowGap: "$40",

          "&__section": {
            margin: "0"
          }
        }
      }
    }
  }),
  section: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$16",
    lineHeight: "21px",
    flex: "1",
    "@sm": {
      marginX: "15px",
      "&:first-child": {
        marginLeft: 0
      },
      "&:last-child": {
        marginRight: 0
      }
    }
  })
};
