import React from "react";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import styles from "./WelcomeDashboardCard.module.less";

export default function WelcomeDashboardCard(props) {
  return (
    <div className={styles["welcome-dashboard-card"]}>
      <div className={styles["welcome-dashboard-card__banner"]}>
        <img
          className={styles["welcome-dashboard-card__banner-image"]}
          src={props.banner}
          alt=""
        />
      </div>
      <div className={styles["welcome-dashboard-card__content"]}>
        <h1 className={styles["welcome-dashboard-card__title"]}>
          {props.title}
        </h1>
        <div className={styles["welcome-dashboard-card__body"]}>
          {props.body.map((paragraph, index) => (
            <p
              key={index}
              className={styles["welcome-dashboard-card__body-paragraph"]}
            >
              {paragraph}
            </p>
          ))}
        </div>
        <PrimaryButton
          className={styles["welcome-dashboard-card__button"]}
          text={props.buttonText}
          onClick={props.onButtonClick}
          width="auto"
          color="robbinsEggBlue"
          size="medium"
        />
      </div>
    </div>
  );
}
