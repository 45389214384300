import React from "react";
import { Label } from "../../components/Label";
import { TextArea } from "../../inputs/TextArea";

export function EditBookingNotesForm(props) {
  const { values, errors, touched, onChange, onBlur, tutors = [] } = props;

  return (
    <div className="assign-form">
      <Label label="Notes" error={touched?.notes && errors.notes}>
        <TextArea
          name="notes"
          value={values.notes}
          onChange={onChange}
          onBlur={onBlur}
        />
      </Label>
    </div>
  );
}
