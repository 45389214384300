export function scrollToBookmark(bookmark, behavior = "smooth") {
  if (document && history) {
    if (history.replaceState) history.replaceState({}, "", `#${bookmark}`);

    setTimeout(() => {
      const $anchor = document.getElementById(bookmark);
      const top = $anchor
        ? $anchor.getBoundingClientRect().top + window.pageYOffset
        : 0;

      if (window.scroll) {
        window.scroll({
          top,
          behavior
        });
      } else {
        window.scrollTo(0, top);
      }
    }, 0);
  }
}
