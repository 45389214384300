import React from "react";
import PropTypes from "prop-types";

import Icons from "../Icons";
import Icon from "../Icon";
import useCarousel from "../../hooks/useCarousel";
import { Skeleton } from "../Skeleton";
import { styles } from "./RailCarousel.styles";

const RailCarousel = ({
  description,
  title,
  numberOfColumns = 4,
  Child,
  items = [],
  hideArrows = items.length <= numberOfColumns,
  onClick,
  skeleton
}) => {
  const [next, previous, visibleItems, atMax, atMin] = useCarousel(
    numberOfColumns,
    items
  );

  return (
    <div className={`rail-carousel ${styles.railCarousel()}`}>
      <div className={`rail-carousel__header ${styles.header()}`}>
        <h2 className={`rail-carousel__title ${styles.title()}`}>
          {skeleton ? (
            <div className={styles.titleSkeleton()}>
              <Skeleton dark />
            </div>
          ) : (
            title
          )}
        </h2>
        {!skeleton && !hideArrows && (
          <nav className={`rail-carousel__nav ${styles.nav()}`}>
            <a
              onClick={!atMin() ? previous : () => {}}
              className={`rail-carousel__nav-item ${styles.navItem()} ${
                atMin() ? "rail-carousel__nav-item--disabled" : ""
              }`}
            >
              <Icon
                className={`rail-carousel__nav-icon ${styles.navIcon()}`}
                icon={Icons.ChevronPrev}
                height={11}
              />
            </a>
            <a
              onClick={!atMax() ? next : () => {}}
              className={`rail-carousel__nav-item ${styles.navItem()} ${
                atMax() ? "rail-carousel__nav-item--disabled" : ""
              }`}
            >
              <Icon
                className={`rail-carousel__nav-icon ${styles.navIcon()}`}
                icon={Icons.ChevronNext}
                height={11}
              />
            </a>
          </nav>
        )}
      </div>
      {description && (
        <p className={`rail-carousel__description ${styles.description()}`}>
          {description}
        </p>
      )}
      <div
        className={`rail-carousel__body ${styles.body({
          css: {
            gridTemplateColumns: `repeat(2, 1fr)`,
            "@sm": {
              gridTemplateColumns: `repeat(3, 1fr)`
            },
            "@md": {
              gridTemplateColumns: `repeat(4, 1fr)`
            }
          }
        })}`}
      >
        {visibleItems.map((childProps, index) => (
          <Child
            key={index}
            onClick={() => onClick(childProps)}
            skeleton={skeleton}
            {...childProps}
          />
        ))}
      </div>
    </div>
  );
};

RailCarousel.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  numberOfColumns: PropTypes.number.isRequired,
  Child: PropTypes.elementType.isRequired,
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func
};

export default RailCarousel;
