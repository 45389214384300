import moment from "moment";

export function Time__FormattedTime(
  time: string,
  format: string = "H:mma",
  { inputFormat = "HH:mm" }: Args = {}
): string {
  const date = moment.utc(time, inputFormat);
  return date.format(format);
}

interface Args {
  inputFormat?: string;
}
