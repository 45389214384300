import { useEffect, useState } from "react";

export function useCopyText(timer: number = 2000) {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    if (isCopied) {
      timeout = setTimeout(() => {
        setIsCopied(false);
      }, timer);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isCopied, timer]);

  async function copyToClipboard(text: string) {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
    } catch (e) {
      throw e;
    }
  }

  return {
    isCopied,
    copyToClipboard
  };
}
