// Lib & Constants
import { DiscountTypes } from "@obby/constants";

export function Discount__DiscountedPrice(discount, price) {
  if (discount.type === DiscountTypes.PERCENTAGE) {
    // we need to round in order to overcome float precision issue
    return Math.round(price * (1 - Math.min(discount.value, 100) / 100));
  }
  if (discount.type === DiscountTypes.PRICE) {
    return Math.max(price - discount.value, 0);
  }

  return price;
}
