import React from "react";

import { styles } from "./CheckoutStepsBreadcrumb.styles";

export function CheckoutStepsBreadcrumb({
  active,
  current,
  onClick,
  text
}: Props) {
  return (
    <span
      className={styles.root({
        current,
        active
      })}
      onClick={active ? () => onClick : undefined}
    >
      {text}
    </span>
  );
}

interface Props {
  active?: boolean;
  current?: boolean;
  onClick?: () => void;
  text: string;
}
