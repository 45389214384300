export const defaultCancellationPolicy: {
  canCancel: boolean;
  canReschedule: boolean;
  daysBefore: number;
  hoursBefore: number;
  customText: string | null;
  updated: Date | null;
  mandatoryTickBox: boolean;
} = {
  canCancel: true,
  canReschedule: true,
  daysBefore: 7,
  hoursBefore: 0,
  customText: null,
  updated: null,
  mandatoryTickBox: false
};
