import React, { useState } from "react";
import { AccordionListItem } from "./AccordionListItem";
import { styles } from "./AccordionList.styles";
import { AccordionListContext } from "./AccordionList.context";

export function AccordionList({
  children,
  size = "large"
}: React.PropsWithChildren<Props>) {
  return (
    <div className={styles.accordionList()}>
      <div className={styles.wrapper()}>
        <AccordionListContext.Provider value={{ size }}>
          {children}
        </AccordionListContext.Provider>
      </div>
    </div>
  );
}

interface Props {
  size?: "medium" | "large";
}

AccordionList.Item = AccordionListItem;
