import { css } from "../../sitches.config";
import { ListItem } from "../List";
import { Box } from "../List";
import { Text } from "../List";

interface TableFilterSectionProps {
  title: string;
  children: any;
}

function TableFilterSection({ title, children }: TableFilterSectionProps) {
  return (
    <ListItem className={styles.item()}>
      <Box className={styles.title()}>
        <Text singleLine>
          <strong>{title}</strong>
        </Text>
      </Box>
      <Box className={styles.filter()}>{children}</Box>
    </ListItem>
  );
}

const styles = {
  item: css({
    "@sm": {
      display: "flex",
      alignItems: "center"
    }
  }),

  title: css({
    marginBottom: 5,

    "@sm": {
      marginBottom: 0
    }
  }),

  filter: css({
    flex: 1,

    "@sm": {
      marginLeft: 10
    }
  })
};

export { TableFilterSection };
