import React from "react";

import { styles } from "./Block.styles";
import { Property } from "csstype";

export function Block({ className, children,
  margin, marginLg, marginMd, marginSmd, marginSm,marginXl,
  marginEmpty, //marginEmptySm,marginEmptySmd,marginEmptyMd,marginEmptyLg,marginEmptyXl,
  marginFirst, //marginFirstSm, marginFirstSmd, marginFirstMd, marginFirstLg, marginFirstXl,
  marginLast, //marginLastSm,marginLastSmd,marginLastMd,marginLastLg,marginLastXl,

  marginBottom, marginBottomLg, marginBottomMd, marginBottomSmd, marginBottomSm,marginBottomXl,
  marginBottomEmpty, //marginBottomEmptySm,marginBottomEmptySmd,marginBottomEmptyMd,marginBottomEmptyLg,marginBottomEmptyXl,
  marginBottomFirst, //marginBottomFirstSm, marginBottomFirstSmd, marginBottomFirstMd, marginBottomFirstLg, marginBottomFirstXl,
  marginBottomLast, //marginBottomLastSm,marginBottomLastSmd,marginBottomLastMd,marginBottomLastLg,marginBottomLastXl,

  marginLeft, marginLeftLg, marginLeftMd, marginLeftSmd, marginLeftSm,marginLeftXl,
  marginLeftEmpty, //marginLeftEmptySm,marginLeftEmptySmd,marginLeftEmptyMd,marginLeftEmptyLg,marginLeftEmptyXl,
  marginLeftFirst, //marginLeftFirstSm, marginLeftFirstSmd, marginLeftFirstMd, marginLeftFirstLg, marginLeftFirstXl,
  marginLeftLast, //marginLeftLastSm,marginLeftLastSmd,marginLeftLastMd,marginLeftLastLg,marginLeftLastXl,

  marginRight, marginRightLg, marginRightMd, marginRightSmd, marginRightSm,marginRightXl,
  marginRightEmpty, //marginRightEmptySm,marginRightEmptySmd,marginRightEmptyMd,marginRightEmptyLg,marginRightEmptyXl,
  marginRightFirst, //marginRightFirstSm, marginRightFirstSmd, marginRightFirstMd, marginRightFirstLg, marginRightFirstXl,
  marginRightLast, //marginRightLastSm,marginRightLastSmd,marginRightLastMd,marginRightLastLg,marginRightLastXl,

  marginTop, marginTopLg, marginTopMd, marginTopSmd, marginTopSm,marginTopXl,
  marginTopEmpty, //marginTopEmptySm,marginTopEmptySmd,marginTopEmptyMd,marginTopEmptyLg,marginTopEmptyXl,
  marginTopFirst, //marginTopFirstSm, marginTopFirstSmd, marginTopFirstMd, marginTopFirstLg, marginTopFirstXl,
  marginTopLast, //marginTopLastSm,marginTopLastSmd,marginTopLastMd,marginTopLastLg,marginTopLastXl,

  padding, paddingLg, paddingMd, paddingSmd, paddingSm,paddingXl,
  paddingEmpty, //paddingEmptySm,paddingEmptySmd,paddingEmptyMd,paddingEmptyLg,paddingEmptyXl,
  paddingFirst, //paddingFirstSm, paddingFirstSmd, paddingFirstMd, paddingFirstLg, paddingFirstXl,
  paddingLast, //paddingLastSm,paddingLastSmd,paddingLastMd,paddingLastLg,paddingLastXl,

  paddingBottom, paddingBottomLg, paddingBottomMd, paddingBottomSmd, paddingBottomSm,paddingBottomXl,
  paddingBottomEmpty, //paddingBottomEmptySm,paddingBottomEmptySmd,paddingBottomEmptyMd,paddingBottomEmptyLg,paddingBottomEmptyXl,
  paddingBottomFirst, //paddingBottomFirstSm, paddingBottomFirstSmd, paddingBottomFirstMd, paddingBottomFirstLg, paddingBottomFirstXl,
  paddingBottomLast, //paddingBottomLastSm,paddingBottomLastSmd,paddingBottomLastMd,paddingBottomLastLg,paddingBottomLastXl,

  paddingLeft, paddingLeftLg, paddingLeftMd, paddingLeftSmd, paddingLeftSm,paddingLeftXl,
  paddingLeftEmpty, //paddingLeftEmptySm,paddingLeftEmptySmd,paddingLeftEmptyMd,paddingLeftEmptyLg,paddingLeftEmptyXl,
  paddingLeftFirst, //paddingLeftFirstSm, paddingLeftFirstSmd, paddingLeftFirstMd, paddingLeftFirstLg, paddingLeftFirstXl,
  paddingLeftLast, //paddingLeftLastSm,paddingLeftLastSmd,paddingLeftLastMd,paddingLeftLastLg,paddingLeftLastXl,

  paddingRight, paddingRightLg, paddingRightMd, paddingRightSmd, paddingRightSm,paddingRightXl,
  paddingRightEmpty, //paddingRightEmptySm,paddingRightEmptySmd,paddingRightEmptyMd,paddingRightEmptyLg,paddingRightEmptyXl,
  paddingRightFirst, //paddingRightFirstSm, paddingRightFirstSmd, paddingRightFirstMd, paddingRightFirstLg, paddingRightFirstXl,
  paddingRightLast, //paddingRightLastSm,paddingRightLastSmd,paddingRightLastMd,paddingRightLastLg,paddingRightLastXl,

  paddingTop, paddingTopLg, paddingTopMd, paddingTopSmd, paddingTopSm,paddingTopXl,
  paddingTopEmpty, //paddingTopEmptySm,paddingTopEmptySmd,paddingTopEmptyMd,paddingTopEmptyLg,paddingTopEmptyXl,
  paddingTopFirst, //paddingTopFirstSm, paddingTopFirstSmd, paddingTopFirstMd, paddingTopFirstLg, paddingTopFirstXl,
  paddingTopLast, //paddingTopLastSm,paddingTopLastSmd,paddingTopLastMd,paddingTopLastLg,paddingTopLastXl,


  display, displayLg, displayMd, displaySm, displaySmd, displayXl,

...props

    }: Props) {

  return (
    <div className={styles.block({ className,
      display, displayLg, displayMd, displaySm, displaySmd, displayXl,
      margin, marginLg, marginMd, marginSmd, marginSm,marginXl,
      marginEmpty, //marginEmptySm,marginEmptySmd,marginEmptyMd,marginEmptyLg,marginEmptyXl,
      marginFirst, //marginFirstSm, marginFirstSmd, marginFirstMd, marginFirstLg, marginFirstXl,
      marginLast, //marginLastSm,marginLastSmd,marginLastMd,marginLastLg,marginLastXl,
      marginBottom, marginBottomLg, marginBottomMd, marginBottomSmd, marginBottomSm,marginBottomXl,
      marginBottomEmpty, //marginBottomEmptySm,marginBottomEmptySmd,marginBottomEmptyMd,marginBottomEmptyLg,marginBottomEmptyXl,
      marginBottomFirst, //marginBottomFirstSm, marginBottomFirstSmd, marginBottomFirstMd, marginBottomFirstLg, marginBottomFirstXl,
      marginBottomLast, //marginBottomLastSm,marginBottomLastSmd,marginBottomLastMd,marginBottomLastLg,marginBottomLastXl,
      marginLeft, marginLeftLg, marginLeftMd, marginLeftSmd, marginLeftSm,marginLeftXl,
      marginLeftEmpty, //marginLeftEmptySm,marginLeftEmptySmd,marginLeftEmptyMd,marginLeftEmptyLg,marginLeftEmptyXl,
      marginLeftFirst, //marginLeftFirstSm, marginLeftFirstSmd, marginLeftFirstMd, marginLeftFirstLg, marginLeftFirstXl,
      marginLeftLast, //marginLeftLastSm,marginLeftLastSmd,marginLeftLastMd,marginLeftLastLg,marginLeftLastXl,
      marginRight, marginRightLg, marginRightMd, marginRightSmd, marginRightSm,marginRightXl,
      marginRightEmpty, //marginRightEmptySm,marginRightEmptySmd,marginRightEmptyMd,marginRightEmptyLg,marginRightEmptyXl,
      marginRightFirst, //marginRightFirstSm, marginRightFirstSmd, marginRightFirstMd, marginRightFirstLg, marginRightFirstXl,
      marginRightLast, //marginRightLastSm,marginRightLastSmd,marginRightLastMd,marginRightLastLg,marginRightLastXl,
      marginTop, marginTopLg, marginTopMd, marginTopSmd, marginTopSm,marginTopXl,
      marginTopEmpty, //marginTopEmptySm,marginTopEmptySmd,marginTopEmptyMd,marginTopEmptyLg,marginTopEmptyXl,
      marginTopFirst, //marginTopFirstSm, marginTopFirstSmd, marginTopFirstMd, marginTopFirstLg, marginTopFirstXl,
      marginTopLast, //marginTopLastSm,marginTopLastSmd,marginTopLastMd,marginTopLastLg,marginTopLastXl,
      padding, paddingLg, paddingMd, paddingSmd, paddingSm,paddingXl,
      paddingEmpty, //paddingEmptySm,paddingEmptySmd,paddingEmptyMd,paddingEmptyLg,paddingEmptyXl,
      paddingFirst, //paddingFirstSm, paddingFirstSmd, paddingFirstMd, paddingFirstLg, paddingFirstXl,
      paddingLast, //paddingLastSm,paddingLastSmd,paddingLastMd,paddingLastLg,paddingLastXl,
      paddingBottom, paddingBottomLg, paddingBottomMd, paddingBottomSmd, paddingBottomSm,paddingBottomXl,
      paddingBottomEmpty, //paddingBottomEmptySm,paddingBottomEmptySmd,paddingBottomEmptyMd,paddingBottomEmptyLg,paddingBottomEmptyXl,
      paddingBottomFirst, //paddingBottomFirstSm, paddingBottomFirstSmd, paddingBottomFirstMd, paddingBottomFirstLg, paddingBottomFirstXl,
      paddingBottomLast, //paddingBottomLastSm,paddingBottomLastSmd,paddingBottomLastMd,paddingBottomLastLg,paddingBottomLastXl,
      paddingLeft, paddingLeftLg, paddingLeftMd, paddingLeftSmd, paddingLeftSm,paddingLeftXl,
      paddingLeftEmpty, //paddingLeftEmptySm,paddingLeftEmptySmd,paddingLeftEmptyMd,paddingLeftEmptyLg,paddingLeftEmptyXl,
      paddingLeftFirst, //paddingLeftFirstSm, paddingLeftFirstSmd, paddingLeftFirstMd, paddingLeftFirstLg, paddingLeftFirstXl,
      paddingLeftLast, //paddingLeftLastSm,paddingLeftLastSmd,paddingLeftLastMd,paddingLeftLastLg,paddingLeftLastXl,
      paddingRight, paddingRightLg, paddingRightMd, paddingRightSmd, paddingRightSm,paddingRightXl,
      paddingRightEmpty, //paddingRightEmptySm,paddingRightEmptySmd,paddingRightEmptyMd,paddingRightEmptyLg,paddingRightEmptyXl,
      paddingRightFirst, //paddingRightFirstSm, paddingRightFirstSmd, paddingRightFirstMd, paddingRightFirstLg, paddingRightFirstXl,
      paddingRightLast, //paddingRightLastSm,paddingRightLastSmd,paddingRightLastMd,paddingRightLastLg,paddingRightLastXl,
      paddingTop, paddingTopLg, paddingTopMd, paddingTopSmd, paddingTopSm,paddingTopXl,
      paddingTopEmpty, //paddingTopEmptySm,paddingTopEmptySmd,paddingTopEmptyMd,paddingTopEmptyLg,paddingTopEmptyXl,
      paddingTopFirst, //paddingTopFirstSm, paddingTopFirstSmd, paddingTopFirstMd, paddingTopFirstLg, paddingTopFirstXl,
      paddingTopLast,//paddingTopLastSm,paddingTopLastSmd,paddingTopLastMd,paddingTopLastLg,paddingTopLastXl,
    })} {...props}>
      {children}
    </div>
  );
}

interface Props extends Omit<React.HTMLProps<HTMLDivElement>, "dangerouslySetInnerHTML">, SpacingProps, DisplayProps {

}

type Space = 0 | 1 | 2 | 3 | 4;
type Attributes = "padding" | "margin";
type Sides = "" | "Top" | "Right" | "Bottom" | "Left";
type Selectors = "" | "First" |"Last"| "Empty";
type Breakpoints = ""| "Sm" | "Smd" | "Md" | "Lg" | "Xl"
type SpacingProps = {
  [key in `${Attributes}${Sides}${Selectors}${Breakpoints}`]?: Space;
};

type DisplayProps = {
  [key in `display${Breakpoints}`]?: Property.Display;
}
