import React from "react";

import Image from "../Image";
import styles from "./avatar-blurb-with-tags.module.less";

const AvatarBlurbWithTags = ({ name, avatar, tags }) => {
  return (
    <div className={styles["avatar-blurb-with-tags"]}>
      <div className={styles["avatar-blurb-with-tags__image-wrapper"]}>
        <Image
          className={styles["avatar-blurb-with-tags__image"]}
          src={avatar}
        />
      </div>
      <div className={styles["avatar-blurb-with-tags__blurb"]}>
        <p className={styles["avatar-blurb-with-tags__name"]}>{name}</p>
        <div className={styles["avatar-blurb-with-tags__tags"]}>
          {tags.map(tag => (
            <span
              key={tag.text}
              className={`
								${styles["avatar-blurb-with-tags__tag"]}
								${styles[`avatar-blurb-with-tags__tag--${tag.color || "ebonyClay"}`]}
							`}
            >
              {tag.text}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AvatarBlurbWithTags;
