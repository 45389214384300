import React from "react";
import Link from "../Link";
import LandingHeader from "./../LandingHeader";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import InfoBanner from "../InfoBanner";
import SimpleCTA from "../SimpleCTA/SimpleCTA";
import ImageAlignedBanner from "../ImageAlignedBanner";
import ImageAndStickerCTA from "../ImageAndStickerCTA";
import MotivationBanner from "../MotivationBanner";
import StickyCTA from "../StickyCTA";
import styles from "./TeacherBenefitsPage.module.less";

const TeacherBenefitsPage = ({
  stickyCTA,
  landingHeaderProps,
  infoBannerProps,
  topSimpleCTAProps,
  alignedImageBannerProps,
  bottomSimpleCTAProps,
  motivationBanner,
  imageAndStickerCTAProps,
  signUpHref
}) => (
  <div className={styles["teacher-benefits-page"]}>
    <StickyCTA {...stickyCTA} />
    <LandingHeader {...landingHeaderProps}>
      <Link
        href={signUpHref}
        className={styles["teacher-benefits-page__banner-cta-link"]}
      >
        <PrimaryButton
          width="auto"
          textMode="uppercase"
          color={landingHeaderProps.buttonColor}
          text={landingHeaderProps.buttonText}
        />
      </Link>
    </LandingHeader>

    {/* BODY */}
    <div className={styles["teacher-benefits-page__container"]}>
      <div className={styles["teacher-benefits-page__top-simple-cta"]}>
        <SimpleCTA {...topSimpleCTAProps} />
      </div>
      <div className={styles["teacher-benefits-page__info-banner"]}>
        <InfoBanner {...infoBannerProps} />
      </div>
      <div className={styles["teacher-benefits-page__aligned-image-banner"]}>
        <ImageAlignedBanner {...alignedImageBannerProps[0]} />
      </div>
      <div className={styles["teacher-benefits-page__aligned-image-banner"]}>
        <ImageAlignedBanner {...alignedImageBannerProps[1]} />
      </div>
      <div className={styles["teacher-benefits-page__aligned-image-banner"]}>
        <ImageAlignedBanner {...alignedImageBannerProps[2]} />
      </div>
      <div className={styles["teacher-benefits-page__motivation-banner"]}>
        <MotivationBanner {...motivationBanner} />
      </div>
      <div className={styles["teacher-benefits-page__bottom-simple-cta"]}>
        <SimpleCTA {...bottomSimpleCTAProps} />
      </div>
    </div>

    {/* FOOTER */}
    <ImageAndStickerCTA {...imageAndStickerCTAProps} />
  </div>
);

export default TeacherBenefitsPage;
