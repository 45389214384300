export function Product__ProductUrl(product, baseUrl = "") {
  return `${baseUrl}/products/${product.productType}/${product.category}/${product.slug}`;
}

export function ProductUrl(
  product,
  baseUrl = "",
  schoolSlug,
  companyName,
  widgetPermission
) {
  if (companyName === "baluu") {
    return `http://${schoolSlug}.live.baluu.co.uk/products/${product.productType}/${product.category}/${product.slug}`;
  }
  if (companyName === "obby" && widgetPermission) {
    return `http://${schoolSlug}.widget.obby.co.uk/products/${product.productType}/${product.category}/${product.slug}`;
  }

  return `${baseUrl}/products/${product.productType}/${product.category}/${product.slug}`;
}
