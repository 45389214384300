import React from "react";

import Icon from "../Icon";
import { styles } from "./InfoBanner.styles";

/**
 *
 * @param titleSize 'medium', ''
 */
const InfoBanner = ({
  title,
  sections,
  shouldWrapSections,
  titleSize,
  iconHeight
}) => (
  <div className={`info-banner ${styles.infoBanner()}`}>
    <span className={`info-banner__title ${styles.title({ titleSize })}`}>
      {title}
    </span>
    <div
      className={`info-banner__sections ${styles.sections({
        shouldWrapSections
      })}`}
    >
      {sections.map(section => (
        <div
          className={`info-banner__section ${styles.section()}`}
          key={section.subTitle}
        >
          {section.icon && (
            <div className={`info-banner__icon ${styles.icon()}`}>
              <Icon
                icon={section.icon}
                height={iconHeight}
                color="emeraldish"
              />
            </div>
          )}
          <span
            className={`info-banner__sub-title ${styles.subTitle({
              titleSize
            })}`}
          >
            {section.subTitle}
          </span>
          <p className={`info-banner__description ${styles.description()}`}>
            {section.description}
          </p>
        </div>
      ))}
    </div>
  </div>
);

InfoBanner.defaultProps = {
  iconHeight: 35
};

export default InfoBanner;
