import { css } from "@obby/components";

export const styles = {
  layout: css({
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column"
  }),
  content: css({
    flex: "1",
    backgroundColor: "$pampas"
  })
};
