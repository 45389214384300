import { css } from "../../sitches.config";

export const styles = {
  component: css({
    display: "flex",
    alignItems: "center"
  }),
  imageWrapper: css({
    display: "none",
    flexBasis: "200px",
    "@smd": {
      display: "block"
    }
  }),
  image: css({
    display: "block",
    width: "100%"
  }),
  title: css({
    fontFamily: "$foundersGroteskCondensed",
    fontWeight: "normal",
    fontSize: "$40",
    marginTop: 0,
    marginBottom: "$3"
  }),
  description: css({
    fontSize: "$20",
    fontWeight: "normal",
    fontFamily: "$foundersGrotesk",
    marginY: "$3"
  })
};
