import React from "react";
import PropTypes from "prop-types";

import Address from "../Address";
import LocationDetails from "../LocationDetails";

import { css } from "../../sitches.config";

export function ClassLocationSection(props) {
  const {
    classType,
    googleMap,
    location,
    isAnywhere,
    hasVariousLocations
  } = props;

  return (
    <div className={`class-location-section ${styles.classLocation}`}>
      {googleMap && (
        <div className={`class-location-section__map ${styles.map}`}>
          {googleMap}
        </div>
      )}
      {/* LOCATION DETAILS */}
      <div className={`in-person-class-page__section`}>
        {isAnywhere && (
          <Address
            line1={`This ${classType} takes place at a location of your choosing.`}
          />
        )}
        {hasVariousLocations && (
          <Address
            line1={`This ${classType} takes place in multiple different locations. Click on SEE DATES and select your Booking Option to see where each date takes place.`}
          />
        )}
        {location && (
          <LocationDetails
            title={location.name}
            area={location.area}
            line1={location.line1}
            line2={location.line2}
            postcode={location.postcode}
            city={location.city}
          />
        )}
      </div>
    </div>
  );
}

ClassLocationSection.propTypes = {};

const styles = {
  classLocation: css({
    flexDirection: "column",
    ".in-person-class-page__section": {
      marginBottom: "0px"
    }
  }),

  map: css({
    width: "100%",
    height: "380px",
    marginBottom: "25px"
  })
};
