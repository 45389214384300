import { css } from "../../sitches.config";

export const styles = {
  component: css({
    width: "100%",
    marginTop: "$4",
    "&:first-child": {
      marginTop: 0
    }
  }),
  label: css({
    fontFamily: "$foundersGrotesk",
    lineHeight: 1,
    fontWeight: 400,
    color: "$textColor",
    variants: {
      condensed: {
        true: {
          fontFamily: "$foundersGroteskCondensed",
          textTransform: "uppercase"
        }
      },
      bold: {
        true: { fontWeight: 500 }
      },
      restricted: {
        true: {
          color: "$silver"
        }
      },

      size: {
        small: {
          fontSize: "$18"
        },
        normal: {
          fontSize: "$20"
        },
        large: {
          fontSize: "$26"
        }
      },

      nowrap: {
        true: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }
      }
    },
    "&:not(:last-child)": {
      marginBottom: "$1"
    },
    "&:empty:after": {
      content: "\\00a0",
      visibility: "hidden"
    }
  }),

  lockIcon: css({
    marginLeft: "$1",
    "& path": {
      fill: "currentColor"
    }
  }),

  description: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$18",
    lineHeight: "1.25",
    color: "$textColor",
    variants: {
      restricted: {
        true: {
          color: "$silver"
        }
      }
    }
  }),
  content: css({
    variants: {
      short: {
        true: {
          maxWidth: "260px"
        }
      },
      size: {
        small: {
          marginTop: "$1"
        },
        normal: {
          marginTop: "$2"
        },
        large: {
          marginTop: "$3"
        }
      }
    }
  }),

  note: css({
    paddingTop: "$1",
    fontFamily: "$foundersGrotesk",
    fontSize: "$18",
    lineHeight: "1.25",
    color: "$venus",
    variants: {
      restricted: {
        true: {
          color: "$silver"
        }
      }
    }
  }),

  error: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$18",
    paddingTop: "$1",
    lineHeight: "1.25",
    color: "$deepBlush"
  })
};
