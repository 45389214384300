import React from "react";
import { RoundedButton } from "../RoundedButton";

export function UrlButton(props: UrlButton.Props) {
  const {
    size = "medium",
    style = "primary",
    color = "callToAction",
    disabled = false,
    className = "",
    href,
    innerRef,
    onClick,
    outline = style === "secondary",
    tag,
    target,
    text,
    icon,
    faIcon,
    download,
    width = "auto",
    square
  } = props;

  return (
    <RoundedButton
      className={`url-button ${className}`}
      color={color}
      disabled={disabled}
      elem="a"
      elemProps={{
        download,
        href,
        target
      }}
      icon={icon}
      faIcon={faIcon}
      innerRef={innerRef}
      onClick={onClick}
      outline={outline}
      size={size}
      text={text}
      tag={tag}
      width={width}
      square={square}
    />
  );
}

export namespace UrlButton {
  export interface Props
    extends Omit<RoundedButton.Props, "elem" | "elemProps"> {
    download?: boolean | string;
    href?: string;
    style?: "primary" | "secondary";
    target?: "_self" | "_blank" | "_parent" | "_top";
  }
}
