import React from "react";
import { styles } from "./Beacon.styles";
import { ClassName } from "../../sitches.config";

export function Beacon({ id, className }: Props) {
  return <div id={id} className={styles.beacon({ className })} />;
}

interface Props {
  id: string;
  className?: ClassName;
}
