import React, { useState } from "react";
import { bemModule } from "../../configs/bem";
import { SimpleBanner } from "../../banners/SimpleBanner";
import styles from "./BulletPointsList.module.less";

const cn = bemModule(styles, "bullet-points-list");
export function BulletPointsList(props) {
  const {
    color = "camelot",
    title,
    items = [],
    maxItems = 3,
    linkTxt = "",
    linkRef = false
  } = props;

  const [expand, setExpand] = useState(false);
  const hasMore = items.length > maxItems;
  const showingItems = expand ? items : items.slice(0, maxItems);

  function onReadMoreClick() {
    setExpand(!expand);
  }

  return (
    <SimpleBanner
      accent={color}
      title={title}
      footer={hasMore ? `Read ${expand ? "less" : "more"}` : undefined}
      onFooterClick={onReadMoreClick}
    >
      <ul className={cn("list")}>
        {showingItems.map((item, index) => (
          <li
            key={index}
            className={cn("list-item", null, ["mt-2 mb-4 mb-last-0"])}
          >
            <span className={cn("bullet-point")} />
            {item}
          </li>
        ))}
      </ul>
      {linkRef && (
        <a
          className={cn("link")}
          href={linkRef}
          target="_blank"
          rel="noopener noreferrer"
        >
          {linkTxt}
        </a>
      )}
    </SimpleBanner>
  );
}
