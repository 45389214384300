import { css, withBem } from "../../sitches.config";

export const styles = withBem("tabs-nav", {
  tabsNav: css({
    display: "flex",
    alignItems: "flex-end"
  }),
  horizonte: css({
    flex: "1",
    borderBottom: "solid 1px $silverMalibu"
  })
});
