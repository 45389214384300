import React from "react";
import { bemModule } from "../../configs/bem";

import styles from "./TipsExample.module.less";

const cn = bemModule(styles, "tips-example");
export function TipsExample(props) {
  const { children, title = "Example" } = props;

  return (
    <li className={cn(null, ["mb-3 mb-last-0"])}>
      {title && <div className={cn("title")}>{title}:</div>}
      {children}
    </li>
  );
}
