import React from "react";
import { Label } from "../../components/Label";
import { TextArea } from "../../inputs/TextArea";
import { bem } from "../../configs/bem";
import DashboardPageHeader from "../../components/DashboardPageHeader";
import DashboardPageSection from "../../components/DashboardPageSection";

import { ProductEmailFormSchema } from "./ProductEmailForm.schema";

const cn = bem("product-email-form");
export function ProductEmailForm(props) {
  const {
    values = {},
    touched = {},
    errors = {},
    courses = [],
    onChange,
    onBlur
  } = props;

  return (
    <div className={cn()}>
      <DashboardPageHeader title="Custom Email Confirmation" />
      <DashboardPageSection
        title="Personalised message to customer"
        description="Immediately after purchase, your customer will receive an email including this message and links to any learning content you provide."
      >
        <Label error={touched.purchaseMessage && errors.purchaseMessage}>
          <TextArea
            name="purchaseMessage"
            onChange={onChange}
            onBlur={onBlur}
            value={values.purchaseMessage}
            placeholder="Thanks for buying our Learning Kit."
            max={4000}
          />
        </Label>
      </DashboardPageSection>
    </div>
  );
}

ProductEmailForm.Schema = ProductEmailFormSchema;
