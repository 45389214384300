import { css, withBem } from "./../sitches.config";

export const styles = withBem("table-cell", {
  tableCell: css({
    backgroundColor: "$white",
    fontFamily: "$foundersGrotesk",
    fontSize: "$18",
    padding: "$2",
    textAlign: "var(--table-cell-align, left)"
  })
});
