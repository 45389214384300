import React, { useState } from "react";

import { styles } from "./ClassListGrid.styles";
import ClassListCard from "../ClassListCard/ClassListCard";
import SecondaryButton from "../SecondaryButton";

const ClassListGrid = ({ title, classList, buttonText, onViewMoreClick }) => {
  const defaultDisplaySize = 6;
  const [displaySize, setDisplaySize] = useState(defaultDisplaySize);
  const _classList = classList.filter((c, index) => index < displaySize);

  const onViewMoreClickProxy = () => {
    const newDisplaySize = displaySize + defaultDisplaySize;
    if (onViewMoreClick) {
      onViewMoreClick(newDisplaySize);
    }

    setDisplaySize(newDisplaySize);
  };

  return (
    <div className={`class-list-grid`}>
      <div className={`class-list-grid__header ${styles.header}`}>
        <span className={`class-list-grid__title ${styles.title}`}>
          {title}
        </span>
      </div>
      <div className={`class-list-grid__grid ${styles.grid}`}>
        {_classList.map(classListItem => (
          <ClassListCard key={classListItem.id} {...classListItem} />
        ))}
      </div>
      {displaySize < classList.length && (
        <div className={`class-list-grid__cta-wrapper ${styles.ctaWrapper}`}>
          <SecondaryButton
            text={buttonText}
            className={`class-list-grid__cta ${styles.cta}`}
            color="ebonyClay"
            width="auto"
            onClick={onViewMoreClickProxy}
          />
        </div>
      )}
    </div>
  );
};

export default ClassListGrid;
