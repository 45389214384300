import moment from "moment-timezone";
import { Timezones } from "@obby/constants";

// We are just comparing utc dates, so timezone has no role to play here
export function UTCDate__IsSame(
  isoDate1: string,
  isoDate2?: string,
  granularity?: Granularity,
  // timezone is only useful if granularity is specified.
  // e.g 2022-05-31T21:00:00.000Z and 2022-05-31T22:00:00.000Z happens in different dates in Amsterdam timezone but not
  // in London timezone
  timezone: string = Timezones.EUROPE_LONDON
) {
  return moment(isoDate1)
    .tz(timezone)
    .isSame(isoDate2, granularity);
}

type Granularity =
  | "year"
  | "years"
  | "y"
  | "month"
  | "months"
  | "M"
  | "week"
  | "weeks"
  | "w"
  | "day"
  | "days"
  | "d"
  | "hour"
  | "hours"
  | "h"
  | "minute"
  | "minutes"
  | "m"
  | "second"
  | "seconds"
  | "s"
  | "millisecond"
  | "milliseconds"
  | "ms";
