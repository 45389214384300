import React, { useState, useEffect, ReactNode } from "react";
import { TableBody } from "./TableBody";
import { TableCell } from "./TableCell";
import { TableHeader } from "./TableHeader";
import { TableHeaderCell } from "./TableHeaderCell";
import { TablePaginator } from "./TablePaginator";
import { TableRow } from "./TableRow";
import { TableEmpty } from "./TableEmpty";
import { styles } from "./Table.styles";
import { TableProvider } from "./TableProvider";
import { LoadingIcon, Block, Flex } from "@obby/components";

export function Table({
  className,
  isLoading = false,
  isScrollable = false,
  order,
  orderBy,
  onPageChange,
  onSort,
  onSortClear,
  page,
  totalPages,
  ...props
}: React.PropsWithChildren<Props>) {
  // children can only be rendered on client cause <TableBody> uses useLayoutEffect
  const [children, setChildren] = useState<ReactNode | undefined>(undefined);
  useEffect(() => {
    setChildren(props.children);
  }, [props.children]);

  return (
    <TableProvider
      order={order}
      orderBy={orderBy}
      onSort={onSort}
      onSortClear={onSortClear}
    >
      <Flex column gap={1}>
        <div className={styles.tableWrapper({ isScrollable })}>
          <table className={styles.table({ className })} {...props}>
            {children}
          </table>
          {isLoading && (
            <div className={styles.loader()}>
              <LoadingIcon />
            </div>
          )}
        </div>
        {page !== undefined && (totalPages === undefined || totalPages > 1) && (
          <TablePaginator
            page={page}
            onChange={onPageChange}
            total={totalPages}
          />
        )}
      </Flex>
    </TableProvider>
  );
}
Table.Body = TableBody;
Table.Cell = TableCell;
Table.Empty = TableEmpty;
Table.Header = TableHeader;
Table.HeaderCell = TableHeaderCell;
Table.Paginator = TablePaginator;
Table.Row = TableRow;

interface Props
  extends Omit<React.HTMLProps<HTMLTableElement>, "dangerouslySetInnerHTML"> {
  isLoading?: boolean;
  isScrollable?: boolean;
  orderBy?: string;
  order?: "asc" | "desc";
  onSort?: (orderBy: string, order: "asc" | "desc") => void;
  onSortClear?: () => void;
  onPageChange?: (page: number) => void;
  page?: number;
  totalPages?: number;
}
