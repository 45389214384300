import React from "react";
import PropTypes from "prop-types";
// Components
import RadioButtons from "../RadioButtons";
// Constants
import { CCourseTypesOptions } from "@obby/lib";

const options = [
  { label: "All" },
  ...Object.keys(CCourseTypesOptions).map(type => ({
    label: CCourseTypesOptions[type],
    value: type
  }))
];

export function CourseTypeFilter(props) {
  const { type, onChange, color = "robbinsEggBlue" } = props;

  return (
    <div className="course-type-filter">
      <RadioButtons
        value={type}
        color={color}
        options={options}
        onChange={onChange}
      />
    </div>
  );
}

CourseTypeFilter.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  onChange: PropTypes.func
};
