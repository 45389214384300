import { FaIcon } from "./FaIcon";

export const EmbedStandard: FaIcon.Icon = {
  icon: [
    300,
    300,
    [],
    "",
    [
      "M20 20 h260 v10 h-260 z",
      "M20 60 h260 v180 h-260 z",
      "M20 270 h260 v10 h-260 z"
    ]
  ]
};
