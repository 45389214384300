export const PapertrailActions = {
  ADD_COURSE_DATE: "add_course_date",
  ADD_TICKET_TYPE: "add_ticket_type",
  ADD_SESSION_TICKET_TYPE: "add_session_ticket_type",
  ASSIGN_TUTOR: "assign_tutor",
  CANCEL_BOOKING: "cancel_booking",
  CANCEL_SESSION: "cancel_session",
  CHANGE_PRODUCT_PRICE: "change_product_price",
  CHANGE_PRODUCT_STOCK: "change_product_stock",
  CHANGE_SESSION_TICKET_CAPACITY: "change_session_ticket_capacity",
  CHANGE_TICKET_TYPE_PRICE: "change_ticket_type_price",
  DELETE_COURSE_DATE: "delete_course_date",
  DELETE_TICKET_TYPE: "delete_ticket_type",
  EDIT_BOOKING_TICKET: "edit_booking_ticket",
  REFUND_BOOKING: "refund_booking",
  REMOVE_BOOKING_GUEST: "remove_booking_guest",
  REMOVE_SESSION_TICKET_TYPE: "remove_session_ticket_type",
  REMOVE_WAITING_LIST_RECORD: "remove_waiting_list_record",
  RESCHEDULE_BOOKING: "reschedule_booking"
} as const;
