export function Duration__DurationString(
  duration: number,
  units: {
    hour?: string;
    hours?: string;
    minute?: string;
    minutes?: string;
  } = {}
) {
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  let strings: string[] = [];
  if (hours > 0) {
    let string = `${hours}`;
    if (hours === 1) string += `${units.hour ?? "hr"}`;
    else string += `${units.hours ?? "hrs"}`;
    strings.push(string);
  }
  if (minutes > 0) {
    let string = `${minutes}`;
    if (minutes === 1) string += `${units.minute ?? "min"}`;
    else string += `${units.minutes ?? "min"}`;
    strings.push(string);
  }
  return strings.join(" ");
}
