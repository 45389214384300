import { css, withBem } from "../../sitches.config";

const removeItemButton: ReturnType<typeof css> = css({
  color: "$silver",
  cursor: "pointer",
  flex: 0,
  marginLeft: "12px",
  fontSize: "20px",
  "@smd": {
    opacity: 0
  }
});

const addItemButton: ReturnType<typeof css> = css({
  alignItems: "center",
  color: "$callToAction",
  cursor: "pointer",
  display: "inline-flex",
  fontFamily: "$foundersGrotesk",
  fontSize: "18px",
  fontStyle: "normal",
  gap: "$1",
  lineHeight: "22px"
});

export const styles = withBem("array-input", {
  arrayInput: css({}),
  list: css({
    padding: 0,
    margin: 0,
    listStyle: "none",
    [`&:not(:empty) ~ .${addItemButton}`]: {
      marginTop: "$2"
    }
  }),

  item: css({
    display: "flex",
    marginBottom: "$3",
    alignItems: "flex-start",

    "&:last-child": {
      marginBottom: 0
    },
    "@smd": {
      [`&:hover .${removeItemButton}`]: {
        opacity: 1
      }
    }
  }),

  itemContent: css({
    flex: 1
  }),

  addItemButton,

  removeItemButton
});
