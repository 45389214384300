import { css } from "../../sitches.config";

export const styles = {
  banner: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    backgroundColor: "$ebonyClay",
    height: "38px",
    overflow: "hidden",
    textAlign: "center"
  }),
  content: css({
    display: "inline-block",
    fontFamily: "$foundersGrotesk",
    fontSize: "$16",
    color: "$hummingBird",
    whiteSpace: "nowrap",
    lineHeight: 1,
    "@sm": {
      fontSize: "$18"
    },
    "@smd": {
      fontSize: "$20"
    },
    "@md": {
      fontSize: "$22"
    }
  })
};
