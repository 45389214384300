export const PapertrailItemTypes = {
  BOOKING: "booking",
  COURSE: "course",
  DISCOUNT: "discount",
  LOCATION: "location",
  PRIVATE_CLASS: "private_class",
  PRODUCT: "product",
  PRODUCT_PURCHASE: "product_purchase",
  SCHOOL: "school",
  SESSION: "session",
  TICKET_TYPE: "ticket_type",
  USER: "user",
  WAITING_LIST: "waiting_list"
} as const;
