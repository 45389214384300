import { styled } from "../sitches.config";

/** @deprecated */
const List = styled("ul", {
  listStyle: "none",
  padding: 0,
  margin: 0,
  boxSizing: "border-box"
});

/** @deprecated */
const ListItem = styled("li", {
  padding: 0,
  margin: 0,
  boxSizing: "border-box"
});

/** @deprecated - Use a div! */
const Box = styled("div", {
  boxSizing: "border-box"
});

export { Box };
/** @deprecated - we should not be forced to use a <p> just to print some inline text, which, judging by his usage, it's the purpose of this component.
 * The <p> tag, besides not being an inline element, has his own specificities and should only be used, guest what, for PARAGRAPHS!  */
const Text = styled("p", {
  fontFamily: "$foundersGrotesk",
  fontWeight: 400,
  margin: "0 0 15px 0",

  "&:last-child": {
    marginBottom: 0
  },

  variants: {
    textStyle: {
      italic: {
        fontStyle: "italic"
      }
    },
    color: {
      gray: {
        color: "$gray"
      },
      ebonyClay: {
        color: "$ebonyClay"
      },
      tundora: {
        color: "$tundora"
      },
      gothic: {
        color: "$gothic"
      },
      alto: {
        color: "$alto"
      },
      deepBlush: {
        color: "$deepBlush"
      },
      camelot: {
        color: "$camelot"
      }
    },
    lineHeight: {
      normal: {
        lineHeight: 1.2
      },
      large: {
        lineHeight: 1.4
      }
    },
    size: {
      smallest: {
        fontSize: "0.75rem"
      },

      small: {
        fontSize: "0.875rem"
      },

      normal: {
        fontSize: "1.0rem"
      },

      large: {
        fontSize: "1.125rem"
      },

      largest: {
        fontSize: "1.25rem"
      },

      // Do not use
      18: {
        fontSize: 18
      },
      20: {
        fontSize: 20
      }
    },
    align: {
      center: {
        textAlign: "center"
      },
      left: {
        textAlign: "left"
      },
      right: {
        textAlign: "right"
      }
    },
    singleLine: {
      true: {
        lineHeight: 1
      }
    }
  },

  defaultVariants: {
    color: "ebonyClay",
    lineHeight: "normal",
    textAlign: "left",
    size: "normal"
  }
});

export { Text };

export { List, ListItem };
