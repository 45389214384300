import Image__ResizeImage from "./Image__ResizedImage";
import isFullArray from "./isFullArray";
import CoursePriceBooking__DiscountPercent from "./CoursePriceBooking__DiscountPercent";
import { prop } from "ramda";
import { sales } from "@obby/constants";

const Course__ClassItemBlockBooking = course => {
  if (isFullArray(course.blockBooking)) {
    return course.blockBooking.map(booking => {
      return {
        classCount: booking.sessions,
        price: booking.price,
        discountPercent: CoursePriceBooking__DiscountPercent(
          course.price,
          booking
        )
      };
    });
  }
  return [];
};

const AlgoliaSearchResult__ClassCard = result => ({
  id: result._id,
  title: result.title,
  thumbnail: Image__ResizeImage(
    { width: 450, height: 300, crop: "fill" },
    result.image
  ).url,
  price: result.price * 100,
  prevPrice: result.prevPrice ? result.prevPrice * 100 : null,
  url: `/classes/${result.slug}`,
  urlAlt: `Book ${result.subcategory} class on Obby`,
  duration: result.duration,
  rating: Math.round((result.rating / 2) * 10) / 10,
  numberOfReviews: result.reviewsCount,
  location: result.location,
  locations: isFullArray(result.locations)
    ? result.locations.map(prop("area"))
    : [],
  level: result.level,
  blockBooking: Course__ClassItemBlockBooking(result),
  flexitimeMinCount:
    result.anytimeClass && result.minSize ? result.minSize : null,
  sales: sales,
  isOnline: result.isOnline,
  samePriceAlways: result.samePriceAlways || null,
  discount: result.discount || null,
  requiresSendingPhysicalMaterial: result.requiresSendingPhysicalMaterial
});

export default AlgoliaSearchResult__ClassCard;
