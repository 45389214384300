import React, { useState, useEffect } from "react";
import { styles } from "./Checkbox.styles";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { FaIcon } from "../../icons/FaIcon";

interface Props {
  className?: string;
  isChecked?: boolean;
  color?: "brightTeal" | "robbinsEggBlue" | "camelot" | "callToAction";
  size?: "small" | "medium" | "large";
  label?: React.ReactNode;
  name?: string;
  value?: boolean;
  css?: any;
  onClick?: (val: boolean, name: string) => void;
  onChange?: (val: boolean, name: string) => void;
  onBlur?: (name: string) => void;
  disabled?: boolean;
  readOnly?: boolean;
}

export function Checkbox({
  className = "",
  isChecked = false,
  color = "brightTeal",
  size = "large",
  label,
  name,
  css,
  value = isChecked ?? false,
  onClick,
  onChange = onClick,
  onBlur = () => {},
  disabled = false,
  readOnly = false
}: Props) {
  const [checked, setChecked] = useState(value);
  useEffect(() => {
    setChecked(value);
  }, [value]);

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (readOnly) e.preventDefault();
    else {
      setChecked(e.target.checked);
      onChange && onChange(e.target.checked, name!);
    }
  }

  return (
    <label
      className={styles.checkbox({
        disabled,
        css,
        className,
        size
      })}
    >
      <input
        className={styles.input()}
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onInputChange}
        onBlur={() => onBlur(name!)}
        disabled={disabled}
      />
      {!checked && (
        <div
          className={styles.uncheckedBox({
            disabled
          })}
        />
      )}
      {checked && (
        <FaIcon
          className={styles.checkedBox({
            disabled,
            css: { "--box-color": `$colors$${color}` }
          })}
          icon={faCheck}
        />
      )}
      {label && <span className={styles.text({ disabled })}>{label}</span>}
    </label>
  );
}
