import React from "react";
import ReactLoadingSkeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { styles } from "./Skeleton.styles";

export function Skeleton({
  circle,
  sharp,
  className,
  dark,
  highlightColor,
  light,
  lines = 1,
  color,
  stretch,
  width
}: Props) {
  if (dark) {
    color = "mercury";
    highlightColor = "alto";
  }
  if (light) {
    color = "white";
    highlightColor = "concrete";
  }

  return (
    <ReactLoadingSkeleton
      borderRadius={sharp ? 0 : "0.25rem"}
      circle={circle}
      className={styles.skeleton({
        stretch,
        css: {
          "&.react-loading-skeleton": {
            "--base-color": color ? `$colors$${color}` : undefined,
            "--highlight-color": highlightColor
              ? `$colors$${highlightColor}`
              : undefined,
            width: width ? `${width}px` : undefined,
            ...(width ? { maxWidth: "100%" } : {})
          }
        },
        className
      })}
      count={lines}
    />
  );
}

interface Props {
  circle?: boolean;
  className?: string;
  color?: string;
  colorHex?: string;
  highlightColor?: string;
  lines?: number;
  sharp?: boolean;
  dark?: boolean;
  light?: boolean;
  stretch?: boolean;
  width?: number;
}
