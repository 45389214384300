import { Timezones } from "@obby/constants";
import { Date__DisplayTime } from "./Date__DisplayTime";
import { Date__DisplayOffsetName } from "./Date__DisplayOffsetName";
import { Date__DisplayDate } from "./Date__DisplayDate";

export function Date__DisplayDateTimeOffset(
  date: string,
  duration: number,
  timezone: string = Timezones.EUROPE_LONDON,
  forceTimezone: boolean = false
) {
  let displayDate = `${Date__DisplayDate(
    date,
    timezone,
    forceTimezone
  )}, ${Date__DisplayTime(date, duration, timezone, forceTimezone)}`;
  if (forceTimezone)
    displayDate += ` ${Date__DisplayOffsetName(date, timezone, forceTimezone)}`;

  return displayDate;
}
