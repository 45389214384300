import React from "react";
import { bemModule } from "../../../configs/bem";
import styles from "./RadioInputOption.module.less";
import { useRadioInputContext } from "./RadioInputContext";
import { RadioInputRadio } from "./RadioInputRadio";

const cn = bemModule(styles, "radio-input-option");
export function RadioInputOption(props) {
  const {
    size,
    accent,
    name,
    onChange,
    onBlur,
    checked,
    disabled = props.disabled
  } = useRadioInputContext();

  const { children, label = children, value, description, onClick } = props;

  const marginBottom = {
    medium: "mb-3 mb-last-0",
    large: "mb-4 mb-last-0"
  };

  return (
    <li className={cn(null, [marginBottom[size]])} onClick={onClick}>
      <label className={cn("wrapper")}>
        <RadioInputRadio
          accent={accent}
          checked={checked}
          value={value}
          name={name}
          disabled={disabled}
          onBlur={onBlur}
          onChange={onChange}
          size={size}
        />
        <div className={cn("text", { size })}>
          <div className={cn("label", { size, disabled })}>{label}</div>
          {description && (
            <div className={cn("description", { size, disabled })}>
              {description}
            </div>
          )}
        </div>
      </label>
    </li>
  );
}
