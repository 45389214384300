import React from "react";
import { includes } from "lodash";
import BaseComponent from "./../lib/BaseComponent";
import ForgottenPasswordForm from "../ForgottenPasswordForm";
import AuthenticationStatuses from "../../constants/AuthenticationStatuses";
import { SignInForm, SignUpForm, SignUpWithEmailForm } from "@obby/components";
import authInstance from "../../config/auth";
import { styles } from "./AccountForms.style";

export default class AccountForms extends BaseComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={styles.accountForms()}>
        {(this.props.status === AuthenticationStatuses.SIGNING_IN ||
          this.props.status ===
            AuthenticationStatuses.SIGNING_IN_FOR_CHECKOUT) && (
          <SignInForm
            errors={this.props.errors}
            isCheckout={
              this.props.status ===
              AuthenticationStatuses.SIGNING_IN_FOR_CHECKOUT
            }
            facebookAppId={this.props.facebookAppId}
            disabledFacebookLogin={process.env.NODE_ENV !== "production"}
            googleAppId={this.props.googleAppId}
            email={this.props.email}
            signInNote={this.props.signInNote}
            password={this.props.password}
            onTypeEmail={this.props.onTypeEmail}
            onTypePassword={this.props.onTypePassword}
            onSubmitSignIn={this.props.onSubmitSignIn}
            onBeginSignUp={this.props.onBeginSignUp}
            onBeginResetPassword={this.props.onBeginResetPassword}
            onCheckoutAsGuest={this.props.onCheckoutAsGuest}
            onFacebookSignInSuccess={this.props.onFacebookSignIn}
            onFacebookSignInFailure={this.props.onFacebookSignInFailed}
            onGoogleSignInSuccess={this.props.onGoogleSignIn}
            onGoogleSignInFailure={this.props.onGoogleSignInFailed}
          />
        )}

        {this.props.status === AuthenticationStatuses.SIGNING_UP_VIA_SOCIAL && (
          <SignUpForm
            error={this.props.errors.general}
            isCheckout={this.props.isCheckout}
            facebookAppId={this.props.facebookAppId}
            googleAppId={this.props.googleAppId}
            onSubmitSignIn={this.props.onSubmitSocialSignIn}
            onBeginSignUp={this.props.onBeginEmailSignUp}
            onBeginSignIn={this.props.onBeginSignIn}
            onFacebookSignInSuccess={async data =>
              await authInstance.socialLogin("facebook", data.token)
            }
            onFacebookSignInFailure={() =>
              console.log("onFacebookSignInFaliure")
            }
            onGoogleSignInSuccess={async data =>
              await authInstance.socialLogin("google", data.token)
            }
            onGoogleSignInFailure={() => console.log("onGoogleSignInFailure")}
            onCheckoutAsGuest={() => console.log("onCheckoutAsGuest")}
          />
        )}

        {this.props.status === AuthenticationStatuses.SIGNING_UP_VIA_EMAIL && (
          <SignUpWithEmailForm
            errors={this.props.errors}
            isCheckout={this.props.isCheckout}
            isSignedUpToNewsletter={this.props.isSignedUpToNewsletter}
            facebookAppId={this.props.facebookAppId}
            googleAppId={this.props.googleAppId}
            firstName={this.props.firstName}
            lastName={this.props.lastName}
            email={this.props.email}
            password={this.props.password}
            onTypeFirstName={this.props.onTypeFirstName}
            onTypeLastName={this.props.onTypeLastName}
            onTypeEmail={this.props.onTypeEmail}
            onTypePassword={this.props.onTypePassword}
            onSubmitSignUp={this.props.onSubmitSignUp}
            onBeginSignIn={this.props.onBeginSignIn}
            onToggleNewsletterSignUp={this.props.onToggleNewsletterSignUp}
          />
        )}

        {includes(
          [
            AuthenticationStatuses.RESETING_PASSWORD,
            AuthenticationStatuses.PASSWORD_RESET
          ],
          this.props.status
        ) && (
          <ForgottenPasswordForm
            errors={this.props.errors}
            email={this.props.email}
            isSubmitted={
              this.props.status === AuthenticationStatuses.PASSWORD_RESET
            }
            onTypeEmail={this.props.onTypeEmail}
            onSubmitResetPassword={this.props.onSubmitResetPassword}
          />
        )}
      </div>
    );
  }
}
