import React, { ReactNode } from "react";
// @ts-ignore
import { CurrencyISO__CurrencySymbol, Number__Currency } from "@obby/lib";

import { styles } from "./CheckoutCardItem.styles";

export function CheckoutCardItem(props: React.PropsWithChildren<Props>) {
  const {
    title,
    description,
    price,
    percentage,
    count,
    currency,
    bold = false,
    children,
    pricingOptions = [],
    isTotal = false
  } = props;

  return (
    <div className={styles.item({ className: "checkout-card-item", bold })}>
      <div className={styles.title({ bold })}>
        {count ? `${count} x ` : ""}
        {title}
      </div>
      {price !== undefined &&
        (percentage !== undefined || price != 0 || isTotal) && (
          <div className={styles.price({ bold })}>
            {Number__Currency(
              price * (count ?? 1),
              CurrencyISO__CurrencySymbol(currency)
            )}
            {percentage !== undefined && ` (${percentage}% off)`}
          </div>
        )}
      {percentage == undefined && pricingOptions.length > 1 && price == 0 && (
        <div className={styles.price({ bold })}>Free</div>
      )}

      {description && <div className={styles.description()}>{description}</div>}
      {children && <div className={styles.content()}>{children}</div>}
    </div>
  );
}

interface Props {
  bold?: boolean;
  count?: number;
  currency?: string;
  description?: string;
  percentage?: number;
  price?: number;
  title: ReactNode;
  pricingOptions?: any;
  isTotal?: boolean;
}
