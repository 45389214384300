import { css } from "../../sitches.config";

export const styles = {
  courseWideCardWithReviews: css({
    position: "relative"
  }),
  background: css({
    boxShadow: "0 0 40px rgba(0, 0, 0, 0.02)",
    padding: "16px 18px 0px",
    "@sm": {
      padding: "$4"
    }
  }),
  favoriteButton: css({
    position: "absolute",
    top: "$15",
    right: "$15",
    "@smd": {
      right: "auto",
      left: "calc(33.3% - 52px)"
    }
  }),
  header: css({
    display: "flex",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "$10",
    minHeight: "2.3rem",
    "@sm": {
      marginBottom: "$16"
    }
  }),
  title: css({
    fontFamily: "$foundersGrotesk",
    fontWeight: "500",
    lineHeight: "120%",
    letterSpacing: "0.01em",
    color: "$ebonyClay",
    "-webkit-font-smoothing": "antialiased",

    "@sm": {
      fontSize: "$mParagraph",
      maxWidth: "70%"
    }
  }),
  section: css({
    marginBottom: "4px"
  }),
  type: css({
    background: "rgba(218, 218, 218, 0.44)",
    borderRadius: "$3",
    fontFamily: "$foundersGrotesk",
    fontSize: "$12",
    lineHeight: "22px",
    textAlign: "center",
    letterSpacing: "0.05em",
    textTransform: "uppercase",
    color: "$venus",
    display: "none",
    padding: "0 14px",
    margin: "0 10px 0 0",
    "-webkit-font-smoothing": "antialiased",
    "@sm": {
      display: "inline-block"
    }
  }),
  review: css({
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": "3",
    "-webkit-box-orient": "vertical",

    ".review-card": {
      "& .card-background": {
        padding: "0",
        borderRadius: "0",
        ".review-card__name": {
          fontSize: "16px",
          fontWeight: "400",
          color: "#888"
        },

        ".review-card__rating ": {
          marginTop: "$1"
        },
        ".review-card__text": {
          marginTop: "0px",
          overflow: "hidden",
          display: "-webkit-box",
          "-webkit-line-clamp": "2",
          "-webkit-box-orient": "vertical",

          "@sm": {
            "-webkit-line-clamp": "3"
          }
        }
      }
    }
  }),

  student: css({
    fontFamily: "$foundersGrotesk",
    fontWeight: "500",
    fontSize: "$xsmParagraph",
    lineHeight: "110%",
    letterSpacing: "0.03em",
    color: "$scorpion",

    "@sm": {
      fontSize: "$smParagraph"
    }
  }),

  duration: css({
    fontFamily: "$foundersGrotesk",
    fontWeight: "500",
    fontSize: "$xsmParagraph",
    lineHeight: "110%",
    letterSpacing: "0.03em",
    color: "$scorpion",
    textTransform: "lowercase",

    "@sm": {
      fontSize: "$smParagraph"
    }
  }),

  content: css({
    flex: "1",
    display: "flex",
    flexDirection: "column",

    "@smd": {
      height: "320px"
    },

    "&:link, &:visited, &:hover, &:active": {
      color: "inherit",
      textDecoration: "none"
    }
  }),

  main: css({
    "@sm": {
      display: "flex",
      flexDirection: "column",
      flexGrow: "1"
    }
  }),

  footer: css({
    flexGrow: "1",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end"
  }),

  description: css({
    display: "none",
    fontFamily: "$foundersGrotesk",
    fontSize: "$mParagraph",
    lineHeight: "144%",
    letterSpacing: "0.01em",
    color: "$ebonyClay",
    "@sm": {
      display: "block"
    }
  }),

  button: css({
    position: "absolute",
    right: "0",
    top: "0",
    display: "none",
    "@sm": {
      display: "flex"
    }
  }),

  footerIcons: css({
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginBlock: "$10",

    "@sm": {
      marginBlock: "$16"
    }
  }),

  extraInfoItem: css({
    fontFamily: "$foundersGrotesk",
    fontWeight: "500",
    fontSize: "$14",
    lineHeight: "110%",
    letterSpacing: "0.03em",
    color: "#888",
    display: "inline-flex",
    alignItems: "center",
    marginRight: "$2",

    "@sm": {
      fontSize: "$smParagraph",
      marginRight: "$3"
    },

    variants: {
      variant: {
        displayGrid: {
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          rowGap: "16px",

          "&-item:first-child, &-item:nth-child(3)": { gridColumn: "span 2" },

          "@sm": {
            gridGap: "16px",
            width: "100%",
            gridTemplateColumns: "1fr 1fr",
            "&-item:first-child, &-item:nth-child(3)": { gridColumn: "span 1" }
          }
        }
      }
    }
  }),

  locationIcon: css({
    verticalAlign: "bottom",
    marginRight: "4px"
  }),

  price: css({
    variants: {
      withDiscount: {
        true: {
          textDecoration: "line-through"
        }
      }
    }
  }),

  twoColumns: css({
    gridColumn: "span 2"
  }),

  label: css({
    borderRadius: "2px",
    padding: "0px 6px 2px",

    variants: {
      variant: {
        liveOnline: {
          backgroundColor: "rgb(230, 228, 128, 0.2)"
        },
        watchTutorial: {
          backgroundColor: "rgb(50, 200, 100, 0.2)"
        }
      }
    }
  }),

  bottom: css({
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "$16",

    span: {
      fontSize: "16px",
      fontWeight: "500"
    }
  }),

  nextSessionDate: css({
    marginTop: "$8",
    fontWeight: "500",
    lineHeight: "100%",
    color: "$ebonyClay",
    letterSpacing: "0.01em",
    fontSize: "$smParagraph",
    fontFamily: "$foundersGrotesk",

    "@sm": {
      marginTop: "$16"
    }
  }),

  nextSessionDateLabel: css({
    color: "$gray",
    marginRight: "$1"
  })
};
