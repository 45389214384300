import React from "react";
import { store } from "statorgfc";
import { BoxPopup } from "../BoxPopup";
import AccountForms from "../accounts/AccountForms";
import { includes } from "lodash";
import AuthenticationStatuses from "../../constants/AuthenticationStatuses";
import AuthenticationState from "../../state/AuthenticationState";
import { facebook, google } from "../../settings";

export class AuthenticationPopup extends React.Component {
  constructor(props) {
    super(props);
    store.connectComponentState(this, ["authentication"]);
  }

  onBeginEmailSignUp() {
    AuthenticationState.events.beginEmailSignUp.next();
  }

  onBeginResettingPassword() {
    AuthenticationState.events.beginResetingPassword.next();
  }

  onBeginSignIn() {
    AuthenticationState.events.beginSignIn.next();
  }

  onBeginSignUp() {
    AuthenticationState.events.beginSignUp.next();
  }
  onCheckoutAsGuest() {
    AuthenticationState.events.checkoutAsGuest.next();
  }

  onCloseSignInPopup() {
    AuthenticationState.events.endSignIn.next();
  }

  onResetPassword() {
    AuthenticationState.events.resetPassword.next();
  }

  onSignIn() {
    AuthenticationState.events.signIn.next();
  }

  onSignOut() {
    AuthenticationState.events.signOut.next();
  }

  onSignUp() {
    AuthenticationState.events.signUp.next();
  }

  onTypeEmail(value) {
    AuthenticationState.events.typeEmail.next(value);
  }

  onTypeFirstName(value) {
    AuthenticationState.events.typeFirstName.next(value);
  }

  onTypeLastName(value) {
    AuthenticationState.events.typeLastName.next(value);
  }

  onTypePassword(value) {
    AuthenticationState.events.typePassword.next(value);
  }

  isUserSigningIn() {
    return includes(
      [
        AuthenticationStatuses.SIGNING_IN,
        AuthenticationStatuses.SIGNING_IN_FOR_CHECKOUT,
        AuthenticationStatuses.SIGNING_UP_VIA_EMAIL,
        AuthenticationStatuses.SIGNING_UP_VIA_SOCIAL,
        AuthenticationStatuses.RESETING_PASSWORD,
        AuthenticationStatuses.PASSWORD_RESET
      ],
      this.state.authentication.status
    );
  }

  onToggleNewsletterSignUp = () =>
    AuthenticationState.events.toggleNewsletterSignUp.next();

  onFacebookSignIn(data) {
    AuthenticationState.events.socialSignIn.next({
      service: "facebook",
      token: data.token
    });
  }

  onFacebookSignInFailed(error) {
    AuthenticationState.events.socialSignInFailed.next({
      service: "facebook",
      token: error
    });
  }

  onGoogleSignIn(data) {
    AuthenticationState.events.socialSignIn.next({
      service: "google",
      token: data.token
    });
  }

  onGoogleSignInFailed(error) {
    AuthenticationState.events.socialSignInFailed.next({
      service: "google",
      token: error
    });
  }

  render() {
    return (
      <BoxPopup
        isOpen={this.isUserSigningIn()}
        onClose={this.onCloseSignInPopup}
      >
        <AccountForms
          status={this.state.authentication.status}
          errors={this.state.authentication.errors}
          firstName={this.state.authentication.firstName}
          lastName={this.state.authentication.lastName}
          email={this.state.authentication.signInEmail}
          password={this.state.authentication.signInPassword}
          isSignedUpToNewsletter={
            this.state.authentication.isSignedUpToNewsletter
          }
          facebookAppId={facebook.appId}
          googleAppId={google.clientId}
          onTypeFirstName={this.onTypeFirstName}
          onTypeLastName={this.onTypeLastName}
          onTypeEmail={this.onTypeEmail}
          onTypePassword={this.onTypePassword}
          onBeginSignUp={this.onBeginSignUp}
          onBeginEmailSignUp={this.onBeginEmailSignUp}
          onBeginSignIn={this.onBeginSignIn}
          onSignIn={this.onSignIn}
          onFacebookSignIn={this.onFacebookSignIn.bind(this)}
          onFacebookSignInFailed={this.onFacebookSignInFailed.bind(this)}
          onGoogleSignIn={this.onGoogleSignIn.bind(this)}
          onGoogleSignInFailed={this.onGoogleSignInFailed.bind(this)}
          onBeginResetPassword={this.onBeginResettingPassword}
          onSubmitResetPassword={this.onResetPassword}
          onSubmitSignIn={this.onSignIn}
          onSubmitSignUp={this.onSignUp}
          onResetPasswordSent={this.onResetPassword}
          onCheckoutAsGuest={this.onCheckoutAsGuest}
          onToggleNewsletterSignUp={this.onToggleNewsletterSignUp}
          isCheckout={false}
          close={this.onCloseSignInPopup}
        />
      </BoxPopup>
    );
  }
}
