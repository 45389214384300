import * as Yup from "yup";
export function GiftFormSchema() {
  const defaults = {
    to: "",
    from: "",
    message: ""
  };

  return Yup.object({
    to: Yup.string()
      .label("To")
      .default(defaults.to),
    from: Yup.string()
      .label("From")
      .default(defaults.from),
    message: Yup.string()
      .label("Message")
      .default(defaults.message)
  });
}
