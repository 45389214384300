import { css } from "../../sitches.config";

export const styles = {
  embedVideoCard: css({
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "20px",
    color: "$ebonyClay"
  }),

  title: css({
    fontFamily: "$foundersGroteskCondensed",
    fontSize: "28px",
    lineHeight: "100%",
    letterSpacing: "0.01em",
    textTransform: "uppercase",
    margin: "5px 0",

    "@lg": {
      fontSize: "32px"
    }
  }),

  separator: css({
    backgroundColor: "$royalBlue",
    border: "none",
    width: "60px",
    height: "3px",
    margin: "22px 0 18px"
  }),

  description: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$18",
    lineHeight: "134%",
    letterSpacing: "0.01em",
    marginBottom: "23px",

    "@md": {
      marginBottom: "0"
    }
  }),

  embedHtmlWrapper: css({
    position: "relative",
    paddingBottom: "56.25%",
    borderRadius: "10px",
    overflow: "hidden"
  }),

  embedHtml: css({
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",

    iframe: {
      width: "100%",
      height: "100%"
    }
  })
};
