import React, { useEffect } from "react";
import Icon from "./../Icon";
import Icons from "./../Icons";
import styles from "./big-popup.module.less";

export default function BigPopup(props) {
  const {
    className,
    children,
    isOpen,
    onClose,
    doNotDisableDocumentScrolling = false
  } = props;

  useEffect(() => {
    if (!doNotDisableDocumentScrolling)
      window.document.body.style.overflow = isOpen ? "hidden" : "auto";

    return () => {
      if (!doNotDisableDocumentScrolling)
        window.document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <div
      className={
        isOpen
          ? styles[`big-popup ${className || ""}`]
          : styles[`big-popup big-popup--hidden ${className || ""}`]
      }
    >
      <div className={styles["big-popup__controls"]}>
        <span
          onClick={onClose}
          className={`${styles["big-popup__closeButton"]} ${
            isOpen ? "" : styles["big-popup__closeButton--hidden"]
          }`}
        >
          <Icon width={20} icon={Icons.Cross} />
        </span>
      </div>
      <div className={styles["big-popup__container"]}>{children}</div>
    </div>
  );
}
