import React from "react";

import SecondaryButton from "../SecondaryButton";
import styles from "./banner-content.module.less";

const BannerContent = ({
  title,
  description,
  buttonText,
  onButtonClick = () => {},
  // this need to be dynamic because it will be specified by the admins
  textColor = "#28283c",
  dividerColor
}) => (
  <div className={styles["banner-content"]} style={{ color: textColor }}>
    <span className={styles["banner-content__title"]}>{title}</span>
    <span
      className={`${styles["banner-content__divider"]} ${
        dividerColor ? styles[`banner-content__divider--${dividerColor}`] : ""
      }`}
    />
    <span className={styles["banner-content__description"]}>{description}</span>
    {buttonText && (
      <div className={styles["banner-content__button"]}>
        <SecondaryButton
          size="medium"
          text={buttonText}
          textMode="uppercase"
          color="ebonyClay"
          onClick={onButtonClick}
        />
      </div>
    )}
  </div>
);

BannerContent.defaultProps = {
  mobileImageAlignment: "bottom"
};

export default BannerContent;
