import React from "react";
import { PrimaryButton } from "../../components/PrimaryButton";
import SecondaryButton from "../../components/SecondaryButton";
import ScrollLink from "../../components/ScrollLink";
import { bem } from "../../configs";
import { styles } from "./CoursePageTopBar.styles";

const cn = bem("course-page-top-bar");
export function CoursePageTopBar({
  menuItems = [],
  menuActions = [],
  onMenuItemClick
}) {
  return (
    <div className={`${cn()} ${styles.coursePageTopBar}`}>
      <div className={`${cn("wrapper")} ${styles.wrapper}`}>
        <div className={`${cn("menu-list")} ${styles.menuList}`}>
          {menuItems.map((menuItem, index) => (
            <ScrollLink
              key={index}
              href={menuItem.link}
              className={`${cn("link")} ${styles.link}`}
              onClick={() => onMenuItemClick && onMenuItemClick(menuItem)}
              offset={menuItem.offset}
            >
              {menuItem.label}
            </ScrollLink>
          ))}
        </div>
        <div className={`${cn("book-wrapper")} ${styles.bookWrapper}`}>
          <div className={`${cn("actions")} ${styles.actions}`}>
            {menuActions
              .filter(action => !action.hidden)
              .map((action, index) => {
                const { type = "PRIMARY" } = action;
                const Button = {
                  SECONDARY: SecondaryButton,
                  PRIMARY: PrimaryButton
                }[type];
                return (
                  <Button
                    key={index}
                    onClick={action.onClick}
                    text={action.text}
                    width={action.width}
                    textMode={action.textMode || "uppercase"}
                    color={action.color || "camelot"}
                    disabled={action.disabled || false}
                    className={`${cn("action")} ${styles.action}`}
                    css={{
                      width: "12.5rem",
                      fontSize: "$14",
                      letterSpacing: "1px"
                    }}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
