import React from "react";
import _ from "lodash";
import { SecondaryButton } from "@obby/components";
import { PrimaryButton } from "@obby/components";
import dynamic from "next/dynamic";
import { styles } from "./MessagePrompt.styles";
const ReactMarkdown = dynamic(
  () => import("react-markdown/react-markdown.min"),
  { ssr: false }
);

export default class MessagePrompt extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
      error: ""
    };
  }

  onMessageChange(event) {
    this.setState({ message: event.target.value });
  }

  onMessageSend(event) {
    const { message } = this.state;
    this.props.submit(message);
    this.setState({ message: "" });
  }

  linkRenderer(props) {
    return (
      <Link href={props.href} target="_blank" prefetch={false}>
        {props.children}
      </Link>
    );
  }

  render() {
    const {
      title,
      subtitle,
      smallSubtitle,
      preSubmitSubtitle,
      submit,
      cancel,
      disabled
    } = this.props;
    const { message } = this.state;

    return (
      <div className={`message-prompt ${styles.messagePrompt}`}>
        <h2 className={`message-prompt__title ${styles.title}`}>{title}</h2>
        <div
          className={`message-prompt__form-container ${styles.formContainer}`}
        >
          <h3 className={`message-prompt__subtitle ${styles.subTitle}`}>
            {subtitle}
          </h3>
          {!_.isEmpty(preSubmitSubtitle) && (
            <h4
              className={`message-prompt__small-subtitle ${styles.smallSubtitle}`}
            >
              {smallSubtitle}
            </h4>
          )}
          <div
            className={`message-prompt__message-container ${styles.messageContainer}`}
          >
            <textarea
              className={`message-prompt__message ${styles.message}`}
              value={message}
              onChange={this.onMessageChange.bind(this)}
              placeholder="Write a message here"
            />
          </div>
          {!_.isEmpty(preSubmitSubtitle) && (
            <h4
              className={`message-prompt__pre-submit-subtitle ${styles.preSubmitSubtitle}`}
            >
              <ReactMarkdown
                children={preSubmitSubtitle}
                renderers={{ Link: this.linkRenderer }}
              />
            </h4>
          )}
        </div>
        <div
          className={`message-prompt__button-container ${styles.buttonContainer}`}
        >
          <SecondaryButton
            text="Cancel"
            className={`message-prompt__cancel-button ${styles.cancelButton}`}
            onClick={cancel}
            disabled={disabled}
          />
          <PrimaryButton
            text="Send"
            color="camelot"
            className={`message-prompt__submit-button ${styles.submitButton}`}
            onClick={this.onMessageSend.bind(this)}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }
}
