import React, { useMemo, useState } from "react";
import * as Icons from "../../components/Icons";
import { AsyncSelectBox } from "../../inputs/AsyncSelectBox";
import { PrimaryButton } from "../../components/PrimaryButton/PrimaryButton";
import { ImageUrl__ImageResizedUrl } from "@obby/lib";

import { styles } from "./SearchHeader.styles";

export function SearchHeader(props) {
  const {
    backgroundImageUrl,
    onLoadTags,
    onLoadGeoTags,
    onSearchTags,
    onSearchGeoTags
  } = props;

  const [
    smBackground,
    smdBackground,
    mdBackground,
    lgBackground,
    xlBackground
  ] = useMemo(() => {
    return [
      ImageUrl__ImageResizedUrl(
        { width: 700, height: 480, crop: "fill" },
        backgroundImageUrl
      ),
      ImageUrl__ImageResizedUrl(
        { width: 834, height: 480, crop: "fill" },
        backgroundImageUrl
      ),
      ImageUrl__ImageResizedUrl(
        { width: 1024, height: 480, crop: "fill" },
        backgroundImageUrl
      ),
      ImageUrl__ImageResizedUrl(
        { width: 1440, height: 600, crop: "fill" },
        backgroundImageUrl
      ),
      ImageUrl__ImageResizedUrl(
        { width: 1620, height: 600, crop: "fill" },
        backgroundImageUrl
      )
    ];
  }, [backgroundImageUrl]);

  const [defaultOptions, setDefaultOptions] = useState(props.tags);
  const [tagType, setTagType] = useState("tag");
  const [placeholder, setPlaceholder] = useState("What do you want to learn?");
  const [disabledButton, setDisabledButton] = useState(false);

  function onChange(option) {
    switch (tagType) {
      case "tag":
        onSearchTags(option);
        break;
      case "geotag":
        onSearchGeoTags(option);
        break;
    }
    setDisabledButton(true);
  }

  function onLoad(query) {
    switch (tagType) {
      case "tag":
        return onLoadTags(query);
      case "geotag":
        return onLoadGeoTags(query);
    }
  }

  function onTagClick(tagType) {
    switch (tagType) {
      case "tag":
        setDefaultOptions(props.tags);
        setPlaceholder("What do you want to learn");
        break;
      case "geotag":
        setDefaultOptions(props.geotags);
        setPlaceholder("Where do you want to learn");
        break;
    }
    setTagType(tagType);
  }

  function onButtonClick(e) {
    e.preventDefault();
    window.location.href = "/learn";
  }

  return (
    <div
      className={styles.component({
        className: "search-header",
        css: {
          "@sm": {
            backgroundImage: `url(${smBackground})`
          },
          "@smd": {
            backgroundImage: `url(${smdBackground})`
          },
          "@md": {
            backgroundImage: `url(${mdBackground})`
          },
          "@lg": {
            backgroundImage: `url(${lgBackground})`
          },
          "@xl": {
            backgroundImage: `url(${xlBackground})`
          }
        }
      })}
    >
      <div className="container-fluid">
        <div className={styles.wrapper()}>
          <h1 className={styles.heading()}>
            Discover your hidden talents and experience the joy of learning.
          </h1>
          <h2 className={styles.subheading()}>
            With 3,500+ unique experiences local to you or at home, you can
            experience a day in the life of a local maker, shaker or creator
            effortlessly with Obby.
          </h2>
          <div className={styles.searchInput()}>
            <div>
              <ul className={styles.tagFilters()}>
                <li
                  className={styles.tagFilter({ active: tagType === "tag" })}
                  onClick={() => onTagClick("tag")}
                >
                  Learn something
                </li>
                <li
                  className={styles.tagFilter({ active: tagType === "geotag" })}
                  onClick={() => onTagClick("geotag")}
                >
                  Find a class near you
                </li>
              </ul>
            </div>
            <div className={styles.inputFilter()}>
              <AsyncSelectBox
                icon={Icons.Search}
                onChange={onChange}
                placeholder={placeholder}
                accent="ebonyClay"
                defaultOptions={defaultOptions}
                onLoad={onLoad}
                parseOption={todo => ({ value: todo._id, label: todo.name })}
                rounded
                noOptionsMessage="Try something else"
              />
            </div>
            <PrimaryButton
              disabled={disabledButton}
              color={{ "@initial": "sun", "@sm": "robbinsEggBlue" }}
              text="explore all classes"
              onClick={onButtonClick}
              width="auto"
              textMode="uppercase"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
