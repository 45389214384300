import { css, withBem } from "../../sitches.config";
export const styles = withBem("calendar-list", {
  header: css({
    backgroundColor: "white",
    borderStyle: "solid",
    borderColor: "$mercury",
    borderWidth: "0 1px 1px",
    fontFamily: "$foundersGrotesk",
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "-0.02em",
    color: "$ebonyClay",
    padding: "$2 $3",
    "@md": {
      fontSize: "30px"
    },
    "&:first-child": {
      borderRadius: "4px 4px 0 0",
      borderTopWidth: "1px"
    }
  })
});
