import React, { useState } from "react";
import { CenteredPopup } from "../../modals/CenteredPopup";
import {
  EnquireLargeGroupFormSchema,
  Fields
} from "./EnquireLargeGroupForm.schema";
import { EnquireLargeGroupForm } from "./EnquireLargeGroupForm";
import { useFormState } from "../../hooks/useFormState";
import { Label } from "../../components/Label";
import { Block } from "../../layout/Block";
export function EnquireLargeGroupPopup({ onClose, onSendRequest }: Props) {
  const [success, setSuccess] = useState(false);
  const {
    errors,
    setFieldValue,
    setFieldTouched,
    touched,
    values,
    submit
  } = useFormState({
    schema: EnquireLargeGroupFormSchema(),
    values: {
      // firstName: "Sérgio",
      // lastName: "Freitas",
      // email: "sergio@obby.co.uk",
      // preferredDate: new Date("2023-07-06T09:00:00.000Z"),
      // budgetPerPerson: 75
    }
  });

  function onBlur(name: string) {
    setFieldTouched(name);
  }

  function onChange(value: any, name: string) {
    setFieldValue(name, value, false);
  }

  async function onSubmit() {
    const isValid = await submit(undefined);
    if (isValid)
      try {
        await onSendRequest(values);
        setSuccess(true);
      } catch (e) {}
  }

  return (
    <CenteredPopup
      title={
        success ? "Thanks for your enquiry!" : "Request a large group booking"
      }
      closeButtonPosition="cross"
      onClose={onClose}
      onAccept={success ? undefined : onSubmit}
      acceptButtonText="Send Request"
      size="medium"
      scrollable
    >
      {success && <Label label="We have received your request." />}

      {!success && (
        <>
          <Block marginBottom={4}>
            <Label label="Send us a request for a group of 15 guests or more. Please provide as much information as you can so we can help kick start your journey. You’ll receive a response from the team within 24-48hrs. " />
          </Block>
          <EnquireLargeGroupForm
            errors={errors}
            onBlur={onBlur}
            onChange={onChange}
            touched={touched}
            values={values}
          />
        </>
      )}
    </CenteredPopup>
  );
}

interface Props {
  onClose: () => void;
  onSendRequest: (values: Fields) => Promise<void>;
}
