import React from "react";

import * as Icons from "../../components/Icons";
import { AccordionBox } from "../../boxes/AccordionBox";
import { PopoutMenu } from "../../menus/PopoutMenu";
import { bemModule } from "../../configs/bem";

import styles from "./CourseAccordionListItem.module.less";

const cn = bemModule(styles, "course-accordion-list-item");
export function CourseAccordionListItem(props) {
  const { title, description, children, open, options = [] } = props;

  return (
    <div className={cn()}>
      <AccordionBox open={open}>
        <AccordionBox.Toggle>
          <div className={cn("slot")}>
            <div className={cn("slot-content")}>
              <div className={cn("title")}>{title}</div>
              {description && (
                <div className={cn("description")}>{description}</div>
              )}
            </div>
            <div className={cn("actions")} onClick={e => e.stopPropagation()}>
              {options.length > 0 && (
                <PopoutMenu icon={Icons.MoreOptions}>
                  {options.map(({ label, onClick }, index) => (
                    <PopoutMenu.Item key={index} onClick={onClick}>
                      {label}
                    </PopoutMenu.Item>
                  ))}
                </PopoutMenu>
              )}
            </div>
          </div>
        </AccordionBox.Toggle>
        <AccordionBox.Container className={cn("content")}>
          <div className="pb-4">{children}</div>
        </AccordionBox.Container>
      </AccordionBox>
    </div>
  );
}
