import { css, withBem } from "../../sitches.config";

export const styles = withBem("accordion-list-item", {
  accordionListItem: css({
    borderTop: "1px solid $iron"
  }),

  title: css({
    color: "$textColor",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "$foundersGrotesk",
    fontWeight: "500",
    letterSpacing: "0.04em",
    textTransform: "uppercase",
    cursor: "pointer",
    variants: {
      size: {
        large: {
          fontSize: "$18",
          height: "90px"
        },
        medium: {
          fontSize: "$14",
          height: "65px"
        }
      },
      disabled: {
        true: {
          color: "$disabled"
        }
      }
    }
  }),

  tick: css({
    display: "flex",
    transition: "transform 250ms ease",
    variants: {
      open: {
        true: {
          transform: "rotate(180deg)"
        }
      },
      size: {
        large: {
          fontSize: "20px"
        },
        medium: {
          fontSize: "16px"
        }
      }
    }
  }),

  contentWrapper: css({
    variants: {
      open: {
        false: {
          overflow: "hidden"
        }
      },
      opening: {
        true: {
          transition: "height 250ms ease, padding-bottom 250ms ease"
        }
      },
      defaultOpen: {
        false: {
          height: "0"
        }
      }
    }
  }),

  content: css({
    variants: {
      open: {
        true: {
          paddingBottom: "$4"
        }
      }
    }
  })
});
