import React from "react";
import PropTypes from "prop-types";

import Icon from "../Icon";
import Icons from "../Icons";
import ScrollLink from "../ScrollLink";
import { bem } from "../../configs/bem";
import { styles } from "./ClassesSubListMenu.styles";

const cn = bem("classes-sub-list-menu");

const ClassesSubListMenu = ({
  menuTitle,
  sublistMenu,
  onClickMenuItem,
  selectedMenuItem,
  selectedMenuItemColor
}) => (
  <div className={`${cn()}`}>
    <div className={`${cn("menu-header")} ${styles.menuHeader}`}>
      {menuTitle}
    </div>
    <div className={`${cn("menu-list")} ${styles.menuList}`}>
      {sublistMenu.map((menu, index) => (
        <ScrollLink
          key={index}
          href={menu.link}
          className={`${cn("menu-item-wrapper")} ${styles.menuItemWrapper}`}
          onClick={() => onClickMenuItem(menu)}
        >
          {menu.id === selectedMenuItem.id && (
            <Icon
              height={12}
              icon={Icons.ArrowForwards}
              className={`${cn("menu-item-icon")} ${styles.menuItemIcon({
                css: { g: { stroke: `$${selectedMenuItemColor}` } }
              })} classes-sub-list-menu__menu-item-icon--${selectedMenuItemColor}`}
            />
          )}
          <span
            className={`${cn("menu-item")} ${
              menu.id === selectedMenuItem.id
                ? styles.menuItem({
                    css: { color: `$${selectedMenuItemColor}` }
                  })
                : styles.menuItem()
            } ${menu.id === selectedMenuItem.id &&
              `classes-sub-list-menu__menu-item--${selectedMenuItemColor}`}`}
          >
            {menu.label}
          </span>
        </ScrollLink>
      ))}
    </div>
  </div>
);

ClassesSubListMenu.propTypes = {
  menuTitle: PropTypes.string,
  sublistMenu: PropTypes.array
};

export default ClassesSubListMenu;
