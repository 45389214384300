import React from "react";
export const SwitchInputContext = React.createContext<Context>({
  onOptionClick: () => {},
  size: "small"
});

interface Context {
  currentValue?: any;
  onOptionClick: (value: any) => void;
  color?: any;
  size: "small" | "medium";
}
