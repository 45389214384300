import React from "react";
import PropTypes from "prop-types";
import { CloudinaryImage } from "../CloudinaryImage";
import Link from "../Link";

import styles from "./menu-banner.module.less";

const MenuBanner = ({
  title,
  description,
  link,
  linkText,
  backgroundColor,
  thumbnail
}) => {
  return (
    <div
      className={`${styles["menu-banner"]} ${
        styles[`menu-banner--${backgroundColor}`]
      }`}
    >
      <div
        className={`${styles["menu-banner__wrapper"]} ${
          thumbnail ? styles["menu-banner__wrapper--has-thumbnail"] : ""
        }`}
      >
        <div className={styles["menu-banner__content"]}>
          <span className={styles["menu-banner__title"]}>{title}</span>
          <span className={styles["menu-banner__description"]}>
            {description}
          </span>
          {link && (
            <Link href={link} className={styles["menu-banner__link"]}>
              {linkText}
            </Link>
          )}
        </div>
        {thumbnail && (
          <CloudinaryImage
            width={530}
            height={265}
            src={thumbnail}
            className={styles["menu-banner__thumbnail"]}
          />
        )}
      </div>
    </div>
  );
};

MenuBanner.defaultProps = {
  backgroundColor: "coral"
};

MenuBanner.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  thumbnail: PropTypes.string
};

export default MenuBanner;
