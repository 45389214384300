type Value = string | number | boolean;

export function comparator(
  value1: Value | Value[],
  value2: Value | Value[],
  direction: "asc" | "desc" = "asc"
) {
  const [firstValue1, ...remainingValues1] = Array.isArray(value1)
    ? value1
    : [value1];
  const [firstValue2, ...remainingValues2] = Array.isArray(value2)
    ? value2
    : [value2];

  const order = compare(firstValue1, firstValue2, direction);
  if (order === 0 && (remainingValues1.length || remainingValues2.length))
    return comparator(remainingValues1, remainingValues2, direction);
  return order;
}

function compare(
  value1: Value,
  value2: Value,
  direction: "asc" | "desc" = "asc"
) {
  if (value1 === undefined && value2 === undefined) return 0;
  if (value1 === undefined) return 1;
  if (value2 === undefined) return -1;

  switch (direction) {
    case "asc":
      // value1 < value2 = -1
      // value1 > value2 = 1
      // value1 === value2 = 0
      return (value1 > value2 ? 1 : 0) - (value1 < value2 ? 1 : 0);
    case "desc":
      // value1 < value2 = 1
      // value1 > value2 = -1
      // value1 === value2 = 0
      return (value1 < value2 ? 1 : 0) - (value1 > value2 ? 1 : 0);
    default:
      return 0;
  }
}
