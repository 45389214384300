import { css } from "@obby/components";

export const styles = {
  messagePrompt: css({
    padding: "30px",
    backgroundColor: "#fff"
  }),

  title: css({
    fontSize: "28px",
    fontWeight: "600",
    marginBottom: "25px"
  }),

  subTitle: css({
    fontSize: "20px",
    margin: "0 0 15px 0"
  }),

  smallSubtitle: css({
    fontSize: "16px",
    margin: "0 0 15px 0",
    color: "$gray"
  }),

  preSubmitSubtitle: css({
    fontSize: "16px",
    margin: "0 0 15px 0",
    color: "$gray",

    p: {
      lineHeight: "18px"
    },
    a: {
      color: "$gray !important",
      textDecoration: "underline"
    }
  }),
  formContainer: css({
    width: "100%",

    "@media all and (min-width: 834px)": {
      width: "500px"
    }
  }),
  messageContainer: css({
    marginTop: "30px"
  }),
  message: css({
    display: "block",
    width: "100%",
    margin: "0 0 20px 0",
    height: "150px",
    border: "1px solid $mercury",
    borderRadius: "4px",
    padding: "10px 15px",
    outline: "none",
    fontSize: "18px",
    lineHeight: "20px"
  }),
  buttonContainer: css({
    textAlign: "right",
    marginTop: "30px",
    display: "flex",
    justifyContent: "space-between"
  }),
  cancelButton: css({
    marginRight: "15px !important",
    maxWidth: "150px"
  }),
  submitButton: css({
    maxWidth: "150px"
  })
};
