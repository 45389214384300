import { animations, css, withBem } from "../../sitches.config";

export const styles = withBem("loading-icon", {
  loadingIcon: css({
    display: "flex",
    justifyContent: "center",
    variants: {
      widthSize: {
        max: {
          width: "100%"
        },
        auto: {
          width: "auto"
        }
      }
    }
  }),
  wrapper: css({
    animation: `${animations.rotate} 1000ms infinite linear`,
    "& path": {
      stroke: "$robbinsEggBlue"
    },
    variants: {
      currentColor: {
        true: {
          "& path": {
            stroke: "currentColor"
          }
        }
      }
    }
  })
});
