import React, { useEffect, useRef, useState, useMemo } from "react";

import { GroupScheduleCheckoutView } from "./GroupScheduleCheckout.view";
import { GroupCheckoutSchema } from "./GroupScheduleCheckout.schema";
import { StripePaymentProvider } from "../StripePayment";
// @ts-ignore
import { DiscountNatures, Timezones } from "@obby/constants";
import {
  UTCDate__Add,
  UTCDate__Get,
  UTCDate__IsSameOrAfter,
  UTCDate__IsSameOrBefore,
  UTCDate__Set
  // @ts-ignore
} from "@obby/lib";
import { useFormState } from "../../hooks/useFormState";
import { useDiscount } from "../../hooks/useDiscount";
import _ from "lodash";

export function GroupScheduleCheckout({
  cancellationPolicyUrl = "/terms",
  course,
  discount,
  isNewsletterSignupEnabled = true,
  onConfirmFreeBooking,
  onGetPaymentIntent,
  onLogin,
  onStepChange,
  onValidateDiscount,
  stripePublicKey,
  ticketTypes,
  unavailableTimeBlocks = [],
  mandatoryTickBox = false,
  ...props
}: Props) {
  const timezone = getTimezone();

  const [state, setState] = useState(() => {
    return {
      isLastStep: false,
      isDiscountEnabled: false,
      user: props.user
    };
  });
  const { isDiscountEnabled, isLastStep, user } = state;
  let sortedQuestions = _.sortBy(course.userQuestionForm.questions, ["order"]);
  const {
    values,
    errors,
    touched,
    isValid,
    setFieldValue,
    setFieldTouched,
    submit
  } = useFormState({
    schema: GroupCheckoutSchema(course.requiresSendingPhysicalMaterial),
    values: {
      // dateTime: {
      //   date: "2023-02-20T23:00:00.000Z",
      //   time: 780
      // },
      inputs: course.userInputs.map(() => ""),
      userInputsValues: sortedQuestions.map(() => ""),
      personalDetails: {
        guests: [
          {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: ""
          }
        ]
      }
    }
  });

  const {
    discountCode,
    applyCode,
    getDiscount,
    getDiscountPercentage,
    isDiscountInvalid
  } = useDiscount(() => getTotalGross(), onValidateDiscount, discount);

  const credit = getCredit();
  const startDateTime = getStartDateTime(
    values.dateTime.date,
    values.dateTime.time
  );
  const selectedTicketType = getSelectedTicketType();
  const eligibleSlots = getEligibleSlots(
    course.groupScheduleSlots,
    startDateTime,
    timezone
  );
  const eligibleTicketTypes = getEligibleTicketTypes(
    ticketTypes,
    eligibleSlots
  );

  useEffect(() => {
    if (eligibleTicketTypes.length === 1)
      setFieldValue("ticket", {
        numberOfGuests: eligibleTicketTypes[0].minTicketsPerOrder,
        ticketTypeId: eligibleTicketTypes[0]._id
      });
  }, [startDateTime]);

  function getBookingData() {
    let { personalDetails, ticket, inputs } = values;

    const { guests, deliveryAddress } = personalDetails;
    const [guest, ...additionalGuests] = guests;
    let userInputsToSend: any = [];
    if (course.userQuestionForm.isEnabled) {
      //@ts-ignore
      userInputsToSend = sortedQuestions
        .filter((question, index) => {
          return (
            values.userInputsValues[index] &&
            values.userInputsValues[index] != ""
          );
        })
        .map((question, index) => ({
          _id: question._id,
          label: question.title,
          description: question.title,
          value: values.userInputsValues[index]
        }));
    }

    return {
      bookingType: "anytime",
      scheduleType: "group",
      tickets: [
        {
          ...selectedTicketType,
          count: ticket.numberOfGuests
        }
      ],
      user: {
        _id: user?._id,
        profile: {
          firstName: guest.firstName,
          lastName: guest.lastName,
          phone: guest.phoneNumber
        },
        renderEmail: guest.email
      },
      marketingOptIn: guest.marketingOptIn,
      courseId: course._id,
      startDateTime,
      additionalGuests: additionalGuests,
      discountCode: credit ? "" : discountCode,
      credit,
      utm: "", //@TODO
      isRegular: false,
      sessionid: "",
      deliveryAddress: course.requiresSendingPhysicalMaterial
        ? deliveryAddress
        : null,
      timezone,
      userInputs: userInputsToSend
    };
  }

  function getCredit() {
    const totalGross = getTotalGross();

    if (
      totalGross < 3000 ||
      !user?.profile?.credit ||
      discount?.nature === DiscountNatures.SALES
    )
      return 0;

    return Math.min(totalGross, user.profile.credit);
  }

  function getSelectedTicketType() {
    const { ticketTypeId } = values.ticket;
    return ticketTypes.find(
      (ticketType: any) => ticketType._id === ticketTypeId
    );
  }

  function getTimezone() {
    return course.isOnline
      ? Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? course.timezone
      : course.timezone;
  }

  function getTotalGross() {
    const { numberOfGuests } = values.ticket;
    return numberOfGuests && selectedTicketType
      ? numberOfGuests * selectedTicketType.price
      : 0;
  }

  function getTotal() {
    return getTotalGross() - (getCredit() || getDiscount());
  }

  async function onApplyDiscount(code: string) {
    const isValid = await submit("discountCode");
    if (isValid) await applyCode(code, values.personalDetails.guests[0].email);
  }

  function onBlur(name: string) {
    setFieldTouched(name);
  }

  function onChange(value: any, name: string) {
    setFieldValue(name, value);
  }

  function onCheckoutStepChange(index: number) {
    let userDetailsIndex = 3;
    let lastStepIndex = 4;
    setState(state => ({
      ...state,
      isDiscountEnabled: !discount && index === userDetailsIndex,
      isLastStep: index === lastStepIndex
    }));
    onStepChange && onStepChange();
  }

  function onConfirmFree() {
    onConfirmFreeBooking(getBookingData());
  }

  async function onPersonalDetailsEnter() {
    let user = state.user ? { ...state.user } : undefined;

    if (!user && onLogin)
      try {
        user = await onLogin();
        console.info(state.isDiscountEnabled);
        setState(state => ({
          ...state,
          user
        }));
      } catch (e) {}
    if (user) {
      const { firstName = "", lastName = "", phone = "" } = user.profile;
      const [{ address = "" } = {}] = user.emails || [];
      setFieldValue("personalDetails.guests.0", {
        ...values.personalDetails[0],
        firstName,
        lastName,
        phoneNumber: phone,
        email: address
      });
    }
  }

  return (
    <StripePaymentProvider
      publicKey={stripePublicKey}
      onGetPaymentIntent={onGetPaymentIntent}
    >
      <GroupScheduleCheckoutView
        errors={errors}
        cancellationPolicyUrl={cancellationPolicyUrl}
        course={course}
        credit={getCredit()}
        discount={discount}
        getBookingData={getBookingData}
        isDiscountEnabled={isDiscountEnabled}
        isLastStep={isLastStep}
        isUserDiscountInvalid={isDiscountInvalid()}
        isValid={isValid}
        isNewsletterSignupEnabled={isNewsletterSignupEnabled}
        onApplyDiscount={onApplyDiscount}
        onBlur={onBlur}
        onChange={onChange}
        onCheckoutStepChange={onCheckoutStepChange}
        onConfirmFree={onConfirmFree}
        onPersonalDetailsEnter={onPersonalDetailsEnter}
        slots={eligibleSlots}
        startDateTime={startDateTime}
        ticketType={selectedTicketType}
        ticketTypes={eligibleTicketTypes}
        timezone={timezone}
        total={getTotal()}
        touched={touched}
        unavailableTimeBlocks={unavailableTimeBlocks}
        userDiscountValue={getDiscount()}
        userDiscountPercentage={getDiscountPercentage()}
        values={values}
        mandatoryTickBox={mandatoryTickBox}
      />
    </StripePaymentProvider>
  );
}

interface Props {
  cancellationPolicyUrl?: string;
  course: any;
  discount?: any;
  isNewsletterSignupEnabled?: boolean;
  onConfirmFreeBooking: (data: any) => void;
  onGetPaymentIntent: (
    data: any
  ) => Promise<{ clientSecret: string; successUrl: string }>;
  onLogin?: () => Promise<any>;
  onStepChange: () => void;
  onValidateDiscount: (code: string, email: string) => Promise<any>;
  stripePublicKey: string;
  ticketTypes: any;
  unavailableTimeBlocks?: any[];
  user?: any;
  mandatoryTickBox?: boolean;
}

function getEligibleSlots(slots: any, startDateTime: string, timezone: string) {
  if (!startDateTime) return [];
  const isoWeekday = UTCDate__Get(startDateTime, timezone, "isoWeekday");

  return slots.filter((slot: any) => {
    if (slot.isoWeekday !== isoWeekday) return false;

    const slotStartDateTime = UTCDate__Set(
      startDateTime,
      timezone,
      "hour",
      slot.startPeriod.hour,
      ["minute", slot.startPeriod.minute]
    );
    const slotEndDateTime = UTCDate__Set(
      startDateTime,
      timezone,
      "hour",
      slot.endPeriod.hour,
      ["minute", slot.endPeriod.minute]
    );

    return (
      UTCDate__IsSameOrBefore(slotStartDateTime, startDateTime) &&
      UTCDate__IsSameOrAfter(slotEndDateTime, startDateTime)
    );
  });
}

function getEligibleTicketTypes(ticketTypes: any[], slots: any[]) {
  return slots.reduce((eligibleTicketTypes: any[], slot: any) => {
    eligibleTicketTypes.push(
      ...slot.tickets.reduce((eligibleTicketTypes: any[], ticket: any) => {
        for (let ticketTypeId of ticket.ticketTypes)
          eligibleTicketTypes.push(
            ticketTypes.find(
              (ticketType: any) => ticketType._id === ticketTypeId
            )
          );

        return eligibleTicketTypes;
      }, [])
    );

    return eligibleTicketTypes;
  }, []);
}

function getStartDateTime(date?: Date, time?: number) {
  return date && time
    ? UTCDate__Add(date.toISOString(), time, "minutes")
    : undefined;
}
