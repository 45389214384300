import { css } from "../../sitches.config";

export const styles = {
  railCarousel: css({}),

  title: css({
    flex: "1",
    fontFamily: "$foundersGrotesk",
    fontWeight: "500",
    fontSize: "21px",
    color: "$ebonyClay",
    margin: "0",
    letterSpacing: "0.5px",
    WebkitFontSmoothing: "antialiased"
  }),

  titleSkeleton: css({
    width: "25%",
    minWidth: "250px"
  }),

  description: css({
    fontFamily: "$foundersGrotesk",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "$14",
    color: "$ebonyClay",
    opacity: "0.6",
    margin: "0",
    letterSpacing: "0.03em",
    lineHeight: "1.2",

    "@lg": {
      fontSize: "$18"
    }
  }),

  body: css({
    marginTop: "$24",
    display: "grid",
    gap: "$16",
    justifyItems: "center"
  }),

  header: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  }),

  nav: css({
    display: "none",

    "@sm": {
      display: "block"
    }
  }),

  navItem: css({
    display: "inline-flex",
    width: "27px",
    height: "27px",
    borderRadius: "50%",
    textAlign: "center",
    marginLeft: "7px",
    marginTop: "2.5px",
    cursor: "pointer",
    border: "2px solid $emeraldish",

    "&.rail-carousel__nav-item--disabled": {
      opacity: "0.5"
    }
  }),

  navIcon: css({
    display: "block",
    margin: "auto",
    fill: "$emeraldish"
  })
};
