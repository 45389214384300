import moment from "moment";
import { store } from "statorgfc";
import { Subject } from "rxjs";
import Router from "next/router";

import AuthenticationState from "./AuthenticationState";

import axios from "../config/axios";

const getDefaultModel = initialData => {
  return {
    school: {}
  };
};

const events = {
  mount: new Subject(),
  unmount: new Subject(),
  checkout: new Subject(),
  startCheckout: new Subject()
};

const subscriptions = {
  onMount: events.mount.subscribe(onMount),
  onUnmount: events.unmount.subscribe(onUnmount)
};

function onMount(data) {
  const model = store.get("school");
  store.set("school", { ...model, data: data });

  subscriptions.onStartCheckout = events.startCheckout.subscribe(
    onStartCheckout
  );

  trackSchoolPage();
}

function onUnmount() {}

function onReadyToCheckout() {
  events.checkout.next(false);
  subscriptions.checkout.unsubscribe();
  onSignInClose();
}

function onStartCheckout(forCheckout = true) {
  if (AuthenticationState.helpers.isSignedIn()) {
    events.checkout.next(true);
    subscriptions.checkout.unsubscribe();
  } else {
    subscriptions.signInSuccess = AuthenticationState.events.signInSuccess.subscribe(
      onReadyToCheckout
    );
    subscriptions.endSignIn = AuthenticationState.events.endSignIn.subscribe(
      onSignInClose
    );
    AuthenticationState.events.beginSignIn.next(forCheckout);
  }
}

function trackSchoolPage() {
  let dataTrack = {
    name: "School Page",
    path: window.location.pathname || "",
    referrer: document.referrer || "",
    title: document.title || "",
    url: window.location.href
  };
  if (window.rudderanalytics)
    window.rudderanalytics.page(dataTrack.name, dataTrack);
}

function onSignInClose() {
  subscriptions.signInSuccess.unsubscribe();
  subscriptions.endSignIn.unsubscribe();
}

export default {
  events,
  getDefaultModel
};
