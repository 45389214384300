import { atom } from "recoil";

export const userState = atom({
  key: "userState",
  default: {} // default value (aka initial value)
});

export const isCurrentUserTeacherState = atom({
  key: "isCurrentUserTeacherState",
  default: false
});

export const schoolState = atom({
  key: "school",
  default: {}
});

export const contactObbyPopupIsOpen = atom({
  key: "contactObbyPopupIsOpen",
  default: false
});

export const contactObbyPopupData = atom({
  key: "contactObbyPopupData",
  default: {}
});
