import React from "react";

import { PrimaryButton } from "../PrimaryButton";
import { CloudinaryImage } from "../CloudinaryImage";

import styles from "./join-banner.module.less";

export function JoinBanner(props) {
  const {
    title,
    description,
    image,
    imageAlign = "left",
    buttonText,
    onButtonClick,
    buttonColor
  } = props;

  return (
    <div className={styles["join-banner"]}>
      <div className="row">
        {image && (
          <div
            className={`col-12 col-smd-5 order-0 ${
              imageAlign === "right" ? "order-smd-1 offset-smd-1" : ""
            }`}
          >
            <div className={styles["join-banner__image-wrapper"]}>
              <CloudinaryImage
                className={styles["join-banner__image"]}
                src={image}
                width={670}
              />
            </div>
          </div>
        )}
        <div className="col-12 offset-smd-1 col-smd-5 order-0">
          <div className={styles["join-banner__info"]}>
            <h1 className={styles["join-banner__title"]}>{title}</h1>
            <div className={styles["join-banner__description"]}>
              {(Array.isArray(description) ? description : [description]).map(
                (paragraph, index) => (
                  <p
                    key={index}
                    className={styles["join-banner__description-paragraph"]}
                  >
                    {paragraph}
                  </p>
                )
              )}
            </div>
            {buttonText && (
              <PrimaryButton
                className="mt-4"
                text={buttonText}
                width="auto"
                onClick={onButtonClick}
                color={buttonColor}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

JoinBanner.propTypes = {};
