import { css } from "../../sitches.config";

export const styles = {
  toggle: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer"
  }),
  wrapper: css({
    flex: 1
  }),
  tick: css({
    transition: "transform 250ms ease",
    transform: "rotate(-90deg)",
    variants: {
      open: { true: { transform: "rotate(90deg)" } }
    }
  })
};
