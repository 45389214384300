import { css, withBem } from "../../sitches.config";

export const styles = withBem("checkbox", {
  checkbox: css({
    position: "relative",
    display: "inline-flex",
    // alignItems: "center",
    margin: 0,
    cursor: "pointer",
    color: "$white",
    gap: "0.5em",
    variants: {
      disabled: {
        true: {
          color: "$silver",
          cursor: "default"
        }
      },
      size: {
        small: {
          fontSize: "$14"
        },

        medium: {
          fontSize: "$16"
        },

        large: {
          fontSize: "$18"
        }
      }
    }
  }),
  label: css({
    position: "relative",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    variants: {
      isDisabled: {
        true: {
          cursor: "default"
        }
      }
    }
  }),

  input: css({
    position: "absolute",
    opacity: 0,
    zIndex: -1
  }),

  uncheckedBox: css({
    flex: "0 0 1.5em",
    height: "1.5em",
    width: "1.5em",
    borderRadius: 4,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid $mercury",
    backgroundColor: "white",
    color: "white",
    transition: "all 300ms",
    variants: {
      disabled: {
        true: {
          backgroundColor: "$mercury",
          borderColor: "$mercury"
        }
      }
    }
  }),

  checkedBox: css({
    display: "inline-flex",
    backgroundColor: "var(--box-color)",
    flex: "0 0 1em",
    fontSize: "1.5em",
    border: "solid 4px var(--box-color)",
    color: "$white",
    borderRadius: "$1",
    variants: {
      disabled: {
        true: {
          backgroundColor: "$mercury",
          borderColor: "$mercury"
        }
      }
    }
  }),

  text: css({
    marginTop: "$1",
    color: "$ebonyClay",
    fontFamily: "$foundersGrotesk",
    fontSize: "inherit",
    fontWeight: 400,
    variants: {
      disabled: {
        true: {
          color: "$disabled"
        }
      }
    }
  })
});
