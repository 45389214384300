import React, { useEffect } from "react";
import { CheckoutStepsHeader } from "./CheckoutStepsHeader";

export function CheckoutStepsContent({
  children,
  description,
  onEnter,
  title
}: React.PropsWithChildren<Props>) {
  useEffect(() => {
    onEnter && onEnter();
  }, []);

  return (
    <div>
      <CheckoutStepsHeader title={title} description={description} />
      {children}
    </div>
  );
}

interface Props {
  description: string;
  onEnter?: () => void;
  title: string;
}
