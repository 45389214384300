import React from "react";
import styles from "./form-template.module.less";

const FormSection = props => (
  <div className={styles["form-template__section"]}>{props.children}</div>
);

const FormSectionColumn = props => (
  <div className={styles["form-template__section-column"]}>
    {props.children}
  </div>
);

const FormSectionTitle = props => (
  <div className={styles["form-template__section-title"]}>{props.children}</div>
);

const FormHeading = props => (
  <h4
    className={`
      ${styles["form-template__heading"]}
      ${styles[`form-template__heading--${props.weight || "bold"}`]}
    `}
  >
    {props.children}
  </h4>
);

const FormParagraph = props => (
  <div className={styles["form-template__paragraph"]}>{props.children}</div>
);

const FormInfoBox = props => (
  <div
    className={`
      ${styles["form-template__info-box"]}
      ${styles[`form-template__info-box--${props.color || "azalea"}`]}
    `}
  >
    {props.children}
  </div>
);

export {
  FormSection,
  FormSectionColumn,
  FormSectionTitle,
  FormHeading,
  FormParagraph,
  FormInfoBox
};
