// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Box } from "../../layout/Box";
import { CenteredPopup } from "../../modals/CenteredPopup";
import { CheckoutFooterBar } from "../../bars/CheckoutFooterBar";
import { CheckoutPanel } from "../../panels/CheckoutPanel";
import { CheckoutSteps } from "../../navs/CheckoutSteps";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import { SessionDateList } from "../../components/SessionDateList";
import {
  Session__DisplayDateTimeOffset,
  UTCDate__FormattedDate
  // @ts-ignore
} from "@obby/lib";
import { styles } from "./BookingReschedulePanel.styles";
import { PricingOptionsStep } from "../../containers/AppointmentScheduleCheckout/PricingOptionStep";
import moment from "moment-timezone";

export function BookingReschedulePanelEntryView({
  booking,
  course,
  cancellationPolicyUrl,
  canCancel,
  error,
  errorCode,
  isSuccessfullySubmitted,
  isSubmitting,
  locations,
  onCancelBooking,
  onContinue,
  onErrorPopupClose,
  onLogin,
  onSessionSelect,
  onSuccessPopupClose,
  selectedSession,
  session,
  sessions,
  tutors,
  rescheduleAppointmentValues,
  setRescheduleAppointmentValues,
  pricingOptions,
  unavailableTimeBlocks,
  timezone
}: Props) {
  const [appointmentOption, setPppointmentOption] = useState(0);
  const [appointmentDate, setAppointmentDate] = useState(
    moment(session.startDateTime)
  );
  const [appointmentTime, setAppointmentTime] = useState(null);

  useEffect(() => {
    setRescheduleAppointmentValues({
      option: appointmentOption,
      date: appointmentDate,
      time: appointmentTime
    });
  }, [appointmentOption, appointmentDate, appointmentTime]);

  if (errorCode === "USER_NOT_LOGGED_IN") {
    return (
      <div className="container-fluid">
        <Box>
          <h1 className={styles.title()}>
            You need to login/sign up in order to reschedule.
          </h1>
          <p className={styles.paragraph()}>
            In order for you to reschedule this booking you need to login or
            create an account using the same email address you used to book.
          </p>
          <div className="text-center">
            <PrimaryButton
              text="Login/Sign up"
              width="auto"
              onClick={onLogin}
            />
          </div>
        </Box>
      </div>
    );
  }

  if (errorCode === "CANCELLATION_POLICY_ISSUE") {
    return (
      <div className="container-fluid">
        <Box>
          <h1 className={styles.title()}>Unable to reschedule this booking.</h1>
          <p className={styles.paragraph()}>
            This booking cannot be rescheduled due to our cancellation policy.{" "}
            <a href={cancellationPolicyUrl}>Click here</a> to view our full
            terms of booking.
          </p>
        </Box>
      </div>
    );
  }

  if (
    errorCode === "BOOKING_RESCHEDULE_NOTENOUGH_TIME" ||
    errorCode === "BOOKING_RESCHEDULE_TERM_ALREADY_STARTED"
  ) {
    return (
      <div className="container-fluid">
        <Box>
          <h1 className={styles.title()}>Unable to reschedule this booking.</h1>
          <p className={styles.paragraph()}>
            This booking cannot be rescheduled automatically, please get in
            touch with us by replying to your email and we will be able to
            assist you further.
          </p>
        </Box>
      </div>
    );
  }
  if (errorCode === "BOOKING_ALREADY_REFUNDED") {
    return (
      <div className="container-fluid">
        <Box>
          <h1 className={styles.title()}>Unable to reschedule this booking.</h1>
          <p className={styles.paragraph()}>
            This booking can’t be rescheduled as it has already been refunded.
            Please book and pay again to attend.
          </p>
        </Box>
      </div>
    );
  }
  if (errorCode === "CANCELLATION_POLICY_ISSUE") {
    return (
      <div className="container-fluid">
        <Box>
          <h1 className={styles.title()}>Unable to reschedule this booking.</h1>
          <p className={styles.paragraph()}>
            This booking cannot be rescheduled due to our cancellation policy{" "}
            <a href="https://obby.helpscoutdocs.com/article/187-i-need-to-reschedule-my-booking">
              here.
            </a>
          </p>
        </Box>
      </div>
    );
  }

  function getSessionLocation(session: any) {
    if (session.isOnline) return "Online";
    const location = locations!.find(
      location => location._id === session.locationId
    );
    return location?.area;
  }

  return (
    <div>
      <CheckoutSteps.Provider onContinue={onContinue}>
        <CheckoutPanel
          title={course.title}
          description={
            session
              ? Session__DisplayDateTimeOffset(session, booking.timezone)
              : undefined
          }
          thumbnail={course.thumbnail}
          currency={booking.currencyUsed}
          items={booking.tickets}
          total={booking.total}
        >
          <CheckoutSteps>
            <CheckoutSteps.Step
              breadcrumb="Date selection"
              title="Reschedule your booking"
              disabled={isSubmitting || selectedSession === undefined}
              nextButtonText="Reschedule"
            >
              {booking.bookingType == "anytime" && (
                <div className="mb-4">
                  <PricingOptionsStep
                    preventIsEditingPricingOptions={true}
                    course={course}
                    errors={""}
                    onBlur={() => {}}
                    onChange={(value, name) => {
                      if (name == "time") {
                        setAppointmentTime(value);
                      }
                      if (name == "date") {
                        setAppointmentDate(value);
                      }
                    }}
                    options={pricingOptions}
                    timezone={timezone}
                    touched={""}
                    unavailableTimeBlocks={unavailableTimeBlocks}
                    values={{
                      option: appointmentOption,
                      time: appointmentTime,
                      date: appointmentDate
                    }}
                  />
                </div>
              )}
              {booking.bookingType == "regular" && (
                <SessionDateList
                  currency={booking.currencyUsed}
                  sessions={sessions!.map(session => {
                    const tutor = tutors!.find(
                      tutor => tutor._id === session.tutor
                    );
                    const location = locations!.find(
                      location => location._id === session.locationId
                    );
                    return {
                      _id: session._id,
                      date: session.startDateTime,
                      description:
                        session.availability === 0
                          ? "Sold out"
                          : `${session.availability} ${
                              session.availability === 1 ? "spot" : "spots"
                            } left`,
                      duration: session.duration,
                      location: session.isOnline ? "Online" : location?.area,
                      selected: session._id === selectedSession?._id,
                      disabled: isSubmitting,
                      tutorName: tutor?.fullName,
                      tutorImageUrl: tutor?.profile?.imageUrl,
                      tutorUrl: tutor?.profile?.links?.bio
                    };
                  })}
                  selection={selectedSession ? [selectedSession._id] : []}
                  onChange={([sessionId]: string[]) =>
                    onSessionSelect(sessionId)
                  }
                  timezone={booking.timezone}
                />
              )}

              {canCancel ? (
                <div className={styles.box()}>
                  {(sessions!.length > 0 ||
                    booking.bookingType == "anytime") && (
                    <div className={styles.boxText()}>
                      None of these dates work for you? You can cancel your
                      booking and get a full refund.
                    </div>
                  )}
                  {sessions!.length === 0 &&
                    booking.bookingType != "anytime" && (
                      <div className={styles.boxText()}>
                        Looks like there aren't any dates left for your ticket
                        type. Does your current date not work for you? If so you
                        can cancel your booking and get a full refund.
                      </div>
                    )}
                  <div>
                    <PrimaryButton
                      text="Cancel Booking"
                      onClick={onCancelBooking}
                    />
                  </div>
                </div>
              ) : (
                <div className={styles.box()}>
                  <div className={styles.boxText()}>
                    Our{" "}
                    <a href="/terms" target="_blank">
                      cancellation policy
                    </a>{" "}
                    does not allow you to cancel a booking.
                  </div>
                </div>
              )}
            </CheckoutSteps.Step>
          </CheckoutSteps>
        </CheckoutPanel>
        <CheckoutFooterBar
          label="Date Selected"
          value={
            selectedSession &&
            UTCDate__FormattedDate(
              selectedSession.startDateTime,
              booking.timezone,
              "dddd, Do MMMM"
            )
          }
        >
          <CheckoutSteps.Navigation />
        </CheckoutFooterBar>
      </CheckoutSteps.Provider>
      {isSuccessfullySubmitted && (
        <CenteredPopup
          title="You've rescheduled your class"
          onAccept={onSuccessPopupClose}
          acceptButtonText="Close"
        >
          New date:{" "}
          {Session__DisplayDateTimeOffset(selectedSession, booking.timezone)},{" "}
          {getSessionLocation(selectedSession)}
        </CenteredPopup>
      )}
      {error && (
        <CenteredPopup title="Unable to reschedule" onClose={onErrorPopupClose}>
          {error}
        </CenteredPopup>
      )}
    </div>
  );
}

interface Props {
  booking?: any;
  course?: any;
  cancellationPolicyUrl: string;
  canCancel?: boolean;
  error?: string;
  errorCode: string | false;
  isSuccessfullySubmitted: boolean;
  isSubmitting: boolean;
  locations?: any[];
  onCancelBooking: () => void;
  onContinue: () => void;
  onErrorPopupClose: () => void;
  onLogin?: () => void;
  onSessionSelect: (sessionId: string) => void;
  onSuccessPopupClose: () => void;
  selectedSession?: any;
  session: any;
  sessions?: any[];
  tutors?: any[];
  rescheduleAppointmentValues?: any;
  setRescheduleAppointmentValues?: any;
  pricingOptions?: any;
  unavailableTimeBlocks?: any;
  timezone?: any;
}
