import React from "react";

import { bemModule } from "../../configs/bem";
import { PrimaryButton } from "../../components/PrimaryButton";
import styles from "./CourseAccordionListAdd.module.less";

const cn = bemModule(styles, "course-accordion-list-add");
export function CourseAccordionListAdd(props) {
  const { text, buttonText, onAdd } = props;
  return (
    <div className={cn()}>
      <div className={cn("text")}>{text}</div>
      <PrimaryButton
        text={buttonText}
        size="small"
        width="auth"
        onClick={onAdd}
      />
    </div>
  );
}
