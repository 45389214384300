import { css, withBem } from "./../sitches.config";

export const styles = {
  buttonWrapper: css({
    flex: "0 0 36px"
  }),
  etc: css({
    fontFamily: "$foundersGrotesk",
    color: "$textColor",
    flex: "0 0 36px",
    textAlign: "center"
  })
};
