import * as Yup from "yup";
export function ProductEmailFormSchema(defaults = {}) {
	defaults = {
		purchaseMessage: "",
		...defaults
	};

	return Yup.object({
		purchaseMessage: Yup.string()
			.label("Post Purchase Message")
			.max(4000)
			.default(defaults.purchaseMessage)
	});
}
