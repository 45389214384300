export function Location__GoogleMapsLink(address) {
  if (address.mapLink) {
    return address.mapLink;
  } else {
    const addressSegment = [
      address.line1,
      ...(address.line2 ? [address.line2] : []),
      ...(address.postCode ? [address.postCode] : []),
      address.city,
      address.country || "United Kingdom"
    ].join(" ");

    return `https://www.google.co.uk/maps/place/${addressSegment}`;
  }
}
