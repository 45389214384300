import React from "react";
import { Indexing } from "@obby/components";
import { styles } from "./TableRow.styles";

export function TableRow({
  children,
  onClick
}: React.PropsWithChildren<Props>) {
  return (
    <tr className={styles.tableRow()} onClick={onClick}>
      <Indexing>{children}</Indexing>
    </tr>
  );
}

interface Props {
  onClick?: () => void;
}
