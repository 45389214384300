import { useState, useMemo } from "react";
import { createContainer } from "../../configs/createContainer";
import { useFormState } from "../../hooks/useFormState";
import { ProductCheckoutSchema } from "./ProductCheckout.schema";
import { useDiscount } from "../../hooks/useDiscount";
import { DiscountNatures } from "@obby/constants";
import { useStripePayment } from "../StripePayment";
import _ from "lodash";

function useProductSelectionPopupContainer({
  product,
  discount,
  postages,
  school,
  isAGift = false,
  isNewsletterSignupEnabled,
  logoUrl,
  onClose,
  onConfirmFreePurchase,
  onLogin,
  onValidateDiscount,
  isSendingMaterial = true,
  ...initialState
}) {
  const [state, setState] = useState(() => {
    return {
      isConfirmingFreeBooking: false,
      isLastStep: false,
      isDiscountEnabled: false,
      user: initialState.user
    };
  });

  const {
    isConfirmingFreeBooking,
    isDiscountEnabled,
    isLastStep,
    user
  } = state;
  const [isUserFormValid, setIsUserFormValid] = useState(false);

  const stripePayment = useStripePayment();
  // @TODO This need to be at products as well.
  let sortedQuestions = _.sortBy(product.userQuestionForm.questions, ["order"]);
  const {
    errors,
    isValid,
    setFieldValue,
    setFieldTouched,
    touched,
    values,
    submit
  } = useFormState({
    schema: ProductCheckoutSchema(isSendingMaterial),
    values: {
      quantity: 1,
      postageId: postages.length === 1 ? postages[0]._id : undefined,
      inputs: product.userInputs.map(() => ""),
      userInputsValues: sortedQuestions.map(() => ""),
      isGift: isAGift,
      personalDetails: {
        guests: [
          {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: ""
          }
        ]
      }
    }
  });

  const {
    discountCode,
    applyCode,
    getDiscount,
    getDiscountPercentage,
    isDiscountInvalid
  } = useDiscount(
    () => product.price * values.quantity,
    onValidateDiscount,
    discount
  );

  const postage = postages.find(postage => postage._id === values.postageId);
  const totalGross =
    (product.price + (postage?.price || 0)) * values.quantity +
    (values.isGift ? product.gifting?.giftingFee ?? 0 : 0);

  const credit = useMemo(() => {
    if (
      totalGross < 3000 ||
      !user?.profile?.credit ||
      discount?.nature === DiscountNatures.SALES
    )
      return 0;
    return Math.min(totalGross, user.profile.credit);
  }, [user, totalGross]);

  function getPurchaseData() {
    const { personalDetails, giftingDetails, isGift, inputs } = values;
    let userInputsToSend = [];
    if (product.userQuestionForm.isEnabled) {
      //@ts-ignore
      userInputsToSend = sortedQuestions
        .filter((question, index) => {
          return (
            values.userInputsValues[index] &&
            values.userInputsValues[index] != ""
          );
        })
        .map((question, index) => ({
          _id: question._id,
          label: question.title,
          description: question.title,
          value: values.userInputsValues[index]
        }));
    }
    return {
      giftingDetails: {
        message: giftingDetails.message,
        to: giftingDetails.to,
        from: giftingDetails.from,
        giftingFeeUsed: isGift ? product.gifting?.giftingFee ?? 0 : 0
      },
      cancelURL: window.location.href,
      bookingType: "product",
      isFree: getTotal() === 0,
      user: {
        _id: user?.id,
        profile: {
          firstName: personalDetails.guests[0].firstName,
          lastName: personalDetails.guests[0].lastName,
          phone: personalDetails.guests[0].phoneNumber
        },
        renderEmail: personalDetails.guests[0].email
      },
      deliveryAddress: personalDetails.deliveryAddress,
      product,
      quantity: values.quantity,
      school,
      postage,
      userInputs: userInputsToSend,
      discountCode,
      credit,
      isAGift: isGift
    };
  }

  function getTotal() {
    const discount = credit || getDiscount();
    return totalGross - discount;
  }

  async function onApplyDiscount(code) {
    const isValid = await submit("discountCode");
    if (isValid) await applyCode(code, values.personalDetails.guests[0].email);
  }

  async function onCardPaymentEnter() {
    await stripePayment.getPaymentIntent(getPurchaseData());
  }

  function onContinue() {
    if (isFree()) {
      setState(state => ({ ...state, isConfirmingFreeBooking: true }));
      onConfirmFreePurchase(getPurchaseData());
    } else stripePayment.submitPayment();
  }

  function onCheckoutStepChange(index) {
    let userDetailsIndex = 2;
    let lastStepIndex = 3;
    setState(state => ({
      ...state,
      isDiscountEnabled: !discount && index === userDetailsIndex,
      isLastStep: index === lastStepIndex
    }));
  }

  function onChange(value, name) {
    setFieldValue(name, value);
  }

  function isSelectionValid() {
    return values.quantity > 0 && values.postageId;
  }

  function isFree() {
    return getTotal() === 0;
  }

  async function onPersonalDetailsEnter() {
    let user = state.user ? { ...state.user } : undefined;

    if (!user && onLogin)
      try {
        user = await onLogin();
        setState(state => ({
          ...state,
          user
        }));
      } catch (e) {}

    if (user) {
      const { firstName = "", lastName = "", phone = "" } = user.profile;
      const [{ address = "" } = {}] = user.emails || [];
      setFieldValue("personalDetails.guests.0", {
        ...values.personalDetails[0],
        firstName,
        lastName,
        phoneNumber: phone,
        email: address
      });
    }
  }

  function onBlur(name) {
    setFieldTouched(name);
  }

  return {
    credit,
    school,
    product,
    postage,
    postages,
    errors,
    touched,
    values,
    isConfirmingFreeBooking,
    isDiscountEnabled,
    isLastStep,
    isNewsletterSignupEnabled,
    isStripePaymentReady: stripePayment.isReady,
    logoUrl,
    isUserFormValid,
    helpers: {
      getDiscount,
      getDiscountPercentage,
      getTotal,
      isDiscountInvalid,
      isFree,
      isSelectionValid,
      isValid
    },
    actions: {
      onApplyDiscount,
      onCardPaymentEnter,
      onCheckoutStepChange,
      onClose,
      onContinue,
      onBlur,
      onChange,
      onPersonalDetailsEnter,
      setIsUserFormValid
    }
  };
}

const ProductSelectionPopupContainer = createContainer(
  useProductSelectionPopupContainer
);
export { ProductSelectionPopupContainer };
