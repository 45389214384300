import React from "react";
import styles from "./GiftCardBanner.module.less";
import { bem } from "../../configs/bem";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import { CloudinaryImage } from "../../components/CloudinaryImage";

const cn = bem("gift-card-banner");
export function GiftCardBanner(props) {
  const { title, description, image, buttonText, onButtonClick } = props;
  console.log("styelews ", styles);
  return (
    <div className={styles[cn()]}>
      <div className="row">
        <div className="col-12 col-smd-6 order-smd-1">
          <div className={cn("col-wrapper")}>
            <CloudinaryImage className={styles[cn("image")]} src={image} />
          </div>
        </div>
        <div className="col-12 col-smd-6">
          <div className={styles[cn("col-wrapper")]}>
            <div className={styles[cn("title")] + " my-3 mt-smd-0"}>
              {title}
            </div>
            <div className={styles[cn("description")]}>{description}</div>
            <div className={styles[cn("actions")] + " mt-3"}>
              <PrimaryButton
                text={buttonText}
                color="astra"
                width="auto"
                onClick={onButtonClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
