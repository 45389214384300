import React from "react";
import _ from "lodash";
import { CourseCheckoutContainer } from "./CourseCheckout.container";
import { TicketTypesStep } from "./TicketTypesStep";
import { DateSelectionStep } from "./DateSelectionStep";
import { CheckoutFooterBar } from "../../bars/CheckoutFooterBar";
import { CheckoutPanel } from "../../panels/CheckoutPanel";
import { CheckoutPersonalDetailsForm } from "../../forms/CheckoutPersonalDetailsForm";
import { CheckoutSteps } from "../../navs/CheckoutSteps";
import { WaitingListCheckoutBanner } from "../../components/WaitingListCheckoutBanner";
import { StripePayment } from "../StripePayment";
import { UTCDate__FormattedDate } from "@obby/lib";
import { useAppText } from "../AppText";
import { UserFormQuestionary } from "../UserFormQuestionary";

export function CourseCheckoutView() {
  const {
    course,
    actions,
    mandatoryTickBox,
    availability,
    cancellationPolicyUrl,
    courseTitle,
    courseThumbnail,
    credit,
    courseType,
    currency,
    errors,
    helpers,
    isConfirmingFreeBooking,
    isDiscountEnabled,
    isLastStep,
    isOnline,
    isSendingMaterial,
    isStripePaymentReady,
    isNewsletterSignupEnabled,
    selectedSession,
    showBundleTicketsNote,
    showTrialTicketNote,
    ticketTypes,
    touched,
    values,
    isOnSales,
    isSoldOut,
    timezone,
    source,
    isUserFormValid,
    allowPrefillOthersGuestsOnCheckout
  } = CourseCheckoutContainer.useContainer();

  const { __ } = useAppText();

  const tickets = ticketTypes
    .map((ticketType, index) => ({
      ...ticketType,
      count: values.tickets[index]
    }))
    .filter(ticketType => ticketType.count > 0);

  function getTicketNumber(index) {
    const ticketNumberMap = values.tickets.reduce(
      (ticketNumberMap, ticket, index) => {
        const ticketNumbers = _.range(1, ticket + 1).reduce(
          (ticketNumbers, ticketNumber) => [
            ...ticketNumbers,
            ...Array(ticketTypes[index].numberOfGuestsPerTicket).fill(
              ticketNumber
            )
          ],
          []
        );
        return [...ticketNumberMap, ...ticketNumbers];
      },
      []
    );
    return ticketNumberMap[index];
  }

  function getTicketType(seat) {
    const indexMap = values.tickets.reduce(
      (indexMap, ticket, index) => [
        ...indexMap,
        ...Array(ticket * ticketTypes[index].numberOfGuestsPerTicket).fill(
          index
        )
      ],
      []
    );
    const ticketIndex = indexMap[seat];
    return ticketTypes[ticketIndex];
  }

  function getGuestLabel(index) {
    const ticketType = getTicketType(index);
    const number = getTicketNumber(index);
    return {
      description: `${ticketType.name} #${number}`
    };
  }

  function getCourseTypeDescription() {
    if (courseType === "course") {
      return "Choose the dates you wish to attend this course.";
    } else {
      return __("Choose a date you wish to attend this class.");
    }
  }

  return (
    <CheckoutSteps.Provider
      onContinue={actions.onContinue}
      onNext={actions.onCheckoutStepChange}
      onBeforeNext={actions.onCheckoutBeforeStepChange}
      onPrevious={actions.onCheckoutStepChange}
    >
      <div className="course-checkout-popup-view">
        <div className="container-fluid mb-2 mb-md-0">
          <CheckoutSteps.Breadcrumbs />
        </div>
        <CheckoutPanel
          source={source}
          availability={availability}
          cancellationPolicyUrl={cancellationPolicyUrl}
          title={courseTitle}
          thumbnail={courseThumbnail}
          credit={credit}
          currency={currency}
          description={helpers.getFormattedDates().map((date, index) => (
            <div key={index}>{date}</div>
          ))}
          discountError={touched.discountCode && errors.discountCode}
          discountValue={helpers.getDiscount()}
          discountPercentage={helpers.getDiscountPercentage()}
          isBundleEnabled={showBundleTicketsNote}
          isTrialEnabled={showTrialTicketNote}
          isDiscountEnabled={isDiscountEnabled}
          isDiscountInvalid={helpers.isDiscountInvalid()}
          isOnline={isOnline}
          isOnSales={isOnSales}
          onApplyDiscount={actions.onApplyDiscount}
          onSideNoteClick={actions.onViewBundlesClick}
          onViewBundlesClick={actions.onViewBundlesClick}
          onViewTrialClick={actions.onViewTrialClick}
          personalDetails={
            isLastStep ? values.personalDetails.guests[0] : undefined
          }
          sidebarOnTop={isLastStep}
          items={tickets}
          total={helpers.getTotal()}
          selectedSession={selectedSession}
        >
          <CheckoutSteps>
            <CheckoutSteps.Step
              breadcrumb="Date selection"
              title="Choose a date"
              description={getCourseTypeDescription()}
              disabled={selectedSession === undefined}
              pass={selectedSession !== undefined}
            >
              {isSoldOut && (
                <WaitingListCheckoutBanner
                  title="This Class is Sold Out"
                  onOpen={() => actions.openWaitingListPopup()}
                >
                  You can join a waiting list for a particular session below, or
                  we can let you know when new dates become available.
                </WaitingListCheckoutBanner>
              )}
              <DateSelectionStep />
            </CheckoutSteps.Step>
            <CheckoutSteps.Step
              breadcrumb="Tickets"
              title="Tickets"
              disabled={!helpers.isValid("tickets")}
            >
              <TicketTypesStep />
            </CheckoutSteps.Step>
            <CheckoutSteps.Step
              breadcrumb=" Customer Information"
              title=" Customer Information"
              description="Please provide the necessary information so that we can continue with your booking."
              disabled={
                !(course.userQuestionForm.isEnabled == false || isUserFormValid)
              }
              hidden={!course.userQuestionForm.isEnabled}
            >
              <UserFormQuestionary
                values={values}
                onChange={actions.onChange}
                mode={course.userQuestionForm.mode}
                questions={course.userQuestionForm.questions}
                onFormValidation={actions.setIsUserFormValid}
              />
            </CheckoutSteps.Step>

            <CheckoutSteps.Step
              breadcrumb="Personal details"
              title="Who's coming?"
              disabled={
                !helpers.isValid("personalDetails") || isConfirmingFreeBooking
              }
              onEnter={() => actions.onPersonalDetailsEnter()}
              nextButtonText={helpers.isFree() ? "Confirm booking" : undefined}
            >
              <CheckoutPersonalDetailsForm
                allowPrefillOthersGuestsOnCheckout={
                  allowPrefillOthersGuestsOnCheckout
                }
                errors={errors?.personalDetails}
                onChange={(value, name) =>
                  actions.onChange(value, `personalDetails.${name}`)
                }
                onBlur={name => actions.onBlur(`personalDetails.${name}`)}
                isSendingMaterial={isSendingMaterial}
                disableMarketingOptIn={!isNewsletterSignupEnabled}
                getGuestLabel={getGuestLabel}
                values={values.personalDetails}
                touched={touched?.personalDetails}
                mandatoryTickBox={mandatoryTickBox}
              />
            </CheckoutSteps.Step>
            <CheckoutSteps.Step
              breadcrumb="Card payment"
              title="Card payment"
              nextButtonText="Pay now"
              onEnter={actions.onCardPaymentEnter}
              disabled={!isStripePaymentReady}
              hidden={helpers.isFree()}
            >
              <StripePayment />
            </CheckoutSteps.Step>
          </CheckoutSteps>
        </CheckoutPanel>
        <CheckoutFooterBar
          label="Date Selected"
          value={
            selectedSession &&
            UTCDate__FormattedDate(
              selectedSession.startDateTime,
              timezone,
              "dddd, Do MMMM"
            )
          }
        >
          <CheckoutSteps.Navigation total={helpers.getTotal()} />
        </CheckoutFooterBar>
      </div>
    </CheckoutSteps.Provider>
  );
}
