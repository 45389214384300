export default function Number__NumberText(number, capitalize = false) {
  const ones = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine"
  ];
  const tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety"
  ];
  const teens = [
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen"
  ];

  let text = "";
  if (number === 0) {
    text = "zero";
  } else if (number < 10) {
    text = ones[number];
  } else if (number >= 10 && number < 20) {
    text = teens[number - 10];
  } else {
    text = tens[Math.floor(number / 10)] + " " + ones[number % 10];
  }

  if (capitalize) text = text.charAt(0).toUpperCase() + text.slice(1);

  return text;
}
