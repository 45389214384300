import { css, withBem } from "./../sitches.config";

export const styles = withBem("table-header-cell", {
  tableHeaderCell: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$18",
    padding: "$2",
    fontWeight: 500,
    backgroundColor: "$gallery",
    border: "solid 0 var(--table-border-color)",
    borderTopWidth: "1px",
    "&:first-child": {
      borderLeftWidth: "1px"
    },
    "&:last-child": {
      borderRightWidth: "1px"
    },
    cursor: "default",
    variants: {
      left: {
        true: {
          textAlign: "left"
        }
      },
      disabled: {
        true: {
          color: "$disabled"
        }
      }
    }
  }),
  sort: css({
    height: "9px",
    fontSize: "$18",
    overflow: "hidden",
    cursor: "pointer",
    variants: {
      selected: {
        true: {
          color: "$ebonyClay"
        },
        false: {
          color: "$silver"
        }
      }
    }
  }),
  sortUp: css({
    display: "block",
    marginBottom: "-9px"
  }),
  sortDown: css({
    display: "block",
    marginTop: "-9px"
  })
});
