// These will only be reported to teachers/partners when the source is the widget
// as those purchased on the marketplace will not generate income for the partner.

import { Number__Currency } from "./Number_Currency";
import { CurrencyISO__CurrencySymbol } from "./CurrencyISO__CurrencySymbol";
import { Timezones } from "@obby/constants";
import { UTCDate__FormattedDate } from "./UTCDate__FormattedDate";

// the orderITem need to have the order as populate as well
export function OrderItem__FinancialReportingOrderItemRow(
  orderItem: any,
  timezone = Timezones.EUROPE_LONDON,
  isRefunded = false
) {
  const {
    _id,
    partnerMoneyStatus,
    partnerMoneyTransfer,
    createdAt
  } = orderItem;
  console.log("order itemn", orderItem);
  const order = orderItem.order;

  return {
    id: _id,
    class: order.displayName,
    date: UTCDate__FormattedDate(createdAt, timezone, "D MMMM YYYY"),
    ticket: "",
    name: order.buyerName,
    quantity: 1,
    type: order.orderType,
    source: "secure",
    // @TODO we dont have a refunded object
    grossTotal: !isRefunded
      ? orderItem.accounting.rawEarning
      : orderItem.partnerMoneyTransfer?.reverseAmount,
    discount: orderItem.accounting.discountAmountUsed,
    //@TODO we dont have a refunded object
    netPay: !isRefunded
      ? orderItem.accounting.netPay
      : orderItem.partnerMoneyTransfer?.reverseAmount,
    paymentProcessing: orderItem.accounting.paymentProcessing,
    obbyFee: orderItem.accounting.obbyFee,
    vat: orderItem.accounting.vat,
    isMultiple: false, //@TODO his make sense?
    allTickets: [], // is this make sense?
    partnerMoneyStatus,
    // TODO: Manual transfer date?
    transferDate:
      partnerMoneyStatus === "transfered"
        ? UTCDate__FormattedDate(
            partnerMoneyTransfer.transferDate,
            timezone,
            "D MMMM YYYY"
          )
        : "-",
    currencyUsed: orderItem.currencyUsed
  };
}
