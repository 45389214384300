import React, { useState } from "react";
import PropTypes from "prop-types";

import { DatePickerInput } from "../DatePickerInput";

export function CourseDateFilter(props) {
  const { date, onChange, fullScreen = false, position } = props;
  return (
    <div className="date-picker-input">
      <DatePickerInput
        name="date"
        date={date}
        format="YYYY-MM-DD"
        onChange={onChange}
        fullScreen={fullScreen}
        position={position}
      />
    </div>
  );
}

CourseDateFilter.propTypes = {
  date: PropTypes.string,
  onChange: PropTypes.func
};
