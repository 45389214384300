import React, { MutableRefObject, useEffect, useState, useRef } from "react";

import Icon from "../../components/Icon";
import { Cross } from "../../components/Icons";
import { FaIcon } from "../../icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark";
import { styles } from "./TextInput.styles";

export function TextInput({
  accent,
  autoComplete,
  autoFocus = false,
  className = "",
  cleaner,
  compound,
  disabled,
  faIcon,
  icon,
  iconAlign = "left",
  inputRef = useRef(),
  max,
  name,
  onBlur = () => {},
  onChange = () => undefined,
  onFocus = () => {},
  onReset = () => {},
  onSelect = () => {},
  onSubmit = () => {},
  placeholder,
  prefix,
  required = false,
  readOnly = false,
  size = "medium",
  suffix,
  textAlign = "left",
  type = "text",
  width = "full",
  min,
  maxValue,
  ...props
}: TextInput.Props) {
  const [value, setValue] = useState(props.value ?? "");
  // updates the state when the value from props changes
  useEffect(() => {
    setValue(props.value ?? "");
  }, [props.value]);

  function onCleanerClick() {
    setValue("");
    onChange("", name!);
    onReset("", name!);
  }

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    if (onChange(value, name!) !== false) setValue(value);
  }

  function onKeyPress(e: React.KeyboardEvent) {
    if (e.key === "Enter") onSubmit(value, name!);
  }
  return (
    <div
      className={styles.textInput({
        compound,
        disabled,
        width,
        size,
        className,
        readOnly,
        css: { ...(accent ? { "--accent": `$colors$${accent}` } : {}) }
      })}
      onClick={() => inputRef?.current.focus()}
    >
      <input
        min={min}
        max={maxValue}
        ref={inputRef}
        className={styles.input({
          readOnly,
          textAlign
        })}
        name={name}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        maxLength={max}
        onChange={onInputChange}
        onKeyPress={onKeyPress}
        onSelect={() => onSelect(name!)}
        onBlur={() => onBlur(name!)}
        onFocus={() => onFocus(name!)}
        required={required}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        readOnly={readOnly}
      />
      {icon && (
        <Icon className={styles.icon({ size, iconAlign })} icon={icon} />
      )}
      {faIcon && (
        <FaIcon
          className={styles.faIcon({ size, iconAlign })}
          icon={faIcon}
          color="silver"
        />
      )}
      {prefix && <div className={styles.prefix()}>{prefix}</div>}
      {suffix && <div className={styles.suffix()}>{suffix}</div>}
      {max && (
        <div className={styles.characterCount()}>
          {value.length} / {max}
        </div>
      )}
      {!!value && cleaner && !readOnly && !disabled && (
        <FaIcon
          icon={faXmark}
          className={styles.cleaner({
            size
          })}
          color="disabled"
          onClick={onCleanerClick}
        />
      )}
    </div>
  );
}

export namespace TextInput {
  export interface Props {
    name?: string;
    onBlur?: (name: string) => void;
    onChange?: (value: any, name: string) => boolean | void;
    onFocus?: (name: string) => void;
    onReset?: (value: "", name: string) => void;
    onSelect?: (name: string) => void;
    onSubmit?: (value: any, name: string) => void;
    accent?: string | false;
    autoComplete?: string;
    autoFocus?: boolean;
    className?: string;
    cleaner?: boolean;
    compound?: "left" | "middle" | "right";
    disabled?: boolean;
    faIcon?: FaIcon.Icon;
    /** @deprecated - use fontawesome (faIcon) icon instead */
    icon?: any;
    iconAlign?: "left" | "right";
    inputRef?: MutableRefObject<any>;
    max?: number;
    placeholder?: string;
    prefix?: string;
    readOnly?: boolean;
    required?: boolean;
    size?: "small" | "medium";
    suffix?: string;
    textAlign?: "left" | "center" | "right";
    type?: string;
    value?: any;
    width?: "full" | "auto" | "compact";
    min?: number;
    maxValue?: number;
  }
}
