import { css } from "../../sitches.config";

export const styles = {
  classSublist: css({
    ".class-sublist__list-group + .class-sublist__list-group": {
      paddingTop: "80px"
    },

    ".class-sublist__list-item + .class-sublist__list-item": {
      marginTop: "24px",

      "@sm": {
        marginTop: "32px"
      }
    },

    ".class-sublist__class-link, .class-sublist__class-link:link, .class-sublist__class-link:visited, .class-sublist__class-link:hover, .class-sublist__class-link:active": {
      color: "inherit",
      textDecoration: "none"
    }
  }),
  listGroup: css({
    paddingBottom: "22px",
    borderBottom: "1px solid $silverMalibu",

    "@sm": {
      paddingBottom: "32px"
    }
  }),

  title: css({
    fontFamily: "$foundersGroteskCondensed",
    fontWeight: "bold",
    fontSize: "28px",
    lineHeight: "100%",
    letterSpacing: "0.01em",
    textTransform: "uppercase",
    color: "$ebonyClay"
  }),

  list: css({
    marginTop: "30px"
  })
};
