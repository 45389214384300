// Lib
import CBookingRunningStatus from "./CBookingRunningStatus";
import Booking__Duration from "./Booking__Duration";
import CurrentTimeAndBooking__MinutesElapsedSinceStarted from "./CurrentTimeAndBooking__MinutesElapsedSinceStarted";
import CurrentTimeAndBooking__MinutesUntilBookingStarts from "./CurrentTimeAndBooking__MinutesUntilBookingStarts";

const CurrentTimeAndBooking__BookingRunningStatus = (currentTime, booking) => {
  var duration = Booking__Duration(booking);
  var minutesUntilBookingStarts = CurrentTimeAndBooking__MinutesUntilBookingStarts(
    currentTime,
    booking
  );
  var minutesElapstedSinceStart = CurrentTimeAndBooking__MinutesElapsedSinceStarted(
    currentTime,
    booking
  );

  if (minutesUntilBookingStarts <= 10 && minutesElapstedSinceStart === 0) {
    return CBookingRunningStatus.STARTING_SOON;
  }

  if (minutesElapstedSinceStart > 0 && minutesElapstedSinceStart < duration) {
    return CBookingRunningStatus.RUNNING;
  }

  if (minutesElapstedSinceStart >= duration) {
    return CBookingRunningStatus.FINISHED;
  }

  return CBookingRunningStatus.NOT_RUN;
};

export default CurrentTimeAndBooking__BookingRunningStatus;
