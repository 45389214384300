import React from "react";
import SecondaryButton from "../SecondaryButton";
import LabelInput from "../LabelInput";
import { PrimaryButton } from "../PrimaryButton";
import Checkbox from "../Checkbox";
import styles from "./SignUpWithEmailForm.module.less";

export default class SignUpWithEmailForm extends React.Component {
  constructor(props) {
    super(props);
  }

  onKeyDown(e) {
    if (e.key === "Enter") this.props.onSubmitSignUp();
  }

  render() {
    return (
      <div className={styles["sign-up-with-email-form"]}>
        <div className={styles["sign-up-with-email-form__body"]}>
          <div className={styles["sign-up-with-email-form__title"]}>
            Book faster by signing up
          </div>

          <div
            className={styles["sign-in-form__form"]}
            onKeyDown={this.onKeyDown.bind(this)}
          >
            <div className={styles["sign-in-form__form-input"]}>
              <LabelInput
                type="text"
                placeholder="First name"
                value={this.props.firstName}
                error={this.props.errors.inputs.firstName}
                onChange={this.props.onTypeFirstName}
              />
            </div>
            <div className={styles["sign-in-form__form-input"]}>
              <LabelInput
                type="text"
                placeholder="Last name"
                value={this.props.lastName}
                error={this.props.errors.inputs.lastName}
                onChange={this.props.onTypeLastName}
              />
            </div>
            <div className={styles["sign-in-form__form-input"]}>
              <LabelInput
                type="email"
                placeholder="Email address"
                value={this.props.email}
                error={this.props.errors.inputs.email}
                onChange={this.props.onTypeEmail}
              />
            </div>
            <div className={styles["sign-in-form__form-input"]}>
              <LabelInput
                type="password"
                placeholder="Password"
                value={this.props.password}
                error={this.props.errors.inputs.password}
                onChange={this.props.onTypePassword}
              />
            </div>
            {!this.props.hideNewsletter && (
              <div className={styles["sign-in-form__form-input"]}>
                <Checkbox
                  isChecked={this.props.isSignedUpToNewsletter}
                  label="Sign up for an Obby newsletter"
                  color="camelot"
                  size="small"
                  onClick={this.props.onToggleNewsletterSignUp}
                />
              </div>
            )}

            <div className={styles["sign-in-form__form-button"]}>
              <PrimaryButton
                text="Sign up"
                onClick={this.props.onSubmitSignUp}
              />
            </div>
            {this.props.errors.general && (
              <div className="sign-in-form__form-error">
                {this.props.errors.general}
              </div>
            )}
          </div>
        </div>

        {/* FOOTER */}
        <div className={styles["sign-up-with-email-form__footer"]}>
          <span className={styles["sign-up-with-email-form__footer-text"]}>
            Already have an account?
          </span>
          <span className={styles["sign-up-with-email-form__footer-button"]}>
            <SecondaryButton
              text="Log in"
              size="small"
              onClick={this.props.onBeginSignIn}
            />
          </span>
        </div>
      </div>
    );
  }
}
