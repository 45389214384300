import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import Label from "../../components/Label";
import { Box } from "../../layout/Box";
import { PersonalDetailsForm } from "../PersonalDetailsForm";
import { DeliveryAddressForm } from "../DeliveryAddressForm";
import { CheckoutPersonalDetailsFormSchema } from "./CheckoutPersonalDetailsForm.schema";
import { Checkbox } from "../../inputs/Checkbox";

export function CheckoutPersonalDetailsForm({
  disableMarketingOptIn = false,
  errors,
  onBlur,
  onChange,
  isSendingMaterial,
  getGuestLabel = () => ({}),
  values,
  touched,
  mandatoryTickBox = false,
  allowPrefillOthersGuestsOnCheckout = false
}: CheckoutPersonalDetailsForm.Props) {
  const [prefillOthers, setPrefillOthers] = useState(false);
  useEffect(() => {
    if (!prefillOthers) return;
    values.guests.forEach((guest, index) => {
      if (index == 0) {
        return;
      }
      // fullfill firstname
      onChange(`guest${index}_firstname`, `guests.${index}.firstName`);
      onChange(`guest${index}_lastname`, `guests.${index}.lastName`);
      onChange(`noreply@baluu.co.uk`, `guests.${index}.email`);
      onChange("123456789", `guests.${index}.phoneNumber`);
    });
  }, [prefillOthers]);
  return (
    <div className="checkout-personal-details-form">
      {values.guests.map((guest, index) => {
        const {
          label = index === 0 ? "Your Details" : `Guest ${index + 1}`,
          description
        } = getGuestLabel(index);
        if (prefillOthers && index > 0) {
          return null;
        }
        return (
          <div className="mb-4 mb-last-0" key={index}>
            <Box borderRadius={2}>
              <Label label={label} description={description} condensed>
                <PersonalDetailsForm
                  disableMarketingOptIn={disableMarketingOptIn}
                  values={guest}
                  touched={touched?.guests?.[index] || {}}
                  errors={errors?.guests?.[index] || {}}
                  onChange={(value, name) =>
                    onChange(value, `guests.${index}.${name}`)
                  }
                  onBlur={name => onBlur(`guests.${index}.${name}`)}
                  guest={guest.guest}
                  mandatoryTickBox={mandatoryTickBox}
                />
              </Label>
              {allowPrefillOthersGuestsOnCheckout &&
                values.guests.length > 1 &&
                index == 0 && (
                  <Label>
                    <Checkbox
                      value={prefillOthers}
                      label={
                        "I do not want to provide the guest information at this time."
                      }
                      onChange={setPrefillOthers}
                    />
                  </Label>
                )}
            </Box>
          </div>
        );
      })}

      {isSendingMaterial && (
        <>
          <Label
            label="Delivery Address"
            description="The delivery charge is included within the price.
                              Please enter the address you would like your
                              materials sent to. They will arrive within 5 days
                              of your class start date."
            condensed
          />
          <Box borderRadius={2}>
            <DeliveryAddressForm
              values={values?.deliveryAddress!}
              touched={touched?.deliveryAddress}
              errors={errors?.deliveryAddress}
              onChange={(value, name) => {
                onChange(value, `deliveryAddress.${name}`);
              }}
              onBlur={name => onBlur(`deliveryAddress.${name}`)}
            />
          </Box>
        </>
      )}
    </div>
  );
}
CheckoutPersonalDetailsForm.Schema = CheckoutPersonalDetailsFormSchema;

namespace CheckoutPersonalDetailsForm {
  export type Fields = Yup.InferType<
    ReturnType<typeof CheckoutPersonalDetailsFormSchema>
  >;

  export interface Props {
    errors?: any;
    disableMarketingOptIn?: boolean;
    getGuestLabel?: (index: number) => { label?: string; description?: string };
    isSendingMaterial: boolean;
    onBlur: (name: string) => void;
    onChange: (value: any, name: string) => void;
    touched?: any;
    values: Fields;
    mandatoryTickBox?: boolean;
    allowPrefillOthersGuestsOnCheckout?: boolean;
  }
}
