import React from "react";
import { bemModule } from "../../configs/bem";
import styles from "./Tips.module.less";
import { TipsItem } from "./TipsItem";
import { TipsExample } from "./TipsExample";

import { AccordionBox } from "../AccordionBox";

const cn = bemModule(styles, "tips");
export function Tips(props) {
  const { children, open, title = "Tips" } = props;

  return (
    <AccordionBox className={cn()} open={open}>
      <AccordionBox.Toggle className={cn("toggle")} tick>
        <div className={cn("title")}>{title}</div>
      </AccordionBox.Toggle>
      <AccordionBox.Container className={cn("content")}>
        <ul className={cn("wrapper")}>{children}</ul>
      </AccordionBox.Container>
    </AccordionBox>
  );
}

Tips.Item = TipsItem;
Tips.Example = TipsExample;
