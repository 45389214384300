import { css, withBem } from "./../sitches.config";

export const styles = withBem("table-row", {
  tableRow: css({
    "& > .table-cell": {
      border: "solid 0 var(--table-border-color)",
      borderTopWidth: "1px",
      "&:first-child": {
        borderLeftWidth: "1px"
      },
      "&:last-child": {
        borderRightWidth: "1px"
      }
    },
    "&:last-child > .table-cell": {
      borderBottomWidth: "1px"
    }
  })
});
