import MarkdownIt from "markdown-it";

export function Markdown__TruncatedMarkdown(markdown, maxWords = 100) {
  const markdownIt = new MarkdownIt();
  const html = markdownIt.render(markdown).trim();

  let numberOfWords = maxWords;
  for (; numberOfWords > 0; numberOfWords--) {
    // truncate the text to the specified number of words
    const [truncatedMarkdown] = markdown.match(
      `^(\\W*\\w*){0,${numberOfWords}}`
    ) || [""];
    const truncatedHtml = markdownIt.render(truncatedMarkdown).trim();
    // extracts the last closing tags to get the text added by markdownIt
    const [closingTags] = truncatedHtml.match("(\\W*<\\/[a-z1-6]+>)+$") || [""];
    // If this is true means the truncate did not break the markdown syntax.
    // In order to compare we need to slice the closing tags too. those closing
    // tags are added by markdownIt, so we don't want them to interfere with the comparison
    if (
      truncatedHtml
        .slice(0, truncatedHtml.length - closingTags.length)
        .trim() ===
      html.slice(0, truncatedHtml.length - closingTags.length).trim()
    )
      return truncatedMarkdown;
  }
  return markdown;
}
