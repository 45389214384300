import React from 'react';
import { useState } from 'react';

export default (numberOfColumns, items) => {
	const ItemsAndNumberOfColumns__NumberOfPages = (items, numberOfColumns) => {
		if (items.length % numberOfColumns === 0) {
			return items.length / numberOfColumns;
		}
		return Math.floor(items.length / numberOfColumns) + 1;
	};

	const getNumberOfPages = () => {
		if (items.length > numberOfColumns) {
			return ItemsAndNumberOfColumns__NumberOfPages(items, numberOfColumns);
		}
		return 1;
	};

	const numberOfPages = getNumberOfPages();
	const [state, setState] = useState({
		visiblePageIndex: 0,
	});

	const currentItems = state.visiblePageIndex * numberOfColumns;
	const visibleItems = items.slice(
		currentItems,
		currentItems + numberOfColumns
	);

	const atMax = () => {
		return state.visiblePageIndex + 1 === numberOfPages;
	};

	const atMin = () => {
		return state.visiblePageIndex === 0;
	};

	const getNextPageInLoop = (max, currentIndex) => {
		if (max === currentIndex) {
			return 0;
		}
		return currentIndex + 1;
	};

	const getPreviousPageInLoop = (max, currentIndex) => {
		if (currentIndex === 0) {
			return max;
		}
		return currentIndex - 1;
	};

	const next = () => {
		var visiblePageIndex = getNextPageInLoop(
			numberOfPages - 1,
			state.visiblePageIndex
		);
		setState({
			...state,
			visiblePageIndex,
		});
	};

	const previous = () => {
		var visiblePageIndex = getPreviousPageInLoop(
			numberOfPages - 1,
			state.visiblePageIndex
		);
		setState({
			...state,
			visiblePageIndex,
		});
	};

	return [next, previous, visibleItems, atMax, atMin];
};
