import React from "react";
import { Star } from "../Icons";
import Icon from "../Icon";

import { Rating__RoundedRating } from "@obby/lib";
import { css } from "../../sitches.config";

export default function RatingSummary(props) {
  const {
    rating,
    /** @deprecated - use numberOfReviews instead */
    reviewCount,
    numberOfReviews = reviewCount,
    /** @deprecated - use starColor instead */
    primaryColor = "robbinsEggBlue",
    starColor = primaryColor,
    short = false,
    starSize = "medium"
  } = props;

  const iconWidths = {
    medium: 18,
    small: 13
  };

  return (
    <div className={styles.ratingSummary()}>
      <Icon
        className={css({
          polygon: {
            fill: `$${starColor}`
          }
        })}
        icon={Star}
        width={iconWidths[starSize]}
      />
      <span
        className={styles.rating({
          size: starSize
        })}
      >
        {Rating__RoundedRating(rating)}
      </span>
      {numberOfReviews && (
        <span
          className={styles.reviews({
            size: starSize
          })}
        >
          ({numberOfReviews}
          {!short ? (numberOfReviews > 1 ? " reviews" : " review") : ""})
        </span>
      )}
    </div>
  );
}

const styles = {
  ratingSummary: css({
    display: "inline-flex",
    alignItems: "center",
    letterSpacing: "0.05em",
    lineHeight: "$18",
    fontSize: "$smParagraph"
  }),

  rating: css({
    fontFamily: "$foundersGrotesk",
    fontWeight: "500",
    color: "$ebonyClay",

    variants: {
      size: {
        small: {
          marginLeft: "0.5ch"
        },
        medium: {
          marginLeft: "1ch"
        }
      }
    }
  }),
  reviews: css({
    fontFamily: "$foundersGrotesk",
    fontWeight: "500",
    color: "$gray",

    variants: {
      size: {
        small: {
          marginLeft: "0.5ch"
        },
        medium: {
          marginLeft: "1ch"
        }
      }
    }
  })
};
