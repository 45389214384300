import { createStitches } from "@stitches/react";
import type * as StitchesTypes from "@stitches/react";
import {withNaming} from "@bem-react/classname";
// @ts-ignore
import {String__KebabCase} from "@obby/lib";

function pxToRem(px: number): string {
  //indexed by the pixel value based on 16px
  const rem: number = px / 16;
  return `${rem}rem`;
}

const pixelsUnits = {
  8: pxToRem(8),
  10: pxToRem(10),
  12: pxToRem(12),
  14: pxToRem(14),
  15: pxToRem(15),
  16: pxToRem(16),
  18: pxToRem(18),
  22: pxToRem(22),
  20: pxToRem(20),
  24: pxToRem(24),
  26: pxToRem(26),
  28: pxToRem(28),
  30: pxToRem(30),
  32: pxToRem(32),
  36: pxToRem(36),
  40: pxToRem(40),
  42: pxToRem(42),
  45: pxToRem(45),
  46: pxToRem(46),
  48: pxToRem(48),
  50: pxToRem(50),
  52: pxToRem(52),
  64: pxToRem(64),
};

export const { styled, getCssText, css, createTheme, keyframes, globalCss, config, theme } =
  createStitches({
    theme: {
      colors: {
        camelot: "#822d50",
        emerald: "#32c864",
        sun: "#faaa1e",
        // the following three colors are very similar. we barely notice a difference so we should opt to use just one of them
        robbinsEggBlue: "#00d2c8", //robbinsEggBlue
        brightTeal: "#00d8c4",
        lightTeal: "#76e6dd",
        lighterTeal: "#d6f6f3",
        lighterGray: "#f5f5f5",
        emeraldish: "#03c2b9",
        iceCold: "#BEF0DC",
        ebonyClay: "#28283c", //ebonyClay
        azalea: "#fadcdc",
        cruise: "#bff0db",
        astra: "#fafabe",
        rumSwizzle: "#e6e480", //rum-swizzle
        gallery: "#f0f0f0",
        lightGreen: "#c0f0dd", //lightGreen
        hummingBird: "#d7f0fa", //humming-bird
        hummingBird_faded: "#f0fafe", //humming-bird-faded
        silverChalice: "#a6a6a6", //silver-chalice
        catskillWhite: "#e1e9f0", //catskill-white
        baliHai: "#8298ab", //bali-hai
        frenchLilac: "#efe0f6", //french-lilac
        deepBlush: "#e07688", //deep-blush
        pink: "#fbdad8",
        gothic: "#6991a1",
        polarBlue: "#f0f9fc", //polar-blue
        prussian: "#304659",
        water: "#d5f5f3",
        sky: "#e1e9f0",
        tulipTree: "#f0ac44", //tulip-tree
        royalBlue: "#4945e2", //royal-blue
        lavender: "#cb87da",
        coral: "#ff774d",
        brightSun: "#ffcc43", //bright-sun
        aquamarineBlue: "#7ce5df", //aquamarine-blue
        jumbo: "#7b7b86",
        pampas: "#f8f7f5",
        darkGallery: "#eaeaea", //dark-gallery
        concrete: "#f2f2f2",
        sand: "#fbfbfb",
        blackSqueeze: "#f9fcfd", //black-squeeze
        athensGray: "#f4f4f4", //athens-gray
        alto: "#d8d8d8",
        gray: "#898989",
        venus: "#7e7e8a",
        dolphin: "#6b6a77",
        mercury: "#e6e6e6",
        textBlack: "#28283c", //text-black
        polar: "#eafbfa",
        tundora: "#484444",
        jet: "#3e3e50",
        silver: "#bdbdbd",
        smoke: "#bfbfbf",
        silverMalibu: "#c4c4c4", //silverMalibu
        gray_2: "#94949e", //gray-2
        boulder: "#757575",
        nickel: "#c7c7c7",
        heather: "#b8c5d0",
        white: "#fff",
        iron: "#dddde0",
        scorpion: "#595959",
        shark: "#272a2d",
        lightRed: "#e28989",
        lighterRed: "#ffcccc",
        red: "#de2828",
        blueLagoon: "#007986",

        // Theme colours - these can be overridden in an individual theme.
        primary: "$emeraldish",
        primaryLight: "$water",
        neutral: "$mercury",
        lightNeutral: "$athensGray",
        callToAction: "$camelot",
        callToActionLight: "$azalea",
        fontColour: "$ebonyClay",
        error: "$deepBlush",
        danger: "$camelot",
        disabled: "$silver",
        focusColour: "$emeraldish",
        outlineColour: "$neutral",

        accent: "$camelot",
        border: "$mercury",
        inputBorderColor: "$mercury",
        textColor: "$ebonyClay",
        inherit: "inherit"
      },
      space: {
        1: "0.25rem",
        2: "0.5rem",
        3: "1rem",
        4: "1.5rem",
        5: "3rem",
        mdHeaderHeight: pxToRem(73),
        lgHeaderHeight: pxToRem(90),
        topBanner: pxToRem(38),
        mdTop: pxToRem(111),
        lgTop: pxToRem(128),
        ...pixelsUnits,

        inputPaddingX: "$3"
      },
      fontSizes: {
        // indexed by the pixel value based on 16px
        /*
        10: pxToRem(10),
        12: pxToRem(12),
        14: pxToRem(14),
        16: pxToRem(16),
        18: pxToRem(18),
        20: pxToRem(20),
        24: pxToRem(24),
        28: pxToRem(28),
        32: pxToRem(32),
        40: pxToRem(40),
        48: pxToRem(48),
        */
        xxxsmParagraph: "0.625rem",
        xxsmParagraph: "0.75rem",
        xsmParagraph: "0.875rem",
        smParagraph: "1rem",
        mParagraph: "1.25rem",
        lParagraph: "1.35rem",
        mHeadingMobile: pxToRem(30),
        ...pixelsUnits,

        default: "1.125rem", // 18px,
        input: "1.125rem"
      },
      fonts: {
        system: "system-ui",
        foundersGrotesk: "Founders Grotesk, Arial, sans-serif",
        foundersGroteskCondensed:
          "Founders Grotesk Condensed, Arial Bold, sans-serif",
        foundersGroteskXCondensed:
          "Founders Grotesk X Condensed,Arial Narrow Bold,sans-serif",

        bodyFont: "$foundersGrotesk",
      },
      fontWeights: {
        1: 400,
        2: 500,
        3: 600,
        4: 700,
        regular: 400,
        medium: 500,
        semiBold: 600,
        bold: 700,
      },
      lineHeights: {
        1: 1,
        2: 1.25,
        3: 1.5,
        mHeadingLh: 1.2
      },
      letterSpacings: {},
      sizes: {
        sm: "576px",
        smd: "700px",
        md: "834px",
        lg: "1024px",
        xl: "1440px",
        lParagraph: "20px",
        lParagraphLh: "1.4",
        mParagraph: "18px",
        mParagraphLh: "1.4",
        smParagraph: "16px",
        smParagraphLh: "1.4",
        xsmParagraph: "14px",
        xsmParagraphLh: "1.2",
        xxsmParagraph: "12px",
        xxsmParagraphLh: "1.2",
        xxxsmParagraph: "10px",
        xxxsmParagraphLh: "1.2",

        inputMediumHeight: "2.8125rem", // 45px
        inputSmallHeight: "2.1875rem" // 35px;
      },
      borderWidths: {},
      borderStyles: {},
      radii: {
        1: "4px",
        2: "10px",
        3: "20px",
        pill: "99999px",
        standard: "1.5rem",

        input: "5px"
      },
      shadows: {
        card: "0 0 10px rgba(0,0,0, 0.1)",
        callToActionHighlight: "0 0 0 5px var(--colors-callToActionLight)"
      },
      zIndices: {
        searchPageFilters: 400,
        popout: 450,
        sticky: 500,
        menuHeader: 501,
        popup: 1000,
        datePicker: 1001,
        cookie: 2000,
        loader: 2200,
      },
      transitions: {},
    },
    utils: {
      //both sides padding
      paddingX: (value: StitchesTypes.PropertyValue<"padding">) => ({
        paddingLeft: value,
        paddingRight: value,
      }),
      paddingY: (value: StitchesTypes.PropertyValue<"padding">) => ({
        paddingTop: value,
        paddingBottom: value,
      }),
      marginX: (value: StitchesTypes.PropertyValue<"margin">) => ({
        marginLeft: value,
        marginRight: value,
      }),
      marginY: (value: StitchesTypes.PropertyValue<"margin">) => ({
        marginTop: value,
        marginBottom: value,
      }),
      borderLeftRadius: (
        value: StitchesTypes.PropertyValue<"borderTopLeftRadius">
      ) => ({
        borderTopLeftRadius: value,
        borderBottomLeftRadius: value,
      }),
      borderRightRadius: (
        value: StitchesTypes.PropertyValue<"borderTopLeftRadius">
      ) => ({
        borderTopRightRadius: value,
        borderBottomRightRadius: value,
      }),
      borderTopRadius: (
        value: StitchesTypes.PropertyValue<"borderTopLeftRadius">
      ) => ({
        borderTopRightRadius: value,
        borderTopLeftRadius: value,
      }),
      borderBottomRadius: (
        value: StitchesTypes.PropertyValue<"borderTopLeftRadius">
      ) => ({
        borderBottomRightRadius: value,
        borderBottomLeftRadius: value,
      }),
    },
    //media queries
    media: {
      xs: "(min-width: 380px)",
      sm: "(min-width: 576px)",
      smd: "(min-width: 700px)",
      md: "(min-width: 834px)",
      lg: "(min-width: 1024px)",
      xl: "(min-width: 1440px)",
      cini: "(min-width: 1660px)",

      // Dashboard only media queries.
      // Should avoid use if possible.
      bp1: "(min-width: 576px)",
      bp2: "(min-width: 768px)",
      bp3: "(min-width: 992px)",
      bp4: "(min-width: 1200px)",
    },
  });



export const animations = {
  fadeIn: keyframes({
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  }),
  fadeOut: keyframes({
    "0%": { opacity: 1 },
    "100%": { opacity: 0 },
  }),

  enterFromLeft: keyframes({
    "0%": { transform: "translateX(-5%)" },
    "100%": { transform: "none" },
  }),

  enterFromRight: keyframes({
    "0%": { transform: "translateX(5%)" },
    "100%": { transform: "none" },
  }),

  popout: keyframes({
    "0%": {
      transform: "matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "5.71%": {
      transform:
        "matrix3d(0.375, 0, 0, 0, 0, 0.375, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "11.31%": {
      transform:
        "matrix3d(0.635, 0, 0, 0, 0, 0.635, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "17.02%": {
      transform:
        "matrix3d(0.809, 0, 0, 0, 0, 0.809, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "22.62%": {
      transform:
        "matrix3d(0.913, 0, 0, 0, 0, 0.913, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "28.33%": {
      transform:
        "matrix3d(0.973, 0, 0, 0, 0, 0.973, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "33.93%": {
      transform:
        "matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "45.15%": {
      transform:
        "matrix3d(1.017, 0, 0, 0, 0, 1.017, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "72.57%": {
      transform:
        "matrix3d(1.004, 0, 0, 0, 0, 1.004, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "100%": {
      transform:
        "transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
  }),

  rotate: keyframes({
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  }),

  riseUp: keyframes({
    from: {
      transform: "translateY(7%)",
    },
    to: {
      transform: "none",
    },
  }),

  fallDown: keyframes({
    from: {
      transform: "none",
    },
    to: {
      transform: "translateY(7%)",
    },
  }),

  pop: keyframes({
    from: {
      transform: "scale(1)",
      boxShadow: "0 0 0 calc(1px / 1.05) rgba(63, 63, 68, 0.05), 0 1px calc(3px / 1.05) 0 rgba(34, 33, 81, 0.15)"
    },
    to: {
      transform: "scale(1.05)",
      boxShadow: "0 0 0 calc(1px / 1.05) rgba(63, 63, 68, 0.05), -1px 0 15px 0 rgba(34, 33, 81, 0.01), 0px 15px 15px 0 rgba(34, 33, 81, 0.25)"
    }
  })
};

export type CssComponent = ReturnType<typeof css>;
interface CssComponents {
  [key: string]: CssComponent;
}

const bem = withNaming({ e: "__", m: "--", v: "-" });
export function withBem<T extends CssComponents = CssComponents>(name: string, cssComponents: T): T {
  name = String__KebabCase(name);
  const cn = bem(name);
  return Object.entries(cssComponents).reduce(
    (cssComponents, [key, cssComponent]) => {
      (cssComponents as any)[key] = function(arg: any = {}) {
        const { className, css, ...variants } = arg;

        const modifiers = Object.entries(variants).reduce((modifiers, [key, value]) => {
          (modifiers as any)[String__KebabCase(key)] = value;
          return modifiers
        }, {});

        key = String__KebabCase(key)

        arg.className = key === name ? cn(modifiers, [className?.toString()]) : cn(key, modifiers, [className?.toString()]);
        return cssComponent(arg);
      };

      return cssComponents;
    },
    {}
  ) as T;
}


export type ClassName = string | CssComponent;
export type Breakpoint = keyof typeof config.media;
export type Breakpoints = `@${Breakpoint | "initial"}`;
export type ResponsiveVariant<T> = T | Partial<{
  [media in Breakpoints]: T;
}>;
