import React from "react";
// Components
import Icon from "../Icon";
import { styles } from "./IconLabel.styles";

export function IconLabel({
  icon,
  iconColor = "ebonyClay",
  iconSize = 20,
  label
}: Props) {
  return (
    <div className={styles.iconLabel({ className: "icon-label" })}>
      <div className={styles.iconWrapper()}>
        <Icon
          className={styles.icon({ css: { color: `$${iconColor}` } })}
          height={iconSize}
          icon={icon}
        />
      </div>
      <div className={styles.label()}>{label}</div>
    </div>
  );
}
/** @deprecated - use named export instead */
export default IconLabel;

interface Props {
  label: any;
  icon: any;
  iconSize?: number;
  iconColor?: string;
}
