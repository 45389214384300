import { UTCDate__FormattedDate } from "./UTCDate__FormattedDate";
import { UTCDate__IsBefore } from "./UTCDate__IsBefore";
export function Timezones__IsBefore(
  isoDate: string,
  timezone1: string,
  timezone2: string,
  granularity: Granularity
) {
  const date1 = UTCDate__FormattedDate(
    isoDate,
    timezone1,
    "YYYY-MM-DD[T]HH:mm:ss.000[Z]"
  );
  const date2 = UTCDate__FormattedDate(
    isoDate,
    timezone2,
    "YYYY-MM-DD[T]HH:mm:ss.000[Z]"
  );

  return UTCDate__IsBefore(date1, date2, granularity, "UTC");
}

type Granularity =
  | "year"
  | "years"
  | "y"
  | "month"
  | "months"
  | "M"
  | "week"
  | "weeks"
  | "w"
  | "day"
  | "days"
  | "d"
  | "hour"
  | "hours"
  | "h"
  | "minute"
  | "minutes"
  | "m"
  | "second"
  | "seconds"
  | "s"
  | "millisecond"
  | "milliseconds"
  | "ms";
