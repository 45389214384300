import { css, withBem } from "../../sitches.config";

export const styles = withBem("checkout-steps-breadcrumb", {
  root: css({
    fontFamily: "$foundersGrotesk",
    fontWeight: 500,
    fontSize: "$16",
    letterSpacing: "0.03em",
    color: "$gray",
    "&:after": {
      content: ">",
      margin: "0 5px",
      "@md": {
        margin: "0 15px 0 10px"
      }
    },
    "&:last-child:after": {
      content: ""
    },
    variants: {
      current: {
        true: {
          color: "$ebonyClay"
        }
      },
      active: {
        true: {
          cursor: "pointer"
        }
      }
    }
  })
});
