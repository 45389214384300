import React from "react";
import PropTypes from "prop-types";

import EmbededVideoPlayer from "../EmbededVideoPlayer";

import styles from "./media-card.module.less";

const MediaCard = ({ title, type, url, mediaWidth, mediaHeight }) => {
  return (
    <div className={styles["media-card"]}>
      <div className={styles["media-card__header"]}>
        <span className={styles["media-card__title"]}>{title}</span>
        <span className={styles["media-card__type"]}>- {type}</span>
      </div>
      <div className={styles["media-card__media-wrapper"]}>
        <EmbededVideoPlayer
          className={styles["media-card__media"]}
          url={url}
          width={mediaWidth}
          height={mediaHeight}
        />
      </div>
    </div>
  );
};

MediaCard.defaultProps = {
  mediaWidth: "544",
  mediaHeight: "306"
};

MediaCard.propTypes = {
  title: PropTypes.any.isRequired,
  type: PropTypes.oneOf(["video"]).isRequired,
  url: PropTypes.any.isRequired,
  mediaWidth: PropTypes.string,
  mediaHeight: PropTypes.string
};

export default MediaCard;
