import { css } from "../../sitches.config";

export const styles = {
  container: css({
    display: "flex",
    ".text-input__input": {
      borderRight: "none",
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    }
  }),

  button: css({
    padding: 0,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "$callToAction",
    border: "none",
    borderRadius: "none",
    borderRightRadius: 4,
    cursor: "pointer",
    color: "$white",
    variants: {
      size: {
        small: {
          width: "$inputSmallHeight",
          height: "$inputSmallHeight",
          fontSize: "16px"
        },
        medium: {
          width: "$inputMediumHeight",
          height: "$inputMediumHeight",
          fontSize: "18px"
        }
      }
    }
  })
};
