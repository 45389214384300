import React from "react";
import { Fields } from "./PersonalDetailsStep.schema";
import { Label } from "../../../components/Label";
import { Box } from "../../../layout/Box";
import { Block } from "../../../layout/Block";
import { PersonalDetailsForm } from "../../../forms";
import { ArrayInput } from "../../../inputs/ArrayInput";
import { styles } from "./PersonalDetailsStep.styles";
import { CheckoutSteps } from "../../../navs";
import { PricingOptionSectionHeader } from "../PricingOptionSectionHeader";
import { TextArea } from "../../../index";
export function PersonalDetailsStep({
  course,
  errors,
  isNewsletterSignupEnabled = true,
  onAddGuest,
  onBlur,
  onChange,
  onRemoveGuest,
  touched,
  values,
  mandatoryTickBox = false
}: Props) {
  const {
    maxNumberOfGuests,
    minNumberOfGuests
  } = course.appointmentScheduleBriefing;
  return (
    <div className="pricing-options-step">
      <PricingOptionSectionHeader
        heading="Personal details"
        description="Provide your booking information and any guest details."
      />
      <ArrayInput
        items={values.guests}
        addButtonLabel="Add guest"
        accent="emeraldish"
        onAdd={onAddGuest}
        onChangeItem={(value, name) => onChange(value, `guests.${name}`)}
        onBlurItem={name => onBlur(`guests.${name}`)}
        maxLength={maxNumberOfGuests}
      >
        {(guest, index, { onChange, onBlur }) => (
          <Block marginBottom={4} marginBottomLast={0}>
            <Box borderRadius={2} className={styles.box()}>
              <Label
                label={index === 0 ? "Your Details" : `Guest ${index + 1}`}
                condensed
              >
                <PersonalDetailsForm
                  values={guest}
                  touched={touched?.guests?.[index] || {}}
                  errors={errors?.guests?.[index] || {}}
                  onChange={onChange}
                  onBlur={onBlur}
                  guest={guest.guest}
                  disableMarketingOptIn={!isNewsletterSignupEnabled}
                  mandatoryTickBox={mandatoryTickBox}
                />
              </Label>
              {index > 0 && values.guests.length > minNumberOfGuests && (
                <div className={styles.removeButton()}>
                  <ArrayInput.RemoveItemButton onClick={onRemoveGuest} />
                </div>
              )}
            </Box>
          </Block>
        )}
      </ArrayInput>
    </div>
  );
}

interface Props {
  course: any;
  errors: any;
  isNewsletterSignupEnabled?: boolean;
  onAddGuest: () => void;
  onRemoveGuest: (index: number) => void;
  onBlur: (name: string) => void;
  onChange: (value: any, name: string) => void;
  touched: any;
  values: Fields;
  mandatoryTickBox?: boolean;
}
