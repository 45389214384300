import React from "react";
import { styles } from "./ClassGiftCard.styles";

export function ClassGiftCard(props) {
  return (
    <div className={styles.classGiftCard()}>
      <div className={styles.text()}>
        <div className={styles.price()}>{props.price}</div>
        {props.title && <div className={styles.title()}>{props.title}</div>}
        {props.description && (
          <div className={styles.description()}>{props.description}</div>
        )}
        {props.logoSrc && (
          <img alt="Obby logo" src={props.logoSrc} className={styles.logo()} />
        )}
      </div>
      <img src={props.image} className={styles.image()} />
    </div>
  );
}
