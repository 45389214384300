import React, { useEffect, useState } from "react";
import {
  comparator,
  TimeIntervals__Times,
  unique,
  UTCDate__Add,
  UTCDate__FormattedDate,
  UTCDate__Get,
  UTCDate__IsAfter,
  UTCDate__IsBefore,
  UTCDate__IsSameOrAfter,
  UTCDate__IsSameOrBefore,
  UTCDate__StartOf
  // @ts-ignore
} from "@obby/lib";

import { Fields } from "./GroupScheduleCheckout.schema";

import { Label } from "../../components/Label";
import { DayPickerInput } from "../../inputs/DayPickerInput";
import { SelectBox } from "../../inputs/SelectBox";

export function DateTimeStep({
  course,
  errors,
  onBlur,
  onChange,
  timezone,
  touched,
  unavailableTimeBlocks,
  values
}: Props) {
  const { groupScheduleBriefing } = course;

  const daysOfWeek = course.groupScheduleSlots
    .map((slot: any) => slot.isoWeekday % 7)
    .filter(unique)
    .sort(comparator);

  const [baseTimes, setBaseTimes] = useState(() =>
    getBaseTimes(groupScheduleBriefing.times, course.isOnline, timezone)
  );
  useEffect(() => {
    setBaseTimes(
      getBaseTimes(groupScheduleBriefing.times, course.isOnline, timezone)
    );
  }, [groupScheduleBriefing.times, timezone]);

  const [times, setTimes] = useState(baseTimes);

  useEffect(() => {
    if (values.date) {
      const times = baseTimes.filter((minutes: number) =>
        isTimeSchedulable(minutes, values.date?.toISOString())
      );
      setTimes(times);
    }
  }, [values.date, baseTimes]);

  useEffect(() => {
    if (values.time && !times.includes(values.time)) onChange(times[0], "time");
  }, [times]);

  function isTimeSchedulable(time: number, date?: string) {
    const day = UTCDate__StartOf(date, timezone, "day");

    let dateTime = UTCDate__Add(day, time, "minutes");
    const dayOfWeek = UTCDate__Get(dateTime, timezone, "isoWeekday") % 7;
    if (!daysOfWeek.includes(dayOfWeek)) return false;

    const noticeDate = UTCDate__Add(
      dateTime,
      -groupScheduleBriefing.hoursNotice,
      "hours"
    );
    return UTCDate__IsAfter(noticeDate);
  }

  function getDisabledBefore() {
    let { daysNotice = 0 } = groupScheduleBriefing;
    daysNotice = Math.max(daysNotice, 0);
    if (daysNotice === 0) {
      // check by the given hours notice if we still have time for today. if not return 1 day notice to prevent today
      // to show up enabled on day picker
      if (!times.some((minutes: number) => isTimeSchedulable(minutes)))
        daysNotice = 1;
    }

    const today = UTCDate__StartOf(undefined, timezone, "day");
    return UTCDate__Add(today, daysNotice, "days");
  }

  return (
    <div className="date-time-step">
      <Label label="Date" error={touched?.date && errors?.date} condensed>
        <DayPickerInput
          placeholder="Select date"
          value={values.date?.toISOString()}
          onChange={date => onChange(new Date(date), "date")}
          disabledDaysOfWeek={[0, 1, 2, 3, 4, 5, 6].filter(
            dayOfWeek => !daysOfWeek.includes(dayOfWeek)
          )}
          disabledBefore={getDisabledBefore()}
          timezone={timezone}
        />
      </Label>
      <Label label="Time" error={touched?.time && errors?.time} condensed>
        <SelectBox
          name="time"
          placeholder="Select time"
          value={values.time}
          onChange={onChange}
          disabled={!values.date}
        >
          {times
            .filter((time: number) => {
              // remove the block that are overlapping with the blocks
              let isNotValid = false;
              if (values.date) {
                // if we have a day verify if the user can select the time
                const startDateTime = UTCDate__Add(
                  values.date,
                  time,
                  "minutes",
                  timezone
                );
                // end time
                const endDateTime = UTCDate__Add(
                  values.date,
                  time + groupScheduleBriefing.duration,
                  "minutes",
                  timezone
                );
                // now we will go through each of the unavailable time blocks
                // if the start time or end time is within of group schedule block we will return false
                // since this is not a valid time
                isNotValid = unavailableTimeBlocks.some(timeBlock => {
                  // simpler way to check for inclusive overlaps. In order to change to exclusive overlap just replace
                  // UTCDate__IsSameOrBefore and UTCDate__IsSameOrAfter with UTCDate__IsBefore and UTCDate__IsAfter accordingly
                  // see https://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap#answer-325964
                  return (
                    UTCDate__IsSameOrBefore(
                      startDateTime,
                      timeBlock.end,
                      "minute",
                      timezone
                    ) &&
                    UTCDate__IsSameOrAfter(
                      endDateTime,
                      timeBlock.start,
                      "minute",
                      timezone
                    )
                  );
                });
              }
              return !isNotValid;
            })
            .map((time: number) => {
              const start = formatTime(time, course.isOnline, timezone);
              const end = formatTime(
                time + groupScheduleBriefing.duration,
                course.isOnline,
                timezone
              );

              return (
                <SelectBox.Option key={time} value={time}>
                  {start} to {end}
                </SelectBox.Option>
              );
            })}
        </SelectBox>
      </Label>
    </div>
  );
}

interface Props {
  course: any;
  errors: any;
  onBlur: (name: string) => void;
  onChange: (value: any, name: string) => void;
  timezone: string;
  touched: any;
  unavailableTimeBlocks: any[];
  values: Fields["dateTime"];
}

function formatTime(time: number, isOnline: boolean, timezone: string) {
  const localTimezone =
    Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? timezone;

  let date = UTCDate__StartOf(undefined, timezone, "day");
  date = UTCDate__Add(date, time, "minutes");
  if (isOnline) return UTCDate__FormattedDate(date, localTimezone, "HH:mm");
  else return UTCDate__FormattedDate(date, timezone, "HH:mm");
}

function getBaseTimes(
  timeIntervals: any[],
  isOnline: boolean,
  timezone: string
) {
  const times = TimeIntervals__Times(
    timeIntervals.map((time: any) => [time.start, time.end]),
    30
  );

  if (!isOnline) return times;

  const localTimezone =
    Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? timezone;

  const today = UTCDate__StartOf(undefined, timezone, "day");
  const localToday = UTCDate__StartOf(undefined, localTimezone, "day");

  return times
    .map((time: number) => {
      const date = UTCDate__Add(today, time, "minutes");
      if (UTCDate__IsBefore(date, localToday, "day", localTimezone))
        time += 1440;
      if (UTCDate__IsAfter(date, localToday, "day", localTimezone))
        time -= 1440;
      return time;
    })
    .sort((time1: number, time2: number) => comparator(time1, time2));
}
