import React from "react";
import { css } from "../../sitches.config";

interface TagProps {
  label: string;
  className?: string;
  color?: "emerald" | "camelot" | "sun" | "alto";
  size?: "small" | "medium" | "large";
}

export default function Tag({
  label,
  className,
  color = "sun",
  size = "small"
}: TagProps) {
  return (
    <span className={styles.tag({ size, color, className })}>{label}</span>
  );
}

const styles = {
  tag: css({
    display: "inline-block",
    textTransform: "uppercase",
    fontFamily: "$foundersGrotesk",
    fontStyle: "normal",
    fontWeight: 500,
    textAlign: "center",
    letterSpacing: 1,
    color: "$pampas",
    verticalAlign: "top",

    variants: {
      size: {
        small: {
          fontSize: 10,
          lineHeight: "7px",
          height: 19,
          borderRadius: 4,
          paddingY: 6,
          paddingX: 8
        },

        medium: {
          fontSize: 12,
          lineHeight: "8px",
          height: 21,
          borderRadius: 4.5,
          paddingY: 6,
          paddingX: 9
        },

        large: {
          fontSize: 14,
          lineHeight: "9px",
          height: 23,
          borderRadius: 5,
          paddingY: 7,
          paddingX: 10
        }
      },

      color: {
        emerald: {
          backgroundColor: "$emerald"
        },

        camelot: {
          backgroundColor: "$camelot"
        },

        sun: {
          backgroundColor: "$sun"
        },

        alto: {
          backgroundColor: "$alto",
          color: "$ebonyClay"
        }
      }
    }
  })
};
