import { css, withBem } from "./../sitches.config";

export const styles = withBem("table-body", {
  tableBody: css({
    "&:first-child > tr:first-child > .table-cell": {
      "&:first-child": {
        borderTopLeftRadius: "var(--table-border-radius, 0)"
      },
      "&:last-child": {
        borderTopRightRadius: "var(--table-border-radius, 0)"
      }
    },
    "&:last-child > tr:last-child > .table-cell": {
      "&:first-child": {
        borderBottomLeftRadius: "var(--table-border-radius, 0)"
      },
      "&:last-child": {
        borderBottomRightRadius: "var(--table-border-radius, 0)"
      }
    }
  })
});
