import React, { useMemo } from "react";

import { CCourseLevels } from "@obby/lib";

import styles from "./course-level-filter.module.less";

export function CourseLevelFilter(props) {
  const { counts, onChange, level } = props;

  const options = useMemo(
    () =>
      Object.keys(CCourseLevels).map(level => ({
        level: CCourseLevels[level],
        label: CCourseLevels[level]
      })),
    [counts]
  );

  return (
    <div className={styles["course-level-filter"]}>
      <Level label="All" isSelected={level === undefined} onChange={onChange} />
      {options.map(option => (
        <Level
          key={option.level}
          isSelected={level === option.level}
          {...option}
          onChange={onChange}
        />
      ))}
    </div>
  );
}

function Level(props) {
  const { isSelected, level, count, label, onChange } = props;
  return (
    <div
      className={`course-level-filter__level ${
        isSelected ? "course-level-filter__level--selected" : ""
      }`}
      onClick={() => onChange(level)}
      role="button"
    >
      <span className="course-level-filter__label">{label}</span>
      {count !== undefined && (
        <span className="course-level-filter__count"> ({count})</span>
      )}
    </div>
  );
}
