const CBookingTypes = {
  REGULAR: "regular",
  // We should once and for all decide which word we use as type for flexitime bookings.
  // I vote for flexitime to be used and anytime to be disused.
  ANYTIME: "anytime",
  FLEXITIME: "flexitime",
  BLOCK: "block",
  PRIVATE: "private",
  EXTERNAL: "external",
  BUNDLE: "bundle",
  BUNDLE_BOOKING: "bundle_booking"
};

export default CBookingTypes;
