import * as Yup from "yup";
export function PaymentProductPersonalDetailsFormSchema() {
	return Yup.object({
		firstName: Yup.string()
			.label("First name")
			.required()
			.min(3)
			.default(""),
		lastName: Yup.string()
			.label("Surname")
			.required()
			.min(3)
			.default(""),
		email: Yup.string()
			.label("Email")
			.email()
			.required()
			.default(""),
		phoneNumber: Yup.string()
			.label("Phone number")
			.required()
			.default(""),
		marketingOptIn: Yup.boolean()
			.label("Marketing Opt in")
			.default(false)
	});
}
