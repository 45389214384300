import React from "react";
import { includes } from "lodash";
import {
  isFullArray,
  callFunctionIfExists,
  isValidTwoPointDecimalNumber
} from "@obby/lib";
import { styles } from "./LabelInput.styles";

const LabelInput = props => (
  <div className={`label-input ${styles.labelInput}`}>
    {/* LABEL */}
    {props.label && (
      <div className={`label-input__label ${styles.label}`}>{props.label}</div>
    )}

    <div className={`label-input__input-wrap ${styles.inputWrap}`}>
      {/* IF TYPE: TEXTAREA */}
      {props.type === "textarea" && (
        <textarea
          className={`label-input__textarea ${styles.textArea}`}
          placeholder={props.placeholder}
          name={props.name}
          maxLength={props.max}
          value={props.value}
          onChange={event => props.onChange(event.target.value)}
          onBlur={event =>
            callFunctionIfExists(props.onBlur, [event.target.name])
          }
        />
      )}
      {/* IF TYPE: MONEY */}
      {props.type === "money" && (
        <div className={`label-input__icon-input ${styles.iconInput}`}>
          {props.value && (
            <span className={`label-input__input-icon ${styles.iconInput}`}>
              {props.moneyCharacter || "£"}
            </span>
          )}
          <input
            className={`
            label-input__input
            label-input__input--${props.value && "icon"}
           ${styles.input({ variant: props.value && "icon" })}`}
            type="number"
            min={0}
            name={props.name}
            placeholder={props.placeholder}
            value={props.value}
            onChange={event => {
              if (
                !isFullArray(event.target.value) ||
                isValidTwoPointDecimalNumber(event.target.value)
              ) {
                props.onChange(event.target.value);
              } else {
                props.onChange(props.value);
              }
            }}
            onBlur={event =>
              callFunctionIfExists(props.onBlur, [event.target.name])
            }
          />
        </div>
      )}
      {/* IF TYPE: LOCKED */}
      {props.type === "locked" && (
        <div className={`label-input__locked-text ${styles.lockedText}`}>
          {props.value}
        </div>
      )}
      {/* IF TYPE: REGULAR */}
      {!includes(["textarea", "money", "locked"], props.type) && (
        <input
          className={`label-input__input ${styles.input}`}
          name={props.name}
          type={props.type || "text"}
          step={props.step || "any"}
          min={0}
          placeholder={props.placeholder}
          maxLength={props.max}
          disabled={props.disabled}
          value={props.value}
          onChange={event => props.onChange(event.target.value)}
          onBlur={event =>
            callFunctionIfExists(props.onBlur, [event.target.name])
          }
        />
      )}
    </div>

    {/* CHARACTER COUNT */}
    {props.max && (
      <div className={`label-input__character-count ${styles.characterCount}`}>
        {props.max - props.value.length} characters left
      </div>
    )}

    {/* NOTE */}
    {props.note && (
      <div className={`label-input__note ${styles.note}`}>{props.note}</div>
    )}

    {/* ERROR */}
    {props.error && (
      <div className={`label-input__error ${styles.error}`}>{props.error}</div>
    )}
  </div>
);

export default LabelInput;
