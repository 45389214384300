import { css, withBem } from "../../sitches.config";

export const styles = withBem("switch-input", {
  switchInput: css({
    display: "flex",
    backgroundColor: "$athensGray",
    border: "1px solid $mercury",
    boxSizing: "border-box",
    borderRadius: "6px",
    width: "100%",

    variants: {
      backgroundColor: {
        white: {
          backgroundColor: "$white !important"
        }
      },
      size: {
        medium: { height: "$inputMediumHeight" },
        small: { height: "$inputSmallHeight" }
      }
    }
  })
});
