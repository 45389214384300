import React from "react";
import RatingSummary from "../../components/RatingSummary";
import { PrimaryButton } from "../../components/PrimaryButton";
import SecondaryButton from "../../components/SecondaryButton";
import HighlightPrice from "../../components/HighlightPrice";
import { bem } from "../../configs";
import {
  Discount__DiscountedPrice,
  Number_Currency,
  CurrencyISO__CurrencySymbol
} from "@obby/lib";

import { styles } from "./CoursePageFooterBar.styles";

const cn = bem("course-page-footer-bar");
export function CoursePageFooterBar({
  productPage,
  menuActions = [],
  /** @deprecated - use isFromPrice */
  additionalPrice = [],
  discount,
  rating = 0,
  numberOfReviews = 0,
  price,
  isFromPrice = additionalPrice.length > 0,
  currency = "GBP"
}) {
  let currencySymbol = CurrencyISO__CurrencySymbol(currency);
  return (
    <div className={`${cn()} ${styles.coursePageFooterBar}`}>
      <div className={`${cn("wrapper")} ${styles.wrapper}`}>
        <div className={`${cn("book-wrapper")} ${styles.bookWrapper}`}>
          <div className={`${cn("actions")} ${styles.actions}`}>
            {menuActions
              .filter(action => !action.hidden)
              .map((action, index) => {
                const { type = "PRIMARY" } = action;
                const Button = {
                  SECONDARY: SecondaryButton,
                  PRIMARY: PrimaryButton
                }[type];
                return (
                  <Button
                    key={index}
                    onClick={action.onClick}
                    text={action.text}
                    width={action.width}
                    textMode={action.textMode || "uppercase"}
                    color={action.color || "robbinsEggBlue"}
                    disabled={action.disabled || false}
                    className={`${cn("action")} ${styles.action}`}
                    css={{ maxWidth: "11.3rem" }}
                  />
                );
              })}
            <div
              className={`${cn("reviews", {
                "no-reviews": numberOfReviews === 0
              })} ${styles.reviews({ noReviews: numberOfReviews === 0 })}`}
            >
              <span className="d-inline-block">
                {discount && (
                  <HighlightPrice
                    price={Discount__DiscountedPrice(discount, price)}
                  />
                )}
                <div
                  className={`${cn("price", {
                    "with-discount": discount !== undefined
                  })} ${styles.price({
                    withDiscount: discount !== undefined
                  })}`}
                >
                  {isFromPrice > 0 ? "from " : ""}
                  <span className="d-inline-block">
                    {productPage
                      ? Number_Currency(price, currencySymbol)
                      : `${Number_Currency(price, currencySymbol)} pp`}
                  </span>
                </div>
              </span>
              {numberOfReviews > 0 && (
                <a href="#latest-reviews">
                  <RatingSummary
                    rating={rating}
                    reviewCount={numberOfReviews}
                    iconSize={15}
                    primaryColor="emeraldish"
                  />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

CoursePageFooterBar.menuActionTypes = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY"
};
