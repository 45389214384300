import React from "react";

import styles from "./Rail.module.less";

export default class Rail extends React.Component {
  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.railRef = React.createRef();
    this.itemsRefs = React.Children.map(props.children, () =>
      React.createRef()
    );

    this.state = {
      left: 0,
      currentX: null,
      direction: null,
      moving: false,
      atMin: true,
      atMax: false
    };
  }

  alignCards(threshold = 0.25) {
    let { left, direction } = this.state;
    // if we are getting to the end we'll align on the right side
    if (left > this.getLastItemLeftPosition() && direction === "right") {
      this.alignRight(threshold);
    }
    // otherwise we'll align on the left side.
    else {
      this.alignLeft(threshold);
    }
  }

  alignLeft(threshold) {
    let { left } = this.state;
    for (let item of this.wrapperRef.current.children) {
      let itemRightPosition = this.getItemRightPosition(item);
      if (itemRightPosition < left) continue;
      // the threshold depends the direction we are moving the cards. if we are moving
      // to right its the last quarter of item. if we are moving to left is the first quarter
      if (
        left >
        itemRightPosition - this.getItemThreshold(item, "right", threshold)
      )
        continue;
      this.setWrapperLeftPosition(itemRightPosition - item.offsetWidth);
      break;
    }
  }

  alignRight(threshold) {
    let { left } = this.state;
    const right = left + this.railRef.current.offsetWidth;
    for (let item of [...this.wrapperRef.current.children].reverse()) {
      let itemLeftPosition = this.getItemLeftPosition(item);
      if (itemLeftPosition > right) continue;
      // the threshold depends the direction we are moving the cards. if we are moving
      // to the left its the last quarter of item. if we are moving to right is the first quarter
      if (
        right <
        itemLeftPosition + this.getItemThreshold(item, "left", threshold)
      )
        continue;
      this.setWrapperLeftPosition(
        itemLeftPosition - this.railRef.current.offsetWidth + item.offsetWidth
      );
      break;
    }
  }

  componentDidMount() {
    this.setState({
      left: this.getWrapperMinLeftPosition()
    });
  }

  end() {
    this.setState({
      currentX: null
    });
    this.alignCards(0);
  }

  getItemLeftPosition(item) {
    const itemRect = item.getBoundingClientRect();
    const parentRect = this.wrapperRef.current.getBoundingClientRect();
    return itemRect.x - parentRect.x;
  }

  getItemRightPosition(item) {
    const { x, width } = item.getBoundingClientRect();
    const wrapperRect = this.wrapperRef.current.getBoundingClientRect();
    return x + width - wrapperRect.x;
  }

  getWrapperMinLeftPosition() {
    if (
      this.wrapperRef.current.children &&
      this.wrapperRef.current.children.length
    ) {
      return this.getItemLeftPosition(this.wrapperRef.current.children[0]);
    }
    return 0;
  }

  getWrapperMaxLeftPosition() {
    return (
      this.getItemRightPosition(
        this.wrapperRef.current.children[
          this.wrapperRef.current.children.length - 1
        ]
      ) - this.railRef.current.offsetWidth
    );
  }

  getLastItemLeftPosition() {
    return (
      this.getWrapperMaxLeftPosition() -
      this.wrapperRef.current.children[
        this.wrapperRef.current.children.length - 1
      ].offsetWidth
    );
  }

  getItemThreshold(item, side, threshold = 0.25) {
    const { direction } = this.state;
    return item.offsetWidth * (direction === side ? 1 - threshold : threshold);
  }

  move(newX) {
    const { left, currentX } = this.state;
    // we are not currently dragging
    if (currentX === null) return;
    // we did not drag enough to set a new position
    if (currentX === newX) return;
    let newLeft = left + (currentX - newX);
    let direction = left > newLeft ? "left" : "right";

    //this.state.left = newLeft;
    this.state.currentX = newX;
    this.state.direction = direction;
    this.state.moving = true;

    this.setWrapperLeftPosition(newLeft, false);
  }

  onWrapperClick(e) {
    // Prevent the click event if we just moved the cards by dragging them. the
    // click event is fired right after releasing the mouse
    if (this.state.moving) e.preventDefault();
  }

  onWrapperMouseDown(e) {
    this.start(e.clientX);
  }

  onWrapperMouseMove(e) {
    if (e.clientX !== null) this.move(e.clientX);
  }

  onWrapperMoveEnded() {
    this.state.moving = false;
  }

  onWrapperMouseOut(e) {
    // returns if it was captured by a descendent element.
    if (e.currentTarget.contains(e.relatedTarget)) return;
    this.onWrapperMouseUp(e);
  }

  onWrapperMouseUp() {
    if (this.state.moving)
      // wrap it on a setTimeout to be called after the click event fired on
      // mouse release
      setTimeout(this.onWrapperMoveEnded.bind(this), 0);

    this.setState({
      currentX: null
    });
    this.alignCards(0.25);
  }
  onTouchStart(e) {
    // bail if we already are handling one touch
    if (e.touches.length > 1) return;
    this.start(e.touches[0].screenX);
  }

  onTouchEnd() {
    if (this.state.moving)
      // wrap it on a setTimeout to be called after the click event fired on
      // mouse release
      setTimeout(this.onWrapperMoveEnded.bind(this), 0);
    this.end();
  }

  onTouchMove(e) {
    if (e.touches) this.move(e.touches[0].screenX);
  }

  render() {
    const { children, className = "", classNames } = this.props;
    const { left, currentX } = this.state;
    return (
      <div
        ref={this.railRef}
        className={`${styles["rail"]} ${className}`}
        onMouseDown={this.onWrapperMouseDown.bind(this)}
        onMouseMove={
          currentX !== null ? this.onWrapperMouseMove.bind(this) : () => {}
        }
        onMouseUp={
          currentX !== null ? this.onWrapperMouseUp.bind(this) : () => {}
        }
        onMouseOut={
          currentX !== null ? this.onWrapperMouseOut.bind(this) : () => {}
        }
        onTouchStart={this.onTouchStart.bind(this)}
        onTouchEnd={this.onTouchEnd.bind(this)}
        onTouchMove={this.onTouchMove.bind(this)}
        onClick={this.onWrapperClick.bind(this)}
      >
        <div
          className={`${styles["rail__wrapper"]} ${
            currentX !== null ? styles["rail__wrapper--railing"] : ""
          }`}
          style={{ left: `${-left}px` }}
          ref={this.wrapperRef}
        >
          {React.Children.map(children, child => (
            <div
              className={
                (classNames && classNames.railItem) || styles["rail__item"]
              }
            >
              {child}
            </div>
          ))}
        </div>
      </div>
    );
  }

  setWrapperLeftPosition(left, preventOverShift = true) {
    // Change the wrapper left position by mutating the state and setting the left value
    // directly on element's dom style. this way we prevent this component to be rendered
    // every time we have to move it

    const {
      onMaxPosition = () => {},
      onLeaveMaxPosition = () => {},
      onMinPosition = () => {},
      onLeaveMinPosition = () => {}
    } = this.props;
    const { atMin, atMax } = this.state;

    // this is false only when we are moving the rail by dragging it. we don't want
    // to prevent the rail to go to far because this process is too heavy process
    // to make every single time the rail is moved. you will be able to move the rail
    // to far. but once the movement stops maximum or minimum position allowed is stablished.
    if (preventOverShift) {
      // prevent the wrapper to go to far
      const minLeft = this.getWrapperMinLeftPosition();
      if (left <= minLeft) {
        left = minLeft;
        if (!atMin) {
          this.state.atMin = true;
          onMinPosition();
        }
      } else if (atMin) {
        this.state.atMin = false;
        onLeaveMinPosition();
      }

      const maxLeft = this.getWrapperMaxLeftPosition();
      if (left >= maxLeft) {
        left = maxLeft;
        if (!atMax) {
          this.state.atMax = true;
          onMaxPosition();
        }
      } else if (atMax) {
        this.state.atMax = false;
        onLeaveMaxPosition();
      }
    }

    // if the we are already on max position prevent the rail to go further
    if (atMax) left = Math.min(left, this.state.left);
    // if the we are already on min position prevent the rail to go further
    if (atMin) left = Math.max(left, this.state.left);

    this.state.left = left;
    this.wrapperRef.current.style.left = `${-this.state.left}px`;
  }

  shiftLeft() {
    let { left } = this.state;

    for (let item of [...this.wrapperRef.current.children].reverse()) {
      let itemLeftPosition = this.getItemLeftPosition(item);
      if (itemLeftPosition >= left) continue;
      let newLeft =
        itemLeftPosition + item.offsetWidth - this.railRef.current.offsetWidth;
      this.setWrapperLeftPosition(newLeft);
      break;
    }
  }

  shiftRight() {
    let { left } = this.state;
    const right = left + this.railRef.current.offsetWidth;
    for (let item of this.wrapperRef.current.children) {
      let itemRightPosition = this.getItemRightPosition(item.current);
      if (itemRightPosition <= right) continue;

      const newLeft = this.getItemLeftPosition(item.current);
      this.setWrapperLeftPosition(newLeft);
      break;
    }
  }

  start(newX) {
    if (this.getWrapperMaxLeftPosition() > 0)
      this.setState({
        currentX: newX
      });
  }
}
