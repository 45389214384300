import { css } from "@obby/components";

export const styles = {
  popup: css({
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    overflow: "scroll",
    animation: "popout 250ms, fadeIn 250ms",
    zIndex: "1001"
  }),
  overlay: css({
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    backgroundColor: "rgba(255, 255, 255, 0.95)",
    zIndex: "90",

    "@media all and (max-width: 767px)": {
      backgroundColor: "white"
    }
  }),
  mobileCloseButton: css({
    position: "fixed",
    zIndex: "101",
    fontSize: "2em",
    top: "10px",
    right: "20px",
    color: "@dark-gray",
    cursor: "pointer",
    textDecoration: "none",

    "@media all and (min-width: 768px)": {
      display: "none"
    }
  }),
  closeButton: css({
    position: "absolute",
    zIndex: "100",
    fontSize: "2em",
    top: "5px",
    right: "20px",
    color: "$darkGray",
    cursor: "pointer",
    textDecoration: "none",

    "@media all and (max-width: 767px)": {
      display: "none"
    }
  }),
  container: css({
    display: "flex",
    justifyContent: "center",
    zIndex: "100",
    padding: "0",
    "@md": {
      padding: "20px 0"
    }
  }),
  box: css({
    position: "relative",
    zIndex: "100",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
    borderRadius: "5px",
    backgroundColor: "white",
    fontFamily: "$foundersGrotesk",
    width: "100%",
    height: "100vh",

    "@md": {
      width: "auto",
      height: "auto"
    }
  })
};
