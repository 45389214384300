import React from "react";

export const TerrariumSticker = {
  viewBox: "0 0 283.5 359.7",
  body() {
    return (
      <g>
        <path
          className="background"
          fill="#000000"
          d="M283.5,179.2C283.5,73.7,235.2,0,142.4,0C48.3,0,0,73.7,0,179.2C0,286,48.3,359.7,142.4,359.7
		C235.2,359.7,283.5,286,283.5,179.2z"
        />
        <path
          className="foreground"
          fill="#FFFFFF"
          d="M221.5,81.8c0-0.1,0-0.1-0.1-0.2c-0.5-2.2-1.7-4.4-3.4-6.3c-3.2-3.5-6.6-5.4-15.6-5.4c-0.1,0-0.2,0-0.3,0
		c-0.5-1.7-1.2-3.2-1.7-4.3c-2.8-6.1-22-35.3-25-36.6c-0.6-0.3-1.3-0.3-1.9-0.1c-11.5,3.8-22.8,5.2-36,6.8l-4.2,0.5
		c-4.1,0.5-7.2,2.1-9.1,4.8c-3.5,4.9-2.3,11.9-1.3,17c0.1,0.8,0.3,1.6,0.4,2.3c1.8,11.3,6.4,19.1,9.7,24.8c4.4,7.4,5.1,9.4,2.1,12.4
		c-1,1-2,1.3-3.2,1.1c-3.4-0.6-7.6-5.4-10.7-12.1c-6.2-13.3-3.4-40.5-3.4-40.8c0.1-1-0.3-1.9-1.2-2.5c-0.8-0.6-1.9-0.6-2.8-0.2
		C90.7,54.9,60.6,82.3,56.8,99.6c-1.7,8-0.4,15.4,3.9,21.3c6.2,8.6,18.3,14.1,35,16c4.3,0.5,7.1,2.4,8.2,5.7c1,3.1,0,6.8-1.5,8.2
		c-3,2.7-10.3,3.3-20.7-4c-17.3-12.1-26.4-28.8-26.5-29c-0.5-1-1.6-1.5-2.7-1.4c-1.1,0.1-2,0.9-2.3,1.9c-0.2,0.5-3.8,13.4-0.3,27.9
		c2.3,9.3,7.5,17.1,15.2,22.9c-0.6,5.1,0.6,9.4,3.8,13.3c2.7,3.3,6.3,5,10.3,5c3.3,0,6.6-1.3,9.5-3.6c1.9-1.6,3.5-3.1,4.8-4.6
		c2.4-0.2,4.5-0.6,6.4-1.3c-2.5,4-5.2,7.8-8.1,11.4c-0.7,0.9-0.8,2.1-0.2,3c1.9,3.3,6.3,4.8,10.3,3.4c0.4-0.1,0.8-0.3,1.2-0.5v40.9
		c0,1,0.8,1.8,1.8,1.8h7.1v36.3c0,0-0.1,0-0.1,0c-2.7,0-4.4,1.4-6.2,2.9c-1.8,1.5-4.1,3.4-8.5,4.7c-12.1,3.7-16.6,6.7-16.7,10.8
		c0,0,0,0,0,0v5.9c0,1,0.8,1.8,1.8,1.8h49.1c1,0,1.8-0.8,1.8-1.8v-5.8v-0.1v-16.8c0-0.7-0.5-1.4-1.1-1.7c-0.5-0.2-1.1-0.1-1.6,0.2
		V238h3.3c1,0,1.8-0.8,1.8-1.8v-27c2,0.6,4.2,1.1,6.3,1.5v25.5c0,1,0.8,1.8,1.8,1.8h6.6v36.4c-0.5-0.3-1.1-0.4-1.6-0.2
		c-0.7,0.3-1.1,0.9-1.1,1.7v16.8v0.1v5.8c0,1,0.8,1.8,1.8,1.8h49.1c1,0,1.8-0.8,1.8-1.8v-5.9c0,0,0,0,0,0c-0.1-4.1-4.6-7-16.7-10.8
		c-4.4-1.4-6.7-3.2-8.5-4.7c-1.8-1.5-3.5-2.9-6.2-2.9c0,0-0.1,0-0.1,0V238h3.8c1,0,1.8-0.8,1.8-1.8v-30.4
		c23.7-15.8,36.1-61.2,36.7-63.1c0.3-1-0.1-2.1-0.8-2.7c-0.8-0.7-1.9-0.8-2.8-0.4c-0.3,0.1-26.1,11.1-41,6.7
		c-4.3-1.3-7.6-4.5-7.6-7.3c0-2.5,2.9-4.9,8-6.5c12.4-3.9,42,2.2,42.3,2.3c1.3,0.3,2.6-0.5,3.1-1.7c1.1-3.1,1.5-7.1,1.6-11.6
		C225.9,107.5,223.4,89.3,221.5,81.8z M94.8,171.8c-1.9,2.8-4.5,6-8.5,9.2c-2.2,1.8-4.8,2.8-7.2,2.8c-2.8,0-5.4-1.2-7.5-3.7
		c-2.5-3-4-6.6-2.6-12.7c2-8.8,0.6-12,2-13.1c0.3-0.2,0.5-0.3,0.8-0.3c0.9,0,1.7,1.4,2.2,3.8c0.2,0.9,0.8,4.5,0.7,5.4
		c0,0,7.5-6.2,9.2-7.6c1-0.8,1.7-1.3,2.3-1.3c0.3,0,0.5,0.1,0.7,0.3c0.7,0.9,0.2,1.7-0.9,3.4c-1,1.5-4.3,5.8-4.3,5.8
		c0,0,6.2-5.2,8.3-6.9c0.6-0.5,1.2-0.9,1.8-0.9c0.3,0,0.6,0.1,0.8,0.4c0.6,0.7,0.6,1.7-0.7,3.3c-3.4,4.3-6.7,8.3-6.7,8.3
		c1.3-1.1,5.6-4.6,7.6-6.3c0.6-0.5,1.1-0.7,1.5-0.7c0.3,0,0.6,0.1,0.8,0.4c0.4,0.5,0.4,1.6-0.4,2.8c-2,3.1-4.8,6.9-6.6,8.9
		c0,0,4.2-3.5,4.6-3.7c1-0.8,1.6-1.2,2.1-1.2c0.3,0,0.5,0.1,0.6,0.3C96.1,169.2,95.7,170.4,94.8,171.8z M141.8,47.9
		c0.5-0.5,1-0.5,1.6-0.6c1,0,2.1,0.5,2.8,1.2c1.8,1.8,3.9,9.6,2.5,12.4c-0.2,0.3-0.3,0.5-1,0.4c-2.1-0.3-4.8-3.5-6-7.1
		C140.7,51.4,140.7,48.9,141.8,47.9z M140.6,144.5c0.4-0.7,0.7-1.1,1.4-1.1c0.2,0,0.5,0,0.8,0.1c0.3,0.1,0.7,0.6,0.9,1.2
		c0.1,0.4,0.1,0.9,0.1,1.4c0,0.5-0.2,1-0.3,1.6c-0.4,1.4-1.1,3.4-2.5,4.5C140,151,139.4,146.9,140.6,144.5z M90.9,97.2
		c-3.9-4.5-6.4-8.6-6.1-13.7c0.1-2.1,1.5-4.5,3.5-4.7c0.1,0,0.2,0,0.4,0c2,0,4,2.2,4.9,4.3c0.6,1.6,1,3.3,1.4,5.1
		c0.4,1.7,0.7,3.5,1.4,5.2c1.7,4.6,4.8,8.3,7.9,11.8c2.4,2.8,4.8,5.5,6.3,8.6c0.8,1.7,0.9,3.4,0.4,3.8c-0.3,0.2-1,0.2-1.6-0.2
		c-1-0.5-1.8-1.5-2.7-2.4C101.5,109.3,96.2,103.3,90.9,97.2z M129.4,296.7H83.9v-2.3h45.5V296.7z M126.7,263.8v13.3
		c-1.4,0.8-3.3,1.5-5.4,1.5c-3.3,0-4.7-1.1-5.8-2.2v-12.6H126.7z M115.5,260.2V238h11.2v22.2H115.5z M131.8,234.4h-25.2v-41.6
		c1-1,1.8-2,2.5-2.9l1.8-2.2c1.4,3.6,4.1,8.9,6.3,11.2c3.4,3.7,8.6,6.8,14.6,9V234.4z M196.4,294.5v2.3h-45.5v-2.3H196.4z
		 M164.9,263.8v12.6c-1.1,1.1-2.5,2.2-5.8,2.2c-2.1,0-3.9-0.7-5.4-1.5v-13.3H164.9z M153.7,260.2V238h11.2v22.2H153.7z M170.5,234.4
		h-25.2v-23.2c2.4,0.3,4.7,0.5,7.1,0.5c6.5,0,12.8-1.2,18.1-3.8V234.4z M173.4,176.8c3.1-0.5,4.8,0,5,0.6c0.2,0.4-0.2,1.3-0.9,1.8
		c-2.8,2.1-4.2,2.6-4.8,2.7c-8.4,1.7-16.5,1.9-23.2,1.9c-3.5,0-14,0-16.7-4.8c-1.6-2.7-1.8-5.1-0.7-6.4c0.6-0.7,1.7-1.1,3.2-1.1
		c1.2,0,2.6,0.3,4.1,1C151.7,177.8,159.9,178.9,173.4,176.8z M169.3,115.6c-1.3-0.5-1.6-1.3-1.8-1.9c-0.6-2.3,0.9-6.2,4.1-10.4
		c3.9-5.2,8-8,14.8-11.8c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.3,0.3,0.4,0.4c-0.1,0.2-0.2,0.4-0.3,0.7c-0.6,1.7-0.3,3.4,0.9,4.8
		c0.9,1,2.2,1.6,3.6,1.7c0.2,0.6,0.5,1.1,1,1.6c0.6,0.7,1.4,1.1,2.3,1.4C187.1,112.9,173.1,117.2,169.3,115.6z M205.1,99.6
		c-0.8,0.4-1.6,0.7-2.2,0.7c-0.5,0-0.9-0.1-1.2-0.4c-0.6-0.6,0-1.5,0.8-2.2c0.6-0.5,4.8-4.3,4.8-4.3c-2.3,1.5-6.4,3.7-9.8,5.2
		c-0.6,0.3-1.1,0.4-1.6,0.4c-0.5,0-1-0.2-1.2-0.4c-0.5-0.5-0.4-1.3,0.7-2.3c2-1.8,6.1-5.5,7.3-6.6c0,0-4.4,2.6-9.1,5.4
		c-0.9,0.5-1.6,0.7-2.2,0.7c-0.5,0-0.9-0.2-1.2-0.5c-0.6-0.6-0.2-1.4,0.9-2.4c2-1.8,8-7.2,8-7.2c0,0-4.4,2.4-6.4,3.4
		c-1.1,0.6-1.9,0.9-2.5,0.9c-0.4,0-0.7-0.2-1-0.5c-0.6-0.7-0.1-1.5,1.4-2.8c1.6-1.5,8.9-8,8.9-8c-0.9-0.1-4.4-1.2-5.2-1.5
		c-3-1-4.2-2.4-3-3.5c0.3-0.3,0.7-0.4,1.3-0.4c1.2,0,3.1,0.5,6.4,0.5c0.3,0,0.7,0,1.1,0c0.9,0,1.8,0,2.6,0c8,0,10.5,1.5,12.9,4.2
		c3.8,4.2,3.8,9.9-0.1,14.2C212.1,95.6,208.1,98,205.1,99.6z M219.4,96.7c-0.3,7.3-2.4,13.7-6.2,19c-0.3-5.3-1.1-10.9-1.9-15.7
		c2.5-1.7,4.8-3.6,6.7-5.7c0.5-0.6,1-1.2,1.4-1.8C219.5,94,219.5,95.3,219.4,96.7z"
        />
        <path
          className="foreground"
          fill="#FFFFFF"
          style={{ opacity: 0.5 }}
          d="M131.8,207.9v13.3c-15.9-2.3-22.5-10.5-25.2-16.1v-12.4c1-1,1.8-2,2.5-2.9l1.8-2.2c1.4,3.6,4.1,8.9,6.3,11.2
		C120.6,202.6,125.8,205.7,131.8,207.9z M152.4,211.7c-2.4,0-4.7-0.2-7.1-0.5V223c5.6,0.2,19.1,0.3,25.2-3.1v-12.1
		C165.2,210.5,158.9,211.7,152.4,211.7z"
        />
      </g>
    );
  }
};
