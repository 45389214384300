import String__UpperCaseFirstLetter from "./String__UpperCaseFirstLetter";

const DashedString__UpperCaseOnEveryWordString = dashedString => {
  if (dashedString)
    return dashedString
      .split("-")
      .map(String__UpperCaseFirstLetter)
      .join(" ");
  else return dashedString;
};

export default DashedString__UpperCaseOnEveryWordString;
