import React from "react";
import { Label } from "../../components/Label";
import { SelectBox } from "../../inputs/SelectBox";
import { TextInput } from "../../inputs/TextInput";
import { Row } from "../../layout/Row";
import { Col } from "../../layout/Col";
import { Flex } from "../../layout/Flex";

import { Fields } from "./EnquireLargeGroupForm.schema";
import { DayPickerInput } from "../../inputs/DayPickerInput";
import { CurrencyInput } from "../../inputs/CurrencyInput";
import { TextArea } from "../../inputs/TextArea";
// @ts-ignore
import { CurrencyISO__CurrencySymbol, UTCDate__StartOf } from "@obby/lib";

export function EnquireLargeGroupForm({
  currency = "GPB",
  errors,
  onBlur,
  onChange,
  timezone = "Europe/London",
  touched,
  values
}: Props) {
  return (
    <Flex column gap={4}>
      <Row gap={4}>
        <Col sm={6} md={4}>
          <Label
            label="First Name*"
            error={touched.firstName && errors.firstName}
          >
            <TextInput
              name="firstName"
              onBlur={onBlur}
              onChange={onChange}
              value={values.firstName}
            />
          </Label>
        </Col>
        <Col sm={6} md={4}>
          <Label label="Last Name*" error={touched.lastName && errors.lastName}>
            <TextInput
              name="lastName"
              onBlur={onBlur}
              onChange={onChange}
              value={values.lastName}
            />
          </Label>
        </Col>
        <Col sm={6} md={4}>
          <Label
            label="Company Name (if applicable)"
            error={touched.companyName && errors.companyName}
          >
            <TextInput
              name="companyName"
              onBlur={onBlur}
              onChange={onChange}
              value={values.companyName}
            />
          </Label>
        </Col>
        <Col sm={6} md={4}>
          <Label label="Email*" error={touched.email && errors.email}>
            <TextInput
              name="email"
              onBlur={onBlur}
              onChange={onChange}
              value={values.email}
            />
          </Label>
        </Col>
        <Col sm={6} md={4}>
          <Label
            label="Phone"
            error={touched.phoneNumber && errors.phoneNumber}
          >
            <TextInput
              name="phoneNumber"
              onBlur={onBlur}
              onChange={onChange}
              value={values.phoneNumber}
            />
          </Label>
        </Col>
      </Row>
      <Row gap={4}>
        <Col sm={6} md={4}>
          <Label
            label="Number of Guests*"
            error={touched.numberOfGuests && errors.numberOfGuests}
          >
            <SelectBox
              name="numberOfGuests"
              onBlur={onBlur}
              onChange={onChange}
              value={values.numberOfGuests}
            >
              <SelectBox.Option value="15 - 20">15 - 20</SelectBox.Option>
              <SelectBox.Option value="20 - 25">20 - 25</SelectBox.Option>
              <SelectBox.Option value="25 - 30">25 - 30</SelectBox.Option>
              <SelectBox.Option value="30 - 35">30 - 35</SelectBox.Option>
              <SelectBox.Option value="35 - 40">35 - 40</SelectBox.Option>
              <SelectBox.Option value="40 - 45">40 - 45</SelectBox.Option>
              <SelectBox.Option value="45 - 50">45 - 50</SelectBox.Option>
              <SelectBox.Option value="50+">50+</SelectBox.Option>
            </SelectBox>
          </Label>
        </Col>
        <Col sm={6} md={4}>
          <Label
            label="Event Occasion*"
            error={touched.eventOccasion && errors.eventOccasion}
          >
            <SelectBox
              name="eventOccasion"
              onBlur={onBlur}
              onChange={onChange}
              value={values.eventOccasion}
            >
              <SelectBox.Option value="Corporate Event">
                Corporate Event
              </SelectBox.Option>
              <SelectBox.Option value="Team Social">
                Team Social
              </SelectBox.Option>
              <SelectBox.Option value="Family/Friends gathering">
                Family/Friends gathering
              </SelectBox.Option>
              <SelectBox.Option value="Celebration (Birthday, Hen Party, etc)">
                Celebration (Birthday, Hen Party, etc)
              </SelectBox.Option>
              <SelectBox.Option value="Other">Other</SelectBox.Option>
            </SelectBox>
          </Label>
        </Col>
        <Col sm={6} md={4}>
          <Label
            label="Event Type*"
            error={touched.eventType && errors.eventType}
          >
            <SelectBox
              name="eventType"
              onBlur={onBlur}
              onChange={onChange}
              value={values.eventType}
            >
              <SelectBox.Option value="In-Person Event">
                In-Person Event
              </SelectBox.Option>
              <SelectBox.Option value="Virtual Event">
                Virtual Event
              </SelectBox.Option>
              <SelectBox.Option value="At Home Kits">
                At Home Kits
              </SelectBox.Option>
            </SelectBox>
          </Label>
        </Col>
      </Row>
      <Row gap={4}>
        <Col sm={6} md={4}>
          <Label
            label="Preferred Date*"
            error={touched.preferredDate && errors.preferredDate}
          >
            <DayPickerInput
              disabledBefore={UTCDate__StartOf(undefined, timezone, "day")}
              value={
                values.preferredDate
                  ? values.preferredDate.toISOString()
                  : undefined
              }
              onChange={date =>
                onChange(date ? new Date(date) : null, "preferredDate")
              }
              timezone={timezone}
            />
          </Label>
        </Col>
        <Col sm={6} md={4}>
          <Label
            label="Budget Per Person*"
            error={touched.preferredDate && errors.preferredDate}
          >
            <CurrencyInput
              name="budgetPerPerson"
              value={values.budgetPerPerson}
              onBlur={onBlur}
              onChange={onChange}
              currency={CurrencyISO__CurrencySymbol(currency)}
            />
          </Label>
        </Col>
        <Col sm={6} md={4}>
          <Label
            label="Desired Location"
            error={touched.desiredLocation && errors.desiredLocation}
          >
            <TextInput
              name="desiredLocation"
              onBlur={onBlur}
              onChange={onChange}
              value={values.desiredLocation}
            />
          </Label>
        </Col>
        <Col>
          <Label label="Message" error={touched.message && errors.message}>
            <TextArea
              name="message"
              onBlur={onBlur}
              onChange={onChange}
              value={values.message}
            />
          </Label>
        </Col>
      </Row>
    </Flex>
  );
}

interface Props {
  currency?: string;
  errors: any;
  onBlur: (name: string) => void;
  onChange: (value: any, name: string) => void;
  touched: any;
  values: Fields;
  timezone?: string;
}
