export const HearUsOptions = {
  GOOGLE_SEARCH: "GOOGLE_SEARCH",
  CAPTERRA: "CAPTERRA",
  OBBY_MARKETPLACE: "OBBY_MARKETPLACE",
  BLOG_ARTICLE: "BLOG_ARTICLE",
  YOUTUBE: "YOUTUBE",
  INSTAGRAM: "INSTAGRAM",
  FACEBOOK: "FACEBOOK",
  BING_SEARCH: "BING_SEARCH",
  RECOMMENDED: "RECOMMENDED"
};

export const HearUsOptionsLabels = {
  GOOGLE_SEARCH: "Google search",
  CAPTERRA: "Capterra",
  OBBY_MARKETPLACE: "Obby Marketplace",
  BLOG_ARTICLE: "Blog article",
  YOUTUBE: "Youtube",
  INSTAGRAM: "Instagram",
  FACEBOOK: "Facebook",
  BING_SEARCH: "Bing search",
  RECOMMENDED: "Recommended by a friend/colleague"
};
