import { store } from "statorgfc";
import { Subject } from "rxjs";

import axios from "./../config/axios";
import ErrorState from "./ErrorState";
import AuthenticationState from "./AuthenticationState";
import User__Photo from "../lib/User__Photo";

const modelName = "userProfile";

const getDefaultModel = initialData => {
  return {
    userProfile: {
      firstName: "",
      lastName: "",
      emailAddress: "",
      phoneNumber: "",
      newsletter: false,
      imageUrl: "",
      saved: false
    }
  };
};

const events = {
  componentDidMount: new Subject(),
  componentWillUnmount: new Subject(),
  firstNameChange: new Subject(),
  lastNameChange: new Subject(),
  emailAddressChange: new Subject(),
  phoneNumberChange: new Subject(),
  newsletterChange: new Subject(),
  imageUrlChange: new Subject(),
  submitChanges: new Subject()
};

events.componentDidMount.subscribe(onComponentDidMount);
events.componentWillUnmount.subscribe(onComponentWillUnMount);

const subscriptions = {};

function onComponentDidMount() {
  subscriptions.onFirstNameChange = events.firstNameChange.subscribe(
    onFirstNameChange
  );
  subscriptions.onLastNameChange = events.lastNameChange.subscribe(
    onLastNameChange
  );
  subscriptions.onEmailAddressChange = events.emailAddressChange.subscribe(
    onEmailAddressChange
  );
  subscriptions.onPhoneNumberChange = events.phoneNumberChange.subscribe(
    onPhoneNumberChange
  );
  subscriptions.onNewsletterChange = events.newsletterChange.subscribe(
    onNewsletterChange
  );
  subscriptions.onImageUrlChange = events.imageUrlChange.subscribe(
    onImageUrlChange
  );
  subscriptions.onSubmitChanges = events.submitChanges.subscribe(
    onSubmitChanges
  );

  const { user } = store.get("authentication");
  const model = store.get(modelName);
  store.set(modelName, {
    ...model,
    imageUrl: User__Photo(user),
    firstName: user.profile.firstName || "",
    lastName: user.profile.lastName || "",
    emailAddress: user.emails[0].address,
    phoneNumber: user.profile.phone || "",
    newsletter: user && user.profile ? !!user.profile.newsletter : false
  });
}

function onComponentWillUnMount() {
  subscriptions.onFirstNameChange.unsubscribe();
  subscriptions.onLastNameChange.unsubscribe();
  subscriptions.onEmailAddressChange.unsubscribe();
  subscriptions.onImageUrlChange.unsubscribe();
  subscriptions.onPhoneNumberChange.unsubscribe();
  subscriptions.onNewsletterChange.unsubscribe();
  subscriptions.onSubmitChanges.unsubscribe();
}

function onFirstNameChange(firstName) {
  const model = store.get(modelName);
  store.set(modelName, { ...model, firstName: firstName });
}

function onLastNameChange(lastName) {
  const model = store.get(modelName);
  store.set(modelName, { ...model, lastName: lastName });
}

function onEmailAddressChange(emailAddress) {
  const model = store.get(modelName);
  store.set(modelName, { ...model, firstName: firstName });
}

function onImageUrlChange(imageUrl) {
  const model = store.get(modelName);
  store.set(modelName, { ...model, imageUrl: imageUrl });
}

function onNewsletterChange(newsletter) {
  const model = store.get(modelName);
  store.set(modelName, { ...model, newsletter: newsletter });
}

function onPhoneNumberChange(phoneNumber) {
  const model = store.get(modelName);
  store.set(modelName, { ...model, phoneNumber: phoneNumber });
}

async function onSubmitChanges() {
  const model = store.get(modelName);
  try {
    const response = await axios.put("user/profile", {
      firstName: model.firstName,
      lastName: model.lastName,
      emailAddress: model.emailAddress,
      phone: model.phoneNumber,
      newsletter: model.newsletter,
      imageUrl: model.imageUrl
    });

    const user = response.data;

    AuthenticationState.events.userUpdate.next(user);

    store.set(modelName, { ...model, saved: true });
    window.setTimeout(() => {
      store.set(modelName, { ...model, saved: false });
    }, 3000);
  } catch (e) {
    ErrorState.events.userProfile.next(e.response.data.message);
  }
}

export default {
  events,
  getDefaultModel
};
