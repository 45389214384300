import React, { useState } from "react";

import Slider from "react-input-slider";
import { styles } from "./SliderInput.styles";

export function SliderInput({
  max,
  min,
  name,
  onChange = () => {},
  size = "medium",
  step,
  value,
  valueWidth,
  unit
}: Props) {
  const [x, setX] = useState(value);

  function onSliderChange({ x }: { x: number }) {
    setX(x);
    onChange(x, name!);
  }

  return (
    <div className={styles.sliderInput({ size })}>
      {/* @ts-ignore */}
      <Slider
        x={x}
        xmax={max}
        xmin={min}
        xstep={step}
        onChange={onSliderChange}
      />
      <span
        className={styles.value({
          css: { ...(valueWidth ? { width: `${valueWidth}px` } : {}) }
        })}
      >{`${x}${unit ?? ""}`}</span>
    </div>
  );
}

interface Props {
  max?: number;
  min?: number;
  name?: string;
  onChange?: (value: number, name: string) => void;
  size?: "medium" | "small";
  step?: number;
  unit?: string;
  value: number;
  valueWidth?: number;
}
