import { css } from "../../sitches.config";

export const styles = {
  pill: css({
    display: "inline-flex",
    alignItems: "center",
    fontFamily: "$foundersGrotesk",
    fontWeight: 500,
    lineHeight: "80%",
    borderRadius: "$1",
    variants: {
      size: {
        micro: {
          fontSize: "$10",
          padding: "0 2px",
          height: "16px"
        },
        tiny: {
          fontSize: "$12",
          padding: "0 5px",
          height: "20px"
        },
        small: {
          fontSize: "$14",
          height: "24px",
          padding: "0 10px 1px"
        },
        medium: {
          fontSize: "$1",
          height: "28px",
          padding: "0 15px 3px"
        }
      }
    }
  })

  // each(@colors, {
  //   &--color-@{value} {
  //   color: @@value;
  // }
  //
  // &--bg-@{value} {
  //   background-color: @@value;
  // }
  // });
};
