import React from "react";

import { styles } from "./NuovoBreadcrumbs.styles";

const NuovoBreadcrumbs = props => {
  const { color = "silver", className = "", condensed = true } = props;
  return (
    <div
      className={`nuovo-breadcrumbs ${styles.nuovoBreadcrumbs} ${
        styles.nuovoBreadcrumbs
      }--${color} ${
        condensed ? `${styles.nuovoBreadcrumbs}--condensed` : ""
      } ${className || ""} `}
    >
      {props.breadcrumbs.map((breadcrumb, index) => {
        return (
          <div
            key={index}
            className={`${styles.crumb}`}
            onClick={() => props.onClick(breadcrumb)}
          >
            {breadcrumb.label}
          </div>
        );
      })}
    </div>
  );
};

export default NuovoBreadcrumbs;
