import { css, withBem } from "../../sitches.config";

export const styles = withBem("fa-icon", {
  faIcon: css({
    color: "var(--fa-icon-color, inherit)",
    "&:not([width], [height])": {
      height: "1em",
      width: "1em"
    },
    path: { fill: "currentColor" }
  })
});
