import * as Yup from "yup";
export function EditBookingNotesFormSchema() {
  const defaults = {
    notes: ""
  };

  return Yup.object({
    notes: Yup.string()
      .label("Notes")
      .required()
      .default(defaults.notes)
  });
}
