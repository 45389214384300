import React from "react";

import Label from "../../components/Label";
import TextArea from "../../components/TextArea";
import { TextInput } from "../../components/TextInput";
import { styles } from "./ContactFormWidget.styles";

import { ContactFormWidgetSchema } from "./ContactFormWidget.schema";

interface ContactFormWidgetProps {
  values?: any;
  errors?: any;
  touched?: any;
  onChange: (value: any, name: string) => void;
  onBlur: () => void;
}

export function ContactFormWidget({
  values,
  errors,
  touched,
  onChange,
  onBlur
}: ContactFormWidgetProps) {
  return (
    <div className={`contact-form-widget ${styles.contactFormWidget}`}>
      <div className={`${styles.formSection}`}>
        <Label label="Full Name*" error={touched?.fullName && errors.fullName}>
          <TextInput
            name="fullName"
            value={values.fullName}
            onChange={onChange}
            onBlur={onBlur}
          />
        </Label>
      </div>
      <div className={`${styles.formSection}`}>
        <Label label="Email address*" error={touched?.email && errors.email}>
          <TextInput
            name="email"
            value={values.email}
            onChange={onChange}
            onBlur={onBlur}
          />
        </Label>
      </div>
      <div className={`${styles.formSection}`}>
        <Label
          label="Nature of Enquiry"
          error={touched?.enquiry && errors.enquiry}
        >
          <TextInput
            name="enquiry"
            value={values.enquiry}
            onChange={onChange}
            onBlur={onBlur}
          />
        </Label>
      </div>
      <div className={`${styles.formSection}`}>
        <Label
          label="Phone number"
          error={touched?.phoneNumber && errors.phoneNumber}
        >
          <TextInput
            name="phoneNumber"
            value={values.phoneNumber}
            onChange={onChange}
            onBlur={onBlur}
          />
        </Label>
      </div>
      <div className={`${styles.sectionMessage}`}>
        <Label label="Message" error={touched?.message && errors.message}>
          <TextArea
            name="message"
            value={values.message}
            onChange={onChange}
            onBlur={onBlur}
          />
        </Label>
      </div>
    </div>
  );
}
ContactFormWidget.Schema = ContactFormWidgetSchema;
