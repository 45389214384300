import React from "react";

import Icon from "./../Icon";
import ThemedMarkdownText from "./../ThemedMarkdownText";
import { styles } from "./MotivationBanner.styles";

const MotivationBannerTitle = ({ headerText, keyPrefix }) => (
  <div className={`motivation-banner__title ${styles.title()}`}>
    {headerText.map((titlePart, index) => {
      var key = `${keyPrefix}-${index}`;
      if (typeof titlePart === "object") {
        return (
          <div
            className={`motivation-banner__title-sticker-part-wrap ${styles.titleStickerPartWrap()}`}
            key={key}
          >
            {titlePart.preText && (
              <h2
                className={`motivation-banner__embedded-title-part ${styles.embeddedTitlePart(
                  { css: { paddingRight: "5px" } }
                )}`}
                key={`${key}-pre-text`}
              >
                {titlePart.preText}
              </h2>
            )}
            <div
              className={`motivation-banner__title-sticker-part ${styles.titleStickerPart()}`}
              key={`${key}-icon`}
            >
              <Icon icon={titlePart.icon} />
            </div>
            {titlePart.postText && (
              <h2
                className={`motivation-banner__embedded-title-part ${styles.embeddedTitlePart(
                  { css: { paddingLeft: "5px" } }
                )}`}
                key={`${key}-post-text`}
              >
                {titlePart.postText}
              </h2>
            )}
          </div>
        );
      }
      return (
        <h2
          className={`motivation-banner__title-part ${styles.titlePart()}`}
          key={key}
        >
          {titlePart}
        </h2>
      );
    })}
  </div>
);

export default function MotivationBanner(props) {
  return (
    <div className={`motivation-banner ${styles.motivationBanner()}`}>
      <div className={`motivation-banner__container ${styles.container()}`}>
        <div
          className={`motivation-banner__header-wrap ${styles.headerWrap()}`}
        >
          <MotivationBannerTitle
            keyPrefix="desktop"
            headerText={props.headerText}
          />
          <div
            className={`motivation-banner__description ${styles.description()}`}
          >
            <span>{props.headerDescription}</span>
          </div>
        </div>
        <div className={`motivation-banner__points ${styles.points()}`}>
          {props.motivationPoints &&
            props.motivationPoints.map((point, index) => (
              <div
                key={index}
                className={`motivation-banner__point ${styles.point()}`}
              >
                <span
                  className={`motivation-banner__point-title ${styles.pointTitle()}`}
                >
                  {point.header}
                </span>
                <span
                  className={`motivation-banner__point-divider ${styles.pointDivider()}`}
                />
                <div
                  className={`motivation-banner__point-description ${styles.pointDescription()}`}
                >
                  <ThemedMarkdownText
                    text={point.description}
                    classNames={{
                      link: `motivation-banner__point-description-link`,
                      paragraph: `motivation-banner__point-description-paragraph`
                    }}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
