import { pipe, not, filter } from "ramda";
import NestedObject__Values from "./NestedObjects__Values";
import { isFullArray } from "@obby/lib";

export const isNestedObjectEmpty = pipe(
  NestedObject__Values,
  filter(isFullArray),
  isFullArray,
  not
);

export default isNestedObjectEmpty;
