import React, { useState, useRef } from "react";
import { TogglePopout } from "../../modals/TogglePopout";
import { MultipleCheckboxInputOption } from "./MultipleCheckboxInputOption";
import { MultipleCheckboxInputContext } from "./MultipleCheckboxInputContext";
import { styles } from "./MultipleCheckoutInputs.styles";
import { SecondaryButton } from "../../buttons/SecondaryButton";
import { PrimaryButton } from "../../buttons";

export function MultipleCheckboxInput({
  children,
  className,
  onChange,
  size = "medium",
  text = "Select...",
  value
}: React.PropsWithChildren<Props>) {
  const [selected, setSelected] = useState(value);
  const [open, setOpen] = useState(false);

  function onApplyClick() {
    onChange && onChange(selected);
    setOpen(false);
  }

  function onCheck(value: any) {
    if (selected.includes(value)) return;
    setSelected([...selected, value]);
  }

  function onClearClick() {
    setSelected([]);
  }

  function onUncheck(value: any) {
    if (!selected.includes(value)) return;
    setSelected(selected.filter(selected => selected !== value));
  }

  function onContainerClick(e: React.MouseEvent) {
    // the TogglePopoutModel components captures every click to close him self.
    // let's stop that from happening
    e.stopPropagation();
  }

  return (
    <MultipleCheckboxInputContext.Provider
      value={{ onCheck, onUncheck, size, selected }}
    >
      <TogglePopout
        className={className}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <TogglePopout.Toogle>
          <div
            className={styles.toggle({ size, selected: selected?.length > 0 })}
          >
            {text}
          </div>
        </TogglePopout.Toogle>
        <TogglePopout.Modal left top>
          <div className={styles.container()} onClick={onContainerClick}>
            <ul className={styles.list()}>{children}</ul>
            <div className={styles.buttons()}>
              <SecondaryButton
                text="Clear"
                size="tiny"
                width="auto"
                color="mercury"
                onClick={onClearClick}
              />
              <PrimaryButton
                text="Apply"
                size="tiny"
                width="auto"
                color="camelot"
                onClick={onApplyClick}
              />
            </div>
          </div>
        </TogglePopout.Modal>
      </TogglePopout>
    </MultipleCheckboxInputContext.Provider>
  );
}
MultipleCheckboxInput.Option = MultipleCheckboxInputOption;
interface Props {
  className?: string;
  onChange?: (value: any[]) => void;
  size?: "small" | "medium" | "large";
  text?: string;
  value: any[];
}
