import React, { ReactElement, useContext } from "react";
import { CheckoutStepsBack } from "./CheckoutStepsBack";
import { CheckoutStepsBreadcrumb } from "./CheckoutStepsBreadcrumb";
import { CheckoutStepsBreadcrumbs } from "./CheckoutStepsBreadcrumbs";
import { CheckoutStepsContent } from "./CheckoutStepsContent";
import { CheckoutStepsContext, Provider } from "./CheckoutStepsContext";
import { CheckoutStepsContinue } from "./CheckoutStepsContinue";
import { CheckoutStepsHeader } from "./CheckoutStepsHeader";
import { CheckoutStepsNavigation } from "./CheckoutStepsNavigation";
import {
  CheckoutStepsStep,
  CheckoutStepsStepContext
} from "./CheckoutStepsStep";

export function CheckoutSteps({ children }: Props) {
  const { currentStep } = useContext(CheckoutStepsContext);

  return (
    <div className="checkout-steps">
      <div>
        {React.Children.map(children, (child, index) => {
          if (child?.type !== CheckoutStepsStep) {
            let name = (child?.type as any)?.name ?? child?.type;
            if (name) name = `<${name}> component`;
            else name = `"${child}"`;
            throw `Error: Every CheckoutSteps child must be a CheckoutSteps.Step component. ${name} found on position ${index}`;
          }

          const { title, description, onEnter, children } = child.props;
          return (
            <CheckoutStepsStepContext.Provider value={{ index }}>
              {index === currentStep && (
                <CheckoutStepsContent
                  title={title}
                  description={description}
                  onEnter={onEnter}
                >
                  {children}
                </CheckoutStepsContent>
              )}
              {child}
            </CheckoutStepsStepContext.Provider>
          );
        })}
      </div>
    </div>
  );
}

interface Props {
  children: ReactElement | Array<ReactElement>;
}

CheckoutSteps.Navigation = CheckoutStepsNavigation;
CheckoutSteps.Step = CheckoutStepsStep;
CheckoutSteps.Provider = Provider;
CheckoutSteps.Continue = CheckoutStepsContinue;
CheckoutSteps.Back = CheckoutStepsBack;
CheckoutSteps.Breadcrumb = CheckoutStepsBreadcrumb;
CheckoutSteps.Breadcrumbs = CheckoutStepsBreadcrumbs;
CheckoutSteps.Header = CheckoutStepsHeader;
