import { css, withBem } from "../../sitches.config";

export const styles = withBem("shared-duration-input", {
  sharedDurationInput: css({
    display: "inline-flex",
    alignItems: "center",
    variants: {
      width: {
        full: {
          width: "100%"
        },
        auto: {}
      },
      size: {
        medium: {
          minWidth: "264px"
        },
        small: {
          minWidth: "224px"
        }
      }
    }
  }),
  selectBox: css({
    flexShrink: 0,
    flexBasis: 0,
    "&:first-child": {
      flexGrow: 4,
      marginRight: "-1px",
      ".react-select__control": {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRight: "none"
      }
    },
    "&:last-child": {
      flexGrow: 5,
      ".react-select__control": {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0
      }
    }
  })
});
