import React from "react";
import { bemModule } from "../../configs/bem";
import styles from "./ProductLearningMaterials.module.less";
import Icon from "../Icon";
import * as Icons from "../Icons";
import { CloudinaryImage } from "../CloudinaryImage";

const cn = bemModule(styles, "product-learning-materials");
export function ProductLearningMaterials({
  learningContent = [],
  productCover
}) {
  const learningMaterialsTypes = learningContent.map(
    content => content.contentType
  );
  const guide = learningContent.find(
    content => content.contentType === "guide"
  );
  const videoContent = learningContent.find(
    content => content.contentType === "video"
  );

  return (
    <div className={cn()}>
      <div className={cn("wrapper")}>
        <div className="row">
          {guide && (
            <div
              className={`col-12 ${
                learningContent.length === 1 ? "col-md-6" : "col-md-5"
              }`}
            >
              <div className="col-12">
                <div className={cn("title", null, ["pb-3"])}>
                  Learn step by step
                </div>
                <div className={cn("video-thumbnail-wrapper")}>
                  <CloudinaryImage
                    className={cn("video-thumbnail")}
                    src={
                      guide.thumbnailURL ||
                      "https://res.cloudinary.com/obby/image/upload/v1612270194/Image_from_iOS_tn2ctd.jpg"
                    }
                    width={272}
                    height={292}
                  />
                </div>
                <div className={cn("note")}>
                  <h4 className="my-3">Written Learning Guide</h4>
                  <p className={cn("note-description", null, ["my-3"])}>
                    A link to your written learning guide will be sent to you
                    immediately once you have completed your purchase.
                  </p>
                </div>
              </div>
            </div>
          )}
          {learningContent.length > 1 ? (
            <div
              className={`col-12 col-md-2 ${cn("middle-section")} ${cn(
                "title"
              )}`}
            >
              AND
            </div>
          ) : null}
          {videoContent && (
            <div
              className={`col-12 ${
                learningContent.length === 1 ? "col-md-6" : "col-md-5"
              }`}
            >
              <div className="col-12">
                <div className={cn("title", null, ["pb-3"])}>
                  Watch video guide
                </div>
                <div className={cn("video-thumbnail-wrapper")}>
                  <CloudinaryImage
                    className={cn("video-thumbnail")}
                    src={videoContent.thumbnailURL || productCover}
                    width={272}
                    height={292}
                  />
                  <Icon
                    className={cn("play-icon")}
                    icon={Icons.PlayRounded}
                    height={80}
                  />
                </div>
                <div className={cn("note")}>
                  <h4 className="my-3">Pre-recorded Video Content</h4>
                  <p className={cn("note-description", null, ["my-3"])}>
                    Links, passwords and instructions on how to use your Video
                    Learning Content will be sent to you automatically once you
                    have completed your purchase.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
