export const TicketTypeBookingTypes = {
  APPOINTMENT: "appointment",
  BUNDLE: "bundle",
  DROP_IN: "drop-in",
  // @deprecated - flexitime type will be replaced by appointment type
  FLEXITIME: "flexitime",
  REGULAR: "regular",
  TRIAL: "trial",
  GROUP: "group"
};
