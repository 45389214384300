import React, { useRef } from "react";
import { faCopy } from "@fortawesome/free-solid-svg-icons/faCopy";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { useCopyText } from "../../hooks/useCopyText";
import { FaIcon } from "../../icons/FaIcon";
import { TextInput } from "../TextInput";
import { styles } from "./CopyInput.styles";

export function CopyInput({ size = "medium", value }: Props) {
  const input = useRef<HTMLInputElement | null>(null);

  const { isCopied, copyToClipboard } = useCopyText();

  async function onCopy() {
    try {
      await copyToClipboard(value);
    } catch {
      if (input.current !== null) {
        input.current.focus();
      }
    }
  }

  function onFocus() {
    if (input.current !== null) {
      input.current.select();
    }
  }

  return (
    <div className={styles.container()}>
      <TextInput
        onFocus={onFocus}
        readOnly
        value={value}
        size={size}
        compound="left"
      />

      <button
        type="button"
        onClick={onCopy}
        className={styles.button({ size })}
      >
        <FaIcon icon={isCopied ? faCheck : faCopy} />
      </button>
    </div>
  );
}

interface Props {
  value: string;
  size?: "small" | "medium";
}
