import { css, withBem } from "../../sitches.config";

export const styles = withBem("responsive-class-section", {
  responsiveClassSection: css({
    variants: {
      fluid: {
        true: {
          ".responsive-class-section__header": {
            "@md": {
              flexDirection: "row"
            }
          }
        }
      }
    },

    ".row--gallery > *": {
      paddingLeft: "0px"
    }
  }),
  header: css({
    display: "flex",
    flexWrap: "wrap",
    paddingBottom: "$8",
    "@sm": {
      paddingBottom: "initial"
    },

    "@md": {
      flexDirection: "column"
    }
  }),

  title: css({
    fontSize: "$28",
    fontWeight: "bold",
    lineHeight: "100%",
    color: "$ebonyClay",
    letterSpacing: "0.01em",
    textTransform: "uppercase",
    fontFamily: "$foundersGroteskCondensed",
    paddingRight: "25px",
    paddingBottom: "15px",

    "@xl": {
      fontSize: "$32"
    }
  }),

  subtitle: css({
    paddingBottom: "$16",

    "@xl": {
      fontSize: "25px"
    }
  })
});
