import React from "react";
// Lib
import {
  String__TruncatedString,
  Number__Currency,
  Discount__DiscountedPrice,
  CurrencyISO__CurrencySymbol
} from "@obby/lib";
// Components
import { Star } from "../Icons";
import Icon from "../Icon/Icon";
import Link from "../Link";
import FavoriteButton from "../FavouriteButton";

import { styles } from "./ClassCard.styles";
import HighlightPrice from "../HighlightPrice";
import { AspectRatio } from "../../boxes/AspectRatio";
import { Skeleton } from "../Skeleton";

export default function ClassCard(props) {
  const {
    addToWishList = true,
    label,
    thumbnail,
    url,
    title,
    rating,
    isOnline,
    requiresSendingPhysicalMaterial,
    numberOfReviews,
    location,
    price,
    currency = "GBP",
    samePriceAlways = true,
    titleLength = 38,
    isFavourite,
    onClick,
    discount,
    isProduct,
    onAddToWishList = () => {},
    onRemoveFromWishList = () => {},
    skeleton
  } = props;

  const onFavouriteToggle = e => {
    e.stopPropagation();
    if (isFavourite) onRemoveFromWishList();
    else onAddToWishList();
  };

  return (
    <div className={styles.classCard()} onClick={onClick}>
      {/* IMAGE */}
      {!skeleton && addToWishList && (
        <FavoriteButton
          className={styles.favouriteButton()}
          checked={isFavourite}
          onToggle={onFavouriteToggle}
        />
      )}
      <Link
        draggable={false}
        onDragStart={e => e.preventDefault()}
        href={!skeleton ? url : undefined}
      >
        {skeleton ? (
          <AspectRatio height={300} width={450}>
            <Skeleton dark sharp stretch />
          </AspectRatio>
        ) : (
          <img
            className={styles.thumbnailImage()}
            alt={label ? `${label}'s class image` : ""}
            src={thumbnail}
            loading="lazy"
          />
        )}

        {/* DETAILS */}
        <div className={styles.content()}>
          {/* TITLE */}
          <span title={title} className={styles.title()}>
            {skeleton ? (
              <Skeleton dark />
            ) : (
              String__TruncatedString({ length: titleLength }, title)
            )}
          </span>
          {/* REVIEWS */}
          <div className={styles.reviews()}>
            {skeleton ? (
              <div className={styles.reviewsSkeleton()}>
                <Skeleton dark />
              </div>
            ) : (
              <>
                {numberOfReviews > 0 && (
                  <>
                    <Icon
                      className={styles.ratingStar()}
                      icon={Star}
                      height={13}
                    />{" "}
                    <div className={styles.ratingScore()}>{rating}</div>
                    <span className={styles.opaqueText()}>
                      ({numberOfReviews})
                      {(isOnline || location) && (
                        <>
                          {" "}
                          <span className={styles.pipe()}>-</span>{" "}
                        </>
                      )}
                    </span>
                  </>
                )}
                {isOnline ? (
                  <span className={styles.opaqueText()}>{`Live Online${
                    requiresSendingPhysicalMaterial ? " + Kit Included" : ""
                  }`}</span>
                ) : (
                  location && (
                    <span className={styles.opaqueText()}>{location}</span>
                  )
                )}
              </>
            )}
          </div>
          {/* PRICE */}
          <div className={`${styles.price} ${styles.opaqueText}`}>
            {skeleton ? (
              <div className={styles.priceSkeleton()}>
                <Skeleton dark />
              </div>
            ) : (
              <>
                {" "}
                {discount && (
                  <HighlightPrice
                    price={Discount__DiscountedPrice(discount, price)}
                    currency={currency}
                    pp
                  />
                )}
                <span
                  className={styles.price({
                    withDiscount: discount !== undefined
                  })}
                >
                  {!samePriceAlways && "from "}
                  {Number__Currency(
                    price,
                    CurrencyISO__CurrencySymbol(currency)
                  )}
                  {!isProduct && "pp"}
                </span>
              </>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
}
