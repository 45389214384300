const String__UpperCaseFirstLetter = word => {
  if (typeof word === "string" && word.length === 1) {
    return word.toUpperCase();
  }
  if (typeof word === "string" && word.length > 1) {
    return word[0].toUpperCase() + word.slice(1);
  }

  return "";
};

export default String__UpperCaseFirstLetter;
