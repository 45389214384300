import { Countries } from "./Countries";
export const CountriesNames = {
  [Countries.UK]: "United Kingdom",
  [Countries.AF]: "Afghanistan",
  [Countries.AX]: "Aland Islands",
  [Countries.AL]: "Albania",
  [Countries.DZ]: "Algeria",
  [Countries.AS]: "American Samoa",
  [Countries.AD]: "Andorra",
  [Countries.AO]: "Angola",
  [Countries.AI]: "Anguilla",
  [Countries.AQ]: "Antarctica",
  [Countries.AG]: "Antigua and Barbuda",
  [Countries.AR]: "Argentina",
  [Countries.AM]: "Armenia",
  [Countries.AW]: "Aruba",
  [Countries.AU]: "Australia",
  [Countries.AT]: "Austria",
  [Countries.AZ]: "Azerbaijan",
  [Countries.BS]: "Bahamas",
  [Countries.BH]: "Bahrain",
  [Countries.BD]: "Bangladesh",
  [Countries.BB]: "Barbados",
  [Countries.BY]: "Belarus",
  [Countries.BE]: "Belgium",
  [Countries.BZ]: "Belize",
  [Countries.BJ]: "Benin",
  [Countries.BM]: "Bermuda",
  [Countries.BT]: "Bhutan",
  [Countries.BO]: "Bolivia",
  [Countries.BQ]: "Bonaire, Sint Eustatius and Saba",
  [Countries.BA]: "Bosnia and Herzegovina",
  [Countries.BW]: "Botswana",
  [Countries.BV]: "Bouvet Island",
  [Countries.BR]: "Brazil",
  [Countries.IO]: "British Indian Ocean Territory",
  [Countries.BN]: "Brunei Darussalam",
  [Countries.BG]: "Bulgaria",
  [Countries.BF]: "Burkina Faso",
  [Countries.BI]: "Burundi",
  [Countries.KH]: "Cambodia",
  [Countries.CM]: "Cameroon",
  [Countries.CA]: "Canada",
  [Countries.CV]: "Cape Verde",
  [Countries.KY]: "Cayman Islands",
  [Countries.CF]: "Central African Republic",
  [Countries.TD]: "Chad",
  [Countries.CL]: "Chile",
  [Countries.CN]: "China",
  [Countries.CX]: "Christmas Island",
  [Countries.CC]: "Cocos (Keeling) Islands",
  [Countries.CO]: "Colombia",
  [Countries.KM]: "Comoros",
  [Countries.CG]: "Congo",
  [Countries.CD]: "Congo, Democratic Republic of the Congo",
  [Countries.CK]: "Cook Islands",
  [Countries.CR]: "Costa Rica",
  [Countries.CI]: "Cote D'Ivoire",
  [Countries.HR]: "Croatia",
  [Countries.CU]: "Cuba",
  [Countries.CW]: "Curacao",
  [Countries.CY]: "Cyprus",
  [Countries.CZ]: "Czech Republic",
  [Countries.DK]: "Denmark",
  [Countries.DJ]: "Djibouti",
  [Countries.DM]: "Dominica",
  [Countries.DO]: "Dominican Republic",
  [Countries.EC]: "Ecuador",
  [Countries.EG]: "Egypt",
  [Countries.SV]: "El Salvador",
  [Countries.GQ]: "Equatorial Guinea",
  [Countries.ER]: "Eritrea",
  [Countries.EE]: "Estonia",
  [Countries.ET]: "Ethiopia",
  [Countries.FK]: "Falkland Islands (Malvinas)",
  [Countries.FO]: "Faroe Islands",
  [Countries.FJ]: "Fiji",
  [Countries.FI]: "Finland",
  [Countries.FR]: "France",
  [Countries.GF]: "French Guiana",
  [Countries.PF]: "French Polynesia",
  [Countries.TF]: "French Southern Territories",
  [Countries.GA]: "Gabon",
  [Countries.GM]: "Gambia",
  [Countries.GE]: "Georgia",
  [Countries.DE]: "Germany",
  [Countries.GH]: "Ghana",
  [Countries.GI]: "Gibraltar",
  [Countries.GR]: "Greece",
  [Countries.GL]: "Greenland",
  [Countries.GD]: "Grenada",
  [Countries.GP]: "Guadeloupe",
  [Countries.GU]: "Guam",
  [Countries.GT]: "Guatemala",
  [Countries.GG]: "Guernsey",
  [Countries.GN]: "Guinea",
  [Countries.GW]: "Guinea-Bissau",
  [Countries.GY]: "Guyana",
  [Countries.HT]: "Haiti",
  [Countries.HM]: "Heard Island and Mcdonald Islands",
  [Countries.VA]: "Holy See (Vatican City State)",
  [Countries.HN]: "Honduras",
  [Countries.HK]: "Hong Kong",
  [Countries.HU]: "Hungary",
  [Countries.IS]: "Iceland",
  [Countries.IN]: "India",
  [Countries.ID]: "Indonesia",
  [Countries.IR]: "Iran, Islamic Republic of",
  [Countries.IQ]: "Iraq",
  [Countries.IE]: "Ireland",
  [Countries.IM]: "Isle of Man",
  [Countries.IL]: "Israel",
  [Countries.IT]: "Italy",
  [Countries.JM]: "Jamaica",
  [Countries.JP]: "Japan",
  [Countries.JE]: "Jersey",
  [Countries.JO]: "Jordan",
  [Countries.KZ]: "Kazakhstan",
  [Countries.KE]: "Kenya",
  [Countries.KI]: "Kiribati",
  [Countries.KP]: "Korea, Democratic People's Republic of",
  [Countries.KR]: "Korea, Republic of",
  [Countries.XK]: "Kosovo",
  [Countries.KW]: "Kuwait",
  [Countries.KG]: "Kyrgyzstan",
  [Countries.LA]: "Lao People's Democratic Republic",
  [Countries.LV]: "Latvia",
  [Countries.LB]: "Lebanon",
  [Countries.LS]: "Lesotho",
  [Countries.LR]: "Liberia",
  [Countries.LY]: "Libyan Arab Jamahiriya",
  [Countries.LI]: "Liechtenstein",
  [Countries.LT]: "Lithuania",
  [Countries.LU]: "Luxembourg",
  [Countries.MO]: "Macao",
  [Countries.MK]: "Macedonia, the Former Yugoslav Republic of",
  [Countries.MG]: "Madagascar",
  [Countries.MW]: "Malawi",
  [Countries.MY]: "Malaysia",
  [Countries.MV]: "Maldives",
  [Countries.ML]: "Mali",
  [Countries.MT]: "Malta",
  [Countries.MH]: "Marshall Islands",
  [Countries.MQ]: "Martinique",
  [Countries.MR]: "Mauritania",
  [Countries.MU]: "Mauritius",
  [Countries.YT]: "Mayotte",
  [Countries.MX]: "Mexico",
  [Countries.FM]: "Micronesia, Federated States of",
  [Countries.MD]: "Moldova, Republic of",
  [Countries.MC]: "Monaco",
  [Countries.MN]: "Mongolia",
  [Countries.ME]: "Montenegro",
  [Countries.MS]: "Montserrat",
  [Countries.MA]: "Morocco",
  [Countries.MZ]: "Mozambique",
  [Countries.MM]: "Myanmar",
  [Countries.NA]: "Namibia",
  [Countries.NR]: "Nauru",
  [Countries.NP]: "Nepal",
  [Countries.NL]: "Netherlands",
  [Countries.AN]: "Netherlands Antilles",
  [Countries.NC]: "New Caledonia",
  [Countries.NZ]: "New Zealand",
  [Countries.NI]: "Nicaragua",
  [Countries.NE]: "Niger",
  [Countries.NG]: "Nigeria",
  [Countries.NU]: "Niue",
  [Countries.NF]: "Norfolk Island",
  [Countries.MP]: "Northern Mariana Islands",
  [Countries.NO]: "Norway",
  [Countries.OM]: "Oman",
  [Countries.PK]: "Pakistan",
  [Countries.PW]: "Palau",
  [Countries.PS]: "Palestinian Territory, Occupied",
  [Countries.PA]: "Panama",
  [Countries.PG]: "Papua New Guinea",
  [Countries.PY]: "Paraguay",
  [Countries.PE]: "Peru",
  [Countries.PH]: "Philippines",
  [Countries.PN]: "Pitcairn",
  [Countries.PL]: "Poland",
  [Countries.PT]: "Portugal",
  [Countries.PR]: "Puerto Rico",
  [Countries.QA]: "Qatar",
  [Countries.RE]: "Reunion",
  [Countries.RO]: "Romania",
  [Countries.RU]: "Russian Federation",
  [Countries.RW]: "Rwanda",
  [Countries.BL]: "Saint Barthelemy",
  [Countries.SH]: "Saint Helena",
  [Countries.KN]: "Saint Kitts and Nevis",
  [Countries.LC]: "Saint Lucia",
  [Countries.MF]: "Saint Martin",
  [Countries.PM]: "Saint Pierre and Miquelon",
  [Countries.VC]: "Saint Vincent and the Grenadines",
  [Countries.WS]: "Samoa",
  [Countries.SM]: "San Marino",
  [Countries.ST]: "Sao Tome and Principe",
  [Countries.SA]: "Saudi Arabia",
  [Countries.SN]: "Senegal",
  [Countries.RS]: "Serbia",
  [Countries.CS]: "Serbia and Montenegro",
  [Countries.SC]: "Seychelles",
  [Countries.SL]: "Sierra Leone",
  [Countries.SG]: "Singapore",
  [Countries.SX]: "Sint Maarten",
  [Countries.SK]: "Slovakia",
  [Countries.SI]: "Slovenia",
  [Countries.SB]: "Solomon Islands",
  [Countries.SO]: "Somalia",
  [Countries.ZA]: "South Africa",
  [Countries.GS]: "South Georgia and the South Sandwich Islands",
  [Countries.SS]: "South Sudan",
  [Countries.ES]: "Spain",
  [Countries.LK]: "Sri Lanka",
  [Countries.SD]: "Sudan",
  [Countries.SR]: "Suriname",
  [Countries.SJ]: "Svalbard and Jan Mayen",
  [Countries.SZ]: "Swaziland",
  [Countries.SE]: "Sweden",
  [Countries.CH]: "Switzerland",
  [Countries.SY]: "Syrian Arab Republic",
  [Countries.TW]: "Taiwan, Province of China",
  [Countries.TJ]: "Tajikistan",
  [Countries.TZ]: "Tanzania, United Republic of",
  [Countries.TH]: "Thailand",
  [Countries.TL]: "Timor-Leste",
  [Countries.TG]: "Togo",
  [Countries.TK]: "Tokelau",
  [Countries.TO]: "Tonga",
  [Countries.TT]: "Trinidad and Tobago",
  [Countries.TN]: "Tunisia",
  [Countries.TR]: "Turkey",
  [Countries.TM]: "Turkmenistan",
  [Countries.TC]: "Turks and Caicos Islands",
  [Countries.TV]: "Tuvalu",
  [Countries.UG]: "Uganda",
  [Countries.UA]: "Ukraine",
  [Countries.AE]: "United Arab Emirates",
  [Countries.US]: "United States",
  [Countries.UM]: "United States Minor Outlying Islands",
  [Countries.UY]: "Uruguay",
  [Countries.UZ]: "Uzbekistan",
  [Countries.VU]: "Vanuatu",
  [Countries.VE]: "Venezuela",
  [Countries.VN]: "Viet Nam",
  [Countries.VG]: "Virgin Islands, British",
  [Countries.VI]: "Virgin Islands, U.s.",
  [Countries.WF]: "Wallis and Futuna",
  [Countries.EH]: "Western Sahara",
  [Countries.YE]: "Yemen",
  [Countries.ZM]: "Zambia",
  [Countries.ZW]: "Zimbabwe"
};
