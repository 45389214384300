import { BookingReschedulePanelErrorCodeView } from "./BookingReschedulePanelErrorCodeView";
import { BookingReschedulePanelEntry } from "./BookingReschedulePanelEntry";

export function BookingReschedulePanel({
  booking,
  cancellationPolicyUrl = "/terms",
  canCancel,
  course,
  errorCode,
  locations,
  onCancelBooking,
  onDone,
  onLogin = () => {},
  onReschedule,
  session,
  sessions,
  tutors,
  ticketTypes,
  unavailableTimeBlocks,
}) {
  console.log("first ", course);
  return (
    <>
      {errorCode && (
        <BookingReschedulePanelErrorCodeView
          errorCode={errorCode}
          onLogin={onLogin}
          cancellationPolicyUrl={cancellationPolicyUrl}
        />
      )}
      {!errorCode && (
        <BookingReschedulePanelEntry
          booking={booking}
          cancellationPolicyUrl={cancellationPolicyUrl}
          canCancel={canCancel}
          course={course}
          errorCode={errorCode}
          locations={locations}
          onCancelBooking={onCancelBooking}
          onDone={onDone}
          onLogin={onLogin}
          onReschedule={onReschedule}
          session={session}
          sessions={sessions}
          tutors={tutors}
          ticketTypes={ticketTypes}
          unavailableTimeBlocks={unavailableTimeBlocks}
        />
      )}
    </>
  );
}
