import { css } from "../../sitches.config";
import React, { useEffect, useMemo, useState } from "react";
import { SessionDateRow } from "../SessionDateRow";
import { Timezones, Currencies } from "@obby/constants";
import { UTCDate__FormattedDate } from "@obby/lib";

export function SessionDateList(props) {
  const {
    onChange,
    maxSelection = 1,
    switchable = true,
    currency = Currencies.GBP,
    timezone = Timezones.EUROPE_LONDON
  } = props;
  const [selection, setSelection] = useState(props.selection || []);

  useEffect(() => {
    setSelection(props.selection || []);
  }, [props.selection]);

  const sessions = useMemo(
    () =>
      (props.sessions || [])
        // Make sure all items are sorted by date
        .sort(
          (item1, item2) =>
            (item1.date > item2.date) - (item1.date < item2.date)
        )
        // Group items by month
        .reduce((items, item) => {
          const month = UTCDate__FormattedDate(item.date, timezone, "MMMM");
          const year = UTCDate__FormattedDate(item.date, timezone, "YYYY");
          return {
            ...items,
            [year]: {
              ...(items[year] ?? []),
              [month]: [...(items[year]?.[month] ?? []), item]
            }
          };
        }, {}),
    [props.sessions]
  );

  function onRowClick(sessionId) {
    let newSelection = [...selection];
    if (selection.includes(sessionId)) {
      newSelection.splice(newSelection.indexOf(sessionId), 1);
    } else {
      newSelection.push(sessionId);
      if (selection.length === maxSelection) newSelection.shift();
    }

    setSelection(newSelection);
    onChange(newSelection);
  }
  return (
    <div>
      {Object.entries(sessions).map(([year, sessions]) => {
        return Object.entries(sessions).map(([month, sessions]) => {
          return (
            <div key={month} className={styles.month()}>
              <h4 className={styles.monthHeader()}>
                {month} {year}
              </h4>
              <ul className={styles.list()}>
                {sessions.map(session => (
                  <li key={session._id} className={styles.item()}>
                    <SessionDateRow
                      currency={currency}
                      key={session._id}
                      {...session}
                      selected={
                        session.selected || selection.includes(session._id)
                      }
                      disabled={
                        session.disabled ||
                        (selection.length === maxSelection &&
                          !selection.includes(session._id) &&
                          !switchable)
                      }
                      onClick={onRowClick}
                      openWaitingListPopup={
                        typeof props.openWaitingListPopup === "function"
                          ? () => props.openWaitingListPopup(session)
                          : undefined
                      }
                      timezone={timezone}
                    />
                  </li>
                ))}
              </ul>
            </div>
          );
        });
      })}
    </div>
  );
}

const styles = {
  month: css({
    paddingTop: 16,
    marginBottom: 40,
    borderTop: "1px solid rgba(40, 40, 60, 0.1)"
  }),

  monthHeader: css({
    display: "block",
    fontFamily: "$foundersGroteskCondensed",
    fontSize: "1.31rem",
    letterSpacing: "0.01em",
    textTransform: "uppercase",
    marginBottom: 16,

    "@md": {
      fontSize: "1.68rem"
    }
  }),

  list: css({
    padding: 0,
    margin: 0,
    listStyle: "none"
  }),

  item: css({
    display: "block",
    "&:not(:last-child)": {
      marginBottom: 8
    }
  })
};
