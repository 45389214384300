import React from "react";
import { pipe, find, propEq, prop } from "ramda";

import Icon from "./../Icon";
import Icons from "./../Icons";
import styles from "./Popout.module.less";

export default class Popout extends React.Component {
  constructor(props) {
    super(props);
    this.onOutsideClickCloseListener = this.onOutsideClickClose.bind(this);
    this.state = {
      isOpen: false
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.onOutsideClickCloseListener);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.onOutsideClickCloseListener);
  }

  setComponentWrapReference(node) {
    this.wrapReference = node;
  }

  onOutsideClickClose(event) {
    if (this.wrapReference && !this.wrapReference.contains(event.target)) {
      this.close();
    }
  }

  getLabelOfSelectedValue() {
    return pipe(
      find(propEq("value", this.props.value)),
      option => option.selectionLabel || option.label
    )(this.props.options);
  }

  close() {
    this.setState({
      isOpen: false
    });
  }

  onToggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  onSelectOption(option) {
    this.props.onChange(option);
    this.close();
  }

  render() {
    return (
      <div
        className={styles["popout"]}
        ref={this.setComponentWrapReference.bind(this)}
      >
        <div
          className={`
          ${styles[`popout__face`]}
          ${styles[`popout__face--${this.state.isOpen ? "open" : "closed"}`]}

          `}
          onClick={this.onToggle.bind(this)}
        >
          <div className={styles["popout__face-text"]}>
            {this.getLabelOfSelectedValue()}
          </div>
          <div
            className={`
            ${styles[`popout__face-icon`]}
            ${
              styles[
                `popout__face-icon--${this.state.isOpen ? "open" : "closed"}`
              ]
            }

            `}
          >
            <Icon icon={Icons.FlatArrow} height={5} />
          </div>
        </div>
        {this.state.isOpen && (
          <div
            className={`
            ${styles[`popout__body`]}
            ${
              stylesp[
                `popout__body--${
                  this.props.align === "right" ? "right" : "left"
                }`
              ]
            }

            `}
          >
            {this.props.options.map((option, index) => (
              <div
                key={index}
                className={styles["popout__option"]}
                onClick={() => this.onSelectOption(option)}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}
