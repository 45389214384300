import { useContext } from "react";
import { StripePaymentContext } from "./StripePayment.context";

export function useStripePayment() {
  const {
    isLoading,
    isSubmitting,
    error,
    getPaymentIntent,
    setPaymentIntent,
    submitPayment
  } = useContext(StripePaymentContext);

  return {
    isReady: !isLoading && !isSubmitting && !error,
    getPaymentIntent,
    setPaymentIntent,
    submitPayment
  };
}
