import { TextArea } from "../../inputs/TextArea";
import { NumberInput } from "../../inputs/NumberInput";
import { SelectBox, Option } from "../../inputs/SelectBox";
import { Label } from "../../components/Label";
import _ from "lodash";
import { useEffect, useState } from "react";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import { SecondaryButton } from "../../buttons/SecondaryButton";
import { css } from "../../sitches.config";
import { BehaviorNextQuestionClick } from "@obby/constants";

export const styles = {
  questionNavigationWrapper: css({
    display: "flex",
    marginTop: "10px",
    justifyContent: "space-between"
  })
};

export function UserFormQuestionary({
  values,
  onChange,
  questions,
  mode = "all", // all or oneAtTime
  onFormValidation
}) {
  // they should be already sorted but still lets prevent
  let sortedQuestions = _.sortBy(questions, ["order"]);
  //
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isFirst, setIsFirst] = useState(true);
  const [isLast, setIsLast] = useState(false);
  const [visitedQuestionsIndex, setVisitedQuestionsIndex] = useState([]);
  // helper field to know wich single select option the use choose
  const [
    singleSelectOptionIndexChoosen,
    setSingleSelectOptionIndexChoosen
  ] = useState(false);

  function isQuestionValueValid(questionIndex) {
    let isQuestionValueValid = true;
    if (sortedQuestions[questionIndex].isRequired) {
      if (values.userInputsValues[questionIndex] == "") {
        isQuestionValueValid = false;
      }
    }
    return isQuestionValueValid;
  }

  useEffect(() => {
    let isUserFormValid = false;

    // so we will check if the validation of each is alright
    if (mode == "oneAtTime") {
      isUserFormValid = isLast;
    }
    if (mode == "all") {
      isUserFormValid = sortedQuestions.every((question, index, array) => {
        return isQuestionValueValid(index);
      });
    }
    // check that we have the form validation
    onFormValidation && onFormValidation(isUserFormValid);
  }, [values, isLast]);

  function getNextQuestionIndex() {
    if (mode == "all") {
      return currentQuestionIndex + 1;
    }
    if (mode == "oneAtTime") {
      let currentQuestion = sortedQuestions[currentQuestionIndex];
      // START SINGLE SELECT PRIORITY first lets check the single select priority
      let isSingleSelect = currentQuestion.typeOfInput == "singleSelect";
      if (isSingleSelect) {
        let singleSelectTargetQuestionName =
          currentQuestion.singleSelectOptions[singleSelectOptionIndexChoosen]
            ?.postOptionSelectedTarget;
        if (
          singleSelectTargetQuestionName &&
          singleSelectTargetQuestionName != ""
        ) {
          // set index of the question to the next one
          let targetQuestionIndex = sortedQuestions.findIndex(question => {
            return question.name == singleSelectTargetQuestionName;
          });
          if (targetQuestionIndex > -1) {
            return targetQuestionIndex;
          }
        }
      }
      // END Single select Priorioty
      console.log("currentQuestion", currentQuestion);
      let behaviorAfterQuestion = currentQuestion.behaviorAfterQuestion;
      if (behaviorAfterQuestion == BehaviorNextQuestionClick.NEXT_QUESTION) {
        return currentQuestionIndex + 1;
      }
      if (behaviorAfterQuestion == BehaviorNextQuestionClick.END_FORM) {
        // it will go to end
        return sortedQuestions.length;
      }
      if (
        behaviorAfterQuestion == BehaviorNextQuestionClick.SPECIFIC_QUESTION
      ) {
        // set index of the question to the next one
        let targetQuestionIndex = sortedQuestions.findIndex(question => {
          return (
            question.name == currentQuestion.specificNextClickQuestionAnchor
          );
        });
        if (targetQuestionIndex > -1) {
          return targetQuestionIndex;
        }
      }
    }
  }

  function previousQuestion() {
    // @note we could store the index of each visited questions in array and keep poping out the last, like an undo/redo
    // get index of the last
    let nextVisitedQuestions = [...visitedQuestionsIndex];
    let nextQuestionIndex = nextVisitedQuestions.pop();
    setVisitedQuestionsIndex(nextVisitedQuestions);
    let isFirst = nextQuestionIndex == 0;
    setIsFirst(isFirst);
    let isLast = nextQuestionIndex >= sortedQuestions.length;
    setIsLast(isLast);
    setCurrentQuestionIndex(nextQuestionIndex);
  }

  function nextQuestion() {
    // store the current one as visited so we can previous
    setVisitedQuestionsIndex([...visitedQuestionsIndex, currentQuestionIndex]);
    let nextQuestionIndex = getNextQuestionIndex();
    let isFirst = nextQuestionIndex == 0;
    setIsFirst(isFirst);
    let isLast = nextQuestionIndex >= sortedQuestions.length;
    setIsLast(isLast);
    setCurrentQuestionIndex(nextQuestionIndex);
  }

  return (
    <div>
      {/** Display all questions in one go */}
      {mode == "all" &&
        sortedQuestions.map((question, index) => {
          return renderQuestion({
            question,
            index,
            values,
            onChange,
            setSingleSelectOptionIndexChoosen
          });
        })}
      {/* Display the question in an single at the time*/}
      <div>
        {mode == "oneAtTime" && isLast && (
          <div>
            Thank you for providing us with this information. To continue with
            your booking, please click the Continue button at the bottom of the
            page. If this button is greyed out, it is because you have not
            provided all the information we require, so please go back and
            double check.
          </div>
        )}
        {mode == "oneAtTime" &&
          !isLast &&
          renderQuestion({
            question: sortedQuestions[currentQuestionIndex],
            index: currentQuestionIndex,
            values,
            onChange,
            setSingleSelectOptionIndexChoosen
          })}
        <div className={styles.questionNavigationWrapper()}>
          {mode == "oneAtTime" && !isFirst && (
            <SecondaryButton
              text="Previous"
              width="auto"
              onClick={() => {
                previousQuestion();
              }}
            />
          )}
          {mode == "oneAtTime" && !isLast && (
            <PrimaryButton
              color={
                isQuestionValueValid(currentQuestionIndex)
                  ? "callToAction"
                  : "white"
              }
              width="auto"
              text="Next"
              onClick={() => {
                nextQuestion();
              }}
              disabled={!isQuestionValueValid(currentQuestionIndex)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function renderQuestion({
  question,
  index,
  values,
  onChange,
  setSingleSelectOptionIndexChoosen
}) {
  if (question.typeOfInput == "shortTextInput") {
    return renderShortTextInput({
      key: index,
      label: question.title,
      value: values.userInputsValues[index],
      onChange: value => onChange(value, `userInputsValues.${index}`)
    });
  }
  if (question.typeOfInput == "singleSelect") {
    return renderSingleSelectTextInput({
      key: index,
      label: question.title,
      value: values.userInputsValues[index],
      onChange: value => {
        let indexOfSelectedOption = question.singleSelectOptions.findIndex(
          option => option.name == value
        );
        if (indexOfSelectedOption != -1) {
          setSingleSelectOptionIndexChoosen(indexOfSelectedOption);
        }
        onChange(value, `userInputsValues.${index}`);
      },
      options: question.singleSelectOptions
    });
  }

  if (question.typeOfInput == "numberInput") {
    return renderNumberTextInput({
      key: index,
      label: question.title,
      value: values.userInputsValues[index],
      onChange: value => {
        onChange(value, `userInputsValues.${index}`);
      }
    });
  }
}

function renderSingleSelectTextInput({
  key,
  label,
  description,
  onChange,
  value,
  options
}) {
  return (
    <div key={key}>
      <Label label={label} description={description} condensed>
        <SelectBox
          name="option"
          onChange={onChange}
          value={value}
          accent="camelot"
        >
          {options.map(option => (
            <Option key={option._id} value={option.name}>
              {option.name}
            </Option>
          ))}
        </SelectBox>
      </Label>
    </div>
  );
}

function renderNumberTextInput({ key, label, description, value, onChange }) {
  return (
    <div key={key}>
      <Label label={label} description={description} condensed>
        <NumberInput value={value} onChange={onChange} />
      </Label>
    </div>
  );
}

function renderShortTextInput({ key, label, description, value, onChange }) {
  return (
    <div key={key}>
      <Label label={label} description={description} condensed>
        <TextArea
          placeholder="Please enter your information here"
          value={value}
          onChange={onChange}
        />
      </Label>
    </div>
  );
}
