import React from "react";

import styles from "./TextSticker.module.less";

const TextSticker = ({ title, description, background, color }) => {
  return (
    <div
      className={`
				${styles["text-sticker"]}
				${styles[`text-sticker--${color || "text-black"}`]}
        ${styles[`text-sticker--bg-${background || "emeraldish"}`]}
			`}
    >
      <span className={styles["text-sticker__title"]}>{title}</span>
      <span className={styles["text-sticker__description"]}>{description}</span>
    </div>
  );
};

export default TextSticker;
