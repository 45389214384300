import React, { useState, useMemo, useContext } from "react";
import _ from "lodash";
import { SecondaryButton, FaIcon, Flex } from "@obby/components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { styles } from "./TablePaginator.styles";

function getPages(page: number, total?: number) {
  if (!total) return [page];

  const numberOfPages = Math.min(Math.max(total - 2, 0), 7);
  const lowestPage = Math.min(Math.max(page - 3, 2), total - numberOfPages);
  const highestPage = Math.max(
    Math.min(page + 3, total - 1),
    numberOfPages + 1
  );
  const pages: (number | "…")[] = _.range(lowestPage, highestPage + 1);
  if (pages[0] > 2) pages[0] = "…";
  if (highestPage < total - 1) pages[pages.length - 1] = "…";

  return total === 1 ? pages : [1, ...pages, total];
}

export function TablePaginator({
  onChange = () => {},
  total,
  ...props
}: Props) {
  const [page, setPage] = useState(props.page);
  const pages = getPages(page, total);

  function onPreviousPageClick() {
    if (page === 1) return;
    setPage(page - 1);
    onChange(page - 1);
  }

  function onNextPageClick() {
    if (page === total) return;
    setPage(page + 1);
    onChange(page + 1);
  }

  function onPageClick(page: number) {
    setPage(page);
    onChange(page);
  }

  return (
    <Flex gap={1}>
      <Button onClick={onPreviousPageClick} disabled={page === 1}>
        <FaIcon icon={faChevronLeft} />
      </Button>

      {pages.map((currentPage, index) =>
        currentPage === "…" ? (
          <span key={index} className={styles.etc()}>
            …
          </span>
        ) : (
          <Button
            key={index}
            selected={currentPage === page}
            onClick={() => onPageClick(currentPage)}
          >
            {currentPage}
          </Button>
        )
      )}

      <Button onClick={onNextPageClick} disabled={page === total}>
        <FaIcon icon={faChevronRight} />
      </Button>
    </Flex>
  );
}

interface Props {
  page: number;
  onChange?: (page: number) => void;
  total?: number;
}

function Button({
  children,
  disabled,
  onClick,
  selected
}: {
  children: any;
  disabled?: boolean;
  onClick: () => void;
  selected?: boolean;
}) {
  return (
    <div className={styles.buttonWrapper()}>
      <SecondaryButton
        backgroundColorHex={selected ? "#f0f0f0" : undefined}
        color="gallery"
        disabled={disabled}
        noPadding
        onClick={onClick}
        size="tiny"
        square
        text={children}
      />
    </div>
  );
}
