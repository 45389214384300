import React from "react";

import { LoadingIcon } from "../../components/LoadingIcon";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import { SessionDateList } from "../../components/SessionDateList";
import { BundleCheckoutContainer } from "./BundleCheckout.container";
import { Discount__DiscountedPrice } from "@obby/lib";
import { SessionVisibility } from "@obby/constants";

export function DateSelectionStep() {
  const {
    discount,
    bundle,
    sessions,
    isLoading,
    isFullyLoaded,
    ticketType,
    values,
    helpers,
    actions,
    currency,
    timezone
  } = BundleCheckoutContainer.useContainer();

  return (
    <div className="date-selection-step">
      <SessionDateList
        currency={currency}
        switchable={false}
        maxSelection={bundle.numberOfSessions}
        timezone={timezone}
        sessions={sessions.map(session => {
          const tutor = helpers.getTutor(session.tutor);
          const location = helpers.getLocation(session.locationId);

          return {
            _id: session._id,
            date: session.startDateTime,
            description:
              session.availability === 0
                ? "Sold out"
                : `${session.availability} ${
                    session.availability === 1 ? "spot" : "spots"
                  } left`,
            duration: session.duration,
            discount: discount
              ? Discount__DiscountedPrice(discount, ticketType.price)
              : undefined,
            disabled: session.availability === 0,
            isEarlyAccess:
              session.visibility === SessionVisibility.EARLY_ACCESS,
            isOnline: session.isOnline,
            location: session.isOnline ? "Online" : location?.area,
            tutorImageUrl: tutor?.profile?.imageUrl,
            tutorName: tutor?.fullName,
            tutorUrl: tutor?.profile?.links?.bio
          };
        })}
        selection={values.sessionsIds}
        onChange={actions.onSessionSelect}
      />
      {isLoading && <LoadingIcon />}
      {!isFullyLoaded && (
        <PrimaryButton
          text="See more dates"
          color="emeraldish"
          textMode="uppercase"
          width="auto"
          disabled={isLoading}
          onClick={actions.onLoadMoreDates}
        />
      )}
    </div>
  );
}
