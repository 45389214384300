import React, { useEffect, useState } from "react";
import { Box } from "../../layout/Box";
import { CenteredPopup } from "../../modals/CenteredPopup";
import { CheckoutFooterBar } from "../../bars/CheckoutFooterBar";
import { CheckoutPanel } from "../../panels/CheckoutPanel";
import { CheckoutSteps } from "../../navs/CheckoutSteps";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import { SessionDateList } from "../../components/SessionDateList";
import {
  Session__DisplayDateTimeOffset,
  UTCDate__FormattedDate
  // @ts-ignore
} from "@obby/lib";
import { styles } from "./BookingReschedulePanel.styles";
import { PricingOptionsStep } from "../../containers/AppointmentScheduleCheckout/PricingOptionStep";
import moment from "moment-timezone";

export function BookingReschedulePanelErrorCodeView({
  errorCode,
  onLogin,
  cancellationPolicyUrl
}) {
  console.log("errorCode ", errorCode);
  if (errorCode === "USER_NOT_LOGGED_IN") {
    return (
      <div className="container-fluid">
        <Box>
          <h1 className={styles.title()}>
            You need to login/sign up in order to reschedule.
          </h1>
          <p className={styles.paragraph()}>
            In order for you to reschedule this booking you need to login or
            create an account using the same email address you used to book.
          </p>
          <div className="text-center">
            <PrimaryButton
              text="Login/Sign up"
              width="auto"
              onClick={onLogin}
            />
          </div>
        </Box>
      </div>
    );
  }

  if (errorCode === "CANCELLATION_POLICY_ISSUE") {
    return (
      <div className="container-fluid">
        <Box>
          <h1 className={styles.title()}>Unable to reschedule this booking.</h1>
          <p className={styles.paragraph()}>
            This booking cannot be rescheduled due to our cancellation policy.{" "}
            <a href={cancellationPolicyUrl}>Click here</a> to view our full
            terms of booking.
          </p>
        </Box>
      </div>
    );
  }

  if (
    errorCode === "BOOKING_RESCHEDULE_NOTENOUGH_TIME" ||
    errorCode === "BOOKING_RESCHEDULE_TERM_ALREADY_STARTED"
  ) {
    return (
      <div className="container-fluid">
        <Box>
          <h1 className={styles.title()}>Unable to reschedule this booking.</h1>
          <p className={styles.paragraph()}>
            This booking cannot be rescheduled automatically, please get in
            touch with us by replying to your email and we will be able to
            assist you further.
          </p>
        </Box>
      </div>
    );
  }
  if (errorCode === "BOOKING_ALREADY_REFUNDED") {
    return (
      <div className="container-fluid">
        <Box>
          <h1 className={styles.title()}>Unable to reschedule this booking.</h1>
          <p className={styles.paragraph()}>
            This booking can’t be rescheduled as it has already been refunded.
            Please book and pay again to attend.
          </p>
        </Box>
      </div>
    );
  }
  if (errorCode === "CANCELLATION_POLICY_ISSUE") {
    return (
      <div className="container-fluid">
        <Box>
          <h1 className={styles.title()}>Unable to reschedule this booking.</h1>
          <p className={styles.paragraph()}>
            This booking cannot be rescheduled due to our cancellation policy{" "}
            <a href="https://obby.helpscoutdocs.com/article/187-i-need-to-reschedule-my-booking">
              here.
            </a>
          </p>
        </Box>
      </div>
    );
  }
}
