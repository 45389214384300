import React from "react";
import { IndexingContext } from "./IndexingContext";

export function Indexing({ children }: React.PropsWithChildren<Props>) {
  return (
    <>
      {React.Children.map(children, (child, index) => (
        <IndexingContext.Provider value={index}>
          {child}
        </IndexingContext.Provider>
      ))}
    </>
  );
}

interface Props {}
