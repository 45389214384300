import React from "react";
import moment from "moment";
import { BundleCheckoutContainer } from "./BundleCheckout.container";
import { DateSelectionStep } from "./DateSelectionStep";
import { CheckoutFooterBar } from "../../bars/CheckoutFooterBar";
import { CheckoutPanel } from "../../panels/CheckoutPanel";
import { CheckoutPersonalDetailsForm } from "../../forms/CheckoutPersonalDetailsForm";
import { CheckoutSteps } from "../../navs/CheckoutSteps";
import { StripePayment } from "../StripePayment";
import { UTCDate__FormattedDate } from "@obby/lib";
import { useAppText } from "../AppText";
import { UserFormQuestionary } from "../UserFormQuestionary";

export function BundleCheckoutView() {
  const {
    course,
    actions,
    bundle,
    cancellationPolicyUrl,
    courseTitle,
    courseThumbnail,
    credit,
    currency,
    errors,
    helpers,
    isConfirmingFreeBooking,
    isDiscountEnabled,
    isLastStep,
    isNewsletterSignupEnabled,
    isSendingMaterial,
    isStripePaymentReady,
    firstSession,
    touched,
    userInputs,
    values,
    ticketType,
    timezone,
    isOnSales,
    isUserFormValid
  } = BundleCheckoutContainer.useContainer();

  const { __ } = useAppText();

  let expireDate = moment().add(bundle.expiry, "months");
  return (
    <CheckoutSteps.Provider
      onContinue={actions.onContinue}
      onNext={actions.onCheckoutStepChange}
      onPrevious={actions.onCheckoutStepChange}
    >
      <div className="bundle-checkout-popup-view">
        <div className="container-fluid mb-2 mb-md-0">
          <CheckoutSteps.Breadcrumbs />
        </div>
        <CheckoutPanel
          isOnSales={isOnSales}
          cancellationPolicyUrl={cancellationPolicyUrl}
          title={courseTitle}
          thumbnail={courseThumbnail}
          credit={credit}
          currency={currency}
          description={helpers.getFormattedDates().map((date, index) => (
            <div key={index}>{date}</div>
          ))}
          discountError={touched.discountCode && errors.discountCode}
          discountValue={helpers.getDiscount()}
          discountPercentage={helpers.getDiscountPercentage()}
          isDiscountEnabled={isDiscountEnabled}
          isDiscountInvalid={helpers.isDiscountInvalid()}
          onApplyDiscount={actions.onApplyDiscount}
          personalDetails={
            isLastStep ? values.personalDetails.guests[0] : undefined
          }
          sidebarOnTop={isLastStep}
          items={[
            {
              ...ticketType,
              count: 1
            }
          ]}
          total={helpers.getTotal()}
        >
          <CheckoutSteps>
            <CheckoutSteps.Step
              breadcrumb="Date selection"
              title="Choose your first class"
              description={
                <>
                  {__(
                    "Pick your first date of the bundle. Future dates can be selected after payment from the Bookings section in your Obby account, or select them all now to avoid missing out on space!"
                  )}{" "}
                  <b>
                    You will have {bundle.expiry} months to select your dates,
                    until {expireDate.format("DD/MM/YYYY")}
                  </b>
                </>
              }
              disabled={!helpers.isValid("sessionsIds")}
            >
              <DateSelectionStep />
            </CheckoutSteps.Step>

            <CheckoutSteps.Step
              breadcrumb=" Customer Information"
              title=" Customer Information"
              description="Please provide the necessary information so that we can continue with your booking."
              disabled={
                !(course.userQuestionForm.isEnabled == false || isUserFormValid)
              }
              hidden={!course.userQuestionForm.isEnabled}
            >
              <UserFormQuestionary
                values={values}
                onChange={actions.onChange}
                mode={course.userQuestionForm.mode}
                questions={course.userQuestionForm.questions}
                onFormValidation={actions.setIsUserFormValid}
              />
            </CheckoutSteps.Step>

            <CheckoutSteps.Step
              breadcrumb="Personal details"
              title="Who's coming?"
              disabled={
                !helpers.isValid("personalDetails") || isConfirmingFreeBooking
              }
              onEnter={() => actions.onPersonalDetailsEnter()}
              nextButtonText={helpers.isFree() ? "Confirm booking" : undefined}
            >
              <CheckoutPersonalDetailsForm
                errors={errors?.personalDetails}
                onChange={(value, name) =>
                  actions.onChange(value, `personalDetails.${name}`)
                }
                onBlur={name => actions.onBlur(`personalDetails.${name}`)}
                isSendingMaterial={isSendingMaterial}
                disableMarketingOptIn={!isNewsletterSignupEnabled}
                values={values.personalDetails}
                touched={touched?.personalDetails}
              />
            </CheckoutSteps.Step>
            <CheckoutSteps.Step
              breadcrumb="Card payment"
              title="Card payment"
              nextButtonText="Pay now"
              onEnter={actions.onCardPaymentEnter}
              disabled={!isStripePaymentReady}
              hidden={helpers.isFree()}
            >
              <StripePayment />
            </CheckoutSteps.Step>
          </CheckoutSteps>
        </CheckoutPanel>
        <CheckoutFooterBar
          label="Date Selected"
          value={
            firstSession &&
            UTCDate__FormattedDate(
              firstSession.startDateTime,
              timezone,
              "dddd, Do MMMM"
            )
          }
        >
          <CheckoutSteps.Navigation helpers={helpers.getTotal()} />
        </CheckoutFooterBar>
      </div>
    </CheckoutSteps.Provider>
  );
}
