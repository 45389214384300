import React from "react";
import LabelInput from "./LabelInput";
import { PrimaryButton } from "@obby/components";
import { styles } from "./ForgottenPasswordForm.styles";

export default class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        className={`forgotten-password-form ${styles.forgottenPasswordForm}`}
      >
        {!this.props.isSubmitted && (
          <div className={`forgotten-password-form__body`}>
            <div className={`forgotten-password-form__title ${styles.title}`}>
              Reset Password
            </div>

            <div
              className={`forgotten-password-form__description ${styles.description}`}
            >
              Enter the email address associated with your account, and we'll
              email you a link to reset your password.
            </div>

            <div className={`forgotten-password-form__form`}>
              <div className={`forgotten-password-form__input ${styles.input}`}>
                <LabelInput
                  type="email"
                  error={this.props.errors.inputs.email}
                  value={this.props.email}
                  onChange={this.props.onTypeEmail}
                  placeholder="Email address"
                />
              </div>
              <div
                className={`forgotten-password-form__button ${styles.button}`}
              >
                <PrimaryButton
                  text="Reset password"
                  onClick={this.props.onSubmitResetPassword}
                />
              </div>
              {this.props.errors.general && (
                <div
                  className={`forgotten-password-form__error ${styles.error}`}
                >
                  {this.props.errors.general}
                </div>
              )}
            </div>
          </div>
        )}
        {this.props.isSubmitted && (
          <div className={`forgotten-password-form__success`}>
            <div className={`forgotten-password-form__title ${styles.title}`}>
              Thank you!
            </div>
            <div
              className={`forgotten-password-form__description ${styles.description}`}
            >
              We sent a link to your inbox (check spam folder too). Click on it
              to reset your password.
            </div>
          </div>
        )}
      </div>
    );
  }
}
