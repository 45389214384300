import * as Yup from "yup";

export function PricingOptionsStepSchema() {
  return Yup.object({
    option: Yup.number()
      .required()
      .nullable()
      .default(null),
    date: Yup.date()
      .label("Date")
      .required("Please select a date to attend the class.")
      .nullable()
      .default(null),
    time: Yup.number()
      .label("Time")
      .min(0)
      .required("Please select the time you wish to attend the class.")
      .nullable()
      .default(null)
  }).required();
}

export type Fields = Yup.InferType<ReturnType<typeof PricingOptionsStepSchema>>;
