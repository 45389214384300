import { useEffect, useRef } from "react";

export function useNoBodyScroll() {
	const bodyOverflow = useRef("");
	useEffect(() => {
		// preserve the current value to be restored later
		bodyOverflow.current = document.body.style.overflow;
		// remove the body sidebar when overlay mounts
		document.body.style.overflow = "hidden";
		return () => {
			// set body sidebar back when overlay unmounts
			document.body.style.overflow = bodyOverflow.current;
		};
	});
}
