import { css } from "../../sitches.config";

const cols = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const styles = {
  col: css({
    flex: "0 0",
    variants: {
      col: generateRules(col),
      xs: generateRules(col, "@xs"),
      sm: generateRules(col, "@sm"),
      smd: generateRules(col, "@smd"),
      md: generateRules(col, "@md"),
      lg: generateRules(col, "@lg"),
      xl: generateRules(col, "@xl"),
      offset: generateRules(offset),
      offsetXs: generateRules(offset, "@xs"),
      offsetSm: generateRules(offset, "@sm"),
      offsetSmd: generateRules(offset, "@smd"),
      offsetMd: generateRules(offset, "@md"),
      offsetLg: generateRules(offset, "@lg"),
      offsetXl: generateRules(offset, "@xl"),
      order: generateRules(order),
      orderXs: generateRules(order, "@xs"),
      orderSm: generateRules(order, "@sm"),
      orderSmd: generateRules(order, "@smd"),
      orderMd: generateRules(order, "@md"),
      orderLg: generateRules(order, "@lg"),
      orderXl: generateRules(order, "@xl")
    }
  })
};

function generateRules(getRules: (col: number) => any, breakpoint?: string) {
  return cols.reduce<{ [col: string]: any }>((cols, col) => {
    const rules = getRules(col);
    cols[col] = breakpoint ? { [breakpoint]: rules } : rules;
    return cols;
  }, {});
}

function col(col: number) {
  return {
    flexBasis: `calc(${(col / 12) * 100}% - var(--gap) * ${(12 - col) / 12})`,
    maxWidth: `calc(${(col / 12) * 100}% - var(--gap) * ${(12 - col) / 12})`
  };
}

function offset(col: number) {
  return {
    marginLeft: `calc(${(col / 12) * 100}% + var(--gap) - var(--gap) * ${(12 -
      col) /
      12})`
  };
}

function order(col: number) {
  return {
    order: col
  };
}
