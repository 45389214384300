import { LoadingIcon } from "../../components/LoadingIcon";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import { SessionDateList } from "../../components/SessionDateList";
import React from "react";
import { CourseCheckoutContainer } from "./CourseCheckout.container";
import { Discount__DiscountedPrice } from "@obby/lib";
import { SessionVisibility } from "@obby/constants";
import { WaitingListCheckoutBanner } from "../../components";

export function DateSelectionStep() {
  const {
    hideDisplaysAvailableTickets,
    discount,
    sessions,
    isLoading,
    isFullyLoaded,
    isSoldOut,
    selectedSession,
    helpers,
    actions,
    currency,
    timezone,
    isFromPrice,
    manualOverridePrice
  } = CourseCheckoutContainer.useContainer();
  return (
    <div className="date-selection-step">
      <SessionDateList
        currency={currency}
        switchable={true}
        timezone={timezone}
        sessions={sessions.map(session => {
          const tutor = helpers.getTutor(session.tutor);
          const location = helpers.getLocation(session.locationId);

          let prices = helpers.getSessionPrices(session);
          let [price, ...additionalPrices] = prices;
          // do we want teacher to override price
          // @MM-177
          if (isFromPrice) {
            price = manualOverridePrice;
          }
          const disabled = session.availability === 0 || prices.length === 0;
          let description = "";
          if (hideDisplaysAvailableTickets) {
            if (disabled) {
              description = "Sold out";
            } else {
              if (session.availability > 0 && session.availability < 3) {
                description = "A few spots remaining";
              } else {
                description = "Spots available";
              }
            }
          } else {
            description = disabled
              ? "Sold out"
              : `${session.availability} ${
                  session.availability === 1 ? "spot" : "spots"
                } left`;
          }

          return {
            _id: session._id,
            date: session.startDateTime,
            additionalDates: session.additionalDates,
            additionalSessions: session.additionalClasses ?? [],
            disabled,
            description: description,
            duration: session.duration,
            discount: discount
              ? Discount__DiscountedPrice(discount, price)
              : undefined,
            hasMorePrices: additionalPrices.length > 0,
            isOnline: session.isOnline,
            isTerm: session.isTerm,
            location: session.isOnline ? "Online" : location?.area,
            number: session.number,
            price,
            tutorName: tutor?.fullName,
            tutorImageUrl: tutor?.profile?.imageUrl,
            tutorUrl: tutor?.profile?.links?.bio,
            isEarlyAccess:
              session.visibility === SessionVisibility.EARLY_ACCESS,
            waitingList: session.availability === 0
          };
        })}
        selection={selectedSession ? [selectedSession._id] : []}
        onChange={actions.onSessionSelect}
        openWaitingListPopup={actions.openWaitingListPopup}
      />
      {isLoading && <LoadingIcon />}
      {!isFullyLoaded && (
        <PrimaryButton
          text="See more dates"
          color="emeraldish"
          textMode="uppercase"
          width="auto"
          disabled={isLoading}
          onClick={actions.onLoadMoreDates}
        />
      )}
      {isFullyLoaded && !isSoldOut && (
        <WaitingListCheckoutBanner
          title="Can't make these dates?"
          onOpen={() => actions.openWaitingListPopup()}
        >
          You don't need to miss out! We can let you know when new dates become
          available.
        </WaitingListCheckoutBanner>
      )}
    </div>
  );
}
