import { withNaming } from "@bem-react/classname";
export const bem = withNaming({ e: "__", m: "--", v: "-" });

export function bemModule(styles, className) {
  const cn = bem(className);
  return (...args) =>
    cn(...args)
      .split(" ")
      .map(className => styles[className] ?? className)
      .join(" ");
}
