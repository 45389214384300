import React from "react";
import _ from "lodash";
import { store } from "statorgfc";
import AuthenticationState from "~/state/AuthenticationState";
import {
  MenuNav,
  MenuNavSearchInput,
  ClassListMenuItem,
  TopBanner,
  SalesBanner,
  MenuBanner
} from "@obby/components";
import { User__CUserType, CUserTypes } from "@obby/lib";
import { urls } from "~/settings";
import { styles } from "./Header.styles";

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOnTop: true,
      categories: []
    };
    this.onScroll = _.throttle(() => {
      this.setState({ isOnTop: window.scrollY === 0 });
    }, 250);

    store.connectComponentState(this, ["categories", "authentication"]);
  }

  componentDidMount() {
    addEventListener("scroll", this.onScroll);
    if (window.scrollY > 0) this.setState({ isOnTop: false });
  }

  componentWillUnmount() {
    removeEventListener("scroll", this.onScroll);
  }

  onBeginSignIn() {
    AuthenticationState.events.beginSignIn.next();
  }

  onBeginSignUp() {
    AuthenticationState.events.beginSignUp.next();
  }

  onSignOut() {
    AuthenticationState.events.signOut.next();
  }

  onGoToDashboard() {
    window.open(urls.teachers);
  }

  onEnterSearch(term) {
    window.location.href = `/learn?q=${term}`;
  }

  isDark() {
    const { sales, url } = this.props;
    return this.state.isOnTop && sales !== undefined && url.pathname === "/";
  }

  showSearchInput() {
    return !this.state.isOnTop || this.props.url.pathname !== "/";
  }

  showSalesBanner() {
    const { sales, url } = this.props;
    if (sales === undefined) return false;
    return [
      // school page
      "/schools/[slug]",
      // results pages,
      "/learn/[[...tag]]",
      "/classes/[[...data]]",
      // course pages
      "/classes/[category]/[subcategory]/[...classPage]",
      // product pages
      "/products/[type]/[category]/[slug]",
      // gift card page
      "/gift",
      "/gift/send",
      // classlists
      "/classlists",
      "/classlists/[slug]"
    ].includes(url.pathname);
  }

  showTopBar() {
    const {
      sales,
      blockInstances: { topBanner }
    } = this.props;
    return sales === undefined && topBanner?.enabled;
  }

  showWideSiteBanner() {
    return true;
  }

  render() {
    const userType = User__CUserType(this.state.authentication.user);
    const userAvatar = this.state.authentication.user?.profile?.imageUrl;
    const { topBanner } = this.props.blockInstances;

    const { featuredTags = [] } = this.props;

    return (
      <>
        {(this.showTopBar() || this.showSalesBanner()) && (
          <div
            className={`new-header__top-bar-placeholder ${styles.topBarPlaceholder()}`}
          />
        )}
        <section
          className={`new-header ${styles.newHeader()} ${
            //condition for if the currently page is either product or classesPage
            (!this.state.isOnTop &&
              this.props.url.pathname ===
                "/classes/[category]/[subcategory]/[...classPage]") ||
            (!this.state.isOnTop &&
              this.props.url.pathname === "/products/[type]/[category]/[slug]")
              ? "new-header--classes"
              : !this.state.isOnTop
              ? "new-header--filled"
              : this.props.url.pathname ===
                  "/classes/[category]/[subcategory]/[...classPage]" ||
                this.props.url.pathname === "/products/[type]/[category]/[slug]"
              ? "new-header--classes-bg"
              : ""
          }`}
        >
          {this.showTopBar() && (
            <TopBanner
              backgroundColor={topBanner.value.backgroundColor}
              color={topBanner.value.textColor}
            >
              <a
                className={styles.topBarText()}
                href={topBanner.value.link}
                style={{ color: "inherit" }}
              >
                {topBanner.value.text}
              </a>
            </TopBanner>
          )}

          {this.showSalesBanner() && (
            <SalesBanner discount={this.props.sales.value} size="small" />
          )}
          <MenuNav
            isSignedIn={userType !== CUserTypes.GUEST}
            onSignOut={this.onSignOut.bind(this)}
            avatarUrl={userAvatar}
            textColor={this.isDark() ? "humming-bird" : undefined}
            textColorHover={this.isDark() ? "robbinsEggBlue" : undefined}
          >
            <MenuNav.Item text="What you can learn" bold>
              <WhatYouCanLearn tags={featuredTags} />
            </MenuNav.Item>
            <MenuNav.Item text="How you can learn" bold>
              <HowYouCanLearn />
            </MenuNav.Item>
            <MenuNav.Item
              target="_blank"
              href="https://obby.co.uk/blog"
              text="Blog"
              bold
            />

            <MenuNav.Space>
              <div
                className={`new-header__search ${styles.search()} ${
                  this.showSearchInput() ? "" : "new-header__search--hidden"
                } pl-3`}
              >
                <MenuNavSearchInput
                  onSearch={this.onEnterSearch.bind(this)}
                  iconColor={this.isDark() ? "humming-bird" : undefined}
                  iconColorHover={this.isDark() ? "robbinsEggBlue" : undefined}
                />
              </div>
            </MenuNav.Space>

            {userType === CUserTypes.GUEST && (
              <MenuNav.Item
                text="Log in"
                bold
                desktop
                onClick={this.onBeginSignIn.bind(this)}
              />
            )}
            {userType === CUserTypes.GUEST && (
              <MenuNav.Button
                text="Log in"
                mobile
                onClick={this.onBeginSignIn.bind(this)}
              />
            )}
            {userType === CUserTypes.STUDENT && (
              <MenuNav.Item text="Your Bookings" bold href="/bookings" />
            )}
            {[CUserTypes.TEACHER, CUserTypes.ADMIN].includes(userType) && (
              <MenuNav.Item
                text="Your Dashboard"
                bold
                onClick={this.onGoToDashboard.bind(this)}
              />
            )}
          </MenuNav>
        </section>
      </>
    );
  }
}

function WhatYouCanLearn(props) {
  const { tags } = props;
  return (
    <div className="row">
      <div className="col-12 col-lg-3">
        <MenuBanner
          backgroundColor="royal-blue"
          title="What you can learn"
          description="Discover the best in-person and online classes, learning kits and products with our Obby teachers."
          thumbnail="https://res.cloudinary.com/obby/image/upload/v1588167893/Page%20Images/inperson.png"
        />
      </div>
      <div className="col-12 col-lg-8">
        <div className="row mt-4 mt-lg-0">
          <div className="col-12 col-lg-4">
            {tags.slice(0, 9).map(({ name, slug }, index) => (
              <div key={index} className="pb-3">
                <a
                  className={`what-you-can-learn__link ${styles.link()}`}
                  href={`/learn/${slug}`}
                >
                  {name}
                </a>
              </div>
            ))}
          </div>
          <div className="col-12 col-lg-4">
            {tags.slice(9, 18).map(({ name, slug }, index) => (
              <div key={index} className="pb-3">
                <a
                  className={`what-you-can-learn__link ${styles.link()}`}
                  href={`/learn/${slug}`}
                >
                  {name}
                </a>
              </div>
            ))}
          </div>
          <div className="col-12 col-lg-4">
            {tags.slice(18, 27).map(({ name, slug }, index) => (
              <div key={index} className="pb-3">
                <a
                  className={`what-you-can-learn__link ${styles.link()}`}
                  href={`/learn/${slug}`}
                >
                  {name}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function HowYouCanLearn() {
  return (
    <div className="row">
      <div className="col-12 col-lg-3">
        <MenuBanner
          title="Learn anywhere, anytime, anyhow"
          description="Obby brings the next generation of learning to your doorstep. Combine At Home Learning Kits with Live Online Tuition. Pre-recorded Video Tutorials and In-Person Classes."
          thumbnail="https://res.cloudinary.com/obby/image/upload/v1588167893/Page%20Images/online.png"
        />
      </div>
      <div className="col-12 col-lg-8">
        <div className="row mt-4 mt-lg-0">
          <div className="col-12 col-lg-6 pb-3">
            <ClassListMenuItem
              title="In-Person Learning"
              description="Learn in studio with your teacher and get hands on"
              thumbnail="https://res.cloudinary.com/obby/image/upload/v1581285266/hu6ydkpxzx1tvs8kyzei.jpg"
              link="/learn?format=inPerson"
            />
          </div>
          <div className="col-12 col-lg-6 pb-3">
            <ClassListMenuItem
              title="Live Online Learning"
              description="Join a virtual classroom and learn in real time"
              thumbnail="https://res.cloudinary.com/obby/image/upload/v1613997646/hnfpirxf8l5cylrvbfyr.jpg"
              link="/learn?format=online"
            />
          </div>
          <div className="col-12 col-lg-6 pb-3">
            <ClassListMenuItem
              title="At Home Learning"
              description="Learn anytime, anywhere with Learning Kits & Products"
              thumbnail="https://res.cloudinary.com/obby/image/upload/v1612444028/ao5sakc14a9jjg4krvyf.jpg"
              link="/learn?format=kits"
            />
          </div>
          <div className="col-12 col-lg-6 pb-3">
            <ClassListMenuItem
              title="Pre-recorded Online Learning"
              description="Tune in when you want, from where you want, and get expert tuition"
              thumbnail="https://res.cloudinary.com/obby/image/upload/v1619708327/vf6byuuv7pz6puvg4o16.jpg"
              link="https://obby.co.uk/learn?format=online-courses"
            />
          </div>
          <div className="col-12 col-lg-6 pb-3">
            <ClassListMenuItem
              title="Group Learning"
              description="Arrange an epic learning experience for your next team-building event, office party or hen-do"
              thumbnail="https://res.cloudinary.com/obby/image/upload/v1575720407/glsxwste0b3eaax6z7qz.jpg"
              link="https://www.groups.obby.co.uk/"
            />
          </div>
          <div className="col-12 col-lg-6 pb-3">
            <ClassListMenuItem
              title="Gift Cards"
              description="Give the gift of learning with an Obby Gift Card"
              thumbnail="https://res.cloudinary.com/obby/image/upload/v1621258341/Misc%20Stickers/gift-card-10.png"
              link="/gift"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
