// don't forget to update react-admin/src/constants/DashboardPermissions.js file too
export const DashboardPermissions = {
  VIEW_CONTACT_INFO: "VIEW_CONTACT_INFO",
  RESCHEDULE_BOOKING: "RESCHEDULE_BOOKING",
  CANCEL_BOOKING: "CANCEL_BOOKING",
  COURSE_NOTIFICATIONS: "COURSE_NOTIFICATIONS",
  EDIT_BOOKING_GUEST: "EDIT_BOOKING_GUEST",
  EMAIL_BOOKING: "EMAIL_BOOKING",
  RESEND_EMAILS: "RESEND_EMAILS",
  MANAGE_BUNDLE_BOOKINGS: "MANAGE_BUNDLE_BOOKINGS",
  MANAGE_PRIVATE_PAYMENTS: "MANAGE_PRIVATE_PAYMENTS",
  MANAGE_TICKET_TYPES: "MANAGE_TICKET_TYPES",
  MANAGE_WAITING_LIST: "MANAGE_WAITING_LIST",
  USE_EARLY_ACCESS: "USE_EARLY_ACCESS",
  USE_WIDGET_GIFT_CARDS: "USE_WIDGET_GIFT_CARDS",
  USE_ZOOM_INTEGRATION: "USE_ZOOM_INTEGRATION",
  USE_CALENDAR_INTEGRATION: "USE_CALENDAR_INTEGRATION",
  USE_EXTERNAL_BOOKINGS: "USE_EXTERNAL_BOOKINGS",
  MANAGE_REVIEWS: "MANAGE_REVIEWS",
  REMOVE_REVIEWS: "REMOVE_REVIEWS",
  MANAGE_CUSTOMERS: "MANAGE_CUSTOMERS",
  FILTER_CUSTOMERS: "FILTER_CUSTOMERS",
  DOWNLOAD_CUSTOMERS: "DOWNLOAD_CUSTOMERS",
  MANAGE_INCOME: "MANAGE_INCOME",
  DOWNLOAD_INCOME: "DOWNLOAD_INCOME",
  USE_DISCOUNTS: "USE_DISCOUNTS",
  USE_SPECIFIC_DISCOUNTS: "USE_SPECIFIC_DISCOUNTS",
  USE_WIDGET: "USE_WIDGET",
  CUSTOMIZE_WIDGET: "CUSTOMIZE_WIDGET",
  USE_BULK_CHANGES: "USE_BULK_CHANGES",
  SHARE_CLASS: "SHARE_CLASS",
  USE_RECURRING_CLASS_DATES: "USE_RECURRING_CLASS_DATES",
  CREATE_UNLIMITED_POSTAGES: "CREATE_UNLIMITED_POSTAGES",
  CREATE_STANDARD_LIMITED_POSTAGES: "CREATE_STANDARD_LIMITED_POSTAGES",
  PRIVATE_TAG_CREATION: "PRIVATE_TAG_CREATION",
  CREATE_SECOND_TUTOR: "CREATE_SECOND_TUTOR",
  CREATE_LIMITED_TUTORS: "CREATE_LIMITED_TUTORS",
  CREATE_UNLIMITED_TUTORS: "CREATE_UNLIMITED_TUTORS",
  CUSTOM_CANCELLATION_POLICY: "CUSTOM_CANCELLATION_POLICY",
  TIMETABLE_FILTERS: "TIMETABLE_FILTER",
  TIMETABLE_BULK_ACTIONS: "TIMETABLE_BULK_ACTIONS",
  WIDGET_HIDE_EMBED_BRANDING: "WIDGET_EMBED_BRANDING",
  AVAILABILITY_VIEW_APPOINTMENTS: "AVAILABILITY_VIEW_APPOINTMENTS",
  LARGE_GROUP_ENQUIRE: "LARGE_GROUP_ENQUIRE",
  ARCHIVE_SCHOOL: "ARCHIVE_SCHOOL",
  OPT_IN_2FA_SMS: "OPT_IN_2FA_SMS",
  DELETE_SCHOOL_ACCOUNT: "DELETE_SCHOOL_ACCOUNT",
  CAN_ENABLE_WIDGET_ACCOUNT: "CAN_ENABLE_WIDGET_ACCOUNT",
  CAN_CHANGE_PAYOUT_INTERVAL_DAILY: "CAN_CHANGE_PAYOUT_INTERVAL_DAILY",
  CAN_CHANGE_PAYOUT_INTERVAL_WEEKLY: "CAN_CHANGE_PAYOUT_INTERVAL_WEEKLY",
  CAN_ADD_DOMAIN_ON_STRIPE: "CAN_ADD_DOMAIN_ON_STRIPE"
};
