import * as Yup from "yup";
export function ReviewFormSchema(defaults = {}) {
  defaults = {
    name: "",
    firstName: "",
    lastName: "",
    showOnlyFirstName: false,
    rating: 0,
    opinion: "",
    ...defaults
  };

  return Yup.object({
    name: Yup.string()
      .label("Name")
      .default(defaults.name),
    firstName: Yup.string()
      .label("First Name")
      .required("First name is a required field")
      .default(defaults.firstName),
    lastName: Yup.string()
      .label("Last Name")
      .default(defaults.lastName),
    showOnlyFirstName: Yup.boolean()
      .label("Show Last Name")
      .default(defaults.showOnlyFirstName),
    rating: Yup.number()
      .label("Rating")
      .required()
      .min(1, "How many stars would you rate it?")
      .default(defaults.rating),
    opinion: Yup.string()
      .label("Your Opinion")
      .required("Please say something about your experience")
      .min(5, "Your review has to be at least 5 characters long.")
      .default(defaults.opinion)
  });
}
