import React, { useContext } from "react";
import { MenuNavContext, MenuNavItemContext } from "./MenuNavContext";
import styles from "./menu-nav-desktop.module.less";

export function MenuNavDesktop(props) {
  const { children, selectedItemRef, onItemLeave } = props;

  const { activeItem, onItemToggle } = useContext(MenuNavContext);

  return (
    <ul className={`${styles["menu-nav-desktop"]} pl-2`}>
      {React.Children.map(children, (child, index) => {
        const { id = index, children, mobile = false } = child?.props || {};
        if (!child || mobile) return <></>;
        const isActive = id === activeItem;
        return (
          <MenuNavItemContext.Provider
            value={{
              isActive,
              onItemToggle: children ? () => onItemToggle(id) : undefined,
              onItemLeave: isActive ? onItemLeave : undefined,
              ref: isActive ? selectedItemRef : undefined
            }}
          >
            {child}
          </MenuNavItemContext.Provider>
        );
      })}
    </ul>
  );
}
