import { css } from "../../sitches.config";

export const styles = {
  aboutUsSection: css({
    display: "flex",
    flexDirection: "column",

    "@sm": {
      flexDirection: "row"
    }
  }),
  image: css({
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "$2",
    overflow: "hidden",
    flex: "0 0 400px",
    maxWidth: "100%",

    "@sm": {
      "-ms-flex": "0 0 33.3%",
      flex: "0 0 33.3%",
      maxWidth: "33.3%",
      maxHeight: "400px"
    }
  }),
  content: css({
    paddingTop: "$18",
    "@sm": {
      flex: "0 0 66.6%",
      maxWidth: "66.6%",
      paddingTop: "0",
      paddingLeft: "$24"
    },
    "@lg": {
      paddingInline: "$42"
    }
  }),
  contentTitle: css({
    fontFamily: "$foundersGroteskCondensed",
    fontWeight: "600",
    fontSize: "$32",
    display: "none",
    "@sm": {
      display: "inline-block"
    },

    variants: {
      viewport: {
        mobile: {
          display: "inline-block",
          "@sm": {
            display: "none"
          }
        }
      }
    }
  }),
  contentDescription: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$16",

    "@sm": {
      fontSize: "$18"
    },
    "p:first-child": {
      marginTop: 0
    }
  })
};
