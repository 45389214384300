import { css } from "../../sitches.config";

export const styles = {
  cardWrapper: css({
    "@smd": {
      margin: "14px 0"
    },

    variants: {
      highlighted: {
        true: {
          margin: "0"
        }
      }
    }
  }),

  info: css({
    height: "84px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    variants: {
      highlighted: {
        true: {
          height: "106px"
        }
      }
    }
  }),

  name: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$16",
    color: "$ebonyClay",

    variants: {
      highlighted: {
        true: {
          fontSize: "$18"
        }
      }
    }
  }),

  price: css({
    color: "$ebonyClay",
    fontFamily: "$foundersGroteskCondensed",
    fontSize: "$28",
    lineHeight: "1",
    textAlign: "center",

    variants: {
      highlighted: {
        true: {
          lineHeight: "1",
          fontSize: "$32"
        }
      }
    }
  }),

  perMonth: css({
    display: "inline-block",
    fontFamily: "$foundersGrotesk",
    color: "$gray",
    fontSize: "$12",
    height: "18px",

    variants: {
      highlighted: {
        true: {
          fontSize: "$14"
        }
      }
    }
  }),

  selectButton: css({
    width: "190px !important"
  }),

  wrapper: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }),

  featuresWrapper: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0 30px"
  }),

  features: css({
    listStyle: "none",
    paddingLeft: "0",
    margin: "auto",
    width: "auto"
  }),

  feature: css({
    display: "flex",
    alignItems: "center",
    fontFamily: "$foundersGrotesk",
    color: "$ebonyClay"
  }),

  tickIcon: css({
    flex: "0 0 18px",
    alignSelf: "flex-start",
    marginTop: "5px",
    circle: {
      fill: "$emeraldish"
    }
  })
};
