import { css } from "../sitches.config";
import { List } from "./List";
import { TableFilterSection } from "./TableFilterSection";

interface TableFilterContainerProps {
  children: any;
  isOpen: boolean;
  hideOnDesktop?: boolean;
}

function TableFilterContainer({
  children,
  isOpen = false,
  hideOnDesktop = false
}: TableFilterContainerProps) {
  return (
    <div className={styles.box({ isOpen, hideOnDesktop })}>
      <List className={styles.filters()}>{children}</List>
    </div>
  );
}

TableFilterContainer.Section = TableFilterSection;

const styles = {
  box: css({
    display: "none",
    backgroundColor: "white",
    border: "1px solid $mercury",
    borderRadius: 5,
    padding: 10,

    "@sm": {
      padding: "10px 20px"
    },

    "@lg": {
      display: "block"
    },

    variants: {
      isOpen: {
        true: {
          display: "block"
        }
      },
      hideOnDesktop: {
        true: {}
      }
    },
    compoundVariants: [
      {
        isOpen: false,
        hideOnDesktop: true,
        css: {
          display: "none"
        }
      }
    ]
  }),

  filters: css({
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoRows: "auto",
    gap: "10px 20px",

    "@md": {
      gridTemplateColumns: "1fr fr"
    },

    "@lg": {
      gridTemplateColumns: "1fr 1fr 1fr"
    }
  })
};

export { TableFilterContainer };
