//@ts-ignore
import * as Yup from "yup";

export function RescheduleFlexitimeBookingFormSchema(isOnline?: boolean) {
  return Yup.object({
    reschedulingMode: Yup.string()
      .oneOf(["suggest", "confirm"])
      .default("suggest"),
    message: Yup.string()
      .label("Message")
      .required()
      .default(""),
    ...(isOnline
      ? {
          zoomLink: Yup.string()
            .label("Zoom Link")
            .required()
            .default("")
        }
      : {}),
    suggestedDates: Yup.array()
      .label("Dates")
      .of(
        Yup.date()
          .label("Date")
          .required("Please select a date.")
          .default(new Date())
      )
      .when("reschedulingMode", {
        is: "suggest",
        //@ts-ignore
        then: schema =>
          schema.test(
            "",
            "Please enter at least 3 alternative dates.",
            (dates: Fields["suggestedDates"] | undefined) =>
              dates !== undefined && dates.length >= 3
          )
      })

      .default([]),
    confirmationDate: Yup.date()
      .label("Date")
      .when("reschedulingMode", {
        is: "confirm",
        //@ts-ignore
        then: schema => {
          return schema.required("Please select a date.");
        }
      })
      .nullable(true)
      .default(null)
  });
}

export interface Fields {
  reschedulingMode: "suggest" | "confirm";
  message: string;
  zoomLink?: string;
  suggestedDates: Date[];
  confirmationDate: Date;
}
