import { css } from "../../sitches.config";

export const styles = {
  responsiveCarousel: css({
    "@sm": {
      paddingTop: "$16"
    },
    ".carousel": {
      display: "flex",
      flexDirection: "column-reverse",

      ".slide": {
        padding: "0 11px",
        background: "transparent",

        "@sm": {
          padding: "0 18px"
        },

        "@lg": {
          padding: "padding: 0 30px"
        },

        a: {
          height: "100%",
          width: "100%",
          display: "inline-block",

          figure: {
            position: "relative",
            width: "100%",
            height: "100%",
            margin: "0"
          },

          img: {
            borderRadius: "10px"
          },

          figcaption: {
            position: "absolute",
            bottom: "0",
            width: "100%",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontFamily: "$foundersGrotesk",
            color: "$white",
            backgroundColor: "rgba(40, 40, 60, 0.4)",
            height: "3.75rem",
            padding: "0 .5rem",
            fontSize: "1.125rem",
            borderBottomRightRadius: "$2",
            borderBottomLeftRadius: "$2",
            lineHeight: 1.2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }
        }
      },
      "button.control-arrow": {
        "&:before": {
          content: "none"
        }
      },

      ".control-dots": {
        position: "relative",
        display: "flex",
        justifyContent: "center",

        ".dot": {
          background: "$silverMalibu",
          boxShadow: "1px 1px 2px rgba(0, 0, 0, 30%)",
          width: "50px",
          height: "5px",
          borderRadius: "8px"
        },

        ".dot.selected": {
          background: "$sun"
        }
      },

      ".control-arrow": {
        display: "none",
        backgroundColor: "$pampas",
        padding: "0 10px",
        opacity: "0.5",

        "@smd": {
          display: "initial",
          width: "44px",
          padding: "0 16px"
        },

        "@lg": {
          width: "76px",
          padding: "0 29px"
        },

        "&:before": {
          content: "none !important"
        },

        "&:hover": {
          backgroundColor: "$pampas",
          opacity: "0.6"
        },

        path: {
          fill: "$ebonyClay"
        }
      }
    },

    ".carousel:nth-of-type(2)": {
      display: "none"
    },

    variants: {
      showIndicator: {
        true: {
          ".slide": {
            height: "280px",

            ".carousel__wrapper": {
              height: "100%",

              img: {
                height: "100%",
                objectFit: "cover"
              }
            }
          }
        }
      }
    }
  })
};
