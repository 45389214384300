import React, { useState, useEffect, useRef } from "react";
import { ReactSortable } from "react-sortablejs";

import {
  ImageUploader,
  ImagePlaceholder,
  ImagePreview
} from "../ImageUploader";

import styles from "./gallery-uploader.module.less";

export function GalleryUploader(props) {
  const {
    progress,
    isUploading,
    onDrop = files => {},
    onChange = images => {},
    placeholders = [],
    label = "Add a photo"
  } = props;

  const [images, setImages] = useState(
    props.images.map(image => ({ id: image, image })) || []
  );

  const ref = useRef(null);

  useEffect(() => {
    setImages(props.images.map(image => ({ id: image, image })));
  }, [props.images]);

  function onDelete(index) {
    const state = [...images];
    state.splice(index, 1);
    setImages(state);
    onChange(state.map(({ image }) => image));
  }

  return (
    <div ref={ref} className={styles["gallery-uploader"]}>
      <div className={styles["gallery-uploader__wrapper"]}>
        <ImageUploader
          label={label}
          onDrop={onDrop}
          multiple
          isUploading={isUploading}
          progress={progress}
        />
      </div>
      <ReactSortable
        list={images}
        setList={setImages}
        className={styles["gallery-uploader__images"]}
        onSort={() => onChange(images.map(({ image }) => image))}
      >
        {images.map((item, index) => (
          <div key={item.id} className={styles["gallery-uploader__wrapper"]}>
            <ImagePreview image={item.image} onDelete={() => onDelete(index)} />
          </div>
        ))}
      </ReactSortable>
      {placeholders.slice(images.length).map(({ icon, label }, index) => (
        <div key={index} className={styles["gallery-uploader__wrapper"]}>
          <ImagePlaceholder icon={icon} label={label} />
        </div>
      ))}
    </div>
  );
}
