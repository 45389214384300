// @ts-ignore
import { CurrencyISO__CurrencySymbol, Number__Currency } from "@obby/lib";
// @ts-ignore
import { Currencies } from "@obby/constants";

type AllowedCurrencies = keyof typeof Currencies;

interface DisplayPriceProps {
  amount: number;
  currency?: AllowedCurrencies;
  precision?: number;
}

function DisplayPrice({ amount, currency, precision }: DisplayPriceProps) {
  const displayCurrency = currency;
  const symbol = CurrencyISO__CurrencySymbol(displayCurrency);

  return <span>{Number__Currency(amount, symbol, precision)}</span>;
}

export { DisplayPrice };
