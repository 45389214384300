import React from "react";

import Label from "../../components/Label";
import { TextInput } from "../../components/TextInput";
import { SignupSchoolDetailsFormSchema } from "./SignupSchoolDetailsForm.schema";

export function SignupSchoolDetailsForm(props) {
  const { values = {}, touched = {}, errors = {}, onBlur, onChange } = props;
  const {
    firstName,
    lastName,
    businessName,
    phoneNumber,
    email,
    emailConfirmation,
    password
  } = values;

  return (
    <div className="signup-teacher-details-form">
      <div className="row">
        <div className="col-12 col-sm-6 pb-4">
          <Label
            label="First name"
            error={touched.firstName && errors.firstName}
          >
            <TextInput
              placeholder="Jane"
              name="firstName"
              value={firstName}
              onChange={onChange}
              onBlur={onBlur}
            />
          </Label>
        </div>
        <div className="col-12 col-sm-6 pb-4">
          <Label label="Last name" error={touched.lastName && errors.lastName}>
            <TextInput
              placeholder="Smith"
              name="lastName"
              value={lastName}
              onChange={onChange}
              onBlur={onBlur}
            />
          </Label>
        </div>
        <div className="col-12 col-sm-6  pb-4">
          <Label
            label="Business name"
            error={touched.businessName && errors.businessName}
          >
            <TextInput
              placeholder="Jane's Painting Club"
              name="businessName"
              value={businessName}
              onChange={onChange}
              onBlur={onBlur}
            />
          </Label>
        </div>
        <div className="col-12 col-sm-6  pb-4">
          <Label
            label="Phone number"
            error={touched.phoneNumber && errors.phoneNumber}
          >
            <TextInput
              name="phoneNumber"
              value={phoneNumber}
              onChange={onChange}
              onBlur={onBlur}
            />
          </Label>
        </div>
        <div className="col-12 pb-4">
          <Label label="Your email" error={touched.email && errors.email}>
            <TextInput
              placeholder="name@example.com"
              name="email"
              value={email}
              onChange={onChange}
              onBlur={onBlur}
              textTransform="lowercase"
              trim
              lowercase
            />
          </Label>
        </div>
        <div className="col-12 pb-4">
          <Label
            label="Confirm your email"
            error={touched.emailConfirmation && errors.emailConfirmation}
          >
            <TextInput
              name="emailConfirmation"
              value={emailConfirmation}
              onChange={onChange}
              onBlur={onBlur}
              textTransform="lowercase"
              trim
              lowercase
            />
          </Label>
        </div>
        <div className="col-12 pb-4">
          <Label label="Password" error={touched.password && errors.password}>
            <TextInput
              placeholder="Create a password to login with"
              name="password"
              type="password"
              value={password}
              onChange={onChange}
              onBlur={onBlur}
            />
          </Label>
        </div>
      </div>
    </div>
  );
}
SignupSchoolDetailsForm.Schema = SignupSchoolDetailsFormSchema;
