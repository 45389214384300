import { css, withBem } from "../../sitches.config";

export const styles = withBem("checkout-panel", {
  panel: css({
    display: "flex",
    flexDirection: "column",
    minHeight: "100%"
  }),

  body: css({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  }),

  content: css({
    flexGrow: 1
  }),

  bundle: css({
    marginTop: "$1",
    marginBottom: "$3",
    paddingTop: "$4"
  }),
  heading: css({
    fontFamily: "$foundersGroteskCondensed",
    fontWeight: "bold",
    fontSize: "$48",
    textTransform: "uppercase",
    color: "$ebonyClay"
  }),
  subheading: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$16",
    letterSpacing: "0.01em",
    color: "$ebonyClay"
  }),
  sidebar: css({
    marginTop: "$4",
    "@md": {
      marginTop: 0,
      position: "sticky",
      top: "15px"
    },
    "& .card-background ": {
      padding: "$3 !important",
      "@md": {
        padding: "$4 !important"
      }
    },
    "& .card-background__thumbnail-wrapper": {
      display: "none",
      "@md": {
        display: "block"
      }
    }
  }),
  footer: css({
    paddingY: "$4",
    marginTop: "$4",
    marginBottom: "5rem",
    "@sm": {
      marginBottom: "6rem"
    },
    "@md": {
      marginTop: "7rem"
    }
  }),
  footerWrapper: css({
    "@md": {
      display: "flex",
      justifyContent: "space-between"
    }
  }),
  address: css({
    fontFamily: "$foundersGrotesk",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "$14",
    letterSpacing: "0.01em",
    color: "$ebonyClay",
    marginBottom: "$3",
    "@md": {
      flexBasis: "400px"
    }
  }),
  paymentIcons: css({
    "& img": {
      height: "35px",
      margin: "0 $2",
      "@md": {
        height: "50px"
        // margin: 0 10px,
      },
      "&:first-child": {
        marginLeft: 0
      },
      "&:last-child": {
        marginRight: 0
      }
    }
  })
});
