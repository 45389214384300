import React from "react";
import Icons from "../Icons";
import TextPill from "../TextPill";
import RatingSummary from "../RatingSummary";
import IconBlurbList from "../IconBlurbList";
import NuovoBreadcrumbs from "../NuovoBreadcrumbs";
import { CloudinaryImage } from "../CloudinaryImage";
import HighlightPrice from "../HighlightPrice";
import {
  Number__Currency,
  CurrencyISO__CurrencySymbol,
  Duration__DurationString,
  Discount__DiscountedPrice,
  UTCDate__FormattedDate
} from "@obby/lib";
import { Timezones } from "@obby/constants";
import { Stories } from "../../media/Stories";
import { styles } from "./CoursePageHeader.styles";
import Icon from "../Icon";
import { Skeleton } from "../Skeleton";
import { AspectRatio } from "../../boxes";
import { useAppText } from "../../containers";

const IMAGE_SIZE = {
  small: { cols: 5 },
  medium: { cols: 6 },
  large: { cols: 7 }
};

const IMAGE_POSITION = {
  left: {
    order: 0
  },
  right: {
    order: 1
  }
};
export function CoursePageHeader({
  title,
  description,
  location,
  price,
  discount,
  samePriceAlways = true,
  duration,
  numberOfAdditionalDates,
  numberOfWeeks,
  level,
  capacity,
  breadcrumbs,
  rating,
  numberOfReviews,
  nextSessionDate,
  timezone = Timezones.EUROPE_LONDON,
  isEarlyAccess,
  isOnline,
  requiresSendingPhysicalMaterial,
  onClickBreadcrumb,
  images,
  imagePosition = "left",
  imageSize = "medium",
  teacherImage,
  teacherName,
  isMultiplePrice,
  addToWishList,
  isFavourite,
  onToggleFavourite,
  currency = "GBP",
  skeleton = false
}) {
  const { __, __n } = useAppText();

  let currencySymbol = CurrencyISO__CurrencySymbol(currency);
  return (
    <div className={styles.coursePageHeader()}>
      <div className="row">
        <div
          className={`
          col-12 col-md-${IMAGE_SIZE[imageSize].cols}
          order-md-${IMAGE_POSITION[imagePosition].order}
          pb-4 pb-md-0`}
        >
          {!skeleton && addToWishList && (
            <div
              className={styles.favoriteIcon({ isFavourite })}
              onClick={onToggleFavourite}
            >
              {isFavourite && <Icon icon={Icons.HeartFull} height={30} />}
              {!isFavourite && <Icon icon={Icons.Heart} height={30} />}
            </div>
          )}
          <div className={styles.slider()}>
            {skeleton ? (
              <AspectRatio height={565} width={834}>
                <Skeleton dark stretch />
              </AspectRatio>
            ) : (
              <Stories
                stories={images}
                width={834}
                height={565}
                interval={3750}
                loop
              />
            )}
          </div>
        </div>
        <div className={`col-12 col-md-${12 - IMAGE_SIZE[imageSize].cols}`}>
          <div className={styles.content()}>
            {breadcrumbs && breadcrumbs.length ? (
              <div className={styles.breadcrumbs()}>
                <NuovoBreadcrumbs
                  breadcrumbs={breadcrumbs}
                  onClick={onClickBreadcrumb}
                />
              </div>
            ) : null}
            <div className={styles.pills({ className: "d-none d-md-flex" })}>
              {skeleton && (
                <div className={styles.pillSkeletonWrapper()}>
                  <Skeleton className={styles.pillSkeleton()} dark />
                </div>
              )}
              {!skeleton && (
                <>
                  {isOnline !== undefined &&
                    {
                      true: (
                        <TextPill
                          className={styles.pill()}
                          text={__("Live online class")}
                        />
                      ),
                      false: (
                        <TextPill
                          className={styles.pill()}
                          text={__("In-person classes")}
                          backgroundColor="royalBlue"
                        />
                      )
                    }[isOnline]}
                  {requiresSendingPhysicalMaterial && (
                    <TextPill
                      className={styles.pill()}
                      text={__("Learning Kit Included")}
                      backgroundColor="camelot"
                    />
                  )}
                  {isEarlyAccess && (
                    <TextPill
                      className={styles.pill()}
                      text="Early Access"
                      backgroundColor="sun"
                    />
                  )}
                </>
              )}
            </div>

            <h1 className={styles.title()}>
              {skeleton ? <Skeleton dark /> : title}
            </h1>
            {skeleton ? (
              <div className={styles.reviewsSkeleton()}>
                <Skeleton dark />
              </div>
            ) : (
              <a className="d-none d-sm-inline" href="#latest-reviews">
                {numberOfReviews > 0 && (
                  <RatingSummary
                    rating={rating}
                    reviewCount={numberOfReviews}
                    iconSize={15}
                    primaryColor="emeraldish"
                  />
                )}
              </a>
            )}
            <span className={styles.description()}>
              {skeleton ? <Skeleton dark lines={2} /> : description}
            </span>

            <div className={styles.iconListWrapper()}>
              {skeleton && (
                <div className="row">
                  <div className="col-3">
                    <Skeleton dark />
                  </div>
                  <div className="col-3">
                    <Skeleton dark />
                  </div>
                  <div className="col-3">
                    <Skeleton dark />
                  </div>
                  <div className="col-3">
                    <Skeleton dark />
                  </div>
                </div>
              )}
              {!skeleton && (
                <IconBlurbList
                  iconBlurbProps={[
                    ...(capacity
                      ? [
                          {
                            icon: Icons.Profile,
                            text: __n("1 Student", ":count Students", capacity),
                            iconColor: "heather"
                          }
                        ]
                      : []),

                    ...(location
                      ? [
                          {
                            icon: Icons.LocationBold,
                            text: location,
                            iconColor: "heather"
                          }
                        ]
                      : []),

                    ...(price
                      ? [
                          {
                            mobileClasses: "d-md-none",
                            displayNoneMobile: false,
                            icon: isOnline ? Icons.Live : Icons.PeopleThin,
                            text: isOnline ? "Online" : "In-person",
                            iconColor: "heather"
                          },
                          {
                            mobileClasses: "d-none d-md-inline",
                            icon: Icons.Currency,
                            text: (
                              <>
                                {discount && (
                                  <HighlightPrice
                                    currency={currency}
                                    price={Discount__DiscountedPrice(
                                      discount,
                                      price
                                    )}
                                    size="medium"
                                    pp
                                  />
                                )}
                                <span
                                  className={styles.priceWrapper({
                                    discount: discount !== undefined
                                  })}
                                >
                                  {!samePriceAlways && "from "}
                                  <span className={styles.price()}>
                                    {`${Number__Currency(
                                      price,
                                      currencySymbol
                                    )} ${isMultiplePrice ? "" : "pp"}`}
                                  </span>
                                </span>
                              </>
                            ),
                            iconColor: "heather"
                          }
                        ]
                      : []),
                    ...(duration
                      ? [
                          {
                            icon: Icons.Time,
                            text: `${Duration__DurationString(duration)}`,
                            iconColor: "heather"
                          }
                        ]
                      : []),
                    ...(numberOfAdditionalDates
                      ? [
                          {
                            icon: Icons.Time,
                            text: `${numberOfAdditionalDates +
                              1} session course`,
                            iconColor: "heather"
                          }
                        ]
                      : []),
                    ...(numberOfWeeks
                      ? [
                          {
                            icon: Icons.Time,
                            text: `${numberOfWeeks} week term`,
                            iconColor: "heather"
                          }
                        ]
                      : []),
                    ...(level
                      ? [
                          {
                            icon: Icons.BulbThin,
                            text: level,
                            iconColor: "heather"
                          }
                        ]
                      : [])
                  ]}
                  direction="rtl"
                />
              )}
            </div>

            <div className={styles.pills({ className: "d-md-none" })}>
              {requiresSendingPhysicalMaterial && (
                <TextPill
                  className={styles.pill()}
                  text={__("Learning Kit Included")}
                  backgroundColor="camelot"
                />
              )}
            </div>

            {teacherImage && (
              <div className={styles.teacher({ className: "mt-4" })}>
                <CloudinaryImage
                  className={styles.teacherImage()}
                  src={teacherImage}
                  width={96}
                  height={96}
                />
                <div className={styles.teacherName({ className: "ml-3" })}>
                  {teacherName}
                </div>
              </div>
            )}
            {nextSessionDate && (
              <div className={styles.nextSessionDate({ className: "mt-4" })}>
                <span className={styles.nextSessionDateLabel()}>
                  Next available date:
                </span>
                {UTCDate__FormattedDate(
                  nextSessionDate,
                  timezone,
                  "Do MMMM YYYY"
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
