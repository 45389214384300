import { css } from "../../sitches.config";

function color(backgroundColor: string, foregroundColor: string) {
  return {
    border: `1px solid $${backgroundColor}`,
    backgroundColor: `$${backgroundColor}`,
    ...textColor(foregroundColor)
  };
}

function textColor(color: string) {
  return {
    color: `$${color}`,
    "&:hover, &:visited, &:focus, &:active": {
      color: `$${color}`
    }
  };
}

export const styles = {
  component: css({
    display: "inline-flex",
    gap: "$2",
    fontFamily: "$foundersGrotesk",
    fontWeight: "$1",
    lineHeight: "$1",
    cursor: "pointer",
    justifyContent: "center",
    outline: "none",
    textDecoration: "none",
    alignItems: "center",
    "&:hover": {
      textDecoration: "none"
    },
    "&:focus": { outline: "none" },
    variants: {
      color: {
        astra: color("astra", "ebonyClay"),
        azalea: color("azalea", "ebonyClay"),
        brightTeal: color("brightTeal", "white"),
        accent: color("accent", "white"),
        callToAction: color("callToAction", "white"),
        camelot: color("camelot", "white"),
        cruise: color("cruise", "ebonyClay"),
        ebonyClay: color("ebonyClay", "hummingBird"),
        emerald: color("emerald", "white"),
        emeraldish: color("emeraldish", "white"),
        gray: color("gray", "white"),
        gallery: color("gallery", "ebonyClay"),
        hummingBird: color("hummingBird", "ebonyClay"),
        lightGreen: color("lightGreen", "ebonyClay"),
        mercury: color("mercury", "ebonyClay"),
        prussian: color("prussian", "white"),
        robbinsEggBlue: color("robbinsEggBlue", "white"),
        silver: color("silver", "ebonyClay"),
        silverMalibu: color("silverMalibu", "white"),
        sun: color("sun", "white"),
        white: color("white", "prussian"),
        locked: color("gallery", "gray")
      },
      disabled: {
        true: {
          cursor: "default"
        }
      },
      // we need to have this entry in order to be used as selector in compound variants
      outline: {
        true: {}
      },
      size: {
        tiny: {
          height: "1.75rem",
          minWidth: "1.75rem",
          paddingX: "0.75rem",
          borderRadius: "0.875rem",
          fontSize: "$16"
        },
        small: {
          paddingX: "$3",
          fontSize: "$16",
          height: 35,
          minWidth: 35,
          borderRadius: "1.0625rem"
        },
        medium: {
          paddingX: "$3",
          fontSize: "$18",
          height: "2.5rem",
          minWidth: "2.5rem",
          borderRadius: "1.25rem"
        },
        large: {
          paddingX: "$4",
          fontSize: "$18",
          height: "3rem",
          minWidth: "3rem",
          borderRadius: "1.5rem"
        }
      },
      // we need to have this entry in order to be used as selector in compound variants
      square: {
        true: {}
      },
      noPadding: {
        true: {}
      },
      uppercase: {
        true: {
          textTransform: "uppercase"
        }
      },
      width: {
        auto: {
          width: "auto"
        },
        max: {
          width: "100%"
        }
      }
    },
    compoundVariants: [
      {
        uppercase: true,
        css: {
          fontSize: "$14"
        }
      },
      {
        outline: true,
        css: {
          backgroundColor: "transparent"
        }
      },
      {
        outline: true,
        color: "gallery",
        css: {
          backgroundColor: "$white"
        }
      },
      {
        outline: true,
        color: "mercury",
        css: {
          backgroundColor: "$white"
        }
      },
      {
        square: true,
        css: {
          borderRadius: "$1"
        }
      },
      {
        noPadding: true,
        css: {
          paddingX: "$1"
        }
      },

      ...[
        "astra",
        "azalea",
        "brightTeal",
        "callToAction",
        "camelot",
        "ebonyClay",
        "emerald",
        "emeraldish",
        "lightGreen",
        "prussian",
        "robbinsEggBlue",
        "silverMalibu",
        "sun",
        "white"
      ].map(color => ({
        outline: true,
        disabled: false,
        color,
        css: textColor(color)
      })),

      {
        outline: true,
        disabled: true,
        css: {
          borderColor: "$mercury",
          ...textColor("neutral")
        }
      },
      {
        outline: false,
        disabled: true,
        css: color("neutral", "white")
      }
    ]
  }),
  loadingWrapper: css({
  }),
  text: css({
    flex: "1 1",
    textAlign: "center"
  }),
  icon: css({
    display: "block",
    "& path": {
      fill: "currentColor"
    },
    variants: {
      size: {
        tiny: {
          height: 16
        },
        small: {
          height: 18
        },
        medium: {
          height: 20
        },
        large: {
          height: 22
        }
      }
    }
  }),
  tag: css({
    display: "inline-flex",
    alignItems: "center",
    fontSize: "$10",
    letterSpacing: "1px",
    color: "$astra",
    textTransform: "uppercase",
    paddingX: "$2",
    backgroundColor: "$emerald",
    borderRadius: "$1",
    textAlign: "center",
    verticalAlign: "top",
    height: "16px",
    lineHeight: "8px"
  })
};
