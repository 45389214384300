import React, { useState } from "react";
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// Components
import { CloudinaryImage } from "../CloudinaryImage";
import Icon from "../Icon";
import { ChevronPrev, ChevronNext } from "../Icons";
// Hooks
import useMediaQuery from "../../hooks/useMediaQuery";
import { styles } from "./Carousel.styles";

export function Carousel(props) {
  const { imagesURL, images, sizes, showIndicator } = props;
  const isNotMobile = useMediaQuery("(min-width: 700px)");
  const [indicatorIndex, setIndicatorIndex] = useState(0);

  return (
    <ResponsiveCarousel
      className={`responsive-carousel ${styles.responsiveCarousel({
        showIndicator,
      })}`}
      infiniteLoop={false}
      centerMode
      centerSlidePercentage={isNotMobile ? 46 : 64}
      showIndicators={showIndicator || false}
      showStatus={false}
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        if (isSelected) {
          setIndicatorIndex(index);
          return (
            // it will render inside an ul element
            <li
              className={`dot ${isSelected ? "selected" : ""}`}
              style={{ opacity: isSelected ? "1" : "0.3" }}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index + 1}
              key={index}
              role="button"
              tabIndex={0}
              aria-label={`${label} ${index + 1}`}
            >
              {/* render the number instead of a box*/}
            </li>
          );
        } else {
          return (
            // it will render inside an ul element
            <li
              className={
                index === indicatorIndex + 1 || index === indicatorIndex - 1
                  ? "dot"
                  : "d-none"
              }
              style={{
                opacity: isSelected ? "1" : "0.3",
                backgroundColor: "#faaa1e",
              }}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              aria-label={`${label} ${index + 1}`}
            >
              {/* render the number instead of a box*/}
            </li>
          );
        }
      }}
      renderArrowPrev={(onClick) => {
        return (
          <button className="control-arrow control-previous" onClick={onClick}>
            <Icon icon={ChevronPrev} />
          </button>
        );
      }}
      renderArrowNext={(onClick) => {
        return (
          <button
            className={`control-arrow control-next  ${styles.responsiveCarousel()}`}
            onClick={onClick}
          >
            <Icon icon={ChevronNext} />
          </button>
        );
      }}
    >
      {showIndicator
        ? imagesURL.map((image, index) => (
            <div key={index} className="carousel__wrapper">
              <a href={image.url}>
                <figure>
                  <CloudinaryImage
                    src={image.imgURL}
                    width={600}
                    height={390}
                    sizes={sizes}
                  />
                  <figcaption>{image.imgCaption}</figcaption>
                </figure>
              </a>
            </div>
          ))
        : images.map((image, index) => (
            <div key={index} className="carousel__wrapper">
              <CloudinaryImage
                src={image}
                width={600}
                height={390}
                sizes={sizes}
              />
            </div>
          ))}
    </ResponsiveCarousel>
  );
}
Carousel.propTypes = {};
