import { css } from "../../sitches.config";

export const styles = {
  row: css({
    "--gap": "$space$4",
    display: "flex",
    flexWrap: "wrap",
    rowGap: "0",
    columnGap: "var(--gap)",
    variants: {
      noGutters: {
        true: {
          "--gap": "0px"
        }
      },
      columnGap: {
        "1": { "--gap": "$space$1" },
        "2": { "--gap": "$space$2" },
        "3": { "--gap": "$space$3" },
        "4": { "--gap": "$space$4" }
      },
      rowGap: {
        "1": { rowGap: "$space$1" },
        "2": { rowGap: "$space$2" },
        "3": { rowGap: "$space$3" },
        "4": { rowGap: "$space$4" }
      }
    }
  })
};
