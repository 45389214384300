import React from "react";

import { bem } from "../../configs/bem";
import { styles } from "./EmbedVideoCard.styles";

const cn = bem("embed-video-card");
export default function EmbedVideoCard(props) {
  const { title, description, embedHtml } = props;
  return (
    <div className={`${cn()} ${styles.embedVideoCard}`}>
      <div className="row">
        {(title || description) && (
          <div className="col-12 col-md-6 col-lg-5">
            {title && (
              <h2 className={`${cn("title")} ${styles.title}`}>{title}</h2>
            )}
            {title && description && (
              <hr className={`${cn("separator")} ${styles.separator}`} />
            )}
            {description && (
              <div className={`${cn("description")} ${styles.description}`}>
                {description}
              </div>
            )}
          </div>
        )}
        <div
          className={`col-12 ${
            title || description ? "col-md-6 offset-lg-1" : ""
          }`}
        >
          <div
            className={`${cn("embed-html-wrapper")} ${styles.embedHtmlWrapper}`}
          >
            <div
              className={`${cn("embed-html")} ${styles.embedHtml}`}
              dangerouslySetInnerHTML={{ __html: embedHtml }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
