import React from "react";
import { BulletPointsList } from "../../lists/BulletPointsList";
import { ExpandableText } from "../ExpandableText";
import { Gallery } from "../Gallery";
import { bemModule } from "../../configs/bem";
import styles from "./AboutProductSection.module.less";

const cn = bemModule(styles, "about-product-section");
export function AboutProductSection(props) {
  const {
    description,
    whatsIncluded = [],
    requiredMaterial = [],
    images = [],
    onExpandDescription
  } = props;

  return (
    <div className={cn()}>
      <div className="pb-4">
        <ExpandableText
          maxWords={100}
          className={cn("description")}
          onExpand={onExpandDescription}
        >
          {description}
        </ExpandableText>
      </div>
      <div className="row py-4">
        {whatsIncluded.length > 0 && (
          <div className={cn("list", null, ["col-12 col-lg-6"])}>
            <BulletPointsList
              title="What is Included"
              color="royal-blue"
              items={whatsIncluded}
            />
          </div>
        )}
        {requiredMaterial.length > 0 && (
          <div className={cn("list", null, ["col-12 col-lg-6"])}>
            <BulletPointsList
              title="What you will need"
              color="lavender"
              items={requiredMaterial}
            />
          </div>
        )}
      </div>
      {images.length > 0 && (
        <div className="pt-4">
          <Gallery images={images} />
        </div>
      )}
    </div>
  );
}
