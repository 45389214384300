import React from "react";
import { bemModule } from "../../configs/bem";
import styles from "./SimpleBanner.module.less";

const cn = bemModule(styles, "simple-banner");
export function SimpleBanner(props) {
  const { accent = "camelot", title, footer, onFooterClick, children } = props;

  return (
    <div className={cn({ accent })}>
      <div className={`px-4 ${children ? "py-2" : "pt-2 pb-0"}`}>
        <div className={cn("title", null, ["py-3"])}>{title}</div>
        {children && (
          <div className={cn("content", null, ["py-3"])}>{children}</div>
        )}
      </div>
      {footer && (
        <div
          className={cn("footer", { border: children !== undefined })}
          onClick={onFooterClick}
        >
          {footer}
        </div>
      )}
    </div>
  );
}
