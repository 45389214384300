import React from "react";

import { styles } from "./ErrorBanner.styles";
import { UrlButton } from "../../index";

export function ErrorBanner({ title, description, imageUrl, homeUrl }: Props) {
  return (
    <div className={styles.component({ className: "error-banner" })}>
      <div>
        <h1 className={styles.title()}>{title}</h1>
        <h2 className={styles.description()}>{description}</h2>
        {homeUrl && (
          <UrlButton
            text="Home"
            uppercase
            width="auto"
            size="large"
            color="ebonyClay"
            href={homeUrl}
          />
        )}
      </div>
      {imageUrl && (
        <div className={styles.imageWrapper()}>
          <img className={styles.image()} src={imageUrl} />
        </div>
      )}
    </div>
  );
}

interface Props {
  title: string;
  description: string;
  imageUrl?: string;
  homeUrl?: string;
}
