import React, { useState } from "react";

import Accordion from "../Accordion";
import PillButton from "../PillButton";
import { ChevronDown } from "../Icons";
import Rail from "../Rail";
import styles from "./filters.module.less";

export function Filters(props) {
  const { filters } = props;
  return (
    <div className={styles["filters"]}>
      <div className="d-lg-none">
        <Rail>
          {filters
            .filter(filter => filter.mobile !== false)
            .map((filter, index) => {
              const {
                text,
                value = null,
                label,
                onButtonClick = () => {}
              } = filter;
              return (
                <PillButton
                  key={index}
                  text={label || value || text}
                  size="small"
                  width="auto"
                  icon={ChevronDown}
                  isSelected={value !== null}
                  onClick={() => onButtonClick(filter)}
                />
              );
            })}
        </Rail>
      </div>
      <div className="d-none d-lg-block">
        {filters
          .filter(filter => filter.desktop !== false)
          .map((filter, index) => (
            <div key={index} className={styles["filters__filter"]}>
              <Accordion title={filter.text}>
                <div className={styles["filters__filter-body"]}>
                  {filter.body}
                </div>
              </Accordion>
            </div>
          ))}
      </div>
    </div>
  );
}
