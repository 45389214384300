import React, { useEffect } from "react";
import { css, animations } from "@obby/components";
import Icon from "../Icon";
import { Cross } from "../Icons";

interface BoxPopupProps {
  children: any;
  onClose: () => void;
  isOpen?: boolean;
  doNotDisableDocumentScrolling?: boolean;
}

function BoxPopup({
  children,
  onClose,
  isOpen = false,
  doNotDisableDocumentScrolling = false
}: BoxPopupProps) {
  useEffect(() => {
    if (!doNotDisableDocumentScrolling) {
      if (isOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen, doNotDisableDocumentScrolling]);

  return (
    <div className={styles.wrap({ isOpen })}>
      <div className={styles.overlay()} onClick={onClose} />
      <div className={styles.container()}>
        <div className={styles.box()}>
          <button className={styles.close()} onClick={onClose}>
            {/* @ts-ignore */}
            <Icon width={24} icon={Cross} />
          </button>
          <div className={styles.body()}>{children}</div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  wrap: css({
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    zIndex: 1001,
    overflow: "auto",
    display: "none",

    variants: {
      isOpen: {
        true: {
          display: "block"
        }
      }
    }
  }),

  overlay: css({
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.8)"
  }),

  container: css({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    minWidth: 350,

    "@md": {
      paddingX: 20
    }
  }),

  box: css({
    position: "relative",
    marginTop: 60,
    animation: `${animations.popout} 250ms, ${animations.fadeIn} 250ms`
  }),

  body: css({
    position: "relative",
    borderRadius: 5,
    overflow: "hidden",
    zIndex: 1
  }),

  close: css({
    position: "absolute",
    top: 18,
    right: 18,
    cursor: "pointer",
    backgroundColor: "transparent",
    border: "none",
    "-webkit-appearance": "none",
    zIndex: 2
  })
};

export default BoxPopup;
export { BoxPopup };
