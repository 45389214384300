import React, { MutableRefObject } from "react";
import { styles } from "./Container.styles";

export function Container({ children, className, innerRef, ...props }: Props) {
  return (
    <div ref={innerRef} className={styles.box({ className })} {...props}>
      {children}
    </div>
  );
}

interface Props
  extends Omit<React.HTMLProps<HTMLDivElement>, "dangerouslySetInnerHTML"> {
  innerRef?: MutableRefObject<HTMLDivElement>;
}
