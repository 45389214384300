import React, { useState } from "react";

import { PrimaryButton } from "../../buttons/PrimaryButton";
import { RailCarousel2 } from "../../components/RailCarousel2";
import { bemModule } from "../../configs/bem";
import styles from "./ResponsiveRail.module.less";

const cn = bemModule(styles, "responsive-rail");
export function ResponsiveRail({
  title,
  children,
  description,
  numberOfColumns,
  onSeeMore
}) {
  const [viewMore, setViewMore] = useState(false);
  function onViewMoreClick() {
    if (onSeeMore) {
      onSeeMore();
    } else {
      setViewMore(true);
    }
  }

  return (
    <>
      <div className={cn("mobile")}>
        <ul className={cn("mobile-product-list")}>
          {title && (
            <li className={cn("mobile-product-list-title")}>{title}</li>
          )}
          {React.Children.toArray(children)
            .filter((_, index) => viewMore || index < 3)
            .map((child, index) => (
              <li className={cn("mobile-product-list-item")} key={index}>
                {child}
              </li>
            ))}
          {!viewMore && (
            <li className={cn("mobile-product-list-item")}>
              <span
                className={cn("mobile-product-list-view-more")}
                onClick={onViewMoreClick}
              >
                View more
              </span>
            </li>
          )}
        </ul>
      </div>
      <div className={cn("tablet")}>
        <RailCarousel2 numberOfColumns={3} title={title}>
          {children}
        </RailCarousel2>
      </div>
      <div className={cn("desktop")}>
        <RailCarousel2
          title={title}
          description={description}
          numberOfColumns={numberOfColumns}
          hideArrows={React.Children.count(children) <= numberOfColumns}
        >
          {children}
        </RailCarousel2>
        {onSeeMore ? (
          <div className={cn("desktop__see-more")}>
            <PrimaryButton
              className={cn("button")}
              width="auto"
              text={"See all Learning Kits"}
              onClick={onSeeMore}
              size="medium"
              color="ebonyClay"
            />
          </div>
        ) : null}
      </div>
    </>
  );
}
