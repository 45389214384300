import React, { useMemo } from "react";
import { ImageUrl__ImageResizedUrl } from "@obby/lib";
import styles from "./category-card.module.less";
import Link from "../Link";

export default function CategoryCard(props) {
  const { onClick, label, url } = props;

  const thumbnail = useMemo(() => {
    return ImageUrl__ImageResizedUrl(
      { width: 222, height: 118 },
      props.thumbnail
    );
  }, [props.thumbnail]);

  return (
    <div className={styles["category-card"]} onClick={onClick}>
      <Link
        draggable={false}
        onDragStart={e => e.preventDefault()}
        href={url}
        className={styles["category-card__thumbnail"]}
      >
        <img
          className={styles["category-card__thumbnail-image"]}
          alt={`${label}'s category image`}
          src={thumbnail}
          loading="lazy"
        />
      </Link>
      <Link
        draggable={false}
        onDragStart={e => e.preventDefault()}
        className={styles["category-card__label-link"]}
        href={url}
      >
        <span className={styles["category-card__label"]}>{label}</span>
      </Link>
    </div>
  );
}
