import React from "react";
import PropTypes from "prop-types";

import styles from "./StickyCTA.module.less";
import Link from "../Link";
import { PrimaryButton } from "../../buttons/PrimaryButton";

const StickyCTA = ({ title, cta }) => {
  return (
    <div className={styles["sticky-cta"]}>
      <div className={styles["sticky-cta__container"]}>
        <span className={styles["sticky-cta__title"]}>{title}</span>
        <Link className={styles["sticky-cta__cta"]} href={cta.link}>
          <PrimaryButton
            textMode="uppercase"
            text={cta.text}
            color="robbinsEggBlue"
          />
        </Link>
      </div>
    </div>
  );
};

StickyCTA.propTypes = {
  title: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
  }).isRequired
};

export default StickyCTA;
