import { atom, selector } from "recoil";
import { pathToSearchState } from "@obby/lib";

export const filtersOnPathState = atom({
  key: "filtersOnPathState",
  default: []
});

export const atomCurrentPage = atom({
  key: "atomCurrentPage",
  default: 0
});

export const atomMaxPage = atom({
  key: "atomMaxPage",
  default: 1
});

export const atomResultsPerPage = atom({
  key: "atomResultsPerPage",
  default: 20
});

export const atomSearchState = selector({
  key: "atomSearchState",
  get: ({ get }) => {
    const filters = get(filtersOnPathState);
    const currentPage = get(atomCurrentPage);
    let searchState = pathToSearchState(filters);
    searchState.currentPage = currentPage;
    searchState.resultsPerPage = get(atomResultsPerPage);
    return searchState;
  }
});

export const atomResultsState = atom({
  key: "atomResultsState",
  default: []
});
