import * as Yup from "yup";

import { CheckoutPersonalDetailsForm } from "../../forms/CheckoutPersonalDetailsForm";

export function GroupCheckoutSchema(isSendingMaterial: boolean) {
  return Yup.object({
    dateTime: Yup.object({
      date: Yup.date()
        .label("Date")
        .required("Please select a date to attend the class.")
        .nullable(true)
        .default(null),
      time: Yup.number()
        .label("Time")
        .min(0)
        .required("Please select the time you wish to attend the class.")
        .nullable(true)
        .default(null)
    }),

    ticket: Yup.object({
      numberOfGuests: Yup.number()
        .label("Guests")
        .min(1)
        .required()
        .nullable(true)
        .default(null),
      ticketTypeId: Yup.string().required()
    }).required(),

    inputs: Yup.array()
      .of(
        Yup.string()
          .required()
          .default("")
      )
      .default([]),
    personalDetails: CheckoutPersonalDetailsForm.Schema(isSendingMaterial),
    discountCode: Yup.string()
      .when(
        "personalDetails.guests.0.email",
        (email: string, schema: Yup.AnySchema) => {
          if (
            !Yup.string()
              .email()
              .required()
              .isValidSync(email)
          )
            return schema.test(
              "email-address-for-discount",
              "Fill in email address first in Personal details step",
              () => false
            );

          return schema;
        }
      )
      .default("")
  }).required();
}
export type Fields = Yup.InferType<ReturnType<typeof GroupCheckoutSchema>>;
