import React from "react";
import RatingSummary from "../../components/RatingSummary";
import { PrimaryButton } from "../../components/PrimaryButton";
import SecondaryButton from "../../components/SecondaryButton";
import { bemModule } from "../../configs";

import styles from "./GiftPageFooterBar.module.less";

const cn = bemModule(styles, "gift-page-footer-bar");
export function GiftPageFooterBar({
  menuActions = [],
  rating = 0,
  numberOfReviews = 0
}) {
  return (
    <div className={cn()}>
      <div className="container-fluid">
        <div className={cn("wrapper")}>
          <div className={cn("actions")}>
            {menuActions
              .filter(action => !action.hidden)
              .map((action, index) => {
                const { type = "PRIMARY" } = action;
                const Button = {
                  SECONDARY: SecondaryButton,
                  PRIMARY: PrimaryButton
                }[type];
                return (
                  <Button
                    key={index}
                    onClick={action.onClick}
                    text={action.text}
                    width={action.width}
                    textMode={action.textMode || "uppercase"}
                    color={action.color || "robbinsEggBlue"}
                    disabled={action.disabled || false}
                    className={cn("action")}
                  />
                );
              })}
          </div>
          <div className={cn("reviews")}>
            {numberOfReviews > 0 && (
              <RatingSummary
                rating={rating}
                reviewCount={numberOfReviews}
                iconSize={15}
                primaryColor="emeraldish"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

GiftPageFooterBar.menuActionTypes = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY"
};
