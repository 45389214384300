import { css, withBem } from "../../sitches.config";

export const styles = withBem("block", {
  block: css({
    variants: {
      ...generateDisplay(),
      ...generate("margin"),
      ...generate("padding")
    }
  })
});

function generateDisplay() {
  const attribute = "display";
  return ["", "Sm", "Smd", "Md", "Lg", "Xl"].reduce<any>(
    (variants, breakpoint) => {
      variants[`display${breakpoint}`] = [
        "block",
        "flex",
        "inline",
        "inline-block",
        "none"
      ].reduce<any>((variant, display) => {
        variant[display] = breakpoint
          ? { [`@${breakpoint.toLowerCase()}`]: { [attribute]: display } }
          : { [attribute]: display };
        return variant;
      }, {});

      return variants;
    },
    {}
  );
}

function generate(attribute: string) {
  const sides = ["", "Top", "Right", "Bottom", "Left"];
  return sides.reduce<any>((variants, side) => {
    return { ...variants, ...generateSelectors(`${attribute}${side}`) };
  }, {});
}

function generateSelectors(attribute: string) {
  return {
    ...generateBreakpoints(attribute, ""),
    [`${attribute}Empty`]: generatePseudoSelectors(attribute, "Empty"),
    [`${attribute}First`]: generatePseudoSelectors(attribute, "First"),
    [`${attribute}Last`]: generatePseudoSelectors(attribute, "Last")
  };
}

function generateBreakpoints(attribute: string, selector: string) {
  const breakpoints = ["", "Sm", "Smd", "Md", "Lg", "Xl"];

  return breakpoints.reduce<any>((variants, breakpoint) => {
    variants[`${attribute}${selector}${breakpoint}`] = generateVariant(
      attribute,
      selector,
      breakpoint
    );

    return variants;
  }, {});
}

function generateVariant(
  attribute: string,
  selector: string,
  breakpoint: string
) {
  return [0, 1, 2, 3, 4].reduce<any>((variant, space) => {
    let rule: any = { [attribute]: space ? `$${space}` : space };
    /*
    switch (selector) {
      case "Empty":
        rule = { "&:empty": rule };
        break;
      case "First":
        rule = { "&:first-child": rule };
        break;
      case "Last":
        rule = { "&:last-child": rule };
        break;
    }
    */

    if (breakpoint) rule = { [`@${breakpoint.toLowerCase()}`]: rule };

    variant[space] = rule;
    return variant;
  }, {});
}

function generatePseudoSelectors(attribute: string, selector: string) {
  return [0, 1, 2, 3, 4].reduce<any>((variant, space) => {
    let rule: any = { [attribute]: space ? `$${space}` : space };

    switch (selector) {
      case "Empty":
        rule = { "&:empty": rule };
        break;
      case "First":
        rule = { "&:first-child": rule };
        break;
      case "Last":
        rule = { "&:last-child": rule };
        break;
    }

    variant[space] = rule;
    return variant;
  }, {});
}
