import React from "react";
import moment from "moment";

import RatingSummary from "../RatingSummary";
import FavoriteButton from "../FavouriteButton";
import CardBackground from "../CardBackground";
import ReviewCard from "../ReviewCard";
import { PrimaryButton } from "../PrimaryButton";
import BannerCard from "../BannerCard";
import HighlightPrice from "../HighlightPrice";
import Link from "../Link";
import {
  CurrencyISO__CurrencySymbol,
  Number__Currency,
  CloudinaryUrl__TransformedUrl,
  Discount__DiscountedPrice
  //@ts-ignore
} from "@obby/lib";
import * as Icons from "../Icons";
import Icon from "../Icon";
import { styles } from "./CourseWideCardWithReviews.styles";

interface CourseWideCardWithReviewsProps {
  thumbnail: string;
  title: string;
  description: string;
  rating?: number;
  numberOfReviews?: number;
  price?: number;
  currency: string;
  duration?: number;
  type: string;
  onAddToWishList: () => void;
  onRemoveFromWishList: () => void;
  location?: string;
  addToWishList?: boolean;
  discount?: any;
  nextAvailableDate?: any;
  isFlexitime?: boolean;
  isFavorite?: boolean;
  url: string;
  rankedCourseReview?: any;
  isOnline?: boolean;
  hasMaterials?: boolean;
  rankedProductReview?: any;
  stock?: number;
  index?: number;
  currentPage?: number;
  isPriceFrom: boolean;
}

function CourseWideCardWithReviews({
  index = 1,
  currentPage = 0,
  thumbnail,
  title,
  url,
  description,
  rating,
  rankedCourseReview = {},
  rankedProductReview = {},
  numberOfReviews = 0,
  price,
  currency,
  location,
  isOnline = false,
  hasMaterials = false,
  duration,
  addToWishList = true,
  discount,
  stock,
  isFavorite,
  onAddToWishList = () => {},
  onRemoveFromWishList = () => {},
  type,
  nextAvailableDate,
  isFlexitime,
  isPriceFrom
}: CourseWideCardWithReviewsProps) {
  function onFavoriteToggle(e: any) {
    e.stopPropagation();
    e.preventDefault();
    if (isFavorite) onRemoveFromWishList();
    else onAddToWishList();
  }
  return (
    <>
      <Link href={url}>
        <div
          className={`course-wide-card-with-reviews ${styles.courseWideCardWithReviews()}`}
        >
          <CardBackground
            className={`course-wide-card-with-reviews__background ${styles.background()}`}
            thumbnail={CloudinaryUrl__TransformedUrl(thumbnail, {
              width: 535,
              height: 405,
              crop: "fill"
            })}
            thumbnailCols={5}
          >
            <div
              className={`course-wide-card-with-reviews__content ${styles.content()}`}
            >
              <div
                className={`course-wide-card-with-reviews__header ${styles.header()}`}
              >
                <span
                  className={`course-wide-card-with-reviews__title ${styles.title()}`}
                >
                  {currentPage > 0 ? `${title}` : `${index}. ${title}`}
                </span>
                <div
                  className={`course-wide-card-with-reviews__button ${styles.button()}`}
                >
                  <PrimaryButton
                    onClick={() => (window.location.href = url)}
                    size="large"
                    width="auto"
                    text="Learn More"
                    color="robbinsEggBlue"
                  />
                </div>
              </div>
              {numberOfReviews > 0 && (
                <div
                  className={`course-wide-card-with-reviews__section ${styles.section()}`}
                >
                  <div className="d-none d-sm-block">
                    <RatingSummary
                      rating={rating}
                      reviewCount={numberOfReviews}
                      starColor="emeraldish"
                    />
                  </div>
                </div>
              )}
              <div
                className={`course-wide-card-with-reviews__main ${styles.main()}`}
              >
                <div
                  className={`course-wide-card-with-reviews__section ${styles.section()} course-wide-card-with-reviews__review ${styles.review()}`}
                >
                  {numberOfReviews > 0 ? (
                    <ReviewCard
                      {...(type === "class"
                        ? rankedCourseReview
                        : rankedProductReview)}
                    />
                  ) : (
                    description
                  )}
                </div>

                {nextAvailableDate && (
                  <div
                    className={`course-wide-card-with-reviews__section ${styles.section()}`}
                  >
                    <div
                      className={`course-wide-card-with-reviews__next-session-date ${styles.nextSessionDate()}`}
                    >
                      <span
                        className={`course-wide-card-with-reviews__next-session-date-label  ${styles.nextSessionDateLabel()}`}
                      >
                        Next available date:
                      </span>
                      {moment(nextAvailableDate).format("Do MMMM YYYY")}
                    </div>
                  </div>
                )}
                {!nextAvailableDate && isFlexitime && (
                  <div
                    className={`course-wide-card-with-reviews__section  ${styles.section()}`}
                  >
                    <div
                      className={`course-wide-card-with-reviews__next-session-date  ${styles.nextSessionDate()}`}
                    >
                      <span
                        className={`course-wide-card-with-reviews__next-session-date-label  ${styles.nextSessionDateLabel()}`}
                      >
                        Book this class as a private group!
                      </span>
                    </div>
                  </div>
                )}
                {type === "class" ? (
                  <div
                    className={`course-wide-card-with-reviews__footer  ${styles.footer()}`}
                  >
                    <Separator
                      className={`course-wide-card-with-reviews__extra-info`}
                    >
                      {isOnline && (
                        <>
                          <Icon
                            className={`course-wide-card-with-reviews__location-icon  ${styles.locationIcon(
                              { variant: "liveOnline" }
                            )}`}
                            height={16}
                            icon={Icons.VideoCamera}
                            color="nickel"
                          />
                          <span
                            className={`${styles.label({
                              variant: "liveOnline"
                            })}`}
                          >
                            join live online
                          </span>
                        </>
                      )}
                      {hasMaterials && (
                        <>
                          <Icon
                            className={`course-wide-card-with-reviews__location-icon  ${styles.locationIcon()}`}
                            height={16}
                            icon={Icons.Delivery}
                            color="nickel"
                          />
                          kit delivered to you
                        </>
                      )}
                      {location && (
                        <>
                          <Icon
                            className={`course-wide-card-with-reviews__location-icon  ${styles.locationIcon()}`}
                            height={16}
                            color="nickel"
                            icon={Icons.CartCheck}
                          />
                          {location}
                        </>
                      )}
                      {price && (
                        <>
                          <Icon
                            className={`course-wide-card-with-reviews__location-icon d-none d-sm-inline  ${styles.locationIcon()}`}
                            height={16}
                            color="nickel"
                            icon={Icons.VideoCamera}
                          />
                          <span className="d-none d-sm-inline">
                            {discount && (
                              <HighlightPrice
                                price={Discount__DiscountedPrice(
                                  discount,
                                  price
                                )}
                              />
                            )}
                            <span
                              className={styles.price({
                                withDiscount: discount !== undefined
                              })}
                            >
                              {isPriceFrom ? "from " : ""}
                              {Number__Currency(
                                price,
                                CurrencyISO__CurrencySymbol(currency)
                              )}
                            </span>
                          </span>
                        </>
                      )}
                      {duration && !isOnline && (
                        <>
                          <Icon
                            className={`course-wide-card-with-reviews__location-icon  ${styles.locationIcon()}`}
                            height={16}
                            color="nickel"
                            icon={Icons.Clock}
                          />
                          {duration}
                        </>
                      )}
                    </Separator>
                  </div>
                ) : (
                  <div
                    className={`course-wide-card-with-reviews__footer  ${styles.footer()}`}
                  >
                    <Separator
                      className={
                        !isOnline
                          ? `course-wide-card-with-reviews__extra-info ${styles.extraInfoItem(
                              { variant: "displayGrid" }
                            )}`
                          : `course-wide-card-with-reviews__extra-info`
                      }
                    >
                      <>
                        <Icon
                          className={`course-wide-card-with-reviews__location-icon  ${styles.locationIcon()}`}
                          height={16}
                          color="nickel"
                          icon={Icons.VideoCamera}
                        />

                        <span
                          className={`${styles.label({
                            variant: "watchTutorial"
                          })}`}
                        >
                          watch video tutorial
                        </span>
                      </>
                      {type === "product" && stock !== undefined && (
                        <>
                          <Icon
                            className={`course-wide-card-with-reviews__location-icon  ${styles.locationIcon()}`}
                            height={16}
                            color="nickel"
                            icon={Icons.CartCheck}
                          />
                          {stock === 0
                            ? "out of stock"
                            : stock < 5
                            ? "low stock"
                            : "in stock"}
                        </>
                      )}
                      {hasMaterials ||
                        (type === "product" && (
                          <>
                            <Icon
                              className={`course-wide-card-with-reviews__location-icon  ${styles.locationIcon()}`}
                              height={16}
                              color="nickel"
                              icon={Icons.Delivery}
                            />
                            kit delivered to you
                          </>
                        ))}
                      {price && (
                        <div className="d-sm-flex d-none">
                          <Icon
                            className={`course-wide-card-with-reviews__location-icon  ${styles.locationIcon()}`}
                            height={16}
                            color="nickel"
                            icon={Icons.Currency}
                          />
                          <span>
                            {discount && (
                              <HighlightPrice
                                price={Discount__DiscountedPrice(
                                  discount,
                                  price
                                )}
                              />
                            )}
                            <span
                              className={styles.price({
                                withDiscount: discount !== undefined
                              })}
                            >
                              {isPriceFrom ? "from " : ""}
                              {Number__Currency(
                                price,
                                CurrencyISO__CurrencySymbol(currency)
                              )}
                            </span>
                          </span>
                        </div>
                      )}
                    </Separator>
                  </div>
                )}
                <div
                  className={`course-wide-card-with-reviews__section  ${styles.section()}`}
                >
                  <div
                    className={`course-wide-card-with-reviews__bottom ${styles.bottom()}  d-sm-none`}
                  >
                    <div>
                      {discount && (
                        <HighlightPrice
                          price={Discount__DiscountedPrice(discount, price)}
                        />
                      )}
                      <span
                        className={styles.price({
                          withDiscount: discount !== undefined
                        })}
                      >
                        {isPriceFrom ? "from " : ""}
                        {Number__Currency(
                          price,
                          CurrencyISO__CurrencySymbol(currency)
                        )}
                      </span>
                    </div>
                    <div>
                      <RatingSummary
                        rating={rating}
                        short={true}
                        reviewCount={numberOfReviews}
                        starColor="emeraldish"
                        starSize="small"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardBackground>
          {addToWishList && (
            <FavoriteButton
              className={`course-wide-card-with-reviews__favorite-button  ${styles.favoriteButton()}`}
              checked={isFavorite}
              onToggle={onFavoriteToggle}
            />
          )}
        </div>
      </Link>
      {index % 8 === 0 && (
        <Link href="/gift">
          <div className="mb-4 mt-4">
            <BannerCard
              title="WITH AN OBBY GIFT CARD THEY CAN CHOOSE"
              description="Give someone the gift of learning and the power to choose from over 2500 classes!"
              buttonText="Discover more"
              thumbnail="https://res.cloudinary.com/obby/image/upload/v1648747859/Page%20Images/Obby_Terrarium-18_1.png"
              categorySticker="https://res.cloudinary.com/obby/image/upload/v1648798736/Page%20Images/floristryy.png"
              backgroundColor="sun"
              buttonColor="astra"
              onClick={onGiftCardClick}
            />
          </div>
        </Link>
      )}
    </>
  );
}

function onGiftCardClick() {
  window.location.href = "/gift";
}

function Separator(props: any) {
  const { children, separator, className = "" } = props;

  let printSeparator = false;
  return (
    <div className={className}>
      {React.Children.map(children, (child, index) => {
        if (!child) return <></>;
        const render = (
          <>
            {printSeparator && separator}
            <span
              className={
                className
                  ? `${className}-item ${styles.extraInfoItem()}`
                  : undefined
              }
            >
              {child}
            </span>
          </>
        );
        printSeparator = true;
        return render;
      })}
    </div>
  );
}

export { CourseWideCardWithReviews };
