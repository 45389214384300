import React from "react";
import { bemModule } from "../../configs/bem";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import { SecondaryButton } from "../../buttons/SecondaryButton";
import ThemedMarkdownText from "../ThemedMarkdownText";
import CardBackground from "../CardBackground";

import styles from "./action-banner.module.less";

const cn = bemModule(styles, "action-banner");
export default function ActionBanner(props) {
  const {
    title,
    thumbnail,
    backgroundColor = "concrete",
    description = "",
    /** @deprecated */
    descriptions = [],
    actions,
    size = "medium"
  } = props;

  return (
    <CardBackground
      thumbnail={thumbnail}
      thumbnailPosition="right"
      color={backgroundColor}
      className={cn({ size })}
    >
      <div className={cn("wrapper")}>
        <div className={cn("title", { size })}>{title}</div>
        <div className={cn("description", { size })}>
          {descriptions.map((description, index) => (
            <ThemedMarkdownText
              key={index}
              text={description}
              classNames={{
                link: cn("description-link", { size }),
                paragraph: cn("description-paragraph", { size })
              }}
            />
          ))}
          {description}
        </div>
        <div className={cn("space")} />
        <div className={cn("actions")}>
          {actions.map(action => {
            if (action.type === "primary") {
              return (
                <PrimaryButton
                  key={action.text}
                  className={cn("action")}
                  width="auto"
                  text={action.text}
                  color={action.color || "emeraldish"}
                  size={size}
                  disabled={action.disabled}
                  uppercase
                  onClick={() => window.open(action.link, action.target)}
                />
              );
            }
            return (
              <SecondaryButton
                key={action.text}
                className={cn("action")}
                width="auto"
                text={action.text}
                color={action.color || "emeraldish"}
                size={size}
                uppercase
                disabled={action.disabled}
                onClick={() => window.open(action.link, action.target)}
              />
            );
          })}
        </div>
      </div>
    </CardBackground>
  );
}
