import { css, withBem } from "./../sitches.config";

export const styles = withBem("table-header", {
  tableHeader: css({
    "&:first-child > tr > .table-header-cell": {
      "&:first-child": {
        borderTopLeftRadius: "var(--table-border-radius, 0)"
      },
      "&:last-child": {
        borderTopRightRadius: "var(--table-border-radius, 0)"
      }
    },
    "&:last-child > tr > .table-header-cell": {
      borderBottomWidth: "1px",
      "&:first-child": {
        borderBottomLeftRadius: "var(--table-border-radius, 0)"
      },
      "&:last-child": {
        borderBottomRightRadius: "var(--table-border-radius, 0)"
      }
    }
  })
});
