import React from "react";

import { CCourseTypes, CCourseTypesOptions } from "@obby/lib";
import { CourseWideCard } from "../CourseWideCard";
import Link from "../Link";
import { bem } from "../../configs/bem";
import { styles } from "./ClassSublist.styles";

const cn = bem("class-sublist");
export default function ClassSublist(props) {
  const {
    subList,
    onClickSubListItem,
    onAddClassToWishList,
    onRemoveClassFromWishList
  } = props;

  return (
    <div className={`${cn()} ${styles.classSublist}`}>
      {subList.map((subListItem, index) => (
        <div key={index} className={`${cn("list-group")} ${styles.listGroup}`}>
          <div className={`${cn("beacon")}`} id={subListItem.id} />
          <div className={`${cn("title")} ${styles.title}`}>
            {subListItem.title}
          </div>
          <div className={`${cn("list")} ${styles.list}`}>
            {subListItem.classes.map((course, index) => (
              <div key={index} className={`${cn("list-item")}`}>
                <Link
                  className={`${cn("class-link")}`}
                  href={`/classes/${course.category}/${course.subcategory}/${course.slug}`}
                  onClick={() => onClickSubListItem(course, subListItem)}
                >
                  <CourseWideCard
                    key={course.id}
                    thumbnail={course.thumbnail}
                    title={course.title}
                    type={
                      course.type === CCourseTypes.WORKSHOP
                        ? CCourseTypesOptions[CCourseTypes.CLASS]
                        : CCourseTypesOptions[course.type]
                    }
                    description={course.shortDescription}
                    url={`/${course.category}/${course.subcategory}/${course.slug}`}
                    rating={course.summary.rating}
                    numberOfReviews={course.summary.numberOfReviews}
                    numberOfStudents={course.summary.capacity}
                    price={course.summary.price}
                    samePriceAlways={
                      course.summary.additionalPrices.length === 0
                    }
                    location={
                      course.isOnline
                        ? `Live Online${
                            course.requiresSendingPhysicalMaterial
                              ? " + Kit Included"
                              : ""
                          }`
                        : course.summary.location
                    }
                    duration={
                      course.type !== CCourseTypes.COURSE
                        ? course.summary.duration
                        : undefined
                    }
                    numberOfAdditionalDates={
                      course.type === CCourseTypes.COURSE
                        ? course.summary.nextAvailableAdditionalDates?.length
                        : undefined
                    }
                    discount={course.discount}
                    isFavorite={course.isFavourite}
                    onAddToWishList={() => onAddClassToWishList(course._id)}
                    onRemoveFromWishList={() =>
                      onRemoveClassFromWishList(course._id)
                    }
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
