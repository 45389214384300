import React, { useRef } from "react";

import { TextInput } from "../../../inputs/TextInput";
import { SecondaryButton } from "../../../buttons/SecondaryButton";
import styles from "./FileInput.module.less";

export function FileInput(props) {
  const {
    name,
    value,
    onChange,
    onBlur,
    onUpload = () => {},
    text = "Browse...",
    multiple = false,
    isUploading
  } = props;
  const inputRef = useRef();

  function onBrowseFilesClick(e) {
    inputRef.current.click();
  }

  function onFileInputChange(e) {
    const [file] = e.target.files;
    onUpload(file);
  }

  return (
    <div className={styles["file-input"]}>
      <TextInput
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        disabled={isUploading}
      />
      <SecondaryButton
        className={styles["file-input__browse-button"]}
        size="medium"
        color="brightTeal"
        text={isUploading ? "Uploading..." : text}
        onClick={onBrowseFilesClick}
        disabled={isUploading}
        width="auto"
      />
      <input
        ref={inputRef}
        className={styles["file-input__file-input"]}
        type="file"
        onChange={onFileInputChange}
        multiple={multiple}
      />
    </div>
  );
}
