import * as Yup from "yup";

export function EnquireLargeGroupFormSchema() {
  return Yup.object({
    firstName: Yup.string()
      .label("First name")
      .required()
      .default(""),
    lastName: Yup.string()
      .label("last Name")
      .required()
      .default(""),
    companyName: Yup.string()
      .label("Company Name")
      .default(""),
    email: Yup.string()
      .label("Email")
      .email()
      .required()
      .default(""),
    phoneNumber: Yup.string()
      .label("Phone")
      .default(""),
    numberOfGuests: Yup.string()
      .label("Number of guests")
      .required()
      .oneOf([
        "15 - 20",
        "20 - 25",
        "25 - 30",
        "30 - 35",
        "35 - 40",
        "40 - 45",
        "45 - 50",
        "50+"
      ])
      .default("15 - 20"),
    eventOccasion: Yup.string()
      .label("Event Occasion")
      .required()
      .oneOf([
        "Corporate Event",
        "Team Social",
        "Family/Friends Gathering",
        "Celebration (Birthday, Hen Party, etc.)",
        "Other"
      ])
      .default("Corporate Event"),
    eventType: Yup.string()
      .label("Event Type")
      .required()
      .oneOf(["In-Person Event", "Virtual Event", "At Home Kits"])
      .default("In-Person Event"),
    preferredDate: Yup.date()
      .label("Preferred Date")
      .required()
      .nullable()
      .default(null),
    budgetPerPerson: Yup.number()
      .required()
      .nullable()
      .default(null),
    desiredLocation: Yup.string()
      .label("Desired Location")
      .default(""),
    message: Yup.string()
      .label("Message")
      .default("")
  }).required();
}

export interface Fields {
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  phoneNumber: string;
  numberOfGuests: string;
  eventOccasion: string;
  eventType: string;
  preferredDate: Date | null;
  budgetPerPerson: number | null;
  desiredLocation: string;
  message: string;
}
