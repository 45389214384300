import { merge, values } from "ramda";

import SearchRoute__SearchState from "./SearchRoute__SearchState";
import SearchQueryParams__SearchState from "./SearchQueryParams__SearchState";

const SearchParamsAndQueryParams__SearchState = (
  route,
  queryParams,
  categories
) => {
  var searchParamsSearchState = SearchRoute__SearchState(route, categories);
  var queryParamsSearchState = SearchQueryParams__SearchState(queryParams);
  return merge(searchParamsSearchState, queryParamsSearchState);
};

export default SearchParamsAndQueryParams__SearchState;
