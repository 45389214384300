export function scrollToElement(element, behavior = "smooth") {
  setTimeout(() => {
    const top = element
      ? element.getBoundingClientRect().top + window.pageYOffset
      : 0;

    if (window.scroll) {
      window.scroll({
        top,
        behavior
      });
    } else {
      window.scrollTo(0, top);
    }
  }, 0);
}
