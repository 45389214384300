import React from "react";
import { styles } from "./Box.styles";

export function Box({
  borderColor = "gallery",
  borderRadius = 1,
  borderWidth = 1,
  children,
  className,
  fullHeight,
  padding = 3,
  shadow,
  variant,
  ...props
}: Props) {
  return (
    <div
      className={styles.sharedBox({
        className,
        fullHeight,
        shadow,
        variant,
        css: {
          "--box-border-color": `$colors$${borderColor}`,
          "--box-border-width": `${borderWidth}px`,
          "--box-border-radius": `$radii$${borderRadius}`,
          "--box-wrapper-margin": `-${borderWidth - 1}px`,
          "--box-padding": `$space$${padding}`
        }
      })}
      {...props}
    >
      <div className={styles.wrapper()}>{children}</div>
    </div>
  );
}

interface Props
  extends Omit<React.HTMLProps<HTMLDivElement>, "dangerouslySetInnerHTML"> {
  borderColor?: string;
  borderRadius?: 1 | 2 | 3;
  borderWidth?: number;
  fullHeight?: boolean;
  padding?: 0 | 1 | 2 | 3 | 4;
  shadow?: boolean;
  variant?: "info" | "success" | "danger";
}
