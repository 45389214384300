import React, { MutableRefObject } from "react";
import { css } from "../../sitches.config";
import { RoundedButton } from "../RoundedButton";

export function SecondaryButton({
  className = "",
  css,
  filled = false,
  innerRef,
  /** @deprecated */
  textMode = "normal",
  type = "button",
  uppercase = textMode === "uppercase",
  ...props
}: SecondaryButton.Props) {
  return (
    <RoundedButton
      {...props}
      className={styles.button({
        filled,
        className: `secondary-button ${className}`,
        css
      })}
      elem="button"
      elemProps={{
        type
      }}
      innerRef={innerRef}
      outline
      uppercase={uppercase}
    />
  );
}

const styles = {
  button: css({
    variants: {
      filled: { true: {} }
    },
    compoundVariants: [
      {
        filled: true,
        css: {
          backgroundColor: "$white"
        }
      }
    ]
  })
};

export namespace SecondaryButton {
  export interface Props
    extends Omit<RoundedButton.Props, "elem" | "elemProps" | "innerRef"> {
    css?: { [key: string]: any };
    filled?: boolean;
    innerRef?: MutableRefObject<HTMLButtonElement | undefined>;
    /** @deprecated */
    textMode?: "normal" | "uppercase";
    type?: "button" | "submit" | "reset";
  }
}
