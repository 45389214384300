import { css } from "../../sitches.config";

export const styles = {
  peekingPopup: css({
    left: "0",
    right: "0",
    bottom: "0",
    position: "fixed",
    zIndex: "1000",
    pointerEvents: "none",

    variants: {
      isOpen: {
        true: {
          pointerEvents: "all"
        }
      }
    }
  }),
  overlay: css({
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    opacity: "0",
    position: "fixed",
    transition: "opacity 400ms",
    backgroundColor: "rgba(40, 40, 60, 0.5)",

    variants: {
      isOpen: {
        true: {
          opacity: "1"
        }
      }
    }
  }),
  container: css({
    transform: "translateY(100%)",
    position: "relative",
    transition: "transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1)",

    variants: {
      isOpen: {
        true: {
          transform: "translateY(0)"
        }
      }
    }
  }),
  wrapper: css({
    width: "100%",
    height: "100%",
    paddingTop: "24px",
    position: "relative",
    backgroundColor: "#ffffff",
    borderRadius: "20px 20px 0 0"
  }),
  child: css({
    padding: "0 24px 105px 24px",
    maxHeight: "80vh"
  }),
  controls: css({
    width: "100%",
    bottom: "0",
    display: "flex",
    padding: "$20",
    position: "absolute",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderTop: "1px solid rgba(40, 40, 60, 0.1)",
    background: "inherit",
    variants: {
      variant: {
        clear: {
          fontSize: "$14",
          fontWeight: "500",
          color: "#c4c4c4",
          textTransform: "uppercase",
          fontFamily: "$foundersGrotesk",
          width: "auto"
        }
      }
    }
  }),

  apply: css({
    width: "120px"
  })
};
