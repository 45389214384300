import { pipe, values, flatten, map, ifElse, is, identity } from "ramda";

function NestedObjects__Values(objects) {
  return pipe(
    values,
    flatten,
    map(ifElse(is(Object), NestedObjects__Values, identity)),
    flatten
  )(objects);
}

export default NestedObjects__Values;
