import { css } from "../../sitches.config";

export const styles = {
  popupWrapper: css({
    width: "345px",
    // padding: "10px 0",
    animation: "popout 250ms, fadeIn 250ms",
    borderRadius: "10px",
    "@sm": {
      width: "525px"
      // padding: "10px"
    },
    "@md": {
      width: "705px"
      // padding: "10px"
    }
  })
};
