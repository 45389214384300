import React from "react";
import { CategoryName__Title } from "@obby/lib";
import styles from "./category-sticker.module.less";

const CategorySticker = ({
  category,
  height,
  includeLegend,
  color,
  className
}) => (
  <div className={`${styles["category-sticker"]} ${className || ""}`}>
    <img
      style={{ height: `${height}px` }}
      alt={`${category} classes in London`}
      src={`/stickers/categories/${category}.svg`}
    />
    {includeLegend && (
      <h3
        className={`${styles["category-sticker__title"]} ${
          styles[`category-sticker__title--${color || "camelot"}`]
        }`}
      >
        {CategoryName__Title(category)}
      </h3>
    )}
  </div>
);

export default CategorySticker;
