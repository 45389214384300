import { css } from "../../sitches.config";

export const styles = {
  classListBanner: css({
    overflow: "hidden",

    variants: {
      roundedCorners: {
        true: {
          borderRadius: "$2"
        }
      }
    }
  }),

  container: css({
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    "@md": {
      maxHeight: "300px",
      flexDirection: "row"
    },

    variants: {
      top: {
        true: {
          flexDirection: "column-reverse",

          "@md": {
            flexDirection: "row"
          }
        }
      }
    }
  }),

  description: css({
    flex: "1",
    padding: "25px 20px",

    "@md": {
      padding: "40px 30px"
    }
  }),

  image: css({
    display: "block",
    flex: "0 0 219px",

    "@sm": {
      flexBasis: "376px"
    },
    "@md": {
      flexBasis: "317px"
    }
  })
};
