import React from "react";

export const WellBeingSticker = {
  viewBox: "0 0 284 360",
  body() {
    return (
      <>
        <path
          className="background"
          d="M284 179C284 74 235 0 142 0 48 0 0 74 0 179c0 107 48 181 142 181 93 0 142-74 142-181z"
          fill="#000000"
        />
        <g>
          <path
            className="foreground"
            fill="#FFFFFF"
            style={{ opacity: 0.5 }}
            d="M139 203l4 1-1 14h-4l1-15zm11 1l1 15h4l-1-15h-4zm-28-2l-3 3-1 9 6 1 1-13h-3zm40 2h-4l1 15 23-3v-13l-20 1zm-27-1l-7-1v14l6 1 1-14zm11 15l1 1-1-15v14z"
          />
          <path
            className="foreground"
            fill="#FFFFFF"
            d="M224 170c0-1-8-26-28-40-2-6-6-13-11-18l-8-6h4c8 0 18-4 22-9 3-5 3-10-1-15-3-3-6-4-9-4-4-1-8-2-13-1l2-1c6-4 8-11 6-17-4-11-16-20-26-20-4 1-8 3-9 7 0-4-3-7-4-8-6-4-16-3-23 2-5 4-6 10-3 16 2 4 6 6 10 7l-16 5c-8 3-9 8-8 11 1 6 8 12 15 16-8-4-19-4-28 0-9 3-14 10-16 16-1 9 5 18 11 22l12 5c-3 3-5 7-6 11-11 9-15 21-11 31 2 4 5 8 9 11v2c1 6 4 11 9 13 2 2 4 2 7 2l4-1-7 49v2l2 1h14l1 16c-1 3-3 5-5 6l-14 4c-4 1-7 5-7 10h-1l-1 2v5l1 2h84l2-2v-5l-2-2h-4v-37h10v-1l-3-55 10-2c6-2 14-6 18-11 7-1 12-6 12-12l-1-7zm-60-98c3 3 7 5 11 5-4 1-9 3-11 7v-2c1-3 2-7-2-11l2 1zm-1 14l-1 3c0 4 0 7 2 10-5-2-9-3-14-2 5-3 10-6 13-11zm-37 52c6-3 10-7 12-14 1-3 2-6 1-10a52 52 0 0 0 18 25c-10-2-21-2-31-1zm17 66l-1 35h-6l3-36 4 1zm7 0h4l3 35h-5l-2-35zm1-153h1c-1 3 1 6 2 8l6 9-4-2c-3-2-7-3-13-3h-3c5-2 9-6 11-12zm-14 48h7c-5 3-6 8-6 12-2-6-6-11-11-15l-2-1c5 3 9 4 12 4zm-31 40l9 1 2-1-16 7 5-7zm5 65l-5-1c-4-2-6-5-7-11-1-9-4-11-3-13l1-1 3 3 3 5 6-10c0-2 1-2 2-2v3l-1 7 5-9 1-2h1v4l-3 10 5-9 2-1 1 3-3 10 3-5 2-2v4c0 3-2 7-4 11s-6 6-9 6zm12 51h-11l7-50 3-3h3l-2 53zm-21 39c0-3 2-6 5-6l3-1v8h-8v-1zm29 7h-31v-3h31v3zm2-6h-8v-1l-3-11c3-1 5-4 6-8l-1-17h13v27c-4 1-7 5-7 9v1zm29-37h12v38l-15-1v-1l-2-11c3-1 5-4 5-8v-17zm-3 17c0 2-2 5-5 6l-11 3v-26h15c1 7 2 13 1 17zm-14 12v8h-9v-1c0-3 3-6 6-6l3-1zm35 11v3h-46v-3h46zm-54-43l2-53 7 1-2 37v2h11l1-1 1-37 3 37 1 1h10v-2l-2-36h4l20-1 2 52h-58zm58-109c7-1 10-5 11-8l1-3c10 7 16 17 20 24l-3-4c-2-2-3-4-6-4l-4 2v1l-19-8zm38 30c-1 6-5 9-11 9h-1l-12-2-3-3 2-1h1l6 1-11-4-2-2 2-1h1l9 1-10-3c-2 0-2-1-2-2l2-1 11 1-7-3c-2 0-3-1-3-2l2-1h1l12 1-2-5c-2-2-1-4 0-4 2 0 3 3 9 9 5 4 6 8 6 12z"
          />
        </g>
      </>
    );
  }
};
