import React, { useContext } from "react";

import { styles } from "./SwitchInputOption.styles";
import { SwitchInputContext } from "./SwitchInputContext";

export function SwitchInputOption({
  children,
  value
}: React.PropsWithChildren<Props>) {
  const { currentValue, onOptionClick, color, size } = useContext(
    SwitchInputContext
  );
  return (
    <div
      className={styles.switchInputOption({
        selected: value !== undefined && value === currentValue,
        color,
        size
      })}
      onClick={() => onOptionClick(value)}
    >
      <div className={styles.label()}>{children}</div>
    </div>
  );
}

interface Props {
  value: any;
}
