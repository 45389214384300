import { css, withBem } from "../../sitches.config";

export const styles = withBem("highlight-price", {
  root: css({
    display: "inline-block",
    fontFamily: "$foundersGroteskXCondensed",
    textTransform: "uppercase",
    color: "$hummingBird",
    backgroundColor: "$ebonyClay",
    margin: "-6px 3px -6px 0",
    transform: "rotate(1.7deg)",
    transformOrigin: "left",
    lineHeight: "1",
    variants: {
      size: {
        small: {
          fontSize: "$18",
          padding: "1px 4px"
        },
        medium: {
          fontSize: "$20",
          padding: "2px 5px"
        },
        large: {
          fontSize: "$22",
          padding: "3px 6px"
        }
      }
    }
  })
});
