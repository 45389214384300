import { css } from "../../sitches.config";

export const styles = {
  stories: css({
    minHeight: "250px",

    div: {
      "div:nth-child(3)": {
        div: {
          zIndex: "0 !important",
          position: "relative"
        }
      }
    }
  })
};
