import React from "react";
import { identity } from "ramda";

import Icons from "./../Icons";
import Icon from "./../Icon";
import Image from "../Image";
import Link from "../Link";
import FavoriteButton from "../FavouriteButton";
import {
  CurrencyISO__CurrencySymbol,
  Discount__DiscountedPrice,
  Number__Currency
} from "@obby/lib";

import HighlightPrice from "../HighlightPrice";

import styles from "./mobile-class-card.module.less";
export default function MobileClassCard({
  thumbnail,
  url,
  label,
  title,
  price,
  currency,
  samePriceAlways,
  duration,
  rating,
  numberOfReviews,
  location,
  onClick = identity,
  isOnline = false,
  requiresSendingPhysicalMaterial,
  addToWishList = true,
  isFavourite,
  discount,
  onAddToWishList = () => {},
  onRemoveFromWishList = () => {}
}) {
  const onFavouriteToggle = e => {
    e.stopPropagation();
    if (isFavourite) onRemoveFromWishList();
    else onAddToWishList();
  };

  return (
    <div className={styles["mobile-class-card"]} onClick={onClick}>
      {addToWishList && (
        <FavoriteButton
          className={styles["mobile-class-card__favourite-button"]}
          checked={isFavourite}
          onToggle={onFavouriteToggle}
        />
      )}
      <Link
        className="mobile-class-card__image-wrapper mobile-class-card__link"
        href={url}
      >
        <Image
          className={styles["mobile-class-card__image"]}
          src={thumbnail}
          alt={label ? `${label}'s class image` : ""}
          isTransparent
          preserve="height"
        />
      </Link>
      <div className={styles["mobile-class-card__content"]}>
        <Link
          className={`${styles["mobile-class-card__title"]} ${styles["mobile-class-card__link"]}`}
          href={url}
        >
          {title}
        </Link>
        <div className={styles["mobile-class-card__detail"]}>
          {numberOfReviews > 0 && (
            <>
              <Icon
                className={styles["mobile-class-card__star"]}
                icon={Icons.Star}
                height={16}
              />{" "}
              {rating}{" "}
              <span className={styles["mobile-class-card__opaque"]}>
                ({numberOfReviews})
              </span>
              <span> - </span>
            </>
          )}

          <span className={styles["mobile-class-card__opaque"]}>
            {!isOnline
              ? location
              : `Live Online${
                  requiresSendingPhysicalMaterial ? " + Kit Included" : ""
                }`}
          </span>
        </div>
        <div className={styles["mobile-class-card__detail"]}>
          {discount && (
            <HighlightPrice
              price={Discount__DiscountedPrice(discount, price)}
              currency={currency}
            />
          )}
          <span className={styles["mobile-class-card__opaque"]}>
            <span
              className={
                discount
                  ? `${styles["class-card__price"]} ${styles["class-card__price--with-discount"]}`
                  : ""
              }
            >
              {!samePriceAlways && "from "}
              {Number__Currency(price, CurrencyISO__CurrencySymbol(currency))}
              pp
            </span>{" "}
            <span className={styles["mobile-class-card__pipe"]}>·</span>{" "}
            {duration}
          </span>
        </div>
        <Link
          className={`${styles["mobile-class-card__title"]} ${styles["mobile-class-card__link"]} ${styles["mobile-class-card__view-link"]}`}
          href={url}
        >
          {"VIEW"}
        </Link>
      </div>
    </div>
  );
}
