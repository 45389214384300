import React from "react";

import * as Icons from "../Icons";
import SocialSignInButton from "../SocialSignInButton/SocialSignInButton";

import styles from "./facebook-sign-in-button.module.less";

export default function FacebookSignInButton(props) {
  const {
    appId,
    text = "Login with Facebook",
    onClick = () => {},
    onLoginSuccess = () => {},
    onLoginFailure = () => {},
    width = "max",
    size = "medium",
    disabled = false
  } = props;
  return (
    <SocialSignInButton
      className={styles["facebook-sign-in-button"]}
      disabled={disabled}
      provider={"facebook"}
      icon={Icons.FacebookF}
      appId={appId}
      text={text}
      onClick={onClick}
      onLoginSuccess={onLoginSuccess}
      onLoginFailure={onLoginFailure}
      width={width}
      size={size}
    />
  );
}
