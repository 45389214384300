import React, { useEffect, useRef, useState, useMemo } from "react";
import _ from "lodash";
import { AppointmentScheduleCheckoutView } from "./AppointmentScheduleCheckout.view";
import {
  AppointmentScheduleCheckoutSchema,
  Fields
} from "./AppointmentScheduleCheckout.schema";
import { StripePaymentProvider } from "../StripePayment";
// @ts-ignore
import { DiscountNatures, Timezones } from "@obby/constants";
import {
  asc,
  UTCDate__Add,
  UTCDate__Get,
  UTCDate__IsSame,
  UTCDate__Set,
  UTCDate__StartOf,
  UTCDate__DiffOf
  // @ts-ignore
} from "@obby/lib";
import { useFormState } from "../../hooks/useFormState";
import { useDiscount } from "../../hooks/useDiscount";

import { PricingOption } from "./PricingOption";

export function AppointmentScheduleCheckout({
  cancellationPolicyUrl = "/terms",
  course,
  discount,
  location,
  isNewsletterSignupEnabled = true,
  onConfirmFreeBooking,
  onGetPaymentIntent,
  onLogin,
  onStepChange,
  onValidateDiscount,
  stripePublicKey,
  ticketTypes,
  unavailableTimeBlocks = [],
  mandatoryTickBox = false,
  ...props
}: Props) {
  const timezone = getTimezone();

  const slots = useMemo<any[]>(
    () =>
      getSlots(
        course.appointmentScheduleSlots as any[],
        course.timezone,
        timezone
      ),
    []
  );
  const [isUserFormValid, setIsUserFormValid] = useState(false);
  const [state, setState] = useState(() => {
    return {
      isLastStep: false,
      isDiscountEnabled: false,
      isPersonalDetailsVisited: false,
      user: props.user
    };
  });
  const {
    isDiscountEnabled,
    isLastStep,
    isPersonalDetailsVisited,
    user
  } = state;

  const pricingOptions = getPricingOptions(slots, ticketTypes);

  let sortedQuestions = _.sortBy(course.userQuestionForm.questions, ["order"]);
  const {
    values,
    errors,
    touched,
    addArrayElement,
    removeArrayElement,
    isValid,
    setFieldValue,
    setFieldTouched,
    submit
  } = useFormState({
    schema: AppointmentScheduleCheckoutSchema(),
    values: {
      pricingOptions: {
        // if there is only one pricing option, make it selected by default
        option: pricingOptions.length === 1 ? 0 : null
        // option: 0,
        // date: "2023-03-13T00:00:00.000Z",
        // time: 570
      },
      userInputsValues: sortedQuestions.map(() => ""),
      personalDetails: {
        guests: _.range(
          0,
          course.appointmentScheduleBriefing.minNumberOfGuests
        ).map(index => ({ guest: index > 0 }))
      }
    }
  });

  const {
    discountCode,
    applyCode,
    getDiscount,
    getDiscountPercentage,
    isDiscountInvalid
  } = useDiscount(() => getTotalGross(), onValidateDiscount, discount);

  const credit = getCredit();

  const selectedOption = pricingOptions[values.pricingOptions.option];
  const startDateTime = getStartDateTime(
    values.pricingOptions.date,
    values.pricingOptions.time
  );

  const selectedTicketType = getSelectedTicketType(
    slots,
    ticketTypes,
    selectedOption,
    startDateTime,
    timezone
  );

  function getBookingData() {
    let { personalDetails } = values;

    const { guests, inputs } = personalDetails;
    const [guest, ...additionalGuests] = guests;
    let userInputsToSend = [];
    if (course.userQuestionForm.isEnabled) {
      //@ts-ignore
      userInputsToSend = sortedQuestions
        .map((question, index): any => ({
          _id: question._id,
          label: question.title,
          description: question.title,
          value: values.userInputsValues[index]
        }))
        .filter((question, index): any => {
          return (
            values.userInputsValues[index] &&
            values.userInputsValues[index] != ""
          );
        });
    }

    return {
      bookingType: "anytime",
      scheduleType: "appointment",
      tickets: [
        {
          ...selectedTicketType,
          count: guests.length
        }
      ],
      user: {
        _id: user?._id,
        profile: {
          firstName: guest.firstName,
          lastName: guest.lastName,
          phone: guest.phoneNumber
        },
        renderEmail: guest.email
      },
      marketingOptIn: guest.marketingOptIn,
      courseId: course._id,
      startDateTime,
      additionalGuests,
      discountCode: credit ? "" : discountCode,
      credit,
      utm: "", //@TODO
      isRegular: false,
      sessionid: "",
      timezone,
      userInputs: userInputsToSend
    };
  }

  function getCredit() {
    const totalGross = getTotalGross();

    if (
      totalGross < 3000 ||
      !user?.profile?.credit ||
      discount?.nature === DiscountNatures.SALES
    )
      return 0;

    return Math.min(totalGross, user.profile.credit);
  }

  function getTimezone() {
    // return "Pacific/Honolulu";
    return course.isOnline
      ? Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? course.timezone
      : course.timezone;
  }

  function getTotalGross() {
    const numberOfGuests = values.personalDetails.guests.length;
    return values.pricingOptions.option !== null
      ? pricingOptions[values.pricingOptions.option].price * numberOfGuests
      : 0;
  }

  function getTotal() {
    return getTotalGross() - (getCredit() || getDiscount());
  }

  async function onAddGuest() {
    addArrayElement("personalDetails.guests", { guest: true });
  }

  async function onApplyDiscount(code: string) {
    const isValid = await submit("discountCode");
    if (isValid) await applyCode(code, values.personalDetails.guests[0].email);
  }

  function onBlur(name: string) {
    setFieldTouched(name);
  }

  function onChange(value: any, name: string) {
    setFieldValue(name, value);
  }

  function onCheckoutStepChange(index: number) {
    let userDetailsIndex = 2;
    let lastStepIndex = 4;
    setState(state => ({
      ...state,
      isDiscountEnabled: !discount && index === userDetailsIndex,
      isLastStep: index === lastStepIndex
    }));
    onStepChange && onStepChange();
  }

  function onConfirmFree() {
    onConfirmFreeBooking(getBookingData());
  }

  async function onPersonalDetailsEnter() {
    if (isPersonalDetailsVisited) return;

    let user = state.user;
    if (!user && onLogin)
      try {
        user = await onLogin();
        setState(state => ({
          ...state,
          user
        }));
      } catch (e) {}
    if (user) {
      const { firstName = "", lastName = "", phone = "" } = user.profile;
      const [{ address = "" } = {}] = user.emails || [];
      setFieldValue("personalDetails.guests.0", {
        ...values.personalDetails[0],
        firstName,
        lastName,
        phoneNumber: phone,
        email: address
      });
    }
    setState(state => ({
      ...state,
      isPersonalDetailsVisited: true
    }));
  }

  function onRemoveGuest(index: number) {
    removeArrayElement("personalDetails.guests", index);
  }

  return (
    <StripePaymentProvider
      publicKey={stripePublicKey}
      onGetPaymentIntent={onGetPaymentIntent}
    >
      <AppointmentScheduleCheckoutView
        isUserFormValid={isUserFormValid}
        setIsUserFormValid={setIsUserFormValid}
        mandatoryTickBox={mandatoryTickBox}
        errors={errors}
        cancellationPolicyUrl={cancellationPolicyUrl}
        course={course}
        credit={getCredit()}
        discount={discount}
        getBookingData={getBookingData}
        isDiscountEnabled={isDiscountEnabled}
        isLastStep={isLastStep}
        isNewsletterSignupEnabled={isNewsletterSignupEnabled}
        isUserDiscountInvalid={isDiscountInvalid()}
        isValid={isValid}
        location={location}
        onAddGuest={onAddGuest}
        onApplyDiscount={onApplyDiscount}
        onBlur={onBlur}
        onChange={onChange}
        onCheckoutStepChange={onCheckoutStepChange}
        onConfirmFree={onConfirmFree}
        onPersonalDetailsEnter={onPersonalDetailsEnter}
        onRemoveGuest={onRemoveGuest}
        pricingOptions={pricingOptions}
        startDateTime={startDateTime}
        timezone={timezone}
        total={getTotal()}
        touched={touched}
        unavailableTimeBlocks={unavailableTimeBlocks}
        userDiscountValue={getDiscount()}
        userDiscountPercentage={getDiscountPercentage()}
        values={values}
      />
    </StripePaymentProvider>
  );
}

interface Props {
  cancellationPolicyUrl?: string;
  course: any;
  discount?: any;
  location?: any;
  isNewsletterSignupEnabled?: boolean;
  onConfirmFreeBooking: (data: any) => void;
  onGetPaymentIntent: (
    data: any
  ) => Promise<{ clientSecret: string; successUrl: string }>;
  onLogin?: () => Promise<any>;
  onStepChange: () => void;
  onValidateDiscount: (code: string, email: string) => Promise<any>;
  stripePublicKey: string;
  ticketTypes: any;
  unavailableTimeBlocks?: any[];
  user?: any;
  mandatoryTickBox?: boolean;
}

/**
 * Group slots by pricing options. Every slot with the same name, description, price and duration will be grouped into
 * the same pricing option. Each pricing option will also have a two-dimensional array of time intervals indexed by the
 * isoWeekDay which corresponds to the slots that were grouped into that pricing option
 * (e.g. pricingOption[0].timeIntervals[2][0] points to the time interval of the first slot of tuesday for the first pricing option).
 */
export function getPricingOptions(slots: any[], ticketTypes: any[]) {
  if (!ticketTypes) return [];
  return slots
    .reduce<PricingOption[]>((pricingOptions, slot) => {
      slot.tickets.forEach((ticket: any) => {
        const [ticketTypeId] = ticket.ticketTypes;
        const ticketType = ticketTypes.find(
          (ticketType: any) => ticketType._id === ticketTypeId
        );

        let pricingOption = pricingOptions.find(
          option =>
            option.name === ticketType.name &&
            option.description === ticketType.description &&
            option.price === ticketType.price &&
            option.duration === ticket.duration
        );
        if (!pricingOption) {
          pricingOption = {
            name: ticketType.name,
            description: ticketType.description,
            isoWeekdays: [],
            price: ticketType.price,
            duration: ticket.duration,
            timeIntervals: [],
            ticketsIds: []
          };
          pricingOptions.push(pricingOption);
        }
        pricingOption.ticketsIds.push(ticket._id);
        if (!pricingOption.isoWeekdays.includes(slot.isoWeekday))
          pricingOption.isoWeekdays.push(slot.isoWeekday);
        if (!pricingOption.timeIntervals[slot.isoWeekday])
          pricingOption.timeIntervals[slot.isoWeekday] = [];
        pricingOption.timeIntervals[slot.isoWeekday].push({
          start: slot.startPeriod.hour * 60 + slot.startPeriod.minute,
          end: slot.endPeriod.hour * 60 + slot.endPeriod.minute,
          validFrom: slot.validFrom,
          hasSpecificEndDate: slot.hasSpecificEndDate,
          validUntil: slot.validUntil
        });
      });

      return pricingOptions;
    }, [])
    .sort((option1, option2) =>
      asc([option1.price, option2.price], [option1.duration, option2.duration])
    );
}

export function getSlots(
  slots: any[],
  timezone: string,
  localTimezone: string
) {
  // timezone = "Asia/Tokyo"; //;"Europe/Amsterdam"; //
  // localTimezone = "America/Los_Angeles"; //"Pacific/Honolulu"; //"Atlantic/Azores"; //

  const enabledSlots = slots.filter(slot => slot.status === "enabled");

  if (timezone === localTimezone) return enabledSlots;

  return enabledSlots
    .reduce<any[]>((slots, slot) => {
      const slotStartDateTime = getSlotUTCDate(
        timezone,
        slot.isoWeekday,
        slot.startPeriod
      );
      const slotEndDateTime = getSlotUTCDate(
        timezone,
        slot.isoWeekday,
        slot.endPeriod
      );
      // prettier-ignore
      const endsSameDay = UTCDate__IsSame(slotStartDateTime, slotEndDateTime, "day", localTimezone);
      // prettier-ignore
      const localSlot = getSlotCopy(slot, slotStartDateTime, endsSameDay ? slotEndDateTime : false, localTimezone);
      // end-prettier-ignore
      slots.push(localSlot);
      if (!endsSameDay) {
        // prettier-ignore
        const nextDaySlot = getSlotCopy(slot, false, slotEndDateTime, localTimezone);
        slots.push(nextDaySlot);
      }

      return slots;
    }, [])
    .sort((slot1, slot2) =>
      asc(
        // sort by week day
        [slot1.isoWeekday, slot2.isoWeekday],
        // sort by start period
        [slot1.startPeriod.hour, slot2.startPeriod.hour],
        [slot1.startPeriod.minute, slot2.startPeriod.minute],
        // sort by end period
        [slot1.endPeriod.hour, slot2.endPeriod.hour],
        [slot1.endPeriod.minute, slot2.endPeriod.minute]
      )
    );
}

function getSelectedTicketType(
  slots: any[],
  ticketTypes: any[],
  option: PricingOption | undefined,
  startDateTime: string | undefined,
  timezone: string
) {
  if (option === undefined || startDateTime === undefined) return undefined;

  const isoWeekday = UTCDate__Get(startDateTime, timezone, "isoWeekday");
  const minutes = UTCDate__DiffOf(startDateTime, timezone, "minutes", "day");

  let ticketType;
  for (const slot of slots) {
    if (slot.isoWeekday !== isoWeekday) continue;

    if (
      minutes < slot.startPeriod.hour * 60 + slot.startPeriod.minute ||
      minutes > slot.endPeriod.hour * 60 + slot.endPeriod.minute
    )
      continue;

    const ticket = slot.tickets.find((ticket: any) =>
      option.ticketsIds.includes(ticket._id)
    );
    if (!ticket) continue;

    ticketType = ticketTypes.find(
      ticketType => ticketType._id === ticket.ticketTypes[0]
    );
    break;
  }

  return ticketType;
}

function getSlotCopy(
  slot: any,
  startDateTime: string | false,
  endDateTime: string | false,
  timezone: string
) {
  const slotCopy = _.cloneDeep(slot);
  slotCopy.isoWeekday = UTCDate__Get(
    startDateTime || endDateTime,
    timezone,
    "isoWeekday"
  );

  slotCopy.startPeriod = startDateTime
    ? getSlotLocalPeriod(startDateTime, timezone)
    : { hour: 0, minute: 0 };
  slotCopy.endPeriod = endDateTime
    ? getSlotLocalPeriod(endDateTime, timezone)
    : { hour: 23, minute: 55 };
  return slotCopy;
}

function getSlotLocalPeriod(dateTime: string, timezone: string) {
  return {
    hour: UTCDate__Get(dateTime, timezone, "hours"),
    minute: UTCDate__Get(dateTime, timezone, "minutes")
  };
}

function getSlotUTCDate(
  timezone: string,
  isoWeekday: string,
  period: { hour: number; minute: number }
) {
  const startOfWeek = UTCDate__StartOf(undefined, timezone, "isoWeek");

  return UTCDate__Set(
    startOfWeek,
    timezone,
    "isoWeekday",
    isoWeekday,
    ["hours", period.hour],
    ["minutes", period.minute]
  );
}

function getStartDateTime(date?: Date, time?: number) {
  return date && time
    ? UTCDate__Add(date.toISOString(), time, "minutes")
    : undefined;
}
