import React, { useState } from "react";
import { Label } from "../Label";
import { PrimaryButton } from "../PrimaryButton";
import { TextInput } from "../TextInput";
import Icon from "../Icon";
import { Cross } from "../Icons";
import { WaitingListPopupStyles as styles } from "./WaitingListPopup.styles";
// @ts-ignore
import { Session__DisplayDateTimeOffset } from "@obby/lib";

// Hold on to submitted parameters if the user wants to
// sign up to more than one date in the same session.
// Shouldn't be stored in LocalStorage due to private nature.
let paramCache: WaitingListPopup.SubmitParams = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  numberOfGuests: 1
};

export namespace WaitingListPopup {
  export interface SubmitParams {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    numberOfGuests: number;
  }

  export interface Props {
    session: {
      _id: string;
      location?: string;
      duration: number;
      date: string;
    } | null;
    course: any;
    location: any;
    onSubmit: (params: SubmitParams) => Promise<any>;
    onClose: () => void;
  }
}

export function WaitingListPopup({
  session,
  course,
  location,
  onSubmit,
  onClose
}: WaitingListPopup.Props) {
  const [firstName, setFirstName] = useState(paramCache.firstName);
  const [lastName, setLastName] = useState(paramCache.lastName);
  const [email, setEmail] = useState(paramCache.email);
  const [phone, setPhone] = useState(paramCache.phone);
  const [numberOfGuests, setNumberOfGuests] = useState(
    paramCache.numberOfGuests.toString()
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const onSubmitAction = async (e: React.FormEvent<HTMLFormElement>) => {
    const params = {
      firstName,
      lastName,
      email,
      phone,
      numberOfGuests: parseInt(numberOfGuests)
    };

    e.preventDefault();
    setIsLoading(true);

    try {
      await onSubmit(params);
      setIsComplete(true);
      paramCache = params;
    } catch (e) {
      window.alert("Something went wrong. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  if (isComplete) {
    return (
      <div className={styles.overlayWrap()}>
        <div className={styles.overlay()} onClick={onClose} />
        <div className={styles.container()}>
          <p className={styles.completeText()}>
            You have been added to the waiting list! You'll receive an email if
            any spaces become available for this date.
          </p>
          <div className={styles.completeButton()}>
            {/* @ts-ignore */}
            <PrimaryButton
              onClick={onClose}
              color="emeraldish"
              width="auto"
              text="Done"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.overlayWrap()}>
      <div className={styles.overlay()} onClick={onClose} />
      <div className={styles.container()}>
        <button className={styles.closeButton()} onClick={onClose}>
          {/* @ts-ignore */}
          <Icon icon={Cross} width={20} height={20} />
        </button>
        <div className={styles.intro()}>
          <h3 className={styles.introTitle()}>Join Waiting List</h3>
          <p className={styles.introText()}>
            Fill out the form below to join the waiting list for this class.
            After joining, you'll be notified if any spaces become available.
          </p>
        </div>
        <form onSubmit={onSubmitAction} className={styles.form()}>
          {session && (
            <div className={styles.info()}>
              {/* @ts-ignore */}
              <div className={styles.box()}>
                <h4 className={styles.title()}>{course.title}</h4>
                {session && (
                  <div>
                    {Session__DisplayDateTimeOffset(session, course.timezone)}
                  </div>
                )}
                {location && <span>{location.area}</span>}
              </div>
            </div>
          )}

          <div className={styles.fields()}>
            <div>
              {/* @ts-ignore */}
              <Label label="First Name*" size="small">
                <TextInput
                  onChange={setFirstName}
                  value={firstName}
                  required
                  autoFocus
                />
              </Label>
            </div>
            <div>
              {/* @ts-ignore */}
              <Label label="Last Name*" size="small">
                <TextInput onChange={setLastName} value={lastName} required />
              </Label>
            </div>
            <div>
              {/* @ts-ignore */}
              <Label label="Email*" size="small">
                <TextInput
                  onChange={setEmail}
                  value={email}
                  type="email"
                  required
                />
              </Label>
            </div>
            <div>
              {/* @ts-ignore */}
              <Label label="Phone Number" size="small">
                <TextInput onChange={setPhone} value={phone} type="tel" />
              </Label>
            </div>
            <div>
              {/* @ts-ignore */}
              <Label label="Number of Guests" size="small">
                <TextInput
                  onChange={setNumberOfGuests}
                  value={numberOfGuests}
                  type="number"
                />
              </Label>
            </div>
          </div>
          <div className={styles.buttonContainer()}>
            {/* @ts-ignore */}
            <PrimaryButton
              type="submit"
              onClick={() => {}}
              color="emeraldish"
              width="auto"
              text="Join Waiting List"
            />
          </div>

          {isLoading && (
            <div className={styles.formOverlay()}>
              <div className={styles.loaderSpinner()} />
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
