import React, { useState, useMemo, useEffect } from "react";

// @ts-ignore
import { BookingReschedulePanelEntryView } from "./BookingReschedulePanelEntry.view";
import {
  getPricingOptions,
  getSlots
} from "../../containers/AppointmentScheduleCheckout/AppointmentScheduleCheckout";
import moment from "moment-timezone";

export function BookingReschedulePanelEntry({
  booking,
  cancellationPolicyUrl = "/terms",
  canCancel,
  course,
  errorCode,
  locations,
  onCancelBooking,
  onDone,
  onLogin,
  onReschedule,
  session,
  sessions,
  tutors,
  ticketTypes,
  unavailableTimeBlocks
}: Props) {
  const [selectedSession, setSelectedSession] = useState<any>();

  const [error, setError] = useState<string | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);

  const [
    rescheduleAppointmentValues,
    setRescheduleAppointmentValues
  ] = useState({ option: 0, time: null, date: null });

  useEffect(() => {
    if (booking.bookingType != "anytime") return;
    if (rescheduleAppointmentValues.time && rescheduleAppointmentValues.date) {
      let appointmentNewDate = moment(rescheduleAppointmentValues.date).add(
        rescheduleAppointmentValues.time,
        "minutes"
      );
      let innerSelectedSession = {
        startDateTime: appointmentNewDate.toISOString()
      };
      console.log("innerSelectedSession", innerSelectedSession);
      setSelectedSession(innerSelectedSession);
    }
  }, [rescheduleAppointmentValues]);
  const timezone = getTimezone();
  function getTimezone() {
    // return "Pacific/Honolulu";
    return course.isOnline
      ? Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? course.timezone
      : course.timezone;
  }
  const slots = useMemo<any[]>(
    () =>
      getSlots(
        course.appointmentScheduleSlots as any[],
        course.timezone,
        timezone
      ),
    []
  );
  const pricingOptions = getPricingOptions(slots, ticketTypes);

  async function onContinue() {
    setIsSubmitting(true);
    try {
      await onReschedule(selectedSession);
      window.scrollTo(0, 0);
      setIsSuccessfullySubmitted(true);
    } catch (error) {
      setError(error as string);
    } finally {
      setIsSubmitting(false);
    }
  }

  function onErrorPopupClose() {
    setError(undefined);
  }

  function onSessionSelect(sessionId: string) {
    setSelectedSession(sessions!.find(session => session._id === sessionId));
  }

  function onSuccessPopupClose() {
    setIsSuccessfullySubmitted(false);
    onDone();
  }

  return (
    <BookingReschedulePanelEntryView
      booking={booking}
      course={course}
      cancellationPolicyUrl={cancellationPolicyUrl}
      canCancel={canCancel}
      error={error}
      errorCode={errorCode}
      locations={locations}
      isSubmitting={isSubmitting}
      isSuccessfullySubmitted={isSuccessfullySubmitted}
      onContinue={onContinue}
      onCancelBooking={onCancelBooking}
      onErrorPopupClose={onErrorPopupClose}
      onLogin={onLogin}
      onSessionSelect={onSessionSelect}
      onSuccessPopupClose={onSuccessPopupClose}
      selectedSession={selectedSession}
      session={session}
      sessions={sessions}
      tutors={tutors}
      rescheduleAppointmentValues={rescheduleAppointmentValues}
      setRescheduleAppointmentValues={setRescheduleAppointmentValues}
      pricingOptions={pricingOptions}
      unavailableTimeBlocks={unavailableTimeBlocks}
      timezone={timezone}
    />
  );
}

interface Props {
  booking?: any;
  cancellationPolicyUrl?: string;
  course?: any;
  canCancel?: boolean;
  errorCode: string | false;
  locations?: any[];
  onReschedule: (session: any) => Promise<void>;
  onDone: () => void;
  onCancelBooking: () => void;
  onLogin?: () => void;
  session?: any;
  sessions?: any[];
  tutors?: any[];
  ticketTypes?: any;
  unavailableTimeBlocks?: any;
}
