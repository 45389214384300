import { FaIcon } from "./FaIcon";

export const EmbedSideTab: FaIcon.Icon = {
  icon: [
    300,
    300,
    [],
    "",
    ["M150 60 h130 v180 h-130 z", "M110 110 h30 v80 h-30 z"]
  ]
};
