import React from "react";

import { CourseAccordionListItem } from "./CourseAccordionListItem";
import { CourseAccordionListAdd } from "./CourseAccordionListAdd";
import { bemModule } from "../../configs/bem";

import styles from "./CourseAccordionList.module.less";

const cn = bemModule(styles, "course-accordion-list");
export function CourseAccordionList(props) {
  const { children } = props;
  return (
    <div className={cn()}>
      <div className={cn("wrapper")}>{children}</div>
    </div>
  );
}

CourseAccordionList.Item = CourseAccordionListItem;
CourseAccordionList.Add = CourseAccordionListAdd;
