import React from "react";
import SecondaryButton from "../SecondaryButton";
import LabelInput from "../LabelInput";
import { PrimaryButton } from "../PrimaryButton";
import GoogleSignInButton from "../GoogleSignInButton";
import FacebookSignInButton from "../FacebookSignInButton";
import styles from "./SignInForm.module.less";

export default class SignInForm extends React.Component {
  constructor(props) {
    super(props);
  }

  onLoginFormSubmit(e) {
    e.preventDefault();
    this.props.onSubmitSignIn();
  }

  render() {
    return (
      <div className={styles["sign-in-form"]}>
        <div className={styles["sign-in-form__body"]}>
          <div className={styles["sign-in-form__title"]}>
            Booking faster by signing up
          </div>
          <div className={styles["sign-in-form__title__note"]}>
            Please login or sign up to manage your booking.
          </div>
          <div className={styles["sign-in-form__title__note2"]}>
            {this.props.signInNote || ""}
          </div>
          {/* EMAIL SIGN IN */}
          <form
            className={styles["sign-in-form__form"]}
            onSubmit={this.onLoginFormSubmit.bind(this)}
            autoComplete="on"
          >
            <div className={styles["sign-in-form__form-input"]}>
              <LabelInput
                type="email"
                name="username"
                placeholder="Email address"
                value={this.props.email}
                error={this.props.errors.inputs.email}
                onChange={this.props.onTypeEmail}
              />
            </div>
            <div className={styles["sign-in-form__form-input"]}>
              <LabelInput
                type="password"
                name="password"
                placeholder="Password"
                value={this.props.password}
                error={this.props.errors.inputs.password}
                onChange={this.props.onTypePassword}
              />
            </div>
            <div className={styles["sign-in-form__form-button"]}>
              <PrimaryButton type="submit" text="Log in" />
            </div>
            {this.props.errors.general && (
              <div className={styles["sign-in-form__form-error"]}>
                {this.props.errors.general}
              </div>
            )}
            <div
              className={styles["sign-in-form__form-label"]}
              onClick={this.props.onBeginResetPassword}
            >
              Forgot password?
            </div>

            {/* FOOTER */}
            <div className={styles["sign-in-form__footer"]}>
              <span className={styles["sign-in-form__footer-text"]}>
                Don't have an account yet?
              </span>
              <span className={styles["sign-in-form__footer-button"]}>
                <SecondaryButton
                  text="Sign up"
                  size="small"
                  onClick={this.props.onBeginSignUp}
                />
              </span>
            </div>
            {/*</div>*/}
          </form>

          {/* SOCIAL SIGN IN */}
          {!this.props.hideSocialLinks && (
            <>
              <div className={styles["sign-in-form__divider"]}>or</div>
              <div className={styles["sign-in-form__social-buttons"]}>
                {this.props.isCheckout && (
                  <div className={styles["sign-in-form__social-button"]}>
                    <SecondaryButton
                      text="Checkout as a guest"
                      onClick={this.props.onCheckoutAsGuest}
                    />
                  </div>
                )}
                <div className={styles["sign-in-form__social-button"]}>
                  <FacebookSignInButton
                    appId={this.props.facebookAppId}
                    onLoginSuccess={this.props.onFacebookSignInSuccess}
                    onLoginFailure={this.props.onFacebookSignInFailure}
                    disabled={this.props.disabledFacebookLogin}
                  />
                </div>
                <div className={styles["sign-in-form__social-button"]}>
                  <GoogleSignInButton
                    appId={this.props.googleAppId}
                    onLoginSuccess={this.props.onGoogleSignInSuccess}
                    onLoginFailure={this.props.onGoogleSignInFailure}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
