import { css } from "../../sitches.config";

export const styles = {
  icon: css({
    fill: "currentColor",

    variants: {
      outline: {
        true: {
          fill: "transparent",
          stroke: "currentColor",
          strokeWidth: "1px"
        }
      },
      strokeOutline: {
        true: {
          fill: "transparent",
          stroke: "currentColor",
          strokeWidth: "1px"
        }
      },
      pathOutline: {
        true: {
          fill: "currentColor",
          stroke: "transparent"
        }
      },
      clickable: {
        true: {
          cursor: "pointer"
        }
      }
    }
  })
};
