import {Course__CourseUrl} from "@obby/lib";
import {Number_Currency} from "@obby/lib";

const Course__ClassListCard = course => {
  return {
    _id: course._id,
    title: course.title,
    type: course.type === "workshop" ? "class" : course.type,
    thumbnail: course.images[0].url,
    rating: course.rating,
    description: course.shortDescription,
    numberOfReviews: course.reviews.length,
    price: course.session
      ? `${Number_Currency(course.session.price, "£", 0)} pp`
      : "",
    numberOfStudents: course.session ? course.session.totalAvailability : "",
    //duration: course.duration.replace("workshop", "class"), // @TODO need to fix this
    duration: "",
    url: Course__CourseUrl(course)
  };
};

export default Course__ClassListCard;
