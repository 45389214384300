// Lib & Constants
import CDiscountTypes from "./CDiscountTypes";
import isFullArray from "./isFullArray";

export default booking => {
  if (
    isFullArray(booking.discount) &&
    booking.discount.type === CDiscountTypes.PERCENTAGE
  ) {
    return {
      discountPercent: booking.discount.value,
      discount: booking.price * (booking.discount.value / 100),
      isFromTeacher: booking.discount.isFromTeacher
    };
  }

  if (
    isFullArray(booking.discount) &&
    booking.discount.type === CDiscountTypes.PRICE &&
    booking.discount.value !== booking.usedCredit
  ) {
    return {
      discountPercent: 0,
      discount: booking.discount.value,
      isFromTeacher: booking.discount.isFromTeacher
    };
  }

  return {
    discountPercent: 0,
    discount: 0,
    isFromTeacher: false
  };
};
