import moment from "moment-timezone";
import { Timezones } from "@obby/constants";

export function UTCDate__Add(
  isoDate: string | undefined,
  amount: number,
  unitOfTime: UnitOfTime,
  // timezone is only needed if unit of time is month because the timezone may determine which month we are.
  // Since not all the months lasts the same, we need to know exactly what month we are, so we can add the right amount of days
  // to shift to the same day of the desired month.
  // E.G. for Amsterdam timezone:
  // 2022-04-30T22:00:00.000Z is 1st of may and if we add 1 month we should expect to get 1st of june which is 2022-05-31T22:00:00.000Z.
  // if we don't specify the timezone (and considering the default timezone is Europe/London) 2022-04-30T22:00:00.000Z is April 30 11pm, so
  // adding 1 month we will get May 30 11pm which is 2022-05-30T22:00:00.000Z
  // UPDATE: it is also needed if the unit of time is week
  timezone: string = Timezones.EUROPE_LONDON
) {
  const date = moment(isoDate)
    .tz(timezone)
    .add(amount, unitOfTime);

  return date.toISOString();
}

type UnitOfTime =
  | "year"
  | "years"
  | "y"
  | "month"
  | "months"
  | "M"
  | "week"
  | "weeks"
  | "w"
  | "day"
  | "days"
  | "d"
  | "hour"
  | "hours"
  | "h"
  | "minute"
  | "minutes"
  | "m"
  | "second"
  | "seconds"
  | "s"
  | "millisecond"
  | "milliseconds"
  | "ms";
