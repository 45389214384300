import React from "react";
import PropTypes from "prop-types";

import styles from "./menu-nav-user-links.module.less";

export function MenuNavUserLinks(props) {
  const { onSignOut } = props;
  return (
    <ul className={styles["menu-nav-user-links"]}>
      <li>
        <a className={styles["menu-nav-user-links__link"]} href="/wishlist">
          Wishlist
        </a>
      </li>
      <li>
        <a className={styles["menu-nav-user-links__link"]} href="/bookings">
          Bookings
        </a>
      </li>
      <li>
        <a className={styles["menu-nav-user-links__link"]} href="/profile">
          Settings
        </a>
      </li>
      <li>
        <a
          className={styles["menu-nav-user-links__link"]}
          href="https://obby.helpscoutdocs.com/collection/31-booking-a-class-with-obby "
          target="_blank"
        >
          Help
        </a>
      </li>
      <li>
        <a onClick={onSignOut} className={styles["menu-nav-user-links__link"]}>
          Logout
        </a>
      </li>
    </ul>
  );
}

MenuNavUserLinks.propTypes = {};
