import { css } from "../../sitches.config";

export const styles = {
  faqWrapper: css({
    borderBottom: "1px solid $iron"
  }),

  faq: css({
    borderTop: "1px solid $iron",

    variants: {
      isOpen: {
        true: {
          ".product-faq-section__answer": {
            display: "block"
          },
          ".product-faq-section__question-tick": {
            transform: "rotate(90deg)"
          }
        }
      }
    }
  }),

  question: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "$foundersGrotesk",
    fontWeight: "500",
    fontSize: "$18",
    letterSpacing: "0.04em",
    textTransform: "uppercase",
    minHeight: "90px",
    cursor: "pointer",
    paddingBlock: "$18"
  }),

  questionTick: css({
    transform: "rotate(-90deg)",
    minWidth: "28px"
  }),

  answer: css({
    display: "none",
    fontFamily: "$foundersGrotesk",
    fontSize: "$18",
    letterSpacing: "0.03em",
    lineHeight: "1.4",
    paddingBottom: "30px"
  })
};
