import { css } from "../../sitches.config";

export const styles = {
  classGiftCard: css({
    width: "100%",
    position: "relative",
    overflow: "hidden",
    borderRadius: "5px"
  }),
  text: css({
    position: "absolute",
    padding: "15px 20px",
    maxWidth: "180px",

    "@sm": {
      padding: "15px 30px",
      maxWidth: "300px"
    }
  }),
  price: css({
    width: "100%",
    fontFamily: "$foundersGroteskCondensed",
    fontSize: "34px",
    lineHeight: "1",
    fontWeight: "600",
    color: "white",
    textAlign: "left",
    marginBottom: "10px",
    "@sm": {
      fontSize: "60px",
      marginBottom: "20px"
    }
  }),
  title: css({
    width: "100%",
    fontFamily: "$foundersGroteskCondensed",
    fontSize: "16px",
    lineHeight: "1",
    fontWeight: "600",
    color: "white",
    textTransform: "uppercase",
    textAlign: "left",
    marginBottom: "5px",
    "@sm": {
      fontSize: "$mParagraph",
      marginBottom: "15px"
    }
  }),
  description: css({
    width: "100%",
    fontFamily: "$foundersGrotesk",
    fontSize: "16px",
    lineHeight: "15px",
    fontWeight: "400",
    color: "white",
    textAlign: "left",
    marginBottom: "10px"
  }),
  logo: css({
    height: "70px",
    width: "70px"
  }),
  image: css({
    display: "block",
    width: "100%"
  })
};
