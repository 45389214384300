import {
  tail,
  pipe,
  cond,
  contains,
  concat,
  toPairs,
  map,
  join,
  append,
  curry,
  evolve
} from "ramda";

const ResizeOptionTuple__UrlOption = cond([
  [contains("width"), pipe(tail, concat(["w_"]))],
  [contains("height"), pipe(tail, concat(["h_"]))],
  [contains("crop"), pipe(tail, concat(["c_"]))],
  [contains("quality"), pipe(tail, concat(["q_"]))],
  [contains("radius"), pipe(tail, concat(["r_"]))]
]);

const ResizeOptions__UrlOptions = pipe(
  toPairs,
  map(ResizeOptionTuple__UrlOption),
  map(join("")),
  append("fl_lossy"),
  append("q_auto"),
  append("f_auto"),
  join(",")
);

const ImageUrl__ImageResizedUrl = curry((options, url) => {
  const resizeUrlPart = ResizeOptions__UrlOptions(options);

  return url.indexOf("image/upload") !== -1
    ? url.replace(
        /(image\/upload\/){1}((w_([0-9])+),(h_([0-9])+){1}([a-z0-9,_])*\/)?/,
        "image/upload/" + resizeUrlPart + "/"
      )
    : url.replace(
        /(images\/){1}((w_([0-9])+),(h_([0-9])+){1}([a-z0-9,_])*\/)?/,
        "images/" + resizeUrlPart + "/"
      );
});

export default ImageUrl__ImageResizedUrl;
