import React, { useContext } from "react";
import { TabsNavContext } from "./TabsNavContext";
import { styles } from "./TabsNavTab.styles";

export function TabsNavTab({
  onClick = () => {},
  children
}: React.PropsWithChildren<Props>) {
  const { selected, id, onSelectTab } = useContext(TabsNavContext);

  function onTabClick(e: React.MouseEvent) {
    onSelectTab(id);
    onClick(e);
  }

  return (
    <div
      className={styles.tabsNavTab({
        selected
      })}
    >
      <div className={styles.wrapper({ selected })} onClick={onTabClick}>
        {children}
      </div>
    </div>
  );
}

interface Props {
  id?: string | number;
  onClick?: (e: React.MouseEvent) => void;
}
