import React from "react";
import PropTypes from "prop-types";
import Icons from "./../Icons";
import { Number__Currency, CurrencyISO__CurrencySymbol } from "@obby/lib";
import { IconLabel } from "../IconLabel";
import { styles } from "./BookingDetailCard.styles";

export default function BookingDetailCard({
  title,
  bookingId,
  borderTopColor = "royalBlue",
  details = {
    totalTickets: "",
    tickets: {
      name: ""
    },
    discountValue: "",
    total: "",
    currency: "GBP"
  }
}) {
  return (
    <div
      className={styles.BookingDetailCard({
        css: {
          borderColor: `$${borderTopColor}`
        }
      })}
    >
      <div className={styles.header()}>
        <span className={styles.title()}>{title}</span>
      </div>
      <div className={styles.details()}>
        <div className={styles.iconLabel()}>
          <IconLabel label={`Booking no: ${bookingId}`} icon={Icons.CheckTag} />
          <IconLabel
            label={`${details.totalTickets} x ${details.tickets.name}`}
            icon={Icons.ShoppingTag}
          />
          {details.discountValue > 0 && (
            <IconLabel
              label={`Discount: ${Number__Currency(
                details.discountValue,
                CurrencyISO__CurrencySymbol(details.currency)
              )}`}
              icon={Icons.DiscountTag}
            />
          )}
          <IconLabel
            label={`Total: ${Number__Currency(
              details.total,
              CurrencyISO__CurrencySymbol(details.currency)
            )}`}
            icon={Icons.PriceTag}
          />
        </div>
      </div>
    </div>
  );
}
