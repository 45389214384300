import React from "react";
import CardBackground from "../../components/CardBackground";
import Icon from "../../components/Icon";
import * as Icons from "../../components/Icons";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import { Number__Currency } from "@obby/lib";

import { styles } from "./PlanCard.styles";

export function PlanCard({
  name,
  highlighted = false,
  features = [],
  onSelect,
  buttonText,
  price,
  priceType = "monthly",
  priceLabel,
  periodicity
}) {
  function getPriceLabel() {
    switch (true) {
      case priceLabel !== undefined:
        return priceLabel;
      case price === undefined:
        return "POA";
      case price === 0:
        return "Free";
      default:
        return Number__Currency(price);
    }
  }

  return (
    <div className="plan-card">
      <div className={`${styles.cardWrapper({ highlighted })}`}>
        <CardBackground shadow={highlighted} border>
          <div className={`${styles.wrapper}`}>
            <div className={`${styles.info({ highlighted })}`}>
              <span className={`${styles.name({ highlighted })}`}>{name}</span>
              <span
                className={`${styles.price({ highlighted })} ${
                  highlighted ? "mt-2" : "mt-1"
                }`}
              >
                {getPriceLabel()}
              </span>
              {periodicity && (
                <span className={`${styles.perMonth({ highlighted })}`}>
                  {periodicity}
                </span>
              )}
              {price !== undefined && price > 0 && priceType === "monthly" && (
                <span className={`${styles.perMonth({ highlighted })}`}>
                  per month
                </span>
              )}
              {price !== undefined && price > 0 && priceType === "yearly" && (
                <span className={`${styles.perMonth({ highlighted })}`}>
                  20% saving per month
                </span>
              )}
            </div>
            <div className="mb-1">
              <PrimaryButton
                className={`${styles.selectButton}`}
                color={highlighted ? "emeraldish" : "gallery"}
                text={buttonText}
                size={highlighted ? "medium" : "small"}
                onClick={onSelect}
              />
            </div>
          </div>
        </CardBackground>
      </div>
      {features.length > 0 && (
        <div className={`${styles.featuresWrapper}`}>
          <ul className={`${styles.features} mt-3 mt-smd-4`}>
            {features.map((feature, index) => (
              <li key={index} className={`${styles.feature} mt-2 mt-smd-3`}>
                <Icon
                  className={`${styles.tickIcon} mr-2`}
                  icon={Icons.FilledCircleTick}
                  width={18}
                />
                {feature}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
