import React, { useRef } from "react";
import { ProductSelectionPopupContainer } from "./ProductSelectionPopup.container";
import { CheckoutSteps } from "../../navs/CheckoutSteps";
import { CheckoutPanel } from "../../panels/CheckoutPanel";
import { CheckoutPersonalDetailsForm } from "../../forms/CheckoutPersonalDetailsForm";
import { CheckoutStepsBreadcrumbs } from "../../navs/CheckoutSteps/CheckoutStepsBreadcrumbs";
import { CheckoutFooterBar } from "../../bars/CheckoutFooterBar";
import { SelectionStep } from "./SelectionStep";
import { StripePayment } from "../StripePayment";
import { WidePopup } from "../../components/WidePopup";
import { UserFormQuestionary } from "../UserFormQuestionary";

export function ProductSelectionPopupView({}) {
  const {
    credit,
    school,
    product,
    isConfirmingFreeBooking,
    isDiscountEnabled,
    isLastStep,
    isNewsletterSignupEnabled,
    isStripePaymentReady,
    postage,
    actions,
    helpers,
    errors,
    touched,
    values,
    logoUrl,
    isUserFormValid
  } = ProductSelectionPopupContainer.useContainer();

  const popupWrapperRef = useRef();

  function onStepChange(index) {
    popupWrapperRef.current.scrollTo({
      top: 0
    });
    actions.onCheckoutStepChange(index);
  }

  return (
    <WidePopup
      onClose={actions.onClose}
      logo={logoUrl}
      wrapperRef={popupWrapperRef}
    >
      <CheckoutSteps.Provider
        onContinue={actions.onContinue}
        onNext={onStepChange}
        onPrevious={onStepChange}
      >
        <div className="product-selection-popup-view">
          <div className="container-fluid mb-2">
            <CheckoutStepsBreadcrumbs />
          </div>
          <CheckoutPanel
            cancellationPolicyUrl="https://obby.co.uk/terms/booking-terms"
            discountError={touched.discountCode && errors.discountCode}
            discountValue={helpers.getDiscount()}
            discountPercentage={helpers.getDiscountPercentage()}
            currency={product.currency}
            credit={credit}
            title={product.name}
            description={`By ${school.name}`}
            thumbnail={product.images[0]}
            isDiscountInvalid={helpers.isDiscountInvalid()}
            isDiscountEnabled={isDiscountEnabled}
            onApplyDiscount={actions.onApplyDiscount}
            items={[
              {
                name: product.name,
                price: product.price,
                count: values.quantity
              },
              ...(postage
                ? [
                    {
                      name: postage.name,
                      price: postage.price * values.quantity
                    }
                  ]
                : []),
              ...(values.isGift
                ? [
                    {
                      name: "Gifting Fee",
                      price: product.gifting?.giftingFee ?? 0
                    }
                  ]
                : [])
            ]}
            personalDetails={
              isLastStep ? values.personalDetails.guests[0] : undefined
            }
            total={helpers.getTotal()}
            sidebarOnTop={isLastStep}
          >
            <CheckoutSteps>
              <CheckoutSteps.Step
                breadcrumb="Selection"
                disabled={!helpers.isSelectionValid()}
              >
                <SelectionStep />
              </CheckoutSteps.Step>
              <CheckoutSteps.Step
                breadcrumb=" Customer Information"
                title=" Customer Information"
                description="Please provide the necessary information so that we can continue with your booking."
                disabled={
                  !(
                    product.userQuestionForm.isEnabled == false ||
                    isUserFormValid
                  )
                }
                hidden={!product.userQuestionForm.isEnabled}
              >
                <UserFormQuestionary
                  values={values}
                  onChange={actions.onChange}
                  mode={product.userQuestionForm.mode}
                  questions={product.userQuestionForm.questions}
                  onFormValidation={actions.setIsUserFormValid}
                />
              </CheckoutSteps.Step>
              <CheckoutSteps.Step
                breadcrumb="Personal details"
                disabled={
                  !helpers.isValid("personalDetails") || isConfirmingFreeBooking
                }
                onEnter={() => actions.onPersonalDetailsEnter()}
                nextButtonText={
                  helpers.isFree() ? "Confirm booking" : undefined
                }
              >
                <CheckoutPersonalDetailsForm
                  errors={errors?.personalDetails}
                  onChange={(value, name) =>
                    actions.onChange(value, `personalDetails.${name}`)
                  }
                  onBlur={name => actions.onBlur(`personalDetails.${name}`)}
                  isSendingMaterial={true}
                  values={values.personalDetails}
                  touched={touched?.personalDetails}
                  disableMarketingOptIn={!isNewsletterSignupEnabled}
                />
              </CheckoutSteps.Step>
              <CheckoutSteps.Step
                breadcrumb="Card payment"
                title="Card payment"
                nextButtonText="Pay now"
                onEnter={actions.onCardPaymentEnter}
                disabled={!isStripePaymentReady}
                hidden={helpers.isFree()}
              >
                <StripePayment />
              </CheckoutSteps.Step>
            </CheckoutSteps>
          </CheckoutPanel>
          <CheckoutFooterBar label="Delivery Preference" value={postage?.name}>
            <CheckoutSteps.Navigation total={helpers.getTotal()} />
          </CheckoutFooterBar>
        </div>
      </CheckoutSteps.Provider>
    </WidePopup>
  );
}
