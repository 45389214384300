import React, { useMemo, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { ImageUrl__ImageResizedUrl } from "@obby/lib";
import styles from "./gallery.module.less";

const sizes = [
  // size for 1 image
  [[800, 400]],
  // sizes for 2 images
  [
    [400, 400],
    [400, 400]
  ],
  // sizes for 3 images
  [
    [400, 400],
    [400, 200],
    [400, 200]
  ]
];

export function Gallery(props) {
  const { images } = props;
  const [imageIndex, setImageIndex] = useState(null);

  const numberOfThumbnails = Math.min(images.length, 3);

  const thumbnails = useMemo(() => {
    if (images.length === 0) return [];
    return images.slice(0, 3).map((image, index) => {
      const [width, height] = sizes[numberOfThumbnails - 1][index];
      return ImageUrl__ImageResizedUrl(
        {
          width,
          height,
          crop: "fill"
        },
        image
      );
    });
  }, [images]);

  function onClose() {
    setImageIndex(null);
  }

  function onNext() {
    setImageIndex((imageIndex + 1) % images.length);
  }

  function onPrevious() {
    setImageIndex((imageIndex + images.length - 1) % images.length);
  }

  function onThumbnailClick(index) {
    setImageIndex(index);
  }

  return (
    <div className={styles["gallery"]}>
      <div className={styles["gallery__placeholder"]} />
      <div
        className={`${styles["gallery__thumbnails"]} ${
          styles[`gallery__thumbnails--${numberOfThumbnails}`]
        }`}
      >
        {thumbnails.map((image, index) => (
          <div
            key={index}
            className={`${styles["gallery__thumbnail"]} ${
              styles[`gallery__thumbnail--${index + 1}`]
            }`}
            onClick={() => onThumbnailClick(index)}
          >
            <div className={styles["gallery__frame"]}>
              <img
                className={styles["gallery__image"]}
                src={image}
                alt={`Image ${index + 1}`}
              />
            </div>
          </div>
        ))}
      </div>
      {imageIndex !== null && (
        <Lightbox
          mainSrc={images[imageIndex]}
          nextSrc={images[(imageIndex + 1) % images.length]}
          prevSrc={images[(imageIndex + images.length - 1) % images.length]}
          onCloseRequest={onClose}
          onMovePrevRequest={onPrevious}
          onMoveNextRequest={onNext}
          enableZoom={false}
          imageCaption={`${imageIndex + 1} of ${images.length}`}
          imagePadding={80}
        />
      )}
    </div>
  );
}

Gallery.propTypes = {};
