import moment from "moment";

export function GetTicketTypePriceBasedOnSession(ticketType, selectedSession) {
  let selectedPrice = ticketType.price;
  if (ticketType.pricesAvailable?.length > 0) {
    ticketType.pricesAvailable.sort(function(a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.fromDate) - new Date(a.fromDate);
    });
    const filteredPrice = ticketType.pricesAvailable.filter(price => {
      return moment(price.fromDate).isBefore(
        moment(selectedSession.startDateTime)
      );
    });
    if (filteredPrice.length > 0) {
      selectedPrice = filteredPrice[0].price;
    }
  }
  return selectedPrice;
}
