import React from "react";
import PropTypes from "prop-types";

import styles from "./attention-banner.module.less";

const AttentionBanner = ({ title, description, color }) => {
  return (
    <div
      className={`${styles["attention-banner"]} ${
        styles[`attention-banner--${color}`]
      }`}
    >
      <span className={styles["attention-banner__title"]}>{title}</span>
      <span className={styles["attention-banner__description"]}>
        {description}
      </span>
    </div>
  );
};

AttentionBanner.defaultProps = {
  color: "emeraldish"
};

AttentionBanner.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  color: PropTypes.string
};

export default AttentionBanner;
