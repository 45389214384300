import React from "react";
import { GiftCardCheckoutContainer } from "./GiftCardCheckout.container";
import { GiftCardCheckoutView } from "./GiftCardCheckout.view";
import { StripePaymentProvider } from "../StripePayment";

export function GiftCardCheckout({
  stripePublicKey,
  onGetPaymentIntent,
  ...props
}) {
  return (
    <StripePaymentProvider
      publicKey={stripePublicKey}
      onGetPaymentIntent={onGetPaymentIntent}
    >
      <GiftCardCheckoutContainer.Provider initialState={props}>
        <GiftCardCheckoutView />
      </GiftCardCheckoutContainer.Provider>
    </StripePaymentProvider>
  );
}
