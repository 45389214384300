import { css } from "../../sitches.config";

export const styles = {
  component: css({
    position: "relative"
  }),

  container: css({
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0
  })
};
