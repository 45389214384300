import moment, { unitOfTime } from "moment-timezone";
import { Timezones } from "@obby/constants";

export function UTCDate__Get(
  isoDate: string,
  timezone: string = Timezones.EUROPE_LONDON,
  unitOfTime: unitOfTime.All
) {
  return moment(isoDate)
    .tz(timezone)
    .get(unitOfTime);
}
