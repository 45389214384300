import React from "react";
// Components
import TipBox from "./../TipBox";
import { bemModule } from "../../configs/bem";
import Icon from "../Icon";
import * as Icons from "../Icons";
import styles from "./dashboard-page-section.module.less";

const cn = bemModule(styles, "dashboard-page-section");
export default function DashboardPageSection(props) {
  const {
    title,
    titleNote,
    description,
    tips,
    maxWidth,
    children,
    spacingGap = "large",
    restricted,
    onClick
  } = props;
  return (
    <div
      className={cn({ "spacing-gap": spacingGap, restricted })}
      onClick={onClick}
    >
      {title && (
        <h3 className={cn("title")}>
          <span>
            {title}
            {restricted && (
              <Icon
                className={cn("lock", null, ["ml-2"])}
                icon={Icons.Lock}
                height={18}
              />
            )}
          </span>
          {titleNote && <span className={cn("title-note")}>{titleNote}</span>}
        </h3>
      )}

      {description && <div className={cn("description")}>{description}</div>}

      {tips && (
        <div className={cn("tips")}>
          <TipBox title="Tips" tips={tips} />
        </div>
      )}

      <div className={cn("body", { "max-width": maxWidth })}>{children}</div>
    </div>
  );
}
