import React from "react";
import PropTypes from "prop-types";

import ClassCard from "../ClassCard";
import RailCarousel from "../RailCarousel";
import MobileClassList from "./../MobileClassList";
import { styles } from "./ResponsiveClassCards.styles";

const ResponsiveClassCards = ({
  title = "",
  description = "",
  numberOfColumns = 4,
  mobileColumns = 2,
  tabletColumns = 3,
  railCarouselMobile = true,
  onClick = () => {},
  sortCard = true,
  skeleton = false,
  cards = skeleton ? new Array(numberOfColumns ?? 4).fill({}) : []
}) => {
  if (sortCard) {
    cards = cards = cards.sort((a, b) => b.rating - a.rating);
  }
  return (
    <>
      <div
        className={`responsive-class-cards__mobile ${styles.responsiveCardMobile()}`}
      >
        {railCarouselMobile ? (
          <RailCarousel
            title={title}
            description={description}
            numberOfColumns={mobileColumns ? mobileColumns : 2}
            items={cards}
            Child={ClassCard}
            onClick={card => onClick(title, card)}
            hideArrows={cards.length <= mobileColumns}
            skeleton={skeleton}
          />
        ) : (
          <MobileClassList title={title} classes={cards} />
        )}
      </div>
      <div
        className={`responsive-class-cards__tablet ${styles.responsiveCardTablet()}`}
      >
        <RailCarousel
          title={title}
          description={description}
          numberOfColumns={tabletColumns ? tabletColumns : 3}
          items={cards}
          Child={ClassCard}
          onClick={card => onClick(title, card)}
          hideArrows={cards.length <= tabletColumns}
          skeleton={skeleton}
        />
      </div>
      <div
        className={`responsive-class-cards__desktop ${styles.responsiveCardDesktop()}`}
      >
        <RailCarousel
          title={title}
          description={description}
          numberOfColumns={numberOfColumns}
          items={cards}
          Child={ClassCard}
          onClick={card => onClick(title, card)}
          hideArrows={cards.length <= numberOfColumns}
          skeleton={skeleton}
        />
      </div>
    </>
  );
};

ResponsiveClassCards.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  cards: PropTypes.array,
  numberOfColumns: PropTypes.number
};

export default ResponsiveClassCards;
