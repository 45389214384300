export default user => {
  if (user.profile && user.profile.imageUrl) {
    return user.profile.imageUrl;
  } else if (
    user.services &&
    user.services.google &&
    user.services.google.picture
  ) {
    return user.services.google.picture;
  } else return "";
};
