import moment from "moment";
import Booking__StartDateTime from "./Booking__StartDateTime";

const CurrentTimeAndBooking__MinutesElapsedSinceStarted = (
  currentTime,
  booking
) => {
  var startDateTime = Booking__StartDateTime(booking);
  var minutesElapsed = moment
    .duration(moment(currentTime) - moment(startDateTime))
    .asMinutes();

  return minutesElapsed < 0 ? 0 : minutesElapsed;
};

export default CurrentTimeAndBooking__MinutesElapsedSinceStarted;
