import React from "react";

import { CourseCheckoutContainer } from "./CourseCheckout.container";
import { CourseCheckoutView } from "./CourseCheckout.view";
import { StripePaymentProvider } from "../StripePayment";
import { Companies, Currencies, Timezones } from "@obby/constants";

export function CourseCheckout({
  mandatoryTickBox = false,
  course,
  cancellationPolicyUrl = "/terms",
  courseId,
  courseThumbnail,
  courseTitle,
  courseType,
  currency = Currencies.GBP,
  discount,
  isBundleEnabled,
  isNewsletterSignupEnabled = true,
  isOnline,
  isSendingMaterial,
  isSoldOut,
  isTrialEnabled,
  locations,
  logoUrl,
  onBeforeGetPaymentIntent,
  onConfirmFreeBooking,
  onGetPaymentIntent,
  onLoadDates,
  onLogin,
  onStepChange,
  onValidateDiscount,
  onViewBundlesClick,
  onViewTrialClick,
  openWaitingListPopup,
  session,
  sessions,
  stripePublicKey,
  user,
  userInputs,
  ticketTypes,
  timezone = Timezones.EUROPE_LONDON,
  tutors,
  source = "obby",
  allowPrefillOthersGuestsOnCheckout = false
}) {
  return (
    <StripePaymentProvider
      publicKey={stripePublicKey}
      onGetPaymentIntent={onGetPaymentIntent}
    >
      <CourseCheckoutContainer.Provider
        initialState={{
          mandatoryTickBox,
          course,
          cancellationPolicyUrl,
          courseId,
          courseThumbnail,
          courseTitle,
          courseType,
          currency,
          discount,
          isBundleEnabled,
          isNewsletterSignupEnabled,
          isOnline,
          isSendingMaterial,
          isSoldOut,
          isTrialEnabled,
          locations,
          logoUrl,
          onBeforeGetPaymentIntent,
          onConfirmFreeBooking,
          onLoadDates,
          onLogin,
          onStepChange,
          onValidateDiscount,
          onViewBundlesClick,
          onViewTrialClick,
          openWaitingListPopup,
          session,
          sessions,
          ticketTypes,
          timezone,
          tutors,
          user,
          userInputs,
          source,
          allowPrefillOthersGuestsOnCheckout
        }}
      >
        <CourseCheckoutView />
      </CourseCheckoutContainer.Provider>
    </StripePaymentProvider>
  );
}

CourseCheckout.propTypes = {};
