import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";

import styles from "./menu-nav-dropdown.module.less";

export function MenuNavDropdown({
  active,
  children,
  onMouseLeave,
  dropdownRef
}) {
  const [activeItem, setActiveItem] = useState(active);
  const [dropdownHeight, setDropdownHeight] = useState(0);
  const containerRef = useRef();
  // listener to be used everytime the viewport resizes when the dropdown is open
  const onResize = useRef(_.throttle(resize, 400));

  // update dropdown height to the container height
  function resize() {
    const { offsetHeight } = containerRef.current;
    setDropdownHeight(offsetHeight);
  }

  // sanity removal of the resize listener if the component is unmounted while open
  useEffect(
    () => () => window.removeEventListener("resize", onResize.current),
    []
  );

  useEffect(() => {
    if (activeItem === active) return;
    // if active is undefined lets hide the dropdown by setting height to 0
    // and remove the container 250m later (after the closing animation)
    if (active === undefined) {
      setDropdownHeight(0);
      setTimeout(() => {
        setActiveItem(undefined);
      }, 250);
    }
    // change the container to the active item
    else setActiveItem(active);
  }, [active]);

  useEffect(() => {
    // Update the dropdown height everytime the active item changes
    if (activeItem !== undefined) {
      resize();
      window.addEventListener("resize", onResize.current);
    }
    //
    else window.removeEventListener("resize", onResize.current);
  }, [activeItem]);

  return (
    <div
      ref={dropdownRef}
      className={`${styles["menu-nav-dropdown"]} ${
        dropdownHeight ? styles["menu-nav-dropdown--visible"] : ""
      }`}
      style={{ height: dropdownHeight }}
    >
      {activeItem !== undefined && (
        <div
          className={styles["menu-nav-dropdown__wrapper"]}
          onMouseLeave={onMouseLeave}
        >
          <div ref={containerRef} className="container-fluid py-4">
            {React.Children.map(children, (child, index) => {
              const { id = index, children } = child?.props || {};
              return id === activeItem ? children : <></>;
            })}
          </div>
        </div>
      )}
    </div>
  );
}
