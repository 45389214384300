import React from "react";
import { InferType } from "yup";
import { css } from "../../sitches.config";
import Label from "../../components/Label";
import { TextInput } from "../../inputs/TextInput";
import { Checkbox } from "../../inputs/Checkbox";
import { PersonalDetailsFormSchema } from "./PersonalDetailsForm.schema";
import { Col } from "../../layout/Col";
import { Row } from "../../layout/Row";
import { useAppText } from "../../containers";
import ThemedMarkdownText from "../../components/ThemedMarkdownText";

export function PersonalDetailsForm({
  errors = {},
  touched = {},
  values,
  guest = false,
  disableEmail = false,
  disablePhoneNumber = false,
  disableMarketingOptIn = false,
  onBlur,
  onChange,
  mandatoryTickBox = false
}: PersonalDetailsForm.Props) {
  const { __ } = useAppText();
  return (
    <div className="personal-details-form">
      <Row rowGap={3}>
        <Col sm={6}>
          <Label
            label="First name*"
            error={touched.firstName && errors.firstName}
          >
            <TextInput
              name="firstName"
              onChange={onChange}
              onBlur={onBlur}
              value={values.firstName}
            />
          </Label>
        </Col>
        <Col sm={6}>
          <Label label="Surname*" error={touched.lastName && errors.lastName}>
            <TextInput
              name="lastName"
              onChange={onChange}
              onBlur={onBlur}
              value={values.lastName}
            />
          </Label>
        </Col>
        <Col sm={6}>
          <Label
            label={guest ? "Email" : "Email*"}
            error={touched.email && errors.email}
          >
            <TextInput
              name="email"
              onChange={onChange}
              onBlur={onBlur}
              value={values.email}
              disabled={disableEmail}
            />
          </Label>
        </Col>
        {!guest && !disablePhoneNumber && (
          <Col sm={6}>
            <Label
              label="Phone number*"
              error={touched.phoneNumber && errors.phoneNumber}
            >
              <TextInput
                className="checkout-personal-details-form__phone-number-input"
                name="phoneNumber"
                onChange={onChange}
                onBlur={onBlur}
                value={values.phoneNumber}
              />
            </Label>
          </Col>
        )}
      </Row>

      {!guest && !disableMarketingOptIn && (
        <Col>
          <Label>
            <Checkbox
              name="marketingOptIn"
              value={values.marketingOptIn}
              label={
                <ThemedMarkdownText
                  text={__(
                    "Sign up to the newsletter to occasionally receive news, promotions and event information. You can unsubscribe at any time."
                  )}
                  classNames={{ link: styles.link() }}
                />
              }
              onChange={onChange}
              onBlur={onBlur}
            />
          </Label>
        </Col>
      )}
      {mandatoryTickBox && (
        <Col>
          <Label>
            <Checkbox
              name="mandatoryTickBox"
              value={values.mandatoryTickBox}
              label={
                <ThemedMarkdownText
                  text={`By clicking here, I state that I have read and understood the terms and conditions associated with my purchase. [T&C link](${window?.location.origin}/terms)`}
                  classNames={{ link: styles.link() }}
                />
              }
              onChange={onChange}
              onBlur={onBlur}
            />
          </Label>
        </Col>
      )}
    </div>
  );
}
PersonalDetailsForm.Schema = PersonalDetailsFormSchema;

export namespace PersonalDetailsForm {
  export type Fields = InferType<ReturnType<typeof PersonalDetailsFormSchema>>;

  export interface Props {
    disableEmail?: boolean;
    disableMarketingOptIn?: boolean;
    disablePhoneNumber?: boolean;
    errors?: any;
    guest?: boolean;
    onBlur: (name: string) => void;
    onChange: (value: any, name: string) => void;
    touched?: any;
    values: PersonalDetailsForm.Fields;
    mandatoryTickBox?: boolean;
  }
}

const styles = {
  link: css({
    color: "$emeraldish",
    textDecoration: "none"
  })
};
