import { css, withBem } from "../../sitches.config";

export const styles = withBem("markdown-editor", {
  markdownEditor: css({
    fontFamily: "$bodyFont",
    fontSize: "1.0rem",
    lineHeight: 1.2,

    "& .react-mde": {
      borderRadius: "$input",
      borderColor: "$outlineColour",

      "&:focus-within": {
        outline: "none"
      }
    },

    // Toolbar
    "& .mde-header": {
      borderRadius: "$input $input 0 0",
      borderColor: "$outlineColour"
    },

    // Preview Container
    "& .mde-preview": {
      backgroundColor: "white",
      borderBottomLeftRadius: "$input",
      borderBottomRightRadius: "$input"
    },

    // Text area
    "& .mde-text": {
      fontFamily: "$bodyFont",
      fontSize: "1.0rem",
      "&:focus": {
        outline: "none"
      },
      "&:disabled": {
        color: "$disabled"
      }
    },

    "& .mde-tabs button": {
      fontFamily: "$bodyFont",
      fontSize: "0.875rem",
      "&.selected": {
        borderRadius: "$1"
      },
      "&:disabled": {
        color: "$disabled",
        "&.selected": {
          borderColor: "$mercury"
        }
      }
    },
    "& .mde-header-item button": {
      "&:disabled svg": {
        color: "$disabled"
      }
    }
  })
});
