import React, { MutableRefObject } from "react";
import { css, withBem } from "../../sitches.config";
import { RoundedButton } from "../RoundedButton";
import Icon from "../../components/Icon";
import * as Icons from "../../components/Icons";

export function PillButton(props: PillButton.Props) {
  const {
    backgroundColorHex,
    borderColorHex = backgroundColorHex,
    className = "",
    colorHex,
    // color = "callToAction",
    css,
    disabled = false,
    innerRef,
    isLoading,
    onClick,
    onMouseOver,
    selected,
    size = "large",
    square = false,
    tag,
    text,
    /** @deprecated */
    textMode = "normal",
    type = "button",
    uppercase = textMode === "uppercase",
    width = "auto"
  } = props;

  return (
    <RoundedButton
      backgroundColorHex={backgroundColorHex}
      borderColorHex={borderColorHex}
      className={styles.button({
        selected,
        css
      })}
      color={selected ? "emeraldish" : "mercury"}
      colorHex={colorHex}
      disabled={disabled}
      elem="button"
      elemProps={{
        type
      }}
      innerRef={innerRef}
      isLoading={isLoading}
      onClick={onClick}
      onMouseOver={onMouseOver}
      size={size}
      square={square}
      text={
        <div className={styles.textWrapper()}>
          {text}{" "}
          <Icon
            className={styles.icon({ size })}
            color={selected ? "white" : "emeraldish"}
            icon={Icons.ChevronDown}
          />
        </div>
      }
      tag={tag}
      uppercase={uppercase}
      width={width}
      outline={!selected}
    />
  );
}

const styles = withBem("pill-button", {
  button: css({
    variants: {
      selected: { true: {} }
    },
    compoundVariants: [
      {
        selected: false,
        css: {
          backgroundColor: "$white"
        }
      }
    ]
  }),
  textWrapper: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    gap: "$2"
  }),
  icon: css({
    variants: {
      size: {
        tiny: {
          width: 11
        },
        small: {
          width: 12
        },
        medium: {
          width: 14
        },
        large: {
          width: 16
        }
      }
    }
  })
});

export namespace PillButton {
  export interface Props
    extends Omit<
      RoundedButton.Props,
      "elem" | "elemProps" | "innerRef" | "icon"
    > {
    css?: { [key: string]: any };
    innerRef?: MutableRefObject<HTMLButtonElement | undefined>;
    /** @deprecated */
    textMode?: "normal" | "uppercase";
    type?: "button" | "submit" | "reset";
    selected?: boolean;
  }
}
