import React, { useContext, useRef, useEffect } from "react";

import { AccordionBoxContext } from "./AccordionBoxContext";
import { styles } from "./AccordionBoxContainer.styles";

export function AccordionBoxContainer(props) {
  const { children, className } = props;
  const { open = false } = useContext(AccordionBoxContext);
  const contentRef = useRef();
  const wrapperRef = useRef();
  //disabling this effect for now because its not working the safari browser
  /*
  function onContentResize(entries) {
    for (let entry of entries) {
      let {contentBoxSize} = entry;
      // for some browsers contentBoxSize is an array of one element
      // so lets destruct the first element if this is the case
      if(Array.isArray(contentBoxSize))
        [contentBoxSize] = contentBoxSize;

      wrapperRef.current.style.height = `${contentBoxSize.blockSize}px`;
    }
  }

  useEffect(() => {
    if (open) {
      const resizeObserver = new ResizeObserver(onContentResize);
      resizeObserver.observe(contentRef.current);
      return () => {
        resizeObserver.unobserve(contentRef.current);
        wrapperRef.current.style.height = 0;
      };
    }
  }, [open]);
*/
  return (
    <div
      ref={wrapperRef}
      className={styles.container({ closed: !open, className })}
    >
      <div ref={contentRef}>{children}</div>
    </div>
  );
}
