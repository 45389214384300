export const BookingPartnerPaymentStatuses = {
  PENDING: "pending",
  // the code is expecting this word to have a typo
  TRANSFERRED: "transfered",
  REFUNDED_TO_USER: "refundedToUser",
  PAID: "paid",
  // the code is expecting this word to have a typo
  NON_APPLICABLE: "nonAplicable",
  DESTINATION_CHARGE: "destinationCharge"
};
