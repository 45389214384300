import { css } from "../../sitches.config";

export const styles = {
  responsiveClassCards: css({
    "&__big-rail-card": {
      width: "267px",
      height: "320px"
    }
  }),

  responsiveCardMobile: css({
    display: "block",
    ".rail-carousel__nav": {
      display: "block"
    },
    "@sm": {
      display: "none"
    }
  }),
  responsiveCardTablet: css({
    display: "none",
    "@media (min-width: 576px) and (max-width: 834px)": {
      display: "block"
    }
  }),
  responsiveCardDesktop: css({
    display: "none",

    "@md": {
      display: "block"
    }
  })
};
