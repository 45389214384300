import isFullArray from "./isFullArray";
import { BookingAdditionalGuests__StudentEmailsAndNames } from "./BookingAdditionalGuests__StudentEmailsAndNames";

export function Bookings__StudentEmailsAndNames(bookings) {
  return bookings.reduce((emailsAndNames, booking) => {
    if (!booking.buyerEmail || booking.cancelled) return emailsAndNames;
    if (isFullArray(booking.additionalGuests)) {
      return emailsAndNames
        .concat(
          BookingAdditionalGuests__StudentEmailsAndNames(
            booking.additionalGuests
          )
        )
        .concat({
          email: booking.buyerEmail,
          name: booking.buyerName,
          bookingId: booking._id
        });
    }
    return emailsAndNames.concat({
      email: booking.buyerEmail,
      name: booking.buyerName,
      bookingId: booking._id
    });
  }, []);
}
