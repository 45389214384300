import * as Yup from "yup";
export function ConfirmBookingFormSchema(fields = []) {
  const defaults = {
    message: "",
    zoomLink: ""
  };

  return Yup.object({
    ...(fields.includes("message")
      ? {
          message: Yup.string()
            .label("Message")
            .required()
            .default(defaults.message)
        }
      : {}),
    ...(fields.includes("zoomLink")
      ? {
          zoomLink: Yup.string()
            .label("Zoom Link")
            .url()
            .required()
            .default(defaults.zoomLink)
        }
      : {})
  });
}
