import React from "react";
import { FaIcon } from "../../../icons";
import { styles } from "./PricingOptionSectionHeader.styles";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";

export function PricingOptionSectionHeader({
  description,
  heading,
  chevron,
  onChevronClick
}: Props) {
  return (
    <div className={styles.pricingOptionSectionHeader()}>
      <div className={styles.headingWrapper({ border: chevron !== undefined })}>
        <label className={styles.heading()}>{heading}</label>
        {chevron && (
          <FaIcon
            className={styles.chevron({
              chevron,
              clickable: onChevronClick !== undefined
            })}
            icon={faChevronDown}
            onClick={onChevronClick}
            size={16}
          />
        )}
      </div>
      <div className={styles.description()}>{description}</div>
    </div>
  );
}

interface Props {
  chevron?: "up" | "down";
  description: string;
  heading: string;
  onChevronClick?: () => void;
}
