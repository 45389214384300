import React from "react";

import Icon from "../../components/Icon";
import Icons from "../../components/Icons";
import { Timezones } from "@obby/constants";
import { UTCDate__FormattedDate } from "@obby/lib";
import { bemModule } from "../../configs/bem";
import styles from "./CustomerCard.module.less";
import { String__PhoneNumber } from "@obby/lib";
const cn = bemModule(styles, "customer-card");
export function CustomerCard(props) {
  const {
    name,
    avatar,
    email,
    phone,
    firstPurchase,
    marketingOptIn,
    canViewContactInfo = true,
    onContactInformationClick,
    timezone = Timezones.EUROPE_LONDON
  } = props;
  return (
    <div className={cn()}>
      {avatar && (
        <img alt={`${name}'s avatar`} className={cn("avatar")} src={avatar} />
      )}
      <div className={cn("info")}>
        <h1 className={cn("name")}>{name}</h1>

        <span className={cn("details")}>
          <div>
            First purchase:{" "}
            {UTCDate__FormattedDate(
              firstPurchase,
              timezone,
              "DD/MM/YYYY h:mm A"
            )}
          </div>
          {marketingOptIn !== undefined && (
            <div>Opted Into Marketing: {marketingOptIn ? "True" : "False"}</div>
          )}
          {canViewContactInfo && (
            <>
              {email && <div>{email}</div>}
              {phone && <div>{String__PhoneNumber(phone)}</div>}
            </>
          )}
          {!canViewContactInfo && (
            <div onClick={onContactInformationClick}>
              <Icon icon={Icons.Lock} height={14} /> Contact information
            </div>
          )}
        </span>
      </div>
    </div>
  );
}
