import React, { useState, useEffect, ReactNode } from "react";
import { faAdd } from "@fortawesome/free-solid-svg-icons/faAdd";
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark";

import { ArrayInputContext } from "./ArrayInput.context";
import { ArrayInputRemoveItemButton } from "./ArrayInputRemoveItemButton";
import { FaIcon } from "../../icons/FaIcon";

import { styles } from "./ArrayInput.styles";

interface Props<T = any> {
  accent?: string;
  addButtonLabel?: string;
  children?: (
    item: T,
    index: number,
    props: {
      onBlur: (name: string) => void;
      onChange: (value: any, name: string) => void;
    }
  ) => ReactNode | false;
  name?: string;
  default?: Partial<T>;
  items: T[];
  onAdd?: () => void;
  onBlurItem?: (name: string) => void;
  onChange?: (values: any[], name: string) => void;
  onChangeItem?: (value: any, name: string) => void;
  onRemove?: (index: number) => void;
  maxLength?: number;
  fixed?: boolean;
}

export function ArrayInput<T = any>(props: Props<T>) {
  const {
    accent = "callToAction",
    addButtonLabel = "Add new item",
    children = () => <></>,
    name,
    default: defaultItem = {},
    onAdd,
    onBlurItem,
    onChange,
    onChangeItem,
    onRemove,
    maxLength,
    fixed = false
  } = props;

  const [items, setItems] = useState<T[]>(props.items || []);

  useEffect(() => {
    setItems(props.items);
  }, [props.items]);

  function onAddClick() {
    if (onChange) {
      const state: T[] = [...items, defaultItem as T];
      setItems(state);
      onChange(state, name!);
    }
    if (onAdd) onAdd();
  }

  function onRemoveClick(index: number) {
    if (onChange) {
      const state = [...items];
      state.splice(index, 1);
      setItems(state);
      onChange(state, name!);
    }
    if (onRemove) onRemove(index);
  }

  const isRemovable =
    !fixed && (onChange !== undefined || onRemove !== undefined);

  return (
    <div className={styles.arrayInput()}>
      <ul className={styles.list()}>
        {items.map((item, index) => {
          const child = children(item, index, {
            onChange: (value, name) =>
              onChangeItem && onChangeItem(value, `${index}.${name}`),
            onBlur: name => onBlurItem && onBlurItem(`${index}.${name}`)
          });
          if (child === false) return <React.Fragment key={index} />;
          return (
            <ArrayInputContext.Provider key={index} value={{ index }}>
              <li className={styles.item()}>
                <div className={styles.itemContent()}>{child}</div>
                {isRemovable && (
                  <ArrayInputRemoveItemButton onClick={onRemoveClick} />
                )}
              </li>
            </ArrayInputContext.Provider>
          );
        })}
      </ul>
      {!fixed && (maxLength === undefined || items.length < maxLength) && (
        <div
          className={styles.addItemButton({ css: { color: `$${accent}` } })}
          onClick={onAddClick}
        >
          <FaIcon icon={faAdd} size={16} />
          {addButtonLabel}
        </div>
      )}
    </div>
  );
}

ArrayInput.RemoveItemButton = ArrayInputRemoveItemButton;
