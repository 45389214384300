import React, { useEffect, useContext, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
  LinkAuthenticationElement
} from "@stripe/react-stripe-js";
import LoadingIcon from "../../components/LoadingIcon";
import { Label } from "../../components/Label";

import { StripePaymentContext } from "./StripePayment.context";

export function StripePayment() {
  const {
    clientSecret,
    error,
    isLoading,
    isSubmitting,
    publicKey,
    setPaymentIntent
  } = useContext(StripePaymentContext);

  useEffect(() => {
    return () => {
      setPaymentIntent();
    };
  }, []);

  return (
    <>
      {isLoading && (
        <div className="mb-4">
          <LoadingIcon />
        </div>
      )}
      <div>
        {clientSecret && (
          // @ts-ignore
          <Elements
            stripe={loadStripe(publicKey)}
            options={{
              clientSecret,
              loader: "auto"
            }}
          >
            <CheckoutForm />
          </Elements>
        )}
        {error && <Label error={error} />}
      </div>
      {isSubmitting && (
        <div className="m-4">
          <LoadingIcon />
        </div>
      )}
    </>
  );
}

export function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const { setStripeElements, setIsLoading } = useContext(StripePaymentContext);
  const [linkEmail, setLinkEmail] = useState("");
  useEffect(() => {
    if (stripe && elements) setStripeElements(stripe, elements);
  }, [stripe, elements]);

  function onReady() {
    setIsLoading(false);
  }

  //return <PaymentElement id="payment-element" onReady={onReady} />;
  return (
    // @ts-ignore
    <form id="payment-form">
      <LinkAuthenticationElement
        id="link-authentication-element"
        // Access the email value like so:
        onChange={event => {
          setLinkEmail(event.value.email);
        }}
        //
        // Prefill the email field like so:
        // options={{defaultValues: {email: 'foo@bar.com'}}}

        options={{
          defaultValues: {
            email: linkEmail
          }
        }}
      />

      <PaymentElement
        id="payment-element"
        onReady={onReady}
        options={{
          defaultValues: {
            billingDetails: {
              email: linkEmail
            }
          }
        }}
      />
    </form>
  );
}
