import { css } from "../../sitches.config";

export const styles = {
  wideBannerWithImage: css({
    position: "relative",
    borderRadius: "$3",
    padding: "$3",
    "@md": {
      padding: "$5"
    },

    variants: {
      category: {
        art: {
          backgroundColor: "$robbinsEggBlue"
        },
        languages: {
          backgroundColor: "$sun"
        },
        "drinks-and-tastings": {
          backgroundColor: "$astra"
        },
        crafts: {
          backgroundColor: "$sun"
        },
        food: {
          backgroundColor: "$astra"
        },
        "performing-arts": {
          backgroundColor: "$hummingBird"
        },
        dance: {
          backgroundColor: "$hummingBird"
        },
        music: {
          backgroundColor: "$hummingBird"
        },
        "health-and-beauty": {
          backgroundColor: "$azalea"
        },
        "mindfulness-and-wellbeing": {
          backgroundColor: "$astra"
        },
        photography: {
          backgroundColor: "$emerald"
        },
        technology: {
          backgroundColor: "$sun"
        },
        ebonyClay: {
          backgroundColor: "$ebonyClay"
        }
      }
    }
  }),

  discountBadge: css({
    position: "absolute",
    top: "-24px",
    right: "-6px",
    zIndex: "2",
    "@md": {
      left: "-6px"
    }
  }),

  textColumn: css({
    width: "100%",
    padding: "30px 17px 15px 17px",
    zIndex: "1",

    "@md": {
      padding: "76px 0 76px 50px",
      width: "55%"
    }
  }),

  label: css({
    fontFamily: "$foundersGroteskCondensed",
    fontSize: "$mParagraph",
    lineHeight: "1",
    textTransform: "uppercase",

    variants: {
      category: {
        art: {
          color: "$humming-bird"
        },
        "drinks-and-tastings": {
          color: "$sun"
        },
        crafts: {
          color: "$astra"
        },
        languages: {
          color: "$astra"
        },
        food: {
          color: "$emerald"
        },
        "performing-arts": {
          color: "$camelot"
        },
        "health-and-beauty": {
          color: "$robbinsEggBlue"
        },
        "mindfulness-and-wellbeing": {
          color: "$sun"
        },
        photography: {
          color: "$hummingBird"
        },
        technology: {
          color: "$astra"
        }
      }
    }
  }),

  title: css({
    fontFamily: "$foundersGroteskXCondensed",
    fontSize: "42px",
    lineHeight: "1",
    textTransform: "uppercase",
    paddingBottom: "10px",
    fontWeight: "400",
    marginY: 0,
    "@md": {
      fontSize: "72px"
    },

    variants: {
      hasDiscount: {
        true: {
          marginRight: "5rem",
          "@md": {
            marginRight: 0
          }
        }
      },
      category: {
        art: {
          color: "$humming-bird"
        },
        "drinks-and-tastings": {
          color: "$sun"
        },
        crafts: {
          color: "$astra"
        },
        languages: {
          color: "$astra"
        },
        food: {
          color: "$emerald"
        },
        "performing-arts": {
          color: "$camelot"
        },
        "health-and-beauty": {
          color: "$robbinsEggBlue"
        },
        "mindfulness-and-wellbeing": {
          color: "$sun"
        },
        photography: {
          color: "$hummingBird"
        },
        technology: {
          color: "$astra"
        },
        ebonyClay: {
          color: "$white"
        }
      }
    }
  }),

  description: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$smParagraph",
    lineHeight: "1.4",
    fontWeight: "600",
    margin: "0",
    maxWidth: "400px",

    "@md": {
      marginBottom: "$32"
    },

    variants: {
      category: {
        art: {
          color: "$white"
        },
        languages: {
          color: "$white"
        },
        crafts: {
          color: "$white"
        },
        photography: {
          color: "$white"
        },
        technology: {
          color: "$white"
        },
        "drinks-and-tastings": {
          color: "$ebonyClay"
        },
        food: {
          color: "$ebonyClay"
        },
        "performing-arts": {
          color: "$ebonyClay"
        },
        dance: {
          color: "$ebonyClay"
        },
        music: {
          color: "$ebonyClay"
        },
        "health-and-beauty": {
          color: "$ebonyClay"
        },
        "mindfulness-and-wellbeing": {
          color: "$ebonyClay"
        },
        ebonyClay: {
          color: "$hummingBird"
        }
      }
    }
  }),

  button: css({
    display: "none",

    "@md": {
      display: "inline-block"
    }
  }),

  imageColumn: css({
    right: "0px",
    top: "0px",
    width: "100%",
    position: "static",
    padding: "16px",

    "@md": {
      width: "52%",
      position: "absolute",
      padding: "initial"
    }
  }),

  imageWrap: css({
    position: "relative"
  }),

  categorySticker: css({
    position: "absolute !important",
    width: "84px",
    transform: "rotate(15deg)",
    top: "-20px",
    right: "-12px",
    "@md": {
      right: "-10px",
      top: "-20px"
    }
  }),

  giftCardImage: css({
    position: "absolute !important",
    width: "120px",
    left: "-15px",
    bottom: "-9px",
    transform: "rotate(-5deg)",
    "@md": {
      width: "155px",
      left: "-50px",
      bottom: "-20px"
    }
  }),

  image: css({
    width: "100%",
    maxWidth: "100%",
    borderRadius: "4px"
  }),

  discountBadgeWrapper: css({
    width: "100%",
    position: "absolute",
    textAlign: "right",
    right: "-15px",

    "@md": {
      top: "-64px",
      left: "-24px",
      textAlign: "left"
    },

    "@lg": {
      top: "-34px"
    }
  }),

  note: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "14px",
    fontWeight: "bold",
    marginTop: "45px",
    opacity: "0.6"
  })
};
