import React, { useState, useEffect } from "react";

import { SwitchInputOption } from "./SwitchInputOption";
import { SwitchInputContext } from "./SwitchInputContext";

import { styles } from "./SwitchInput.styles";

export function SwitchInput({
  name,
  onChange = () => {},
  className = "",
  children,
  color,
  size = "medium",
  ...props
}: React.PropsWithChildren<Props>) {
  const [value, setValue] = useState(props.value);
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  function onOptionClick(value: any) {
    setValue(value);
    onChange(value, name!);
  }

  return (
    <SwitchInputContext.Provider
      value={{
        onOptionClick,
        color,
        currentValue: value,
        size
      }}
    >
      <div
        className={styles.switchInput({
          className,
          backgroundColor: color ? "white" : undefined,
          size
        })}
      >
        {children}
      </div>
    </SwitchInputContext.Provider>
  );
}
SwitchInput.Option = SwitchInputOption;

interface Props {
  className?: string;
  color?: string;
  name?: string;
  onChange?: (value: any, name: string) => void;
  size?: "small" | "medium";
  value?: any;
}
