import {
  assoc,
  dissoc,
  mergeAll,
  tail,
  map,
  split,
  filter,
  pipe,
  contains,
  curry,
  values
} from "ramda";

import DashedString__UpperCaseOnEveryWordString from "./DashedString__UpperCaseOnEveryWordString";
import CSkillLevels from "./CSkillLevels";

const RouteName__NameTypeAndValue = curry((categories, routeName) => {
  if (contains(routeName, categories.categoryNames)) {
    return {
      category: routeName
    };
  }

  if (contains(routeName, categories.subcategoryNames)) {
    return {
      subcategory: routeName
    };
  }

  if (
    contains(
      DashedString__UpperCaseOnEveryWordString(routeName),
      values(CSkillLevels)
    )
  ) {
    return {
      level: DashedString__UpperCaseOnEveryWordString(routeName)
    };
  }

  return {
    unkownUrlPart: routeName
  };
});

const Route__RouteObject = (categories, route) =>
  pipe(
    split("/"),
    filter(routeName => routeName !== ""),
    tail,
    map(RouteName__NameTypeAndValue(categories)),
    mergeAll,
    dissoc("unkownUrlPart")
  )(route);

const removeSubcategoryIfThereIsNoCategory = routeObject => {
  if (routeObject.subcategory && !routeObject.category) {
    return dissoc("subcategory", routeObject);
  }
  return routeObject;
};

const RouteObject__SelectedCategories = routeObject => {
  if (routeObject.category && routeObject.subcategory) {
    return [routeObject.category, routeObject.subcategory];
  }
  if (routeObject.category) {
    return [routeObject.category];
  }
  return [];
};

const SearchRoute__SearchState = (route, categories) => {
  var routeObject = Route__RouteObject(categories, route);
  return pipe(
    removeSubcategoryIfThereIsNoCategory,
    assoc("selectedCategories", RouteObject__SelectedCategories(routeObject))
  )(routeObject);
};

export default SearchRoute__SearchState;
