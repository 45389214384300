import React, { useMemo, useState } from "react";
// @ts-ignore
import { CloudinaryUrl__TransformedUrl } from "@obby/lib";
import { Drawer } from "../Drawer";
import Icons from "../Icons";
import Icon from "../Icon";
// @ts-ignore
import styles from "./WidgetHeader.module.less";
import { WidgetUserMenuNav } from "../WidgetMenuNav";

export function WidgetHeader({
  menu = [],
  links = [],
  logoUrl,
  textColor,
  authLink = { isEnabled: false },
  ...props
}: Props) {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const logo = props.logo
    ? useMemo(
        () =>
          CloudinaryUrl__TransformedUrl(props.logo, {
            height: 46,
            crop: "fill"
          }),
        [props.logo]
      )
    : undefined;

  return (
    <div className={styles["widget-header"]}>
      <a
        href={logoUrl}
        className={styles["widget-header__logo"]}
        target="_blank"
      >
        {logo && (
          <img
            className={styles["widget-header__logo-image"]}
            src={logo}
            alt="logo"
          />
        )}
      </a>
      <ul className={styles["widget-header__nav"]}>
        {menu.map((item, index) => (
          <li
            key={index}
            className={`${styles["widget-header__nav-item"]} ${
              item.bold === false ? "" : styles["widget-header__nav-item--bold"]
            }`}
          >
            <a
              className={`${styles["widget-header__nav-item-link"]} ${
                styles["widget-header__nav-item-link--highlight"]
              } ${
                item.selected
                  ? styles["widget-header__nav-item-link--selected"]
                  : ""
              }`}
              onClick={item.onClick}
              href={item.href}
              style={{ color: textColor }}
            >
              {item.label}
            </a>
          </li>
        ))}
      </ul>
      <div className={styles["widget-header__space"]} />

      <ul className={styles["widget-header__nav"]}>
        {links.map((item, index) => (
          <li
            key={index}
            className={`${styles["widget-header__nav-item"]} ${
              item.bold === false ? "" : styles["widget-header__nav-item--bold"]
            }`}
          >
            <a
              className={styles["widget-header__nav-item-link"]}
              href={item.href}
              target={`${item.target ? item.target : "_blank"}`}
              style={{ color: textColor }}
              onClick={item.onClick}
            >
              {item.label}
            </a>
          </li>
        ))}
        {authLink.isEnabled && authLink.isLoggedIn && (
          <li
            style={{ color: textColor }}
            key={"login"}
            className={`${styles["widget-header__nav-item"]} `}
          >
            <WidgetUserMenuNav
              menuLabel={authLink.label}
              options={authLink.options}
            />
          </li>
        )}
        {authLink.isEnabled && !authLink.isLoggedIn && (
          <li key={"login"} className={`${styles["widget-header__nav-item"]} `}>
            <a
              className={styles["widget-header__nav-item-link"]}
              href={"/"}
              target={`"_blank"}`}
              style={{ color: textColor }}
              onClick={authLink.onClick}
            >
              {authLink.label}
            </a>
          </li>
        )}
      </ul>
      <div
        className={styles["widget-header__hamburger-button"]}
        style={{ fill: textColor }}
        onClick={() => setMobileMenuOpen(true)}
      >
        <Icon
          className={styles["widget-header__hamburger-icon"]}
          icon={Icons.Hamburger}
          height={26}
        />
      </div>
      {/* @ts-ignore */}
      <Drawer open={isMobileMenuOpen} onClose={() => setMobileMenuOpen(false)}>
        <ul className={styles["widget-header__mobile-nav"]}>
          {menu.map((item, index) => (
            <li
              key={index}
              className={styles["widget-header__mobile-nav-item"]}
            >
              <a
                className={`${styles["widget-header__mobile-nav-item-link"]} ${
                  item.selected
                    ? styles["widget-header__mobile-nav-item-link--selected"]
                    : ""
                }`}
                href={item.href}
                onClick={item.onClick}
              >
                {item.label}
              </a>
            </li>
          ))}
          {links.map((item, index) => (
            <li
              key={index}
              className={styles["widget-header__mobile-nav-item"]}
            >
              <a
                className={styles["widget-header__mobile-nav-item-link"]}
                onClick={item.onClick}
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
          {authLink.isEnabled && !authLink.isLoggedIn && (
            <li
              key={"login"}
              className={styles["widget-header__mobile-nav-item"]}
            >
              <a
                className={styles["widget-header__mobile-nav-item-link"]}
                onClick={authLink.onClick}
                href={"/"}
              >
                {authLink.label}
              </a>
            </li>
          )}
          {authLink.isEnabled &&
            authLink.isLoggedIn &&
            authLink.options.map((option: any) => {
              return (
                <li
                  style={{ color: textColor }}
                  key={option.label}
                  className={styles["widget-header__mobile-nav-item"]}
                >
                  <a
                    className={styles["widget-header__mobile-nav-item-link"]}
                    href={option.href}
                    style={{ color: textColor }}
                    onClick={option.onClick}
                  >
                    {option.label}
                  </a>
                </li>
              );
            })}
        </ul>
      </Drawer>
    </div>
  );
}

interface Props {
  links?: Link[];
  logo?: string;
  logoUrl: string;
  menu?: Menu[];
  textColor?: string;
  authLink?: any;
}

interface Menu {
  selected: boolean;
  href: string;
  onClick: () => void;
  label: string;
  bold?: boolean;
}

interface Link {
  href: string;
  onClick?: () => void;
  label: string;
  target?: string;
  bold?: boolean;
}
