import React from "react";

import styles from "./delete-button.module.less";
import Icon from "./../Icon";
import Icons from "./../Icons";

const DeleteButton = props => (
  <div
    className={`
  ${styles["delete-button"]} ${
      styles[`delete-button--${props.color || "camelot"}`]
    }
  ${props.disabled && ` ${styles["delete-button--disabled"]}`}
  ${props.className}
`}
    onClick={event => {
      if (!props.disabled) {
        props.onClick(event);
      }
    }}
    title={props.title || "Delete"}
  >
    <Icon icon={Icons.Bin} height={props.size || 20} width={props.size || 20} />
  </div>
);

export default DeleteButton;
