import { css } from "../../sitches.config";

export const styles = {
  component: css({
    backgroundColor: "$white",
    borderRadius: "0 0 $2 $2",
    borderTop: "solid 10px",
    padding: "$3 $4 $4",
    variants: {
      inline: {
        true: {
          display: "flex",
          justifyContent: "space-between"
        }
      }
    }
  }),
  title: css({
    fontFamily: "$foundersGroteskCondensed",
    fontWeight: "bold",
    fontSize: "$20",
    textTransform: "uppercase",
    color: "$ebonyClay"
  }),

  note: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$16",
    letterSpacing: "0.04em",
    color: "$ebonyClay",
    marginY: "$2"
  }),

  link: css({
    fontFamily: "$foundersGrotesk",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "$16",
    letterSpacing: "0.04em",
    color: "$ebonyClay",
    textDecoration: "underline",
    cursor: "pointer"
  })
};
