import {
  atomResultsState,
  filtersOnPathState,
  atomResultsPerPage
} from "./learn";

// Keep a reference to all atoms by key for initializeRecoilState
// all states that needs SSR needs to come with this hacky way for now
const all_atoms = {
  atomResultsState: atomResultsState,
  filtersOnPathState: filtersOnPathState,
  atomResultsPerPage: atomResultsPerPage
};

// Where the magic happens: Used by RecoilRoot to update atoms by key given an
// initial state object of the form {key: initial_value}

export const initializeRecoilState = initialRecoilState => ({ set }) =>
  Object.keys(initialRecoilState).map(key => {
    const value = initialRecoilState[key];
    const atom = all_atoms[key];
    set(atom, value);
  });
