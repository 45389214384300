import React, { createContext, useState } from "react";
export const RisingPopupContext = createContext({});

export function RisingPopupProvider({ children }) {
  const [close, setClose] = useState(false);
  return (
    <RisingPopupContext.Provider value={{ close, setClose }}>
      {children}
    </RisingPopupContext.Provider>
  );
}
