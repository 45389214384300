import { css } from "../../sitches.config";

export const styles = {
  footer: css({
    position: "fixed",
    bottom: "0",
    backgroundColor: "white",
    width: "100%",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.06)"
  }),
  wrapper: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }),
  datesWrapper: css({
    fontFamily: "$foundersGrotesk",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "110%",
    letterSpacing: "0.04em",
    color: "$ebonyClay",

    "@md": {
      fontSize: "18px"
    }
  }),
  dates: css({
    display: "block",
    color: "$emeraldish",

    "@md": {
      display: "inline",
      "&:before": {
        content: " "
      }
    }
  })
};
