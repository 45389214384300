import React from "react";
import PropTypes from "prop-types";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import Icon from "./../Icon";
import styles from "./PromptCard.module.less";

const PromptCard = ({
  title,
  text,
  icon,
  iconHeight,
  iconColor,
  buttonText,
  buttonColor,
  topBorderColor,
  titleSize,
  onClick
}) => (
  <div
    className={`
    ${styles["prompt-card"]}
    ${styles[`prompt-card--top-border-color-${topBorderColor}`]}
  `}
  >
    <div className={styles["prompt-card__title"]}>
      <span
        className={`
					${styles["prompt-card__title-text"]}
          ${styles[`prompt-card__title-text--size-${titleSize}`]}
				`}
      >
        {title}
      </span>
      {icon && (
        <span
          className={`
            ${styles[`prompt-card__title-icon`]}
            ${styles[`prompt-card__title-icon--${iconColor}`]}
          `}
        >
          <Icon icon={icon} height={iconHeight} />
        </span>
      )}
    </div>
    <div className={styles["prompt-card__text"]}>{text}</div>
    <div className={styles["prompt-card__button"]}>
      <PrimaryButton
        width="auto"
        text={buttonText}
        color={buttonColor}
        textMode="uppercase"
        onClick={onClick}
      />
    </div>
  </div>
);

PromptCard.defaultProps = {
  titleSize: "medium",
  buttonColor: "robbinsEggBlue",
  topBorderColor: "royal-blue",
  iconHeight: 20,
  iconColor: "robbinsEggBlue"
};

PromptCard.propTypes = {
  title: PropTypes.string.isRequired,
  titleSize: PropTypes.string,
  icon: PropTypes.object,
  iconHeight: PropTypes.number,
  iconColor: PropTypes.string,
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonColor: PropTypes.string,
  topBorderColor: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default PromptCard;
