import React from "react";

import { styles } from "./NoticeBox.styles";

export function NoticeBox({
  type = "info",
  children
}: React.PropsWithChildren<Props>) {
  return <div className={styles.box({ type })}>{children}</div>;
}

interface Props {
  type?: "info" | "danger";
}
