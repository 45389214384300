import React from "react";

import Icons from "../Icons";
import Icon from "../Icon";
import useCarousel from "../../hooks/useCarousel";
import styles from "./RailCarousel2.module.less";

export function RailCarousel2(props) {
  const { description, title, numberOfColumns = 4, children } = props;

  const items = React.Children.toArray(children);
  const hideArrows = props.hideArrows ?? items.length <= numberOfColumns;

  const [next, previous, visibleItems, atMax, atMin] = useCarousel(
    numberOfColumns,
    items
  );

  return (
    <div className={styles["rail-carousel-2"]}>
      <div className={styles["rail-carousel-2__header"]}>
        <h2 className={styles["rail-carousel-2__title"]}>{title}</h2>
        {!hideArrows && (
          <nav className={styles["rail-carousel-2__nav"]}>
            <a
              onClick={!atMin() ? previous : () => {}}
              className={`${styles["rail-carousel-2__nav-item"]} ${
                atMin() ? styles["rail-carouse-l-2__nav-item--disabled"] : ""
              }`}
            >
              <Icon
                className={styles["rail-carousel-2__nav-icon"]}
                icon={Icons.ChevronPrev}
                height={11}
              />
            </a>
            <a
              onClick={!atMax() ? next : () => {}}
              className={`${styles["rail-carousel-2__nav-item"]} ${
                atMax() ? styles["rail-carousel-2__nav-item--disabled"] : ""
              }`}
            >
              <Icon
                className={styles["rail-carousel-2__nav-icon"]}
                icon={Icons.ChevronNext}
                height={11}
              />
            </a>
          </nav>
        )}
      </div>
      {description && (
        <p className={styles["rail-carousel-2__description"]}>{description}</p>
      )}
      <div
        className={`${styles["rail-carousel-2__body"]} ${
          styles[`rail-carousel-2__body--column-number-${numberOfColumns}`]
        }`}
      >
        {visibleItems}
      </div>
    </div>
  );
}
