import { css, withBem } from "../../sitches.config";

export const styles = withBem("flex", {
  flex: css({
    alignItems: "var(--flex-align-items, stretch)",
    alignSelf: "var(--flex-align-self, auto)",
    columnGap: "var(--flex-column-gap, initial)",
    display: "flex",
    flexDirection: "var(--flex-direction, row)",
    flexWrap: "var(--flex-wrap, nowrap)",
    justifyContent: "var(--flex-justify-content, flex-start)",
    justifySelf: "var(--flex-justify-self)",
    rowGap: "var(--flex-row-gap, initial)",
    variants: {
      inline: {
        true: {
          display: "inline-flex"
        }
      },
      item: {
        true: {
          flexGrow: "var(--flex-grow, 0)",
          display: "block"
        }
      }
    }
  })
});
