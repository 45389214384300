import React from "react";
// Components
import ThemedMarkdownText from "./../ThemedMarkdownText";
import styles from "./TextBox.module.less";

const TextBox = props => (
  <div
    className={`
    ${styles["text-box"]}
    ${styles[`text-box--${props.color || "azalea"}`]}
  `}
  >
    <ThemedMarkdownText
      text={props.text}
      classNames={{
        link: `${styles[`text-box__link`]} ${
          styles[`text-box__link--${props.color || "azalea"}`]
        }`,
        paragraph: `${styles[`text-box__paragraph`]}`
      }}
    />
  </div>
);

export default TextBox;
