import React from "react";

import { TagsInput as ReactTagInput } from "react-tag-input-component";

import { styles } from "./TagInput.styles";

export function TagInput({
  accent = false,
  disabled,
  name = "",
  onBlur,
  onChange,
  placeholder,
  size = "medium",
  value = []
}: Props) {
  function onInputBlur() {
    onBlur && onBlur(name);
  }

  function onInputChange(value: string[]) {
    onChange && onChange(value, name);
  }

  return (
    <div
      className={styles.component({
        className: "tag-input",
        size,
        css: {
          ...(accent
            ? {
                "& .rti--container": {
                  "--rti-main": `$colors$${accent}`
                }
              }
            : {})
        }
      })}
    >
      <ReactTagInput
        disabled={disabled}
        value={value}
        onChange={onInputChange}
        onBlur={onInputBlur}
        name={name}
        placeHolder={placeholder}
      />
    </div>
  );
}

interface Props {
  accent?: string | boolean;
  disabled?: boolean;
  name?: string;
  onBlur?: (name: string) => void;
  onChange?: (value: string[], name: string) => void;
  placeholder?: string;
  size?: "medium" | "small";
  value?: string[];
}
