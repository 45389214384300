import { css, withBem } from "./../sitches.config";

export const styles = withBem("table-empty", {
  tableEmpty: css({}),
  cell: css({
    borderBottomLeftRadius: "var(--table-border-radius, 0)",
    borderBottomRightRadius: "var(--table-border-radius, 0)",
    border: "solid 0 var(--table-border-color)",
    borderTopWidth: "1px",
    borderBottomWidth: "1px",
    "&:first-child": {
      borderLeftWidth: "1px"
    },
    "&:last-child": {
      borderRightWidth: "1px"
    }
  })
});
