import React, { ReactNode, ReactPortal } from "react";
import Select from "react-select";
import _ from "lodash";
import { styles } from "./SelectBox.styles";
import { SelectBoxOption } from "./SelectBoxOption";
import { SelectBoxGroup } from "./SelectBoxGroup";

export function SelectBox<Type = any, Multiple extends boolean = false>(
  props: React.PropsWithChildren<SelectBox.Props<Type, Multiple>>
) {
  const {
    accent = false,
    children,
    className = "",
    clearable,
    compound,
    disabled,
    fixedWidth,
    hideChevron,
    id = props.name ?? "select-box",
    maxWidth,
    menuPlacement = "bottom",
    minWidth,
    multiple,
    name,
    noOptionsText,
    onChange = () => {},
    onBlur = () => {},
    placeholder,
    size = "medium"
  } = props;

  const options: SelectBox.OptionOrGroup<Type>[] =
    React.Children.map(children, (child, index) => {
      if (child === null) return;
      const { props, type } = child as ReactPortal;
      switch (type) {
        case SelectBoxOption:
          return parseOptionProps(props);

        case SelectBoxGroup:
          return parseGroupProps(props);

        default:
          let name = (type as any)?.name ?? type;
          if (name) name = `<${name}> component`;
          else name = `"${child}"`;
          throw `Error: Every SelectBox child must be a SelectBox.Option or a Select.Group. ${name} found on position ${index}`;
      }
    })?.filter(option => option) ?? [];

  const value = multiple
    ? ((props.value as readonly Type[])
        .map(value => findOption(value))
        .filter(option => option) as SelectBox.MultipleValue<
        SelectBox.Option<Type>
      >)
    : findOption(props.value as Type) ?? null;

  function findOption(value: Type): SelectBox.Option<Type> | undefined {
    return options.reduce<SelectBox.Option<Type> | undefined>(
      (option, optionOrGroup) => {
        if (option) return option;

        if ((optionOrGroup as SelectBox.Option<Type>).value !== undefined) {
          if (_.isEqual((optionOrGroup as SelectBox.Option<Type>).value, value))
            option = optionOrGroup as SelectBox.Option<Type>;
        }

        if ((optionOrGroup as SelectBox.Group<Type>).options !== undefined) {
          option = (optionOrGroup as SelectBox.Group<
            Type
          >).options.find(option => _.isEqual(option.value, value));
        }
        return option;
      },
      undefined
    );
  }

  function onSelectChange(
    option: SelectBox.MultipleOrSingle<SelectBox.Option<Type>, Multiple>
  ) {
    const value = multiple
      ? (option as readonly SelectBox.Option<Type>[]).map(
          option => option.value
        )
      : (option as SelectBox.Option<Type>)?.value ?? null;

    onChange(value, name!);
  }

  function parseGroupProps({ children, label }: SelectBoxGroup.Props) {
    return {
      label,
      options: React.Children.map(children, child => {
        return parseOptionProps(child.props);
      })
    };
  }

  function parseOptionProps({
    children,
    disabled = false,
    value
  }: SelectBoxOption.Props) {
    return value !== undefined
      ? {
          disabled,
          label: children,
          value
        }
      : false;
  }

  return (
    <div
      className={styles.selectBox({
        size,
        className,
        compound,
        hideChevron,
        menuPlacement,
        css: {
          ...(accent
            ? {
                "--accent": `$colors$${accent}`
              }
            : {}),
          ...(fixedWidth ? { width: `${fixedWidth}px` } : {}),
          ...(maxWidth ? { maxWidth: `${maxWidth}px` } : {}),
          ...(minWidth ? { minWidth: `${minWidth}px` } : {})
        }
      })}
    >
      <Select<SelectBox.Option<Type>, Multiple>
        blurInputOnSelect={false}
        id={id}
        instanceId={id}
        inputId={id}
        classNamePrefix="react-select"
        isClearable={clearable}
        isDisabled={disabled}
        isMulti={multiple}
        isOptionDisabled={(option: SelectBox.Option<Type>) =>
          option.disabled ?? false
        }
        isSearchable={false}
        name={name}
        noOptionsMessage={() => noOptionsText}
        onChange={onSelectChange}
        onBlur={() => onBlur(name!)}
        options={options}
        placeholder={placeholder}
        value={value}
        menuPlacement={menuPlacement}
      />
    </div>
  );
}
SelectBox.Option = SelectBoxOption;
SelectBox.Group = SelectBoxGroup;

export namespace SelectBox {
  export interface Props<Type, Multiple extends boolean> {
    accent?: string | false;
    className?: string;
    clearable?: boolean;
    compound?: "left" | "middle" | "right";
    disabled?: boolean;
    fixedWidth?: number;
    hideChevron?: boolean;
    id?: string;
    maxWidth?: number;
    menuPlacement?: "top" | "bottom";
    minWidth?: number;
    multiple?: Multiple;
    name?: string;
    noOptionsText?: string;
    onBlur?: (name: string) => void;
    onChange?: (value: any, name: string) => void;
    placeholder?: string;
    size?: "medium" | "small";
    value?: MultipleOrSingle<Type, Multiple>;
  }

  export type MultipleOrSingle<Type, Multiple> = Multiple extends true
    ? MultipleValue<Type>
    : SingleValue<Type>;

  export type MultipleValue<Type> = readonly Type[];
  type SingleValue<Type> = Type | null;

  export type OptionOrGroup<Type> = Option<Type> | Group<Type>;

  export interface Option<Type> {
    disabled?: boolean;
    label?: ReactNode;
    value: Type;
  }

  export interface Group<Type> {
    label?: string;
    options: Option<Type>[];
  }
}
