import React from "react";
import PropTypes from "prop-types";

import Image from "../Image";
import Icons from "../Icons";
import { IconLabel } from "../IconLabel";
import SecondaryButton from "../SecondaryButton";
import ThemedMarkdownText from "../ThemedMarkdownText";
import styles from "./ProductReceiptCard.module.less";

const ProductReceiptCard = ({
  title,
  image,
  purchaseID,
  buttonText,
  onBuyAgain,
  description,
  borderTopColor,
  datePurchased
}) => (
  <div
    className={`${styles["booking-receipt-card"]} ${
      styles[`booking-receipt-card--top-border-color-${borderTopColor}`]
    }`}
  >
    <div className={styles["booking-receipt-card__header"]}>
      <span className={styles["booking-receipt-card__title"]}>{title}</span>
      <Image src={image} className={styles["booking-receipt-card__avatar"]} />
    </div>
    <div className={styles["booking-receipt-card__details"]}>
      <div className={styles["booking-receipt-card__icon-label"]}>
        <IconLabel
          label={`Purchase no: ${purchaseID}`}
          icon={Icons.ShoppingTag}
        />
      </div>
      <div className={styles["booking-receipt-card__icon-label"]}>
        <IconLabel
          icon={Icons.Time}
          label={`Date purchased: ${datePurchased}`}
        />
      </div>
    </div>
    <div className={styles["booking-receipt-card__join-class"]}>
      <SecondaryButton
        color="mercury"
        text={buttonText}
        textMode="uppercase"
        onClick={onBuyAgain}
      />
    </div>
    <div className={styles["booking-receipt-card__tips"]}>
      <ThemedMarkdownText
        text={description}
        classNames={{
          paragraph: styles["booking-receipt-card__label"],
          link: styles["booking-receipt-card__link"]
        }}
      />
    </div>
  </div>
);

ProductReceiptCard.defaultProps = {
  borderTopColor: "royal-blue"
};

ProductReceiptCard.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  borderTopColor: PropTypes.string,
  bookingId: PropTypes.string,
  bookingStartDate: PropTypes.string,
  onJoinClass: PropTypes.func
};

export default ProductReceiptCard;
