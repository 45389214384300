import { css } from "../../sitches.config";

export const styles = {
  header: css({
    marginBottom: "14px"
  }),

  title: css({
    fontFamily: "$foundersGrotesk",
    fontWeight: "500",
    fontSize: "21px",
    lineHeight: "80%",
    letterSpacing: "0.01em",
    color: "$ebonyClay",
    "-webkit-font-smoothing": "antialiased"
  }),

  grid: css({
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    columnGap: "20px",
    rowGap: "20px",

    "@sm": {
      gridTemplateColumns: "repeat(2, 1fr)",
      columnGap: "14px",
      rowGap: "40px"
    },

    "@md": {
      gridTemplateColumns: "repeat(3, 1fr)",
      columnGap: "16px",
      rowGap: "48px"
    }
  }),

  ctaWrapper: css({
    display: "flex",
    justifyContent: "center",
    margin: "38px 0 0"
  }),

  cta: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "16px",
    letterSpacing: "0.05em",
    textTransform: "uppercase"
  })
};
