import React, { ReactElement } from "react";
import { SelectBoxOption } from "./SelectBoxOption";

export function SelectBoxGroup({ children }: SelectBoxGroup.Props) {
  React.Children.forEach(children, (child, index) => {
    if (child?.type !== SelectBoxOption) {
      let name = (child?.type as any)?.name ?? child?.type;
      if (name) name = `<${name}> component`;
      else name = `"${child}"`;
      throw `Error: Every SelectBox.Group child must be a SelectBox.Option component. ${name} found on position ${index}`;
    }
  });
  return <></>;
}

export namespace SelectBoxGroup {
  export interface Props {
    label?: string;
    children: ReactElement | Array<ReactElement>;
  }
}
