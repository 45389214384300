import React from "react";
import styles from "./RadioButtons.module.less";

export default class RadioButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowingAllOptions: false
    };
  }

  onClickOption(option, event) {
    if (!this.isOptionSelected(option)) {
      this.props.onChange(option.value, option, event);
    }
  }

  isOptionSelected(option) {
    if (typeof option.isSelectedCallback === "function") {
      return option.isSelectedCallback(this.props.value);
    }
    return option.value === this.props.value;
  }

  getOptions() {
    if (this.isHidingOptions()) {
      return this.props.options.slice(0, this.props.maxVisible);
    }
    return this.props.options;
  }

  isHidingOptions() {
    return (
      this.props.maxVisible &&
      this.props.maxVisible < this.props.options.length &&
      !this.state.isShowingAllOptions
    );
  }

  showMoreOptions() {
    this.setState({
      isShowingAllOptions: true
    });
  }

  render() {
    return (
      <div
        className={`${styles["radio-buttons"]} ${this.props.className || ""}`}
      >
        {/* LABEL */}
        {this.props.label && (
          <div className={styles["radio-buttons__body-label"]}>
            {this.props.label}
          </div>
        )}
        {/* NOTE */}
        {this.props.note && (
          <div className={styles["radio-buttons__body-note"]}>
            {this.props.note}
          </div>
        )}

        <div className={styles["radio-buttons__body"]}>
          {this.getOptions().map((option, index) => (
            <div
              key={index}
              className={`${styles["radio-buttons__button"]} ${
                option.disabled ? styles["radio-buttons__button--disabled"] : ""
              }`}
              onClick={
                option.disabled
                  ? undefined
                  : event => this.onClickOption(option, event)
              }
            >
              <span
                className={`
                  ${styles["radio-buttons__icon"]}
                  ${
                    styles[
                      `radio-buttons__icon--size-${this.props.size || "medium"}`
                    ]
                  }
                  ${this.isOptionSelected(option) &&
                    styles["radio-buttons__icon--selected"]}
                `}
              >
                {this.isOptionSelected(option) && (
                  <span
                    className={`
                      ${styles["radio-buttons__icon-dot"]}
                      ${
                        styles[
                          `radio-buttons__icon-dot--color-${this.props.color}`
                        ]
                      }
                      ${
                        styles[
                          `radio-buttons__icon-dot--size-${this.props.size ||
                            "medium"}`
                        ]
                      }
                    `}
                  />
                )}
              </span>

              <div className={styles["radio-buttons__text-wrap"]}>
                <div className={styles["radio-buttons__label"]}>
                  {option.label}
                </div>
                {option.description && (
                  <div className={styles["radio-buttons__description"]}>
                    {option.description}
                  </div>
                )}
                {option.body && this.isOptionSelected(option) && (
                  <div className={styles["radio-buttons__extra-option"]}>
                    {option.body()}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* SHOW MORE BUTTON */}
        {this.isHidingOptions() && (
          <div
            className={styles["radio-buttons__show-more"]}
            onClick={this.showMoreOptions.bind(this)}
          >
            Show more
          </div>
        )}

        {/* ERROR */}
        {this.props.error && (
          <div className={styles["radio-buttons__error"]}>
            {this.props.error}
          </div>
        )}
      </div>
    );
  }
}
