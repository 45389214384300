import React, { useContext } from "react";
import { PrimaryButton } from "../../components/PrimaryButton";
import SecondaryButton from "../../components/SecondaryButton";
import { CheckoutStepsContext } from "./CheckoutStepsContext";
import { css } from "../../sitches.config";

export function CheckoutStepsNavigation() {
  const {
    currentStep,
    steps,
    isFirstStep,
    isLastStep,
    onPrevious,
    onNext
  } = useContext(CheckoutStepsContext);

  const step = steps[currentStep] || {};
  const {
    disabled,
    nextButtonText = isLastStep() ? "finish" : "continue"
  } = step;

  return (
    <>
      <div className={`${styles.checkoutStepsNavigation()}`}>
        {!isFirstStep() && (
          <SecondaryButton
            className="mr-2"
            text="Back"
            size={{ "@initial": "small", "@sm": "large" }}
            color="emeraldish"
            uppercase
            width="auto"
            onClick={onPrevious}
          />
        )}
        <PrimaryButton
          text={nextButtonText}
          size={{ "@initial": "small", "@sm": "large" }}
          color="emeraldish"
          uppercase
          width="auto"
          disabled={disabled || false}
          onClick={onNext}
        />
      </div>
    </>
  );
}

export const styles = {
  checkoutStepsNavigation: css({
    display: "flex",
    flexDirection: "row"
  })
};
