import { css } from "../../sitches.config";

export const styles = {
  bar: css({
    position: "fixed",
    bottom: 0,
    backgroundColor: "white",
    width: "100%",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.06)",
    paddingY: "$3",
    zIndex: "$sticky"
  }),
  wrapper: css({
    display: "flex",
    flexDirection: "column",
    "@md": {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center"
    }
  }),

  valueWrapper: css({
    fontFamily: "$foundersGrotesk",
    fontWeight: 500,
    fontSize: "$16",
    lineHeight: "110%",
    letterSpacing: "0.04em",
    color: "$ebonyClay",
    "@md": {
      fontSize: "$18"
    }
  }),

  value: css({
    display: "inline",
    color: "$emeraldish",
    "&:before": {
      content: " "
    }
  }),
  content: css({
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "$2",
    "@md": {
      marginTop: 0
    }
  })
};
