import React from "react";
import { CheckoutCardItem } from "./CheckoutCardItem";

export function CheckoutSalesNotice() {
  return (
    <CheckoutCardItem
      title={
        <>
          Discounts, Gift Cards and Credit are not eligible for use during the
          Obby sale!
        </>
      }
    />
  );
}
