import React from "react";
import { bemModule } from "../../configs/bem";

import styles from "./TipsItem.module.less";

const cn = bemModule(styles, "tips-item");
export function TipsItem(props) {
  const { children } = props;

  return <li className={cn(null, ["mb-3 mb-last-0"])}>{children}</li>;
}
