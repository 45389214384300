import React from "react";
import Label from "../Label";
import { TextInput } from "../TextInput";
import styles from "./PaymentProductPersonalDetailsForm.module.less";
import Checkbox from "../Checkbox";

export function PaymentProductPersonalDetailsForm(props) {
  const {
    errors = {},
    touched = {},
    values = {},
    schoolName,
    marketingOptIn = false,
    onBlur,
    onChange
  } = props;

  return (
    <div className={styles["payment-product-personal-details-form"]}>
      <div className="row pb-3">
        <div className="col-12 col-smd-6 pt-3">
          <Label
            label="First name"
            error={touched.firstName && errors.firstName}
          >
            <TextInput
              name="firstName"
              onChange={onChange}
              onBlur={onBlur}
              value={values.firstName}
            />
          </Label>
        </div>
        <div className="col-12 col-smd-6 pt-3">
          <Label label="Surname" error={touched.lastName && errors.lastName}>
            <TextInput
              name="lastName"
              onChange={onChange}
              onBlur={onBlur}
              value={values.lastName}
            />
          </Label>
        </div>
        <div className="col-12 col-smd-6 pt-3">
          <Label label="Email" error={touched.email && errors.email}>
            <TextInput
              name="email"
              onChange={onChange}
              onBlur={onBlur}
              value={values.email}
            />
          </Label>
        </div>
        <div className="col-12 col-smd-6 pt-3">
          <Label
            label="Phone number"
            error={touched.phoneNumber && errors.phoneNumber}
          >
            <TextInput
              className="payment-personal-details-form__phone-number-input"
              name="phoneNumber"
              onChange={onChange}
              onBlur={onBlur}
              value={values.phoneNumber}
            />
          </Label>
        </div>
        {marketingOptIn && (
          <div className="col-12 pt-4">
            <Label>
              <Checkbox
                name="marketingOptIn"
                value={values.marketingOptIn}
                label={`Sign up to the${
                  schoolName ? ` ${schoolName}` : ""
                } newsletter to occasionally receive news, promotions and event information. You can unsubscribe at any time.`}
                onChange={onChange}
                onBlur={onBlur}
              />
            </Label>
          </div>
        )}
      </div>
    </div>
  );
}
