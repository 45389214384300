export const Timezones = {
  AFRICA_ABIDJAN: "Africa/Abidjan",
  AFRICA_ADDIS_ABABA: "Africa/Addis_Ababa",
  AFRICA_ALGIERS: "Africa/Algiers",
  AFRICA_BLANTYRE: "Africa/Blantyre",
  AFRICA_CAIRO: "Africa/Cairo",
  AFRICA_WINDHOEK: "Africa/Windhoek",

  AMERICA_ADAK: "America/Adak",
  AMERICA_ANCHORAGE: "America/Anchorage",
  AMERICA_ARAGUAINA: "America/Araguaina",
  AMERICA_ARGENTINA_BUENOS_AIRES: "America/Argentina/Buenos_Aires",
  AMERICA_BELIZE: "America/Belize",
  AMERICA_BOGOTA: "America/Bogota",
  AMERICA_CAMPO_GRANDE: "America/Campo_Grande",
  AMERICA_CANCUN: "America/Cancun",
  AMERICA_CHICAGO: "America/Chicago",
  AMERICA_CHIHUAHUA: "America/Chihuahua",
  AMERICA_DAWSON_CREEK: "America/Dawson_Creek",
  AMERICA_DENVER: "America/Denver",
  AMERICA_ENSENADA: "America/Ensenada",
  AMERICA_GLACE_BAY: "America/Glace_Bay",
  AMERICA_GODTHAB: "America/Godthab",
  AMERICA_GOOSE_BAy: "America/Goose_Bay",
  AMERICA_HAVANA: "America/Havana",
  AMERICA_LA_PAZ: "America/La_Paz",
  AMERICA_LOS_ANGELES: "America/Los_Angeles",
  AMERICA_MIQUELON: "America/Miquelon",
  AMERICA_MONTEVIDEO: "America/Montevideo",
  AMERICA_NEW_YORK: "America/New_York",
  AMERICA_NORONHA: "America/Noronha",
  AMERICA_SANTIAGO: "America/Santiago",
  AMERICA_SAO_PAULO: "America/Sao_Paulo",

  ASIA_ANADYR: "Asia/Anadyr",
  ASIA_BANGKOK: "Asia/Bangkok",
  ASIA_BEIRUT: "Asia/Beirut",
  ASIA_DAMASCUS: "Asia/Damascus",
  ASIA_DHAKA: "Asia/Dhaka",
  ASIA_DUBAI: "Asia/Dubai",
  ASIA_GAZA: "Asia/Gaza",
  ASIA_HONG_KONG: "Asia/Hong_Kong",
  ASIA_IRKUTSK: "Asia/Irkutsk",
  ASIA_JERUSALEM: "Asia/Jerusalem",
  ASIA_KRASNOYARSK: "Asia/Krasnoyarsk",
  ASIA_NOVOSIBIRSK: "Asia/Novosibirsk",
  ASIA_MAGADAM: "Asia/Magadan",
  ASIA_SEOUL: "Asia/Seoul",
  ASIA_TASHKENT: "Asia/Tashkent",
  ASIA_TEHRAN: "Asia/Tehran",
  ASIA_TOKYO: "Asia/Tokyo",
  ASIA_VLADIVOSTOK: "Asia/Vladivostok",
  ASIA_YAKUTSK: "Asia/Yakutsk",
  ASIA_YEKATERINBURG: "Asia/Yekaterinburg",
  ASIA_YEREVAN: "Asia/Yerevan",

  ATLANTIC_AZORES: "Atlantic/Azores",
  ATLANTIC_CAPE_VERDE: "Atlantic/Cape_Verde",
  ATLANTIC_STANLEY: "Atlantic/Stanley",

  AUSTRALIA_BRISBANE: "Australia/Brisbane",
  AUSTRALIA_HORBART: "Australia/Hobart",
  AUSTRALIA_PERTH: "Australia/Perth",

  CHILE_EASTER_ISLAND: "Chile/EasterIsland",

  ETC_GMT_MINUS_11: "Etc/GMT-11",
  ETC_GMT_MINUS_12: "Etc/GMT-12",
  ETC_GMT_PLUS_8: "Etc/GMT+8",
  ETC_GMT_PLUS_10: "Etc/GMT+10",

  EUROPE_AMSTERDAM: "Europe/Amsterdam",
  EUROPE_BELFAST: "Europe/Belfast",
  EUROPE_BELGRADE: "Europe/Belgrade",
  EUROPE_BRUSSELS: "Europe/Brussels",
  EUROPE_DUBLIN: "Europe/Dublin",
  EUROPE_LISBON: "Europe/Lisbon",
  EUROPE_LONDON: "Europe/London",
  EUROPE_MINSK: "Europe/Minsk",
  EUROPE_MOSCOW: "Europe/Moscow",

  PACIFIC_AUCKLAND: "Pacific/Auckland",
  PACIFIC_GAMBIER: "Pacific/Gambier",
  PACIFIC_KIRITIMATI: "Pacific/Kiritimati",
  PACIFIC_MIDWAY: "Pacific/Midway",
  PACIFIC_TONGATAPU: "Pacific/Tongatapu"
};
