export default function String__KebabCase(string) {
  return string
    .replace(/\s/g, "-")
    .replace(/([A-Z]+|[0-9]+)/g, match => {
      if (isNaN(match))
        return "-" + match.toLowerCase().replace(/([a-z])$/g, "-$1");
      return `-${match}-`;
    })
    .replace(/-+/g, "-");
}
