import React from "react";
import * as Icons from "../../components/Icons";
import Icon from "../../components/Icon";
import { css } from "../../sitches.config";
interface PopoutMenuItemProps {
  restricted?: boolean;
  onClick: () => void;
  children: any;
}

export function PopoutMenuItem({
  restricted = false,
  onClick,
  children
}: PopoutMenuItemProps) {
  return (
    <li className={styles.item({ isRestricted: restricted })} onClick={onClick}>
      {restricted && (
        <Icon icon={Icons.Lock} height={14} className={styles.icon()} />
      )}
      {children}
    </li>
  );
}

const styles = {
  item: css({
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    whiteSpace: "nowrap",
    fontFamily: "$foundersGrotesk",
    fontSize: 18,
    padding: 20,
    overflow: "hidden",
    cursor: "pointer",
    border: "solid $alto",
    borderWidth: "0 0 1px 0",

    "@sm": {
      borderWidth: "1px 1px 0 1px",
      fontSize: 16,
      padding: 15
    },

    "&:nth-child(2)": {
      "@sm": {
        borderTopRightRadius: 4,
        borderTopLeftRadius: 4
      }
    },

    "&:last-child": {
      borderBottomWidth: 1,
      borderBottomRightRadius: 1,
      borderBottomLeftRadius: 1
    },

    "&:hover": {
      backgroundColor: "$pampas"
    },

    variants: {
      isRestricted: {
        true: {
          color: "$silver"
        }
      }
    }
  }),

  icon: css({
    marginRight: "0.25rem"
  })
};
