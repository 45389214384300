import { css } from "../../sitches.config";

export const styles = {
  widgetSchoolBanner: css({}),
  imageWrapper: css({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center"
  }),
  image: css({
    width: "100%",
    borderRadius: "4px"
  }),
  imageSkeleton: css({
    height: "100%"
  }),
  info: css({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    padding: "20px 0",
    color: "$ebonyClay",

    "@smd": {
      padding: "0 20px"
    }
  }),
  title: css({
    fontFamily: "$foundersGroteskCondensed",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "36px",
    lineHeight: "90%",
    textTransform: "uppercase",
    margin: "0 0 15px",

    "@smd": {
      textAlign: "center"
    },
    "@md": {
      fontSize: "48px"
    },
    "@lg": {
      fontSize: "56px"
    }
  }),
  description: css({
    fontFamily: "$foundersGrotesk",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "144%",
    letterSpacing: "0.01em",
    "@smd": {
      textAlign: "center"
    },
    "@md": {
      fontSize: "18px"
    }
  })
};
