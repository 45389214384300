import React, { useRef, useState } from "react";

import { styles } from "./RadioInputRadio.styles";
import Icon from "../../../components/Icon";
import * as Icons from "../../../components/Icons";

export function RadioInputRadio({
  accent = "brightTeal",
  disabled,
  name,
  onBlur,
  onChange,
  size = "medium",
  value,
  checked
}: Radio.Props) {
  const inputRef = useRef<HTMLInputElement>(null);

  const tickHeight = {
    medium: 12,
    large: 20
  };

  function onInputChange() {
    inputRef.current && inputRef.current.focus();
    onChange && onChange(value, name);
  }

  return (
    <div>
      <input
        ref={inputRef}
        name={name}
        className={styles.input()}
        type="radio"
        checked={checked}
        value={value}
        onChange={onInputChange}
        onBlur={() => onBlur && onBlur(name)}
        disabled={disabled}
      />
      <div
        className={styles.radio({
          size,
          checked
        })}
      >
        {checked && (
          <div
            className={styles.tickWrapper({
              css: { backgroundColor: `$${accent}`, borderColor: `$${accent}` }
            })}
          >
            <Icon
              className={styles.tick()}
              icon={Icons.NarrowTick}
              height={tickHeight[size]}
            />
          </div>
        )}
      </div>
    </div>
  );
}

namespace Radio {
  export interface Props {
    accent?: string;
    disabled?: boolean;
    checked?: boolean;
    name?: string;
    onBlur?: (name?: string) => void;
    onChange?: (value: any, name?: string) => void;
    size?: "medium" | "large";
    value: any;
  }
}
