import * as Yup from "yup";
export function ContactFormSchema() {
  const defaults = {
    email: "",
    message: ""
  };

  return Yup.object({
    email: Yup.string()
      .label("Email")
      .email()
      .required()
      .default(defaults.email),
    message: Yup.string()
      .label("Message")
      .min(30)
      .required()
      .default(defaults.message)
  });
}
