import { MutableRefObject, useEffect, useRef } from "react";

export function useClickOutside<T extends HTMLElement = HTMLElement>(
  handler: () => void
) {
  const ref = useRef<T>(null);
  useEffect(() => {
    let startedInside = false;
    let startedWhenMounted = false;

    function onClick(event: any) {
      // Do nothing if `mousedown` or `touchstart` started inside ref element
      if (startedInside || !startedWhenMounted) return;
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) return;
      handler();
    }

    function onMouseDown(event: any) {
      startedWhenMounted = !!ref.current;
      startedInside = ref.current ? ref.current.contains(event.target) : false;
    }

    document.addEventListener("mousedown", onMouseDown);
    document.addEventListener("touchstart", onMouseDown);
    document.addEventListener("click", onClick);

    return () => {
      document.removeEventListener("mousedown", onMouseDown);
      document.removeEventListener("touchstart", onMouseDown);
      document.removeEventListener("click", onClick);
    };
  }, [ref, handler]);

  return ref;
}
