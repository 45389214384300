import * as Yup from "yup";
export function EditTutorNotesFormSchema() {
  const defaults = {
    notes: ""
  };

  return Yup.object({
    notes: Yup.string()
      .label("Notes")
      .default(defaults.notes)
  });
}
