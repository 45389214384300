import { css } from "../../sitches.config";

export const ratingStarsStyles = {
  container: css({
    display: "inline-flex",
    alignItems: "center",
    margin: 0
  }),

  star: css({
    display: "flex",

    "&:first-child": {
      marginLeft: 0
    },

    "&:last-child": {
      marginRight: 0
    },

    variants: {
      size: {
        small: {
          marginX: 1
        },

        large: {
          marginX: 1
        },

        huge: {
          marginX: 2
        }
      },

      color: {
        sun: {
          "& path": {
            fill: "$sun"
          },

          "& polygon": {
            fill: "$sun"
          }
        },
        emeraldish: {
          "& path": {
            fill: "$emeraldish"
          },

          "& polygon": {
            fill: "$emeraldish"
          }
        },
        robbinsEggBlue: {
          "& path": {
            fill: "$robbinsEggBlue"
          },

          "& polygon": {
            fill: "$robbinsEggBlue"
          }
        },
        camelot: {
          "& path": {
            fill: "$camelot"
          },

          "& polygon": {
            fill: "$camelot"
          }
        }
      }
    }
  }),

  score: css({
    fontFamily: "$foundersGrotesk",
    fontWeight: 600,
    lineHeight: 1,
    paddingLeft: 5,
    letterSpacing: 0.7,

    variants: {
      size: {
        small: {
          fontSize: "$xxsmParagraph"
        },

        large: {
          fontSize: "$smParagraph"
        },

        huge: {
          fontSize: "$mParagraph"
        }
      },

      color: {
        sun: {
          color: "$sun"
        },

        emeraldish: {
          color: "$emeraldish"
        },

        robbinsEggBlue: {
          color: "$robbinsEggBlue"
        },

        camelot: {
          color: "$camelot"
        }
      }
    }
  }),

  reviewCount: css({
    fontFamily: "$foundersGrotesk",
    fontWeight: 600,
    lineHeight: 1,
    color: "$silverChalice",
    letterSpacing: 0.7,
    paddingLeft: 10,

    variants: {
      size: {
        small: {
          fontSize: "$xxsmParagraph"
        },

        large: {
          fontSize: "$smParagraph"
        },

        huge: {
          fontSize: "$mParagraph"
        }
      }
    }
  }),

  label: css({
    fontFamily: "$foundersGrotesk",
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: 0.7,
    paddingLeft: 5,

    variants: {
      size: {
        small: {
          fontSize: "$xxsmParagraph"
        },

        large: {
          fontSize: "$smParagraph"
        },

        huge: {
          fontSize: "$mParagraph"
        }
      },

      color: {
        sun: {
          color: "$sun"
        },

        emeraldish: {
          color: "$emeraldish"
        },

        robbinsEggBlue: {
          color: "$robbinsEggBlue"
        },

        camelot: {
          color: "$camelot"
        }
      }
    }
  })
};
