import React from "react";
import _ from "lodash";
import { Label } from "../../components/Label";
import { ProductSelectionPopupContainer } from "./ProductSelectionPopup.container";
import { ProductSelectionRow } from "../../components/ProductSelectionRow";
import { GiftForm } from "../../forms/GiftForm";
import { Discount__DiscountedPrice } from "@obby/lib";
import { RunningDaysLabels } from "@obby/constants";
import { RadioInput, TextArea } from "../../index";
export function SelectionStep() {
  const {
    product,
    postages,
    actions,
    errors,
    touched,
    values
  } = ProductSelectionPopupContainer.useContainer();

  return (
    <div className="selection-step">
      <Label label="Product Selection" condensed>
        <ProductSelectionRow
          discount={
            product.discount
              ? Discount__DiscountedPrice(product.discount, product.price)
              : undefined
          }
          title="How many products do you want?"
          description="All products will be delivered to the same address (we ask for this next)"
          price={product.price}
          currency={product.currency}
          options={_.range(1, product.stock + 1, 1).map(value => ({
            label: value,
            value
          }))}
          value={values.quantity}
          onChange={quantity => actions.onChange(quantity, "quantity")}
        />
      </Label>
      <Label label="Select your delivery preference" condensed>
        {postages.map(postage => (
          <div className="mb-4 mb-last-0" key={postage._id}>
            <ProductSelectionRow
              title={postage.name}
              description={`This delivery will take between ${
                postage.deliveryTime.from
              }-${postage.deliveryTime.to} ${RunningDaysLabels[
                postage.runningDays
              ].toLowerCase()}`}
              price={postage.price}
              currency={product.currency}
              selectable
              selected={postage._id === values.postageId}
              onClick={() => actions.onChange(postage._id, "postageId")}
            />
          </div>
        ))}
      </Label>
      {product.gifting.showGifting && (
        <Label
          label={
            product.gifting?.doTeacherWraps
              ? "Would you like to give this as a gift? This will include a personalised message to your recipient and gift wrapping of the product itself."
              : `Would you like to give this as a gift? This will include a
                      personalised message to your recipient.`
          }
          condensed
        >
          <RadioInput
            value={values.isGift}
            name="isGift"
            onChange={actions.onChange}
            size="large"
          >
            <RadioInput.Option value={false}>No</RadioInput.Option>
            <RadioInput.Option value={true}>Yes</RadioInput.Option>
          </RadioInput>
        </Label>
      )}

      {values.isGift && (
        <Label
          label="Please leave a message for your gift recipient below"
          condensed
        >
          <GiftForm
            values={values.giftingDetails}
            touched={touched?.giftingDetails}
            errors={errors?.giftingDetails}
            onChange={(value, name) =>
              actions.onChange(value, `giftingDetails.${name}`)
            }
            onBlur={name => actions.onBlur(`giftingDetails.${name}`)}
          />
        </Label>
      )}
    </div>
  );
}
