import React from "react";
import PropTypes from "prop-types";

import TeacherCard from "../TeacherCard";

export default function ClassTeacherSection({
  teacherName,
  teacherUrl,
  teacherAvatar,
  teacherDescription
}) {
  return (
    <div className="class-teacher-section">
      <TeacherCard
        name={teacherName}
        description={teacherDescription}
        url={teacherUrl}
        imageUrl={teacherAvatar}
      />
    </div>
  );
}
