import React from "react";

import Icon from "../Icon";
import styles from "./ShareButton.module.less";

export function ShareButton(props) {
  const {
    onClick = () => {},
    icon,
    url,
    label,
    popupWidth = 700,
    popupHeight = 400,
    backgroundColor
  } = props;

  function onButtonClick(e) {
    onClick(e);
    if (e.isDefaultPrevented()) return;
    popupCenter(url, popupWidth, popupHeight);
  }

  return (
    <button
      className={styles["share-button"]}
      onClick={onButtonClick}
      style={{ backgroundColor: backgroundColor }}
    >
      {icon && (
        <Icon
          className={styles["share-button__icon"]}
          icon={icon}
          height={20}
        />
      )}
      <span className={styles["share-button__label"]}>{label}</span>
    </button>
  );
}

ShareButton.propTypes = {};

function popupCenter(url, w, h) {
  // Fixes dual-screen position                         Most browsers      Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : screen.left;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : screen.top;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const left = width / 2 - w / 2 + dualScreenLeft;
  const top = height / 2 - h / 2 + dualScreenTop;
  const newWindow = window.open(
    url,
    "",
    "scrollbars=yes, width=" +
      w +
      ", height=" +
      h +
      ", top=" +
      top +
      ", left=" +
      left
  );

  // Puts focus on the newWindow
  if (window.focus) {
    newWindow.focus();
  }
}
