import Image__ResizedImage from "./Image__ResizedImage";

export default function ImageUrl__ResizedImageUrl(options, url) {
  let imageResized = Image__ResizedImage(options, { url: url });
  if (imageResized.url) {
    return imageResized.url;
  } else {
    return imageResized;
  }
}
