import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import CardBackground from "../CardBackground";
import { ExpandableText } from "../ExpandableText";
import * as Icons from "../Icons";
import Icon from "../Icon";
import styles from "./AboutUsCard.module.less";

export function AboutUsCard(props) {
  const {
    title,
    imageUrl,
    description,
    maxNumberOfWords = 100,
    facebook,
    instagram,
    twitter
  } = props;

  return (
    <div className={styles["about-us-card"]}>
      <CardBackground>
        <div className="row">
          <div className="col-12 col-smd-6">
            <div
              className={styles["about-us-card__thumbnail"]}
              style={{ backgroundImage: `url(${imageUrl})` }}
            />
          </div>
          <div className="col-12 col-smd-6">
            <div className={styles["about-us-card__content"]}>
              <div className={styles["about-us-card__title"]}>{title}</div>
              <ExpandableText maxWords={maxNumberOfWords}>
                {description}
              </ExpandableText>
              <div className="mt-4">
                {facebook && (
                  <SocialLink url={facebook} icon={Icons.Facebook} />
                )}
                {instagram && (
                  <SocialLink url={instagram} icon={Icons.Instagram} />
                )}
                {twitter && <SocialLink url={twitter} icon={Icons.Twitter} />}
              </div>
            </div>
          </div>
        </div>
      </CardBackground>
    </div>
  );
}

function SocialLink(props) {
  const { url, icon } = props;
  return (
    <a className="about-us-card__social-link mr-2" href={url} target="_blank">
      <Icon
        className="about-us-card__social-link-icon"
        icon={icon}
        height={14}
      />
    </a>
  );
}

AboutUsCard.propTypes = {
  title: PropTypes.string,
  pill: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string
};
