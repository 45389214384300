import React from "react";
import { Label } from "../../components/Label";
import { TextArea } from "../../components/TextArea";
import { TextInput } from "../../components/TextInput";
import { GiftFormSchema } from "./GiftForm.schema";

export function GiftForm({ errors, touched, values, onChange, onBlur }) {
  return (
    <div className="gift-form">
      <Label label="To" error={touched?.to && errors?.to}>
        <TextInput
          name="to"
          onBlur={onBlur}
          onChange={onChange}
          value={values.to}
          placeholder=""
        />
      </Label>
      <Label label="From" error={touched?.from && errors?.from}>
        <TextInput
          name="from"
          onBlur={onBlur}
          onChange={onChange}
          value={values.from}
          placeholder=""
        />
      </Label>
      <Label label="Message" error={touched?.message && errors?.message}>
        <TextArea
          name="message"
          placeholder="Please enter your message here"
          value={values.message}
          onBlur={onBlur}
          onChange={onChange}
        />
      </Label>
    </div>
  );
}
GiftForm.Schema = GiftFormSchema;
