import React from "react";
import ReactMarkdown from "react-markdown";

const MarkdownLinkRenderer = (className) => {
	return (props) => {
		return (
			<a className={className} href={props.href} target="_blank">
				{props.children}
			</a>
		);
	};
};

const MarkdownParagraphRenderer = (className) => {
	return (props) => {
		return <p className={className}>{props.children}</p>;
	};
};

const MarkdownListRenderer = (className) => {
	return (props) => {
		return <ul className={className}>{props.children}</ul>;
	};
};

const MarkdownListItemRenderer = (className) => {
	return (props) => {
		return <li className={className}>{props.children}</li>;
	};
};

const ThemedMarkdownText = (props) => {
	const { text, classNames = {} } = props;
	return (
		<ReactMarkdown
			source={text}
			renderers={{
				link: MarkdownLinkRenderer(classNames.link),
				paragraph: MarkdownParagraphRenderer(classNames.paragraph),
				list: MarkdownListRenderer(classNames.list),
				listItem: MarkdownListItemRenderer(classNames.listItem),
			}}
		/>
	);
};

export default ThemedMarkdownText;
