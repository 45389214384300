import { css } from "../../sitches.config";

export const styles = {
  reviewsSlider: css({
    "& .carousel": {
      textShadow: "$card",
      ".slider": {
        alignItems: "flex-start"
      },

      ".slide": {
        backgroundColor: "transparent",
        height: "0",
        "&.selected": {
          height: "auto"
        }
      },

      ".control-arrow": {
        display: "none",
        padding: "0",
        opacity: "1",
        bottom: "auto",
        top: "38px",

        "@smd": {
          display: "block"
        },
        "&::before": {
          display: "none !important"
        },
        "&:hover": {
          backgroundColor: "transparent !important"
        },

        path: {
          fill: "$robbinsEggBlue"
        }
      }
    }
  }),
  review: css({
    textAlign: "center",
    color: "$ebonyClay",
    borderRadius: "20px",
    padding: "1rem",

    "@smd": {
      margin: "0 60px",
      textAlign: "center",
      backgroundColor: "transparent"
    },
    "@lg": {
      margin: "0 70px"
    },

    variants: {
      mobile: {
        true: {
          backgroundColor: "$white"
        }
      }
    }
  }),

  reviewContent: css({
    fontFamily: "$foundersGroteskCondensed",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "21px",
    textTransform: "uppercase",
    lineHeight: "120%",

    "@smd": {
      fontSize: "28px",
      fontWeight: "bold",
      fontFamily: "$foundersGroteskCondensed",
      textTransform: "uppercase"
    },
    "@lg": {
      fontSize: "38px"
    },

    variants: {
      mobile: {
        true: {
          fontFamily: "$foundersGrotesk",
          fontSize: "16px",
          textTransform: "none",
          fontWeight: "400"
        }
      }
    }
  }),

  reviewFooter: css({
    marginTop: "30px",
    justifyContent: "center",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",

    "@smd": {
      marginTop: "40px",
      justifyContent: "center"
    }
  }),

  reviewReviewer: css({
    display: "inline-block",
    fontFamily: "$foundersGrotesk",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "1",
    letterSpacing: "0.1em",
    textTransform: "uppercase",
    margin: "0 6px 6px",

    variants: {
      mobile: {
        true: {
          display: "flex !important",
          flexDirection: "column",
          textTransform: "capitalize !important",
          fontSize: "16px !important"
        }
      }
    }
  }),

  reviewRating: css({
    margin: "0 6px 6px"
  }),

  reviewTarget: css({
    color: "$ebonyClay",
    "&:hover, &:visited, &:active, &:focus": {
      color: "$ebonyClay",
      textDecoration: "none"
    },
    "&[href]:hover": {
      textDecoration: "underline"
    },

    variants: {
      mobile: {
        true: {
          textTransform: "capitalize",
          marginTop: "0.25rem",
          filter: "opacity(0.6)",
          fontWeight: "400",
          letterSpacing: "0.25px"
        }
      }
    }
  })
};
