import React from "react";
import { Indexing } from "@obby/components";
import { styles } from "./TableHeader.styles";

export function TableHeader({ children }: React.PropsWithChildren<Props>) {
  return (
    <thead className={styles.tableHeader()}>
      <tr>
        <Indexing>{children}</Indexing>
      </tr>
    </thead>
  );
}

interface Props {}
