import React from "react";
import _ from "lodash";

import { Label } from "../../components/Label";
import { TextArea } from "../../inputs/TextArea";
import { ProductSelectionRow } from "../../components/ProductSelectionRow";
import { CourseCheckoutContainer } from "./CourseCheckout.container";
import {
  Discount__DiscountedPrice,
  GetTicketTypePriceBasedOnSession
} from "@obby/lib";
import { SideNote } from "../../components/SideNote";

export function TicketTypesStep(props) {
  const {
    actions,
    availability,
    currency,
    discount,
    isBundleEnabled,
    isTrialEnabled,
    selectedSession,
    ticketTypes,
    userInputs,
    values
  } = CourseCheckoutContainer.useContainer();

  function getMaxNumberOfTickets(index, availability) {
    const sessionTicketType = selectedSession.ticketTypes.find(
      sessionTicketType =>
        sessionTicketType.ticketTypeId === ticketTypes[index]._id
    );
    // calculate how many seats are available for this specific ticket. remember the currentSold is the number of seats sold
    // and not the number of tickets sold
    const ticketTypeAvailability =
      sessionTicketType.maxLimit * ticketTypes[index].numberOfGuestsPerTicket -
      sessionTicketType.currentSold;
    // calculate how many seats are available in this session
    const sessionAvailability =
      availability +
      values.tickets[index] * ticketTypes[index].numberOfGuestsPerTicket;
    // we want the minor value between both
    const availableSeats = Math.min(
      ticketTypeAvailability,
      sessionAvailability
    );
    // finally divide by the number of guests per ticket for this ticket
    return Math.floor(
      availableSeats / ticketTypes[index].numberOfGuestsPerTicket
    );
  }

  return (
    <div className="ticket-types-step">
      <Label label="Choose your tickets" condensed className="mb-3" />
      {ticketTypes.map((ticketType, index) => {
        const maxNumberOfTickets = getMaxNumberOfTickets(index, availability);
        // is sold out only if the max number of tickets is zero when there are no any other ticket selected
        const isSoldOut =
          !ticketType.isInTimeConstraint ||
          getMaxNumberOfTickets(index, selectedSession.availability) === 0;
        const tickets = _.range(0, maxNumberOfTickets + 1);
        let selectedPrice = GetTicketTypePriceBasedOnSession(
          ticketType,
          selectedSession
        );
        return (
          <div key={ticketType._id} className="mb-4">
            <ProductSelectionRow
              title={ticketType.name}
              description={ticketType.description}
              price={selectedPrice}
              currency={currency}
              discount={
                discount
                  ? Discount__DiscountedPrice(discount, ticketType.price)
                  : undefined
              }
              options={tickets.map(ticket => ({
                label: ticket,
                value: ticket
              }))}
              value={values.tickets[index]}
              onChange={value => actions.onTicketChange(index, value)}
              disabled={
                !ticketType.isInTimeConstraint || maxNumberOfTickets === 0
              }
              soldOut={isSoldOut}
            />
          </div>
        );
      })}
      {isBundleEnabled && (
        <div className="mb-4">
          <SideNote
            title="Bundle tickets available"
            note="Book multiple classes at once for a bigger saving!"
            link="View tickets"
            onLinkClick={actions.onViewBundlesClick}
            inline
          />
        </div>
      )}

      {isTrialEnabled && (
        <div className="mb-4">
          <SideNote
            title="Trial tickets available"
            note="Book a trial session to get a taster of the full term"
            link="View trial"
            onLinkClick={actions.onViewTrialClick}
            inline
          />
        </div>
      )}
    </div>
  );
}
