import React from "react";
import { bem } from "../../configs/bem";

import { styles } from "./SideNote.styles";

const cn = bem("side-note");
export function SideNote({
  title,
  note,
  link,
  onLinkClick,
  accent = "emeraldish",
  inline = false
}) {
  return (
    <div
      className={styles.component({
        inline,
        className: cn(),
        css: {
          borderColor: `$${accent}`
        }
      })}
    >
      <div>
        <div className={styles.title({ className: cn("title") })}>{title}</div>
        <div className={styles.note({ className: cn("note") })}>{note}</div>
      </div>
      {link && (
        <span
          className={styles.link({ className: cn("link") })}
          onClick={onLinkClick}
        >
          {link}
        </span>
      )}
    </div>
  );
}
