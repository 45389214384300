import React, { ReactElement, useEffect, useState } from "react";

import { TabsNavTab } from "./TabsNavTab";
import { TabsNavContext } from "./TabsNavContext";
import { styles } from "./TabsNav.styles";

export function TabsNav({ selected = 0, children }: Props) {
  const [selectedTab, setSelectedTab] = useState(selected);

  function onSelectTab(id: string | number) {
    setSelectedTab(id);
  }

  useEffect(() => {
    setSelectedTab(selected);
  }, [selected]);

  return (
    <div className={styles.tabsNav()}>
      {React.Children.map(children, (child, index) => {
        if (child?.type !== TabsNavTab) {
          let name = (child?.type as any)?.name ?? child?.type;
          if (name) name = `<${name}> component`;
          else name = `"${child}"`;
          throw `Error: Every TabsNav child must be a TabsNav.Tab component. ${name} found on position ${index}`;
        }

        const { id = index } = child.props;

        return (
          <TabsNavContext.Provider
            value={{ id, onSelectTab, selected: id === selectedTab }}
          >
            {child}
          </TabsNavContext.Provider>
        );
      })}
      <div className={styles.horizonte()} />
    </div>
  );
}
TabsNav.Tab = TabsNavTab;

interface Props {
  selected?: number | string;
  children: ReactElement | Array<ReactElement>;
}
