import { Timezones } from "./Timezones";

export const TimezonesNames = {
  [Timezones.AFRICA_ABIDJAN]: "Monrovia, Reykjavik",
  [Timezones.AFRICA_ADDIS_ABABA]: "Nairobi",
  [Timezones.AFRICA_ALGIERS]: "West Central Africa",
  [Timezones.AFRICA_BLANTYRE]: "Harare, Pretoria",
  [Timezones.AFRICA_CAIRO]: "Cairo",
  [Timezones.AFRICA_WINDHOEK]: "Windhoek",

  [Timezones.AMERICA_ADAK]: "Hawaii-Aleutian",
  [Timezones.AMERICA_ANCHORAGE]: "Alaska",
  [Timezones.AMERICA_ARAGUAINA]: "UTC-3",
  [Timezones.AMERICA_ARGENTINA_BUENOS_AIRES]: "Buenos Aires",
  [Timezones.AMERICA_BELIZE]: "Saskatchewan, Central America",
  [Timezones.AMERICA_BOGOTA]: "Bogota, Lima, Quito, Rio Branco",
  [Timezones.AMERICA_CAMPO_GRANDE]: "Brazil",
  [Timezones.AMERICA_CANCUN]: "Guadalajara, Mexico City, Monterrey",
  [Timezones.AMERICA_CHICAGO]: "Central Time (US & Canada)",
  [Timezones.AMERICA_CHIHUAHUA]: "Chihuahua, La Paz, Mazatlan",
  [Timezones.AMERICA_DAWSON_CREEK]: "Arizona",
  [Timezones.AMERICA_DENVER]: "Mountain Time (US & Canada)",
  [Timezones.AMERICA_ENSENADA]: "Tijuana, Baja California",
  [Timezones.AMERICA_GLACE_BAY]: "Atlantic Time (Canada)",
  [Timezones.AMERICA_GODTHAB]: "Greenland",
  [Timezones.AMERICA_GOOSE_BAy]: "Atlantic Time (Goose Bay)",
  [Timezones.AMERICA_HAVANA]: "Cuba",
  [Timezones.AMERICA_LA_PAZ]: "La Paz",
  [Timezones.AMERICA_LOS_ANGELES]: "Pacific Time (US & Canada)",
  [Timezones.AMERICA_MIQUELON]: "Miquelon, St. Pierre",
  [Timezones.AMERICA_MONTEVIDEO]: "Montevideo",
  [Timezones.AMERICA_NEW_YORK]: "Eastern Time (US & Canada)",
  [Timezones.AMERICA_NORONHA]: "Mid-Atlantic",
  [Timezones.AMERICA_SANTIAGO]: "Santiago",
  [Timezones.AMERICA_SAO_PAULO]: "Brasilia",

  [Timezones.ASIA_ANADYR]: "Anadyr, Kamchatka",
  [Timezones.ASIA_BANGKOK]: "Bangkok, Hanoi, Jakarta",
  [Timezones.ASIA_BEIRUT]: "Beirut",
  [Timezones.ASIA_DAMASCUS]: "Syria",
  [Timezones.ASIA_DHAKA]: "Astana, Dhaka",
  [Timezones.ASIA_DUBAI]: "Abu Dhabi, Muscat",
  [Timezones.ASIA_GAZA]: "Gaza",
  [Timezones.ASIA_HONG_KONG]: "Beijing, Chongqing, Hong Kong, Urumqi",
  [Timezones.ASIA_IRKUTSK]: "Irkutsk, Ulaan Bataar",
  [Timezones.ASIA_JERUSALEM]: "Jerusalem",
  [Timezones.ASIA_KRASNOYARSK]: "Krasnoyarsk",
  [Timezones.ASIA_NOVOSIBIRSK]: "Novosibirsk",
  [Timezones.ASIA_MAGADAM]: "Magadan",
  [Timezones.ASIA_SEOUL]: "Seoul",
  [Timezones.ASIA_TASHKENT]: "Tashkent",
  [Timezones.ASIA_TEHRAN]: "Tehran",
  [Timezones.ASIA_TOKYO]: "Osaka, Sapporo, Tokyo",
  [Timezones.ASIA_VLADIVOSTOK]: "Vladivostok",
  [Timezones.ASIA_YAKUTSK]: "Yakutsk",
  [Timezones.ASIA_YEKATERINBURG]: "Ekaterinburg",
  [Timezones.ASIA_YEREVAN]: "Yerevan",

  [Timezones.ATLANTIC_AZORES]: "Azores",
  [Timezones.ATLANTIC_CAPE_VERDE]: "Cape Verde Is.",
  [Timezones.ATLANTIC_STANLEY]: "Faukland Islands",

  [Timezones.AUSTRALIA_BRISBANE]: "Brisbane",
  [Timezones.AUSTRALIA_HORBART]: "Hobart",
  [Timezones.AUSTRALIA_PERTH]: "Perth",

  [Timezones.CHILE_EASTER_ISLAND]: "Easter Island",

  [Timezones.ETC_GMT_PLUS_8]: "Pitcairn Islands",
  [Timezones.ETC_GMT_PLUS_10]: "Hawaii",
  [Timezones.ETC_GMT_MINUS_11]: "Solomon Is., New Caledonia",
  [Timezones.ETC_GMT_MINUS_12]: "Fiji, Kamchatka, Marshall Is.",

  [Timezones.EUROPE_AMSTERDAM]:
    "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
  [Timezones.EUROPE_BELFAST]: "Greenwich Mean Time : Belfast",
  [Timezones.EUROPE_BELGRADE]:
    "Belgrade, Bratislava, Budapest, Ljubljana, Prague",
  [Timezones.EUROPE_BRUSSELS]: "Brussels, Copenhagen, Madrid, Paris",
  [Timezones.EUROPE_DUBLIN]: "Greenwich Mean Time : Dublin",
  [Timezones.EUROPE_LISBON]: "Greenwich Mean Time : Lisbon",
  [Timezones.EUROPE_LONDON]: "Greenwich Mean Time : London",
  [Timezones.EUROPE_MINSK]: "Minsk",
  [Timezones.EUROPE_MOSCOW]: "Moscow, St. Petersburg, Volgograd",

  [Timezones.PACIFIC_AUCKLAND]: "Auckland, Wellington",
  [Timezones.PACIFIC_GAMBIER]: "Gambier Islands",
  [Timezones.PACIFIC_KIRITIMATI]: "Kiritimati",
  [Timezones.PACIFIC_MIDWAY]: "Midway Island, Samoa",
  [Timezones.PACIFIC_TONGATAPU]: "Nuku'alofa"
};
