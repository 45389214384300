import { Timezones } from "@obby/constants";
import { UTCDate__FormattedDate } from "./UTCDate__FormattedDate";

export function Session__DisplayOffsetName(
  session: any,
  timezone: string = Timezones.EUROPE_LONDON,
  forceTimezone: boolean = false
) {
  const { startDateTime, isOnline } = session;

  if (isOnline && !forceTimezone)
    timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? timezone;

  return UTCDate__FormattedDate(startDateTime, timezone, "(z)");
}
