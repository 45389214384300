import { css } from "../../sitches.config";

export const styles = {
  toggle: css({
    backgroundColor: "$white",
    border: "solid 1px $mercury",
    borderRadius: "$1",
    display: "inline-flex",
    alignItems: "center",
    letterSpacing: "0.3px",
    fontFamily: "$foundersGrotesk",
    variants: {
      size: {
        tiny: {
          height: "1.75rem",
          paddingX: "0.75rem",
          fontSize: "$16"
        },
        small: {
          paddingX: "$3",
          fontSize: "$18",
          height: "2.1875rem"
        },
        medium: {
          paddingX: "$3",
          fontSize: "$18",
          height: "2.5rem"
        },
        large: {
          paddingX: "$4",
          fontSize: "$18",
          height: "3rem"
          // borderRadius: "1.5rem"
        }
      },
      selected: {
        true: {
          backgroundColor: "$camelot",
          borderColor: "$camelot",
          color: "$white"
        }
      }
    }
  }),
  container: css({
    backgroundColor: "$white",
    borderRadius: "$1",
    border: "solid 1px $mercury",
    padding: "$2 $3 $3",
    "@sm": {
      width: "400px"
    }
  }),
  list: css({
    listStyle: "none",
    margin: 0,
    padding: "0",
    maxHeight: "404px",
    overflowY: "scroll"
  }),
  listItem: css({
    margin: "$2 0"
  }),
  buttons: css({
    paddingTop: "$3",
    display: "flex",
    justifyContent: "space-between"
  })
};
