import React from "react";
import PropTypes from "prop-types";

import styles from "./ShapeWithTextSticker.module.less";

const ShapeWithTextSticker = ({
  title,
  description,
  color,
  backgroundColor,
  shape
}) => {
  return (
    <div
      className={`
				${styles["shape-with-text-sticker"]}
        ${styles[`shape-with-text-sticker--${shape}`]}
        ${styles[`shape-with-text-sticker--bg-${backgroundColor}`]}
			`}
    >
      <span
        className={`
					${styles[`shape-with-text-sticker__title`]}
          ${styles[`shape-with-text-sticker__title--${color}`]}
				`}
      >
        {title}
      </span>
      <span
        className={`
        ${styles[`shape-with-text-sticker__description`]}
        ${styles[`shape-with-text-sticker__description--${color}`]}
			`}
      >
        {description}
      </span>
    </div>
  );
};

ShapeWithTextSticker.shapes = {
  BOX: "box",
  CIRCLE: "circle"
};

ShapeWithTextSticker.defaultProps = {
  shape: ShapeWithTextSticker.shapes.BOX,
  color: "ebonyClay",
  backgroundColor: "sun"
};

ShapeWithTextSticker.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  shape: PropTypes.oneOf([
    ShapeWithTextSticker.shapes.BOX,
    ShapeWithTextSticker.shapes.CIRCLE
  ])
};

export default ShapeWithTextSticker;
