import { css, withBem } from "../../../sitches.config";

export const styles = withBem("personal-details-step", {
  personalDetailsStep: css({}),
  box: css({
    position: "relative"
  }),
  removeButton: css({
    position: "absolute",
    top: "$3",
    right: "$3"
  })
});
