import { css } from "../../sitches.config";

export const styles = {
  bookingReceiptCard: css({
    padding: "1.9rem 1.6rem",
    borderRadius: "4px",
    borderTop: "solid 0.5rem",
    backgroundColor: "$white",

    "&__label, &__link ": {
      color: "$gray",
      lineHeight: "134%",
      letterSpacing: "0.04em",
      fontSize: "$xsmParagraph",
      fontFamily: "$foundersGrotesk"
    },
    "&__link": {
      color: "$gray !important",
      textDecoration: "underline !important",
      textDecorationColor: "$robbinsEggBlue !important",

      "&:active, &:hover, &:focus, &:visited": {
        color: "$gray !important",
        textDecoration: "underline !important",
        textDecorationColor: "$robbinsEggBlue !important"
      }
    }
  }),

  header: css({
    display: "flex",
    paddingBottom: "$20",
    justifyContent: "space-between",
    borderBottom: "1px solid rgba(40, 40, 60, 0.1)"
  }),

  headerContent: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginRight: "8px"
  }),

  title: css({
    fontSize: "$24",
    lineHeight: "100%",
    fontWeight: "bold",
    fontFamily: "$foundersGroteskCondensed",
    textTransform: "uppercase",
    color: "$ebonyClay",
    marginBottom: "12px"
  }),

  avatar: css({
    display: "none",
    borderRadius: "10px",
    "@md": {
      width: "124px",
      display: "inline-block",

      ".image__image-wrapper": {
        img: {
          borderRadius: "10px"
        }
      }
    }
  }),

  details: css({
    display: "flex",
    paddingTop: "$20",
    flexDirection: "column",
    borderBottom: "none !important",

    variants: {
      isOnline: {
        true: {}
      }
    }
  }),

  iconLabel: css({
    paddingBottom: "$12 !important"
  }),

  joinClass: css({
    paddingTop: "$20"
  }),

  tips: css({
    paddingTop: "$20"
  }),

  label: css({
    color: "$gray",
    lineHeight: "134%",
    letterSpacing: "0.04em",
    fontSize: "$xsmParagraph",
    fontFamily: "$foundersGrotesk"
  }),

  link: css({
    color: "$gray",
    lineHeight: "134%",
    letterSpacing: "0.04em",
    fontSize: "$xsmParagraph",
    fontFamily: "$foundersGrotesk",
    textDecoration: "underline !important",
    textDecorationColor: "$robbinsEggBlue !important",

    "&:active, &:hover, &:focus, &:visited": {
      color: "$gray !important",
      textDecoration: "underline !important",
      textDecorationColor: "$robbinsEggBlue !important"
    }
  })
};
