import CClassFormat from "./CClassFormat";

const SearchFilters__AlgoliaSearchQueries = searchFilters => {
  const result = {
    level: searchFilters.level,
    time: searchFilters.time,
    selectedCategories: searchFilters.selectedCategories,
    query: searchFilters.query,
    dateString: searchFilters.dateString,
    date: searchFilters.date,
    endDate: searchFilters.endDate,
    postcode: searchFilters.postcode,
    coordinates: searchFilters.coordinates,
    radius: searchFilters.radius,
    currentPage: searchFilters.currentPage,
    peopleCount: searchFilters.peopleCount,
    maxPages: searchFilters.maxPages,
    isHidingFlexitime: searchFilters.isHidingFlexitime,
    resultsPerPage: searchFilters.resultsPerPage,
    region: searchFilters.region,
    startDate: searchFilters.startDate,
    classType: searchFilters.classType,
    requiresSendingPhysicalMaterial:
      searchFilters.requiresSendingPhysicalMaterial
  };

  if (searchFilters.selectedClassFormat === CClassFormat.ONLINE) {
    result.isOnline = true;
  }

  if (searchFilters.selectedClassFormat === CClassFormat.IN_PERSON) {
    result.isOnline = false;
  }

  return result;
};

export default SearchFilters__AlgoliaSearchQueries;
