import { css } from "@obby/components";

export const styles = {
  newHeader: css({
    position: "fixed",
    top: "0",
    left: "0",
    right: "0",
    zIndex: "501",
    transition: "all 250ms ease-in-out",
    borderBottom: "solid 1px transparent",

    "&.new-header--classes": {
      "@media (max-width: 576px)": {
        opacity: "0",
        zIndex: "499"
      }
    },

    "&.new-header--filled, &.new-header--classes": {
      backgroundColor: "white",
      "@lg": {
        borderBottomColor: "$pampas"
      }
    },
    "&.new-header--classes-bg": {
      backgroundColor: "#fff"
    },
    ".menu-nav__logo-icon": {
      ".background": {
        fill: "$ebonyClay"
      },
      ".foreground": {
        fill: "$sun"
      }
    }
  }),

  topBarPlaceholder: css({
    height: "38px"
  }),
  topBarText: css({
    color: "inherit",
    fontSize: "1.1rem",
    "@sm": {
      fontSize: "inherit"
    }
  }),
  search: css({
    display: "flex",
    flexBasis: "100%",
    justifyContent: "flex-end",
    opacity: "1",
    visibility: "visible",
    transition: "visibility 0s, opacity 250ms ease-in-out",

    "@sm": {
      justifyContent: "flex-start"
    },
    "@lg": {
      justifyContent: "flex-end"
    },

    "&.new-header__search--hidden": {
      visibility: "hidden",
      opacity: "0"
    },
    ".menu-nav-search-input": {
      "@sm": {
        maxWidth: "420px"
      },
      "@lg": {
        maxWidth: "320px"
      }
    }
  }),

  link: css({
    lineHeight: "100%",
    color: "$ebonyClay",
    letterSpacing: "0.01em",
    fontSize: "$20",
    fontFamily: "$foundersGrotesk",
    textDecoration: "none",
    textTransform: "capitalize",

    "@lg": {
      fontSize: "18px"
    },
    "&:active, &:focus, &:hover, &:visited": {
      color: "$ebonyClay",
      textDecoration: "none"
    }
  })
};
