import { css } from "@stitches/react";
import React from "react";
import SecondaryButton from "../SecondaryButton";

interface WaitingListCheckoutBannerProps {
  title: string;
  children: any;
  onOpen: () => void;
}

function WaitingListCheckoutBanner({
  title,
  children,
  onOpen
}: WaitingListCheckoutBannerProps) {
  return (
    <div className={styles.banner()}>
      <div className={styles.content()}>
        <h3 className={styles.header()}>{title}</h3>
        <p className={styles.text()}>{children}</p>
      </div>
      <div>
        {/* @ts-ignore */}
        <SecondaryButton
          onClick={onOpen}
          size="small"
          width="auto"
          text="Join New Date Waiting List"
          color="white"
        />
      </div>
    </div>
  );
}

const styles = {
  banner: css({
    padding: 20,
    backgroundColor: "$royalBlue",
    borderRadius: 10
  }),

  header: css({
    fontFamily: "$foundersGroteskCondensed",
    fontSize: "$24",
    textTransform: "uppercase",
    color: "white",
    lineHeight: 1,
    margin: 0,
    marginBottom: 10
  }),

  text: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "1.0rem",
    color: "white",
    margin: 0
  }),

  content: css({
    marginBottom: 20
  })
};

export { WaitingListCheckoutBanner };
