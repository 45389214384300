import React from "react";
// @ts-ignore
import { Currencies } from "@obby/constants";
import {
  Number__Currency,
  CurrencyISO__CurrencySymbol,
  Discount__DiscountedPrice
  // @ts-ignore
} from "@obby/lib";
import { SecondaryButton } from "../../../buttons/SecondaryButton";
import { HighlightPrice } from "../../../badges/HighlightPrice";

import { styles } from "./BookingOption.style";

export function BookingOption({
  buttonText = "Select",
  compact = false,
  currency = Currencies.GBP,
  description,
  discount,
  isFromPrice = false,
  onSelect,
  price,
  title
}: BookingOption.Props) {
  const currencySymbol = CurrencyISO__CurrencySymbol(currency);
  return (
    <div className={styles.root({ compact })}>
      <div className={styles.wrapper({ compact })}>
        <div className={styles.textWrapper({ compact })}>
          <div className={styles.titleWrapper()}>
            <span className={styles.title()}>{title}</span>
            <span className={styles.description()}>{description}</span>
          </div>
          {price !== undefined && (
            <div className={styles.priceWrapper()}>
              {discount && (
                <HighlightPrice
                  price={Discount__DiscountedPrice(discount, price)}
                  currency={currency}
                  size={{ "@initial": "small", "@sm": "large" }}
                />
              )}
              <span
                className={styles.price({
                  withDiscount: discount !== undefined
                })}
              >
                {isFromPrice && (
                  <>from {Number__Currency(price, currencySymbol)}</>
                )}
                {!isFromPrice &&
                  price > 0 &&
                  Number__Currency(price, currencySymbol)}
              </span>
            </div>
          )}
        </div>
        <div className={styles.buttonWrapper()}>
          <SecondaryButton
            text={buttonText}
            onClick={onSelect}
            color="robbinsEggBlue"
            size={{ "@initial": "small", "@sm": compact ? "medium" : "large" }}
            uppercase
          />
        </div>
      </div>
    </div>
  );
}

export namespace BookingOption {
  export interface Props {
    buttonText?: string;
    compact?: boolean;
    currency?: string;
    description: string;
    discount?: { value: number; type: string };
    isFromPrice?: boolean;
    onSelect: () => void;
    price?: number;
    title: string;
  }
}
