import React, { useContext, useLayoutEffect } from "react";
import { styles } from "./TableBody.styles";
import { TableContext } from "./TableContext";

export function TableBody({ children }: React.PropsWithChildren<Props>) {
  const numberOfRows = React.Children.count(children);

  const { setNumberOfRows } = useContext(TableContext);

  useLayoutEffect(() => {
    setNumberOfRows(numberOfRows);
  }, [numberOfRows]);

  if (numberOfRows === 0) return null;

  return <tbody className={styles.tableBody()}>{children}</tbody>;
}

interface Props {}
