import React, { ReactNode } from "react";

export function SelectBoxOption(props: SelectBoxOption.Props) {
  return <>{props.children ?? null}</>;
}

export namespace SelectBoxOption {
  export interface Props {
    children?: ReactNode;
    disabled?: boolean;
    value?: any;
  }
}
