// These will only be reported to teachers/partners when the source is the widget
// as those purchased on the marketplace will not generate income for the partner.

import { Number__Currency } from "./Number_Currency";
import { CurrencyISO__CurrencySymbol } from "./CurrencyISO__CurrencySymbol";
import { Timezones } from "@obby/constants";
import { UTCDate__FormattedDate } from "./UTCDate__FormattedDate";

// There is no commission on those purchased via the widget.
function GiftCard__FinancialReportingGiftCardRow(
  giftCard: any,
  timezone = Timezones.EUROPE_LONDON,
  isRefunded = false
) {
  const {
    _id,
    buyerName,
    amount,
    partnerMoneyStatus,
    partnerMoneyTransfer,
    createdAt
  } = giftCard;

  const displayName = `${Number__Currency(
    amount,
    CurrencyISO__CurrencySymbol(giftCard.currencyUsed)
  )} Gift Card`;

  return {
    id: _id,
    class: displayName, // TODO Generate display name
    date: UTCDate__FormattedDate(createdAt, timezone, "D MMMM YYYY"),
    ticket: "",
    name: buyerName,
    quantity: 1,
    type: "Gift Card",
    internalType: "giftcard",
    giftcardCode: giftCard.code,
    source: "widget",
    // @TODO we dont have a refunded object
    grossTotal: !isRefunded
      ? amount
      : giftCard.partnerMoneyTransfer?.reverseAmount ?? 0,
    discount: 0, // Discount can't be used to purchase gift cards
    // @TODO we dont have a refunded object
    netPay: !isRefunded
      ? giftCard.accounting?.netPay ?? 0
      : giftCard.partnerMoneyTransfer?.reverseAmount ?? 0,
    paymentProcessing: giftCard.accounting?.paymentProcessing ?? 0,
    obbyFee: giftCard.accounting?.obbyFee ?? 0,
    vat: giftCard.accounting?.vat ?? 0,
    isMultiple: false,
    allTickets: [],
    partnerMoneyStatus,
    // TODO: Manual transfer date?
    transferDate:
      partnerMoneyStatus === "transfered"
        ? UTCDate__FormattedDate(
            partnerMoneyTransfer.transferDate,
            timezone,
            "D MMMM YYYY"
          )
        : "-",
    currencyUsed: giftCard.currencyUsed
  };
}

export { GiftCard__FinancialReportingGiftCardRow };
