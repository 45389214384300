import React, { PropsWithChildren, ReactNode } from "react";

import Icon from "../Icon";
import * as Icons from "../Icons";
import { styles } from "./Label.styles";

export default function Label({
  label,
  description,
  children,
  note,
  error,
  nowrap = false,
  className = "",
  restricted,
  condensed,
  short,
  size = "normal",
  bold = false
}: PropsWithChildren<Props>) {
  return (
    <div
      className={styles.component({
        className: `label ${className}`
      })}
    >
      {/* LABEL */}
      {label !== undefined && (
        <div
          className={styles.label({
            nowrap,
            restricted,
            condensed,
            size,
            bold
          })}
        >
          {label}
          {restricted && (
            <Icon className={styles.lockIcon()} icon={Icons.Lock} height={15} />
          )}
        </div>
      )}
      {description && (
        <div className={styles.description({ restricted })}>{description}</div>
      )}
      {children && (
        <div className={styles.content({ short, size })}>{children}</div>
      )}
      {/* NOTE */}
      {note && <div className={styles.note({ restricted })}>{note}</div>}

      {/* ERROR */}
      {error && <div className={styles.error()}>{error}</div>}
    </div>
  );
}

interface Props {
  label?: ReactNode;
  description?: ReactNode;
  note?: ReactNode;
  error?: ReactNode;
  nowrap?: boolean;
  condensed?: boolean;
  short?: boolean;
  className?: string;
  restricted?: boolean;
  size?: "normal" | "small" | "large";
  bold?: boolean;
}
