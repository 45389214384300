import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { ImageUrl__ImageResizedUrl } from "@obby/lib";

import Image from "../Image";
import BannerContent from "../BannerContent";

import { styles } from "./ClassListBanner.styles";

const ClassListBanner = ({
  textColor,
  title,
  description,
  dividerColor,
  image,
  buttonText,
  onButtonClick,
  mobileImageAlignment,
  backgroundColor = "white",
  roundedCorners = false
}) => {
  const cropImage = useMemo(
    () =>
      ImageUrl__ImageResizedUrl(
        {
          width: 660,
          height: 376,
          crop: "fill"
        },
        image
      ),
    [image]
  );
  return (
    <div
      className={`class-list-banner ${
        roundedCorners ? "class-list-banner--rounded-corners" : ""
      } ${styles.classListBanner({ roundedCorners })}`}
    >
      <div
        style={{ backgroundColor: backgroundColor }}
        className={`
				class-list-banner__container--${mobileImageAlignment === "top" &&
          "top"} ${styles.container({
          top: mobileImageAlignment === "top" && "top"
        })}`}
      >
        <div className={`class-list-banner__description ${styles.description}`}>
          <BannerContent
            buttonText={buttonText}
            onButtonClick={onButtonClick}
            title={title}
            description={description}
            dividerColor={dividerColor}
            textColor={textColor}
          />
        </div>
        <Image
          src={cropImage}
          className={`class-list-banner__image ${styles.image}`}
          asBackground
          isTransparent
        />
      </div>
    </div>
  );
};

ClassListBanner.propTypes = {
  theme: PropTypes.string,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
  bannerImage: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  backgroundColor: PropTypes.string,
  mobileImageAlignment: PropTypes.oneOf(["top", "bottom"])
};

export default ClassListBanner;
