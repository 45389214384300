import { css, withBem } from "../../sitches.config";

export const styles = withBem("toggle-input", {
  toggleInput: css({
    display: "inline-block"
  }),

  container: css({
    display: "inline-flex",
    gap: "$8",
    alignItems: "center"
  }),

  checkbox: css({
    visibility: "hidden",
    width: 0,
    height: 0,
    margin: 0
  }),

  label: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$16",
    fontWeight: "400",
    variants: {
      disabled: {
        true: {
          color: "$gray"
        }
      },
      highlight: {
        true: {
          fontWeight: "500"
        }
      }
    }
  }),

  toggle: css({
    position: "relative",
    backgroundColor: "#fff",
    border: "1px solid $outlineColour",
    variants: {
      locked: {
        true: {
          backgroundColor: "$outlineColour"
        }
      },
      disabled: {
        true: {
          backgroundColor: "$outlineColour"
        }
      },
      size: {
        small: {
          width: "48px",
          height: "24px",
          borderRadius: "12.5px"
        },
        medium: {
          width: "60px",
          height: "30px",
          borderRadius: "15px"
        }
      }
    }
  }),

  switch: css({
    position: "absolute",
    top: 2,
    left: 2,
    backgroundColor: "$neutral",
    borderRadius: "50%",
    transition: "150ms ease-out",
    variants: {
      value: {
        true: {
          backgroundColor: "$callToAction"
        }
      },
      locked: {
        true: {
          backgroundColor: "#fff"
        }
      },
      disabled: {
        true: {
          backgroundColor: "#fff"
        }
      },
      size: {
        small: {
          width: 18,
          height: 18
        },
        medium: {
          width: 24,
          height: 24
        }
      }
    },
    compoundVariants: [
      {
        value: true,
        size: "small",
        css: {
          transform: "translateX(24px)"
        }
      },
      {
        value: true,
        size: "medium",
        css: {
          transform: "translateX(30px)"
        }
      }
    ]
  })
});
