import React, { useState, useEffect } from "react";
import ReactMde from "react-mde";
import ReactMarkdown from "react-markdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import { styles } from "./MarkdownEditor.styles";

export function MarkdownEditor({
  disabled,
  name,
  onChange,
  readOnly,
  ...props
}: Props) {
  const [tab, setTab] = useState<"write" | "preview">("write");

  const [value, setValue] = useState(props.value ?? "");
  useEffect(() => {
    setValue(props.value ?? "");
  }, [props.value ?? ""]);

  function onReactMdeChange(value: string) {
    setValue(value);
    onChange && onChange(value, name!);
  }

  return (
    <div className={styles.markdownEditor()}>
      <ReactMde
        value={value}
        onChange={onReactMdeChange}
        selectedTab={tab}
        onTabChange={setTab}
        toolbarCommands={[["bold", "italic", "link", "unordered-list"]]}
        generateMarkdownPreview={markdown =>
          // @ts-ignore
          Promise.resolve(<ReactMarkdown source={markdown} />)
        }
        // minPreviewHeight={200}
        minEditorHeight={208}
        childProps={{
          textArea: {
            disabled
          },
          writeButton: {
            disabled
          },
          previewButton: {
            disabled
          }
        }}
        readOnly={readOnly || disabled}
      />
    </div>
  );
}

interface Props {
  disabled?: boolean;
  name?: string;
  onChange?: (value: string, name: string) => void;
  readOnly?: boolean;
  value?: string;
}
