import { useState, useEffect } from "react";

export function useOnScreen(ref, callback = () => {}, options) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    if (isIntersecting) callback();
  }, [isIntersecting]);

  useEffect(() => {
    const observer = new window.IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting),
      options
    );
    if (ref.current) observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}
