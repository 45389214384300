import React from "react";
import { styles } from "./BookingOptionsSectionRow.styles";

export function BookingOptionsSectionRow({
  heading,
  children,
  sticker
}: React.PropsWithChildren<Props>) {
  return (
    <div className={styles.root()}>
      <div className={styles.area()}>
        <h3 className={styles.heading()}>{heading}</h3>
        <div className={styles.description()}>{children}</div>
      </div>
      {sticker && (
        <div className={styles.stickerWrapper()}>
          <img
            className={styles.sticker()}
            src={sticker}
            alt="Obby guarantees good classes"
          />
        </div>
      )}
    </div>
  );
}

interface Props {
  heading: string;
  sticker?: string;
}
