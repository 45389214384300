import React from "react";

import SecondaryButton from "./../SecondaryButton";
import ThemedMarkdownText from "../ThemedMarkdownText";

import styles from "./image-aligned-banner.module.less";
import ImageAlignedBannerAsideContent from "./ImageAlignedBannerAsideContent";
import { is } from "ramda";

function parseImageAlignment(imageAlignment) {
  if (imageAlignment === "right") {
    return styles["image-aligned-banner--right"];
  }

  return "";
}

function StringOrStringArray__StringArray(value) {
  if (is(Array, value)) {
    return value;
  }
  return [value];
}

const ImageAlignedBanner = ({
  imageAlignment,
  imageUrl,
  content,
  textSticker,
  imageSticker,
  imageWithLabelSticker,
  shapeWithTextSticker
}) => {
  return (
    <div
      className={`${styles["image-aligned-banner"]} ${parseImageAlignment(
        imageAlignment
      )}`}
    >
      <div className={styles["image-aligned-banner__aside"]}>
        <div className={styles["image-aligned-banner__aside-content"]}>
          <ImageAlignedBannerAsideContent
            imageUrl={imageUrl}
            imageWithLabelSticker={imageWithLabelSticker}
            imageSticker={imageSticker}
            textSticker={textSticker}
            shapeWithTextSticker={shapeWithTextSticker}
          />
        </div>
      </div>
      <div className={styles["image-aligned-banner__main"]}>
        <span className={styles["image-aligned-banner__title"]}>
          {content.title}
        </span>
        {StringOrStringArray__StringArray(content.description).map(
          (description, index) => (
            <ThemedMarkdownText
              key={index}
              text={description}
              classNames={{
                link: styles["image-aligned-banner__description-link"],
                paragraph: styles["image-aligned-banner__description"],
                list: styles["image-aligned-banner__list"],
                listItem: styles["image-aligned-banner__list-item"]
              }}
            />
          )
        )}
        {content.linkText && (
          <div className={styles["image-aligned-banner__button-wrap"]}>
            <SecondaryButton
              width="auto"
              color="robbinsEggBlue"
              textMode="uppercase"
              text={content.linkText}
              onClick={content.onClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

ImageAlignedBanner.defaultProps = {
  imageAlignment: "left"
};

export default ImageAlignedBanner;
