import React, { useState, useRef, useEffect } from "react";

import Icon from "../Icon";
import * as Icons from "../Icons";

import styles from "./menu-nav-search-input.module.less";

export function MenuNavSearchInput(props) {
  const {
    onSearch,
    iconColor = "ebonyClay",
    iconColorHover = "emeraldish",
    placeholder = "What do you want to learn?",
    showIcon = true,
    defaultValue = ""
  } = props;
  console.log("props", props);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const inputRef = useRef();

  function onKeyUp(e) {
    if (e.key === "Enter") {
      onSearch(value);
    }
  }

  function onIconClick() {
    setOpen(true);
    inputRef.current.focus();
  }

  return (
    <div
      className={`menu-nav-search-input ${styles["menu-nav-search-input"]} ${
        open ? styles["menu-nav-search-input--open"] : ""
      }`}
    >
      <input
        ref={inputRef}
        className={styles[`menu-nav-search-input__input-text`]}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={e => setValue(e.target.value)}
        onKeyUp={onKeyUp}
        onBlur={e => !e.target.value && setOpen(false)}
      />
      {showIcon && (
        <Icon
          className={`${styles["menu-nav-search-input__icon"]} ${
            !open || open
              ? `${
                  styles[`menu-nav-mobile-bar__hamburger--color-${iconColor}`]
                } ${
                  styles[
                    `menu-nav-mobile-bar__hamburger--hover-color-${iconColorHover}`
                  ]
                }`
              : ""
          }`}
          icon={Icons.BoldSearch}
          onClick={onIconClick}
        />
      )}
    </div>
  );
}
