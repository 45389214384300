import { UTCDate__Add, UTCDate__IsAfter, UTCDate__IsBefore } from "@obby/lib";
import { BookingSources, defaultCancellationPolicy } from "@obby/constants";

export function Booking__IsCancellableOrReschedulable(
  booking: any,
  cancellationPolicy: any,
  action: "cancel" | "reschedule"
): boolean {
  switch (true) {
    // User has already been refunded for this booking.
    case booking.refunded:
    // This booking has passed.
    case UTCDate__IsBefore(booking.currentStartDateTime):
      return false;

    // Booking has been cancelled by the teacher but not refunded
    case booking.cancelled:
    // Teacher has rescheduled the booking and is waiting confirmation.
    case booking.confirmation === "rescheduled":
    // Legally, bookings can be cancelled up to 24 hours after they are made, regardless of the policy.
    case UTCDate__IsAfter(UTCDate__Add(booking.createdAt, 24, "hours")):
    // Marketplace purchases must allow cancellation/rescheduling if the duration, start time or location has changed.
    case booking.source === BookingSources.OBBY &&
      booking.bookingContractHasChanged:
      return true;
  }
  // if the booking was made on obby, force the cancellation policy to be the default one
  if (booking.source === BookingSources.OBBY)
    cancellationPolicy = defaultCancellationPolicy;

  const {
    canReschedule,
    daysBefore,
    hoursBefore,
    canCancel
  } = cancellationPolicy;

  switch (action) {
    case "cancel":
      if (!canCancel) return false;
      break;
    case "reschedule":
      if (!canReschedule) return false;
      break;
  }
  let date = UTCDate__Add(booking.currentStartDateTime, -daysBefore, "days");
  date = UTCDate__Add(date, -hoursBefore, "hours");
  return UTCDate__IsAfter(date);
}
