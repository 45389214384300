import * as Yup from "yup";
import { DeliveryAddressForm } from "../DeliveryAddressForm";
import { PersonalDetailsForm } from "../PersonalDetailsForm";

export function CheckoutPersonalDetailsFormSchema(isSendingMaterial: boolean) {
  return Yup.object({
    guests: Yup.array()
      .of(PersonalDetailsForm.Schema())
      .required()
      .default([]),
    deliveryAddress: isSendingMaterial
      ? DeliveryAddressForm.Schema()
      : DeliveryAddressForm.Schema()
          .notRequired()
          .default(undefined)
  }).required();
}
