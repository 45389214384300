import React from "react";
import App from "next/app";
import Router from "next/router";
import getConfig from "next/config";
import NextError from "next/error";
import Layout from "~/components/app/Layout";
import ErrorBoundary from "~/components/ErrorBoundary";
import axios from "~/config/axios";
import token from "~/config/token";
import { is_development } from "~/settings";
import cookies from "~/config/cookies";
import { initalizeStore } from "~/state/store";
import { RecoilRoot } from "recoil";
import { initializeRecoilState } from "../atoms/ssr";
import { store } from "statorgfc";
import "../styles/globals.less";
const { publicRuntimeConfig } = getConfig();
export default class MyApp extends App {
  constructor(props) {
    super(props);

    const { categories, sales } = props.pageProps;
    initalizeStore({ categories, sales });
    store.set("sales", sales);
  }

  componentDidMount() {
    // exposes the token globally for development purposes in order to ease the access from the console
    if (is_development && token.loggedIn())
      window.token = token.getToken().accessToken;

    Router.events.on("routeChangeComplete", this.onRouteChangeComplete);
  }

  componentWillUnmount() {
    Router.events.off("routeChangeComplete", this.onRouteChangeComplete);
  }

  static async getInitialProps({ Component, router, ctx }) {
    let appProps = {};
    try {
      // on the server side the cookies need to be loaded manually
      if (ctx.req && ctx.req.headers) {
        cookies.load(ctx.req.headers.cookie);
      }

      let res = await axios.get("PartnerGeneral");
      appProps = res.data;
      if (Component.getInitialProps) {
        const componentProps = await Component.getInitialProps(ctx, appProps);
        return {
          pageProps: Object.assign(appProps, componentProps)
        };
      }
      return { pageProps: appProps };
    } catch (error) {
      console.log("the ERROR is ", error);
      let pageError = {};
      switch (true) {
        case !!error.response:
          pageError = error.response.data;
          break;
        case error instanceof Error:
          pageError.message = error.message;
          break;
        case typeof error === "string":
          pageError.message = error;
          break;
      }
      if (ctx && ctx.res) {
        ctx.res.statusCode = pageError.code || 500;
        ctx.res.statusMessage = pageError.message || "";
      }

      return { pageProps: appProps, pageError };
    }
  }

  onRouteChangeComplete() {
    // Scroll to top every time a page is changed
    window.scrollTo(0, 0);
  }

  render() {
    if (process.env.NEXT_PUBLIC_MAINTENANCE_MODE) {
      return (
        <div>
          Hi there and thanks for visiting! We are currently doing some
          essential site maintenance meaning this page will be offline until
          3am. We apologies for any disruption this causes you. Many thanks for
          your patience - best, team Obby ;)
        </div>
      );
    }

    const { Component, pageProps, pageError } = this.props;
    pageProps.url = this.props.router;
    if (pageError) {
      return (
        <Layout {...pageProps} args={Component.args || {}}>
          <NextError statusCode={pageError.code} title={pageError.message} />
        </Layout>
      );
    }

    return (
      <ErrorBoundary>
        <RecoilRoot
          initializeState={initializeRecoilState(
            pageProps.initalRecoilState ?? {}
          )}
        >
          <Layout
            {...pageProps}
            args={Component.args || {}}
            noHeader={Component.noHeader}
          >
            <Component {...pageProps} />
          </Layout>
        </RecoilRoot>
      </ErrorBoundary>
    );
  }
}

export function reportWebVitals({ id, name, label, value }) {
  if (window.gtag) {
    window.gtag("event", name, {
      event_category:
        label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
      value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
      event_label: id, // id unique to current page load
      non_interaction: true // avoids affecting bounce rate.
    });
  }
}
