import React from "react";
import { Countries, CountriesNames } from "@obby/constants";
import Label from "../../components/Label";
import { SelectBox } from "../../inputs/SelectBox";
import { TextInput } from "../../components/TextInput";
import { LocationFormSchema, Defaults } from "./LocationForm.schema";

export function LocationForm(props) {
  const { values, errors, touched, onChange, onBlur } = props;

  return (
    <div className="location-form">
      <div className="row">
        <div className="col-12 mb-4">
          <Label label="Name" error={touched.name && errors.name}>
            <TextInput
              name="name"
              value={values.name}
              placeholder="This an the name of your school or venue."
              onChange={onChange}
              onBlur={onBlur}
              max={50}
            />
          </Label>
        </div>
        <div className="col-12 col-md-6 mb-4">
          <Label label="Country" error={touched.country && errors.country}>
            <SelectBox
              name="country"
              valye={values.country}
              onChange={onChange}
              onBlur={onBlur}
            >
              {Object.values(Countries).map(country => (
                <SelectBox.Option key={country} value={country}>
                  {CountriesNames[country]}
                </SelectBox.Option>
              ))}
            </SelectBox>
          </Label>
        </div>
        <div className="col-12 col-md-6 mb-4">
          <Label label="Area" error={touched.area && errors.area}>
            <TextInput
              name="area"
              value={values.area}
              placeholder="Wimbledon, Shoreditch..."
              onChange={onChange}
              onBlur={onBlur}
            />
          </Label>
        </div>
        <div className="col-12 mb-4">
          <Label label="Address Line 1" error={touched.line1 && errors.line1}>
            <TextInput
              name="line1"
              value={values.line1}
              placeholder="Baker Street 30"
              onChange={onChange}
              onBlur={onBlur}
              max={100}
            />
          </Label>
        </div>
        <div className="col-12 mb-4">
          <Label label="Address Line 2" error={touched.line2 && errors.line2}>
            <TextInput
              name="line2"
              value={values.line2}
              placeholder="(Optional)"
              onChange={onChange}
              onBlur={onBlur}
              max={100}
            />
          </Label>
        </div>
        <div className="col-12 mb-4">
          <Label label="Post code" error={touched.postcode && errors.postcode}>
            <TextInput
              name="postcode"
              value={values.postcode}
              onChange={onChange}
              onBlur={onBlur}
            />
          </Label>
        </div>
        <div className="col-12 mb-4">
          <Label label="city" error={touched.city && errors.city}>
            <TextInput
              name="city"
              value={values.city}
              onChange={onChange}
              onBlur={onBlur}
            />
          </Label>
        </div>
      </div>
    </div>
  );
}

LocationForm.Schema = LocationFormSchema;
LocationForm.Defaults = Defaults;
