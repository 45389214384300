import React from "react";
import styles from "./RoundedSwitch.module.less";
import { PrimaryButton } from "../../buttons/PrimaryButton";

export function RoundedSwitch({
  onClick,
  isChecked,
  checkedTxt = "CheckedTxt",
  notCheckedTxt = "NotChedk"
}) {
  return (
    <div className={styles["rounded-switch"]}>
      <div
        className={`${styles["rounded-switch__button"]} ${
          !isChecked ? styles["rounded-switch__button-highlight"] : null
        }`}
      >
        <PrimaryButton
          text={notCheckedTxt}
          onClick={e => onClick("monthly")}
          color={!isChecked ? "camelot" : "gallery"}
        />
      </div>
      <div
        className={`${styles["rounded-switch__button"]} ${
          isChecked ? styles["rounded-switch__button-highlight"] : null
        }`}
      >
        <PrimaryButton
          text={checkedTxt}
          onClick={e => onClick("yearly")}
          color={isChecked ? "camelot" : "gallery"}
        />
      </div>
    </div>
  );
}
