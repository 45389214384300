import React, { useContext } from "react";

import Icon from "../../components/Icon";
import * as Icons from "../../components/Icons";

import { AccordionBoxContext } from "./AccordionBoxContext";

import { styles } from "./AccordionBoxToogle.styles";

export function AccordionBoxToggle(props) {
  const { children, className, tick = false } = props;

  const { open = false, setOpen = () => {}, onToggle = () => {} } = useContext(AccordionBoxContext);

  function onToggleClick(){
    setOpen(!open);
    onToggle(!open);
  }

  return (
    <div
      className={styles.toggle({ className })}
      onClick={onToggleClick}
    >
      <div className={styles.wrapper()}>{children}</div>
      {tick && (
        <Icon
          className={styles.tick({ open })}
          icon={Icons.ChevronLeft}
          height={24}
        />
      )}
    </div>
  );
}
