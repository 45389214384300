import React from "react";
import LocationDetails from "../LocationDetails";

import { css } from "../../sitches.config";
import { bem } from "../../configs/bem";

interface SchoolLocationProps {
  googleMap: any;
  location: any;
}

const cn = bem("school-location");

export function SchoolLocation({ googleMap, location }: SchoolLocationProps) {
  return (
    <div className={`${cn()} ${styles.schoolLocation}`}>
      <section className={`${cn("section")} ${styles.sectionLocation}`}>
        {location && (
          <LocationDetails
            shouldDisplayAreaTitle
            name={location.name}
            area={location.area}
            line1={location.line1}
            line2={location.line2}
            postcode={location.postcode}
            city={location.city}
          />
        )}
      </section>
      <section className={`${cn("section")} ${styles.sectionMap}`}>
        {googleMap && (
          <div className={`${cn("section-map")} ${styles.map}`}>
            {googleMap}
          </div>
        )}
      </section>
    </div>
  );
}

const styles = {
  schoolLocation: css({
    display: "flex",
    flexDirection: "column-reverse",

    "@sm": {
      paddingTop: "1rem",
      flexWrap: "wrap"
    },
    "@smd": {
      flexDirection: "row"
    }
  }),
  sectionLocation: css({
    flex: "0 0 100%",
    paddingBottom: "$16",
    "@smd": {
      paddingLeft: "0 !important",
      "-ms-flex": "0 0 25%",
      flex: "0 0 25%",
      maxWidth: "25%"
    }
  }),
  sectionMap: css({
    flex: "0 0 100%",
    "@smd": {
      flex: "0 0 75%",
      maxWidth: "75%",
      "-ms-flex": "0 0 75%"
    }
  }),
  map: css({
    width: "100%",
    height: "380px",
    marginBottom: "25px"
  })
};
