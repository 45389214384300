import React, { useContext } from "react";
import { PrimaryButton } from "../../components/PrimaryButton";
import { CheckoutStepsContext } from "./CheckoutStepsContext";

export function CheckoutStepsContinue(props: Props) {
  const { size } = props;

  const { currentStep, steps, isLastStep, onNext } = useContext(
    CheckoutStepsContext
  );

  const step = steps[currentStep] || {};
  const {
    disabled,
    nextButtonText = isLastStep() ? "finish" : "continue"
  } = step;

  return (
    <PrimaryButton
      size={size}
      text={nextButtonText}
      color="emeraldish"
      uppercase
      width="auto"
      disabled={disabled}
      onClick={onNext}
    />
  );
}

interface Props {
  size?: PrimaryButton.Props["size"];
}
