import { animations, css } from "../../sitches.config";

export const styles = {
  container: css({
    padding: 24,
    backgroundColor: "$azalea",
    borderRadius: 4,
    marginBottom: 24,
    animation: `${animations.popout} 300ms, ${animations.fadeIn} 300ms`
  }),

  error: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$smParagraph",
    lineHeight: 1.25,
    color: "$ebonyClay",
    fontWeight: 400,
    marginBottom: 14,

    "&:last-child": {
      marginBottom: 0
    }
  })
};
