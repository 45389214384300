import React from "react";
import PropTypes from "prop-types";
import { styles } from "./PeekingPopup.styles";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import { SecondaryButton } from "../../buttons/SecondaryButton";

const PeekingPopup = ({
  children,
  isOpen,
  onClear,
  onApply,
  closeModal,
  noControls = false
}) => (
  <div className={`peeking-popup 	${styles.peekingPopup({ isOpen })} `}>
    <div
      className={`peeking-popup__overlay ${styles.overlay({ isOpen })}`}
      onClick={closeModal}
    />
    <div
      className={` peeking-popup__container ${styles.container({ isOpen })}`}
    >
      <div className={`peeking-popup__wrapper ${styles.wrapper()}`}>
        <div
          className={`peeking-popup__child ${styles.child()} ${
            noControls ? "pb-4" : ""
          }`}
        >
          {children}
        </div>
        {!noControls && (
          <div className={`peeking-popup__controls ${styles.controls()}`}>
            {onClear && (
              <PrimaryButton
                css={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#c4c4c4",
                  textTransform: "uppercase",
                  fontFamily: "$foundersGrotesk",
                  width: "auto"
                }}
                className={`peeking-popup__controls--clear`}
                color="white"
                text="Clear"
                onClick={onClear}
              />
            )}
            <div />
            <div className={`peeking-popup__controls--apply ${styles.apply()}`}>
              <SecondaryButton
                color="robbinsEggBlue"
                text="Apply"
                size="medium"
                onClick={onApply}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
);

PeekingPopup.propTypes = {
  children: PropTypes.instanceOf(Object),
  isOpen: PropTypes.bool.isRequired,
  onClear: PropTypes.func,
  onApply: PropTypes.func,
  closeModal: PropTypes.func.isRequired
};

export default PeekingPopup;
