import * as Yup from "yup";

export function DeliveryAddressFormSchema() {
  return Yup.object({
    firstName: Yup.string()
      .label("First name")
      .required()
      .min(3)
      .default(""),
    lastName: Yup.string()
      .label("Surname")
      .required()
      .min(3)
      .default(""),
    addressLine1: Yup.string()
      .label("Address Line 1")
      .required()
      .default(""),
    addressLine2: Yup.string()
      .label("Address Line 2")
      .default(""),
    city: Yup.string()
      .label("Town or City")
      .required()
      .default(""),
    region: Yup.string()
      .label("Country or Region")
      .default(""),
    postCode: Yup.string()
      .label("Postcode")
      .min(4, "Enter a valid ZIP / postal code for United Kingdom")
      .required()
      .default(""),
    contactNumber: Yup.string()
      .label("Contact Number")
      .default(""),
    additionalNotes: Yup.string()
      .label("Additional Notes")
      .default("")
  }).required();
}
