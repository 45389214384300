import React, { useContext, useEffect } from "react";
import Checkbox from "../../components/Checkbox";
import { MultipleCheckboxInputContext } from "./MultipleCheckboxInputContext";
import { styles } from "./MultipleCheckoutInputs.styles";

export function MultipleCheckboxInputOption({ value, label }: Props) {
  const { onCheck, onUncheck, size, selected } = useContext(
    MultipleCheckboxInputContext
  );

  function onCheckboxChange(checked: boolean) {
    if (checked) onCheck(value);
    else onUncheck(value);
  }

  return (
    <li className={styles.listItem()}>
      <Checkbox
        color="camelot"
        label={label}
        size={size}
        value={selected.includes(value)}
        onChange={onCheckboxChange}
      />
    </li>
  );
}

interface Props {
  label: string;
  value: any;
}
