import React from "react";
// @ts-ignore
import { Number__Currency, CurrencyISO__CurrencySymbol } from "@obby/lib";
import { styles } from "./HighlightPrice.styles";
import { ResponsiveVariant } from "../../sitches.config";

export function HighlightPrice({
  price,
  pp = false,
  currency = "GBP",
  size = "small"
}: Props) {
  return (
    <div className={styles.root({ size })}>
      {Number__Currency(price, CurrencyISO__CurrencySymbol(currency))}
      {pp && " pp"}
    </div>
  );
}

interface Props {
  price: number;
  pp?: boolean;
  currency?: string;
  size?: ResponsiveVariant<"small" | "medium" | "large">;
}
