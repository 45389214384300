import React from "react";
import { FaIcon } from "../../icons/FaIcon";

import { styles } from "./IconButton.styles";

export function IconButton({
  className,
  color,
  disabled = false,
  icon,
  onClick,
  outline,
  size = "small",
  title
}: Props) {
  return (
    <button
      className={styles.iconButton({
        outline,
        size,
        className,
        disabled,
        css: { "--icon-button-color": `$colors$${color}` }
      })}
      type="button"
      title={title}
      onClick={onClick}
      disabled={disabled}
    >
      <FaIcon className={styles.icon()} icon={icon} />
    </button>
  );
}

interface Props {
  className?: string;
  color?: string;
  disabled?: boolean;
  icon: FaIcon.Icon;
  onClick?: () => void;
  outline?: boolean;
  size?: "tiny" | "small" | "medium";
  title?: string;
}
