import React from "react";
import { Label } from "../../components/Label";
import { TextArea } from "../../inputs/TextArea";
import { TextInput } from "../../inputs/TextInput";

export function ConfirmBookingForm(props) {
  const { values, errors, touched, onChange, onBlur, fields = [] } = props;

  return (
    <div className="cancel-booking-form">
      {fields.length === 0 && <Label label="Are you sure?" />}

      {fields.includes("message") && (
        <Label
          label="Message"
          error={touched?.message && errors.message}
          note={`The user will get back to you using email. You can agree on the other details by just replying to their email.`}
        >
          <TextArea
            name="message"
            value={values.message}
            onChange={onChange}
            onBlur={onBlur}
          />
        </Label>
      )}

      {fields.includes("zoomLink") && (
        <Label
          label="Zoom Link"
          error={touched?.zoomLink && errors.zoomLink}
          preNote="Add a Zoom link for this booking in order to confirm"
        >
          <TextInput
            name="zoomLink"
            value={values.zoomLink}
            onChange={onChange}
            onBlur={onBlur}
            placeholder="https://us02web.zoom.us/j/82927876274?pwd=ha81das1"
          />
        </Label>
      )}
    </div>
  );
}
