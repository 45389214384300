import { UTCDate__Get } from "./UTCDate__Get";

export function TermCourse__NumberOfWeeks(course: any) {
  const { nextAvailableAdditionalDates, nextAvailableDate } = course.summary;

  const lastAdditionalDate =
    nextAvailableAdditionalDates[nextAvailableAdditionalDates.length - 1];

  const startWeekNumber = UTCDate__Get(
    nextAvailableDate,
    course.timezone,
    "isoWeek"
  );
  const endWeekNumber = UTCDate__Get(
    lastAdditionalDate,
    course.timezone,
    "isoWeek"
  );
  const startWeekYearNumber = UTCDate__Get(
    nextAvailableDate,
    course.timezone,
    "isoWeekYear"
  );
  const endWeekYearNumber = UTCDate__Get(
    lastAdditionalDate,
    course.timezone,
    "isoWeekYear"
  );

  return (
    endWeekNumber +
    (endWeekYearNumber - startWeekYearNumber) * 52 -
    startWeekNumber +
    1
  );
}
