import { css, withBem } from "../../sitches.config";

export const styles = withBem("calendar-list-item", {
  calendarListItem: css({
    display: "flex",
    minHeight: "70px",
    backgroundColor: "white",
    borderStyle: "solid",
    borderColor: "$mercury",
    borderWidth: "0 1px 1px",
    fontFamily: "$foundersGrotesk",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "-0.02em",
    color: "$ebonyClay",
    "&:link, &:visited, &:hover, &:active": {
      color: "$ebonyClay",
      textDecoration: "none"
    },
    "&:first-child": {
      borderTopWidth: "1px",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px"
    },
    "&:last-child": {
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "4px"
    }
  }),

  center: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "$2",
    flex: "1",
    "@md": {
      paddingX: "$3",
      flexDirection: "row",
      alignItems: "center"
    }
  }),

  edge: css({
    borderRight: "solid 1px $mercury",
    display: "flex",
    flex: "0 0 50px",
    justifyContent: "center",
    "@md": {
      alignItems: "center",
      flexBasis: "70px"
    }
  }),

  day: css({
    fontFamily: "$foundersGrotesk",
    textAlign: "center"
  }),

  details: css({
    fontSize: "18px",
    flexBasis: "40%"
  }),
  title: css({
    fontSize: "18px",
    "@md": {
      flexBasis: "40%",
      paddingRight: "$3"
    }
  }),

  detail: css({
    display: "inline-block",
    // marginY: "$1",
    "&:not(:first-child):before": {
      content: "•",
      marginX: "$2",
      fontSize: "24px",
      color: "$silverMalibu"
    }
    //
  }),

  teacher: css({
    display: "flex",
    alignItems: "center",
    fontSize: "18px",
    "@md": {
      flexBasis: "20%"
    },
    "&:active, &:focus, &:hover, &:visited": {
      color: "$ebonyClay"
    },
    variants: {
      clickable: {
        true: {
          cursor: "pointer",
          "&:hover": {
            textDecoration: "underline"
          }
        }
      }
    }
  }),

  teacherImage: css({
    display: "block",
    borderRadius: "50%",
    width: "36px",
    height: "36px",
    "@md": {
      width: "40px",
      height: "40px"
    }
  }),

  actionWrapper: css({
    borderLeft: "solid 1px $mercury",
    display: "flex",
    flex: "0 0 80px",
    "@md": {
      flexBasis: "100px"
    }
  }),

  actions: css({
    alignItems: "center",
    display: "flex",
    flexBasis: "100%",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    variants: {
      bookable: {
        true: {
          backgroundColor: "#c0f0ed"
        },
        false: {
          backgroundColor: "$azalea"
        }
      }
    }
  }),

  price: css({}),

  action: css({
    cursor: "pointer",
    textTransform: "uppercase",
    fontWeight: "500",
    fontSize: "14px",
    "@md": {
      fontSize: "16px"
    },
    lineHeight: "1",
    letterSpacing: "-0.02em"
  })
});
