import moment from "moment-timezone";
import { Timezones } from "@obby/constants";

export function Session__StartTime(
  session: any,
  timezone = Timezones.EUROPE_LONDON
): number {
  const date = moment(session.startDateTime).tz(timezone);
  return date.minutes() + date.hours() * 60;
}
