import React from "react";
import CookieToast from "../CookieToast";
import styles from "./CookieToastColumn.module.less";

const CookieToastColumn = props => (
  <div className={styles["cookie-toast-column"]}>
    {props.toasts.map((toast, index) => (
      <div key={index} className={styles["cookie-toast-column__toast"]}>
        <CookieToast
          cookieName={toast.name}
          text={toast.text}
          color={toast.color}
        />
      </div>
    ))}
  </div>
);

export default CookieToastColumn;
