import React from "react";
import PropTypes from "prop-types";

import styles from "./menu-nav-user-links.module.less";

export function WidgetUserMenuNavLinks({ options }) {
  console.log("options ", options);
  return (
    <ul className={styles["menu-nav-user-links"]}>
      {options.map(option => {
        return (
          <li>
            <a
              onClick={option.onClick}
              className={styles["menu-nav-user-links__link"]}
              href={option.href}
            >
              {option.label}
            </a>
          </li>
        );
      })}
    </ul>
  );
  return (
    <ul className={styles["menu-nav-user-links"]}>
      <li>
        <a className={styles["menu-nav-user-links__link"]} href="/wishlist">
          Wishlist
        </a>
      </li>
      <li>
        <a className={styles["menu-nav-user-links__link"]} href="/bookings">
          Bookings
        </a>
      </li>
      <li>
        <a className={styles["menu-nav-user-links__link"]} href="/profile">
          Settings
        </a>
      </li>
      <li>
        <a
          className={styles["menu-nav-user-links__link"]}
          href="https://obby.helpscoutdocs.com/collection/31-booking-a-class-with-obby "
          target="_blank"
        >
          Help
        </a>
      </li>
      <li>
        <a onClick={onSignOut} className={styles["menu-nav-user-links__link"]}>
          Logout
        </a>
      </li>
    </ul>
  );
}

WidgetUserMenuNavLinks.propTypes = {};
