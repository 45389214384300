import React from "react";
import { css } from "../../sitches.config";
import Icon from "../Icon";
import { Cross } from "../Icons";

interface OverlayCardProps {
  banner: string;
  title?: string;
  children: any;
  showCloseButton?: boolean;
  onClose?: () => void;
}

export function OverlayCard({
  banner,
  title,
  children,
  showCloseButton = false,
  onClose
}: OverlayCardProps) {
  return (
    <div className={styles.card()}>
      <div className={styles.banner()}>
        <img className={styles.image()} src={banner} alt="" />
        {showCloseButton && typeof onClose === "function" && (
          <button onClick={onClose} className={styles.closeButton()}>
            <div className={styles.closeIcon()}>
              {/* @ts-ignore */}
              <Icon icon={Cross} width={10} />
            </div>
          </button>
        )}
      </div>
      <div className={styles.wrapper()}>
        {title && <h1 className={styles.title()}>{title}</h1>}
        <div className={styles.content()}>{children}</div>
      </div>
    </div>
  );
}

const styles = {
  card: css({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 640,
    minHeight: 655,
    borderRadius: 3,
    overflow: "hidden"
  }),

  banner: css({
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "$white",
    minHeight: 172
  }),

  image: css({
    display: "block",
    flexShrink: 0
  }),

  wrapper: css({
    display: "flex",
    flexDirection: "column",
    paddingY: 50,
    paddingX: 20,
    flexGrow: 1,
    color: "$ebonyClay",
    backgroundColor: "white",

    "@md": {
      paddingX: 50
    }
  }),

  title: css({
    fontFamily: "$foundersGroteskCondensed",
    fontWeight: "bold",
    fontSize: 42,
    lineHeight: 1,
    marginTop: 0,
    textTransform: "uppercase",
    textAlign: "center",
    marginBottom: "1.0rem"
  }),

  content: css({
    flexGrow: 1,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 18,
    lineHeight: 1.4,
    fontFamily: "$foundersGrotesk"
  }),

  closeButton: css({
    position: "absolute",
    top: 0,
    right: 0,
    padding: 10,
    border: 0,
    borderRadius: 0,
    backgroundColor: "transparent"
  }),

  closeIcon: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 30,
    height: 30,
    borderRadius: "50%",
    backgroundColor: "white",

    "& svg": {
      display: "block"
    },

    "&:hover": {
      backgroundColor: "$silver"
    }
  })
};
