import React from "react";
import { css, animations } from "../../sitches.config";

interface FunkyLoaderProps {
  mode?: "box-mode" | "embed" | "full-screen-mode";
  text?: string;
}

const loaderImageUrl =
  "https://res.cloudinary.com/obby/image/upload/v1598160902/Misc%20Stickers/loading-stickers.gif";

const FunkyLoader = ({ mode = "box-mode", text }: FunkyLoaderProps) => (
  <div className={styles.loader({ mode })}>
    <div className={styles.box({ isBox: mode === "box-mode" })}>
      <div>
        <img className={styles.image()} src={loaderImageUrl} alt="Loading..." />
        {text && <div className={styles.text()}>{text}</div>}
      </div>
    </div>
  </div>
);

const styles = {
  loader: css({
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    variants: {
      mode: {
        "box-mode": {
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 2200,
          backgroundColor: "rgba(0,0,0,0.1)",
          animations: `${animations.popout} 250ms, ${animations.fadeIn} 250ms`
        },

        embed: {
          position: "absolute",
          left: 0,
          right: 0,
          zIndex: 1,
          backgroundColor: "rgba(255,255,255,0.2)"
        },

        "full-screen-mode": {
          position: "fixed",
          top: 0,
          left: 0,
          backgroundColor: "white",
          animation: `${animations.fadeIn} 300ms`,
          zIndex: 2200
        }
      }
    },

    defaultVariants: {
      mode: "box-mode"
    }
  }),

  box: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    borderRadius: 4,
    padding: 20,

    variants: {
      isBox: {
        true: {
          boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
          animation: `${animations.popout} 250ms, ${animations.fadeIn} 250ms`
        }
      }
    }
  }),

  image: css({
    display: "block",
    width: 100,
    height: 100
  }),

  text: css({
    paddingTop: 12,
    width: "100%",
    textAlign: "center",
    fontFamily: "$foundersGrotesk",
    fontSize: 16,
    lineHeight: 1.4,
    color: "$ebonyClay"
  })
};

export default FunkyLoader;
