import React, { useState, useEffect } from "react";
import { styles } from "./DurationInput.styles";
import { SelectBox } from "../SelectBox";

export function DurationInput({
  disabled = false,
  name,
  onChange,
  size = "medium",
  value = null,
  width = "auto"
}: Props) {
  const [hours, setHours] = useState(value ? Math.floor(value / 60) : null);
  const [minutes, setMinutes] = useState(value ? value % 60 : null);

  useEffect(() => {
    setHours(value !== null ? Math.floor(value / 60) : null);
    setMinutes(value !== null ? value % 60 : null);
  }, [value]);

  useEffect(() => {
    if (hours !== null && minutes !== null) {
      const value = hours * 60 + minutes;
      onChange && onChange(value, name!);
    } else if (value !== null) onChange && onChange(null, name!);
  }, [hours, minutes]);

  function onHoursChange(hour: number) {
    setHours(hour);
  }

  function onMinutesChange(minutes: number) {
    setMinutes(minutes);
  }

  return (
    <div className={styles.sharedDurationInput({ width, size })}>
      <SelectBox
        className={styles.selectBox()}
        disabled={disabled}
        onChange={onHoursChange}
        size={size}
        value={hours}
        placeholder="Hours"
      >
        <SelectBox.Option value={0}>0 hours</SelectBox.Option>
        <SelectBox.Option value={1}>1 hour</SelectBox.Option>
        <SelectBox.Option value={2}>2 hours</SelectBox.Option>
        <SelectBox.Option value={3}>3 hours</SelectBox.Option>
        <SelectBox.Option value={4}>4 hours</SelectBox.Option>
        <SelectBox.Option value={5}>5 hours</SelectBox.Option>
        <SelectBox.Option value={6}>6 hours</SelectBox.Option>
        <SelectBox.Option value={7}>7 hours</SelectBox.Option>
        <SelectBox.Option value={8}>8 hours</SelectBox.Option>
        <SelectBox.Option value={9}>9 hours</SelectBox.Option>
        <SelectBox.Option value={10}>10 hours</SelectBox.Option>
        <SelectBox.Option value={11}>11 hours</SelectBox.Option>
        <SelectBox.Option value={12}>12 hours</SelectBox.Option>
      </SelectBox>
      <SelectBox
        className={styles.selectBox()}
        disabled={disabled}
        onChange={onMinutesChange}
        placeholder="Minutes"
        size={size}
        value={minutes}
      >
        <SelectBox.Option value={0}>0 minutes</SelectBox.Option>
        <SelectBox.Option value={5}>5 minutes</SelectBox.Option>
        <SelectBox.Option value={10}>10 minutes</SelectBox.Option>
        <SelectBox.Option value={15}>15 minutes</SelectBox.Option>
        <SelectBox.Option value={20}>20 minutes</SelectBox.Option>
        <SelectBox.Option value={25}>25 minutes</SelectBox.Option>
        <SelectBox.Option value={30}>30 minutes</SelectBox.Option>
        <SelectBox.Option value={35}>35 minutes</SelectBox.Option>
        <SelectBox.Option value={40}>40 minutes</SelectBox.Option>
        <SelectBox.Option value={45}>45 minutes</SelectBox.Option>
        <SelectBox.Option value={50}>50 minutes</SelectBox.Option>
        <SelectBox.Option value={55}>55 minutes</SelectBox.Option>
      </SelectBox>
    </div>
  );
}

interface Props {
  disabled?: boolean;
  name?: string;
  onChange: (value: number | null, name: string) => void;
  size?: "medium" | "small";
  value?: number | null;
  width?: "full" | "auto";
}
