import React from "react";
import Icons from "../Icons";
import Icon from "../Icon";

import styles from "./TagFilterButton.module.less";

export default function TagFilterButton({ label, onClose }) {
  return (
    <div className={styles["tag-filter-button"]}>
      <span className={styles["tag-filter-button__label"]}>{label}</span>
      {onClose && (
        <Icon
          className={styles["tag-filter-button__icon"]}
          icon={Icons.Cross}
          height={13}
          onClick={onClose}
        />
      )}
    </div>
  );
}
