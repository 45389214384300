import React from "react";
import { CloudinaryImage } from "../../components";
import { bem } from "../../configs/bem";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import { styles } from "./SquareCard.styles";

const cn = bem("square-card");
export function SquareCard(props) {
  const { thumbnail, title, location, description, url } = props;
  return (
    <a href={url} className={`${cn()} ${styles.squareCard}`}>
      <CloudinaryImage
        className={`${cn("thumbnail")} ${styles.thumbnail}`}
        src={thumbnail}
        width={500}
        height={500}
      />
      <div
        className={`${props.mobile && "d-inline"} ${cn("title", null, [
          "mt-2"
        ])} ${styles.title}`}
      >
        {title}
      </div>
      {description && (
        <div
          className={`${props.mobile && "d-inline"} ${cn("description")}  ${
            styles.description
          }`}
        >
          {description}
        </div>
      )}
      {props.mobile && (
        <div className={`${cn("button")} ${styles.button}`}>
          <PrimaryButton
            color="robbinsEggBlue"
            text={`EXPLORE ${location} LEARNING`}
          />
        </div>
      )}
    </a>
  );
}
