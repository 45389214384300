import { EmbedModes } from "./EmbedModes";

export const EmbedModesLabels = {
  [EmbedModes.STANDARD]: "Standard",
  [EmbedModes.FULL_PAGE]: "Full Page",
  [EmbedModes.POPUP]: "Popup",
  [EmbedModes.SLIDER]: "Slider",
  [EmbedModes.POPOVER]: "Popover",
  [EmbedModes.SIDE_TAB]: "Side Tab"
};
