import React from "react";
import { StringOrArray__StringArray } from "@obby/lib";
// Components
import Icon from "../Icon";
import Icons from "../Icons";
import styles from "./TipBox.module.less";

export default class TipBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true
    };
  }

  toggleBody() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    return (
      <div className={`${styles["tip-box"]} ${this.props.className || ""}`}>
        <div
          className={styles["tip-box__header"]}
          onClick={this.toggleBody.bind(this)}
        >
          <div
            className={`${styles["tip-box__title"]} ${
              styles[`tip-box__title--${this.props.size}`]
            }`}
          >
            {this.props.title}
          </div>
          <div
            className={`
              ${styles["tip-box__toggle-icon"]}
              ${
                styles[
                  `tip-box__toggle-icon--${this.state.isOpen ? "down" : "up"}`
                ]
              }
            `}
          >
            <Icon icon={Icons.ChevronLeft} height={16} />
          </div>
        </div>

        <div
          className={`
            ${styles["tip-box__body"]}
            ${styles[`tip-box__body--${this.state.isOpen ? "open" : "closed"}`]}
          `}
        >
          {this.props.tips.map((tip, index) => (
            <div key={index} className={styles["tip-box__tip-wrap"]}>
              <div className={styles["tip-box__tip"]}>
                <div className={styles["tip-box__tip-icon"]}>
                  <Icon icon={Icons.Ball} height={8} />
                </div>
                {tip.link && (
                  <a
                    href={tip.link}
                    target="_blank"
                    className={`${tip - box__tip - link} ${
                      styles[`tip-box__tip-link--${this.props.size}`]
                    }`}
                  >
                    {tip.text}
                  </a>
                )}
                {!tip.link && (
                  <div
                    className={`
                      ${styles["tip-box__tip-text"]}
                      ${styles[`tip-box__tip-text--${this.props.size}`]}
                    `}
                  >
                    {tip.text}
                  </div>
                )}
              </div>

              {tip.example && (
                <div className={styles["tip-box__example"]}>
                  <div
                    className={`
                      ${styles["tip-box__example-title"]}
                      ${styles[`tip-box__example-title--${this.props.size}`]}
                      `}
                  >
                    Example:
                  </div>
                  <div className={styles["tip-box__example-text-lines"]}>
                    {StringOrArray__StringArray(tip.example).map(
                      (example, index) => (
                        <div
                          key={index}
                          className={`
                            ${styles[`tip-box__example-text`]}
                            ${
                              styles[
                                `tip-box__example-text--${this.props.size}`
                              ]
                            }`}
                        >
                          {example}
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
