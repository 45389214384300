import React, { ReactElement, useState, useEffect } from "react";

import {
  closestCenter,
  DndContext,
  DragEndEvent,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { SortableItem } from "./SortableItem";
import { useIsDragging } from "./useIsDragging";

export function Sortable<T>({
  children,
  id,
  onChange,
  itemClassName,
  ...props
}: Props<T>) {
  const [items, setItems] = useState(props.items ?? []);
  useEffect(() => {
    setItems(props.items ?? []);
  }, [props.items ?? []]);
  const ids = items.map((item, index) => id(item, index));

  const sensors = useSensors(
    // useSensor(PointerSensor, {activationConstraint:{      delay: 250,
    //     tolerance: 5}}),
    useSensor(MouseSensor, {
      activationConstraint: { delay: 250, tolerance: 5 }
    }),
    useSensor(TouchSensor, {
      activationConstraint: { delay: 250, tolerance: 5 }
    })
  );

  function onDraggableChange(e: DragEndEvent) {
    const { active, over } = e;
    if (over && over.id !== active.id) {
      const activeIndex = items.findIndex(item => id(item) === active.id);
      // const activeIndex = items.indexOf(active.id as string);
      const overIndex = items.findIndex(item => id(item) === over.id);
      // const overIndex = items.indexOf(over.id as string);
      const newItems = [...items];
      const [activeItem] = newItems.splice(activeIndex, 1);
      newItems.splice(overIndex, 0, activeItem);

      setItems(newItems);
      onChange(newItems);
    }
  }

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={onDraggableChange}
      collisionDetection={closestCenter}
    >
      <SortableContext items={ids}>
        {ids.map((itemId, index) => {
          const item = items.find(item => id(item) === itemId);

          return (
            <SortableItem key={itemId} id={itemId} className={itemClassName}>
              {children(item!, index)}
            </SortableItem>
          );
        })}
      </SortableContext>
    </DndContext>
  );
}

Sortable.useIsDragging = useIsDragging;

interface Props<T> {
  children: (item: T, index?: number) => ReactElement;
  id: (item: T, index?: number) => string | number;
  items: T[];
  itemClassName?: string;
  onChange: (items: T[]) => void;
}
