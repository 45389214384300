import React, { useState } from "react";

import styles from "./mobile-class-list.module.less";
import MobileClassCard from "../MobileClassCard";
import { CourseCard } from "../../cards/CourseCard";

const MobileClassList = ({
  classes,
  onViewMore = () => {},
  title,
  minNumItems = 3,
  isOnline = false
}) => {
  const [viewMore, setViewMore] = useState(false);

  const onViewMoreClick = event => {
    setViewMore(true);
    onViewMore(event);
  };

  const runViewMoreFilter = (_, index) => {
    if (viewMore) {
      return true;
    }

    return index < minNumItems;
  };
  return (
    <ul className={styles["mobile-class-list"]}>
      {title && <li className={styles["mobile-class-list__title"]}>{title}</li>}
      {classes.filter(runViewMoreFilter).map((_class, index) => (
        <li className={styles["mobile-class-list__item"]} key={index}>
          <CourseCard
            currency={_class.currency}
            discount={_class.discount}
            orientation="horizontal"
            isFromPrice={!_class.samePriceAlways}
            isOnline={_class.isOnline}
            location={_class.location}
            numberOfReviews={_class.numberOfReviews}
            price={_class.price}
            rating={_class.rating}
            requiresSendingPhysicalMaterial={
              _class.requiresSendingPhysicalMaterial
            }
            thumbnail={_class.thumbnail}
            title={_class.title}
            url={_class.url}
          />
        </li>
      ))}
      {!viewMore && (
        <li className={styles["mobile-class-list__item"]}>
          <span
            className={styles["mobile-class-list__view-more"]}
            onClick={onViewMoreClick}
          >
            View more
          </span>
        </li>
      )}
    </ul>
  );
};

export default MobileClassList;
