import { css } from "../../sitches.config";

export const styles = {
  contactFormWidget: css({
    display: "flex",
    "-ms-flex-wrap": "wrap",
    flexWrap: "wrap",
    marginRight: "-15px",
    marginLeft: "-15px",

    "@sm": {
      paddingBottom: "$16"
    }
  }),
  formSection: css({
    paddingTop: "1rem",
    paddingInline: "15px",
    flex: "0 0 100%",
    "@sm": {
      flex: "0 0 50%",
      maxWidth: "50%",
      "-ms-flex": "0 0 50%"
    }
  }),
  sectionMessage: css({
    flex: "0 0 100%",
    paddingInline: "15px",
    paddingTop: "1rem"
  })
};
