import React from "react";
import Link from "../Link";

export default function ScrollLink({ onClick, href, className, ...props }) {
  function onLinkClick(event) {
    event.preventDefault();
    const e = { ...event };
    //disable for now the pushstate change
    if (false && history.pushState && href) {
      console.log("history.pushState");
      history.pushState({}, "", href);
      window.dispatchEvent(new Event("hashchange"));
    }

    setTimeout(() => {
      let offset = () => 0;
      if (typeof props.offset !== "undefined") {
        if (
          !!(props.offset && props.offset.constructor && props.offset.apply)
        ) {
          offset = props.offset;
        } else {
          offset = () => parseInt(props.offset);
        }
      }
      // lets add active class only if the class name is provided
      if (className) {
        const linksList = Array.from(
          document.querySelectorAll(`.${className}`)
        );
        for (let linkItem of linksList) {
          linkItem.classList.remove("active");
        }
        e.currentTarget.classList.add("active");
      }
      const id = e.currentTarget.getAttribute("href").slice(1);
      const $anchor = document.getElementById(id);
      const offsetTop = $anchor
        ? $anchor.getBoundingClientRect().top + window.pageYOffset
        : 0;
      const top = offsetTop - offset();

      if (window.scroll) {
        window.scroll({
          top,
          behavior: "smooth"
        });
      } else {
        window.scrollTo(0, top);
      }

      if (onClick) {
        onClick(e);
      }
    }, 0);
  }

  const { id, offset, ...rest } = props;

  return (
    <Link {...rest} href={href} className={className} onClick={onLinkClick} />
  );
}
