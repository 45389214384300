import React, { KeyboardEvent, useState, useEffect } from "react";
import { Star } from "../../../components/Icons";
import Icon from "../../../components/Icon";
import { css } from "../../../sitches.config";

interface RatingInputProps {
  onChange: (val: number, name?: string) => void;
  onBlur?: (name?: string) => void;
  name?: string;
  value: number;
  size?: "small" | "default";
}

export function RatingInput({
  onChange = () => {},
  onBlur,
  name,
  value,
  size = "default"
}: RatingInputProps) {
  const [rating, setRating] = useState(value);

  useEffect(() => {
    setRating(value);
  }, [value]);

  function onStarClick(newValue: number) {
    if (newValue === rating) {
      newValue = 0;
    }
    changeRating(newValue);
  }

  function changeRating(newValue: number) {
    if (newValue === rating) {
      return;
    }
    setRating(newValue);
    onChange(newValue, name);
  }

  function onKeyPress(e: KeyboardEvent<HTMLDivElement>) {
    switch (e.key) {
      case "ArrowRight":
        changeRating(Math.min(rating + 1, 5));
        break;
      case "ArrowLeft":
        changeRating(Math.max(rating - 1, 0));
        break;
      case "0":
      case "1":
      case "2":
      case "3":
      case "4":
      case "5":
        changeRating(parseInt(e.key));
        break;
    }
  }

  return (
    <div
      className={styles.input()}
      tabIndex={0}
      onBlur={onBlur ? () => onBlur(name) : undefined}
      onKeyDown={onKeyPress}
    >
      {/*
			The rating values are printed descendant on purpose. the flex-direction: row-reverse rule is used to flip them back in place
			The purpose of this is to ease fill of the lower stars when a mouse is hover a certain star with the help
			of the general sibling combinator (~)
			*/}
      {[5, 4, 3, 2, 1].map(number => (
        <Icon
          key={number}
          className={styles.star({ isFilled: rating >= number, size })}
          icon={Star}
          onClick={() => onStarClick(number)}
        />
      ))}
    </div>
  );
}

const styles = {
  input: css({
    display: "inline-flex",
    flexDirection: "row-reverse",

    "&:hover": {
      "& polygon": {
        fill: "$nickel"
      }
    }
  }),

  star: css({
    "& polygon": {
      fill: "$nickel"
    },

    "&:hover": {
      "& polygon": {
        fill: "$camelot"
      },
      "& ~ * polygon": {
        fill: "$camelot"
      }
    },

    variants: {
      size: {
        small: {
          height: 35
        },
        default: {
          height: 60
        }
      },
      isFilled: {
        true: {
          "& polygon": {
            fill: "$camelot"
          }
        }
      }
    }
  })
};
