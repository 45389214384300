import { css } from "../../sitches.config";

export const styles = {
  locationDetails: css({
    display: "flex",
    flexDirection: "column"
  }),
  title: css({
    fontFamily: "$foundersGroteskCondensed",
    fontWeight: "bold",
    fontSize: "$mParagraph",
    lineHeight: "80%",
    letterSpacing: "0.03em",
    textTransform: "uppercase",
    color: "$ebonyClay",
    marginTop: "3px",

    "@media (max-width: 425px)": {
      fontSize: "16px"
    }
  }),
  section: css({
    paddingLeft: "0"
  }),
  area: css({
    fontFamily: "$foundersGrotesk",
    paddingBlock: "1rem",
    color: "#28283c",
    fontSize: "18px",
    lineHeight: "19px",
    letterSpacing: "0.03em",
    opacity: "0.7"
  }),
  body: css({
    marginTop: "16px"
  })
};
