import * as Yup from "yup";
export function ResetPasswordFormSchema(defaults = {}) {
	defaults = {
		email: "",
		newPassword: "",
		confirmNewPassword: "",
		...defaults
	};

	return Yup.object({
		email: Yup.string()
			.label("Email")
			.email()
			.required()
			.default(defaults.email),
		newPassword: Yup.string()
			.label("New password")
			.required()
			.default(defaults.newPassword),
		confirmNewPassword: Yup.string()
			.label("Confirm new password")
			.required()
			.test("", "The passwords do not match.", function(confirmNewPassword) {
				const { newPassword } = this.parent;
				return newPassword === confirmNewPassword;
			})
			.default(defaults.confirmNewPassword)
	});
}
