//@ts-ignore
import { useRecoilState } from "recoil";
import { contactObbyPopupIsOpen, contactObbyPopupData } from "~/atoms/global";
import axios from "~/config/axios";
import Swal from "sweetalert2";
import Popup from "~/components/common/Popup";
import MessagePrompt from "~/components/MessagePrompt";

export function ContactObbyPopup() {
  function onClosePopup() {
    console.log("closing");
    setContactObbyPopupIsOpen(false);
  }
  const [isContactObbyPopupIsOpen, setContactObbyPopupIsOpen] = useRecoilState(
    contactObbyPopupIsOpen
  );
  const [contactObbyPopupDataHere]: any = useRecoilState(contactObbyPopupData);

  async function sendMessageToObby(message: any) {
    try {
      console.log("we are submiting the message ", contactObbyPopupDataHere);
      let bodyRequest = { message: message, ...contactObbyPopupDataHere };
      await axios.post("ContactObbyFromBooking", bodyRequest);
      //@ts-ignore
      Swal.fire({
        title: "Sent!",
        text: `We will reply in 24-48hrs`,
        type: "success",
        confirmButtonColor: "#00D8C4"
      }).then(result => {
        setContactObbyPopupIsOpen(false);
      });
    } catch (error) {
      console.log("error ", error);
      alert("there was an error");
    }
  }
  if (!isContactObbyPopupIsOpen) {
    return null;
  }

  let dateFormated: any = new Date(
    contactObbyPopupDataHere.itemData.sessionDate
  ).toLocaleString();
  return (
    <Popup show={isContactObbyPopupIsOpen} onClose={onClosePopup}>
      <MessagePrompt
        title={`${contactObbyPopupDataHere.itemData.courseTitle}`}
        subtitle={`${dateFormated}`}
        submit={sendMessageToObby}
        cancel={onClosePopup}
      />
    </Popup>
  );
}
