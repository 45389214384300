import React from "react";
import Icon from "../Icon";
import styles from "./bright-icon-pill.module.less";
const BrightIconPill = ({ icon, text, iconHeight, iconWidth, background }) => {
  return (
    <div
      className={`${styles["bright-icon-pill"]} ${
        styles[`bright-icon-pill--${background}`]
      }`}
    >
      {icon && (
        <span className={styles["bright-icon-pill__icon"]}>
          <Icon icon={icon} height={iconHeight} width={iconWidth} />
        </span>
      )}
      <span className={styles["bright-icon-pill__text"]}>{text}</span>
    </div>
  );
};

BrightIconPill.defaultProps = {
  iconHeight: 10,
  background: "sun"
};

export default BrightIconPill;
