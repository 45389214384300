import React, { useRef, useState, useEffect } from "react";
import { BundleDatesSelectionView } from "./BundleDatesSelection.view";
import {
  Currencies,
  defaultCancellationPolicy,
  Timezones
  /** @ts-ignore */
} from "@obby/constants";

export function BundleDatesSelection({
  cancellationPolicy = defaultCancellationPolicy,
  courseThumbnail,
  courseTitle,
  currency = Currencies.GBP,
  locations,
  onLoadDates,
  onSubmit,
  timezone = Timezones.EUROPE_LONDON,
  tutors,
  ...props
}: Props) {
  const [bundle, setBundle] = useState(props.bundle);
  const [sessions, setSessions] = useState(props.sessions ?? []);
  const page = useRef(sessions.length > 0 ? 1 : 0);
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [isFullyLoaded, setIsFullyLoaded] = useState(false);
  const [isSucceeded, setIsSucceeded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedSessionsIds, setSelectedSessionsIds] = useState<string[]>(
    bundle.bundleRecords.map((record: any) => record.session)
  );

  useEffect(() => {
    if (sessions.length === 0) (async () => onLoadMoreDates())();
  }, []);

  async function onContinue() {
    try {
      setIsSubmitting(true);
      const bundle = await onSubmit(selectedSessionsIds);
      setSelectedSessionsIds(
        bundle.bundleRecords.map((record: any) => record.session)
      );
      setIsSubmitting(false);
      setIsSucceeded(true);
      setBundle(bundle);
    } catch (e) {
      setError((e as any)?.toString());
    }
  }

  function onErrorPopupClose() {
    setError(undefined);
  }

  async function onLoadMoreDates() {
    try {
      setIsLoading(true);
      const dates = await onLoadDates({
        page: ++page.current,
        limit: 15
      });
      setSessions([...sessions, ...dates]);
      setIsFullyLoaded(dates.length < 15);
      setIsLoading(false);
    } catch (e) {
      setIsFullyLoaded(false);
      setIsLoading(false);
    }
  }

  function onSelectionChange(sessionsIds: string[]) {
    setSelectedSessionsIds(sessionsIds);
  }

  function onSuccessPopupClose() {
    setIsSucceeded(false);
  }

  return (
    <>
      <BundleDatesSelectionView
        bundle={bundle}
        cancellationPolicy={cancellationPolicy}
        courseThumbnail={courseThumbnail}
        courseTitle={courseTitle}
        currency={currency}
        error={error}
        isFullyLoaded={isFullyLoaded}
        isLoading={isLoading}
        isSubmitting={isSubmitting}
        isSucceeded={isSucceeded}
        locations={locations}
        onContinue={onContinue}
        onErrorPopupClose={onErrorPopupClose}
        onLoadMoreDates={onLoadMoreDates}
        onSelectionChange={onSelectionChange}
        onSuccessPopupClose={onSuccessPopupClose}
        selectedSessionsIds={selectedSessionsIds}
        sessions={sessions}
        timezone={timezone}
        tutors={tutors}
      />
    </>
  );
}

interface Props {
  bundle: any;
  cancellationPolicy?: any;
  courseThumbnail: string;
  courseTitle: string;
  currency: string;
  locations: any[];
  onLoadDates: (args: { page: number; limit: number }) => Promise<any[]>;
  onSubmit: (sessionsIds: string[]) => Promise<any>;
  sessions?: any[];
  timezone: string;
  tutors: any[];
}
