import React, { useState } from "react";
import PropTypes from "prop-types";

import Icon from "../Icon";
import Icons from "../Icons";
import styles from "./Accordion.module.less";

const Accordion = ({ isOpen, title, children }) => {
  const [open, setOpen] = useState(isOpen || false);
  const updateAccordion = () => setOpen(open => !open);

  return (
    <div className={styles["accordion"]}>
      <div className={styles["accordion__wrapper"]} onClick={updateAccordion}>
        <span className={styles["accordion__title"]}>{title}</span>
        <Icon
          className={styles["accordion__title--icon"]}
          icon={open ? Icons.ChevronUp : Icons.ChevronDown}
        />
      </div>
      <div
        className={`${styles["accordion__body"]}
				${styles[`accordion__body--${open ? "open" : "closed"}`]}
				`}
      >
        {children}
      </div>
    </div>
  );
};

Accordion.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.instanceOf(Object)
};

export default Accordion;
