import React, { useState } from "react";
// @ts-ignore
import { CountriesNames, DiscountNatures } from "@obby/constants";
import { CheckoutSteps } from "../../navs/CheckoutSteps";
import { useStripePayment } from "../StripePayment";
import {
  Session__DisplayDateTimeOffset
  // @ts-ignore
} from "@obby/lib";
import { CheckoutPanel } from "../../panels/CheckoutPanel";
import { Fields } from "./AppointmentScheduleCheckout.schema";
import { CheckoutFooterBar } from "../../bars/CheckoutFooterBar";
import { StripePayment } from "../StripePayment";
import { PricingOptionsStep } from "./PricingOptionStep";
import { PersonalDetailsStep } from "./PersonalDetailsStep/PersonalDetailsStep";
import { PricingOption } from "./PricingOption";
import { UserFormQuestionary } from "../UserFormQuestionary";

export function AppointmentScheduleCheckoutView({
  cancellationPolicyUrl,
  credit,
  course,
  discount,
  errors,
  getBookingData,
  isDiscountEnabled,
  isLastStep,
  isNewsletterSignupEnabled = true,
  isValid,
  isUserDiscountInvalid,
  location,
  onAddGuest,
  onApplyDiscount,
  onBlur,
  onChange,
  onCheckoutStepChange,
  onConfirmFree,
  onPersonalDetailsEnter,
  onRemoveGuest,
  pricingOptions,
  startDateTime,
  timezone,
  total,
  touched,
  unavailableTimeBlocks,
  userDiscountValue,
  userDiscountPercentage,
  values,
  mandatoryTickBox = false,
  isUserFormValid,
  setIsUserFormValid
}: Props) {
  const stripePayment = useStripePayment();

  const [isConfirmingFreeBooking, setIsConfirmingFreeBooking] = useState(false);

  const option = pricingOptions[values.pricingOptions.option];
  const formattedDate =
    option &&
    startDateTime &&
    Session__DisplayDateTimeOffset(
      {
        startDateTime,
        duration: option.duration,
        isOnline: course.isOnline
      },
      timezone
    );
  const formattedLocation = location
    ? [
        location.name || "",
        location.line1 || "",
        location.city || "",
        location.postCode || location.postcode
      ]
        .filter(location => location)
        .join(", ")
    : undefined;

  function onContinue() {
    if (total === 0) {
      setIsConfirmingFreeBooking(true);
      onConfirmFree();
    } else stripePayment.submitPayment();
  }

  async function onCardPaymentEnter() {
    await stripePayment.getPaymentIntent(getBookingData());
  }
  return (
    <CheckoutSteps.Provider
      onContinue={onContinue}
      onNext={onCheckoutStepChange}
      onPrevious={onCheckoutStepChange}
    >
      <div className="container-fluid mb-2 mb-md-0">
        <CheckoutSteps.Breadcrumbs />
      </div>
      <CheckoutPanel
        cancellationPolicyUrl={cancellationPolicyUrl}
        title={course.title}
        description={formattedLocation}
        credit={credit}
        currency={course.currency}
        discountError={touched.discountCode && errors.discountCode}
        discountValue={userDiscountValue}
        discountPercentage={userDiscountPercentage}
        isDiscountEnabled={isDiscountEnabled}
        isDiscountInvalid={isUserDiscountInvalid}
        isOnSales={discount?.nature === DiscountNatures.SALES}
        onApplyDiscount={onApplyDiscount}
        personalDetails={
          isLastStep ? values.personalDetails.guests[0] : undefined
        }
        sidebarOnTop={isLastStep}
        items={[
          ...(formattedDate ? [{ name: formattedDate }] : []),
          ...(option
            ? [
                {
                  name: option.name,
                  price: option.price,
                  count: values.personalDetails.guests.length
                }
              ]
            : [])
        ]}
        total={total}
        pricingOptions={pricingOptions}
      >
        <CheckoutSteps>
          <CheckoutSteps.Step
            breadcrumb="Pricing options"
            disabled={!isValid("pricingOptions")}
          >
            <div className="mb-4">
              <PricingOptionsStep
                course={course}
                errors={errors?.dateTime}
                onBlur={onBlur}
                onChange={(value, name) =>
                  onChange(value, `pricingOptions.${name}`)
                }
                options={pricingOptions}
                timezone={timezone}
                touched={touched?.dateTime}
                unavailableTimeBlocks={unavailableTimeBlocks}
                values={values.pricingOptions}
              />
            </div>
          </CheckoutSteps.Step>
          <CheckoutSteps.Step
            breadcrumb=" Customer Information"
            title=" Customer Information"
            description="Please provide the necessary information so that we can continue with your booking."
            disabled={
              !(course.userQuestionForm.isEnabled == false || isUserFormValid)
            }
            hidden={!course.userQuestionForm.isEnabled}
          >
            <UserFormQuestionary
              values={values}
              onChange={onChange}
              mode={course.userQuestionForm.mode}
              questions={course.userQuestionForm.questions}
              onFormValidation={setIsUserFormValid}
            />
          </CheckoutSteps.Step>

          <CheckoutSteps.Step
            breadcrumb="Personal details"
            disabled={isConfirmingFreeBooking || !isValid("personalDetails")}
            onEnter={onPersonalDetailsEnter}
            nextButtonText={total === 0 ? "Confirm booking" : undefined}
          >
            <PersonalDetailsStep
              mandatoryTickBox={mandatoryTickBox}
              course={course}
              errors={errors?.personalDetails}
              isNewsletterSignupEnabled={isNewsletterSignupEnabled}
              onAddGuest={onAddGuest}
              onChange={(value: any, name: string) =>
                onChange(value, `personalDetails.${name}`)
              }
              onBlur={(name: string) => onBlur(`personalDetails.${name}`)}
              onRemoveGuest={onRemoveGuest}
              values={values.personalDetails}
              touched={touched?.personalDetails}
            />
          </CheckoutSteps.Step>
          <CheckoutSteps.Step
            breadcrumb="Card payment"
            title="Card payment"
            nextButtonText="Pay now"
            onEnter={onCardPaymentEnter}
            disabled={!stripePayment.isReady}
            hidden={total === 0}
          >
            <StripePayment />
          </CheckoutSteps.Step>
        </CheckoutSteps>
      </CheckoutPanel>
      <CheckoutFooterBar label="Date Selected" value={formattedDate}>
        <CheckoutSteps.Navigation />
      </CheckoutFooterBar>
    </CheckoutSteps.Provider>
  );
}

interface Props {
  cancellationPolicyUrl: string;
  credit: number;
  course: any;
  discount?: any;
  errors: any;
  getBookingData: () => any;
  isDiscountEnabled: boolean;
  isLastStep: boolean;
  isNewsletterSignupEnabled?: boolean;
  isUserDiscountInvalid: boolean;
  isValid: (path: string) => boolean;
  location?: any;
  onAddGuest: () => void;
  onApplyDiscount: (code: string) => void;
  onBlur: (name: string) => void;
  onChange: (value: any, name: string) => void;
  onCheckoutStepChange: (index: number) => void;
  onConfirmFree: () => void;
  onPersonalDetailsEnter: () => void;
  onRemoveGuest: (index: number) => void;
  pricingOptions: PricingOption[];
  startDateTime?: string;
  timezone: string;
  total: number;
  touched: any;
  unavailableTimeBlocks: any[];
  userDiscountValue: number;
  userDiscountPercentage?: number;
  values: Fields;
  mandatoryTickBox?: boolean;
  setIsUserFormValid: any;
  isUserFormValid: boolean;
}
