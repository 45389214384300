import React from "react";
import { CheckoutCardItem } from "./CheckoutCardItem";

export function CheckoutCardTotal({ currency, total }: Props) {
  return (
    <CheckoutCardItem
      currency={currency}
      bold
      price={total}
      title="Total"
      isTotal={true}
    />
  );
}

interface Props {
  currency?: string;
  total?: number;
}
