import React from "react";
import { identity } from "ramda";

import Icon from "../Icon";
import styles from "./alert-banner.module.less";

const AlertBanner = ({
  icon,
  title,
  description,
  promptText,
  onClick = identity
}) => (
  <div className={styles["alert-banner"]} onClick={onClick}>
    <div className={styles["alert-banner__icon-section"]}>
      <div className={styles["alert-banner__icon"]}>
        <Icon icon={icon} height={20} />
      </div>
    </div>
    <div className={styles["alert-banner__text-section"]}>
      <div className={styles["alert-banner__title"]}>{title}</div>
      <div className={styles["alert-banner__description"]}>{description}</div>
      {promptText && (
        <div className={styles["alert-banner__prompt"]}>{promptText}</div>
      )}
    </div>
  </div>
);

export default AlertBanner;
