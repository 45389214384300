import { store } from "statorgfc";
import { mergeAll } from "ramda";
// Event Managers
import AuthenticationState from "./AuthenticationState";
import ErrorState from "./ErrorState";
import SchoolState from "./SchoolState";
import UserProfileState from "./UserProfileState";
import UserPasswordState from "./UserPasswordState";

const initalizeStore = initialData => {
  if (!isStoreInitalized()) {
    store.initialize(getDefaultStoreData(initialData));
  }
};

const isStoreInitalized = () => store._store_created;

const getDefaultStoreData = initialData => {
  return mergeAll([
    AuthenticationState.getDefaultModel(initialData),
    ErrorState.getDefaultModel(initialData),
    SchoolState.getDefaultModel(initialData),
    UserProfileState.getDefaultModel(initialData),
    UserPasswordState.getDefaultModel(initialData)
  ]);
};

export { initalizeStore, isStoreInitalized, getDefaultStoreData, store };
