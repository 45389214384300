import React from "react";
import PropTypes from "prop-types";
import styles from "./address.module.less";

const Address = ({ name, area, line1, line2, postcode, city }) => (
  <div className={styles["address"]}>
    {name && <div className={styles["address__line"]}>{name}</div>}
    {area && <div className={styles["address__line"]}>{area}</div>}
    {line1 && <div className={styles["address__line"]}>{line1}</div>}
    {line2 && <div className={styles["address__line"]}>{line2}</div>}
    {postcode && <div className={styles["address__line"]}>{postcode}</div>}
    {city && <div className={styles["address__line"]}>{city}</div>}
  </div>
);

Address.propTypes = {
  name: PropTypes.string,
  area: PropTypes.string,
  line1: PropTypes.string,
  line2: PropTypes.string,
  postcode: PropTypes.string,
  city: PropTypes.string
};

export default Address;
