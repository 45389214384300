import React from "react";
// Components
import Icons from "../Icons";
import Icon from "../Icon";

import { styles } from "./LoadingIcon.styles";

export function LoadingIcon({
  width = "auto",
  size = 30,
  currentColor = false
}: Props) {
  return (
    <div className={styles.loadingIcon({ widthSize: width })}>
      <div
        className={styles.wrapper({
          currentColor,
          css: {
            height: size,
            width: size
          }
        })}
      >
        <Icon icon={Icons.Spinner} width={size} height={size} />
      </div>
    </div>
  );
}

interface Props {
  currentColor?: boolean;
  size?: number;
  width?: "max" | "auto";
}

/** @deprecated* */
export default LoadingIcon;
