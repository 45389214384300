import React from "react";
import _ from "lodash";
import { SelectBox } from "../../inputs/SelectBox";
// @ts-ignore
import { Timezones } from "@obby/constants";
import {
  UTCDate__StartOf,
  UTCDate__FormattedDate,
  UTCDate__Add
  // @ts-ignore
} from "@obby/lib";

export function TimeSelection({
  accent = false,
  value,
  name,
  onChange,
  onBlur,
  range = 1440,
  timezone = Timezones.EUROPE_LONDON as string,
  size = "medium",
  startOfDay = UTCDate__StartOf(value, timezone, "day")
}: Props) {
  return (
    <SelectBox
      name={name}
      className="time-selection"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      accent={accent}
      size={size}
    >
      {_.range(0, range / 15).map(index => {
        const date = UTCDate__Add(startOfDay, index * 15, "minutes");
        return (
          <SelectBox.Option key={index} value={date}>
            {UTCDate__FormattedDate(date, timezone, "HH:mm")}
          </SelectBox.Option>
        );
      })}
    </SelectBox>
  );
}

interface Props {
  accent?: string | false;
  name?: string;
  onBlur?: (name?: string) => void;
  onChange?: (value: string, name?: string) => void;
  range?: number;
  timezone?: string;
  size?: "small" | "medium";
  startOfDay?: string;
  value?: string;
}
