import React from "react";

import Icon from "../Icon";
import { TurnLeft } from "../Icons";

import { bemModule } from "../../configs/bem";

import styles from "./arrow-note..module.less";

const cn = bemModule(styles, "arrow-note");
export function ArrowNote(props) {
  const { children } = props;
  return (
    <div className={cn()}>
      <Icon className={cn("arrow-icon")} icon={TurnLeft} height={87} />
      <span className={cn("percentage")}>{children}</span>
    </div>
  );
}
