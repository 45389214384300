import React from "react";

import { BundleDatesSelectionFooter } from "./BundleDatesSelectionFooter";
import { CenteredPopup } from "../../modals/CenteredPopup";
import { CheckoutPanel } from "../../panels/CheckoutPanel";
import { CheckoutSteps } from "../../navs/CheckoutSteps";
import { LoadingIcon } from "../../components/LoadingIcon";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import { SessionDateList } from "../../components/SessionDateList";
import {
  UTCDate__Add,
  UTCDate__FormattedDate,
  UTCDate__IsAfter,
  UTCDate__IsBefore
  /** @ts-ignore */
} from "@obby/lib";
/** @ts-ignore */
import { BookingSources } from "@obby/constants";

export function BundleDatesSelectionView({
  bundle,
  cancellationPolicy,
  courseThumbnail,
  courseTitle,
  currency,
  error,
  isFullyLoaded,
  isLoading,
  isSucceeded,
  isSubmitting,
  locations,
  onErrorPopupClose,
  onLoadMoreDates,
  onSelectionChange,
  onSuccessPopupClose,
  selectedSessionsIds,
  sessions,
  timezone,
  tutors,
  onContinue
}: BundleDatesSelectionView.Props) {
  const ticketsLeft = bundle.bundleTotalTickets - selectedSessionsIds.length;
  const expireDateDisplay = UTCDate__FormattedDate(
    bundle.bundleExpireDate,
    timezone,
    "DD/MM/YYYY"
  );

  function isContinueDisabled() {
    return (
      selectedSessionsIds.length === 0 ||
      (selectedSessionsIds.length === bundle.bundleRecords.length &&
        bundle.bundleRecords.every((record: any) =>
          selectedSessionsIds.includes(record.session)
        ))
    );
  }

  function isDateDisabled(session: any) {
    // dates from the past are disabled
    if (UTCDate__IsBefore(session.startDateTime)) return true;

    if (session.availability === 0) return true;
    // no bundle record found for this session
    const recordSessions = bundle.bundleRecords.map(
      ({ session }: any) => session
    );
    if (!recordSessions.includes(session._id)) return false;

    // Legally, bookings can be cancelled up to 24 hours after they are made, regardless of the policy.
    if (UTCDate__IsAfter(UTCDate__Add(bundle.createdAt, 24, "hours")))
      return false;
    // Marketplace purchases must allow cancellation/rescheduling if the duration, start time or location has changed.
    if (
      bundle.source === BookingSources.OBBY &&
      bundle.bookingContractHasChanged
    )
      return false;

    const { daysBefore, hoursBefore, canCancel } = cancellationPolicy;
    if (!canCancel) return true;

    let date = UTCDate__Add(session.startDateTime, -daysBefore, "days");
    date = UTCDate__Add(date, -hoursBefore, "hours");
    return UTCDate__IsBefore(date);
  }

  return (
    <>
      <CheckoutSteps.Provider onContinue={onContinue}>
        <CheckoutPanel
          title={courseTitle}
          thumbnail={courseThumbnail}
          currency={currency}
          items={bundle.tickets}
          total={bundle.total}
        >
          <CheckoutSteps>
            <CheckoutSteps.Step
              breadcrumb="Date selection"
              title={
                <>
                  You have <span>{ticketsLeft}</span>{" "}
                  {ticketsLeft === 1 ? "class" : "classes"} left to book
                </>
              }
              description={
                <>
                  Select which classes you want to attend below. You can select
                  multiple classes at one time.{" "}
                  <b>You have until {expireDateDisplay} to select your dates</b>
                </>
              }
              disabled={isContinueDisabled() || isSubmitting}
              nextButtonText={
                selectedSessionsIds.length > 1 ? "Select Dates" : "Select Date"
              }
            >
              <SessionDateList
                currency={currency}
                switchable={false}
                maxSelection={bundle.bundleTotalTickets}
                sessions={sessions.map(session => {
                  const tutor = tutors.find(
                    tutor => tutor._id === session.tutor
                  );
                  const location = locations.find(
                    location => location._id === session.locationId
                  );
                  return {
                    _id: session._id,
                    date: session.startDateTime,
                    description:
                      session.availability === 0
                        ? "Sold out"
                        : `${session.availability} ${
                            session.availability === 1 ? "spot" : "spots"
                          } left`,
                    duration: session.duration,
                    location: session.isOnline ? "Online" : location?.area,
                    selected: selectedSessionsIds.includes(session._id),
                    disabled: isDateDisabled(session) || isSubmitting,
                    tutorName: tutor?.fullName,
                    tutorImageUrl: tutor?.profile?.imageUrl,
                    tutorUrl: tutor?.profile?.links?.bio
                  };
                })}
                selection={selectedSessionsIds}
                onChange={onSelectionChange}
                timezone={timezone}
              />
              {isLoading && <LoadingIcon />}
              {!isFullyLoaded && (
                <PrimaryButton
                  text="See more dates"
                  color="emeraldish"
                  width="auto"
                  disabled={isLoading}
                  onClick={onLoadMoreDates}
                  uppercase
                />
              )}
            </CheckoutSteps.Step>
            {/**We still need to implement this for bundles */}
          </CheckoutSteps>
        </CheckoutPanel>
        <BundleDatesSelectionFooter
          selectedSessions={selectedSessionsIds
            .map(sessionId =>
              sessions.find(session => session._id === sessionId)
            )
            .filter(session => session)}
          timezone={timezone}
        />
      </CheckoutSteps.Provider>

      {isSucceeded && (
        <CenteredPopup
          title="Dates saved successfully"
          onAccept={onSuccessPopupClose}
          acceptButtonText="Close"
        >
          {ticketsLeft == 0
            ? `Congratulations. All of your ${bundle.bundleTotalTickets} classes are booked.`
            : ticketsLeft === 1
            ? `1  class is still left to book. You have until ${expireDateDisplay} to select your dates.`
            : `${ticketsLeft} classes are still left to book. You have until ${expireDateDisplay} to select your dates.`}
        </CenteredPopup>
      )}
      {error && (
        <CenteredPopup
          title="Unable to save the dates"
          onClose={onErrorPopupClose}
        >
          {error}
        </CenteredPopup>
      )}
    </>
  );
}

export namespace BundleDatesSelectionView {
  export interface Props {
    bundle: any;
    cancellationPolicy: any;
    courseTitle: string;
    courseThumbnail: string;
    currency?: string;
    error?: string;
    isLoading: boolean;
    isFullyLoaded: boolean;
    isSucceeded: boolean;
    isSubmitting: boolean;
    locations: any[];
    onContinue: () => void;
    onErrorPopupClose: () => void;
    onLoadMoreDates: () => void;
    onSuccessPopupClose: () => void;
    onSelectionChange: (sessionsIds: string[]) => void;
    selectedSessionsIds: string[];
    sessions: any[];
    timezone?: string;
    tutors: any[];
  }
}
