import React, { useState, useEffect } from "react";
import { TextInput } from "../../../components/TextInput";
import Icon from "../../../components/Icon";
import { Add, Cross } from "../../../components/Icons";
import styles from "./BulletPointsInput.module.less";
import { bemModule } from "../../../configs/bem";
const cn = bemModule(styles, "bullet-points-input");
export function BulletPointsInput(props) {
  const {
    maxChars,
    name,
    onChange = () => {},
    addText = "Add a new bullet point"
  } = props;

  const [items, setItems] = useState(props.items || []);

  useEffect(() => {
    if (items !== props.items) onChange(items, name);
  }, [items]);

  function onAddNewItemClick() {
    setItems([...items, ""]);
  }

  function onItemChange(index, value) {
    if (maxChars && maxChars < value.length) return;

    const newItems = [...items];
    newItems[index] = value;
    setItems(newItems);
  }

  function onItemRemove(index) {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  }

  return (
    <div className={cn()}>
      <ul className={cn("list")}>
        {items.map((item, index) => (
          <li key={index} className={cn("list-item")}>
            <TextInput
              value={item}
              name={name ? `${name}[]` : undefined}
              placeholder="Insert text here"
              onChange={onItemChange.bind(null, index)}
              max={maxChars}
            />
            <span
              className={cn("remove-item")}
              onClick={onItemRemove.bind(null, index)}
            >
              <Icon
                className={cn("remove-item-icon")}
                icon={Cross}
                height={16}
              />
            </span>
          </li>
        ))}
      </ul>
      <div className={cn("add-bullet-point")} onClick={onAddNewItemClick}>
        <Icon className={cn("add-icon")} icon={Add} width={30} height={30} />
        {addText}
      </div>
    </div>
  );
}
