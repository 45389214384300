export function Booking__TotalAttendants(booking) {
  if (booking.bookingType === "private") return booking.adults;
  let total = 0;
  if (booking.tickets.length === 0) return 0;
  let tickets = [];
  // this is maybe legacy. there are no bookings with tickets.0.userInfo defined
  // so this will always be false
  if (booking.tickets[0].userInfo !== undefined) {
    for (let ticketDB of booking.tickets) {
      tickets.push(ticketDB.ticket);
    }
    total = tickets.reduce(
      (total, ticket) => total + 1 * ticket.numberOfGuestsPerTicket,
      0
    );
  } else {
    tickets = booking.tickets;
    total = tickets.reduce(
      (total, ticket) => total + ticket.count * ticket.numberOfGuestsPerTicket,
      0
    );
  }

  return total;
}
