import { animations, css } from "../../sitches.config";

export const styles = {
  dialog: css({
    height: "100%"
  }),

  centered: css({
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    paddingBottom: "98px"
  }),
  sticker: css({
    flexBasis: "120px"
  }),

  success: css({
    color: "$ebonyClay",
    fontFamily: "$foundersGrotesk",
    fontSize: "20px",
    lineHeight: 1.25,
    margin: "$4 0",
    textAlign: "center"
  }),

  title: css({
    color: "$ebonyClay",
    fontFamily: "$foundersGrotesk",
    fontSize: "32px",
    fontWeight: "500",
    lineHeight: 1.25,
    margin: 0,
    paddingBottom: "$2"
  }),

  description: css({
    color: "$ebonyClay",
    fontFamily: "$foundersGrotesk",
    fontSize: "18px",
    lineHeight: 1.25,
    margin: 0,
    paddingBottom: "$2"
  }),
  container: css({
    marginBottom: "$4"
  }),
  button: css({
    marginRight: "$3",
    "&:last-child": {
      marginRight: 0
    }
  }),
  loader: css({
    width: 50,
    height: 50,
    border: "8px solid $neutral",
    borderTopColor: "$callToAction",
    borderRadius: "50%",
    animation: `${animations.rotate} 850ms ease-in-out infinite`
  })
};
