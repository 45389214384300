import React from "react";
import Link from "../Link";
import Icon from "../Icon";
import * as Icons from "../Icons";

import { useTabsContext } from "./TabsContext";
import styles from "./Tab.module.less";

export function Tab(props) {
  const { id, label, disabled, url, notification, restricted } = props;
  const icon = restricted ? Icons.Lock : props.icon;

  const {
    accentColor,
    notificationColor,
    isActive,
    onClick
  } = useTabsContext();

  return (
    <div id={id} className={styles[`tab`]} onClick={props.onClick}>
      <div
        className={`${styles[`tab__wrapper`]} ${
          styles[`tab__wrapper--accent-${accentColor}`]
        } ${isActive ? styles[`tab__wrapper--active`] : ""}`}
      >
        {url ? (
          <Link
            href={url}
            className={styles["tab__label"]}
            target="_blank"
            onClick={disabled ? undefined : onClick}
          >
            {label}
          </Link>
        ) : (
          <span
            className={`
						${styles[`tab__label`]}
						${restricted || disabled ? styles["tab__label--disabled"] : ""}
					`}
            onClick={disabled ? undefined : onClick}
          >
            {label}
          </span>
        )}
        {notification && (
          <span
            className={`${styles[`tab__notification`]} ${
              styles[`tab__notification--color-${notificationColor}`]
            }`}
          >
            {notification}
          </span>
        )}
        {icon && (
          <Icon
            className={`${styles[`tab__icon`]} ${
              restricted || disabled ? styles[`tab__icon--disabled`] : ""
            }`}
            icon={icon}
            height={14}
          />
        )}
      </div>
    </div>
  );
}
