import { UTCDate__Get } from "./UTCDate__Get";
import { UTCDate__FormattedDate } from "./UTCDate__FormattedDate";
import { comparator } from "./comparator";
import { Timezones } from "@obby/constants";
import { UTCDate__DiffOf } from "./UTCDate__DiffOf";

export function Sessions__Schedules(
  sessions: any[],
  timezone: string = Timezones.EUROPE_LONDON
): Schedule[] {
  const schedules = sessions.reduce<{ [key: number]: number[] }>(
    (schedules, session) => {
      const dayOfWeek = UTCDate__Get(
        session.startDateTime,
        timezone,
        "isoWeekday"
      );
      const time = UTCDate__DiffOf(
        session.startDateTime,
        timezone,
        "minutes",
        "day"
      );
      if (!schedules[dayOfWeek].includes(time)) schedules[dayOfWeek].push(time);
      return schedules;
    },
    { 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [] }
  );

  return Object.entries(schedules).reduce<Schedule[]>(
    (schedules, [dayOfWeek, times]) => {
      schedules.push(
        ...times.sort(comparator).map(time => ({
          dayOfWeek: parseInt(dayOfWeek),
          time
        }))
      );
      return schedules;
    },
    []
  );
}

interface Schedule {
  dayOfWeek: number;
  time: number;
}
