import { css } from "../../sitches.config";

// The class card is designed
// to have a consistent height so that it looks uniform in a grid
// or rail. Both width and height are 100% because the exact dimensions of the card
// are expected to be set by the parent card wrapper.
export const styles = {
  classCard: css({
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    borderRadius: "20px",
    position: "relative",
    overflow: "hidden",
    "a, a:hover": {
      textDecoration: "none"
    }
  }),

  favouriteButton: css({
    position: "absolute",
    top: "16px",
    right: "16px"
  }),

  thumbnailImage: css({
    width: "100%"
  }),

  content: css({
    display: "flex",
    flexDirection: "column",
    color: "$ebonyClay",
    padding: "15px 15px 20px",

    "@md": {
      padding: "15px 15px 28px"
    }
  }),

  title: css({
    display: "block",
    fontFamily: "$foundersGrotesk",
    fontSize: "$smParagraph",
    fontWeight: "500",
    lineHeight: "1.3",
    textDecoration: "none",
    letterSpacing: "0.5px",
    color: "$ebonyClay",
    "-webkit-font-smoothing": "antialiased",
    paddingBottom: "5px",
    wordBreak: "break-word",

    "&:hover, &:visited, &:active, &:focus": {
      color: "$ebonyClay",
      textDecoration: "none"
    }
  }),

  reviews: css({
    flexGrow: "0",
    fontSize: "$xsmParagraph",
    marginTop: "10px",
    letterSpacing: "0.02em"
  }),

  reviewsSkeleton: css({
    width: "100px"
  }),

  ratingStar: css({
    marginRight: "4px",
    position: "relative",
    top: "1px",

    polygon: {
      fill: "$emeraldish"
    }
  }),

  ratingScore: css({
    display: "inline-block",
    fontFamily: "Helvetica, sans-serif",
    lineHeight: "1.2",
    fontWeight: "400",
    letterSpacing: "0.5px",
    paddingRight: "5px",

    "@md": {
      fontSize: "$xsmParagraph"
    }
  }),

  opaqueText: css({
    fontFamily: "Helvetica, sans-serif",
    fontSize: "$xsmParagraph",
    lineHeight: "1.2",
    fontWeight: "400",
    color: "$gray",
    letterSpacing: "0.5px"
  }),

  pipe: css({
    color: "#93939d",
    margin: "0 2px",
    fontWeight: "bold"
  }),

  price: css({
    flexGrow: "0",
    letterSpacing: "0.02em",
    marginTop: "10px",
    variants: {
      withDiscount: {
        true: {
          textDecoration: "line-through"
        }
      }
    }
  }),

  priceSkeleton: css({
    width: "50px"
  })
};
