import React from "react";
import PropTypes from "prop-types";
import RatingSummary from "../RatingSummary";
import {
  CurrencyISO__CurrencySymbol,
  Discount__DiscountedPrice
} from "@obby/lib";

// libs
import { Number_Currency } from "@obby/lib";

import { PrimaryButton } from "../../buttons/PrimaryButton";
import { SecondaryButton } from "../../buttons/SecondaryButton";
import ScrollLink from "../ScrollLink";
import { HighlightPrice } from "../../badges/HighlightPrice";
import styles from "./class-page-menu.module.less";
const css = {
  height: "4rem",
  maxWidth: "250px"
};

const ClassPageMenu = ({
  productPage,
  menuItems,
  menuActions,
  onMenuItemClick,
  mobile,
  additionalPrice = [],
  discount,
  ratingSummaryProps: { rating, reviewCount } = { rating: 0, reviewCount: 0 },
  price,
  currency
}) => {
  const onClickProxy = menuItem => {
    return event => {
      if (onMenuItemClick) {
        onMenuItemClick(menuItem, event);
      }
    };
  };

  return (
    <div
      className={
        mobile
          ? `${styles["class-page-menu"]} mobile`
          : styles["class-page-menu"]
      }
    >
      <div className={styles["class-page-menu__wrapper"]}>
        <div className={styles["class-page-menu__menu-list"]}>
          {menuItems.map((menuItem, index) => (
            <ScrollLink
              key={index}
              href={menuItem.link}
              className={styles["class-page-menu__link"]}
              onClick={onClickProxy(menuItem)}
              offset={menuItem.offset}
            >
              {menuItem.label}
            </ScrollLink>
          ))}
        </div>
        <div className={styles["class-page-menu__book-wrapper"]}>
          <div className={styles["class-page-menu__actions"]}>
            {menuActions &&
              menuActions.reduce(function(filtered, action, index) {
                const Button =
                  action.type === ClassPageMenu.menuActionTypes.SECONDARY
                    ? SecondaryButton
                    : PrimaryButton;
                if (typeof action.hidden == "undefined" || !action.hidden) {
                  filtered.push(
                    <Button
                      key={index}
                      onClick={action.onClick}
                      text={action.text}
                      width={action.width}
                      textMode={action.textMode || "uppercase"}
                      color={action.color || "robbinsEggBlue"}
                      disabled={action.disabled || false}
                      className={styles["class-page-menu__action"]}
                      size="large"
                      css={{
                        width: "12.5rem",
                        fontSize: "$14",
                        letterSpacing: "1px",
                        maxWidth: "11.3rem",
                        paddingX: "$8"
                      }}
                    />
                  );
                }

                return filtered;
              }, [])}
            <div
              className={
                reviewCount > 0
                  ? styles["class-page-menu__reviews"]
                  : `${styles["class-page-menu__reviews"]} ${styles["class-page-menu__reviews--no-reviews"]}`
              }
            >
              <span className="d-inline-block">
                {discount && (
                  <HighlightPrice
                    price={Discount__DiscountedPrice(discount, price)}
                  />
                )}
                <div
                  className={`class-page-menu__price ${
                    discount
                      ? styles["class-page-menu__price--with-discount"]
                      : ""
                  }`}
                >
                  {additionalPrice.length > 0 ? "from " : ""}
                  <span className="d-inline-block">
                    {productPage
                      ? Number_Currency(
                          price,
                          CurrencyISO__CurrencySymbol(currency)
                        )
                      : `${Number_Currency(
                          price,
                          CurrencyISO__CurrencySymbol(currency)
                        )} pp`}
                  </span>
                </div>
              </span>
              {reviewCount > 0 && (
                <a href="#latest-reviews">
                  <RatingSummary
                    rating={rating}
                    reviewCount={reviewCount}
                    iconSize={15}
                    primaryColor="emeraldish"
                  />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ClassPageMenu.menuActionTypes = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY"
};

ClassPageMenu.propTypes = {
  menuItems: PropTypes.array.isRequired,
  menuActions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      textMode: PropTypes.string,
      color: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
      type: PropTypes.oneOf([
        ClassPageMenu.menuActionTypes.PRIMARY,
        ClassPageMenu.menuActionTypes.SECONDARY
      ])
    })
  ).isRequired
};

export default ClassPageMenu;
