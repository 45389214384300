import React from "react";
import { css } from "../../sitches.config";
import { RoundedButton } from "../RoundedButton";

export function PrimaryButton(props: PrimaryButton.Props) {
  const {
    backgroundColorHex,
    borderColorHex = backgroundColorHex,
    className = "",
    colorHex,
    color = "callToAction",
    css,
    disabled = false,
    icon,
    isLoading,
    locked,
    onClick,
    onMouseOver,
    size = "large",
    tag,
    text,
    /** @deprecated */
    textMode = "normal",
    type = "button",
    uppercase = textMode === "uppercase",
    width
  } = props;

  return (
    <RoundedButton
      backgroundColorHex={backgroundColorHex}
      borderColorHex={borderColorHex}
      className={styles.button({
        className: `primary-button ${className}`,
        css
      })}
      color={color}
      colorHex={colorHex}
      disabled={disabled}
      elem="button"
      elemProps={{
        type
      }}
      icon={icon}
      isLoading={isLoading}
      locked={locked}
      onClick={onClick}
      onMouseOver={onMouseOver}
      size={size}
      text={text}
      tag={tag}
      uppercase={uppercase}
      width={width}
    />
  );
}

const styles = {
  button: css({})
};

export namespace PrimaryButton {
  export interface Props
    extends Omit<RoundedButton.Props, "elem" | "elemProps"> {
    css?: { [key: string]: any };
    /** @deprecated */
    textMode?: "normal" | "uppercase";
    type?: "button" | "submit" | "reset";
  }
}
