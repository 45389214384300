import * as Yup from "yup";
export function UserCancelBookingFormSchema(defaults = {}) {
  defaults = {
    reason: "",
    message: "",
    refundMethod: undefined,
    ...defaults
  };
  return Yup.object({
    reason: Yup.string()
      .label("Reason")
      .required()
      .default(defaults.reason),
    message: Yup.string()
      .label("Message")
      .default(defaults.message),
    refundMethod: Yup.string()
      .label("Refund method")
      .default(defaults.refundMethod)
  });
}
