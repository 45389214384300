import React, { useState } from "react";
import { CenteredPopup } from "../../modals/CenteredPopup";

export function DisabledBookingPopup({ onClose }: Props) {
  return (
    <CenteredPopup
      title={"Obby as been acquired by Classbento"}
      closeButtonPosition="cross"
      onClose={onClose}
      acceptButtonText="Send Request"
      size="medium"
    >
      <div>
        Obby has recently been acquired by Classbento and therefore we are
        currently unable to accept new bookings. Please visit{" "}
        <a href="https://classbento.co.uk/">Classbento.co.uk</a>
        to find classes to book!
      </div>
    </CenteredPopup>
  );
}

interface Props {
  onClose: () => void;
}
