import React from "react";

import { bem } from "../../configs/bem";
import { css } from "../../sitches.config";

const cn = bem("section");
export function Section(props) {
  const { title, description, children } = props;
  return (
    <div className={`${cn()} ${styles.section}`}>
      <div className={`${cn("title")} ${styles.title}`}>{title}</div>
      {description && (
        <div className={`${cn("description")} ${styles.description}`}>
          {description}
        </div>
      )}
      <div className="mt-4">{children}</div>
    </div>
  );
}

const styles = {
  section: css({
    padding: "50px 0 0",

    "@md": {
      padding: "50px 0 50px"
    }
  }),

  title: css({
    fontFamily: "$foundersGroteskCondensed",
    fontSize: "36px",
    color: "$ebonyClay",
    lineHeight: "1",
    textTransform: "uppercase",

    "@md": {
      fontSize: "40px"
    }
  }),

  description: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "18px",
    color: "$ebonyClay",

    "@md": {
      fontSize: "20px"
    }
  })
};
