import { store } from "statorgfc";
import { Subject } from "rxjs";

const getDefaultModel = initialData => {
  return {
    errors: {
      general: "",
      payment: "",
      deliveryAddress: "",
      discount: "",
      userProfile: "",
      userPassword: ""
    }
  };
};

const events = {
  general: new Subject(),
  payment: new Subject(),
  deliveryAddress: new Subject(),
  discount: new Subject(),
  userProfile: new Subject(),
  userPassword: new Subject(),
  clear: new Subject(),
  clearAll: new Subject()
};

events.general.subscribe(onErrorChange("general"));
events.payment.subscribe(onErrorChange("payment"));
events.deliveryAddress.subscribe(onErrorChange("deliveryAddress"));
events.discount.subscribe(onErrorChange("discount"));
events.userProfile.subscribe(onErrorChange("userProfile"));
events.userPassword.subscribe(onErrorChange("userPassword"));
events.clear.subscribe(onClear);
events.clearAll.subscribe(onClearAll);

function onErrorChange(error) {
  return message => {
    const model = store.get("errors");
    store.set("errors", { ...model, [error]: message });
  };
}

function onClear(error) {
  const model = store.get("errors");
  store.set("errors", { ...model, [error]: "" });
}

function onClearAll() {
  store.set("errors", getDefaultModel().errors);
}

export default {
  events,
  getDefaultModel
};
