import { styled } from "../../sitches.config";

export const Box = styled("div", {
  variants: {
    variant: {
      content: {
        marginBottom: "$24"
      },

      infoWrapper: {
        display: "flex"
      },

      info: {
        fontFamily: "$foundersGrotesk",
        fontWeight: "500",
        letterSpacing: "0.03em"
      },

      infoValue: {
        color: "$ebonyClay",
        fontSize: "$18"
      },

      subDescription: {
        marginTop: "$16",
        display: "inline-block",

        "&:before": {
          display: "block",
          content: '" "',
          height: "4px",
          width: "100%",
          borderRadius: "2px",
          backgroundColor: "$coral"
        }
      },

      subDescriptionTitle: {
        fontFamily: "$foundersGrotesk",
        fontSize: "$16",
        textTransform: "uppercase",
        letterSpacing: "0.03em",
        color: "$silverChalice",
        padding: "0 5px 3px"
      },

      subDescriptionTxt: {
        fontFamily: "$foundersGrotesk",
        fontWeight: "500",
        fontSize: "$18",
        padding: "0 5px",
        lineHeight: "1",
        color: "$ebonyClay"
      }
    }
  }
});

export const StoriesWrapper = styled("div", {
  margin: "0 -15px",
  "@md": {
    margin: 0,
    overflow: "hidden",
    borderRadius: "$2"
  }
});

export const TextH1 = styled("h1", {
  display: "block",
  fontFamily: "$foundersGroteskCondensed",
  fontWeight: "bold",
  fontSize: "$28",
  paddingY: "$8",
  lineHeight: "90%",
  color: "$ebonyClay",
  textTransform: "uppercase",

  "@sm": {
    fontSize: "$36"
  },
  "@md": {
    fontSize: "$52"
  }
});

export const Label = styled("label", {
  color: "$silverChalice",
  fontSize: "$16",
  display: "block"
});

export const Span = styled("span", {
  variants: {
    variant: {
      description: {
        display: "block",
        fontFamily: "$foundersGrotesk",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "$18",
        lineHeight: "144%"
      }
    },
    lineThrough: {
      true: {
        textDecoration: "line-through"
      }
    }
  }
});
