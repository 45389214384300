import { curry, merge } from "ramda";

const String__TruncatedString = curry((givenOptions, string) => {
  var slice = merge({ length: 20, postfix: "..." }, givenOptions);
  if (string.length <= slice.length) {
    return string;
  }
  return `${string.slice(0, slice.length - slice.postfix.length)}${
    slice.postfix
  }`;
});

export default String__TruncatedString;
