import React, { useEffect, useRef, useContext } from "react";
import { TogglePopoutContext } from "./TogglePopoutContext";
import { bemModule } from "../../configs/bem";
import styles from "./TogglePopoutModal.module.less";

const cn = bemModule(styles, "toggle-popout-modal");
export function TogglePopoutModal(props) {
  const {
    children,
    bottom,
    top = bottom === undefined ? true : undefined,
    left,
    right = left === undefined ? true : undefined
  } = props;

  const { open, onBlur } = useContext(TogglePopoutContext);

  const ref = useRef();
  useEffect(() => {
    if (open) ref.current.focus();
  }, [open]);

  function onModalBlur(e) {
    if (!ref.current.contains(e.relatedTarget)) onBlur(e);
  }

  function onModalClick(e) {
    if (!e.isDefaultPrevented()) onBlur(e);
  }

  return (
    <>
      {open && (
        <div className={cn()}>
          <div
            className={cn("container", { top, right, bottom, left }, [
              "p-4 p-sm-0"
            ])}
            ref={ref}
            tabIndex={-1}
            onBlur={onModalBlur}
            onClick={onModalClick}
          >
            {children}
          </div>
          <div className={cn("align")} />
        </div>
      )}
    </>
  );
}
