import Course__ClassListCard from "./Course__ClassListCard";
import String__KebabCase from "./String__KebabCase";

const ClassSubListResults__ClassSubList = classSubList => {
  return {
    ...classSubList,
    id: String__KebabCase(classSubList.title),
    classes: classSubList.classes.map(Course__ClassListCard)
  };
};

export default ClassSubListResults__ClassSubList;
