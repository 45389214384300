import { BookingTypes } from "@obby/constants";

export function Booking__BundleBooking(bookingId, booking) {
  if (booking.bookingType !== BookingTypes.BUNDLE || booking._id === bookingId)
    return booking;

  const index = booking.bundleRecords.findIndex(
    record => record._id === bookingId
  );
  const record = booking.bundleRecords[index];
  booking._id = record._id;
  booking.session = record.session;
  booking.createdAt = record.assignmentDate;
  booking.updatedAt = record.assignmentDate;
  booking.bookingType = BookingTypes.BUNDLE_BOOKING;
  booking.bundleId = booking._id;
  booking.bundleIndex = index;
  booking.confirmation = record.confirmation || booking.confirmation;
  booking.rejectReason = record.rejectReason || booking.rejectReason;
  booking.notes = record.notes;
  return booking;
}
