import React, { useState, useEffect } from "react";
import { DayPickerInput } from "../../inputs/DayPickerInput/DayPickerInput";
import { Label } from "../../components/Label";
import { SelectBox } from "../../inputs/SelectBox";
import { FlexitimeFormSchema } from "./FlexitimeForm.schema";
import { Timezones } from "@obby/constants";
import {
  comparator,
  UTCDate__Add,
  UTCDate__FormattedDate,
  UTCDate__Get,
  UTCDate__IsAfter,
  UTCDate__IsBefore,
  UTCDate__IsSameOrAfter,
  UTCDate__IsSameOrBefore,
  UTCDate__StartOf
} from "@obby/lib";

export function FlexitimeForm({
  duration,
  daysOfWeek = [0, 1, 2, 3, 4, 5, 6],
  errors,
  hoursNotice = 0,
  isOnline,
  onChange,
  onBlur,
  timezone = Timezones.EUROPE_LONDON,
  touched,
  values,
  flexitimeBlockBlocks = [],
  ...props
}) {
  const localTimezone =
    Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? timezone;

  const [baseTimes, setBaseTimes] = useState(() => getBaseTimes());
  useEffect(() => {
    setBaseTimes(getBaseTimes());
  }, [props.times, timezone]);

  const [times, setTimes] = useState(baseTimes);

  useEffect(() => {
    if (values.date) {
      const times = baseTimes.filter(minutes =>
        isTimeSchedulable(minutes, values.date?.toISOString())
      );
      setTimes(times);
    }
  }, [values.date, baseTimes]);

  useEffect(() => {
    if (values.time && !times.includes(values.time)) onChange(times[0], "time");
  }, [times]);

  function isTimeSchedulable(time, date) {
    const day = UTCDate__StartOf(date, timezone, "day");

    let dateTime = UTCDate__Add(day, time, "minutes");
    const dayOfWeek = UTCDate__Get(dateTime, timezone, "isoWeekday") % 7;
    if (!daysOfWeek.includes(dayOfWeek)) return false;

    const noticeDate = UTCDate__Add(dateTime, -hoursNotice, "hours");
    return UTCDate__IsAfter(noticeDate);
  }

  function getBaseTimes() {
    if (!isOnline) return props.times;

    const today = UTCDate__StartOf(undefined, timezone, "day");
    const localToday = UTCDate__StartOf(undefined, localTimezone, "day");

    return props.times
      .map(time => {
        const date = UTCDate__Add(today, time, "minutes");
        if (UTCDate__IsBefore(date, localToday, "day", localTimezone))
          time += 1440;
        if (UTCDate__IsAfter(date, localToday, "day", localTimezone))
          time -= 1440;
        return time;
      })
      .sort((time1, time2) => comparator(time1, time2));
  }

  function getDisabledBefore() {
    let { daysNotice = 0 } = props;
    daysNotice = Math.max(daysNotice, 0);
    if (daysNotice === 0) {
      // check by the given hours notice if we still have time for today. if not return 1 day notice to prevent today
      // to show up enabled on day picker
      if (!times.some(minutes => isTimeSchedulable(minutes))) daysNotice = 1;
    }

    const today = UTCDate__StartOf(undefined, timezone, "day");
    return UTCDate__Add(today, daysNotice, "days");
  }

  function formatTime(time) {
    let date = UTCDate__StartOf(undefined, timezone, "day");
    date = UTCDate__Add(date, time, "minutes");
    if (isOnline) return UTCDate__FormattedDate(date, localTimezone, "HH:mm");
    else return UTCDate__FormattedDate(date, timezone, "HH:mm");
  }

  return (
    <div className="flexitime-form">
      <Label label="Date" error={touched?.date && errors?.date} condensed>
        <DayPickerInput
          placeholder="Select date"
          value={values.date?.toISOString()}
          onChange={date => onChange(new Date(date), "date")}
          disabledDaysOfWeek={[0, 1, 2, 3, 4, 5, 6].filter(
            dayOfWeek => !daysOfWeek.includes(dayOfWeek)
          )}
          disabledBefore={getDisabledBefore()}
          timezone={timezone}
        />
      </Label>
      <Label label="Time" error={touched?.time && errors?.time} condensed>
        <SelectBox
          name="time"
          placeholder="Select time"
          value={values.time}
          onChange={onChange}
          disabled={!values.date?.toISOString()}
        >
          {times
            .filter(time => {
              // remove the block that are overlapping with the blocks
              let isNotValid = false;
              if (values.date) {
                // if we have a day verify if the user can select the time
                const startDateTime = UTCDate__Add(
                  values.date,
                  time,
                  "minutes",
                  timezone
                );
                // end time
                const endDateTime = UTCDate__Add(
                  values.date,
                  time + duration,
                  "minutes",
                  timezone
                );
                // now we will go through each of the flexitime block
                // if the start time or end time is within of flexitime block we will return false
                // since this is not a valid time
                isNotValid = flexitimeBlockBlocks.some(timeBlock => {
                  // simpler way to check for inclusive overlaps. In order to change to exclusive overlap just replace
                  // UTCDate__IsSameOrBefore and UTCDate__IsSameOrAfter with UTCDate__IsBefore and UTCDate__IsAfter accordingly
                  // see https://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap#answer-325964
                  return (
                    UTCDate__IsSameOrBefore(
                      startDateTime,
                      timeBlock.endTime,
                      "minute",
                      timezone
                    ) &&
                    UTCDate__IsSameOrAfter(
                      endDateTime,
                      timeBlock.startTime,
                      "minute",
                      timezone
                    )
                  );
                });
              }
              return !isNotValid;
            })
            .map(time => {
              const start = formatTime(time);
              const end = formatTime(time + duration);

              return (
                <SelectBox.Option key={time} value={time}>
                  {start} to {end}
                </SelectBox.Option>
              );
            })}
        </SelectBox>
      </Label>
    </div>
  );
}

FlexitimeForm.Schema = FlexitimeFormSchema;
