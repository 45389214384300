import { Currencies } from "./Currencies";
export const CurrenciesSymbols = {
  [Currencies.EUR]: "€",
  [Currencies.GBP]: "£",
  [Currencies.USD]: "$",
  [Currencies.AED]: "د.إ",
  [Currencies.AFN]: "؋",
  [Currencies.ALL]: "L",
  [Currencies.AMD]: "֏",
  [Currencies.ANG]: "ƒ",
  [Currencies.AOA]: "Kz",
  [Currencies.ARS]: "$",
  [Currencies.AUD]: "$",
  [Currencies.AWG]: "ƒ",
  [Currencies.AZN]: "₼",
  [Currencies.BAM]: "KM",
  [Currencies.BBD]: "$",
  [Currencies.BDT]: "৳",
  [Currencies.BGN]: "лв",
  [Currencies.BMD]: "$",
  [Currencies.BND]: "$",
  [Currencies.BOB]: "$b",
  [Currencies.BRL]: "R$",
  [Currencies.BSD]: "$",
  [Currencies.BWP]: "P",
  [Currencies.BYN]: "Br",
  [Currencies.BZD]: "BZ$",
  [Currencies.CAD]: "$",
  [Currencies.CDF]: "FC",
  [Currencies.CHF]: "CHF",
  [Currencies.COP]: "$",
  [Currencies.CRC]: "₡",
  [Currencies.CVE]: "$",
  [Currencies.CZK]: "Kč",
  [Currencies.DKK]: "kr",
  [Currencies.DOP]: "RD$",
  [Currencies.DZD]: "دج",
  [Currencies.EGP]: "£",
  [Currencies.ETB]: "Br",
  [Currencies.FJD]: "$",
  [Currencies.FKP]: "£",
  [Currencies.GEL]: "₾",
  [Currencies.GIP]: "£",
  [Currencies.GMD]: "D",
  [Currencies.GTQ]: "Q",
  [Currencies.GYD]: "$",
  [Currencies.HKD]: "$",
  [Currencies.HNL]: "L",
  [Currencies.HRK]: "kn",
  [Currencies.HTG]: "G",
  [Currencies.HUF]: "Ft",
  [Currencies.IDR]: "Rp",
  [Currencies.ILS]: "₪",
  [Currencies.INR]: "₹",
  [Currencies.ISK]: "kr",
  [Currencies.JMD]: "J$",
  [Currencies.KES]: "KSh",
  [Currencies.KGS]: "лв",
  [Currencies.KHR]: "៛",
  [Currencies.KYD]: "$",
  [Currencies.KZT]: "₸",
  [Currencies.LAK]: "₭",
  [Currencies.LBP]: "£",
  [Currencies.LKR]: "₨",
  [Currencies.LRD]: "$",
  [Currencies.LSL]: "M",
  [Currencies.MAD]: "MAD",
  [Currencies.MDL]: "lei",
  [Currencies.MKD]: "ден",
  [Currencies.MMK]: "K",
  [Currencies.MNT]: "₮",
  [Currencies.MOP]: "MOP$",
  [Currencies.MRO]: "UM",
  [Currencies.MUR]: "₨",
  [Currencies.MVR]: "Rf",
  [Currencies.MWK]: "MK",
  [Currencies.MXN]: "$",
  [Currencies.MYR]: "RM",
  [Currencies.MZN]: "MT",
  [Currencies.NAD]: "$",
  [Currencies.NGN]: "₦",
  [Currencies.NIO]: "C$",
  [Currencies.NOK]: "kr",
  [Currencies.NPR]: "₨",
  [Currencies.NZD]: "$",
  [Currencies.PAB]: "B/.",
  [Currencies.PEN]: "S/.",
  [Currencies.PGK]: "K",
  [Currencies.PHP]: "₱",
  [Currencies.PKR]: "₨",
  [Currencies.PLN]: "zł",
  [Currencies.QAR]: "﷼",
  [Currencies.RON]: "lei",
  [Currencies.RSD]: "Дин.",
  [Currencies.SAR]: "﷼",
  [Currencies.SBD]: "$",
  [Currencies.SCR]: "₨",
  [Currencies.SEK]: "kr",
  [Currencies.SGD]: "S$",
  [Currencies.SHP]: "£",
  [Currencies.SLL]: "Le",
  [Currencies.SOS]: "S",
  [Currencies.SRD]: "$",
  [Currencies.STD]: "Db",
  [Currencies.SZL]: "E",
  [Currencies.THB]: "฿",
  [Currencies.TJS]: "SM",
  [Currencies.TOP]: "T$",
  [Currencies.TRY]: "₺",
  [Currencies.TTD]: "TT$",
  [Currencies.TWD]: "NT$",
  [Currencies.TZS]: "TSh",
  [Currencies.UAH]: "₴",
  [Currencies.UYU]: "$U",
  [Currencies.UZS]: "лв",
  [Currencies.WST]: "WS$",
  [Currencies.XCD]: "$",
  [Currencies.YER]: "﷼",
  [Currencies.ZAR]: "R",
  [Currencies.ZMW]: "ZK"
};
