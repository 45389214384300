import { css } from "../../sitches.config";

export const styles = {
  container: css({
    /*
    height: 0,
    transition: "height 250ms ease, padding-bottom 250ms ease",
    overflow: "hidden"
    */
    variants: {
      closed: {
        true: {
          display: "none"
        }
      }
    }
  })
};
