import React from "react";
import { css } from "../../sitches.config";

import { DayPickerInput } from "../../inputs/DayPickerInput/DayPickerInput";

const NewDatePickerFilterBody = ({ onChange, value }) => {
  return (
    <div className={`new-date-picker-filter-body ${css({ width: "100%" })}`}>
      <DayPickerInput
        value={value}
        onChange={onChange}
        displayFormat="DD/MM/YYYY"
        disabledPastDays
      />
    </div>
  );
};

export default NewDatePickerFilterBody;
