import moment from "moment-timezone";
export function updateFiltersBasedOnURL(queryString = {}) {
  let filters = [];
  const { tag, format, level, date, hidePrivate, location } = queryString;
  if (tag) {
    for (let tagRecord of tag) {
      filters.push({
        type: "tag",
        value: tagRecord,
        label: tagRecord,
        fixed: true
      });
    }
  }

  if (format) {
    filters.push({
      type: "format",
      value: format,
      label: format,
      hidden: true
    });
  }
  if (level) {
    filters.push({
      type: "level",
      value: level,
      label: level
    });
  }
  if (date) {
    filters.push({
      type: "date",
      hidden: false,
      value: date.replace(" ", "+"),
      label: moment(date.replace(" ", "+")).format("DD/MM/YYYY"),
      subType: false
    });
  }
  if (hidePrivate) {
    filters.push({
      type: "hidePrivate",
      value: true,
      label: "Hide Private Events"
    });
  }
  if (queryString.q) {
    filters.push({
      type: "q",
      value: queryString.q,
      label: queryString.q
    });
  }
  let keywordContent = false;
  if (queryString.keyword) {
    console.log("exists keyword");
    keywordContent = queryString.keyword;
  }
  if (queryString["keyword[]"]) {
    console.log("exists keyword");
    keywordContent = queryString["keyword[]"];
  }
  if (keywordContent && keywordContent.split)
    keywordContent.split(",").forEach(keyword =>
      filters.push({
        type: "keyword",
        value: keyword,
        label: keyword,
        subType: keyword,
        hidden: false
      })
    );
  if (location && location.split)
    location
      .split(",")
      .forEach(location =>
        filters.push({ type: "location", value: location, label: location })
      );
  return filters;
}
