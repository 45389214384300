import React from "react";
import moment from "moment";
import { bemModule } from "../../configs/bem";

import { CloudinaryImage } from "../../components/CloudinaryImage";
import Icon from "../../components/Icon";
import * as Icons from "../../components/Icons";
import { HighlightPrice } from "../../badges/HighlightPrice";
import RatingSummary from "../../components/RatingSummary";
import {
  Discount__DiscountedPrice,
  Number__Currency,
  CurrencyISO__CurrencySymbol
} from "@obby/lib";

import styles from "./MixedCard.module.less";

const cn = bemModule(styles, "mixed-card");
export function MixedCard({
  price,
  currency = "GBP",
  samePriceAlways = true,
  image,
  title,
  rating,
  numberOfReviews,
  type,
  hasMaterials,
  isFlexitime,
  dates = [],
  stock,
  hasVideoContent,
  isOnline,
  location,
  discount
}) {
  function getItemLabel() {
    switch (true) {
      case type === "product" && hasVideoContent:
        return "watch video tutorial";
      case type === "product" && !hasVideoContent:
        return "follow step by step guide";
      case type === "class" && isOnline:
        return "join live online";
      case type === "class" && !isOnline:
        return "join in person";
      default:
        return "";
    }
  }

  return (
    <div className={cn()}>
      <CloudinaryImage
        className={cn("image")}
        src={image}
        width={450}
        height={300}
      />
      <div className={cn("details", null, ["px-3 pb-3"])}>
        <h3 className={cn("title", null, ["my-3"])}>{title}</h3>

        <div className={cn("data-list")}>
          <DataListItem icon={Icons.VideoCamera}>
            <div
              className={
                isOnline
                  ? `${cn("type", {
                      type
                    })} ${cn("type", {
                      type
                    })}--online`
                  : cn("type", {
                      type
                    })
              }
            >
              <span className={cn("type-label")}>{getItemLabel()}</span>
            </div>
          </DataListItem>
          {location && (
            <DataListItem icon={Icons.LocationBold}>{location}</DataListItem>
          )}
          {(hasMaterials || type === "product") && (
            <DataListItem icon={Icons.Delivery}>
              kit delivered to you
            </DataListItem>
          )}
          {dates.length > 0 && (
            <DataListItem icon={Icons.Agenda} ellipsis>
              book for{" "}
              {dates.map(date => moment(date).format("Do MMM")).join(", ")}
            </DataListItem>
          )}
          {isFlexitime && (
            <DataListItem icon={Icons.LockInCircle}>
              {dates.length
                ? "or book a private session"
                : "book a private session anytime"}
            </DataListItem>
          )}
          {type === "product" && stock !== undefined && (
            <DataListItem icon={Icons.CartCheck}>
              {stock === 0
                ? "out of stock"
                : stock < 5
                ? "low stock"
                : "in stock"}
            </DataListItem>
          )}
        </div>
        <div className={cn("footer")}>
          <div>
            {discount && (
              <HighlightPrice
                price={Discount__DiscountedPrice(discount, price)}
                currency={currency}
              />
            )}

            <span className={cn("price", { discount: !!discount })}>
              {!samePriceAlways && "from "}{" "}
              {Number__Currency(price, CurrencyISO__CurrencySymbol(currency))}
            </span>
          </div>
          {numberOfReviews > 0 && (
            <RatingSummary
              rating={rating}
              numberOfReviews={numberOfReviews}
              short
              starColor="emeraldish"
              starSize="small"
            />
          )}
        </div>
      </div>
    </div>
  );
}

function DataListItem(props) {
  const { icon, children, ellipsis } = props;
  return (
    <div className={cn("data", null, ["mb-2"])}>
      <div className={cn("icon-wrapper")}>
        <Icon className={cn("icon")} icon={icon} height={16} />
      </div>
      <div className={cn("data-text", { ellipsis })}>{children}</div>
    </div>
  );
}
