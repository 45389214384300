import React, { useContext, useEffect } from "react";
import { FaIcon, Flex, useIndex, Breakpoint } from "@obby/components";
import { TableContext } from "./TableContext";
import { faSortUp } from "@fortawesome/free-solid-svg-icons/faSortUp";
import { faSortDown } from "@fortawesome/free-solid-svg-icons/faSortDown";
import { styles } from "./TableHeaderCell.styles";
import { useVisibleColumn } from "./useVisibleColumn";

export function TableHeaderCell({
  align = "center",
  children,
  className,
  disabled = false,
  icon,
  onClick,
  sortable,
  ...props
}: React.PropsWithChildren<Props>) {
  const index = useIndex();
  const {
    onOrderChange,
    onOrderClear,
    orderBy,
    order,
    setVisibleColumn
  } = useContext(TableContext);

  useEffect(() => {
    setVisibleColumn(index, props.visible);
  }, [props.visible]);

  const visible = useVisibleColumn();

  function onCellClick(e: React.MouseEvent<HTMLTableCellElement>) {
    if (sortable) {
      if (sortable === orderBy && order === "desc" && onOrderClear)
        onOrderClear();
      else
        onOrderChange(
          sortable,
          sortable !== orderBy || order === "desc" ? "asc" : "desc"
        );
    }
    onClick && onClick(e);
  }

  function onSortAsc(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    if (sortable === orderBy && order === "asc" && onOrderClear) onOrderClear();
    else onOrderChange(sortable!, "asc");
  }

  function onSortDesc(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    if (sortable === orderBy && order === "desc" && onOrderClear)
      onOrderClear();
    else onOrderChange(sortable!, "desc");
  }

  if (!visible) return null;

  return (
    <th
      className={styles.tableHeaderCell({ className, disabled })}
      onClick={onCellClick}
      {...props}
    >
      <Flex
        alignItems="center"
        justifyContent={
          { left: "flex-start", center: "center", right: "flex-end" }[align]
        }
        gap={1}
      >
        {icon && <FaIcon icon={icon} size={16} />}
        {children}
        {!disabled && sortable && (
          <Flex column>
            <div
              onClick={onSortAsc}
              className={styles.sort({
                selected: orderBy === sortable && order === "asc"
              })}
            >
              <FaIcon icon={faSortUp} className={styles.sortUp()} />
            </div>
            <div
              onClick={onSortDesc}
              className={styles.sort({
                selected: orderBy === sortable && order === "desc"
              })}
            >
              <FaIcon icon={faSortDown} className={styles.sortDown()} />
            </div>
          </Flex>
        )}
      </Flex>
    </th>
  );
}

interface Props
  extends Omit<
    React.HTMLProps<HTMLTableCellElement>,
    "dangerouslySetInnerHTML"
  > {
  align?: "left" | "center" | "right";
  disabled?: boolean;
  icon?: FaIcon.Icon;
  sortable?: string;
  visible?: Breakpoint;
}
