import { useEffect, useRef } from "react";

export function useAfterEffect(effect, deps) {
  const mountedRef = useRef(false);
  const cleanUpRef = useRef(undefined);

  useEffect(() => {
    // bails if still not mounted
    if (!mountedRef.current) return;
    // resolve the effect into a promise to support async effects
    Promise.resolve(effect()).then(cleanUp => (cleanUpRef.current = cleanUp));
    return () => cleanUpRef.current && cleanUpRef.current();
  }, deps);
  // an effect to keep track of mounted state
  useEffect(() => {
    mountedRef.current = true;
    return () => (mountedRef.current = false);
  }, []);
}
