import React from "react";
import { bem } from "../../configs/bem";
const cn = bem("card-background");
import { styles } from "./CardBackground.styles";

export default function CardBackground(props) {
  const {
    color = "white",
    thumbnail = "",
    thumbnailPosition = "left",
    thumbnailCols = 6,
    children,
    shadow = false,
    border = false,
    className,
    breakpoint = "smd"
  } = props;

  return (
    <div
      className={styles.card({
        color,
        shadow,
        border,
        className: cn(null, [className]),
        css: { backgroundColor: `$${color}` }
      })}
    >
      <div className="row">
        {thumbnail && (
          <div
            className={`col-12 ${
              ["left", "right"].includes(thumbnailPosition)
                ? `col-${breakpoint}-${thumbnailCols}`
                : ``
            } order-${["bottom", "right"].includes(thumbnailPosition) ? 1 : 0}`}
          >
            <div
              className={styles.thumbnailWrapper({
                breakpoint,
                thumbnailPosition,
                className: cn("thumbnail-wrapper")
              })}
            >
              <div
                className={styles.thumbnail({
                  breakpoint,
                  className: cn("thumbnail")
                })}
                style={{ backgroundImage: `url(${thumbnail})` }}
              />
            </div>
          </div>
        )}
        <div
          className={`col-12 ${
            thumbnail && ["left", "right"].includes(thumbnailPosition)
              ? `col-${breakpoint}-${12 - thumbnailCols}`
              : ""
          } order-0`}
        >
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
}
