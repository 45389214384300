import * as Icons from "../Icons";

export function Facebook(url, appId) {
	return {
		url: `https://web.facebook.com/dialog/feed?app_id=${appId}&display=popup&link=${url}`,
		icon: Icons.Facebook,
		label: "Facebook",
		backgroundColor: "#3b5998"
	};
}

export function Messenger(url, appId) {
	return {
		url: `https://web.facebook.com/dialog/send?app_id=${appId}&display=popup&link=${url}&redirect_uri=https://obby.co.uk`,
		icon: Icons.Messenger,
		label: "Messenger",
		backgroundColor: "#3884f7"
	};
}

export function Twitter(url, text) {
	return {
		url: `https://twitter.com/intent/tweet?text=${text}&url=${url}`,
		icon: Icons.Twitter,
		label: "Twitter",
		backgroundColor: "#4daae7"
	};
}
