import latinize from "latinize";
import moment from "moment";

// Helper method to remain as the same as in Meteor code
export function createSlug(title) {
  return (
    latinize(title.toLowerCase())
      .trim()
      .replace("&", "and")
      .replace(/  +/g, " ")
      .replace(/ /g, "-")
      .replace(/[^0-9a-zA-Z -]/g, "") +
    "-" +
    moment()
      .unix()
      .toString()
  );
}

export function createSimpleSlug(title) {
  return latinize(title.toLowerCase())
    .trim()
    .replace("&", "and")
    .replace(/  +/g, " ")
    .replace(/ /g, "-")
    .replace(/[^0-9a-zA-Z -]/g, "");
}
