import React from "react";

import { range } from "ramda";

import Icons from "../Icons";
import Icon from "../Icon";

import styles from "./Pagination.module.less";

const collapseNavButtons = (buttons, current, visibleRange) => {
  return buttons.map(button => {
    const median = Math.floor(visibleRange / 2);
    if (button === 1) {
      return button;
    }

    if (button === buttons.length) {
      return button;
    }

    if (current < visibleRange && button <= visibleRange) {
      return button;
    }

    if (
      button > buttons.length - visibleRange &&
      current > buttons.length - visibleRange
    ) {
      return button;
    }

    if (button >= current - median && button <= current + median) {
      return button;
    }

    return ".";
  });
};

const getVisibleNav = (total, current, visibleRange) => {
  const buttons = collapseNavButtons(range(1, total + 1), current, visibleRange)
    .join(",")
    .replace(/(,\.)+/g, ",-1")
    .split(",");
  return buttons.map(button => parseInt(button));
};

const Pagination = ({
  currentPage,
  maxPages,
  onClick,
  onClickLastPage,
  onClickFirstPage,
  visibleRange,
  color
}) => {
  const pages =
    maxPages > 1 ? getVisibleNav(maxPages, currentPage, visibleRange) : [];
  return pages.length ? (
    <div
      className={`${styles["pagination"]} ${styles[`pagination--${color}`]}`}
    >
      <span
        className={`${styles[`pagination__step`]} ${
          currentPage === 1 ? styles["pagination__step--disabled"] : ""
        }`}
        onClick={currentPage === 1 ? null : onClickFirstPage}
      >
        <Icon icon={Icons.ChevronPrev} height={13} />
      </span>
      {pages.map((page, index) => {
        if (page < 1) {
          return (
            <span
              key={`${index}${page}`}
              className={styles["pagination__skip"]}
            >
              ...
            </span>
          );
        }
        return (
          <span
            key={page}
            onClick={() => onClick(page)}
            className={`${styles["pagination__step"]} ${
              page === currentPage ? styles["pagination__step--active"] : ""
            }`}
          >
            {page}
          </span>
        );
      })}
      <span
        className={`${styles["pagination__step"]} ${
          currentPage == maxPages ? styles["pagination__step--disabled"] : ""
        }`}
        onClick={currentPage == maxPages ? null : onClickLastPage}
      >
        <Icon icon={Icons.ChevronNext} height={13} />
      </span>
    </div>
  ) : null;
};

Pagination.defaultProps = {
  color: "emeraldish",
  visibleRange: 5
};

export default Pagination;
