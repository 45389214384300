import * as Yup from "yup";
import { CheckoutPersonalDetailsForm } from "../../forms/CheckoutPersonalDetailsForm";

export function CourseCheckoutSchema(isSendingMaterial, defaults = {}) {
  defaults = {
    tickets: [],
    inputs: [],
    personalDetails: [],
    sessionId: undefined,
    discountCode: "",
    ...defaults
  };

  return Yup.object({
    sessionId: Yup.string()
      .required()
      .default(defaults.sessionId),
    tickets: Yup.array()
      .of(Yup.number().default(0))
      .test(
        "",
        "No ticket chosen",
        tickets => tickets.reduce((total, ticket) => total + ticket, 0) > 0
      )
      .default(defaults.tickets),
    personalDetails: CheckoutPersonalDetailsForm.Schema(isSendingMaterial),
    discountCode: Yup.string()
      .when("personalDetails.guests.0.email", (email, schema) => {
        if (
          !Yup.string()
            .email()
            .required()
            .isValidSync(email)
        )
          return schema.test(
            "email-address-for-discount",
            "Fill in email address first in Personal details step",
            () => false
          );

        return schema;
      })
      .default(defaults.discountCode)
  });
}
