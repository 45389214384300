import React, { useContext } from "react";
import Icon from "../Icon";
import Icons from "../Icons";
import { MenuNavItemWrapper } from "./MenuNavItemWrapper";
import { MenuNavItemContext, MenuNavContext } from "./MenuNavContext";
import styles from "./menu-nav-item.module.less";

export function MenuNavItem(props) {
  const { text, href, bold = false, children, onClick, target } = props;
  const { isActive } = useContext(MenuNavItemContext);
  const { textColor, textColorHover } = useContext(MenuNavContext);

  return (
    <MenuNavItemWrapper
      href={href}
      onClick={onClick}
      target={target}
      border={true}
    >
      <span
        className={`${styles["menu-nav-item"]} ${
          styles[
            `menu-nav-item--color-${isActive ? textColorHover : textColor}`
          ]
        } ${styles[`menu-nav-item--hover-color-${textColorHover}`]} ${
          isActive ? styles["menu-nav-item--active"] : ""
        }`}
      >
        <span
          className={`${styles["menu-nav-item__text"]} ${
            bold ? styles["menu-nav-item__text--bold"] : ""
          }`}
        >
          {text}
        </span>
        {children && (
          <Icon
            className={styles["menu-nav-item__icon"]}
            icon={Icons.ChevronDown}
          />
        )}
      </span>
    </MenuNavItemWrapper>
  );
}
