import { css, withBem } from "../../sitches.config";

export const styles = withBem("session-date-row", {
  sessionDateRow: css({
    position: "relative",
    padding: 16,
    backgroundColor: "white",
    border: "1px solid $darkGallery",
    borderRadius: 10,
    cursor: "pointer",
    fontFamily: "$foundersGrotesk",
    fontSize: "1.0rem",
    color: "$ebonyClay",
    lineHeight: "normal",

    "@smd": {
      paddingX: 32
    },

    variants: {
      isSelected: {
        true: {
          backgroundColor: "#d5f5f3",
          padding: 15,
          border: "2px solid $emeraldish",

          "@smd": {
            paddingX: 31
          }
        }
      },
      isDisabled: {
        true: {
          cursor: "default"
        }
      }
    },
    compoundVariants: [
      {
        isSelected: false,
        isDisabled: true,
        css: {
          backgroundColor: "rgba(255, 255, 255, 0.5)"
        }
      }
    ]
  }),

  wrapper: css({
    "@smd": {
      display: "flex",
      alignItems: "flex-start"
    }
  }),

  header: css({
    display: "flex",
    justifyContent: "space-between",

    "@smd": {
      flexBasis: "30%",
      flexDirection: "column"
    },

    variants: {
      isDisabled: {
        true: {
          opacity: 0.5,
          cursor: "default"
        }
      }
    }
  }),

  dateContainer: css({
    marginBottom: "0.25rem"
  }),

  date: css({
    fontWeight: 500,
    letterSpacing: "0.05em",

    "&:not(:last-child)": {
      marginBottom: 2
    }
  }),

  showAllButton: css({
    fontSize: "0.875rem",
    letterSpacing: "0.03em",
    opacity: 0.8,
    userSelect: "none",
    cursor: "pointer"
  }),

  additionalDate: css({
    fontSize: "0.875rem",
    letterSpacing: "0.03em",
    marginTop: 4
  }),
  descriptions: css({
    letterSpacing: "0.03rem",
    lineHeight: 1,
    textAlign: "right",
    "@smd": {
      textAlign: "left"
    }
  }),
  description: css({
    "@smd": {
      display: "inline-block",
      "&:not(:first-child):before": {
        content: "•",
        marginX: "$1",
        fontSize: "16px",
        // lineHeight: "16px",
        color: "$silverMalibu"
      }
    },
    variants: {
      bold: {
        true: {
          fontWeight: 500,
          "@smd": {
            fontWeight: 400
          }
        }
      }
    }
  }),

  info: css({
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",

    "@smd": {
      flex: 1,
      minHeight: 40
    }
  }),

  location: css({
    fontSize: "0.875rem",
    letterSpacing: "0.04em",
    opacity: 0.8,

    "@smd": {
      fontSize: "1.0rem",
      flexBasis: "30%",
      textAlign: "center",
      order: 2
    },

    variants: {
      isDisabled: {
        true: {
          opacity: 0.5,
          cursor: "default"
        }
      }
    }
  }),

  time: css({
    fontSize: "0.875rem",
    letterSpacing: "0.04em",
    opacity: 0.8,
    marginLeft: 16,

    "@smd": {
      fontSize: "1.0rem",
      flexBasis: "30%",
      textAlign: "center",
      marginLeft: 0,
      order: 1
    },

    variants: {
      isDisabled: {
        true: {
          opacity: 0.5,
          cursor: "default"
        }
      }
    }
  }),

  price: css({
    letterSpacing: "0.04em",
    flexGrow: 1,

    "@smd": {
      flexGrow: "initial",
      flexBasis: "10%",
      order: 0
    },

    variants: {
      isDisabled: {
        true: {
          opacity: 0.5,
          cursor: "default"
        }
      }
    }
  }),

  priceText: css({
    variants: {
      isDiscounted: {
        true: {
          textDecoration: "line-through"
        }
      }
    }
  }),

  lineBreak: css({
    flexBasis: "100%",

    "@smd": {
      flexBasis: 0
    }
  }),

  authorOrAction: css({
    "@smd": {
      flexBasis: "30%",
      order: 3
    }
  }),

  tutor: css({
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.04em",
    color: "$ebonyClay",
    height: 30,
    textDecoration: "none",

    "&:hover": {
      textDecoration: "underline",
      color: "$ebonyClay"
    },

    variants: {
      isDisabled: {
        true: {
          opacity: 0.5,
          cursor: "default"
        }
      }
    }
  }),

  tutorImage: css({
    width: 30,
    height: 30,
    borderRadius: "50%",
    marginRight: 8
  }),

  waitingListButton: css({
    display: "block",
    textAlign: "right",
    fontFamily: "inherit",
    fontSize: "inherit",
    letterSpacing: "0.03em",
    color: "$emeraldish",
    padding: 6,
    margin: "0 0 0 auto",
    backgroundColor: "white",
    border: "1px solid $mercury",
    borderRadius: 4,
    cursor: "pointer",
    "-webkit-appearance": "none",

    "&:hover": {
      textDecoration: "underline"
    }
  })
});
