import React, { useState, useEffect, MutableRefObject } from "react";
import accounting from "accounting";
import { TextInput } from "../TextInput";
// @ts-ignore
import { CurrencyISO__CurrencySymbol, Number__Currency } from "@obby/lib";
// @ts-ignore
import { Currencies } from "@obby/constants";

export function CurrencyInput<Name extends string | undefined = undefined>(
  props: CurrencyInput.Props<Name>
) {
  const {
    accent,
    autoComplete,
    autoFocus,
    className = "",
    cleaner,
    currency = Currencies.GBP,
    compound,
    disabled,
    icon,
    iconAlign = "left",
    inputRef,
    onBlur = () => {},
    onChange = () => undefined,
    onFocus = () => {},
    onReset = () => {},
    name,
    placeholder,
    required,
    readOnly,
    symbol = CurrencyISO__CurrencySymbol(currency),
    size,
    textAlign,
    value = null,
    width
  } = props;

  const [inputValue, setInputValue] = useState<string>(formatValue());
  useEffect(() => {
    if (value !== parseFloat(inputValue)) setInputValue(formatValue());
  }, [value]);

  function onInputBlur() {
    if (inputValue !== "") {
      const parsedValue: number = accounting.unformat(inputValue);
      setInputValue(Number__Currency(parsedValue * 100, ""));
      if (parsedValue !== value) {
        onChange(parsedValue, name!);
      }
    }
    onBlur(name!);
  }
  function onInputChange(inputValue: string) {
    // bail if the input is about to be invalid. by returning false the inserted input will not be printed
    if (inputValue.match(/^-?\d*\.?\d{0,2}$/) === null) return false;

    setInputValue(inputValue);

    if (inputValue === "") {
      if (value !== null) onChange(null, name!);
    } else if (!isNaN(parseFloat(inputValue))) {
      const parsedValue: number = accounting.unformat(inputValue);
      // trigger onChange if we actually changed the value number (e.g. if we are changing from 1.50 to 1.5 we
      // are not actually changing the number. they are both 1.5)
      if (parsedValue !== value) {
        onChange(parsedValue, name!);
      }
    }
  }

  function formatValue() {
    return value === null ? "" : value + "";
  }

  return (
    <TextInput
      accent={accent}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      className={className}
      cleaner={cleaner}
      compound={compound}
      disabled={disabled}
      icon={icon}
      iconAlign={iconAlign}
      inputRef={inputRef}
      name={name!}
      onBlur={onInputBlur}
      onChange={onInputChange}
      onFocus={onFocus}
      onReset={onReset}
      placeholder={placeholder}
      prefix={symbol}
      readOnly={readOnly}
      required={required}
      size={size}
      textAlign={textAlign}
      value={inputValue + ""}
      width={width}
    />
  );
}

namespace CurrencyInput {
  export interface Props<Name extends string | undefined> {
    accent?: string | false;
    autoComplete?: string;
    autoFocus?: boolean;
    className?: string;
    cleaner?: boolean;
    compound?: "left" | "middle" | "right";
    currency?: string;
    disabled?: boolean;
    /** @deprecated */
    empty?: null;
    icon?: any;
    iconAlign?: "left" | "right";
    inputRef?: MutableRefObject<HTMLInputElement>;
    name?: Name;
    onBlur?: Name extends undefined ? () => void : (name: string) => void;
    onChange?: Name extends undefined
      ? (value: number | null) => boolean | void
      : (value: number | null, name: string) => boolean | void;
    onFocus?: Name extends undefined ? () => void : (name: string) => void;
    onReset?: Name extends undefined ? () => void : (name: string) => void;
    placeholder?: string;
    // prefix?: string;
    readOnly?: boolean;
    required?: boolean;
    size?: "small" | "medium";
    /** @deprecated - use currency (iso) instead */
    symbol?: string;
    textAlign?: "left" | "center" | "right";
    value?: number | null;
    width?: "full" | "auto";
  }

  export type Empty = null | undefined | "";
}
