import React from "react";

import { styles } from "./CheckoutFooterBar.styles";

export function CheckoutFooterBar({ children, label, value }) {
  return (
    <footer className={styles.bar({ className: "checkout-footer-bar" })}>
      <div className="container-fluid">
        <div className={styles.wrapper()}>
          <div className={styles.valueWrapper()}>
            {label && `${label}:`}
            {value && <span className={styles.value()}>{value}</span>}
          </div>
          <div className={styles.content()}>{children}</div>
        </div>
      </div>
    </footer>
  );
}
