import { css, withBem } from "../../sitches.config";

export const styles = withBem("select-box", {
  selectBox: css({
    "--default-accent": "$colors$mercury",
    "--default-accent-indicator": "#999999",
    "--default-option-background-focused": "$colors$silver",
    "--default-option-background-selected": "$colors$gallery",
    fontFamily: "$foundersGrotesk",

    ".react-select": {
      color: "$ebonyClay",
      "&--is-disabled": {
        ".react-select__dropdown-indicator": {
          color: "$disabled",
          "&:hover": {
            color: "$disabled"
          }
        }
      },
      "&__value-container": {
        padding: 0
      },
      "&__control": {
        borderColor: "$mercury",
        minHeight: "45px",
        boxShadow: "none",
        fontSize: "20px",
        "&:hover": {
          borderColor: "$mercury"
        },
        "&--is-disabled": {
          backgroundColor: "$gallery"
        },
        "&--is-focused": {
          borderColor: "var(--accent, $colors$mercury)",
          "&:hover": {
            borderColor: "var(--accent, var(--default-accent))"
          },
          "&.react-select__control--menu-is-open": {
            borderBottomColor: "$mercury"
          },
          "+ .react-select__menu .react-select__menu-list": {
            borderColor: "var(--accent, var(--default-accent))"
          }
        },
        "&--menu-is-open": {
          borderRadius: "$input $input 0 0",
          ".react-select__dropdown-indicator": {
            transform: "rotate(180deg)"
          }
        }
      },
      "&__single-value": {
        color: "$textColor",
        "&--is-disabled": {
          color: "$disabled"
        }
      },
      "&__indicators": {
        flexShrink: "unset",
        gap: "3px"
      },
      "&__indicator": {
        paddingX: 0
      },
      "&__indicator-separator": {
        display: "none"
      },
      "&__clear-indicator": {
        // marginRight: "-13px",
        color: "var(--default-accent-indicator)",
        "&:hover": {
          color: "var(--default-accent-indicator)"
        }
      },
      "&__dropdown-indicator": {
        transition: "transform 250ms",
        alignSelf: "flex-end",
        margin: "3px 0",
        color: "var(--accent, var(--default-accent-indicator))",
        "&:hover": {
          color: "var(--accent, var(--default-accent-indicator))"
        }
      },
      "&__placeholder": {
        color: "$alto",
        fontSize: "20px"
      },
      "&__menu": {
        margin: 0,
        boxShadow: "none"
      },
      "&__menu-list": {
        padding: 0,
        fontSize: "20px",
        border: "solid $mercury",
        borderWidth: "0 1px 1px",
        borderRadius: "0 0 $input $input"
      },
      "&__option": {
        position: "relative",
        minHeight: "46px",
        "&--is-focused": {
          backgroundColor: "transparent",
          "&::before": {
            position: "absolute",
            content: "",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0.12,
            zIndex: -1,
            backgroundColor:
              "var(--accent, var(--default-option-background-focused))"
          }
        },
        "&--is-selected": {
          color: "$ebonyClay",
          backgroundColor:
            "var(--accent, var(--default-option-background-selected))"
        }
      },
      "&__multi-value": {
        padding: "0 4px 0 8px",
        height: "28px",
        margin: "4px",
        borderRadius: "$input",
        alignItems: "center",
        "&__label": {
          fontSize: "inherit",
          padding: 0,
          lineHeight: 1
        },
        "&__remove": {
          color: "$silver",
          cursor: "pointer",
          "&:hover": {
            color: "#999999",
            backgroundColor: "transparent"
          },
          svg: {
            height: "23px",
            width: "23px",
            "@md": {
              height: "20px",
              width: "20px"
            }
          }
        }
      }
    },

    variants: {
      size: {
        small: {
          ".react-select": {
            "&__control": {
              minHeight: "35px",
              fontSize: "16px",
              paddingLeft: "15px",
              height: "35px"
            },
            "&__placeholder": {
              fontSize: "18px"
            },
            "&__indicators": {
              paddingX: "$1"
            },
            "&__indicator": {
              paddingY: "$1",
              gap: "$1"
            },

            "&__menu-list": {
              fontSize: "16px"
            },
            "&__option": {
              position: "relative",
              minHeight: "34px",
              padding: "7px 12px 6px"
            }
          }
        },
        medium: {
          ".react-select": {
            "&__control": {
              minHeight: "45px",
              fontSize: "18px",
              paddingLeft: "20px"
            },
            "&__placeholder": {
              fontSize: "18px"
            },
            "&__indicators": {
              paddingX: "$2",
              gap: "$2"
            },
            "&__indicator": {
              paddingY: "$2"
            },

            "&__menu-list": {
              fontSize: "18px"
            },
            "&__option": {
              position: "relative",
              minHeight: "45px",
              padding: "11px 20px"
            }
          }
        }
      },
      hideChevron: {
        true: {
          ".react-select__dropdown-indicator": {
            display: "none"
          }
        }
      },
      menuPlacement: {
        top: {
          ".react-select__menu-list": {
            borderWidth: "1px 1px 0",
            borderRadius: "$input $input 0 0"
          },
          ".react-select__control--menu-is-open": {
            borderRadius: "0 0 $input $input"
          }
        },
        bottom: {
          ".react-select__menu-list": {
            borderWidth: "0 1px 1px",
            borderRadius: "0 0 $input $input"
          },
          ".react-select__control--menu-is-open": {
            borderRadius: "$input $input 0 0"
          }
        }
      },
      compound: {
        left: {
          flex: "0 0 auto",
          ".react-select__control": {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }
        },
        middle: {
          flex: "0 0 auto",
          ".react-select__control": {
            borderLeft: "none",
            borderRadius: 0
          }
        },
        right: {
          flex: "0 0 auto",
          ".react-select__control": {
            borderLeft: "none",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0
          }
        }
      }
    }
  })
});
