import React, { useEffect, useRef } from "react";
import Icon from "../Icon";
import * as Icons from "../Icons";

import styles from "./drawer.module.less";

export function Drawer(props) {
  const {
    children,
    position = "right",
    open = false,
    onClose = () => {}
  } = props;

  const bodyOverflow = useRef("");

  useEffect(() => {
    if (open) bodyOverflow.current = document.body.style.overflow;
    document.body.style.overflow = open ? "hidden" : bodyOverflow.current;
  }, [open]);

  useEffect(() => {
    return () => {
      // set body sidebar back when overlay unmounts
      if (open) document.body.style.overflow = bodyOverflow.current;
    };
  }, []);

  return (
    <div
      className={`${styles["drawer"]} ${styles[`drawer--${position}`]} ${
        open ? styles[`drawer--${position}-open`] : ""
      }`}
    >
      <div
        className={`${styles["drawer__body"]} ${
          styles[`drawer__body--${position}`]
        } ${open ? styles[`drawer__body--${position}-open`] : ""}`}
      >
        <div className={`${styles["drawer__content"]} px-3`}>{children}</div>
        <div className={`${styles["drawer__toolbar"]} px-3 py-4`}>
          <div className={styles["drawer__close-button"]} onClick={onClose}>
            <Icon
              className={styles["drawer__close-icon"]}
              icon={Icons.Cross}
              height={20}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
