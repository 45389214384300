import React from "react";

import { bem } from "../../configs/bem";
import { Label } from "../../components/Label";
import { RatingInput } from "../../common/inputs/RatingInput";
import { TextArea } from "../../inputs/TextArea";
import { TextInput } from "../../inputs/TextInput";
import { Checkbox } from "../../inputs/Checkbox";

const cn = bem("review-form");
export function ReviewForm(props) {
  const { values = {}, touched = {}, errors = {}, onChange, onBlur } = props;
  return (
    <div className={cn()}>
      <div className="row">
        <div className="col-12">
          <Label
            error={touched.name && errors.name}
            note="Please confirm the name you would like shown alongside this review. To protect your identity online, you only need include your first or nickname."
          >
            <Label
              label="First name*"
              error={touched.firstName && errors.firstName}
            >
              <TextInput
                name="firstName"
                value={values.firstName}
                onChange={onChange}
                onBlur={onBlur}
              />
            </Label>
            <Label label="Last name" error={touched.name && errors.name}>
              <TextInput
                name="lastName"
                value={values.lastName}
                onChange={onChange}
                onBlur={onBlur}
              />
            </Label>
          </Label>
          <Label>
            <Checkbox
              label="Only show first name"
              isChecked={values.showOnlyFirstName}
              name="showOnlyFirstName"
              onClick={onChange}
            />
          </Label>
        </div>
        <div className="col-12 pt-4 text-center">
          <div className="d-inline-block text-left">
            <Label error={touched.rating && errors.rating}>
              <RatingInput
                name="rating"
                value={values.rating}
                onChange={onChange}
                onBlur={onBlur}
              />
            </Label>
          </div>
        </div>
        <div className="col-12 pt-4">
          <Label error={touched.opinion && errors.opinion}>
            <TextArea
              name="opinion"
              placeholder="Let us know your opinion"
              value={values.opinion}
              onChange={onChange}
              onBlur={onBlur}
            />
          </Label>
        </div>
      </div>
    </div>
  );
}
