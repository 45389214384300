import axios from "axios";
import getConfig from "next/config";
import token from "./token";

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();
let apiInstance = axios.create({
  baseURL: publicRuntimeConfig.api_url
});

let messagesInstance = axios.create({
  baseURL: publicRuntimeConfig.messages_url
});

apiInstance.interceptors.request.use(config => {
  // Dynamically set the authorization header on each request if the user is logged in
  if (token.loggedIn())
    config.headers.Authorization = `Bearer ${token.getToken().accessToken}`;
  return config;
});

messagesInstance.interceptors.request.use(config => {
  // Dynamically set the authorization header on each request if the user is logged in
  if (token.loggedIn())
    config.headers.Authorization = `Bearer ${token.getToken().accessToken}`;
  return config;
});

//let marketplace instance
let marketplaceAxios = axios.create({});

export { messagesInstance as messagesAxios };
export { marketplaceAxios };
export default apiInstance;
