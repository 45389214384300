import Booking__Discount from "./Booking__Discount";
import Booking__Type from "./Booking__Type";
import { Booking__Source } from "./Booking__Source";
import { Timezones } from "@obby/constants";
import { UTCDate__FormattedDate } from "./UTCDate__FormattedDate";

const Booking__FinancialReportingBookingRow = (
  booking,
  timezone = Timezones.EUROPE_LONDON,
  isRefunded = false
) => {
  const discount = Booking__Discount(booking);
  let ticketName = "";
  let isMultiple = false;
  let allTickets = [];
  if (booking.tickets.length > 1) {
    ticketName = "Multiple";
    isMultiple = true;
    for (let ticket of booking.tickets) {
      allTickets.push({
        name: ticket.name,
        seats: ticket.numberOfGuestsPerTicket * ticket.count,
        quantity: ticket.count,
        grossTotal: ticket.price * ticket.count
      });
    }
  }
  if (booking.tickets && booking.tickets.length === 1) {
    ticketName = booking.tickets[0].name;
  }
  let studentsName = "";
  if (booking.enabledTickets.length > 0) {
    let names = booking.enabledTickets.map(enabledTicket => {
      return `${enabledTicket.userInfo.firstname} ${enabledTicket.userInfo.surname}`;
    });
    studentsName = names.join(" - ");
  }
  let transferDate;
  let partnerMoneyStatus = convertPartnerMoneyStatusIntoLabel(
    booking.partnerMoneyStatus
  );
  if (booking.partnerMoneyStatus === "transfered") {
    transferDate = booking.partnerMoneyTransfer.transferDate;
  }
  if (booking.partnerMoneyStatus === "paid") {
    if (booking.manualPaidDate) {
      transferDate = booking.manualPaidDate;
    }
  }
  console.log("booking ", booking);
  return {
    id: booking._id,
    class:
      booking.bookingType === "private"
        ? booking.privateCourse.title
        : booking.course.title,
    date: UTCDate__FormattedDate(
      booking.currentStartDateTime,
      timezone,
      "D MMMM YYYY"
    ),
    name: booking.buyerName,
    ticket: ticketName,
    //students: booking.tickets.map(ticket => `${ticket.count} x ${ticket.name}`),
    seats: booking.totalAttendants,
    quantity: booking.totalTickets,
    type: Booking__Type(booking),
    internalType: booking.bookingType,
    source: Booking__Source(booking),
    grossTotal: !isRefunded
      ? booking.accounting.rawEarning
      : booking.refundedObject?.refundedCardValue,
    discount:
      discount.discount && discount.isFromTeacher ? discount.discount : null,
    netPay: !isRefunded
      ? booking.accounting.netPay
      : booking.refundedObject?.refundedCardValue,
    paymentProcessing: booking.accounting.paymentProcessing,
    obbyFee: booking.accounting.obbyFee,
    vat: booking.accounting.vat,
    isMultiple: isMultiple,
    allTickets: allTickets,
    studentsName: studentsName,
    partnerMoneyStatus: partnerMoneyStatus,
    payoutID: booking.payoutID,
    transferDate: transferDate
      ? UTCDate__FormattedDate(transferDate, timezone, "D MMMM YYYY")
      : "-",
    currency: booking.currencyUsed,
    customerEmail: booking.customer?.email ?? null,
    customerID: booking.customer?._id ?? null,
    sessionID: booking.session._id ?? null
  };
};

// need to a function on its own
function convertPartnerMoneyStatusIntoLabel(partnerMoneyStatus) {
  if (partnerMoneyStatus == "pending") {
    return "Pending";
  }

  if (
    partnerMoneyStatus == "transfered" ||
    partnerMoneyStatus == "paid" ||
    partnerMoneyStatus == "destinationCharge"
  ) {
    return "Paid";
  }

  if (partnerMoneyStatus == "refundedToUser") {
    return "Refunded";
  }

  if (partnerMoneyStatus == "nonAplicable") {
    return "Non Aplicable";
  }
}
export default Booking__FinancialReportingBookingRow;
