import React, { ReactNode, useContext, useEffect } from "react";
import { CheckoutStepsContext } from "./CheckoutStepsContext";

export function CheckoutStepsStep({
  breadcrumb,
  disabled = false,
  hidden = false,
  nextButtonText,
  pass = false
}: React.PropsWithChildren<Props>) {
  const { onStepChange } = useContext(CheckoutStepsContext);
  const { index } = useContext(CheckoutStepsStepContext);

  useEffect(() => {
    onStepChange(
      {
        breadcrumb: breadcrumb,
        disabled: disabled,
        hidden: hidden,
        nextButtonText: nextButtonText,
        pass: pass,
        index
      },
      index
    );
  }, [breadcrumb, disabled, hidden, nextButtonText, pass]);

  return <></>;
}

interface Props {
  breadcrumb: string;
  disabled?: boolean;
  description?: ReactNode;
  hidden?: boolean;
  nextButtonText?: string;
  onEnter?: () => void;
  pass?: boolean;
  title?: ReactNode;
}

export const CheckoutStepsStepContext = React.createContext<Value>({
  index: 0
});
interface Value {
  index: number;
}
