import React, { useState, useEffect } from "react";
import { TogglePopoutContext } from "./TogglePopoutContext";

import { TogglePopoutToggle } from "./TogglePopoutToggle";
import { TogglePopoutModal } from "./TogglePopoutModal";

import styles from "./TogglePopout.module.less";
import { bemModule } from "../../configs/bem";
const cn = bemModule(styles, "toggle-popout");

export function TogglePopout(props) {
  const { children, className = "", onClose, onOpen } = props;

  const [open, setOpen] = useState(props.open || false);
  useEffect(() => {
    setOpen(props.open || false);
  }, [props.open]);

  function close(e) {
    if (e.isDefaultPrevented()) return;

    onClose && onClose(e);
    setOpen(false);
    // window.removeEventListener("popstate", onPopState);
    // history.back();
  }

  function onToggle(e) {
    if (!open) {
      // this tecnique is not working well on chrome. if the click action results in a redirection (eg Manage Listing)
      // the above history.back() ends up reverting that redirection instead of popping out the state added by the menu.
      // window.history.pushState(
      //   {},
      //   ""
      // );
      // window.addEventListener("popstate", onPopState, { once: true });
      setOpen(true);
      onOpen && onOpen(e);
    } else {
      close(e);
    }
  }

  // function onPopState(e) {
  //   onClose && onClose(e);
  //   setOpen(false);
  // }

  function onBlur(e) {
    close(e);
  }

  return (
    <TogglePopoutContext.Provider value={{ onBlur, onToggle, open }}>
      <div className={cn(null, [`${className}`])}>{children}</div>
    </TogglePopoutContext.Provider>
  );
}

TogglePopout.Toogle = TogglePopoutToggle;
TogglePopout.Modal = TogglePopoutModal;
