import React from "react";
import styles from "./dashboard-page-header.module.less";

const DashboardPageHeader = props => (
  <div className={styles["dashboard-page-header"]}>
    <h2
      className={`dashboard-page-header__title ${
        props.uppercase ? styles["dashboard-page-header__title--uppercase"] : ""
      }`}
    >
      {props.title}
    </h2>
    {props.description && (
      <div className={styles["dashboard-page-header__description"]}>
        {props.description}
      </div>
    )}
  </div>
);

export default DashboardPageHeader;
