import React from "react";
import { styles } from "./GiftCardConfirmationCard.styles";
import Icons from "../../components/Icons";
import { IconTimelineList } from "../../lists/IconTimelineList";
// @ts-ignore
import { Location__FullAddressString } from "@obby/lib";
export function GiftCardConfirmationCard({
  address,
  buyerEmail,
  email,
  recipient
}: Props) {
  return (
    <div className="giftcard-confirmation-card">
      <div className={styles.label()}>confirmation</div>
      <div className={styles.title()}>YOU’VE GIVEN THE GIFT OF LEARNING!</div>
      <div className={styles.description()}>
        You’ve given someone one of the best gifts they could recieve, the power
        to learn something new! Check the timeline below to see what happens
        next.
      </div>
      <div className="gift-card-receipt-page__list">
        <IconTimelineList
          list={[
            {
              icon: Icons.BoldCircleTick,
              title: "Confirmation email SENT TO YOU",
              text: `${buyerEmail} receives an email confirming the giftcard!`
            },
            ...(email
              ? [
                  {
                    icon: Icons.BoldEmailCircle,
                    title: `${recipient} recieves a GIFTCARD`,
                    text: `${email} receives an email with the gift card and instructions!`
                  }
                ]
              : []),
            ...(address
              ? [
                  {
                    icon: Icons.BoldEmailCircle,
                    title: `Gift card posted`,
                    text: `We've sent the giftcard to ${Location__FullAddressString(
                      address
                    )}`
                  }
                ]
              : []),
            {
              icon: Icons.BoldCalendarCircle,
              title: `${recipient} Books a class`,
              text: `${recipient} books a class of their choosing.`
            },
            {
              icon: Icons.BoldStarCircle,
              title: `${recipient} unlocks new skills`,
              text: `${recipient} levels up and aquires a new skill, thanks to your wonderful gift!`
            }
          ]}
        />
      </div>
    </div>
  );
}
interface Props {
  address?: any;
  buyerEmail: string;
  email?: string;
  recipient: string;
}
