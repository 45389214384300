import { css, withBem } from "../../sitches.config";

export const styles = withBem("shared-container", {
  box: css({
    width: "100%",
    marginX: "auto",
    maxWidth: "1200px",
    paddingX: "15px"
  })
});
