import React from "react";
import { styles } from "./ErrorBox.styles";

export function ErrorBox({ children }: React.PropsWithChildren<{}>) {
  return (
    <div className={styles.container()}>
      <div className={styles.error()}>{children}</div>
    </div>
  );
}
