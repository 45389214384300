export const CourseStatuses = {
  /** @deprecated */
  WAITINGFORAPPROVAL: "waitingforapproval",
  WAITING_FOR_APPROVAL: "waitingforapproval",
  APPROVED: "approved",
  DISAPPROVED: "disapproved",
  DRAFT: "draft",
  ARCHIVED: "archived",
  HIDDEN: "hidden",
  DELETED: "deleted"
};
