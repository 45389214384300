import React, { useState, useEffect, useContext } from "react";

import { PrimaryButton } from "../../buttons/PrimaryButton";
import { SecondaryButton } from "../../buttons/SecondaryButton";

import { RisingPopupContext } from "./RisingPopupContext";
import { styles } from "./RisingPopupDialog.styles";
export function RisingPopupDialog(props) {
  const {
    title,
    description,
    children,
    submitButtonText = "Submit",
    submitButtonDisabled = false,
    onSubmit,
    cancelButtonText = "Cancel"
  } = props;

  const { setClose } = useContext(RisingPopupContext);

  const [isLoading, setIsLoading] = useState(props.isLoading ?? false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setIsLoading(props.isLoading);
  }, [props.isLoading]);

  function onReset() {
    setIsLoading(false);
    setSuccess("");
    setError("");
  }

  function onCancel() {
    setClose(true);
  }

  async function onSubmitClick() {
    setIsLoading(true);
    setSuccess("");
    setError("");

    try {
      const success = await onSubmit();
      if (success) setSuccess(success);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className={styles.dialog()}>
      {isLoading ? (
        <div className={styles.centered()}>
          <div className={styles.loader()} />
        </div>
      ) : error ? (
        <div className={styles.centered()}>
          <img
            className={styles.sticker()}
            src="https://res.cloudinary.com/obby/image/upload/v1598252974/Misc%20Stickers/thumbs-down-sticker.svg"
          />
          <span className={styles.success()}>{error}</span>
          <div>
            {onSubmit && (
              <PrimaryButton
                text="Try Again"
                className={styles.button()}
                size="small"
                onClick={onSubmitClick}
                width="auto"
              />
            )}
            <SecondaryButton
              className={styles.button()}
              text="Back"
              onClick={onReset}
              width="auto"
              size="small"
            />
            {onCancel && (
              <SecondaryButton
                className={styles.button()}
                text="Cancel"
                onClick={onCancel}
                width="auto"
                size="small"
              />
            )}
          </div>
        </div>
      ) : success ? (
        <div className={styles.centered()}>
          <img
            className={styles.sticker()}
            src="https://res.cloudinary.com/obby/image/upload/v1598207964/Misc%20Stickers/thumbs-up-sticker.svg"
          />
          <span className={styles.success()}>{success}</span>
          <SecondaryButton
            text="Close"
            onClick={onCancel}
            width="auto"
            size="small"
          />
        </div>
      ) : (
        <>
          <h2 className={styles.title()}>{title}</h2>
          {description && (
            <div className={styles.description()}>{description}</div>
          )}
          <div className={styles.container()}>{children}</div>
          <footer className="mb-4">
            {onSubmit && (
              <PrimaryButton
                className={styles.button()}
                text={submitButtonText}
                size="small"
                onClick={onSubmitClick}
                width="auto"
                disabled={submitButtonDisabled}
              />
            )}
            {onCancel && (
              <SecondaryButton
                className={styles.button()}
                text={cancelButtonText}
                size="small"
                color="ebonyClay"
                onClick={onCancel}
                width="auto"
              />
            )}
          </footer>
        </>
      )}
    </div>
  );
}
