import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();

const settings = {
  apiHost: publicRuntimeConfig.api_url
};

export default settings;

export const is_development = publicRuntimeConfig.is_development;

export const urls = {
  api: publicRuntimeConfig.api_url,
  teachers: publicRuntimeConfig.teachers_url,
  marketplace: publicRuntimeConfig.marketplace_url
};

export const facebook = {
  appId: publicRuntimeConfig.facebook_appid
  // testEmail: 'gnbzzasmjg_1520267015@tfbnw.net'
};

export const cloudinary = {
  name: publicRuntimeConfig.cloudinary_name,
  apiKey: publicRuntimeConfig.cloudinary_apiKey,
  uploadPreset: publicRuntimeConfig.cloudinary_uploadPreset
};

export const google = {
  clientId: publicRuntimeConfig.google_appid,
  // clientSecret: 'Dn1k5tTfWan-ZcSqkG2bqCRG',
  mapsKey: publicRuntimeConfig.google_maps_key
};

export const analytics = {
  sourceId: publicRuntimeConfig.analytics_sourceid,
  apiKey: publicRuntimeConfig.analytics_key
};

export const timezones = {
  London: "Europe/London"
};

export const stripe = {
  publicKey: publicRuntimeConfig.stripe_public_key
};

export const talkjs_appid = publicRuntimeConfig.talkjs_appid;
