import accounting from "accounting";

export const Number__Currency = (
  number,
  symbol = "£",
  precision = number % 10 ? 2 : number % 100 ? 2 : 0 // defaults to 2 if we end up having decimals or 0 if not
) => {
  switch (true) {
    case number < 0:
      return (
        "-" + accounting.formatMoney((number * -1) / 100, symbol, precision)
      );
    case number >= 0:
      return accounting.formatMoney(number / 100, symbol, precision);
    default:
      return "--.--";
  }
};
