import React from "react";
import TextPill from "../TextPill";
import NuovoBreadcrumbs from "../NuovoBreadcrumbs";
import RatingSummary from "../RatingSummary";
import { RunningDaysLabels } from "@obby/constants";
import {
  Number__Currency,
  ImageUrl__ImageResizedUrl,
  Discount__DiscountedPrice,
  CurrencyISO__CurrencySymbol
} from "@obby/lib";
import { bemModule } from "../../configs/bem";
import HighlightPrice from "../HighlightPrice";
import {
  Box,
  Label,
  Span,
  TextH1,
  StoriesWrapper
} from "./ProductPageHeader.layout";
import { Stories } from "../../media/Stories";
import styles from "./ProductPageHeader.module.less";

const IMAGE_RESOLUTION = {
  portrait: {
    low: { width: 175, height: 200 },
    high: { width: 700, height: 800 }
  },
  landscape: {
    low: { width: 300, height: 200 },
    high: { width: 900, height: 600 }
  }
};

const IMAGE_SIZE = {
  small: { cols: 3 },
  medium: { cols: 4 },
  large: { cols: 7 }
};

const IMAGE_POSITION = {
  left: {
    order: 0
  },
  right: {
    order: 1
  }
};

const cn = bemModule(styles, "product-page-header");
export function ProductPageHeader({
  name,
  description,
  breadcrumps,
  price,
  currency,
  discount,
  level,
  postage,
  images,
  learningMaterials = [],
  imageOrientation = "portrait",
  imagePosition = "left",
  imageSize = "medium",
  rating,
  numberOfReviews,
  type
}) {
  const highResImages = images.map(image =>
    ImageUrl__ImageResizedUrl(
      {
        ...IMAGE_RESOLUTION[imageOrientation].high,
        crop: "fill"
      },
      image
    )
  );

  return (
    <Box className={cn()}>
      <Box className="row">
        <Box
          className={`col-12 col-md-${IMAGE_SIZE[imageSize].cols}`}
          css={{
            order: "0",
            paddingBottom: "$16",
            "@md": {
              order: `${IMAGE_POSITION[imagePosition].order}`
            }
          }}
        >
          <StoriesWrapper>
            <Stories
              stories={images}
              width={834}
              height={565}
              interval={3750}
              loop
            />
          </StoriesWrapper>
        </Box>
        <Box className={`col-12 col-md-${12 - IMAGE_SIZE[imageSize].cols}`}>
          <Box className={cn("content")} variant="content">
            {breadcrumps && (
              <Box className={cn("breadcrumbs", null, ["mb-3"])}>
                <NuovoBreadcrumbs
                  breadcrumbs={breadcrumps}
                  onClick={() => {}}
                />
              </Box>
            )}
            <Box
              className={cn("pills", null, ["mb-1"])}
              css={{ display: "none", "@md": { display: "inline-block" } }}
            >
              <TextPill className={cn("pill")} text={type} />
            </Box>

            <TextH1 className={cn("title", null, ["mt-0 mb-2"])}>{name}</TextH1>

            {numberOfReviews > 0 && (
              <Box
                css={{ display: "none", "@sm": { display: "inline-block" } }}
              >
                <RatingSummary
                  rating={rating}
                  numberOfReviews={numberOfReviews}
                />
              </Box>
            )}
            <Box
              className={cn("info-wrapper", null, ["mt-3 mb-4"])}
              variant="infoWrapper"
            >
              {price && (
                <Box
                  className={cn("info", null, ["mr-4 mr-last-0"])}
                  variant="info"
                >
                  <Label className={cn("info-label", null, ["mb-2"])}>
                    Price
                  </Label>
                  <Box className={cn("info-value")} variant="infoValue">
                    {discount && (
                      <HighlightPrice
                        price={Discount__DiscountedPrice(discount, price)}
                        currency={currency}
                      />
                    )}
                    <Span lineThrough={discount !== undefined}>
                      {Number__Currency(
                        price,
                        CurrencyISO__CurrencySymbol(currency)
                      )}
                    </Span>
                  </Box>
                </Box>
              )}
              {level && (
                <Box
                  className={cn("info", null, ["mr-4 mr-last-0"])}
                  variant="info"
                >
                  <Label className={cn("info-label", null, ["mb-2"])}>
                    Level
                  </Label>
                  <Box className={cn("info-value")} variant="infoValue">
                    {level}
                  </Box>
                </Box>
              )}
              {postage && (
                <Box
                  className={cn("info", null, ["mr-4 mr-last-0"])}
                  variant="info"
                >
                  <Label className={cn("info-label", null, ["mb-2"])}>
                    Delivery Time
                  </Label>
                  <Box className={cn("info-value")} variant="infoValue">
                    {postage.deliveryTime.from}-{postage.deliveryTime.to}{" "}
                    {RunningDaysLabels[postage.runningDays].toLowerCase()}
                  </Box>
                </Box>
              )}
            </Box>
            <Span className={cn("description")} variant="description">
              {description}
            </Span>
            {learningMaterials.length ? (
              <Box className={cn("sub-description")} variant="subDescription">
                <Box
                  className={cn("sub-description-title")}
                  variant="subDescriptionTitle"
                >
                  Learning materials included
                </Box>
                {learningMaterials.map((material, index) => (
                  <Box
                    key={index}
                    className={cn("sub-description-txt")}
                    variant="subDescriptionTxt"
                  >
                    {material}
                  </Box>
                ))}
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
