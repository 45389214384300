import React from "react";

import RatingSummary from "../RatingSummary";
import FavoriteButton from "../FavouriteButton";
import { Timezones } from "@obby/constants";
import {
  Duration__DurationString,
  Number__Currency,
  Discount__DiscountedPrice,
  CurrencyISO__CurrencySymbol,
  UTCDate__FormattedDate,
  CloudinaryUrl__TransformedUrl
} from "@obby/lib";
import { useAppText } from "../../containers";
import * as Icons from "../Icons";
import Icon from "../Icon";
import HighlightPrice from "../HighlightPrice";
import { Skeleton } from "../Skeleton";

import { styles } from "./CourseWideCard.styles";
import { AspectRatio } from "../../boxes";

export function CourseWideCard({
  thumbnail,
  title,
  description,
  rating,
  numberOfReviews,
  numberOfStudents,
  numberOfAdditionalDates,
  price,
  currency,
  samePriceAlways = true,
  location,
  duration,
  addToWishList = true,
  discount,
  isFavorite,
  onAddToWishList = () => {},
  onRemoveFromWishList = () => {},
  type,
  nextAvailableDate,
  isFlexitime,
  timezone = Timezones.EUROPE_LONDON,
  skeleton
}) {
  const { __ } = useAppText();

  function onFavoriteToggle(e) {
    e.stopPropagation();
    e.preventDefault();
    if (isFavorite) onRemoveFromWishList();
    else onAddToWishList();
  }

  return (
    <div className={styles.card()}>
      <div className="row">
        <div className="col-12 col-smd-4">
          {skeleton ? (
            <>
              <AspectRatio className="d-smd-none" width={535} height={405}>
                <Skeleton dark stretch sharp />
              </AspectRatio>
              <Skeleton className="d-none d-smd-block" dark stretch sharp />
            </>
          ) : (
            <img
              alt={title}
              className={styles.thumbnail()}
              src={CloudinaryUrl__TransformedUrl(thumbnail, {
                width: 535,
                height: 405,
                crop: "fill"
              })}
            />
          )}
        </div>
        <div className="col-12 col-smd-8">
          <div className={styles.content()}>
            <div className={styles.header()}>
              <span className={styles.title()}>
                {skeleton ? <Skeleton dark /> : title}
              </span>
              {skeleton ? (
                <Skeleton dark width={50} />
              ) : (
                <span className={styles.priceWrapper()}>
                  {discount && (
                    <HighlightPrice
                      price={Discount__DiscountedPrice(discount, price)}
                    />
                  )}
                  <span
                    className={styles.price({
                      withDiscount: discount !== undefined
                    })}
                  >
                    {!samePriceAlways &&
                      `from ${Number__Currency(
                        price,
                        CurrencyISO__CurrencySymbol(currency)
                      )}`}
                    {samePriceAlways &&
                      price > 0 &&
                      `${Number__Currency(
                        price,
                        CurrencyISO__CurrencySymbol(currency)
                      )}`}
                  </span>
                </span>
              )}
            </div>
            {skeleton ? (
              <div className={styles.section()}>
                <Skeleton dark width={130} />
              </div>
            ) : (
              numberOfReviews > 0 && (
                <div className={styles.section()}>
                  <div className="d-sm-none">
                    <RatingSummary
                      rating={rating}
                      starColor="emeraldish"
                      starSize="small"
                    />
                  </div>
                  <div className="d-none d-sm-block">
                    <RatingSummary
                      rating={rating}
                      reviewCount={numberOfReviews}
                      starColor="emeraldish"
                    />
                  </div>
                </div>
              )
            )}

            <div
              className={styles.description({ className: styles.section() })}
            >
              {skeleton ? <Skeleton lines={2} dark /> : description}
            </div>
            {skeleton ? (
              <div className={styles.section()}>
                <Skeleton dark width={260} />
              </div>
            ) : (
              <>
                {" "}
                {nextAvailableDate && (
                  <div className={styles.section()}>
                    <div className={styles.nextSessionDate()}>
                      <span className={styles.nextSessionDateLabel()}>
                        Next available date:
                      </span>
                      {UTCDate__FormattedDate(
                        nextAvailableDate,
                        timezone,
                        "Do MMMM YYYY"
                      )}
                    </div>
                  </div>
                )}
                {!nextAvailableDate && isFlexitime && (
                  <div className={styles.section()}>
                    <div className={styles.nextSessionDate()}>
                      <span className={styles.nextSessionDateLabel()}>
                        Book this class as a private group!
                      </span>
                    </div>
                  </div>
                )}
              </>
            )}

            <div className={styles.footer()}>
              {skeleton ? (
                <Skeleton dark width={130} />
              ) : (
                <>
                  <Separator
                    className={styles.extraInfoItem()}
                    separator={<span className={styles.extraInfoSeparator()} />}
                  >
                    {location && (
                      <>
                        <Icon
                          className={styles.locationIcon()}
                          height={16}
                          icon={Icons.Location}
                        />
                        {location}
                      </>
                    )}
                    {numberOfStudents &&
                      `Up to ${numberOfStudents} ${
                        numberOfStudents === 1
                          ? __("attendee")
                          : __("attendees")
                      }`}
                    {numberOfAdditionalDates &&
                      `${numberOfAdditionalDates + 1} session course`}
                    {duration && Duration__DurationString(duration)}
                  </Separator>
                  <span className={styles.type()}>{type}</span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/*</CardBackground>*/}
      {!skeleton && addToWishList && (
        <FavoriteButton
          className={styles.favoriteButton()}
          checked={isFavorite}
          onToggle={onFavoriteToggle}
        />
      )}
    </div>
  );
}

function Separator(props) {
  const { children, separator, className = "" } = props;

  let printSeparator = false;
  return (
    <div className={className}>
      {React.Children.map(children, (child, index) => {
        if (!child) return <></>;
        const render = (
          <>
            {printSeparator && separator}
            <span className={className ? `${className}-item` : undefined}>
              {child}
            </span>
          </>
        );
        printSeparator = true;
        return render;
      })}
    </div>
  );
}
