// @ts-nocheck
import React, { useState, useEffect } from "react";
import {
  asc,
  Minutes__Time,
  TimeInterval__Times,
  unique,
  uniqueObject,
  UTCDate__Add,
  UTCDate__FormattedDate,
  UTCDate__Get,
  UTCDate__IsAfter,
  UTCDate__IsBefore,
  UTCDate__StartOf,
  UTCDates__IsOverlapping
  // @ts-ignore
} from "@obby/lib";

import { faCalendar } from "@fortawesome/free-regular-svg-icons/faCalendar";
import { faClock } from "@fortawesome/free-regular-svg-icons/faClock";
import { PricingOption, TimeInterval } from "../PricingOption";
import { PricingOptionBox } from "../PricingOptionBox";
import { DateTimeBox } from "../DateTimeBox";
import { Row } from "../../../layout/Row";
import { Col } from "../../../layout/Col";
import { Block } from "../../../layout/Block";
import { SecondaryButton } from "../../../buttons/SecondaryButton";
import { Fields } from "./PricingOptionsStep.schema";
import { DayPicker } from "../../../inputs/DayPicker";
import { PricingOptionSectionHeader } from "../PricingOptionSectionHeader";
import { styles } from "./PricingOptionsStep.styles";
import { CheckoutSteps } from "../../../navs";

export function PricingOptionsStep({
  course,
  onChange,
  options,
  timezone,
  unavailableTimeBlocks,
  values,
  preventIsEditingPricingOptions = false
}: Props) {
  console.log("PricingOptionsStep ", values);
  const [isEditingPricingOptions, setIsEditingPricingOptions] = useState(
    (values.option === null || options.length == 1) &&
      !preventIsEditingPricingOptions
  );
  const [isEditingDate, setIsEditingDate] = useState(true);
  const [times, setTimes] = useState([]);

  const disabledDaysOfWeek = getDisabledDaysOfWeek(options, values.option);
  const disabledBefore = getDisabledBefore(
    course.appointmentScheduleBriefing.daysNotice,
    timezone
  );
  useEffect(() => {
    if (values.option !== null && values.date !== null) {
      let innerTimes = getTimeIntervals(
        options[values.option],
        values.date,
        timezone,
        unavailableTimeBlocks
      );
      //@ts-ignore
      setTimes(innerTimes);
    }
  }, [values]);

  function isCurrentTimeAvailable(date: Date | null) {
    if (values.time === null || date === null) return false;
    const timeIntervals = getTimeIntervals(
      options[values.option],
      date,
      timezone,
      unavailableTimeBlocks
    );
    return timeIntervals.some(
      timeInterval => timeInterval.start === values.time
    );
  }

  function onDateChange(value: string) {
    // let's collapse calendar if we are setting the date for the first time
    if (values.date === null) setIsEditingDate(false);
    const date = new Date(value);
    onChange(date, "date");

    if (!isCurrentTimeAvailable(date)) onChange(null, "time");
  }

  function onPricingOptionChange(index: number) {
    // let's collapse pricing option if we are setting it for the first time
    if (values.option === null) setIsEditingPricingOptions(false);
    onChange(index, "option");

    // reset the date and make sure the calendar is open if the new pricing option
    // is not available for the current selected date
    if (
      values.date !== null &&
      !isPricingOptionAvailableOnDate(
        options[index],
        values.date.toISOString(),
        timezone
      )
    ) {
      onChange(null, "date");
      setIsEditingDate(true);
    }

    if (!isCurrentTimeAvailable(values.date)) onChange(null, "time");
  }

  function isDayDisabled(date: string) {
    const eligibleOptions =
      values.option === null ? options : [options[values.option]];

    return eligibleOptions.every(option => {
      return !isPricingOptionAvailableOnDate(option, date, timezone);
    });
  }
  console.log("the discount is ", course.discount);

  return (
    <div className={styles.pricingOptionsStep()}>
      {!preventIsEditingPricingOptions && (
        <>
          <PricingOptionSectionHeader
            heading="Pricing Options"
            description="Select the option you'd like to book. Different options may have different dates & times available."
            chevron={isEditingPricingOptions ? "down" : "up"}
            // isOpen={isEditingPricingOptions}
            onChevronClick={
              values.option === null
                ? undefined
                : () => setIsEditingPricingOptions(!isEditingPricingOptions)
            }
          />
          <div
            onClick={
              isEditingPricingOptions
                ? undefined
                : () => setIsEditingPricingOptions(true)
            }
          >
            <Row rowGap={2}>
              {options
                .map((option, index) => {
                  let isDiscounted = course.discount ? true : false;
                  let discountPrice = 0;
                  if (isDiscounted) {
                    switch (course.discount.type) {
                      case "PERCENTAGE":
                        discountPrice =
                          option.price -
                          Math.ceil(
                            (option.price *
                              Math.min(course.discount.value, 100)) /
                              100
                          );
                        break;
                      case "PRICE":
                        discountPrice = Math.max(
                          0,
                          option.price - course.discount.value
                        );
                        break;
                    }
                  }
                  return (
                    <Col key={index}>
                      <PricingOptionBox
                        isDiscounted={isDiscounted}
                        discountPrice={discountPrice}
                        compacted={!isEditingPricingOptions}
                        currency={course.currency}
                        description={option.description}
                        duration={option.duration}
                        name={option.name}
                        onSelect={() => onPricingOptionChange(index)}
                        price={option.price}
                        selected={values.option === index}
                        optionsTotal={options.length}
                      />
                    </Col>
                  );
                })
                .filter(
                  (_, index) =>
                    isEditingPricingOptions || values.option === index
                )}
            </Row>
          </div>
        </>
      )}

      <PricingOptionSectionHeader
        heading="Date & Time"
        description="Choose the date and time you'd like to book."
        chevron={isEditingDate ? "down" : "up"}
        // isOpen={isEditingDate}
        onChevronClick={
          values.date === null
            ? undefined
            : () => setIsEditingDate(!isEditingDate)
        }
      />
      <DateTimeBox
        highlight={values.date !== null}
        icon={faCalendar}
        label={
          values.date
            ? UTCDate__FormattedDate(values.date, timezone, "dddd Do MMMM")
            : "Choose date"
        }
        disabled={values.option === null}
        onClick={() => setIsEditingDate(true)}
      >
        {isEditingDate && (
          <DayPicker
            value={values.date?.toISOString()}
            onChange={onDateChange}
            timezone={timezone}
            disabled={values.option === null}
            disabledDay={isDayDisabled}
            disabledBefore={disabledBefore}
            disabledDaysOfWeek={disabledDaysOfWeek}
          />
        )}
      </DateTimeBox>
      <DateTimeBox
        icon={faClock}
        label="Choose time"
        disabled={times.length === 0}
      >
        {times.length > 0 && (
          <Block padding={3} paddingSm={4}>
            <Row gap={3}>
              {times.map(time => (
                <Col key={time.start} col={6} sm={4}>
                  <SecondaryButton
                    text={`${Minutes__Time(time.start)} - ${Minutes__Time(
                      time.end
                    )}`}
                    size="medium"
                    color={
                      time.start === values.time ? "emeraldish" : "mercury"
                    }
                    onClick={() => onChange(time.start, "time")}
                  />
                </Col>
              ))}
            </Row>
          </Block>
        )}
      </DateTimeBox>
    </div>
  );
}

interface Props {
  course: any;
  errors: any;
  onBlur: (name: string) => void;
  onChange: (value: any, name: string) => void;
  options: PricingOption[];
  timezone: string;
  touched: any;
  unavailableTimeBlocks: any[];
  values: Fields;
  preventIsEditingPricingOptions?: any;
}

function getDisabledBefore(daysNotice: number, timezone: string) {
  daysNotice = Math.max(daysNotice, 0);
  // if (daysNotice === 0) {
  //   // check by the given hours notice if we still have time for today. if not return 1 day notice to prevent today
  //   // to show up enabled on day picker
  //   if (!times.some((minutes: number) => isTimeSchedulable(minutes)))
  //     daysNotice = 1;
  // }

  const today = UTCDate__StartOf(undefined, timezone, "day");
  return UTCDate__Add(today, daysNotice, "days");
}

function getDisabledDaysOfWeek(
  pricingOptions: PricingOption[],
  option: number | null
) {
  const isoWeekdays = pricingOptions
    .filter((_, index) => [null, index].includes(option))
    .reduce<number[]>(
      (isoWeekDays, pricingOption) =>
        isoWeekDays.concat(pricingOption.isoWeekdays),
      []
    )
    .filter(unique);

  return [0, 1, 2, 3, 4, 5, 6].filter(
    dayOfWeek => !isoWeekdays.includes(dayOfWeek || 7)
  );
}

function getTimeIntervals(
  option: PricingOption,
  date: Date,
  timezone: string,
  unavailableTimeBlocks: any[]
) {
  const day = UTCDate__StartOf(date, timezone, "day");
  const isoWeekday = UTCDate__Get(date, timezone, "isoWeekday");

  if (!option.timeIntervals[isoWeekday]) {
    return [];
  }
  let firstStep = option.timeIntervals[isoWeekday].filter(timeInterval =>
    isTimeIntervalAvailableOnDate(timeInterval, date.toISOString(), timezone)
  );
  let secondStep = firstStep.reduce<{ start: number; end: number }[]>(
    (timeIntervals, timeInterval) => {
      const times = TimeInterval__Times(
        timeInterval.start,
        timeInterval.end - option.duration,
        option.duration
      );
      timeIntervals.push(
        ...times.map((time: number) => ({
          start: time,
          end: time + option.duration
        }))
      );
      return timeIntervals;
    },
    []
  );
  // removes duplicates
  let thirdStep = secondStep.filter(uniqueObject((time: any) => time.start));
  // removes the times that overlaps the unavailable time blocks

  let fourthStep = thirdStep.filter(time => {
    const startDateTime = UTCDate__Add(day, time.start, "minutes", timezone);
    const endDateTime = UTCDate__Add(day, time.end, "minutes", timezone);
    return !unavailableTimeBlocks.some(timeBlock =>
      UTCDates__IsOverlapping(
        [startDateTime, endDateTime],
        [timeBlock.start, timeBlock.end]
      )
    );
  });
  let fifthStep = fourthStep.sort((time1, time2) =>
    asc(time1.start, time2.start)
  );
  return fifthStep;
}

function isPricingOptionAvailableOnDate(
  option: PricingOption,
  date: string,
  timezone: string
) {
  const isoWeekday = UTCDate__Get(date, timezone, "isoWeekday");

  if (!option.isoWeekdays.includes(isoWeekday)) return false;

  const timeIntervals = option.timeIntervals[isoWeekday];

  return timeIntervals.some(timeInterval =>
    isTimeIntervalAvailableOnDate(timeInterval, date, timezone)
  );
}

function isTimeIntervalAvailableOnDate(
  timeInterval: TimeInterval,
  date: string,
  timezone: string
) {
  const isBeforeDateRestriction =
    timeInterval.validFrom &&
    UTCDate__IsBefore(date, timeInterval.validFrom, "day", timezone);
  const isAfterDateRestriction =
    timeInterval.hasSpecificEndDate &&
    UTCDate__IsAfter(date, timeInterval.validUntil, "day", timezone);

  return !isBeforeDateRestriction && !isAfterDateRestriction;
}
