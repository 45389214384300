import React from "react";
// @ts-ignore
import { DiscountTypes, Currencies } from "@obby/constants";
// @ts-ignore
import { CurrencyISO__CurrencySymbol, Number__Currency } from "@obby/lib";

import { styles } from "./DiscountBadge.styles";

export function DiscountBadge({
  currency = Currencies.GBP,
  rotate = 0,
  size = "small",
  type,
  value
}: Props) {
  return (
    <div className={styles.badge({ size })}>
      <div
        className={styles.wrapper({
          size,
          css: {
            transform: `rotate(${rotate}deg)`
          }
        })}
      >
        <span className={styles.value({ size })}>
          {type === DiscountTypes.PERCENTAGE && `${value}%`}
          {type === DiscountTypes.PRICE &&
            Number__Currency(value, CurrencyISO__CurrencySymbol(currency))}
        </span>{" "}
        off
      </div>
    </div>
  );
}

interface Props {
  type: string;
  value: number;
  currency?: string;
  size?: "small" | "medium" | "large";
  rotate?: number;
}
