import React from "react";
import { CloudinaryImage } from "../../components/CloudinaryImage";
import {
  CurrencyISO__CurrencySymbol,
  Duration__DurationString,
  Number__Currency,
  UTCDate__FormattedDate
  // @ts-ignore
} from "@obby/lib";
import { styles } from "./CalendarListItem.styles";
// @ts-ignore
import { CourseTypes } from "@obby/constants";

export function CalendarListItem({
  hideDisplaysAvailableTickets = false,
  availability,
  courseType,
  currency,
  duration,
  isBookable,
  isBundlesEnabled,
  isPriceFrom,
  isSelfBookable,
  isTrialEnabled,
  location,
  number,
  numberOfSessions,
  onBookNow,
  onClick,
  onJoinCourseWaitingList,
  onJoinWaitingList,
  price,
  startDateTime,
  teacherImage,
  teacherName,
  teacherUrl,
  timezone,
  title,
  url
}: Props) {
  function onActionsClick(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    e.preventDefault();

    if (isBookable) onBookNow && onBookNow();
    else if ([CourseTypes.COURSE, CourseTypes.TERMLY].includes(courseType))
      onJoinCourseWaitingList && onJoinCourseWaitingList();
    else onJoinWaitingList && onJoinWaitingList();
  }

  let availabilityLabel = "";
  if (availability == 0) {
    availabilityLabel = "SOLD OUT";
  }
  if (availability > 0) {
    if (hideDisplaysAvailableTickets) {
      availabilityLabel = "Spots available";
    }
    if (!hideDisplaysAvailableTickets && number == 1) {
      availabilityLabel = `${availability} spots left`;
    }
  }

  return (
    <a
      href={url}
      className={styles.calendarListItem({
        soldOut: !availability
      })}
      onClick={onClick}
    >
      <div className={styles.edge()}>
        <div
          className={styles.day({
            className: "mt-2 mt-md-0"
          })}
        >
          {UTCDate__FormattedDate(startDateTime, timezone, "Do")}
          <br />
          {UTCDate__FormattedDate(startDateTime, timezone, "ddd")}
        </div>
      </div>
      <div className={styles.center()}>
        <span className={styles.title()}>
          {UTCDate__FormattedDate(startDateTime, timezone, "h:mma")} - {title}
        </span>
        <div className={styles.details({ className: "my-1 my-md-0" })}>
          <span className={styles.detail()}>{location}</span>
          {(courseType === CourseTypes.CLASS ||
            courseType === CourseTypes.WORKSHOP) && (
            <span className={styles.detail()}>
              {Duration__DurationString(duration)}
            </span>
          )}
          {courseType === CourseTypes.TERMLY && (
            <span className={styles.detail()}>
              {numberOfSessions} session term
            </span>
          )}

          {courseType === CourseTypes.COURSE && (
            <span className={styles.detail()}>
              {numberOfSessions} session course
            </span>
          )}
          {number > 1 && (
            <span className={styles.detail()}>
              Session {number} of {numberOfSessions}
            </span>
          )}
          {<span className={styles.detail()}>{availabilityLabel}</span>}
          {isTrialEnabled && (
            <span className={styles.detail()}>Trials available</span>
          )}
          {isBundlesEnabled && (
            <span className={styles.detail()}>Bundles available</span>
          )}
        </div>
        {teacherName && (
          <div
            className={styles.teacher({
              className: "mt-2 mt-md-0",
              clickable: !!teacherUrl
            })}
            onClick={
              teacherUrl
                ? e => {
                    e.preventDefault();
                    window.open(teacherUrl, "_blank");
                  }
                : undefined
            }
          >
            {teacherImage && (
              <CloudinaryImage
                className={styles.teacherImage({
                  className: "mr-2"
                })}
                src={teacherImage}
                width={40}
                height={40}
              />
            )}
            {teacherName}
          </div>
        )}
      </div>
      <div className={styles.actionWrapper()}>
        <div
          className={styles.actions({
            bookable: isBookable
          })}
          onClick={onActionsClick}
        >
          {isBookable && (
            <>
              {price !== undefined && (
                <div className={styles.price()}>
                  {isPriceFrom && "From "}
                  {Number__Currency(
                    price,
                    CurrencyISO__CurrencySymbol(currency)
                  )}
                </div>
              )}
              <span className={styles.action()}>
                {isSelfBookable
                  ? "Book now"
                  : {
                      [CourseTypes.COURSE]: "Join next date",
                      [CourseTypes.TERMLY]: "Join term"
                    }[courseType]}
              </span>
            </>
          )}
          {!isBookable && (
            <span className={styles.action()}>
              Join <br />
              Wait-list
            </span>
          )}
        </div>
      </div>
    </a>
  );
}

interface Props {
  hideDisplaysAvailableTickets?: boolean;
  availability: number;
  courseType: string;
  currency?: string;
  duration: number;
  isBookable: boolean;
  isBundlesEnabled?: boolean;
  isPriceFrom?: boolean;
  isSelfBookable: boolean;
  isTrialEnabled?: boolean;
  location: string;
  number: number;
  numberOfSessions: number;
  onBookNow: () => void;
  onClick?: () => void;
  onJoinCourseWaitingList?: () => void;
  onJoinWaitingList?: () => void;
  price: number;
  startDateTime: string;
  teacherImage?: string;
  teacherName?: string;
  teacherUrl?: string;
  timezone?: string;
  title: string;
  url: string;
}
