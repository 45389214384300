import axios from "./axios";
import token from "./token";

let authInstance = {};

authInstance.login = async (email, password) => {
  try {
    let response = await axios.post("PartnerLogin", {
      email,
      password
    });
    let user = response.data.user;
    let isCurrentUserTeacher = response.data.isCurrentUserTeacher;
    let school = response.data.school;
    token.setToken(response.data.token);
    return {
      user,
      isCurrentUserTeacher,
      school
    };
  } catch (error) {
    console.log("there was an error login", error);
    return { error: error.response.data };
  }
};

authInstance.signUp = async user => {
  try {
    let response = await axios.post("PartnerRegister", {
      emails: [
        {
          address: user.email
        }
      ],
      profile: {
        firstName: user.firstName,
        lastName: user.lastName,
        newsletter: user.isSignedUpToNewsletter
      },
      password: user.password
    });
    token.setToken(response.data.token);
    return {
      user: response.data.user,
      isCurrentUserTeacher: false,
      school: {}
    };
  } catch (error) {
    console.log("there was an error login", error);
    return { error: error.response.data };
  }
};

authInstance.changePassword = async (currentPassword, newPassword) => {
  try {
    let response = await axios.put("user/password", {
      currentPassword,
      newPassword
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
    //return { error:  };
  }
};

authInstance.resetPassword = async email => {
  try {
    let response = await axios.post("UserResetLink", { email });
    return response;
  } catch (error) {
    console.log("there was an error login", error);
    return { error: error.response.data };
  }
};

authInstance.socialLogin = async (service, data) => {
  try {
    let accessToken = data.accessToken;
    let response = await axios.post(`/${service}`, {
      access_token: accessToken
    });
    let user = response.data.user;
    let isCurrentUserTeacher = response.data.isCurrentUserTeacher;
    let school = response.data.school;
    token.setToken(response.data.token);
    return {
      user,
      isCurrentUserTeacher,
      school
    };
  } catch (error) {
    console.log("There was an erro with social login ", error);
    return { error: error.response.data };
  }
};

authInstance.logout = () => {
  token.logout();
};
export default authInstance;
