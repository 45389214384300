import React, { useContext } from "react";
import { Draggable } from "react-beautiful-dnd";

import { bemModule } from "../../configs/bem";

import { DraggableListItemContext } from "./DraggableListItemContext";
import styles from "./DraggableListItem.module.less";

const cn = bemModule(styles, "draggable-list--item");
export function DraggableListItem(props) {
  const { draggableId, children, neglectDrag = false } = props;
  const {
    index,
    isDraggedOverBelow,
    isDraggedOverAbove,
    isDragging,
    disabled
  } = useContext(DraggableListItemContext);

  return (
    <Draggable
      draggableId={draggableId}
      index={index}
      isDragDisabled={disabled || props.disabled}
    >
      {provided => {
        const { draggableProps, dragHandleProps, innerRef } = provided;

        return (
          <div
            className={cn({
              dragging: isDragging,
              "dragged-over-below": isDraggedOverBelow,
              "dragged-over-above": isDraggedOverAbove
            })}
            {...draggableProps}
            {...(neglectDrag ? {} : dragHandleProps)}
            ref={innerRef}
          >
            {typeof children === "function"
              ? children(neglectDrag ? dragHandleProps : {})
              : children}
          </div>
        );
      }}
    </Draggable>
  );
}
