import React, { useState } from "react";
import PropTypes from "prop-types";

import Icon from "../Icon";
import Icons from "../Icons";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import styles from "./banner-popup.module.less";
import ThemedMarkdownText from "../ThemedMarkdownText";

const BannerPopup = ({
  bannerImage,
  bannerImageTablet,
  bannerImageMobile,
  preTitle,
  title,
  onCloseModal,
  description,
  buttonText,
  onButtonClick,
  isOpen,
  buttonColor
}) => (
  <div
    className={`${styles["banner-popup"]} ${
      styles[`banner-popup--${isOpen ? "open" : "closed"}`]
    } `}
  >
    {/* BOX */}
    {isOpen && (
      <div className={styles["banner-popup__box"]}>
        <div className={styles["banner-popup__container"]}>
          <div className={styles["banner-popup__description-body"]}>
            <div className={styles["banner-popup__title-wrapper"]}>
              {preTitle && (
                <span className="banner-popup__title banner-popup__title-colored">
                  {preTitle}
                </span>
              )}
              <span className={styles["banner-popup__title"]}>{title}</span>
            </div>
            <div className={styles["banner-popup__description"]}>
              <ThemedMarkdownText
                text={description}
                classNames={{
                  paragraph: styles["banner-popup__description-paragraph"],
                  link: styles["banner-popup__description-link"]
                }}
              />
            </div>
            <div className={styles["banner-popup__button"]}>
              <PrimaryButton
                width="auto"
                textMode="uppercase"
                color={buttonColor}
                text={buttonText}
                onClick={onButtonClick}
              />
            </div>
          </div>
          {/* MOBILE: IMAGE */}
          {/* temp disable since we dont have the image
					<div className="banner-popup__image-wrapper banner-popup__image-wrapper--mobile">
						<img src={bannerImageMobile} className={styles["banner-popup__image"]} />
					</div>
					*/}
          {/* MOBILE: TABLET */}
          {/* temp disable since we dont have the image
					<div className="banner-popup__image-wrapper banner-popup__image-wrapper--tablet">
						<img src={bannerImageTablet} className={styles["banner-popup__image"]} />
					</div>
										*/}
          {/* MOBILE: DESKTOP */}
          {/* temp disable since we dont have the image
					<div className="banner-popup__image-wrapper banner-popup__image-wrapper--desktop">
						<img className={styles["banner-popup__image"]} src={bannerImage} />
					</div>
										*/}
        </div>
      </div>
    )}

    {/* CLOSE BUTTON: DESKTOP */}
    {onCloseModal ? (
      <div
        className={styles["banner-popup__desktop-close-button"]}
        onClick={onCloseModal}
      >
        <Icon
          icon={Icons.Cross}
          className={styles["banner-popup__desktop-close-button-icon"]}
          width={20}
        />
      </div>
    ) : null}

    {/* CLOSE BUTTON: MOBILE */}
    {onCloseModal ? (
      <div
        className={styles["banner-popup__mobile-close-button-wrap"]}
        onClick={onCloseModal}
      >
        <span className={styles["banner-popup__mobile-close-button"]}>
          Close
        </span>
      </div>
    ) : null}
  </div>
);

BannerPopup.defaultProps = {
  buttonText: "Ok",
  buttonColor: "robbinsEggBlue"
};

BannerPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  bannerImage: PropTypes.string.isRequired,
  bannerImageTablet: PropTypes.string.isRequired,
  bannerImageMobile: PropTypes.string.isRequired,
  preTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
  buttonColor: PropTypes.string
};

export default BannerPopup;
