import React from "react";
import {
  CurrencyISO__CurrencySymbol,
  UTCDate__FormattedDate,
  Number__Currency
} from "@obby/lib";
import Icon from "../../components/Icon";
import * as Icons from "../../components/Icons";
import { bemModule } from "../../configs/bem";
import styles from "./CustomerGiftCardCard.module.less";

const cn = bemModule(styles, "customer-gift-card-card");
export function CustomerGiftCardCard(props) {
  const {
    customerName,
    createdAt,
    amount,
    currency,
    timezone,
    giftcard
  } = props;

  return (
    <div className={cn()}>
      <div className={cn("row")}>
        <h2 className={cn("class")}>Gift card</h2>
        <div className={cn("details")}>
          <span className={cn("datetime")}>
            <Icon
              className={cn("icon", { general: true, "general-fill": true })}
              icon={Icons.Clock12h20m}
              height={16}
            />
            {UTCDate__FormattedDate(createdAt, timezone, "h:mm A")}
          </span>
          <span className={cn("datetime")}>
            <Icon
              className={cn("icon", { general: true, "general-stroke": true })}
              icon={Icons.CalendarThin}
              height={18}
            />
            {UTCDate__FormattedDate(createdAt, timezone, "dddd D MMMM YYYY")}
          </span>
          {giftcard.payout && (
            <span className={cn("datetime")}>
              <Icon
                className={cn("icon", {
                  general: true,
                  "general-stroke": true
                })}
                icon={Icons.CalendarThin}
                height={18}
              />
              <a href={`/payouts/${giftcard.payout}`}> Payout link</a>
            </span>
          )}
        </div>
      </div>
      <div className={cn("row", { flex: true })}>
        <span className={cn("customer-name")}>{customerName}</span>
        <span className={cn("price")}>
          {Number__Currency(amount, CurrencyISO__CurrencySymbol(currency))}
        </span>
      </div>
    </div>
  );
}
