import React from "react";

import {
  CurrencyISO__CurrencySymbol,
  Discount__DiscountedPrice,
  Number__Currency,
  CloudinaryUrl__TransformedUrl,
  String__TruncatedString,
  Rating__RoundedRating
  // @ts-ignore
} from "@obby/lib";
import Icons from "./../../components/Icons";
import Icon from "./../../components/Icon";
import FavoriteButton from "../../components/FavouriteButton";
import HighlightPrice from "../../components/HighlightPrice";

import * as styles from "./CourseCard.styles";

export function CourseCard({
  addToWishList,
  currency,
  discount,
  isFavourite = false,
  isFromPrice,
  isOnline,
  location,
  numberOfReviews,
  orientation = "vertical",
  onAddToWishList,
  onClick,
  onRemoveFromWishList,
  price,
  requiresSendingPhysicalMaterial,
  rating,
  title,
  thumbnail,
  url
}: Props) {
  const locationDisplay = !isOnline
    ? location
    : `Live Online${requiresSendingPhysicalMaterial ? " + Kit Included" : ""}`;

  function onFavouriteChange(isFavourite: boolean) {
    if (isFavourite) onAddToWishList && onAddToWishList();
    else onRemoveFromWishList && onRemoveFromWishList();
  }

  return (
    <div className={styles.card({ orientation })} onClick={onClick}>
      {addToWishList && (
        <div
          className={styles.favouriteButtonWrapper()}
          onClick={e => e.stopPropagation()}
        >
          <FavoriteButton
            className={styles.favouriteButton({ isFavourite, orientation })}
            value={isFavourite}
            onChange={onFavouriteChange}
          />
        </div>
      )}
      <a href={url} className={styles.imageWrapper()}>
        <img
          alt={title}
          src={CloudinaryUrl__TransformedUrl(thumbnail, {
            width: 450,
            height: 300,
            crop: "fill"
          })}
          className={styles.image()}
        />
      </a>
      <div className={styles.contentWrapper()}>
        <div className={styles.content({ orientation })}>
          <a
            href={url}
            className={styles.title({
              withMarginRight: addToWishList,
              orientation,
              truncated: false
            })}
          >
            {title}
          </a>
          <a
            href={url}
            className={styles.title({
              withMarginRight: addToWishList,
              orientation,
              truncated: true
            })}
            title={title}
          >
            {String__TruncatedString({ length: 38 }, title)}
          </a>
          <div>
            <div className={styles.detail({ orientation })}>
              {numberOfReviews > 0 && (
                <>
                  <Icon color="emeraldish" icon={Icons.Star} height={16} />
                  &nbsp;
                  <span className={styles.rating()}>
                    {rating}
                    {/**{Rating__RoundedRating(rating)*/}
                  </span>
                  &nbsp;
                  {`(${numberOfReviews}) - `}
                </>
              )}
              <span>&nbsp;{locationDisplay}</span>
            </div>
            <div className={styles.detail({ orientation })}>
              {discount && (
                <HighlightPrice
                  price={Discount__DiscountedPrice(discount, price)}
                  currency={currency}
                />
              )}
              <span
                className={styles.price({
                  lineThrough: discount !== undefined
                })}
              >
                {isFromPrice && "from "}
                {Number__Currency(price, CurrencyISO__CurrencySymbol(currency))}
                pp
              </span>
            </div>
          </div>
        </div>
        <a href={url} className={styles.view({ orientation })}>
          View
        </a>
        {/*<Link*/}
        {/*  className="mobile-class-card__title mobile-class-card__link mobile-class-card__view-link"*/}
        {/*  href={url}*/}
        {/*>*/}
        {/*  View*/}
        {/*</Link>*/}
      </div>
    </div>
  );
}

interface Props {
  addToWishList?: boolean;
  currency?: string;
  discount?: {
    type: string;
    value: number;
  };
  isFavourite?: boolean;
  isFromPrice?: boolean;
  isOnline?: boolean;
  location?: string;
  numberOfReviews: number;
  onAddToWishList?: () => void;
  onClick?: () => void;
  onRemoveFromWishList?: () => void;
  orientation?:
    | "vertical"
    | "horizontal"
    | { [media: string]: "vertical" | "horizontal" };
  price: number;
  requiresSendingPhysicalMaterial?: boolean;
  rating: number;
  thumbnail: string;
  title: string;
  url: string;
}
