import React from "react";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import PromptCard from "../PromptCard";
import Icons from "../Icons";
import AvatarSpeechBubbleBanner from "../AvatarSpeechBubbleBanner";
import styles from "./PostClassTab.module.less";

export default function PostClassTab({
  onContactObbyPopup,
  onSignIn,
  teacherName,
  teacherAvatar,
  speechBubbleText,
  isReviewEnabled = true,
  onLeaveReview,
  speechTitle,
  backgroundBanner
}) {
  return (
    <div className={styles["post-class-tab"]}>
      <div className={styles["post-class-tab__speech-bubble-wrapper"]}>
        <AvatarSpeechBubbleBanner
          personName={teacherName}
          personAvatar={teacherAvatar}
          text={speechBubbleText}
          title={speechTitle}
          backgroundBanner={backgroundBanner}
        />
      </div>
      {isReviewEnabled && (
        <div className={styles["post-class-tab__leave-a-review-wrapper"]}>
          <PromptCard
            title="Leave a review"
            titleSize="large"
            text="Leave a review of your experience of the class below. Let others know what you achieved on the class and tell the teacher your thoughts!"
            icon={Icons.Star}
            buttonText="Leave a review"
            topBorderColor="white"
            onClick={onLeaveReview}
          />
        </div>
      )}
      {onContactObbyPopup && (
        <div className={styles["get-ready-tab__single-chat-wrapper"]}>
          <div className={styles["get-ready-tab__make-account-wrapper"]}>
            <div className={styles["get-ready-tab__create-account"]}>
              <PrimaryButton
                onClick={onContactObbyPopup}
                text="Contact Obby"
                color="emeraldish"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
