import { css } from "../../sitches.config";

export const styles = {
  component: css({
    display: "block",
    letterSpacing: "0.3px",
    fontFamily: "$foundersGrotesk",
    fontWeight: 400,
    fontSize: "$mParagraph",
    lineHeight: 1,
    "--dp-background-color": "$colors$textColor",
    "--dp-text-color": "$colors$white",

    "--rdp-cell-size": "auto",
    "--rdp-background-color": "$colors$emeraldish",
    "--rdp-accent-color": "$colors$emeraldish",
    "--rdp-outline": "none",
    "--rdp-outline-selected": "2px solid $colors$emeraldish",

    "& .rdp": {
      margin: 0,
      padding: "$3",
      "@sm": {
        padding: "$4"
      }
    },

    "& .rdp-month": {
      flex: 1,
      color: "$textColor"
    },

    "& .rdp-caption_label": {
      fontSize: "20px",
      padding: 0,
      "@sm": {
        fontSize: "22px"
      }
    },

    "& .rdp-nav_button": {
      color: "var(--rdp-accent-color)",
      border: "solid 1px",
      width: "30px",
      height: "30px",
      "&:active, &:focus": {
        border: "solid 1px",
        color: "var(--rdp-accent-color)",
        backgroundColor: "$white"
      },
      '&:hover:not([aria-disabled="true"])': {
        backgroundColor: "$white"
      },
      "@md": {
        width: "35px",
        height: "35px"
      },
      "&_previous": {
        borderRadius: "50% 0 0 50%",
        borderRightWidth: 0,
        "&:active, &:focus": { borderRightWidth: 0 }
      },
      "&_next": {
        borderRadius: "0 50% 50% 0"
      }
    },

    "& .rdp-nav_icon": {
      width: "8px",
      "@md": {
        width: "10px"
      }
    },

    "& .rdp-table": {
      margin: "0 calc(100% / 7 * -0.125)",
      tableLayout: "fixed",
      width: "calc(100% + 100% / 7 * 0.25)",
      fontSize: "14px",
      "@sm": {
        fontSize: "16px"
      },
      "@md": {
        fontSize: "18px"
      }
    },

    "& .rdp-head_cell": {
      fontWeight: 400,
      fontSize: "inherit",
      aspectRatio: 1,
      paddingTop: "calc(100%  / 14)",
      position: "relative",
      textTransform: "capitalize",
      "& > span:first-child": {
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }
    },

    "& .rdp-cell": {
      position: "relative",
      fontSize: "inherit",
      fontWeight: "500",
      flex: "1 1 0",
      paddingTop: "calc(100%  / 7)"
    },

    "& .rdp-day": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "calc(100% - 25%)",
      height: "calc(100% - 25%)",
      margin: "12.5%",
      borderRadius: "50%",
      backgroundColor: "var(--dp-background-color)",
      color: "var(--dp-text-color)",
      '&:hover:not([aria-disabled="true"]), &:active, &:focus': {
        backgroundColor: "var(--dp-background-color)"
      },
      "&:active, &:focus": {
        color: "var(--dp-text-color)"
      },
      '&_selected, &_selected:hover:not([aria-disabled="true"])': {
        backgroundColor: "var(--rdp-accent-color)"
      },
      '&_disabled, &_disabled[aria-disabled="true"]': {
        color: "$colors$silver",
        border: "none",
        backgroundColor: " $athensGray",
        cursor: "default",
        opacity: 1,
        pointerEvents: "inherit"
      }
    },

    variants: {
      disabled: {
        true: {
          "--dp-background-color": "$colors$silver",
          "--dp-text-color": "$colors$athensGray",
          "& .rdp-day": {
            cursor: "default",
            '&:not([aria-disabled="true"])': {
              cursor: "default"
            },
            "&_selected": {
              '&, &:not([aria-disabled="true"]), &:focus:not([aria-disabled="true"]), &:active:not([aria-disabled="true"]), &:hover:not([aria-disabled="true"])': {
                color: "$athensGray",
                backgroundColor: "$gray",
                borderColor: "$gray"
              }
            }
          }
        }
      }
    }
  }),
  body: css({
    position: "relative",
    backgroundColor: "$white",
    borderRadius: "10px"
  })
};
