import { UTCDate__IsSameOrBefore } from "./UTCDate__IsSameOrBefore";
import { UTCDate__IsSameOrAfter } from "./UTCDate__IsSameOrAfter";
import { UTCDate__IsAfter } from "./UTCDate__IsAfter";
import { UTCDate__IsBefore } from "./UTCDate__IsBefore";

/**
 * Simple way to check for inclusive overlaps. In order to change to exclusive overlap just replace
 * UTCDate__IsSameOrBefore and UTCDate__IsSameOrAfter with UTCDate__IsBefore and UTCDate__IsAfter accordingly
 * @see https://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap#answer-325964
 */
export function UTCDates__IsOverlapping(
  [startDate1, endDate1]: [string, string],
  [startDate2, endDate2]: [string, string]
) {
  let firstCondition = UTCDate__IsBefore(startDate1, endDate2, "minute");
  let secondCondition = UTCDate__IsAfter(endDate1, startDate2, "minute");
  let result = firstCondition && secondCondition;
  return result;
}
