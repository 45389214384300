import React, { useState } from "react";
import { MenuNavUserLinks } from "./MenuNavUserLinks";
import styles from "./menu-nav-user.module.less";
import { useClickOutside } from "../../hooks";

export function MenuNavUser(props) {
  const { avatarUrl, onSignOut } = props;
  const ref = useClickOutside(onBlur);

  const [open, setOpen] = useState(false);

  function onBlur() {
    setOpen(false);
  }

  function onAvatarMouseDown() {
    setOpen(!open);
  }

  return (
    <div className={`${styles["menu-nav-user"]} pl-4`} ref={ref}>
      <a
        className={styles["menu-nav-user__avatar"]}
        onMouseDown={onAvatarMouseDown}
      >
        <img
          className={styles["menu-nav-user__avatar-image"]}
          src={avatarUrl}
          alt="Avatar"
        />
      </a>
      {open && (
        <div className={styles["menu-nav-user__popout"]}>
          <span className={styles["menu-nav-user__popout-heading"]}>
            My account
          </span>
          <MenuNavUserLinks onSignOut={onSignOut} />
        </div>
      )}
    </div>
  );
}
