import { css } from "@obby/components";

export const styles = {
  labelInput: css({
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    // this rule will not affect the component anywhere since it is always used as first child
    marginTop: "30px",
    "&:first-child": {
      marginTop: "0"
    }
  }),
  label: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$lParagraph",
    lineHeight: "1",
    fontWeight: "400",
    color: "$ebonyClay",
    width: "100%",
    marginBottom: "15px"
  }),
  inputWrap: css({
    width: "100%"
  }),
  iconInput: css({
    width: "100%",
    display: "flex",
    position: "relative"
  }),
  input: css({
    width: "100%",
    fontFamily: "$foundersGrotesk",
    fontSize: "$l-paragraph",
    lineHeight: "1",
    fontWeight: "400",
    color: "$ebonyClay",
    border: "1px solid $gallery",
    borderRadius: "5px",
    backgroundColor: "transparent",
    margin: "0",
    padding: "10px 20px",
    outline: "none",
    "-moz-appearance": "textfield",
    transition: "all 250ms",

    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: "0"
    },
    variants: {
      variant: {
        icon: {
          paddingLeft: "35px"
        }
      }
    }
  }),
  lockedText: css({
    width: "100%",
    fontFamily: "$foundersGrotesk",
    fontSize: "$lParagraph",
    lineHeight: "1.25",
    fontWeight: "400",
    color: "$ebonyClay"
  }),
  inputIcon: css({
    display: "flex",
    alignItems: "center",
    height: "100%",
    position: "absolute",
    left: "15px",
    fontFamily: "$foundersGrotesk",
    fontSize: "$lParagraph",
    lineHeight: "1",
    color: "$ebonyClay",
    fontWeight: "400",
    margin: "0",
    animation: "fadeIn 250ms"
  }),
  textArea: css({
    width: "100%",
    fontFamily: "$foundersGrotesk",
    fontSize: "$lParagraph",
    lineHeight: "$lParagraphLh",
    color: "$ebonyClay",
    border: "1px solid $gallery",
    borderRadius: "5px",
    backgroundColor: "transparent",
    margin: "0",
    padding: "10px 20px",
    minHeight: "150px",
    outline: "none",

    "&::placeholder": {
      color: "$alto"
    }
  }),
  characterCount: css({
    paddingTop: "15px",
    fontFamily: "$foundersGrotesk",
    fontSize: "$mParagraph",
    lineHeight: "1",
    color: "$alto"
  }),
  note: css({
    paddingTop: "15px",
    fontFamily: "$foundersGrotesk",
    fontSize: "$smParagraph",
    lineHeight: "1.25",
    color: "$alto"
  }),
  error: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$mParagraph",
    paddingTop: "15px",
    lineHeight: "1.25",
    color: "$deepBlush"
  })
};
