import React from "react";
import PropTypes from "prop-types";

import Image from "../Image";
import Link from "../Link";
import style from "./class-list-card.module.less";

const ClassListCard = ({
  title,
  thumbnail,
  numOfClasses,
  link,
  textColor = "#28283c",
  onClick
}) => {
  return (
    <div className={styles["class-list-card"]}>
      <Image
        src={thumbnail}
        className={styles["class-list-card__background-image"]}
        asBackground
      />
      <Link
        href={link}
        className={styles["class-list-card__content"]}
        onClick={onClick}
      >
        <span
          className={styles["class-list-card__title"]}
          style={{ color: textColor }}
        >
          {title}
        </span>
        <span
          className={styles["class-list-card__num-classes"]}
          style={{ color: textColor }}
        >{`${numOfClasses} Class${numOfClasses > 1 ? "es" : ""}`}</span>
      </Link>
    </div>
  );
};

ClassListCard.propTypes = {
  title: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  numOfClasses: PropTypes.number.isRequired,
  textColor: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(["light", "dark"]),
  link: PropTypes.string.isRequired
};

export default ClassListCard;
