import { useState, useEffect } from "react";
import { createContainer } from "../../configs";
import { useFormState } from "../../hooks";
import { PersonalDetailsForm } from "../../forms/PersonalDetailsForm";
import { useDiscount } from "../../hooks/useDiscount";
import { useStripePayment } from "../StripePayment";

function useGiftCardCheckoutContainer({
  amount,
  cover,
  currency,
  deliveryMethod,
  description,
  discount,
  email,
  from,
  message,
  to,
  user
}) {
  useEffect(() => {
    if (user) {
      const { firstName = "", lastName = "" } = user.profile;
      const [{ address = "" } = {}] = user.emails || [];
      setFieldValues({
        firstName,
        lastName,
        email: address
      });
    }
  }, [user]);

  const stripePayment = useStripePayment();

  const {
    errors,
    isValid,
    setFieldValue,
    setFieldValues,
    setFieldTouched,
    touched,
    values
  } = useFormState({
    schema: PersonalDetailsForm.Schema(true),
    values: {
      firstName: "",
      lastName: "",
      email: "",
      guest: false,
      marketingOptIn: false
    }
  });

  const { getDiscount, getDiscountPercentage } = useDiscount(
    () => amount * 100,
    undefined,
    discount
  );

  function onBlur(name) {
    setFieldTouched(name);
  }

  async function onCardPaymentEnter() {
    await stripePayment.getPaymentIntent(getGiftCardData());
  }

  function onChange(value, name) {
    setFieldValue(name, value);
  }

  function onContinue() {
    stripePayment.submitPayment();
  }

  function getTotal() {
    const discount = getDiscount();
    const postage = deliveryMethod === "postbox" ? 300 : 0;
    return amount * 100 - discount + postage;
  }

  function getGiftCardData() {
    const { firstName, lastName, phoneNumber, email } = values;
    return {
      firstName,
      lastName,
      phoneNumber,
      email
    };
  }

  return {
    errors,
    values,
    touched,
    amount,
    cover,
    currency,
    deliveryMethod,
    description,
    discount,
    email,
    from,
    isStripePaymentReady: stripePayment.isReady,
    message,
    to,
    helpers: {
      getDiscount,
      getDiscountPercentage,
      getTotal,
      isValid
    },
    actions: {
      onBlur,
      onCardPaymentEnter,
      onChange,
      onContinue
    }
  };
}
export const GiftCardCheckoutContainer = createContainer(
  useGiftCardCheckoutContainer
);
