import React from "react";
import PropTypes from "prop-types";

import CardBackground from "../CardBackground";
import RatingStars from "../RatingStars";

import styles from "./ReviewCard.module.less";
import { UTCDate__FormattedDate } from "@obby/lib";
import { Timezones } from "@obby/constants";

export default function ReviewCard(props) {
  const {
    name,
    review,
    date,
    rating,
    value,
    firstName,
    lastName,
    showOnlyFirstName,
    timezone = Timezones.EUROPE_LONDON
  } = props;

  let nameToDisplay = "Anonymous";
  if (showOnlyFirstName) {
    if (firstName) {
      nameToDisplay = firstName;
    } else {
      if (name) {
        nameToDisplay = name.split(" ")[0];
      }
    }
  } else {
    if (name) {
      nameToDisplay = name;
    }
  }
  return (
    <div className="review-card">
      <CardBackground>
        <div className={styles["review-card__header"]}>
          <div className={`review-card__name ${styles["review-card__name"]}`}>
            {nameToDisplay}
            {date && (
              <>
                {" - "}
                <span className={styles["review-card__date"]}>
                  {date &&
                    UTCDate__FormattedDate(date, timezone, "D MMMM YYYY")}
                </span>
              </>
            )}
          </div>
          {rating > 0 && (
            <div
              className={`review-card-rating ${styles["review-card__rating"]}`}
            >
              <RatingStars rating={rating} color="emeraldish" />
            </div>
          )}
          {value > 0 && (
            <div className={styles["review-card__rating"]}>
              <RatingStars rating={value} color="emeraldish" />
            </div>
          )}
          {/*</div>*/}
        </div>
        <div className={`review-card__text ${styles["review-card__text"]}`}>
          {review}
        </div>
      </CardBackground>
    </div>
  );
}

ReviewCard.propTypes = {
  name: PropTypes.string,
  avatarUrl: PropTypes.string,
  review: PropTypes.string,
  date: PropTypes.string,
  rating: PropTypes.number
};
