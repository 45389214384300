import React from "react";

import { CloudinaryImage } from "../CloudinaryImage";
import styles from "./balloon-card.module.less";

export function BalloonCard(props) {
  const { title, description, image } = props;
  return (
    <div className={styles["balloon-card"]}>
      <div>
        <CloudinaryImage
          className={styles["balloon-card__image"]}
          src={image}
        />
      </div>
      <h2 className={styles["balloon-card__title"]}>{title}</h2>
      <div className={styles["balloon-card__description"]}>{description}</div>
    </div>
  );
}
