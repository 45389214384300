import React, { useRef, useEffect } from "react";
import _ from "lodash";
import Label from "../Label";
import { TextInput } from "../TextInput";
import { CurrencyInput } from "../CurrencyInput";
import { SelectBox, Option } from "../../inputs/SelectBox";
import styles from "./gift-card-form.module.less";
import TextArea from "../TextArea";
import { CurrencyISO__CurrencySymbol, Number__Currency } from "@obby/lib";
import { bemModule } from "../../configs/bem";
import { RadioInput } from "../../common/inputs/RadioInput";

const cn = bemModule(styles, "gift-card-form");
export function GiftCardForm(props) {
  const {
    currency = "GBP",
    errors = {},
    touched = {},
    values = {},
    onBlur,
    onChange,
    options,
    disablePhysicalDelivery = false,
    minNumberOfGuests = 1,
    maxNumberOfGuests = 10
  } = props;

  const customValueInputRef = useRef();

  useEffect(() => {
    if (values.value === "custom") customValueInputRef.current.focus();
  }, [values.value]);

  return (
    <div className={cn()}>
      <div className="row pb-3">
        {options && (
          <>
            <div className="col-6 pb-3">
              <Label
                label="Gift Option"
                error={touched.option && errors.option}
                nowrap
              >
                <SelectBox
                  name="option"
                  onChange={onChange}
                  value={values.option}
                  accent="camelot"
                >
                  {options.map(option => (
                    <Option key={option._id} value={option._id}>
                      {option.name} -{" "}
                      {Number__Currency(
                        option.price,
                        CurrencyISO__CurrencySymbol(currency)
                      )}
                    </Option>
                  ))}
                </SelectBox>
              </Label>
            </div>
            <div className="col-6 pb-3">
              <Label
                label="Number of guests"
                error={touched.numberOfGuests && errors.numberOfGuests}
                nowrap
              >
                <SelectBox
                  name="numberOfGuests"
                  onChange={onChange}
                  value={values.numberOfGuests}
                  accent="camelot"
                >
                  {_.range(minNumberOfGuests, maxNumberOfGuests + 1, 1).map(
                    number => (
                      <Option key={number} value={number}>
                        {number} {number === 1 ? "guest" : "guests"}
                      </Option>
                    )
                  )}
                </SelectBox>
              </Label>
            </div>
          </>
        )}

        {!options && (
          <>
            <div className="col-6 pb-3">
              <Label
                label="Gift card value"
                error={touched.value && errors.value}
                nowrap
              >
                <SelectBox
                  name="value"
                  onChange={onChange}
                  value={values.value}
                  accent="camelot"
                >
                  {[5000, 10000, 15000].map(number => (
                    <Option key={number} value={number}>
                      {Number__Currency(
                        number,
                        CurrencyISO__CurrencySymbol(currency)
                      )}
                    </Option>
                  ))}
                  <Option value="custom">Custom</Option>
                </SelectBox>
              </Label>
            </div>
            {values.value === "custom" && (
              <div className="col-6 pb-3">
                <Label
                  label="Custom value"
                  error={touched.customValue && errors.customValue}
                  nowrap
                >
                  <CurrencyInput
                    inputRef={customValueInputRef}
                    name="customValue"
                    placeholder="75.00"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={values.customValue}
                    accent="camelot"
                    symbol={CurrencyISO__CurrencySymbol(currency)}
                  />
                </Label>
              </div>
            )}
            {values.value !== "custom" && (
              <div className="offset-6 offset-md-0" />
            )}
          </>
        )}

        {values.deliveryMethod === "email" && (
          <>
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-sm-6 pt-1 pt-md-0 pb-3 pb-sm-0">
                  <Label
                    label="From"
                    error={
                      touched.emailDetails?.from && errors.emailDetails?.from
                    }
                  >
                    <TextInput
                      name="emailDetails.from"
                      placeholder="Name"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={values.emailDetails?.from}
                      accent="camelot"
                    />
                  </Label>
                </div>
                <div className="col-12 col-sm-6 pt-1 pt-md-0">
                  <Label
                    label="To"
                    error={touched.emailDetails?.to && errors.emailDetails?.to}
                  >
                    <TextInput
                      name="emailDetails.to"
                      placeholder="Name"
                      onChange={onChange}
                      value={values.emailDetails?.to}
                      accent="camelot"
                    />
                  </Label>
                </div>
              </div>
            </div>
          </>
        )}
        {values.deliveryMethod === "postbox" && (
          <>
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-sm-6 pt-1 pt-md-0 pb-3 pb-sm-0">
                  <Label
                    label="First name"
                    error={
                      touched.deliveryDetails?.firstName &&
                      errors.deliveryDetails?.firstName
                    }
                  >
                    <TextInput
                      name="deliveryDetails.firstName"
                      placeholder=""
                      onChange={onChange}
                      onBlur={onBlur}
                      value={values.deliveryDetails?.firstName}
                      accent="camelot"
                    />
                  </Label>
                </div>
                <div className="col-12 col-sm-6 pt-1 pt-md-0">
                  <Label
                    label="Last name"
                    error={
                      touched.deliveryDetails?.lastName &&
                      errors.deliveryDetails?.lastName
                    }
                  >
                    <TextInput
                      name="deliveryDetails.lastName"
                      placeholder=""
                      onChange={onChange}
                      onBlur={onBlur}
                      value={values.deliveryDetails?.lastName}
                      accent="camelot"
                    />
                  </Label>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {!disablePhysicalDelivery && (
        <div className="row pb-3">
          <div className="col pt-1">
            <Label
              label="Delivery Method"
              error={touched.deliveryMethod && errors.deliveryMethod}
            >
              <RadioInput
                name="deliveryMethod"
                value={values.deliveryMethod}
                onChange={value => onChange(value, "deliveryMethod")}
              >
                <RadioInput.Option value="email">
                  Email to recipient
                </RadioInput.Option>
                <RadioInput.Option value="postbox">
                  Delivered by 1st Class Royal Mail post (£3)
                </RadioInput.Option>
              </RadioInput>
            </Label>
          </div>
        </div>
      )}
      {values.deliveryMethod === "email" && (
        <>
          <div className="row pb-3">
            <div className="col-12 pt-1">
              <Label
                label="What do you want to say?"
                error={touched.emailDetails?.note && errors.emailDetails?.note}
              >
                <TextArea
                  className={cn("note")}
                  name="emailDetails.note"
                  placeholder="Note"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={values.emailDetails?.note}
                  accent="camelot"
                />
              </Label>
            </div>
          </div>
          <div className="row pb-3">
            <div className="col-12 pt-1">
              <Label
                label="Recipient's email address"
                error={
                  touched.emailDetails?.recipientEmail &&
                  errors.emailDetails?.recipientEmail
                }
              >
                <TextInput
                  name="emailDetails.recipientEmail"
                  placeholder="Use your email if you want to send it to yourself"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={values.emailDetails?.recipientEmail}
                  accent="camelot"
                />
              </Label>
            </div>
          </div>
        </>
      )}

      {values.deliveryMethod === "postbox" && (
        <>
          <div className="row pb-3">
            <div className="col pt-1">
              <Label
                label="Address"
                error={
                  touched.deliveryDetails?.addressLine1 &&
                  errors.deliveryDetails?.addressLine1
                }
              >
                <TextInput
                  name="deliveryDetails.addressLine1"
                  placeholder=""
                  onChange={onChange}
                  onBlur={onBlur}
                  value={values.deliveryDetails?.addressLine1}
                  accent="camelot"
                />
              </Label>
            </div>
          </div>
          <div className="row pb-3">
            <div className="col pt-1">
              <Label
                label="Address line 2"
                error={
                  touched.deliveryDetails?.addressLine2 &&
                  errors.deliveryDetails?.addressLine2
                }
              >
                <TextInput
                  name="deliveryDetails.addressLine2"
                  placeholder=""
                  onChange={onChange}
                  onBlur={onBlur}
                  value={values.deliveryDetails?.addressLine2}
                  accent="camelot"
                />
              </Label>
            </div>
          </div>
          <div className="row pb-3">
            <div className="col-6 pt-1">
              <Label
                label="Post code"
                error={
                  touched.deliveryDetails?.postCode &&
                  errors.deliveryDetails?.postCode
                }
              >
                <TextInput
                  name="deliveryDetails.postCode"
                  placeholder="E10DR"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={values.deliveryDetails?.postCode}
                  accent="camelot"
                />
              </Label>
            </div>
            <div className="col-6 pt-1">
              <Label
                label="City"
                error={
                  touched.deliveryDetails?.city && errors.deliveryDetails?.city
                }
              >
                <TextInput
                  name="deliveryDetails.city"
                  placeholder=""
                  onChange={onChange}
                  onBlur={onBlur}
                  value={values.deliveryDetails?.city}
                  accent="camelot"
                />
              </Label>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
