import { css } from "../../../sitches.config";

const radio = css({
  display: "inline-block",
  position: "relative",
  overflow: "hidden",
  backgroundColor: "white",
  minWidth: "22px",
  height: "20px",
  border: "1px solid $mercury",
  borderRadius: "50%",
  variants: {
    size: {
      medium: {},
      large: {
        "@sm": {
          width: "35px",
          height: "35px"
        }
      }
    }
  }
});

const tickWrapper = css({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
});

export const styles = {
  component: css({
    display: "inline-block"
  }),
  input: css({
    display: "none",
    [`&:checked ~ ${radio}`]: {
      border: "none"
    },
    [`&:disabled ~ .${radio}`]: {
      border: "none",
      backgroundColor: "$mercury",
      [`.${tickWrapper}`]: {
        backgroundColor: "$mercury"
      }
    }
  }),
  radio,
  tickWrapper,
  tick: css({
    width: "12px",
    "@sm": {
      width: "initial"
    },
    path: {
      fill: "white"
    }
  })
};
