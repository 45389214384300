const AuthenticationStatuses = {
  SIGNING_IN: "SIGNING_IN",
  SIGNING_IN_FOR_CHECKOUT: "SIGNING_IN_FOR_CHECKOUT",
  SIGNING_UP_VIA_EMAIL: "SIGNING_UP_VIA_EMAIL",
  SIGNING_UP_VIA_SOCIAL: "SIGNING_UP_VIA_SOCIAL",
  RESETING_PASSWORD: "RESETING_PASSWORD",
  PASSWORD_RESET: "PASSWORD_RESET",
  SIGNED_OUT: "SIGNED_OUT",
  SIGNED_IN: "SIGNED_IN"
};

export default AuthenticationStatuses;
