import React from "react";
import { bemModule } from "../../configs/bem";
import CardBackground from "../CardBackground";
import { PrimaryButton } from "../PrimaryButton";
import styles from "./TagLandingPageHeader.module.less";

const cn = bemModule(styles, "tag-landing-page-header");
export function TagLandingPageHeader({
  highlight1,
  highlight2,
  title,
  buttons = [],
  children
}) {
  return (
    <CardBackground className={cn()}>
      <div className="row">
        <div className="col-12 col-smd-5">
          {(highlight1 || highlight2) && (
            <div className={cn("highlights")}>
              {highlight1 && (
                <span
                  className={cn("highlight", {
                    1: true,
                    color: highlight1.color,
                    "background-color": highlight1.backgroundColor
                  })}
                >
                  {highlight1.text}
                </span>
              )}
              {highlight2 && (
                <span
                  className={cn("highlight", {
                    2: true,
                    color: highlight2.color,
                    "background-color": highlight2.backgroundColor
                  })}
                >
                  {highlight2.text}
                </span>
              )}
            </div>
          )}
          <h1 className={cn("title")}>{title}</h1>
        </div>
        <div className="col-12 col-smd-6 offset-smd-1 order-1 order-smd-0">
          <div className={cn("description_container")}>
            <div className={cn("description")}>{children}</div>
          </div>
        </div>
      </div>
      {buttons.length > 0 && (
        <div className={cn("buttons", null, ["mt-4"])}>
          {buttons.map((button, index) => (
            <PrimaryButton
              key={index}
              className={cn("button", null, ["mx-2 mb-2"])}
              width="auto"
              text={button.text}
              onClick={button.onClick}
              size="medium"
              color="ebonyClay"
            />
          ))}
        </div>
      )}
    </CardBackground>
  );
}
