const ClassListItem__ClassListCard = (linkPrefix = "/") => {
  return classListItem => ({
    id: classListItem._id,
    slug: classListItem.slug,
    title: classListItem.title,
    numOfClasses: classListItem.numOfClasses,
    thumbnail: classListItem.thumbnail,
    textColor: classListItem.textColor,
    link: `${linkPrefix}/${classListItem.slug}`
  });
};

export default ClassListItem__ClassListCard;
