import { Duration__DurationString } from "./Duration__DurationString";
import Number__NumberText from "./Number__NumberText";
// @ts-ignore
import { CourseTypes } from "@obby/constants";
import { TermCourse__NumberOfWeeks } from "./TermCourse__NumberOfWeeks";

export function Course__DurationString(course: any, noun?: string) {
  const {
    duration,
    hasAvailableDates,
    numberOfAdditionalDates
  } = course.summary;
  if (hasAvailableDates)
    switch (course.type) {
      case CourseTypes.COURSE:
        return `${Number__NumberText(
          numberOfAdditionalDates + 1
        )} session ${noun || "course"}`;
      case CourseTypes.TERMLY:
        return `${TermCourse__NumberOfWeeks(course)} week ${noun || "term"}`;
    }
  return `${Duration__DurationString(duration)} ${noun || course.type}`;
}
