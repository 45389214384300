import React, { PropsWithChildren } from "react";

interface PropsGivenToContainer<I> {
  initialState?: I;
}
export function createContainer<T, I = undefined>(useHook: Hook<T, I>) {
  const Context = React.createContext<T | null>(null);

  function Provider(props: PropsWithChildren<PropsGivenToContainer<I>>) {
    // TODO: Add typings.
    // @ts-ignore
    let value = useHook(props.initialState);
    return <Context.Provider value={value}>{props.children}</Context.Provider>;
  }

  function useContainer() {
    return React.useContext(Context) as ReturnType<typeof useHook>;
  }

  return { Provider, useContainer, Consumer: Context.Consumer };
}

type Hook<T, I> = I extends undefined ? () => T : (initialState: I) => T;
