import { CountriesNames } from "@obby/constants";

export function Location__FullAddressString(location) {
  return [
    location.name || "",
    location.area || "",
    location.line1 || "",
    location.line2 || "",
    location.postCode || location.postcode,
    location.city || "",
    CountriesNames[location.country || "UK"]
  ]
    .filter(location => location)
    .join(", ");
}
