import { css, withBem } from "../../../sitches.config";

export const styles = withBem("pricing-option-section-header", {
  pricingOptionSectionHeader: css({
    color: "$textColor",
    marginTop: "$4",
    marginBottom: "$3",
    "&:first-child": {
      marginTop: 0
    }
  }),
  headingWrapper: css({
    display: "flex",
    gap: "$2",
    justifyContent: "space-between",
    alignItems: "center",
    variants: {
      border: {
        true: {
          borderBottom: "solid 1px $alto",
          marginBottom: "$1",
          paddingBottom: "$1"
        }
      }
    }
  }),
  heading: css({
    fontFamily: "$foundersGroteskCondensed",
    fontSize: "$26",
    textTransform: "uppercase"
    // marginBottom: "$1"
  }),

  description: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$18"
  }),

  chevron: css({
    transition: "transform 250ms",
    variants: {
      clickable: {
        true: {
          cursor: "pointer"
        }
      },
      position: {
        top: {
          transform: "rotate(180deg)"
        },
        down: {
          transform: "rotate(0)"
        }
      }
    }
  })
});
