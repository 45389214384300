import { css, withBem, keyframes } from "../../sitches.config";

const openPopup = keyframes({
  from: {
    top: "100%"
  },
  to: {
    top: 0
  }
});
const closePopup = keyframes({
  from: {
    top: 0
  },
  to: {
    top: "100%"
  }
});

export const styles = withBem("wide-popup", {
  root: css({
    position: "fixed",
    top: 0,
    height: "100vh",
    left: 0,
    right: 0,
    overflow: "hidden",
    zIndex: "1001"
  }),

  overlay: css({
    position: "absolute",
    height: "100%",
    width: "100%",
    animation: `${openPopup} 500ms`,
    variants: {
      closing: {
        true: { animationName: `${closePopup}` }
      }
    }
  }),

  wrapper: css({
    display: "flex",
    flexDirection: "column",
    backgroundColor: "$pampas",
    overflowY: "auto",
    height: "100%"
  }),

  header: css({
    flex: "0 0 34px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    "@md": {
      flex: "0 0 68px"
    }
  }),

  headerTitle: css({
    flexGrow: 1,
    fontFamily: "$foundersGroteskCondensed",
    fontWeight: 600,
    fontSize: "30px",
    textTransform: "uppercase",
    color: "$ebonyClay",
    margin: 0,
    paddingRight: "20px",
    textAlign: "center",
    "@md": {
      fontSize: "40px"
    }
  }),

  container: css({
    flexGrow: 1
  }),

  closeIcon: css({
    cursor: "pointer",
    width: "20px",
    g: {
      stroke: "$emeraldish"
    }
  }),

  logo: css({
    position: "absolute",
    right: "1.5rem",
    top: "-16px",
    flexGrow: 0,
    height: "100px"
  }),

  obbyIcon: css({
    display: "block",
    width: "116px",
    margin: "4.3px 0",
    transform: "rotate(-5.25deg)",
    ".background": {
      fill: "$ebonyClay"
    },
    ".foreground": {
      fill: "$hummingBird"
    }
  })
});
