import React, { useEffect } from "react";
import { PrimaryButton } from "../../components/PrimaryButton";
import SecondaryButton from "../../components/SecondaryButton";
import { Overlay } from "../../components/Overlay";
import { css, withBem, animations } from "../../sitches.config";

export function CenteredPopup(props) {
  const {
    title,
    children,
    onClose,
    closeButtonText = "Close",
    showCloseButton = true,
    closeButtonPosition = "button",
    close,
    onAccept,
    acceptButtonText = "Ok",
    position = "fixed",
    background = "dark",
    size = "small",
    scrollable = false
  } = props;

  useEffect(() => {
    if (close && onClose) onClose();
  }, [close]);
  return (
    <Overlay onClick={onClose} position={position} background={background}>
      <div className={styles.centeredPopup({ size })}>
        {onClose && showCloseButton && closeButtonPosition === "cross" && (
          <button onClick={onClose} className={styles.closeCross()}>
            ×
          </button>
        )}
        <h4 className={styles.header()}>{title}</h4>
        <div className={styles.container({ scrollable })}>{children}</div>
        <footer className={styles.footer()}>
          {onClose && showCloseButton && closeButtonPosition === "button" && (
            <div className={styles.buttonContainer()}>
              <SecondaryButton
                text={closeButtonText}
                color="robbinsEggBlue"
                onClick={onClose}
                size="medium"
              />
            </div>
          )}
          {onAccept && (
            <div className={styles.buttonContainer()}>
              <PrimaryButton
                text={acceptButtonText}
                onClick={onAccept}
                size="medium"
                color="robbinsEggBlue"
              />
            </div>
          )}
        </footer>
      </div>
    </Overlay>
  );
}

const styles = withBem("centered-popup", {
  centeredPopup: css({
    position: "relative",
    width: "100%",
    animation: `${animations.popout} 250ms, ${animations.fadeIn} 250ms`,
    maxHeight: "100%",
    backgroundColor: "$white",
    border: "solid 1px $gallery",
    display: "flex",
    gap: "$3",
    flexDirection: "column",
    borderRadius: "$2",
    padding: "$3",
    variants: {
      size: {
        small: {
          maxWidth: "576px"
        },
        medium: {
          maxWidth: "930px"
        }
      }
    }
  }),

  container: css({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    fontSize: 18,
    fontFamily: "$foundersGrotesk",
    color: "$ebonyClay",
    variants: {
      scrollable: {
        true: {
          overflowY: "scroll"
        }
      }
    }
  }),

  header: css({
    color: "$ebonyClay",
    display: "flex",
    fontFamily: "$foundersGrotesk",
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: 1,
    margin: "0",
    "@bp2": {
      fontSize: "28px"
    }
  }),

  footer: css({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    gap: "$3"
  }),

  buttonContainer: css({
    minWidth: 100
  }),

  closeCross: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 40,
    height: 40,
    position: "absolute",
    top: 0,
    right: 0,
    fontWeight: "bold",
    fontSize: 30,
    cursor: "pointer",

    fontFamily: "$foundersGrotesk",
    backgroundColor: "transparent",
    border: 0,
    borderRadius: 0,
    padding: 0,
    color: "$ebonyClay",
    "-webkit-appearance": "none"
  })
});
