import React, { useState, useEffect } from "react";
import { CompoundInput } from "../CompoundInput/CompoundInput";
import { NumberInput } from "../NumberInput";
import { SelectBox } from "../SelectBox";

export function MeasurementInput({
  name,
  onChange = () => undefined,
  size = "medium",
  units,
  unitsWidth = 65,
  ...props
}: MeasurementInput.Props) {
  const [[value, unit], setState] = useState<
    [number | null, string | undefined]
  >(() => parseValue());
  useEffect(() => {
    setState(parseValue());
  }, [props.value]);
  useEffect(() => {
    const changedValue: string | null =
      value !== null && unit ? `${value}${unit}` : null;
    if (changedValue !== props.value) onChange(changedValue, name!);
  }, [value, unit]);

  function onUnitChange(unit: string) {
    setState([value, unit]);
  }

  function onValueChange(value: number | null) {
    setState([value, unit]);
  }

  function parseValue(): [number | null, string | undefined] {
    if (!props.value) return [null, undefined];
    const regex = RegExp(`([0-9]*)(${units.join("|")})`);
    const [, measure, unit] = regex.exec(props.value) ?? [];
    return [measure ? parseInt(measure) : null, unit];
  }

  return (
    <CompoundInput size={size}>
      <NumberInput value={value} onChange={onValueChange} width="auto" />
      <SelectBox
        value={unit}
        onChange={onUnitChange}
        placeholder=""
        fixedWidth={unitsWidth}
      >
        {units.map((unit: string) => (
          <SelectBox.Option key={unit} value={unit}>
            {unit}
          </SelectBox.Option>
        ))}
      </SelectBox>
    </CompoundInput>
  );
}
namespace MeasurementInput {
  export interface Props {
    name?: string;
    onChange?: (value: string | null, name: string) => void;
    size?: "medium" | "small";
    units: string[];
    unitsWidth?: number;
    value: string | null;
  }
}
