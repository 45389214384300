import React from "react";
import {
  CurrencyISO__CurrencySymbol,
  UTCDate__FormattedDate,
  Number__Currency
} from "@obby/lib";
import styles from "./ProductPurchaseDetailsCard.module.less";
import Icon from "../../components/Icon";
import * as Icons from "../../components/Icons";
import { bemModule } from "../../configs/bem";
import { PopoutMenu } from "../../menus/PopoutMenu";
import { Timezones } from "@obby/constants";

const cn = bemModule(styles, "product-purchase-details-card");
export function ProductPurchaseDetailsCard(props) {
  const {
    productPurchase,
    deliveryAddress,
    dispatchDate,
    canResendEmail,
    giftingDetails,
    isMsp,
    isAGift,
    onResendEmail,
    postage,
    price,
    currency,
    purchaseDate,
    quantity,
    userInputs,
    timezone = Timezones.EUROPE_LONDON
  } = props;
  return (
    <div className={cn()}>
      <div className={cn("row", { flex: true })}>
        <div className={cn("class")}>
          <h2 className={cn("class-title")}>{props.product}</h2>

          <div className={cn("details")}>
            <span className={cn("datetime")}>
              <Icon
                className={cn("icon", { general: true, "general-fill": true })}
                icon={Icons.Clock12h20m}
                height={16}
              />
              {UTCDate__FormattedDate(purchaseDate, timezone, "h:mm A")}
            </span>
            <span className={cn("datetime")}>
              <Icon
                className={cn("icon", {
                  general: true,
                  "general-stroke": true
                })}
                icon={Icons.CalendarThin}
                height={18}
              />
              {UTCDate__FormattedDate(
                purchaseDate,
                timezone,
                "dddd D MMMM YYYY"
              )}
            </span>
            <span className={cn("price")}>
              {quantity} {quantity === 1 ? "item" : "items"} /{" "}
              {Number__Currency(price, CurrencyISO__CurrencySymbol(currency))}
            </span>
          </div>
        </div>
        <div className={cn("actions")}>
          <PopoutMenu icon={Icons.MoreOptions}>
            {onResendEmail && (
              <PopoutMenu.Item
                restricted={!canResendEmail}
                onClick={onResendEmail}
              >
                Resend Auto-Emails
              </PopoutMenu.Item>
            )}

            {productPurchase.payout && (
              <PopoutMenu.Item
                onClick={() => {
                  window.location.href = `/payouts/${productPurchase.payout}`;
                }}
              >
                Payout link
              </PopoutMenu.Item>
            )}
          </PopoutMenu>
        </div>
      </div>
      {props.cancelledDate ? (
        <div className={cn("row", { center: true })}>
          <span className={cn("cancelled")}>
            Cancelled on{" "}
            {UTCDate__FormattedDate(
              props.cancelledDate,
              timezone,
              "D MMMM YYYY"
            )}
          </span>
        </div>
      ) : (
        <>
          <div className={cn("row", { flex: true, wrap: true })}>
            <div className={cn("customer-name")}>
              {deliveryAddress?.firstName} {deliveryAddress?.lastName}
            </div>
            {isMsp ? (
              <div>
                <div className={cn("customer-email")}>{props.buyerEmail}</div>
                <div className={cn("customer-phone")}>{props.buyerPhone}</div>
              </div>
            ) : null}
            <div className={cn("address")}>
              {[
                deliveryAddress.addressLine1,
                deliveryAddress.addressLine2,
                deliveryAddress.city,
                deliveryAddress.region,
                deliveryAddress.postCode
              ]
                .filter(address => address)
                .join(", ")}
            </div>
            <span>
              <b>{postage.name}</b>
            </span>
            <span>
              {Number__Currency(
                postage.price,
                CurrencyISO__CurrencySymbol(currency)
              )}
            </span>
          </div>
          {dispatchDate && (
            <div className={cn("row")}>
              Dispatched At:{" "}
              {UTCDate__FormattedDate(dispatchDate, timezone, "D MMMM YYYY")}
            </div>
          )}
          {userInputs.length > 0 && (
            <div className={cn("row")}>
              {userInputs.map(input => (
                <div key={input._id} className={cn("input", null, ["mb-2"])}>
                  <div className={cn("input-label")}>{input.label}</div>
                  <div className={cn("input-value")}>{input.value}</div>
                </div>
              ))}
            </div>
          )}
          {deliveryAddress?.additionalNotes && (
            <div className={cn("row", { flex: true })}>
              <span className={cn("customer-name")}>
                {deliveryAddress.additionalNotes}
              </span>
            </div>
          )}
          {isAGift ? (
            <>
              <div className={cn("row")}>
                <h5 className="mb-2">Gifting Details:</h5>
                <div className={cn("input", null, ["mb-2"])}>
                  <div className={cn("input-label")}>To:</div>
                  <div className={cn("input-value")}>{giftingDetails.to}</div>
                </div>
                <div className={cn("input", null, ["mb-2"])}>
                  <div className={cn("input-label")}>From:</div>
                  <div className={cn("input-value")}>{giftingDetails.from}</div>
                </div>
                <div className={cn("input", null, ["mb-2"])}>
                  <div className={cn("input-label")}>Message:</div>
                  <div className={cn("input-value")}>
                    {giftingDetails.message}
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </>
      )}
    </div>
  );
}
