import React, { useMemo } from "react";

import Icon from "../../components/Icon";
import * as Icons from "../../components/Icons";
import { bemModule } from "../../configs/bem";
import { PopoutMenu } from "../../menus/PopoutMenu";
import {
  Number__Currency,
  UTCDate__FormattedDate,
  CurrencyISO__CurrencySymbol,
  UTCDate__Add
} from "@obby/lib";

import styles from "./CustomerBookingCard.module.less";
import { Timezones } from "@obby/constants";
import { DisplayPrice } from "../../components/DisplayPrice";

const cn = bemModule(styles, "customer-booking-card");
export function CustomerBookingCard(props) {
  const {
    booking, // @note by adding this we can dismiss the rest of the props
    bookingType,
    bundleTotalTickets,
    cancelledDate,
    canCancel,
    canRefund,
    canEditGuest,
    canEmail,
    canReschedule,
    canResendEmail,
    courseTitle,
    customerName,
    dates,
    deliveryAddress,
    isCancelled,
    isRefunded,
    numberOfGuests,
    onCancel,
    onCustomerRemove,
    onCustomerEdit,
    onEditNotes,
    onEmail,
    onRefund,
    onReschedule,
    onResendEmail,
    onSelectBundleDates,
    onUpdateBundleNumberOfTickets,
    notes,
    numberOfBundleRecords,
    price,
    currency = "GBP",
    userInputs = [],
    tickets,
    timezone = Timezones.EUROPE_LONDON,
    onUpdateBundleExpiryDate
  } = props;

  const address = useMemo(
    () =>
      deliveryAddress &&
      [
        deliveryAddress.addressLine1,
        deliveryAddress.addressLine2,
        deliveryAddress.city,
        deliveryAddress.region,
        deliveryAddress.postCode
      ]
        .filter(address => address)
        .join(", "),

    [deliveryAddress]
  );

  let priceToDisplay = booking.accounting.rawEarning;
  if (
    booking.refundedObject?.isRefunded &&
    booking.refundedObject?.isPartialRefund
  ) {
    priceToDisplay -= booking.refundedObject?.refundedCardValue;
  }

  return (
    <div className={cn()}>
      <div className={cn("row", { flex: true })}>
        <div className={cn("class")}>
          <h2 className={cn("class-title")}>
            {courseTitle}
            <div>
              {bookingType === "bundle" && (
                <div className={cn("type-tag")}>
                  Bundle booking: {numberOfBundleRecords} of{" "}
                  {bundleTotalTickets} sessions
                </div>
              )}
              {bookingType === "private" && (
                <div className={cn("type-tag")}>Private</div>
              )}
              {bookingType === "regular" &&
                isCancelled &&
                !booking.refundedObject?.isRefunded && (
                  <div
                    className={cn("type-tag", {
                      color: "sun"
                    })}
                  >
                    {"On Hold"}
                  </div>
                )}
              {booking.refundedObject?.isRefunded && (
                <div
                  className={cn("type-tag", {
                    color: "camelot"
                  })}
                >
                  {booking.refundedObject?.isRefunded &&
                    !booking.refundedObject?.isPartialRefund &&
                    "Refunded"}
                  {booking.refundedObject?.isRefunded &&
                    booking.refundedObject?.isPartialRefund &&
                    "Partially Refunded"}
                </div>
              )}
            </div>
          </h2>
          {dates.map((date, index) => (
            <DateTimeRow
              key={index}
              url={date.url}
              startDate={date.date}
              duration={date.duration}
              timezone={timezone}
            />
          ))}
        </div>
        {(!booking.refundedObject?.isRefunded ||
          booking.refundedObject?.isPartialRefund) &&
          (onEmail ||
            onReschedule ||
            onEditNotes ||
            onRefund ||
            booking.payout) && (
            <div className={cn("actions")}>
              <PopoutMenu icon={Icons.MoreOptions}>
                {onEmail && (
                  <PopoutMenu.Item restricted={!canEmail} onClick={onEmail}>
                    Email
                  </PopoutMenu.Item>
                )}

                {booking.payout && (
                  <PopoutMenu.Item
                    onClick={() => {
                      window.location.href = `/payouts/${booking.payout}`;
                    }}
                  >
                    Payout link
                  </PopoutMenu.Item>
                )}

                {onEditNotes && (
                  <PopoutMenu.Item onClick={onEditNotes}>Notes</PopoutMenu.Item>
                )}
                {onCancel && (
                  <PopoutMenu.Item restricted={!canCancel} onClick={onCancel}>
                    Cancel
                  </PopoutMenu.Item>
                )}
                {onRefund && (
                  <PopoutMenu.Item restricted={!canRefund} onClick={onRefund}>
                    Refund
                  </PopoutMenu.Item>
                )}
                {onReschedule && (
                  <PopoutMenu.Item
                    restricted={!canReschedule}
                    onClick={onReschedule}
                  >
                    Reschedule
                  </PopoutMenu.Item>
                )}
                {onResendEmail && (
                  <PopoutMenu.Item
                    restricted={!canResendEmail}
                    onClick={onResendEmail}
                  >
                    Resend Auto-Emails
                  </PopoutMenu.Item>
                )}
                {onSelectBundleDates && (
                  <PopoutMenu.Item onClick={onSelectBundleDates}>
                    Select Bundle Sessions
                  </PopoutMenu.Item>
                )}
                {onUpdateBundleNumberOfTickets && (
                  <PopoutMenu.Item onClick={onUpdateBundleNumberOfTickets}>
                    Update Number of Sessions
                  </PopoutMenu.Item>
                )}
                {onUpdateBundleExpiryDate && (
                  <PopoutMenu.Item onClick={onUpdateBundleExpiryDate}>
                    Edit Bundle Expiry Date
                  </PopoutMenu.Item>
                )}
              </PopoutMenu>
            </div>
          )}
      </div>
      {isCancelled ? (
        <div className={cn("row", { center: true })}>
          <span className={cn("cancelled")}>
            Cancelled on{" "}
            {UTCDate__FormattedDate(cancelledDate, timezone, "D MMMM YYYY")}
          </span>
        </div>
      ) : (
        <>
          <div className={cn("row", { flex: true })}>
            <span className={cn("customer-name")}>
              {customerName}{" "}
              {numberOfGuests > 0 &&
                `(+${numberOfGuests} ${
                  numberOfGuests > 1 ? "Guests" : "Guest"
                })`}
              {address && (
                <>
                  <br />
                  {address}
                </>
              )}
              {booking.refundedObject?.isRefunded &&
                booking.refundedObject?.isPartialRefund && (
                  <>
                    <br />
                    {`Partially Refunded - ${Number__Currency(
                      booking.refundedObject?.refundedCardValue,
                      CurrencyISO__CurrencySymbol(currency)
                    )}`}
                  </>
                )}
            </span>
            <span>
              {Number__Currency(
                priceToDisplay,
                CurrencyISO__CurrencySymbol(currency)
              )}
            </span>
          </div>
          {tickets.length > 0 && (
            <div>
              <div className={cn("breakdown")}>Breakdown</div>
              {tickets.map((ticket, ticketIndex) => (
                <div
                  key={ticket._id}
                  className={cn("row", { flex: true, wrap: true })}
                >
                  <span className={cn("customer-name")}>
                    <div>{`${ticket.ticket.name} for ${ticket.userInfo.firstname} ${ticket.userInfo.surname}`}</div>
                  </span>
                  <span>
                    {Number__Currency(
                      ticket.ticket.price,
                      CurrencyISO__CurrencySymbol(currency)
                    )}
                  </span>
                  <span className={cn("customer-actions")}>
                    <span
                      className={cn("customer-action", {
                        restricted: !canEditGuest
                      })}
                      onClick={() => onCustomerEdit(ticket._id)}
                    >
                      <Icon
                        className={cn("customer-action-icon")}
                        icon={canEditGuest ? Icons.Pencil : Icons.Lock}
                        height={16}
                      />
                      Edit {ticketIndex === 0 ? "buyer" : "guest"} details
                    </span>
                    {ticketIndex > 0 && (
                      <span
                        className={cn("customer-action", null, ["ml-2"])}
                        onClick={() => onCustomerRemove(ticket._id)}
                      >
                        <Icon
                          className={cn("customer-action-icon")}
                          icon={Icons.Bin}
                          height={20}
                        />
                        Remove
                      </span>
                    )}
                  </span>
                </div>
              ))}
            </div>
          )}

          {userInputs.length > 0 && (
            <div className={cn("row")}>
              {userInputs.map(input => (
                <div key={input._id} className={cn("input", null, ["mb-2"])}>
                  <div className={cn("input-label")}>{input.label}</div>
                  <div className={cn("input-value")}>{input.value}</div>
                </div>
              ))}
            </div>
          )}
          {deliveryAddress?.additionalNotes && (
            <div className={cn("row", { center: true })}>
              <span className={cn("customer-name")}>
                {deliveryAddress.additionalNotes}
              </span>
            </div>
          )}
          {notes && <div className={cn("row")}>{notes}</div>}
        </>
      )}
    </div>
  );
}

function DateTimeRow(props) {
  const { startDate, duration, url, timezone } = props;
  const endDate = UTCDate__Add(startDate, duration, "minutes");

  return (
    <a className={cn("datetime-row")} href={url}>
      <span className={cn("datetime")}>
        <Icon
          className={cn("icon", { general: true, "general-fill": true })}
          icon={Icons.Clock12h20m}
          height={16}
        />
        {UTCDate__FormattedDate(startDate, timezone, "h:mm A")} –{" "}
        {UTCDate__FormattedDate(endDate, timezone, "h:mm A")}
      </span>
      <span className={cn("datetime")}>
        <Icon
          className={cn("icon", { general: true, "general-stroke": true })}
          icon={Icons.CalendarThin}
          height={18}
        />
        {UTCDate__FormattedDate(startDate, timezone, "dddd D MMMM YYYY")}
      </span>
    </a>
  );
}
