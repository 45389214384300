import { useContext } from "react";
import { AppTextContext } from "./AppTextContext";

export function useAppText() {
  const getContent = useContext(AppTextContext);

  function __(
    key: string,
    params: { [key: string]: string | number } = {}
  ): string {
    const paramsArr = Object.entries(params);
    let string = getContent(key);

    if (paramsArr.length > 0) {
      paramsArr.forEach(([identifier, value]) => {
        string = string.replace(new RegExp(`:${identifier}`, "g"), value + "");
      });
    }

    return string;
  }

  function __n(
    singular: string,
    plural: string,
    count: number,
    params: { [key: string]: string | number } = {}
  ): string {
    return __(count === 1 ? singular : plural, { count, ...params });
  }

  return {
    __,
    __n
  };
}
