import { useState, useEffect, useRef } from "react";

export function useCloudinaryUpload(config) {
	const [files, setFiles] = useState([]);
	const numberOfFiles = useRef(0);
	const [progress, setProgress] = useState(0);
	const [isUploading, setIsUploading] = useState(false);
	const [images, setImages] = useState(
		config.images || (config.image ? [config.image] : undefined) || []
	);
	const [error, setError] = useState(undefined);

	useEffect(() => {
		if (files.length === 0) {
			if (isUploading) setIsUploading(false);
			if (progress > 0) setProgress(0);
			return;
		}

		const [file, ...state] = [...files];

		(async function() {
			try {
				const response = await uploadToCloudinary(file, config, onProgress);
				const { secure_url } = response;
				setImages([...images, secure_url]);
			} catch (error) {
				setError(error);
			} finally {
				setFiles(state);
			}
		})();
	}, [files]);

	function onProgress(e) {
		const progress = numberOfFiles.current - files.length + e.loaded / e.total;
		setProgress((progress * 100) / numberOfFiles.current);
	}

	function remove(image) {
		const state = image ? [...images] : [];
		if (image) {
			const index = state.indexOf(image);
			state.splice(index, 1);
		}
		setImages(state);
	}

	function upload(files) {
		if (!Array.isArray(files)) {
			remove();
			files = [files];
		}

		setIsUploading(true);
		numberOfFiles.current = files.length;
		setFiles(files);
	}

	return {
		images,
		image: images[0],
		error,
		progress,
		isUploading,
		remove,
		setImages,
		upload
	};
}

function uploadToCloudinary(file, config, onProgress) {
	const { apiKey, preset, name } = config;
	return new Promise((resolve, reject) => {
		const data = new FormData();
		data.append("file", file);
		data.append("api_key", apiKey);
		data.append("timestamp", `${Date.now() / 1000}`);
		data.append("upload_preset", preset);

		const request = new XMLHttpRequest();
		request.upload.addEventListener("progress", onProgress);
		request.addEventListener("error", e => {
			reject("There was an issue with uploading your image, please try again.");
		});
		request.addEventListener("load", e => {
			const response = JSON.parse(e.target.response);
			if (response.error) reject(response.error);
			else resolve(response);
		});
		request.open(
			"POST",
			`https://api.cloudinary.com/v1_1/${name}/image/upload`,
			true
		);
		request.send(data);
	});
}
