import { css } from "../../sitches.config";

export const styles = {
  rows: css({
    marginTop: "$4",
    // "&:not(:first-child)": {
    // },

    "&:empty, &:first-child": {
      marginTop: 0
    }
  }),

  link: css({
    color: "$brightTeal",
    "&:hover": {
      color: "$brightTeal",
      textDecoration: "none"
    }
  })
};
