import UniversalCookie from "universal-cookie";

let universalCookie = new UniversalCookie();

const cookies = {
  addChangeListener(callback) {
    universalCookie.addChangeListener(callback);
  },

  get(name, options) {
    return universalCookie.get(name, options);
  },

  getAll(options) {
    return universalCookie.getAll(options);
  },

  load(cookies) {
    universalCookie = new UniversalCookie(cookies);
  },

  remove(name, options = {}) {
    universalCookie.remove(name, { path: "/", ...options });
  },

  removeChangeListener(callback) {
    universalCookie.removeChangeListener(callback);
  },

  set(name, value, options) {
    universalCookie.set(name, value, options);
  }
};

export { cookies };
