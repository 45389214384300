import React from "react";
import { bemModule } from "../../configs/bem";
import Icon from "../../components/Icon";
import * as Icons from "../../components/Icons";
import styles from "./QuickStartCard.module.less";

const cn = bemModule(styles, "quick-start-card");
export function QuickStartCard({
  checked = false,
  disabled = false,
  icon,
  label,
  onClick
}) {
  const active = !disabled && !checked;
  return (
    <div className={cn({ active })} onClick={disabled ? undefined : onClick}>
      <div className={cn("wrapper", { active })}>
        <div className={cn("icon-wrapper")}>
          <Icon className={cn("icon", { disabled, active })} icon={icon} />
          {checked && (
            <Icon className={cn("check-icon")} icon={Icons.FilledCircleTick} />
          )}
        </div>
        <div className={cn("label", { disabled }, ["py-3"])}>{label}</div>
      </div>
    </div>
  );
}
