import { UTCDate__Diff } from "./UTCDate__Diff";
import { UTCDate__StartOf } from "./UTCDate__StartOf";
import { Timezones } from "@obby/constants";

export function UTCDate__DiffOf(
  isoDate: string,
  timezone: string = Timezones.EUROPE_LONDON,
  unitOfTime: UnitOfTime,
  of: UnitOfTime,
  precise?: boolean
) {
  const isoDate2 = UTCDate__StartOf(isoDate, timezone, of);
  return UTCDate__Diff(isoDate, isoDate2, unitOfTime, precise);
}

type UnitOfTime =
  | "year"
  | "years"
  | "y"
  | "month"
  | "months"
  | "M"
  | "week"
  | "weeks"
  | "w"
  | "day"
  | "days"
  | "d"
  | "hour"
  | "hours"
  | "h"
  | "minute"
  | "minutes"
  | "m"
  | "second"
  | "seconds"
  | "s"
  | "millisecond"
  | "milliseconds"
  | "ms";
