import React, { useState } from "react";
import ProductCard from "../ProductCard";
import Link from "../Link";
import { RailCarousel2 } from "../RailCarousel2";
import { styles } from "./ResponsiveProductsCards.styles";
import { bem } from "../../configs/bem";
import { PrimaryButton } from "../../buttons/PrimaryButton";

const cn = bem("responsive-products-cards");
export default function ResponsiveProductsCards({
  title,
  cards,
  description,
  numberOfColumns,
  onClick = () => {},
  onSeeMore
}) {
  const [viewMore, setViewMore] = useState(false);
  function onViewMoreClick() {
    if (onSeeMore) {
      onSeeMore();
    } else {
      setViewMore(true);
    }
  }

  return (
    <>
      <div className={`${styles.mobile} ${cn()}`}>
        <ul className={`${styles.mobileProductList}`}>
          {title && (
            <li className={`${styles.mobileProductList}-title`}>{title}</li>
          )}
          {cards
            .filter((_, index) => viewMore || index < 3)
            .map((card, index) => (
              <li className={`${styles.mobileProductList}-item`} key={index}>
                <Link
                  draggable={false}
                  onDragStart={e => e.preventDefault()}
                  href={card.url}
                  className={`${styles.productLink}`}
                >
                  <ProductCard {...card} />
                </Link>
              </li>
            ))}
          {!viewMore && (
            <li className={`${styles.mobileProductList}-item`}>
              <span
                className={`${styles.mobileProductList}-view-more`}
                onClick={onViewMoreClick}
              >
                View more
              </span>
            </li>
          )}
        </ul>
      </div>
      <div className={`${styles.tablet} ${cn("tablet")}`}>
        <RailCarousel2
          title={title}
          description={description}
          numberOfColumns={3}
          hideArrows={cards.length <= numberOfColumns}
        >
          {cards.map((card, index) => (
            <Link
              key={index}
              draggable={false}
              onDragStart={e => e.preventDefault()}
              href={card.url}
              className={`${styles.productLink}`}
            >
              <ProductCard {...card} onClick={card => onClick(title, card)} />
            </Link>
          ))}
        </RailCarousel2>
        {onSeeMore ? (
          <div className={`${styles.desktop({ variant: "seeMore" })}`}>
            <PrimaryButton
              width="auto"
              text={"See all Learning Kits"}
              onClick={onSeeMore}
              size="medium"
              color="ebonyClay"
            />
          </div>
        ) : null}
      </div>
      <div className={`${styles.desktop} ${cn("desktop")}`}>
        <RailCarousel2
          title={title}
          description={description}
          numberOfColumns={numberOfColumns}
          hideArrows={cards.length <= numberOfColumns}
        >
          {cards.map((card, index) => (
            <Link
              key={index}
              draggable={false}
              onDragStart={e => e.preventDefault()}
              href={card.url}
              className={`${styles.productLink}`}
            >
              <ProductCard {...card} onClick={card => onClick(title, card)} />
            </Link>
          ))}
        </RailCarousel2>
        {onSeeMore ? (
          <div className={`${styles.desktop({ variant: "seeMore" })}`}>
            <PrimaryButton
              width="auto"
              text={"See all Learning Kits"}
              onClick={onSeeMore}
              size="medium"
              color="ebonyClay"
            />
          </div>
        ) : null}
      </div>
    </>
  );
}
