import Reviews__AggregateRating from "./Reviews__AggregateRating";
import _ from "lodash";

export default course => {
  if (course.rating) {
    return course.rating;
  }
  if (!_.isEmpty(course.reviews)) {
    return Reviews__AggregateRating(course.reviews);
  }
  return 0;
};
