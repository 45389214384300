import moment from "moment";
import React from "react";
import { CheckoutCard } from "../../cards/CheckoutCard";
import { SideNote } from "../../components/SideNote";
import Label from "../../components/Label";
import { useAppText } from "../../containers";
import { styles } from "./CheckoutPanel.styles";
// @ts-ignore
import { Currencies } from "@obby/constants";
// @ts-ignore
import { GetTicketTypePriceBasedOnSession } from "@obby/lib";

interface Props {
  availability?: number;
  cancellationPolicyUrl?: string;
  credit?: number;
  currency?: string;
  description?: string;
  discountError?: string;
  discountValue?: number;
  discountPercentage?: number;
  isBundleEnabled?: boolean;
  isTrialEnabled?: boolean;
  isDiscountEnabled?: boolean;
  isDiscountInvalid?: boolean;
  isOnline?: boolean;
  isOnSales?: boolean;
  onApplyDiscount?: (code: string) => void;
  onViewBundlesClick?: () => void;
  onViewTrialClick?: () => void;
  personalDetails?: any;
  sidebarOnTop?: boolean;
  thumbnail?: string;
  items?: {
    description?: string;
    count?: number;
    price?: number;
    name: string;
  }[];
  title: string;
  total?: number;
  pricingOptions?: any;
  source?: string;
  selectedSession?: any;
}

export function CheckoutPanel({
  availability,
  cancellationPolicyUrl,
  children,
  credit = 0,
  currency = Currencies.GBP,
  description,
  discountError,
  discountValue,
  discountPercentage,
  isBundleEnabled = false,
  isTrialEnabled = false,
  isDiscountEnabled = false,
  isDiscountInvalid = false,
  isOnline,
  onApplyDiscount,
  onViewBundlesClick,
  onViewTrialClick,
  personalDetails,
  thumbnail,
  items = [],
  title,
  total = undefined,
  isOnSales = false,
  sidebarOnTop = false,
  pricingOptions = [],
  source = "obby",
  selectedSession = false,
}: React.PropsWithChildren<Props>) {
  const { __ } = useAppText();
  return (
    <div className={styles.panel()}>
      <div className={styles.body()}>
        <div className={styles.content()}>
          <div className="container-fluid">
            <div className="row">
              <div
                className={`col-12 col-md-8 ${
                  sidebarOnTop ? "order-1 order-md-0 mt-4 mt-md-0" : ""
                }`}
              >
                {children}
              </div>
              {isBundleEnabled && (
                <div className="col-12 col-md-8 d-md-none">
                  <div className={styles.bundle()}>
                    <SideNote
                      title="Bundle tickets available"
                      note={__(
                        "Book multiple classes at once for a bigger saving!",
                      )}
                      link="View tickets"
                      onLinkClick={onViewBundlesClick}
                    />
                  </div>
                </div>
              )}
              {isTrialEnabled && (
                <div className="col-12 col-md-8 d-md-none">
                  <div className={styles.bundle()}>
                    <SideNote
                      title="Trial tickets available"
                      note="Book a trial session to get a taster of the full term"
                      link="View trial"
                      onLinkClick={onViewTrialClick}
                    />
                  </div>
                </div>
              )}
              <div className="col-12 col-md-4">
                <div className={styles.sidebar()}>
                  <Label
                    label="Purchase Information"
                    condensed
                    className="mb-3 d-md-none"
                  />
                  <CheckoutCard
                    heading={title}
                    subheading={description}
                    thumbnail={thumbnail}
                    availability={availability}
                    isOnline={isOnline}
                    cancellationPolicyUrl={cancellationPolicyUrl}
                    source={source}
                  >
                    {items.map((item, index) => {
                      console.log("item ", item);
                      //
                      let selectedItemPrice = GetTicketTypePriceBasedOnSession(
                        item,
                        selectedSession,
                      );
                      return (
                        <CheckoutCard.Item
                          key={index}
                          title={item.name}
                          description={item.description}
                          price={selectedItemPrice}
                          count={item.count}
                          currency={currency}
                          pricingOptions={pricingOptions}
                        />
                      );
                    })}

                    {credit > 0 && <CheckoutCard.Credit credit={credit} />}
                    {credit === 0 && isDiscountEnabled && (
                      <CheckoutCard.Discount
                        onApply={onApplyDiscount}
                        error={discountError}
                        isInvalid={isDiscountInvalid}
                        value={discountValue}
                        percentage={discountPercentage}
                        currency={currency}
                      />
                    )}
                    {!isDiscountEnabled && !!discountValue && (
                      <CheckoutCard.Discount
                        value={discountValue}
                        percentage={discountPercentage}
                        currency={currency}
                      />
                    )}
                    {isOnSales && <CheckoutCard.SalesNotice />}
                    {items.length > 0 &&
                      total !== undefined &&
                      (total > 0 || isDiscountEnabled) && (
                        <CheckoutCard.Total total={total} currency={currency} />
                      )}
                    {personalDetails && (
                      <CheckoutCard.Item
                        title={`${personalDetails.firstName} ${personalDetails.lastName}`}
                        description={personalDetails.email}
                      />
                    )}
                  </CheckoutCard>
                  {isBundleEnabled && (
                    <div className="pt-4 mt-1 d-none d-md-flex">
                      <SideNote
                        title="Bundle tickets available"
                        note={__(
                          "Book multiple classes at once for a bigger saving!",
                        )}
                        link="View tickets"
                        onLinkClick={onViewBundlesClick}
                      />
                    </div>
                  )}
                  {isTrialEnabled && (
                    <div className="pt-4 mt-1 d-none d-md-flex">
                      <SideNote
                        title="Trial tickets available"
                        note="Book a trial session to get a taster of the full term"
                        link="View trial"
                        onLinkClick={onViewTrialClick}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className={styles.footer()}>
          <div className="container-fluid">
            <div className={styles.footerWrapper()}>
              <div className={styles.paymentIcons()}>
                <img
                  alt="Visa"
                  src="https://res.cloudinary.com/obby/image/upload/v1602168853/Page%20Images/visa.png"
                />
                <img
                  alt="Mastercard"
                  src="https://res.cloudinary.com/obby/image/upload/v1602168853/Page%20Images/mastercard.png"
                />
                <img
                  alt="American Express"
                  src="https://res.cloudinary.com/obby/image/upload/v1602168853/Page%20Images/american.jpg"
                />
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}
