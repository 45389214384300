import { Session__FormattedStartDateEndTime } from "./Session__FormattedStartDateEndTime";

export function Booking__AnytimeAnywhereUrl(booking, session, email, subject) {
  return `mailto:${email}?subject=${subject.replace(
    /&/g,
    "and"
  )} (flexitime) - ${Session__FormattedStartDateEndTime(session)} - ${
    booking.buyerName
  }`;
}
