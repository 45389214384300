import { useEffect, useState } from "react";

export function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  function onChange(e) {
    setMatches(e.matches);
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    matchMedia.addEventListener("change", onChange);
    setMatches(matchMedia.matches);

    return () => matchMedia.removeEventListener("change", onChange);
  }, [query]);

  return matches;
}
/** @deprecated - use named export instead */
export default useMediaQuery;
