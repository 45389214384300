import React from "react";

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const Header = ({ children, className }: Props) => (
  <header>
    <div>Change!</div>
    {children}
  </header>
);
