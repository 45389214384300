import React from "react";
import PropTypes from "prop-types";

import ThemedMarkdownText from "../ThemedMarkdownText";

import styles from "./motivation-points.module.less";

export function MotivationPoints(props) {
  const { points = [], cols = { sm: 6 }, accentColor } = props;

  return (
    <div className={styles["motivation-points"]}>
      <div className="row">
        {points.map((point, index) => (
          <div
            key={index}
            className={`col-12 ${Object.keys(cols)
              .map(bp => `col-${bp}-${cols[bp]}`)
              .join(" ")} pb-4 mb-4`}
          >
            <div className={styles["motivation-points__point"]}>
              <span className={styles["motivation-points__point-title"]}>
                {point.header}
              </span>
              <div
                className={`${styles["motivation-points__point-divider"]} ${
                  accentColor
                    ? styles[
                        `motivation-points__point-divider--color-${accentColor}`
                      ]
                    : ""
                }`}
              />
              <div className={styles["motivation-points__point-description"]}>
                <ThemedMarkdownText
                  text={point.description}
                  classNames={{
                    link: `${
                      styles["motivation-points__point-description-link"]
                    } ${
                      accentColor
                        ? styles[
                            `motivation-points__point-description-link--color-${accentColor}`
                          ]
                        : ``
                    }`,
                    paragraph:
                      styles[`motivation-points__point-description-paragraph`]
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

MotivationPoints.propTypes = {};
