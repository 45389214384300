import { asc } from "./asc";
import { UTCDate__IsSameOrAfter } from "./UTCDate__IsSameOrAfter";

export function TimeBlocks__MergedTimeBlocks(
  timeBlocks: TimeBlock[]
): TimeBlock[] {
  // clone itself to not change the original
  timeBlocks = [...timeBlocks];
  // let's make sure time blocks are sorted
  timeBlocks.sort((timeBlock1, timeBlock2) =>
    asc([timeBlock1.start, timeBlock2.start], [timeBlock1.end, timeBlock2.end])
  );

  const [firstTimeBlock, ...remainingTimeBlocks] = timeBlocks;
  return remainingTimeBlocks.reduce<TimeBlock[]>(
    (timeBlocks, timeBlock) => {
      const lastTimeBlock = timeBlocks[timeBlocks.length - 1];
      if (UTCDate__IsSameOrAfter(lastTimeBlock.end, timeBlock.start)) {
        lastTimeBlock.end = timeBlock.end;
      } else {
        timeBlocks.push({ ...timeBlock });
      }

      return timeBlocks;
    },
    [{ ...firstTimeBlock }]
  );
}

interface TimeBlock {
  start: string;
  end: string;
}
