import { css, withBem } from "../../sitches.config";

export const styles = withBem("text-input", {
  textInput: css({
    "--default-accent": "$colors$inputBorderColor",
    alignItems: "center",
    border: "1px solid $inputBorderColor",
    borderRadius: "$input",
    backgroundColor: "white",
    cursor: "text",
    display: "inline-flex",
    fontFamily: "$foundersGrotesk",
    fontSize: "var(--font-size)",
    position: "relative",
    variants: {
      size: {
        small: {
          padding: "6px 15px 6px",
          height: "$inputSmallHeight",
          gap: "10px",
          "--font-size": "$fontSizes$16"
        },
        medium: {
          padding: "11px 20px",
          height: "$inputMediumHeight",
          gap: "14.5px",
          "--font-size": "$fontSizes$18"
        }
      },
      width: {
        full: {
          width: "100%"
        },
        auto: {
          width: "auto"
        },
        compact: {
          width: "120px"
        }
      },
      readOnly: {
        true: {
          cursor: "default"
        }
      },
      disabled: {
        true: {
          cursor: "default"
        }
      },
      compound: {
        left: {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0
        },
        middle: {
          borderLeft: "none",
          borderRadius: 0
        },
        right: {
          borderLeft: "none",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0
        }
      }
    }
  }),

  icon: css({
    flexShrink: 0,
    color: "var(--accent, $colors$gray_2)",
    "&:focus": {
      color: "var(--accent, $colors$gray_2)"
    },
    variants: {
      iconAlign: {
        left: {
          order: 0
          // left: 0
        },
        right: {
          order: 6
          // right: 0
        }
      },
      size: {
        small: {
          width: "14px",
          height: "14px"
        },
        medium: {
          width: "20px",
          height: "20px"
        }
      }
    }
  }),

  faIcon: css({
    flexShrink: 0,
    color: "var(--accent, $colors$gray_2)",
    "&:focus": {
      color: "var(--accent, $colors$gray_2)"
    },
    variants: {
      iconAlign: {
        left: {
          order: 0
        },
        right: {
          order: 6
        }
      },
      size: {
        small: {
          fontSize: "14px"
        },
        medium: {
          fontSize: "20px"
        }
      }
    }
  }),

  input: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "var(--font-size)",
    fontWeight: 400,
    color: "$ebonyClay",
    border: "none",
    backgroundColor: "transparent",
    flex: "1 1 auto",
    margin: 0,
    padding: 0,
    outline: "none",
    transition: "all 250ms",
    width: "100%",
    order: 2,
    "&::placeholder": {
      color: "$gray_2"
    },

    "&:disabled": {
      color: "$disabled",
      "~ .text-input": {
        "&__suffix, &__prefix, &__icon, &__fa-icon": {
          color: "$disabled"
        }
      }
    },
    variants: {
      textAlign: {
        left: {
          textAlign: "left"
        },
        center: {
          textAlign: "center"
        },
        right: {
          textAlign: "right"
        }
      },
      readOnly: {
        true: {
          cursor: "default"
        }
      }
    }
  }),

  prefix: css({
    flex: "0 0 auto",
    order: 1
  }),
  suffix: css({
    flex: "0 0 auto",
    order: 3
  }),
  characterCount: css({
    flex: "0 0 auto",
    order: 4,
    color: "$disabled"
  }),

  cleaner: css({
    cursor: "pointer",
    flexShrink: 0,
    order: 5,
    variants: {
      size: {
        small: {
          fontSize: "14px"
        },
        medium: {
          fontSize: "20px"
        }
      }
    }
  })
});
