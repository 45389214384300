import { includes } from "lodash";
import CUserTypes from "./CUserTypes";
import isFullArray from "./isFullArray";

const User__CUserType = user => {
  if (!isFullArray(user)) {
    return CUserTypes.GUEST;
  }
  if (includes(user.roles, CUserTypes.ADMIN)) {
    return CUserTypes.ADMIN;
  }
  if (includes(user.roles, CUserTypes.TEACHER)) {
    return CUserTypes.TEACHER;
  }
  return CUserTypes.STUDENT;
};

export default User__CUserType;
