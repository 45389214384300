import { css } from "../../sitches.config";

export const styles = {
  box: css({
    borderRadius: 4,
    fontFamily: "$foundersGrotesk",
    fontSize: "$20",
    lineHeight: "1.4",
    padding: "$3",
    variants: {
      type: {
        info: {
          backgroundColor: "$iceCold"
        },

        danger: {
          backgroundColor: "$azalea"
        }
      }
    }
  })
};
