import React from "react";

import TextSticker from "../TextSticker";
import ImageWithLabel from "../ImageWithLabel";
import withSuperPosition from "../withSuperPosition";
import ShapeWithTextSticker from "../ShapeWithTextSticker";
import Image from "../Image";
import styles from "./image-aligned-banner.module.less";

const ImageAlignedBannerAsideContent = ({
  textSticker,
  imageSticker,
  imageUrl,
  imageWithLabelSticker,
  shapeWithTextSticker
}) => {
  let Content = () => (
    <div className={styles["image-aligned-banner__image-container"]}>
      <Image className={styles["image-aligned-banner__image"]} src={imageUrl} />
    </div>
  );

  if (textSticker) {
    Content = withSuperPosition(
      Content,
      <div className={styles["image-aligned-banner__text-sticker"]}>
        <TextSticker
          title={textSticker.title}
          description={textSticker.description}
          color={textSticker.color}
          background={textSticker.background}
        />
      </div>,
      textSticker.position
    );
  }

  if (imageSticker && imageSticker.url) {
    Content = withSuperPosition(
      Content,
      <img
        src={imageSticker.url}
        alt=""
        className={styles["image-aligned-banner__image-sticker"]}
        loading="lazy"
      />,
      imageSticker.position
    );
  }

  if (imageWithLabelSticker) {
    Content = withSuperPosition(
      Content,
      <ImageWithLabel
        imageUrl={imageWithLabelSticker.imageUrl}
        labelText={imageWithLabelSticker.labelText}
      />,
      imageWithLabelSticker.position
    );
  }

  if (shapeWithTextSticker) {
    Content = withSuperPosition(
      Content,
      <ShapeWithTextSticker
        title={shapeWithTextSticker.title}
        description={shapeWithTextSticker.description}
        shape={shapeWithTextSticker.shape}
        backgroundColor={shapeWithTextSticker.backgroundColor}
        color={shapeWithTextSticker.color}
      />,
      shapeWithTextSticker.position,
      shapeWithTextSticker.transform
    );
  }

  return <Content />;
};

export default ImageAlignedBannerAsideContent;
