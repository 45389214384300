import decode from "jwt-decode";
import cookies from "../config/cookies";

let tokenHelper = {};
function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

tokenHelper.logout = () => {
  cookies.remove("token");
};
tokenHelper.getToken = ctx => {
  let tokenRaw = false;
  if (ctx && ctx.req) {
    //server side
    tokenRaw = ctx.req.headers.cookie.replace(
      /(?:(?:^|.*;\s*)token\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
  } else {
    //client side
    tokenRaw = cookies.get("token") || null;
  }
  if (tokenRaw === null) {
    return false;
  } else {
    return IsJsonString(tokenRaw) ? JSON.parse(tokenRaw) : tokenRaw;
  }
};

tokenHelper.loggedIn = () => {
  const token = tokenHelper.getToken();
  return !!token && !tokenHelper.isTokenExpired(token);
};
tokenHelper.isTokenExpired = token => {
  try {
    const decoded = decode(token.accessToken);
    if (decoded.exp < Date.now() / 1000) {
      return true;
    } else return false;
  } catch (err) {
    console.log("err ", err);
    return false;
  }
};

tokenHelper.setToken = idToken => {
  if (idToken) {
    cookies.set("token", JSON.stringify(idToken), {
      path: "/",
      expires: new Date(idToken.expiresIn)
    });
  }
};

tokenHelper.getUserID = () => {
  const token = tokenHelper.getToken();
  if (token) {
    const decoded = decode(token.accessToken);
    return decoded.userID;
  }
  return false;
};

tokenHelper.getSchoolID = () => {
  const token = tokenHelper.getToken();
  if (token) {
    const decoded = decode(token.accessToken);
    return decoded.schoolID;
  }
  return false;
};

export default tokenHelper;
