import { css } from "../../sitches.config";

export const styles = {
  responsiveProductsCards: css({}),
  mobile: css({
    display: "block",
    "@sm": {
      display: "none"
    }
  }),
  mobileProductList: css({
    padding: "0",
    listStyle: "none",

    "&-item + &-item": {
      marginTop: "14px"
    },

    "&-title": {
      fontFamily: "$foundersGrotesk",
      fontWeight: "500",
      fontSize: "21px",
      lineHeight: "80%",
      marginBottom: "23px",
      "-webkit-font-smoothing": "antialiased"
    },

    "&-view-more": {
      color: "$robbinsEggBlue",
      textDecoration: "none",
      fontFamily: "$foundersGrotesk",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "80%",
      display: "inline-block",
      borderBottom: "1px solid",
      paddingBottom: "3px",
      cursor: "pointer"
    }
  }),

  tablet: css({
    display: "none",

    "@sm": {
      display: "block"
    },
    "@md": {
      display: "none"
    }
  }),

  desktop: css({
    display: "none",

    "@md": {
      display: "block"
    },

    variants: {
      variant: {
        seeMore: {
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px"
        }
      }
    }
  }),

  bigRailCard: css({
    width: "267px",
    height: "320px"
  }),

  productLink: css({
    textDecoration: "none",
    display: "block",
    "&:hover, &:visited, &:active, &:focus": {
      color: "inherit",
      textDecoration: "none"
    }
  })
};
