import React from "react";
import Address from "../Address";
import { styles } from "./LocationDetails.styles";

interface LocationDetailsProps {
  title?: string;
  name?: string;
  area?: string;
  line1: string;
  line2?: string;
  postcode: string;
  city: string;
  shouldDisplayAreaTitle?: boolean;
}

const LocationDetails = ({
  title,
  name = "",
  area = "",
  line1,
  line2,
  postcode,
  city,
  shouldDisplayAreaTitle
}: LocationDetailsProps) => {
  return (
    <div className={`location-details ${styles.locationDetails}`}>
      {shouldDisplayAreaTitle && (
        <>
          <span className={`location-details__title ${styles.title}`}>
            Where we are:
          </span>
          <span className={`location-details__area ${styles.area}`}>
            {area}
          </span>
        </>
      )}
      {title ? (
        <span className={`location-details__title ${styles.title}`}>
          {title}
        </span>
      ) : (
        <span className={`location-details__title ${styles.title}`}>
          Address:
        </span>
      )}

      <div className={`location-details__body ${styles.body}`}>
        <Address
          name={name}
          area={shouldDisplayAreaTitle ? undefined : area}
          line1={line1}
          line2={line2}
          postcode={postcode}
          city={city}
        />
      </div>
    </div>
  );
};

export default LocationDetails;
