import moment from "moment-timezone";
import { Timezones } from "@obby/constants";

export function UTCDate__StartOf(
  isoDate: string | undefined,
  timezone: undefined | string = Timezones.EUROPE_LONDON,
  unitOfTime: UnitOfTime
) {
  return moment(isoDate)
    .tz(timezone)
    .startOf(unitOfTime)
    .toISOString();
}

type UnitOfTime =
  | "year"
  | "years"
  | "y"
  | "month"
  | "months"
  | "M"
  | "week"
  | "weeks"
  | "isoWeek"
  | "w"
  | "day"
  | "days"
  | "d"
  | "hour"
  | "hours"
  | "h"
  | "minute"
  | "minutes"
  | "m"
  | "second"
  | "seconds"
  | "s"
  | "millisecond"
  | "milliseconds"
  | "ms";
