import Booking__Discount from "./Booking__Discount";
import { Booking__DiscountValue } from "./Booking__DiscountValue";

export function Booking__BookingRefund(booking) {
  return {
    userCharged: booking.accounting.userCharged,
    price: booking.price,
    total: booking.total,
    credit: booking.usedCredit || 0,
    card: booking.total, //@note we should use the value of the accounting that the user was charged, when we crentalize the order this is soemthing todo
    ...(booking.discount?.nature === "GIFTCARD"
      ? {
          giftCard: {
            code: booking.discount.code,
            value: Booking__DiscountValue(booking)
          }
        }
      : {}),
    ...(booking.discount?.nature === "DISCOUNT"
      ? Booking__Discount(booking)
      : {})
  };
}
