import { BookingTypes } from "@obby/constants";

export function Bundle__BundleBookings(bundle: any) {
  return bundle.bundleRecords.map((record, index) => {
    return {
      ...bundle,
      _id: record._id,
      session: record.session,
      createdAt: record.assignmentDate,
      updatedAt: record.assignmentDate,
      bookingType: BookingTypes.BUNDLE_BOOKING,
      bundleId: bundle._id,
      bundleIndex: index,
      confirmation: record.confirmation || bundle.confirmation,
      rejectReason: record.rejectReason || bundle.rejectReason,
      notes: record.notes,
      // only the first bundle booking gets the accounting
      ...(index > 0
        ? {
            accounting: {
              userCharged: 0,
              discountAmountUsed: 0,
              rawEarning: 0,
              profitEarning: 0,
              netPay: 0,
              paymentProcessing: 0,
              obbyMargin: 0,
              percentageCommissionUsed: 0,
              vat: 0,
              obbyFee: 0,
              netTicketPrice: 0,
              ticketVat: 0
            },
            // reset virtual properties
            ticketsNetPrice: 0,
            ticketsVat: 0,
            totalGross: 0
          }
        : {})
    };
  });
}
