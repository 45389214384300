import React, { useContext } from "react";

import { MenuNavItemContext, MenuNavContext } from "./MenuNavContext";
import { MenuNavItem } from "./MenuNavItem";
import { MenuNavBarSpace } from "./MenuNavBarSpace";
import { MenuNavUserLinks } from "./MenuNavUserLinks";
import styles from "./menu-nav-mobile.module.less";

export function MenuNavMobile(props) {
  const { children } = props;

  const {
    isSignedIn,
    onSignOut,
    avatarUrl,
    activeItem,
    onItemToggle
  } = useContext(MenuNavContext);

  // Despite being a little verbose We add a MenuNavContext again because we don't want the items on mobile menu
  // to be affected by custom colors. So basically we are resetting the colors to defaultd.
  return (
    <MenuNavContext.Provider
      value={{
        isSignedIn,
        onSignOut,
        avatarUrl,
        textColor: "ebonyClay",
        textColorHover: "emeraldish",
        activeItem,
        onItemToggle
      }}
    >
      <div className={styles["menu-nav-mobile"]}>
        <ul className={styles["menu-nav-mobile__items"]}>
          {React.Children.map(children, (child, index) => {
            const { id = index, children, desktop = false } =
              child?.props || {};
            const isActive = id === activeItem;
            // early return if child is falsy
            if (!child) return <></>;
            // early return if the child is a bar space (it is only applied on desktop)
            if (child.type === MenuNavBarSpace) return <></>;
            // early return if we only want it to be on desktop
            if (desktop) return <></>;
            // early return if some item is active and it is not this one
            if (!isActive && activeItem !== undefined) return <></>;

            return (
              <MenuNavItemContext.Provider
                value={{
                  isActive,
                  onItemToggle: children ? () => onItemToggle(id) : undefined
                }}
              >
                {child}
              </MenuNavItemContext.Provider>
            );
          })}
          {isSignedIn &&
            (activeItem === "my-account" || activeItem === undefined) && (
              <MenuNavItemContext.Provider
                value={{
                  isActive: activeItem === "my-account",
                  onItemToggle: children
                    ? () => onItemToggle("my-account")
                    : undefined
                }}
              >
                <MenuNavItem
                  text={
                    <>
                      My Account{" "}
                      <img
                        className={styles["menu-nav-mobile__avatar"]}
                        src={avatarUrl}
                      />
                    </>
                  }
                >
                  <MenuNavUserLinks onSignOut={onSignOut} />
                </MenuNavItem>
              </MenuNavItemContext.Provider>
            )}
          {isSignedIn && activeItem === undefined && (
            <MenuNavItem text="Logout" onClick={onSignOut} />
          )}
        </ul>
        {activeItem !== undefined && (
          <div className="py-3">
            {React.Children.map(children, (child, index) => {
              const { id = index, children } = child?.props || {};
              return id === activeItem ? children : <></>;
            })}
            {activeItem === "my-account" && (
              <MenuNavUserLinks onSignOut={onSignOut} />
            )}
          </div>
        )}
        {activeItem === undefined && (
          <div
            className={`${styles["menu-nav-mobile__footer"]} text-center mt-4 mx-3`}
          >
            <a
              className={styles["menu-nav-mobile__footer-link"]}
              href="/about-us"
            >
              About us
            </a>
            <a
              className={styles["menu-nav-mobile__footer-link"]}
              href="/contact-us"
            >
              Help
            </a>
            <a className={styles["menu-nav-mobile__footer-link"]} href="/terms">
              Terms
            </a>
            <a className={styles["menu-nav-mobile__footer-link"]} href="/join">
              Start Teaching
            </a>
          </div>
        )}
      </div>
    </MenuNavContext.Provider>
  );
}

MenuNavMobile.propTypes = {};
