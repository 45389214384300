import React from "react";

import { PrimaryButton } from "../../buttons/PrimaryButton";
import Icon from "../Icon";
import { CloudinaryImage } from "../CloudinaryImage";
import { PointyArrow } from "../Icons";

import styles from "./join-header-banner.module.less";

export function JoinHeaderBanner(props) {
  const { title, description, cta, onCtaClick, image, imageSticker } = props;

  return (
    <div className={styles["join-header-banner"]}>
      <div className="row">
        <div className="col-12 col-smd-7">
          <div className={styles["join-header-banner__info"]}>
            <h1 className={styles["join-header-banner__title"]}>{title}</h1>
            <div className={styles["join-header-banner__description"]}>
              {description}
            </div>
            {cta && (
              <PrimaryButton
                className={styles["join-header-banner__cta"]}
                text={cta}
                textMode="uppercase"
                width="auto"
                size="medium"
                color="emeraldish"
                onClick={onCtaClick}
              />
            )}
          </div>
        </div>
        {image && (
          <div className="col-12 col-smd-5">
            <div className={styles["join-header-banner__image-wrapper"]}>
              <CloudinaryImage
                className={styles["join-header-banner__image"]}
                src={image}
                width={670}
                height={670}
              />
              {imageSticker && (
                <>
                  <Icon
                    className={
                      styles["join-header-banner__image-sticker-arrow"]
                    }
                    icon={PointyArrow}
                    // height={80}
                  />
                  <span className={styles["join-header-banner__image-sticker"]}>
                    {imageSticker}
                  </span>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

JoinHeaderBanner.propTypes = {};
