import React from "react";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { Box } from "../../../layout/Box";
import { FaIcon } from "../../../icons/FaIcon";
import { styles } from "./DateTimeBox.styles";

export function DateTimeBox({
  children,
  highlight,
  icon,
  label,
  disabled,
  onClick
}: React.PropsWithChildren<Props>) {
  return (
    <Box
      className={styles.dateTimeBox()}
      borderRadius={2}
      padding={0}
      onClick={children ? undefined : onClick}
      borderColor={highlight && !children ? "emeraldish" : undefined}
      borderWidth={highlight && !children ? 2 : 1}
    >
      <div className={styles.wrapper({ disabled })}>
        <span className={styles.label()}>{label}</span>
        <FaIcon icon={icon} size={16} />
      </div>
      {children && <div className={styles.children()}>{children}</div>}
    </Box>
  );
}

export interface Props {
  disabled?: boolean;
  highlight?: boolean;
  icon: IconDefinition;
  label: string;
  onClick?: () => void;
}
