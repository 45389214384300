import { css, withBem } from "../../sitches.config";

export const styles = withBem("tabs-nav-tab", {
  tabsNavTab: css({
    borderBottom: "solid 1px $silverMalibu",
    variants: {
      selected: {
        true: {
          borderBottom: "none"
        }
      }
    }
  }),
  wrapper: css({
    fontSize: "16px",
    fontWeight: "500",
    color: "$ebonyClay",
    fontFamily: "$foundersGrotesk",
    textTransform: "capitalize",
    padding: "8px 16px",
    borderRadius: "6px 6px 0 0",
    border: "solid 1px $silverMalibu",
    borderBottomWidth: "0",
    cursor: "pointer",
    margin: "0 4px",
    variants: {
      selected: {
        true: {
          padding: "10px 20px",
          margin: "0"
        }
      }
    }
  })
});
