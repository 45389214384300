import React, { MouseEventHandler } from "react";

import { styles } from "./Icon.styles";

const Icon = React.forwardRef<SVGSVGElement, Props>(function(
  { width, height, icon, className, outline, color, onClick }: Props,
  ref
) {
  return (
    <svg
      ref={ref}
      className={styles.icon({
        outline,
        strokeOutline: icon.strokeOutline,
        pathOutline: icon.pathOutline,
        clickable: onClick !== undefined,
        className,
        css: {
          ...(color ? { color: `$${color}` } : {})
        }
      })}
      width={width}
      height={height}
      viewBox={icon.viewBox}
      onClick={onClick}
    >
      {icon.body()}
    </svg>
  );
});
export default Icon;

interface Props {
  className?: string;
  color?: string;
  height?: number;
  icon: any;
  onClick?: MouseEventHandler<SVGSVGElement>;
  outline?: boolean;
  width?: number;
}
