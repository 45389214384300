import { BookingTypes } from "@obby/constants";
import { GetTicketTypePriceBasedOnSession } from "@obby/lib";

export function Booking__TotalGross(booking, sessionDB) {
  if (
    [BookingTypes.PRIVATE, BookingTypes.EXTERNAL].includes(booking.bookingType)
  ) {
    return booking.total;
  }
  let total = 0;
  if (booking.tickets.length === 0) return 0;
  let tickets = [];
  if (booking.tickets[0].userInfo !== undefined) {
    for (let ticketDB of booking.tickets) {
      tickets.push(ticketDB.ticket);
    }
    total = tickets.reduce((total, ticket) => {
      let ticketPrice = GetTicketTypePriceBasedOnSession(ticket, sessionDB);
      return total + 1 * ticketPrice;
    }, 0);
  } else {
    tickets = booking.tickets;
    total = tickets.reduce((total, ticket) => {
      let ticketPrice = GetTicketTypePriceBasedOnSession(ticket, sessionDB);
      return total + ticket.count * ticketPrice;
    }, 0);
  }

  return total;
}
