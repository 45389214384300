import React, { useState } from "react";
import { TextInput } from "../../components/TextInput";
import { CalendarThin } from "../../components/Icons";
import { DayPicker } from "../DayPicker/DayPicker";
import { Overlay } from "../../components/Overlay";
// @ts-ignore
import { UTCDate__FormattedDate } from "@obby/lib";
import { styles } from "./DayPickerInput.styles";
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons/faCalendarDays";

export function DayPickerInput({
  displayFormat = "dddd, D MMMM",
  value,
  onChange,
  placeholder,
  timezone,
  ...props
}: Props) {
  const [open, setOpen] = useState(false);

  function onDayPickerChange(date: string) {
    setOpen(false);
    onChange && onChange(date);
  }
  function onTextInputFocus() {
    setOpen(true);
  }

  function onOverlayClick() {
    setOpen(false);
  }

  return (
    <div>
      <TextInput
        faIcon={faCalendarDays}
        iconAlign="right"
        onFocus={onTextInputFocus}
        placeholder={placeholder}
        value={
          value
            ? UTCDate__FormattedDate(value, timezone, displayFormat)
            : undefined
        }
        readOnly
        accent={false}
      />
      {open && (
        <Overlay onClick={onOverlayClick}>
          <div className={styles.popupWrapper()}>
            <DayPicker
              value={value}
              onChange={onDayPickerChange}
              timezone={timezone}
              {...props}
            />
          </div>
        </Overlay>
      )}
    </div>
  );
}

interface Props extends DayPicker.Props {
  placeholder?: string;
  displayFormat?: string | undefined;
}
