import { css, withBem } from "../../sitches.config";

export const styles = withBem("slider-input", {
  sliderInput: css({
    width: "100%",
    display: "inline-flex",
    alignItems: "center",
    border: "1px solid $mercury",
    borderRadius: "5px",
    backgroundColor: "white",
    "> div": { backgroundColor: "$mercury", width: "100%", flex: "1 1" },
    "> div > div:first-child": { backgroundColor: "$callToAction" },
    variants: {
      size: {
        small: {
          padding: "6px 15px 6px",
          height: "$inputSmallHeight",
          gap: "$2"
        },
        medium: {
          padding: "11px 20px",
          height: "$inputMediumHeight",
          gap: "$3"
        }
      },
      width: {
        full: {
          width: "100%"
        },
        auto: {
          width: "auto"
        }
      },
      compound: {
        left: {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0
        },
        middle: {
          borderLeft: "none",
          borderRadius: 0
        },
        right: {
          borderLeft: "none",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0
        }
      }
    }
  }),
  value: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$18",
    flex: "0 0 auto",
    textAlign: "right"
  })
});
