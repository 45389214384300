import React from "react";
import { ProductSelectionPopupContainer } from "./ProductSelectionPopup.container";
import { ProductSelectionPopupView } from "./ProductSelectionPopup.view";
import { StripePaymentProvider } from "../StripePayment";

export function ProductSelectionPopup({
  discount,
  isAGift,
  logoUrl,
  onClose,
  isNewsletterSignupEnabled = true,
  onConfirmFreePurchase,
  onGetPaymentIntent,
  onLogin,
  onValidateDiscount,
  postages,
  product,
  school,
  stripePublicKey,
  user
}) {
  return (
    <StripePaymentProvider
      publicKey={stripePublicKey}
      onGetPaymentIntent={onGetPaymentIntent}
    >
      <ProductSelectionPopupContainer.Provider
        initialState={{
          discount,
          isAGift,
          isNewsletterSignupEnabled,
          logoUrl,
          onClose,
          onConfirmFreePurchase,
          onLogin,
          onValidateDiscount,
          product,
          postages,
          school,
          user
        }}
      >
        <ProductSelectionPopupView school={school} onClose={onClose} />
      </ProductSelectionPopupContainer.Provider>
    </StripePaymentProvider>
  );
}

ProductSelectionPopup.propTypes = {};
