import React from "react";
import { bemModule } from "../../configs/bem";
import ClassCard from "../ClassCard";
import { ImageUrl__ResizedImageUrl } from "@obby/lib";

import styles from "./join-live-online-section.module.less";

const cn = bemModule(styles, "join-live-online-section");
export function JoinLiveOnlineSection({ course, courseUrl, title }) {
  return (
    <div className={cn()}>
      <div className={cn("wrapper")}>
        <div className="row">
          <div className="col-12 col-md-7">
            <h4 className={cn("title", null, ["my-3"])}>{title}</h4>
            <p className={cn("description", null, ["my-3"])}>
              If you would prefer to buy this product as part of a live class,
              so that you can learn in a more interactive way, simply click on
              the class to read more.
            </p>
          </div>
          <div className="col-12 col-md-5">
            <div key={course._id} className="col-12">
              <ClassCard
                title={course.title}
                thumbnail={ImageUrl__ResizedImageUrl(
                  { width: 534, height: 330, crop: "fill" },
                  course.thumbnail
                )}
                url={courseUrl}
                rating={course.summary.rating}
                numberOfReviews={course.summary.numberOfReviews}
                isOnline={course.isOnline}
                requiresSendingPhysicalMaterial={
                  course.requiresSendingPhysicalMaterial
                }
                location={course.summary.location}
                price={course.summary.price}
                currency={course.currency}
                discount={course.discount}
                samePriceAlways={course.summary.fixedPrice}
                addToWishList={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
