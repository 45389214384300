import * as Yup from "yup";

export function GiftCardFormSchema(forCourse = false) {
  return Yup.object({
    ...(forCourse
      ? {
          option: Yup.string()
            .required()
            .label("Ticket"),
          numberOfGuests: Yup.number()
            .min(1)
            .max(10)
            .label("Number of guests")
            .required()
            .default(1)
        }
      : {
          value: Yup.mixed()
            .label("Gift card value")
            .oneOf([5000, 10000, 15000, "custom"])
            .required()
            .default(5000),
          customValue: Yup.number()
            .label("Custom value")
            .min(1)
            .nullable()
            .test("", "Custom value is a required field", function(
              customValue
            ) {
              return this.parent.value !== "custom" || customValue !== null;
            })
            .default(75)
        }),
    deliveryMethod: Yup.string()
      .label("Delivery method")
      .oneOf(["email", "postbox"])
      .default("email"),

    emailDetails: Yup.mixed().when("deliveryMethod", {
      is: "email",
      then: Yup.object({
        from: Yup.string()
          .label("Sender name")
          .required()
          .default(""),
        to: Yup.string()
          .label("Recipient name")
          .required()
          .default(""),
        note: Yup.string()
          .label("Note")
          .required()
          .default(""),
        recipientEmail: Yup.string()
          .label("Recipient's email address")
          .email()
          .required()
          .default("")
      }).required()
    }),

    deliveryDetails: Yup.mixed().when("deliveryMethod", {
      is: "postbox",
      then: Yup.object({
        firstName: Yup.string()
          .label("First name")
          .required()
          .default(""),
        lastName: Yup.string()
          .label("Surname")
          .required()
          .default(""),
        addressLine1: Yup.string()
          .label("Address")
          .required()
          .default(""),
        addressLine2: Yup.string()
          .label("Address Line 2")
          .default(""),
        postCode: Yup.string()
          .label("Postcode")
          .min(4, "Enter a valid ZIP / postal code for United Kingdom")
          .required()
          .default(""),
        city: Yup.string()
          .label("City")
          .required()
          .default("")
      })
        .required()
        .default({})
    })
  });
}
