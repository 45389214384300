import React, { useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import { DraggableListItemContext } from "./DraggableListItemContext";
import { DraggableListItem } from "./DraggableListItem";

export function DraggableList(props) {
  const { droppableId, onOrderChange, children, disabled } = props;

  const [draggingIndex, setDraggingIndex] = useState(-1);
  const [draggedOverIndex, setDraggedOverIndex] = useState(-1);

  function onDragEnd(result) {
    onOrderChange(draggingIndex, draggedOverIndex);
  }

  function onDragStart(dragStart) {
    setDraggingIndex(dragStart.source.index);
    setDraggedOverIndex(dragStart.source.index);
  }

  function onDragUpdate(dragUpdate) {
    console.log(dragUpdate);
    if (dragUpdate.destination) {
      setDraggedOverIndex(dragUpdate.destination.index);
      setDraggingIndex(draggedOverIndex);
      onOrderChange(draggingIndex, draggedOverIndex);
    }
  }

  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      onDragUpdate={onDragUpdate}
    >
      <Droppable droppableId={droppableId}>
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {React.Children.map(children, (child, index) => (
              <DraggableListItemContext.Provider
                value={{
                  index,
                  isDragging: index === draggingIndex,
                  isDraggedOverAbove:
                    index === draggedOverIndex && index < draggingIndex,
                  isDraggedOverBelow:
                    index === draggedOverIndex && index >= draggingIndex,
                  disabled
                }}
              >
                {child}
              </DraggableListItemContext.Provider>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
DraggableList.Item = DraggableListItem;
