import { css, withBem } from "./../sitches.config";

export const styles = withBem("table", {
  tableWrapper: css({
    position: "relative",
    variants: {
      isScrollable: {
        true: {
          overflowX: "auto"
        }
      }
    }
  }),
  table: css({
    "--table-border-radius": "$radii$1",
    "--table-border-color": "$colors$gallery",
    borderSpacing: 0,
    width: "100%",
    color: "$ebonyClay"
  }),
  loader: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    paddingTop: "38px",
    top: "1px",
    right: "1px",
    bottom: "1px",
    left: "1px",
    backgroundColor: "rgba(255,255,255,0.6)"
  })
});
