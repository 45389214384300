import React, { ReactNode } from "react";
import { styles } from "./ResponsiveClassSection.styles";
import { Skeleton } from "../Skeleton";

export default function ResponsiveClassSection({
  type,
  title,
  subtitle,
  children,
  fluid = false,
  skeleton = false
}: Props) {
  return (
    <div
      className={styles.responsiveClassSection({
        fluid
      })}
    >
      <div className={type ? "row--gallery" : "row"}>
        <div className={`col-12 ${!fluid ? "col-md-4" : ""}`}>
          <div className={styles.header()}>
            <div className={styles.title()}>
              {skeleton ? <Skeleton dark /> : title}
            </div>
            {subtitle && (
              <div className={styles.subtitle()}>
                {skeleton ? <Skeleton dark /> : subtitle}
              </div>
            )}
          </div>
        </div>
        <div className={`col-12 ${!fluid ? "col-md-8" : ""}`}>
          <div className="responsive-class-section__content">{children}</div>
        </div>
      </div>
    </div>
  );
}

interface Props {
  title: string;
  subtitle?: ReactNode;
  children: ReactNode;
  fluid?: boolean;
  type?: any;
  skeleton?: boolean;
}
