type Value = string | number | boolean;

export function asc(value1: Value, value2: Value);
export function asc(...values: [Value, Value][]);
export function asc(
  value1: Value | [Value, Value],
  value2?: Value | [Value, Value],
  ...values: [Value, Value][]
) {
  // normalize overloads to be [Value, Value][]
  const [firstTuple, ...remainingTuples] = normalizeValue(
    value1,
    value2,
    ...values
  );

  const order = compare(...firstTuple);

  if (order === 0 && remainingTuples.length > 0) return asc(...remainingTuples);
  return order;
}

function compare(value1: Value, value2: Value) {
  if (value1 === undefined && value2 === undefined) return 0;
  if (value1 === undefined) return 1;
  if (value2 === undefined) return -1;

  return (value1 > value2 ? 1 : 0) - (value1 < value2 ? 1 : 0);
}

function normalizeValue(
  value1: Value | [Value, Value],
  value2?: Value | [Value, Value],
  ...values: [Value, Value][]
): [Value, Value][] {
  return Array.isArray(value1)
    ? [value1, ...(value2 ? [value2 as [Value, Value]] : []), ...values]
    : [[value1, value2 as Value]];
}
