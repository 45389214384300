import moment from "moment-timezone";

export function UTCDate__Diff(
  isoDate1: string,
  isoDate2: string,
  unitOfTime: UnitOfTime,
  precise?: boolean
) {
  return moment(isoDate1).diff(isoDate2, unitOfTime, precise);
}

type UnitOfTime =
  | "year"
  | "years"
  | "y"
  | "month"
  | "months"
  | "M"
  | "week"
  | "weeks"
  | "w"
  | "day"
  | "days"
  | "d"
  | "hour"
  | "hours"
  | "h"
  | "minute"
  | "minutes"
  | "m"
  | "second"
  | "seconds"
  | "s"
  | "millisecond"
  | "milliseconds"
  | "ms";
