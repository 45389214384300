import { css } from "@obby/components";

export const styles = {
  forgottenPasswordForm: css({
    padding: "30px"
  }),
  title: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$lParagraph",
    lineHeight: "1",
    fontWeight: "400",
    color: "$ebonyClay",
    width: "100%",
    marginBottom: "25px",
    textAlign: "center"
  }),
  description: css({
    width: "100%",
    fontFamily: "$foundersGrotesk",
    fontSize: "$mParagraph",
    lineHeight: "1",
    fontWeight: "400",
    color: "$ebonyClay",
    paddingBottom: "15px",
    cursor: "pointer"
  }),
  input: css({
    paddingBottom: "15px"
  }),
  button: css({
    marginBottom: "15px"
  }),
  error: css({
    width: "100%",
    fontFamily: "$foundersGrotesk",
    fontSize: "$mParagraph",
    lineHeight: "1",
    fontWeight: "400",
    color: "$deepBlush"
  })
};
