import React, { useState, useEffect } from "react";
import {
  DayPicker as ReactDayPicker,
  Matcher,
  ActiveModifiers
} from "react-day-picker";
import "react-day-picker/dist/style.css";
// @ts-ignore
import { Timezones } from "@obby/constants";
import {
  Date__UTCDate,
  UTCDate__Add,
  UTCDate__FormattedDate,
  UTCDate__Get,
  UTCDate__IsAfter,
  UTCDate__StartOf
  // @ts-ignore
} from "@obby/lib";
import { styles } from "./DayPicker.styles";

function valueToDate(value: string, timezone: string) {
  return new Date(
    UTCDate__FormattedDate(value, timezone, "YYYY-MM-DD[T]12:00:00.000[Z]")
  );
}

function dateToValue(date: Date, timezone: string) {
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, "0");
  const day = `${date.getDate()}`.padStart(2, "0");
  return Date__UTCDate(`${year}-${month}-${day}`, timezone);
}

export function DayPicker({
  disabledDaysOfWeek,
  name,
  value,
  onChange,
  timezone = Timezones.EUROPE_LONDON,
  disabled = false,
  disabledDay = () => false,
  disabledPastDays = false,
  disabledBefore
}: DayPicker.Props) {
  const [date, setDate] = useState<Date | undefined>(() =>
    value ? valueToDate(value, timezone) : undefined
  );
  const [month, setMonth] = useState<Date | undefined>(() => {
    if (value) return valueToDate(value, timezone);

    let month = UTCDate__StartOf(undefined, timezone, "day");

    if (disabledBefore) {
      const firstAvailableDate = UTCDate__StartOf(
        disabledBefore,
        timezone,
        "day"
      );
      if (UTCDate__IsAfter(firstAvailableDate, month))
        month = firstAvailableDate;
    }

    // walk through the whole week onwards until we find an enabled day
    if (disabledDaysOfWeek) {
      let days = 0;
      let weekDay = UTCDate__Get(month, timezone, "isoWeekday");
      while (disabledDaysOfWeek.includes((weekDay + days) % 7) && days < 7)
        days++;
      month = UTCDate__Add(month, days, "day");
    }

    return valueToDate(month, timezone);
  });

  useEffect(() => {
    const date = value ? valueToDate(value, timezone) : undefined;
    setDate(date);
    if (date) setMonth(date);
  }, [value, timezone]);

  function isDateDisabled(date: Date) {
    return disabledDay(dateToValue(date, timezone));
  }

  function onDayClick(date: Date, modifiers: ActiveModifiers) {
    if (disabled || modifiers.disabled) return;
    setDate(date);
    if (onChange) {
      const value = dateToValue(date, timezone);
      onChange(value, name);
    }
  }

  function onMonthChange(month: Date) {
    setMonth(month);
  }

  const disabledModifiers: Matcher[] = [isDateDisabled];
  if (disabledDaysOfWeek)
    disabledModifiers.push({ dayOfWeek: disabledDaysOfWeek });
  if (disabledBefore)
    disabledModifiers.push({ before: valueToDate(disabledBefore, timezone) });
  if (disabledPastDays)
    disabledModifiers.push({
      before: valueToDate(
        UTCDate__StartOf(undefined, timezone, "day"),
        timezone
      )
    });

  return (
    <div className={styles.component({ disabled })}>
      <div className={styles.body()}>
        <ReactDayPicker
          selected={date}
          modifiers={
            date
              ? {
                  highlighted: date
                }
              : undefined
          }
          onDayClick={onDayClick}
          onMonthChange={onMonthChange}
          disabled={disabledModifiers}
          month={month}
        />
      </div>
    </div>
  );
}

export namespace DayPicker {
  export interface Props {
    name?: string;
    onChange?: (value: string, name?: string) => void;
    timezone?: string | undefined;
    value?: string | undefined;
    disabled?: boolean;
    disabledDay?: (date: string) => boolean;
    disabledDaysOfWeek?: number[];
    disabledBefore?: string;
    disabledPastDays?: boolean;
  }
}
