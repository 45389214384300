import qs from "qs";
import moment from "moment-timezone";
export function updateUrlBasedOnSearchState(
  filters,
  searchState,
  baseURL = false
) {
  let pathname = baseURL || window?.location?.pathname?.split("/")?.[1] || "";
  let query = {};
  for (let filter of filters) {
    if (filter.type === "q") {
      query.q = filter.value;
    }
    if (filter.type === "level") {
      query.level = filter.value;
    }
    if (filter.type === "hidePrivate") {
      query.hidePrivate = filter.value;
    }
    if (filter.type === "format") {
      query.format = filter.value;
    }
    if (filter.type === "keyword") {
      query.keyword = [...(query.keyword || []), filter.value];
    }
    if (filter.type === "location") {
      query.location = [...(query.location || []), filter.value];
    }
    if (filter.type === "date") {
      query.date = filter.value;
    }
  }
  let newUrl = `/${pathname}`;
  // the dashboard build is complaining about the optional chaining that was here before.
  // maybe some misconfiguration at the dashboard level is preventing transpilers to work under shared/lib folder
  if (searchState && searchState.currentPage > 0) {
    //we add one because the pag in algolia its based on index 0 and to show the user we use 1
    query.page = searchState.currentPage + 1;
  }
  if (Object.keys(query).length > 0) {
    let queryStringRaw = qs.stringify(query, {
      arrayFormat: "comma",
      encode: false
    });
    newUrl += `?${queryStringRaw}`;
  }
  history.pushState({}, null, newUrl);
  /* this trigger a scroll to top wich makes weird to use the filters
  router.replace(
    {
      pathname: pathname,
      query: query
    },
    undefined,
    { shallow: true }
  );
  */
}
