import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";

import CardBackground from "../CardBackground";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import Link from "../Link";
import { CloudinaryImage } from "../CloudinaryImage";
import { ExpandableText } from "../ExpandableText";

import { styles } from "./TeacherCard.styles";

export default function TeacherCard(props) {
  const { name, url, imageUrl, description, maxNumberOfWords = 100 } = props;

  return (
    <div className={`teacher-card ${styles.teacherCard}`}>
      <CardBackground>
        <div className="row">
          {imageUrl && (
            <div className="col-12 col-smd-4">
              <div
                className={`teacher-card__image ${styles.image({
                  variant: "landscape"
                })}`}
              >
                <CloudinaryImage src={imageUrl} width={620} height={383} />
              </div>
              <div
                className={`teacher-card__image ${styles.image({
                  variant: "portrait"
                })}`}
              >
                <CloudinaryImage src={imageUrl} width={230} height={346} />
              </div>
            </div>
          )}
          <div className={`col-12 ${imageUrl ? "col-smd-8" : ""}`}>
            <div className={`teacher-card__content`}>
              <div
                className={`teacher-card__title mt-3 mt-smd-0 mb-2 ${styles.title}`}
              >
                <Link
                  href={url}
                  className={`teacher-card__title-link ${styles.titleLink}`}
                  prefetch="false"
                >
                  {name}
                </Link>
              </div>
              <ExpandableText
                maxWords={maxNumberOfWords}
                className={`teacher-card__description`}
              >
                {description}
              </ExpandableText>
            </div>
          </div>
        </div>
      </CardBackground>
    </div>
  );
}

TeacherCard.propTypes = {
  title: PropTypes.string,
  pill: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string
};
