import React from "react";
import PropTypes from "prop-types";
import styles from "./SimpleCTA.module.less";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import Link from "../Link";

const SimpleCTA = ({ title, description, cta, alwaysShowCta }) => {
  return (
    <div
      className={`${styles[`simple-cta`]} ${
        alwaysShowCta ? styles[`simple-cta--always-show-cta`] : ""
      }`}
    >
      <span className={styles["simple-cta__title"]}>{title}</span>
      <span className={styles["simple-cta__description"]}>{description}</span>
      {cta && (
        <Link className={styles["simple-cta__cta"]} href={cta.link}>
          <PrimaryButton
            textMode="uppercase"
            text={cta.text}
            color="robbinsEggBlue"
          />
        </Link>
      )}
    </div>
  );
};

SimpleCTA.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  alwaysShowCta: PropTypes.bool,
  cta: PropTypes.shape({
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
  })
};

export default SimpleCTA;
