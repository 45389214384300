import { css } from "../../sitches.config";

export const styles = {
  item: css({
    display: "flex"
  }),
  iconWrap: css({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    position: "relative",
    paddingRight: "21px"
  }),
  icon: css({
    width: "100%",
    zIndex: "1"
  }),
  line: css({
    width: "3px",
    height: "100%",
    backgroundColor: "$robbinsEggBlue",
    opacity: "0.5",
    display: "flex",
    position: "absolute"
  }),
  body: css({
    paddingBottom: "50px"
  }),
  title: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$16",
    lineHeight: "1.2",
    margin: "0",
    color: "$ebonyClay",
    textTransform: "uppercase",
    marginBottom: "5px",

    "@md": {
      fontSize: "$18"
    }
  }),
  text: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$16",
    lineHeight: "1.25",
    fontWeight: "400",
    color: "$gray",

    "@md": {
      fontSize: "$18"
    }
  })
};
