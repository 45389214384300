import { useState, useEffect } from "react";
import _ from "lodash";

export function useIsOnTop({ offset = 0 }: Args = {}) {
  const [isOnTop, setIsOnTop] = useState(true);
  useEffect(() => {
    addEventListener("scroll", onScroll);
    if (window.scrollY > offset) setIsOnTop(false);
    return () => {
      removeEventListener("scroll", onScroll);
    };
  }, []);

  const onScroll = _.throttle(() => {
    setIsOnTop(window.scrollY <= offset);
  }, 250);

  return isOnTop;
}

interface Args {
  offset?: number;
}
