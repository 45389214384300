import React, { useState, useEffect, useRef, useContext } from "react";
import { styles } from "./AccordionListItem.styles";
import { AccordionListContext } from "./AccordionList.context";
import { FaIcon } from "../../icons/FaIcon";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
export function AccordionListItem({
  title,
  children,
  disabled,
  open: defaultOpen = false
}: React.PropsWithChildren<Props>) {
  const [open, setOpen] = useState(defaultOpen);

  useEffect(() => {
    if (defaultOpen !== open) onItemClick();
  }, [defaultOpen]);

  const [opening, setOpening] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const openTimeoutRef = useRef<number>();

  const { size } = useContext(AccordionListContext);

  useEffect(() => {
    wrapperRef.current!.style.height = open
      ? `${contentRef.current!.offsetHeight}px`
      : "0";
  }, [open, children]);

  function onItemClick() {
    setOpening(true);
    window.clearTimeout(openTimeoutRef.current);
    openTimeoutRef.current = window.setTimeout(() => {
      setOpening(false);
    }, 250);
    setOpen(!open);
  }

  return (
    <div className={styles.accordionListItem()}>
      <div
        className={styles.title({ size, disabled })}
        onClick={disabled ? undefined : onItemClick}
      >
        {title}
        <div className={styles.tick({ open, size })}>
          <FaIcon icon={faChevronDown} />
        </div>
      </div>

      <div
        ref={wrapperRef}
        className={styles.contentWrapper({
          open: open && !opening,
          opening,
          defaultOpen
        })}
      >
        <div ref={contentRef} className={styles.content({ open })}>
          {children}
        </div>
      </div>
    </div>
  );
}

interface Props {
  title: string;
  open?: boolean;
  disabled?: boolean;
}
