import { ProductTypes } from "./ProductTypes";
import { ProductSections } from "./ProductSections";

export const ProductSectionsLabels = {
  [ProductSections[ProductTypes.COLLECTION]]: "Collections",
  [ProductSections[ProductTypes.EQUIPMENT]]: "Equipment",
  [ProductSections[ProductTypes.LEARNING_KIT]]: "Learning Kits",
  [ProductSections[ProductTypes.ONLINE_COURSE]]: "Online Courses",
  [ProductSections[ProductTypes.MATERIALS]]: "Materials"
};
