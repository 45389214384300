export const BookingExternalSources = {
  WEBSITE: "website",
  AIRBNB: "airbnb",
  EVENTBRITE: "eventbrite",
  BANK_TRANSFER: "bank_transfer",
  CASH: "cash",
  VOUCHER: "voucher",
  CRAFT_COURSES: "Craft courses",
  OTHER: "other",
  PHONE: "phone",
  EMAIL: "email",
  IN_PERSON: "in_person"
};
