import React, { useMemo } from "react";
import moment from "moment";
import { CloudinaryImage } from "../CloudinaryImage";
import { bemModule } from "../../configs/bem";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import { SecondaryButton } from "../../buttons/SecondaryButton";
import styles from "./bundle-card.module.less";

const cn = bemModule(styles, "bundle-card");
export function BundleCard(props) {
  const {
    imageUrl,
    title,
    numberOfSessions,
    numberOfSessionsLeft,
    nextAvailableSessionDate,
    expiryDate,
    onBookClick,
    onBookAgainClick,
    onNotifyMeClick,
    onCancelClick,
    cancelled,
    isPast,
    canCancel
  } = props;

  const rows = useMemo(() => {
    return [
      ["Classes Purchased", numberOfSessions],
      ["Classes Left", numberOfSessionsLeft],
      [
        "Next Available Class",
        nextAvailableSessionDate
          ? moment(nextAvailableSessionDate).format("Do MMMM YYYY")
          : "Sold out"
      ],
      ["Expiry Date", moment(expiryDate).format("Do MMMM YYYY")]
    ];
  }, [numberOfSessions, numberOfSessionsLeft]);

  return (
    <div className={cn()}>
      <CloudinaryImage
        className={cn("image", null, ["d-block"])}
        src={imageUrl}
        width={358}
        height={216}
      />

      <div className={cn("info", null, ["p-4"])}>
        <div className={cn("title", null, ["mb-3"])}>{title}</div>
        {rows.map(([label, value], index) => (
          <div key={index} className={cn("info-row", null, ["my-1"])}>
            {label}: <span className={cn("info-value")}>{value}</span>
          </div>
        ))}
        {!isPast && !cancelled && numberOfSessionsLeft > 0 && (
          <PrimaryButton
            className={cn("book-now-button", null, ["mt-3"])}
            width="auto"
            text={nextAvailableSessionDate ? "Book now" : "Notify Me"}
            textMode="uppercase"
            color="emeraldish"
            onClick={nextAvailableSessionDate ? onBookClick : onNotifyMeClick}
          />
        )}
        {isPast && (
          <SecondaryButton
            className={cn("book-now-button", null, ["mt-3"])}
            width="auto"
            text="Book again"
            textMode="uppercase"
            color="emeraldish"
            onClick={onBookAgainClick}
          />
        )}
        {(canCancel || cancelled) && (
          <PrimaryButton
            className={cn("book-now-button", null, ["mt-3"])}
            width="auto"
            text={cancelled ? "Cancelled" : "Cancel"}
            textMode="uppercase"
            color={"azalea"}
            disabled={cancelled}
            onClick={onCancelClick}
          />
        )}
      </div>
    </div>
  );
}
