export const QuestionsInputsTypes = {
  SHORT_TEXT_INPUT: "shortTextInput",
  LONG_TEXT_INPUT: "longTextInput",
  SINGLE_SELECT: "singleSelect",
  FILE_INPUT: "fileInput",
  NUMBER_INPUT: "numberInput",
  BOOLEAN_INPUT: "booleanInput",
  MULTI_SELECT: "multiSelect"
};

export const QuestionInputsTypesLabel = {
  SHORT_TEXT_INPUT: "Short text",
  LONG_TEXT_INPUT: "Long text",
  SINGLE_SELECT: "Single select",
  FILE_INPUT: "File input",
  NUMBER_INPUT: "Number input",
  BOOLEAN_INPUT: "Boolean input",
  MULTI_SELECT: "Multi select"
};

export const BehaviorNextQuestionClick = {
  NEXT_QUESTION: "nextQuestion",
  SPECIFIC_QUESTION: "specificQuestion",
  END_FORM: "endForm"
};

export const BehaviorNextQuestionClickLabel = {
  NEXT_QUESTION: "Next question",
  SPECIFIC_QUESTION: "Specific question",
  END_FORM: "End form"
};
