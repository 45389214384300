import { useEffect, useState, useRef, useMemo } from "react";

import { useDiscount } from "../../hooks/useDiscount";
import { useFormState } from "../../hooks/useFormState";
import { createContainer } from "../../configs/createContainer";
import { BundleCheckoutSchema } from "./BundleCheckout.schema";
import { DiscountNatures } from "@obby/constants";
import { useStripePayment } from "../StripePayment";
import { Session__DisplayDateTimeOffset } from "@obby/lib";
import _ from "lodash";

function useBundleCheckoutContainer(initialState) {
  const {
    course,
    bundle,
    cancellationPolicyUrl,
    courseId,
    courseThumbnail,
    courseTitle,
    discount,
    isNewsletterSignupEnabled,
    isOnline,
    isSendingMaterial,
    logoUrl,
    onClose,
    onConfirmFreeBooking,
    onLoadDates,
    onLogin,
    onStepChange,
    onValidateDiscount,
    locations,
    tutors,
    userInputs,
    ticketType,
    timezone,
    currency
  } = initialState;
  const page = useRef(initialState.sessions === undefined ? 0 : 1);
  //We need to import course
  let sortedQuestions = _.sortBy(course.userQuestionForm.questions, ["order"]);

  const stripePayment = useStripePayment();
  const [isUserFormValid, setIsUserFormValid] = useState(false);

  const isOnSales = discount?.nature === DiscountNatures.SALES;
  const [state, setState] = useState(() => {
    return {
      isConfirmingFreeBooking: false,
      isDiscountEnabled: false,
      isFullyLoaded: false,
      isLastStep: false,
      isLoading: initialState.sessions === undefined,
      sessions: initialState.sessions ?? [],
      user: initialState.user
    };
  });
  const {
    isConfirmingFreeBooking,
    isDiscountEnabled,
    isLastStep,
    isLoading,
    isFullyLoaded,
    sessions,
    user
  } = state;

  const {
    errors,
    isValid,
    setFieldValue,
    setFieldValues,
    setFieldTouched,
    touched,
    values,
    submit
  } = useFormState({
    schema: BundleCheckoutSchema(isSendingMaterial),
    values: {
      inputs: userInputs.map(() => ""),
      userInputsValues: sortedQuestions.map(() => ""),
      personalDetails: {
        guests: [
          {
            firstName: "",
            lastName: "",
            phoneNumber: "",
            email: "",
            guest: false,
            marketingOptIn: false
          }
        ]
      }
    }
  });

  const firstSession = useMemo(
    () =>
      values.sessionsIds.reduce((firstSession, sessionId) => {
        const session = sessions.find(session => session._id === sessionId);
        return firstSession &&
          firstSession.startDateTime <= session.startDateTime
          ? firstSession
          : session;
      }, null),
    [values.sessionsIds, sessions]
  );

  const credit = useMemo(() => {
    if (
      bundle.price < 3000 ||
      !user?.profile?.credit ||
      discount?.nature === DiscountNatures.SALES
    )
      return 0;
    return Math.min(bundle.price, user.profile.credit);
  }, [user]);

  const {
    discountCode,
    applyCode,
    getDiscount,
    getDiscountPercentage,
    isDiscountInvalid
  } = useDiscount(() => bundle.price, onValidateDiscount, discount);

  useEffect(() => {
    if (initialState.sessions === undefined)
      (async () => {
        await onLoadMoreDates();
      })();
  }, []);

  function getLocation(locationId) {
    return locations.find(location => location._id === locationId);
  }

  function getTutor(tutorId) {
    return tutors.find(tutor => tutor._id === tutorId);
  }

  function getTimezone() {
    return isOnline
      ? Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? timezone
      : timezone;
  }

  function getTotal() {
    const discount = credit || getDiscount();
    return bundle.price - discount;
  }

  function isFree() {
    return getTotal() === 0;
  }

  async function onLoadMoreDates() {
    try {
      setState(state => ({
        ...state,
        isLoading: true
      }));
      const dates = await onLoadDates({
        page: ++page.current,
        limit: 15
      });

      setState(state => ({
        ...state,
        sessions: [...sessions, ...dates],
        isFullyLoaded: dates.length < 15,
        isLoading: false
      }));
    } catch (e) {
      setState(state => ({
        ...state,
        isFullyLoaded: true,
        isLoading: false
      }));
    }
  }

  function onBlur(name) {
    setFieldTouched(name);
  }

  async function onApplyDiscount(code) {
    const isValid = await submit("discountCode");
    if (isValid) await applyCode(code, values.personalDetails.guests[0].email);
  }

  async function onCardPaymentEnter() {
    await stripePayment.getPaymentIntent(getBookingData());
  }

  function onChange(value, name) {
    setFieldValue(name, value);
  }

  function onCheckoutStepChange(index) {
    let userDetailsIndex = 2;
    let lastStepIndex = 3;
    setState(state => ({
      ...state,
      isDiscountEnabled: !discount && index === userDetailsIndex,
      isLastStep: index === lastStepIndex
    }));
    onStepChange && onStepChange();
  }

  function onSessionSelect(sessionsIds) {
    setFieldValues({
      sessionsIds
    });
  }

  function onContinue() {
    if (isFree()) {
      setState(state => ({ ...state, isConfirmingFreeBooking: true }));
      onConfirmFreeBooking(getBookingData());
    } else stripePayment.submitPayment();
  }

  function getBookingData() {
    let { personalDetails } = values;

    const { guests, deliveryAddress } = personalDetails;
    const [guest, ...additionalGuests] = guests;
    let userInputsToSend = [];
    if (course.userQuestionForm.isEnabled) {
      //@ts-ignore
      userInputsToSend = sortedQuestions
        .filter((question, index) => {
          return (
            values.userInputsValues[index] &&
            values.userInputsValues[index] != ""
          );
        })
        .map((question, index) => ({
          _id: question._id,
          label: question.title,
          description: question.title,
          value: values.userInputsValues[index]
        }));
    }
    return {
      bookingType: "bundle",
      tickets: [
        {
          ...ticketType,
          count: 1
        }
      ],
      user: {
        _id: user?._id,
        profile: {
          firstName: guest.firstName,
          lastName: guest.lastName,
          phone: guest.phoneNumber
        },
        renderEmail: guest.email
      },
      marketingOptIn: guest.marketingOptIn,
      additionalGuests: additionalGuests,
      bundleId: bundle._id,
      sessionsIds: values.sessionsIds,
      deliveryAddress: isSendingMaterial ? deliveryAddress : null,
      discountCode: credit ? "" : discountCode,
      credit: credit,
      timezone: getTimezone(),
      userInputs: userInputsToSend
    };
  }

  function getFormattedDates() {
    const selectedSessions = values.sessionsIds.map(sessionId =>
      sessions.find(session => session._id === sessionId)
    );

    selectedSessions.sort(
      (session1, session2) =>
        (session1.startDateTime > session2.startDateTime) -
        (session1.startDateTime < session2.startDateTime)
    );
    return selectedSessions.map(session =>
      Session__DisplayDateTimeOffset(session, timezone)
    );
  }

  async function onPersonalDetailsEnter() {
    let user = state.user ? { ...state.user } : undefined;

    if (!user && onLogin)
      try {
        user = await onLogin();
        setState(state => ({
          ...state,
          user
        }));
      } catch (e) {}

    if (user) {
      const { firstName = "", lastName = "", phone = "" } = user.profile;
      const [{ address = "" } = {}] = user.emails || [];
      setFieldValue("personalDetails.guests.0", {
        ...values.personalDetails[0],
        firstName,
        lastName,
        phoneNumber: phone,
        email: address
      });
    }
  }

  return {
    course,
    bundle,
    cancellationPolicyUrl,
    ticketType,
    credit,
    courseId,
    courseThumbnail,
    courseTitle,
    currency,
    discount,
    errors,
    isConfirmingFreeBooking,
    isDiscountEnabled,
    isLastStep,
    isLoading,
    isFullyLoaded,
    isNewsletterSignupEnabled,
    isOnline,
    isSendingMaterial,
    isStripePaymentReady: stripePayment.isReady,
    logoUrl,
    firstSession,
    sessions,
    touched,
    timezone,
    userInputs,
    values,
    isOnSales,
    isUserFormValid,
    helpers: {
      getDiscount,
      getDiscountPercentage,
      getFormattedDates,
      getLocation,
      getTotal,
      getTutor,
      isDiscountInvalid,
      isFree,
      isValid
    },
    actions: {
      onApplyDiscount,
      onBlur,
      onCardPaymentEnter,
      onChange,
      onClose,
      onContinue,
      onLoadMoreDates,
      onPersonalDetailsEnter,
      onSessionSelect,
      onCheckoutStepChange,
      setIsUserFormValid
    }
  };
}

export const BundleCheckoutContainer = createContainer(
  useBundleCheckoutContainer
);
