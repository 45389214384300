import { css, animations } from "../../sitches.config";

const styles = {
  overlayWrap: css({
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 1001,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }),

  overlay: css({
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0.8)"
  }),

  closeButton: css({
    position: "absolute",
    top: 0,
    right: 0,
    padding: 10,
    backgroundColor: "transparent",
    border: "none",
    borderRadius: 0,
    "-webkit-appearance": "none",
    cursor: "pointer",

    "& svg": {
      display: "block"
    }
  }),

  container: css({
    position: "relative",
    backgroundColor: "white",
    borderRadius: 10,
    width: "90vw",
    maxWidth: 500,
    maxHeight: "90vh",
    padding: 30,
    overflow: "auto",
    animation: `${animations.popout} 250ms, ${animations.fadeIn} 250ms`,
    zIndex: 2
  }),

  form: css({
    position: "relative"
  }),

  fields: css({
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoRows: "auto",
    gap: "10px",
    marginBottom: 20,

    "@md": {
      gridTemplateColumns: "1fr 1fr"
    }
  }),

  info: css({
    marginBottom: 15
  }),

  box: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$18",
    lineHeight: 1.1,
    padding: 15,
    border: "1px solid $mercury",
    borderRadius: 4
  }),

  title: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "inherit",
    fontWeight: 600,
    lineHeight: 1,
    marginTop: 0,
    marginBottom: "$2"
  }),

  introTitle: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$20",
    lineHeight: 1,
    fontWeight: 600,
    marginBottom: 8
  }),

  intro: css({
    marginBottom: 15
  }),

  introText: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$18",
    color: "$gray",
    lineHeight: 1.25,

    "&:not(:last-child)": {
      marginBottom: 10
    }
  }),

  buttonContainer: css({
    display: "flex",
    justifyContent: "flex-end"
  }),

  formOverlay: css({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }),

  completeText: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$18",
    textAlign: "center"
  }),

  completeButton: css({
    display: "flex",
    justifyContent: "center"
  }),

  loaderSpinner: css({
    width: 40,
    height: 40,
    border: "6px solid $emeraldish",
    borderTopColor: "transparent",
    borderRadius: "50%",
    animation: `${animations.rotate} 1s ease-in-out infinite`
  })
};

export { styles as WaitingListPopupStyles };
