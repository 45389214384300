import React from "react";
import Icon from "./../../components/Icon";
import { styles } from "./IconTimelineList.styles";

export function IconTimelineList({ list }: Props) {
  return (
    <div className={`icon-timeline-list`}>
      {/* ITEM */}
      {list.map((item, index) => (
        <div key={index} className={styles.item()}>
          <div className={styles.iconWrap()}>
            <div className={styles.icon()}>
              <Icon icon={item.icon} height={34} />
            </div>
            {index < list.length - 1 && <div className={styles.line()} />}
          </div>
          <div className={styles.body()}>
            <div className={styles.title()}>{item.title}</div>
            <div className={styles.text()}>{item.text}</div>
          </div>
        </div>
      ))}
    </div>
  );
}
interface Props {
  list: {
    icon: any;
    text: string;
    title: string;
  }[];
}
