import { css } from "../../sitches.config";

export const styles = {
  card: css({
    borderRadius: "$2",
    overflow: "hidden",
    padding: "$4",
    variants: {
      border: { true: { border: "solid 1px $alto" } },
      shadow: { true: { boxShadow: "$card" } }
    }
  }),

  thumbnailWrapper: css({
    height: "100%",
    variants: {
      // despite being empty we need to declare this variant in order to be used in compoundVariants
      breakpoint: {},
      thumbnailPosition: {
        top: {
          margin: "-$4 -$4 $4 -$4"
        },
        bottom: {
          margin: "$4 -$4 -$4 -$4"
        }
      }
    },
    compoundVariants: [
      {
        breakpoint: "smd",
        thumbnailPosition: "left",
        css: {
          margin: "-$4 -$4 $4 -$4",
          "@smd": {
            margin: "-$4 0 -$4 -$4",
            height: "calc(100% + $space$4 * 2)"
          }
        }
      },
      {
        breakpoint: "smd",
        thumbnailPosition: "right",
        css: {
          margin: "$4 -$4 -$4 -$4",
          "@smd": {
            margin: "-$4 -$4 -$4 0",
            height: "calc(100% + $space$4 * 2)"
          }
        }
      },
      {
        breakpoint: "md",
        thumbnailPosition: "left",
        css: {
          margin: "-$4 -$4 $4 -$4",
          "@md": {
            margin: "-$4 0 -$4 -$4",
            height: "calc(100% + $space$4 * 2)"
          }
        }
      },
      {
        breakpoint: "md",
        thumbnailPosition: "right",
        css: {
          margin: "$4 -$4 -$4 -$4",
          "@md": {
            margin: "-$4 -$4 -$4 0",
            height: "calc(100% + $space$4 * 2)"
          }
        }
      }
    ]
  }),
  thumbnail: css({
    paddingBottom: "56.25%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    variants: {
      breakpoint: {
        smd: {
          "@smd": {
            height: "100%"
          }
        },
        md: {
          "@md": {
            height: "100%"
          }
        }
      }
    }
  })
};
