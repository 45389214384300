import { Timezones } from "@obby/constants";
import { UTCDate__FormattedDate } from "./UTCDate__FormattedDate";
import { UTCDate__Add } from "./UTCDate__Add";

export function Date__DisplayTime(
  date: string,
  duration: number,
  timezone: string = Timezones.EUROPE_LONDON,
  forceTimezone: boolean = false
) {
  const endTime = UTCDate__Add(date, duration, "minutes");
  if (!forceTimezone)
    timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? timezone;
  return (
    UTCDate__FormattedDate(date, timezone, "h:mma") +
    " - " +
    UTCDate__FormattedDate(endTime, timezone, "h:mma")
  );
}
