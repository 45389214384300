import React from "react";
import LoadingIcon from "../LoadingIcon";
import Icon from "../Icon";
//stitches
import { styled } from "../../sitches.config";

const Button = styled("button", {
  // reset
  fontFamily: "$foundersGrotesk",
  fontWeight: "$1",
  lineHeight: "$1",
  borderRadius: "$pill",
  cursor: "pointer",
  justifyContent: "center",
  color: "$white",
  border: "none",
  outline: "none",
  alignItems: "center",
  "&:focus": { outline: "none" },

  variants: {
    size: {
      small: {
        paddingX: "$3",
        fontSize: "$16",
        height: "2.125rem"
      },
      medium: {
        paddingX: "$3",
        fontSize: "$18",
        height: "2.5rem"
      },
      large: {
        paddingX: "$4",
        fontSize: "$18",
        height: "3rem"
      }
    },
    color: {
      callToAction: {
        border: "1px solid $callToAction",
        backgroundColor: "$callToAction",
        color: "$white"
      },
      robbinsEggBlue: {
        backgroundColor: "$robbinsEggBlue",
        color: "$white"
      },
      sun: {
        backgroundColor: "$sun",
        color: "$astra"
      },
      emerald: {
        border: "1px solid $emerald",
        backgroundColor: "$emerald",
        color: "$astra"
      },
      hummingBird: {
        border: "1px solid $hummingBird",
        backgroundColor: "$hummingBird",
        color: "$camelot"
      },
      emeraldish: {
        border: "1px solid $emeraldish",
        backgroundColor: "$emeraldish",
        color: "$white"
      },
      astra: {
        border: "1px solid $astra",
        backgroundColor: "$astra",
        color: "$ebonyClay"
      },
      silverMalibu: {
        border: "1px solid $silverMalibu",
        backgroundColor: "$silverMalibu",
        color: "$white"
      },
      ebonyClay: {
        border: "1px solid $ebonyClay",
        backgroundColor: "$ebonyClay",
        color: "$hummingBird"
      },
      cruise: {
        border: "1px solid $cruise",
        backgroundColor: "$cruise",
        color: "$camelot"
      },
      azalea: {
        border: "1px solid $azalea",
        backgroundColor: "$azalea",
        color: "$ebonyClay"
      },
      camelot: {
        border: "1px solid $camelot",
        backgroundColor: "$camelot",
        color: "$white"
      },
      prussian: {
        border: "1px solid $prussian",
        backgroundColor: "$prussian",
        color: "$white"
      },
      white: {
        border: "1px solid white",
        backgroundColor: "$white",
        color: "$prussian"
      },
      lightGreen: {
        border: "1px solid $lightGreen",
        backgroundColor: "$lightGreen"
      },
      brightTeal: {
        border: "1px solid $brightTeal",
        backgroundColor: "$brightTeal",
        color: "$white"
      },
      gallery: {
        border: "1px solid $gallery",
        backgroundColor: "$gallery",
        color: "$robbinsEggBlue"
      }
    },
    disabledButton: {
      true: {
        color: "$white !important",
        borderColor: "$silver",
        backgroundColor: "$silver !important",
        cursor: "default"
      }
    },
    outlined: {
      true: {
        backgroundColor: "transparent",
        border: "1px solid"
      }
    },
    isUpperCase: {
      true: {
        textTransform: "uppercase"
      }
    },
    width: {
      auto: {
        display: "inline-flex",
        width: "auto"
      },
      max: {
        display: "flex",
        width: "100%"
      }
    }
  },
  compoundVariants: [
    {
      isUpperCase: true,
      css: {
        lineHeight: 1.2,
        fontSize: "$14"
      }
    },
    {
      color: "robbinsEggBlue",
      outlined: true,
      css: {
        borderColor: "$robbinsEggBlue",
        color: "$robbinsEggBlue"
      }
    },
    {
      color: "sun",
      outlined: true,
      css: {
        borderColor: "$sun",
        color: "$sun"
      }
    }
  ],
  defaultVariants: {
    color: "robbinsEggBlue",
    size: "large"
  }
});

const Span = styled("span", {
  marginRight: ".75rem"
});

export function PrimaryButton({
  className = "",
  color = "callToAction",
  colorHex,
  backgroundColorHex,
  borderColorHex = backgroundColorHex,
  disabled = false,
  isLoading,
  onClick,
  onMouseOver,
  size = "large",
  text,
  textMode = "normal",
  uppercase = textMode === "uppercase",
  type = "button",
  width = "max",
  changeColorOnMobile,
  mobileColor,
  css,
  icon
}) {
  return (
    <Button
      target={"_blank"}
      css={css}
      componentname="primary-button"
      className={className}
      type={type}
      onClick={event => {
        if (!disabled && onClick) {
          onClick(event);
        }
      }}
      disabledButton={disabled}
      disabled={disabled}
      style={{
        borderColor: borderColorHex,
        color: colorHex,
        backgroundColor: backgroundColorHex
      }}
      size={size}
      width={width}
      isUpperCase={uppercase}
      onMouseOver={onMouseOver}
      color={
        changeColorOnMobile ? { "@initial": mobileColor, "@sm": color } : color
      }
    >
      {isLoading && (
        <Span componentname="primary-button__loading-icon">
          <LoadingIcon width="auto" size={15} />
        </Span>
      )}
      {icon && (
        <div style={{ marginRight: "12px" }}>
          <Icon icon={icon} height={size === "small" ? 14 : 20} />
        </div>
      )}
      {text}
    </Button>
  );
}
