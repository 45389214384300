import { css } from "../../sitches.config";

export const styles = {
  coursePageTopBar: css({
    display: "flex",
    background: "white",
    ".course-page-top-bar__action + .course-page-top-bar__action": {
      marginLeft: "10px",
      textDecoration: "underline"
    },

    "@md": {
      display: "block"
    }
  }),

  wrapper: css({
    padding: "10px 0",
    width: "100%",
    "@md": {
      height: "68px",
      display: "flex",
      flexDirection: "row",
      justifyItems: "center",
      justifyContent: "space-between"
    }
  }),

  menuList: css({
    display: "flex",
    justifyContent: "space-around",

    "@md": {
      justifyContent: "flex-start"
    }
  }),

  link: css({
    "&:link,&:visited,&:hover,&:active": {
      fontWeight: "500",
      lineHeight: "110%",
      textDecoration: "none",
      letterSpacing: "0.03em",
      fontSize: "15px",
      color: "$robbinsEggBlue",
      textTransform: "capitalize",
      alignSelf: "center",
      fontFamily: "$foundersGrotesk",

      "@media (min-width: 426px)": {
        fontSize: "18px"
      },

      "@md": {
        marginRight: "40px"
      }
    },
    "&.active": {
      color: "black"
    }
  }),

  bookWrapper: css({
    display: "none",
    "@md": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    }
  }),

  actions: css({
    display: "flex",
    width: "100%",
    justifyContent: "space-between",

    ".primary-button": {
      alignSelf: "flex-end"
    }
  }),

  action: css({
    "&.secondary-button": {
      display: "none",

      "@md": {
        display: "initial"
      }
    },
    "&.secondary-button--width-max, &.primary-button--width-max": {
      width: "185px"
    }
  })
};
