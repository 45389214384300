import React from "react";
import { css } from "../../sitches.config";

interface ProgressBarProps {
  progress: number;
  colour?: "callToAction" | "blueGradient";
  backgroundColour?: "white" | "neutral";
  size?: "default" | "small";
  shape?: "rounded" | "square";
  hideBorder?: boolean;
  showPercentage?: boolean;
}

export function ProgressBar({
  progress,
  colour = "callToAction",
  size = "default",
  shape = "rounded",
  hideBorder = false,
  showPercentage = false
}: ProgressBarProps) {
  const displayPercent = Math.round(progress);

  return (
    <div className={styles.wrap()}>
      <div
        className={styles.container({
          size: size !== "default" ? size : undefined,
          shape: shape === "rounded" ? shape : undefined,
          hideBorder
        })}
      >
        <div
          className={styles.bar({ colour })}
          style={{
            transform: `translateX(${(100 - progress) * -1}%)`
          }}
        />
      </div>
      {showPercentage && (
        <div className={styles.percent()}>{displayPercent}%</div>
      )}
    </div>
  );
}

const styles = {
  wrap: css({
    width: "100%",
    display: "flex",
    alignItems: "center"
  }),

  container: css({
    flex: 1,
    width: "100%",
    height: 20,
    backgroundColor: "#fff",
    overflow: "hidden",

    variants: {
      size: {
        small: {
          height: 10
        }
      },

      shape: {
        rounded: {
          borderRadius: 10
        }
      },

      hideBorder: {
        false: {
          border: "1px solid $outlineColour"
        }
      }
    }
  }),

  bar: css({
    width: "100%",
    height: "100%",
    borderRadius: "inherit",
    transition: "100ms linear",

    variants: {
      colour: {
        callToAction: {
          backgroundColor: "$callToAction"
        },

        blueGradient: {
          background:
            "linear-gradient(90deg, rgba(14,234,214,1) 0%, rgba(6,180,231,1) 100%)"
        }
      }
    }
  }),

  percent: css({
    marginLeft: 10,
    fontFamily: "$bodyFont",
    fontSize: "1.0rem",
    color: "$gray"
  })
};
