import React, { useEffect, useState } from "react";

import { styles } from "./TextArea.styles";

export function TextArea({
  disabled = false,
  placeholder,
  max,
  rows,
  name,
  className = "",
  accent,
  onChange = () => {},
  onBlur = () => {},
  required = false,
  size = "medium",
  ...props
}: Props) {
  const [value, setValue] = useState(props.value ?? "");
  // updates the state when the value from props changes
  useEffect(() => {
    setValue(props.value ?? "");
  }, [props.value]);

  function onInputChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const { value } = e.target;
    if (onChange(value, name!) !== false) setValue(value);
  }

  return (
    <div className={styles.sharedTextArea({ className })}>
      <textarea
        className={styles.input({ size })}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onInputChange}
        onBlur={event => onBlur(event.target.name)}
        maxLength={max}
        rows={rows}
        required={required}
        disabled={disabled}
      />
      {/* CHARACTER COUNT */}
      {max && (
        <div className={styles.characterCount()}>
          {Math.max(max - value.length, 0)} characters left
        </div>
      )}
    </div>
  );
}

interface Props {
  disabled?: boolean;
  name?: string;
  accent?: string | false;
  className?: string;
  max?: number;
  onBlur?: (name: string) => void;
  onChange?: (value: any, name: string) => boolean | void;
  placeholder?: string;
  required?: boolean;
  rows?: number;
  size?: "small" | "medium";
  value?: string;
}
