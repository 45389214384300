import React from "react";

import { BulletPointsList } from "../../lists/BulletPointsList";
import { ExpandableText } from "../ExpandableText";

import { styles } from "./AboutClassSection.styles";

export const AboutClassSection = ({
  description,
  notes,
  acquiredSkills,
  requiredMaterial,
  onExpandDescription
}) => {
  return (
    <div className={styles.aboutClassSession()}>
      <ExpandableText
        maxWords={100}
        className={styles.description()}
        onExpand={onExpandDescription}
      >
        {description}
      </ExpandableText>
      {notes && (
        <>
          <div className={styles.descriptionHeader()}>Things to remember</div>
          <ExpandableText maxWords={50} className={styles.description()}>
            {notes}
          </ExpandableText>
        </>
      )}
      <div className="row">
        {acquiredSkills.length > 0 && (
          <div className={styles.list({ className: "col-12 col-lg-6" })}>
            <BulletPointsList
              title="What you will learn"
              color="royal-blue"
              items={acquiredSkills}
            />
          </div>
        )}
        {requiredMaterial.length > 0 && (
          <div className={styles.list({ className: "col-12 col-lg-6" })}>
            <BulletPointsList
              title="What you will need"
              color="lavender"
              items={requiredMaterial}
            />
          </div>
        )}
      </div>
    </div>
  );
};
