import moment from "moment-timezone";
import { Timezones } from "@obby/constants";
import { UTCDate__StartOf } from "./UTCDate__StartOf";
import { UTCDate__Add } from "./UTCDate__Add";

export function UTCDate__StartOfAndAdd(
  isoDate: string,
  timezone: string = Timezones.EUROPE_LONDON,
  unitOfTime: UnitOfTime,
  amount: number,
  addUnitOfTime: UnitOfTime
) {
  let date = UTCDate__StartOf(isoDate, timezone, unitOfTime);
  date = UTCDate__Add(date, amount, addUnitOfTime, timezone);
  return date;
}

type UnitOfTime =
  | "year"
  | "years"
  | "y"
  | "month"
  | "months"
  | "M"
  | "week"
  | "weeks"
  | "w"
  | "day"
  | "days"
  | "d"
  | "hour"
  | "hours"
  | "h"
  | "minute"
  | "minutes"
  | "m"
  | "second"
  | "seconds"
  | "s"
  | "millisecond"
  | "milliseconds"
  | "ms";
