import React from "react";
import styles from "./CustomerReviewCard.module.less";

import Icon from "../../components/Icon";
import * as Icons from "../../components/Icons";
import RatingStars from "../../components/RatingStars";
import { bemModule } from "../../configs";

const cn = bemModule(styles, "customer-review-card");
export function CustomerReviewCard(props) {
  const { title, rating, review } = props;
  return (
    <div className={cn()}>
      <div className={cn("info")}>
        <Icon
          className={cn("review-icon")}
          icon={Icons.StarInCircleFull}
          height={20}
        />
        <h2 className={cn("class")}>{title}</h2>
        <div className={cn("rating")}>
          <RatingStars rating={rating} color="sun" />
        </div>
      </div>
      <p className={cn("review")}>"{review.trim()}"</p>
    </div>
  );
}
