import React from "react";

import { bemModule } from "../../configs/bem";

import HighlightDiscount from "../HighlightDiscount";

import styles from "./SalesBanner.module.less";

const cn = bemModule(styles, "sales-banner");
export function SalesBanner({ size = "medium", discount }) {
  return (
    <div className={cn({ size })}>
      <div>
        {[1, 2, 3].map(key => (
          <div key={key} className={cn("text")}>
            Black Friday get{" "}
            <HighlightDiscount discount={discount} size={size} /> any class
          </div>
        ))}
      </div>
    </div>
  );
}
