import * as Yup from "yup";
export function SignupSchoolDetailsFormSchema(defaults = {}) {
  defaults = {
    firstName: "",
    lastName: "",
    businessName: "",
    phoneNumber: "",
    email: "",
    emailConfirmation: "",
    password: "",
    ...defaults
  };

  return Yup.object({
    firstName: Yup.string()
      .label("First name")
      .required()
      .default(defaults.firstName),
    lastName: Yup.string()
      .label("Last name")
      .required()
      .default(defaults.lastName),
    businessName: Yup.string()
      .label("Business name")
      .required()
      .default(defaults.businessName),
    phoneNumber: Yup.string()
      .label("Phone Number")
      .required()
      .default(defaults.phoneNumber),
    email: Yup.string()
      .label("Email")
      .email()
      .required()
      .default(defaults.email),
    emailConfirmation: Yup.string()
      .label("Email Confirmation")
      .oneOf([Yup.ref("email")], "Emails must match")
      .email()
      .required()
      .default(defaults.email),
    password: Yup.string()
      .label("Password")
      .required()
      .default(defaults.password)
  });
}
