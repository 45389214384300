import { css } from "../../sitches.config";

export const styles = {
  teamMember: css({
    textAlign: "center",
    padding: "$8",
    margin: "0 auto",
    maxWidth: "360px",
    width: "100%",
    height: "100%"
  }),
  image: css({
    borderRadius: "$pill"
  }),
  name: css({
    fontSize: "$18",
    fontWeight: "700",

    "@lg": {
      fontSize: "$26"
    }
  }),
  title: css({
    marginTop: "$16",
    marginBottom: "$8",
    display: "flex",
    justifyContent: "center",
    "@sm": {
      position: "relative"
    }
  }),
  description: css({
    "&-paragraph": {
      fontFamily: "$foundersGrotesk",
      fontWeight: "400",
      fontSize: "$16",

      "@lg": {
        fontSize: "$18"
      }
    }
  }),
  socialLink: css({
    display: "flex",
    marginLeft: 4,
    justifyContent: "center",
    alignItems: "center",
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    backgroundColor: "$robbinsEggBlue",
    color: "white",

    "&:hover": {
      color: "white"
    },

    "@sm": {
      position: "absolute",
      top: "0",
      right: "0",
      marginRight: "$12"
    }
  })
};
