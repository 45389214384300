import moment from "moment";
import { reverse } from "ramda";
import isFullArray from "./isFullArray";

const TalkJsMessages__SingleChatMessages = messages => {
  if (!isFullArray(messages)) {
    return [];
  }
  // we are using a ramda reverse rather than native reverse,
  // because it is not stateful. The native reverse will also reverse
  // the array reference of the messages we have which can cause unforseen problems.
  var oldestToNewestMessages = reverse(messages);
  var leftAlignedChatBubbleSender = oldestToNewestMessages[0].senderId;
  return oldestToNewestMessages.map(message => {
    return {
      id: message.id,
      senderId: message.senderId,
      avatar: "",
      username: "",
      text: message.text,
      direction:
        leftAlignedChatBubbleSender === message.senderId ? "left" : "right",
      postDate: moment(message.createdAt).format("hh:mm DD/M/YY")
    };
  });
};

export default TalkJsMessages__SingleChatMessages;
