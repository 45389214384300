import React, { useRef } from "react";
import { css } from "../../sitches.config";

interface OverlayProps {
  children: any;
  onClick?: (e?: React.MouseEvent<HTMLDivElement>) => void;
  position?: "absolute" | "fixed";
  background?: "dark" | "light";
  scroll?: boolean;
}

export function Overlay({
  children,
  onClick,
  position = "fixed",
  background = "dark",
  scroll = false
}: OverlayProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  function onContainerClick(e: React.MouseEvent<HTMLDivElement>) {
    if (onClick && e.target === containerRef.current) {
      onClick(e);
    }
  }

  return (
    <div className={styles.overlay({ position, background })}>
      <div
        ref={containerRef}
        className={styles.container({ isScrollable: scroll })}
        onClick={onContainerClick}
      >
        {children}
      </div>
    </div>
  );
}

const styles = {
  overlay: css({
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    overflow: "hidden",
    zIndex: "$popup",

    variants: {
      position: {
        absolute: {
          position: "absolute"
        },
        fixed: {
          position: "fixed"
        }
      },

      background: {
        dark: {
          backgroundColor: "rgba(40, 40, 40, 0.7)"
        },
        light: {
          backgroundColor: "rgba(40, 40, 40, 0.3);"
        }
      }
    }
  }),

  container: css({
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    zIndex: 91,

    variants: {
      isScrollable: {
        true: {
          alignItems: "flex-start",
          overflowY: "auto"
        }
      }
    }
  })
};
