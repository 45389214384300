import React from "react";
import moment from "moment";
import { ClassGiftCard } from "../ClassGiftCard";
import { styles } from "./GiftCardCard.style";
import { Location__FullAddressString } from "@obby/lib";

export default function GiftCardCard(props) {
  const {
    to,
    address,
    value,
    expires,
    email,
    message,
    cover,
    isClass = false
  } = props;
  return (
    <div className={styles.giftCardCard()}>
      <div className={styles.header()}>
        {isClass && (
          <ClassGiftCard
            image={cover}
            price={value}
            title="Your pass to learn something new"
            description="Book anything from pottery to meditation www.obby.co.uk"
            logoSrc="https://res.cloudinary.com/obby/image/upload/v1605268925/Page%20Images/logos/logo-red.svg"
          />
        )}
        {!isClass && <ClassGiftCard image={cover} price={value} />}
      </div>
      <div>
        <div className={styles.body()}>
          <div className={styles.row()}>
            {to && (
              <div className={styles.field()}>
                <div className={styles.fieldLabel()}>To</div>
                <div className={styles.fieldValue()}>{to}</div>
              </div>
            )}
            <div className={styles.field()}>
              <div className={styles.fieldLabel()}>Amount</div>
              <div className={styles.fieldValue()}>{value}</div>
            </div>
            <div className={styles.field()}>
              <div className={styles.fieldLabel()}>Expires</div>
              <div className={styles.fieldValue()}>
                {moment(expires).format("DD/MM/YYYY")}
              </div>
            </div>
          </div>
          {address && (
            <div className={styles.row()}>
              <div className={styles.field()}>
                <div className={styles.fieldLabel()}>Address</div>
                <div className={styles.fieldValue()}>
                  {Location__FullAddressString(address)}
                </div>
              </div>
            </div>
          )}
          <div className={styles.row()}>
            <div className={styles.field()}>
              <div className={styles.fieldLabel()}>Email</div>
              <div className={styles.fieldValue()}>{email}</div>
            </div>
          </div>
          {message && (
            <div className={styles.row()}>
              <div className={styles.field()}>
                <div className={styles.fieldLabel()}>Message</div>
                <div className={styles.fieldValue()}>{message}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
