import React, { useState, useEffect } from "react";

import { bemModule } from "../../../configs/bem";
import { RadioInputOption } from "./RadioInputOption";
import { Provider } from "./RadioInputContext";
import styles from "./RadioInput.module.less";

const cn = bemModule(styles, "radio-input");
export function RadioInput(props) {
  const {
    name,
    size = "medium",
    accent = "brightTeal",
    onChange = () => {},
    children,
    disabled
  } = props;

  const [value, setValue] = useState(props.value);
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  function onOptionChange(value) {
    setValue(value);
    onChange(value, name);
  }

  return (
    <ul className={cn()}>
      {React.Children.map(children, (child, index) => (
        <Provider
          key={index}
          value={{
            name,
            size,
            accent,
            onChange: onOptionChange,
            checked: child.props.value === value,
            disabled
          }}
        >
          {child}
        </Provider>
      ))}
    </ul>
  );
}
RadioInput.Option = RadioInputOption;
