export function TimeIntervals__Times(intervals, period) {
  const times = intervals.reduce(
    (times, interval) => times.concat(TimeInterval__Times(interval, period)),
    []
  );
  return times
    .filter((time, index) => times.indexOf(time) === index)
    .sort((time1, time2) => (time1 > time2) - (time1 < time2));
}
// return all the times on the interval separated by the period
function TimeInterval__Times([from, to], period) {
  const times = [];
  for (
    // get the next period point after `from` value (inclusive). That is, giving the time 10:05 on a period of 15 min
    // we should get 10:15
    let time = from + (from % period ? period - (from % period) : 0);
    time <= to;
    time += period
  )
    times.push(time);
  return times;
}
