import React from "react";
import { InferType } from "yup";
import Label from "../../components/Label";
import TextArea from "../../components/TextArea";
import { TextInput } from "../../inputs/TextInput";
import { Row } from "../../layout/Row";
import { Col } from "../../layout/Col";
import { DeliveryAddressFormSchema } from "./DeliveryAddressForm.schema";

export function DeliveryAddressForm(props: DeliveryAddressForm.Props) {
  const { errors = {}, touched = {}, values, onBlur, onChange } = props;

  const {
    firstName,
    lastName,
    addressLine1,
    addressLine2,
    city,
    region,
    postCode,
    contactNumber,
    additionalNotes
  } = values;

  return (
    <div className="delivery-address-form">
      <Row rowGap={3}>
        <Col sm={6}>
          <Label
            label="First name*"
            error={touched.firstName && errors.firstName}
          >
            <TextInput
              name="firstName"
              onChange={onChange}
              onBlur={onBlur}
              value={firstName}
            />
          </Label>
        </Col>
        <Col sm={6}>
          <Label label="Surname*" error={touched.lastName && errors.lastName}>
            <TextInput
              name="lastName"
              onChange={onChange}
              onBlur={onBlur}
              value={lastName}
            />
          </Label>
        </Col>
        <Col>
          <Label
            label="Address line 1*"
            error={touched.addressLine1 && errors.addressLine1}
          >
            <TextInput
              name="addressLine1"
              onChange={onChange}
              onBlur={onBlur}
              value={addressLine1}
            />
          </Label>
        </Col>
        <Col>
          <Label
            label="Address line 2"
            error={touched.addressLine2 && errors.addressLine2}
          >
            <TextInput
              name="addressLine2"
              onChange={onChange}
              onBlur={onBlur}
              value={addressLine2}
            />
          </Label>
        </Col>
        <Col sm={6}>
          <Label label="Town/City*" error={touched.city && errors.city}>
            <TextInput
              name="city"
              onChange={onChange}
              onBlur={onBlur}
              value={city}
            />
          </Label>
        </Col>
        <Col sm={6}>
          <Label
            label="Country/Region*"
            error={touched.region && errors.region}
          >
            <TextInput
              name="region"
              onChange={onChange}
              onBlur={onBlur}
              value={region}
            />
          </Label>
        </Col>
        <Col sm={6}>
          <Label label="Postcode*" error={touched.postCode && errors.postCode}>
            <TextInput
              name="postCode"
              onChange={onChange}
              onBlur={onBlur}
              value={postCode}
            />
          </Label>
        </Col>
        <Col sm={6}>
          <Label
            label="Contact number"
            error={touched.contactNumber && errors.contactNumber}
          >
            <TextInput
              name="contactNumber"
              onChange={onChange}
              onBlur={onBlur}
              value={contactNumber}
            />
          </Label>
        </Col>
        <Col>
          <Label
            label="Additional Notes"
            error={touched.additionalNotes && errors.additionalNotes}
          >
            <TextArea
              name="additionalNotes"
              onChange={onChange}
              onBlur={onBlur}
              value={additionalNotes}
            />
          </Label>
        </Col>
      </Row>
    </div>
  );
}
DeliveryAddressForm.Schema = DeliveryAddressFormSchema;

export namespace DeliveryAddressForm {
  export type Fields = InferType<ReturnType<typeof DeliveryAddressFormSchema>>;

  export interface Props {
    errors?: any;
    onBlur: (name?: string) => void;
    onChange: (value: any, name?: string) => void;
    touched?: any;
    values: Fields;
  }
}
