import React, { useContext } from "react";
import { CheckoutStepsContext } from "./CheckoutStepsContext";
import { CheckoutStepsBreadcrumb } from "./CheckoutStepsBreadcrumb";

export function CheckoutStepsBreadcrumbs() {
  const { steps = [], currentStep, onGoToStep } = useContext(
    CheckoutStepsContext
  );

  // keep track of the steps that can be visited by clicking in the breadcrumb.
  // once we get to a disabled step, the remaining ones can not be visited by click
  let disabled = false;
  return (
    <div className="checkout-steps-breadcrumbs">
      {steps
        .filter(step => !step.hidden)
        .map((step, index) => {
          const active = !disabled;
          disabled = disabled || step.disabled;

          return (
            <CheckoutStepsBreadcrumb
              key={index}
              onClick={() => onGoToStep(step.index)}
              text={step.breadcrumb}
              current={currentStep === step.index}
              active={active}
            />
          );
        })}
    </div>
  );
}
