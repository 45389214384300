import { css } from "../../sitches.config";

export const styles = {
  squareCard: css({
    display: "block",
    maxWidth: "546px",
    color: "$ebonyClay",

    "&:hover, &:visited, &:active, &:focus": {
      color: "$ebonyClay",
      textDecoration: "none"
    },

    ".d-inline + .d-inline": {
      marginLeft: "8px"
    }
  }),

  thumbnail: css({
    width: "100%",
    borderRadius: "10px"
  }),

  button: css({
    marginTop: "8px",
    display: "flex",
    justifyContent: "center",

    button: {
      maxWidth: "350px",
      fontSize: "18px",
      "@sm": {
        fontSize: "11px"
      },
      "@smd": {
        fontSize: "14px"
      },
      "@lg": {
        fontSize: "18px"
      }
    }
  }),

  title: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "22px",
    fontWeight: "bold",
    color: "$ebonyClay",

    "@sm": {
      fontSize: "20px"
    },
    "@md": {
      fontSize: "22px"
    }
  }),

  description: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "18px",
    color: "$ebonyClay",
    lineHeight: "1",

    "@sm": {
      fontSize: "16px"
    },
    "@md": {
      fontSize: "18px"
    }
  })
};
