import { css } from "../../sitches.config";

export const styles = {
  skeleton: css({
    "&.react-loading-skeleton": {
      display: "inline-block"
    },
    variants: {
      stretch: {
        true: { height: "100%" }
      }
    }
  })
};
