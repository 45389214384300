import React from "react";

import { styles } from "./CheckoutStepsHeader.styles";

export function CheckoutStepsHeader({ title, description }: Props) {
  return (
    <header className={styles.root()}>
      {title && <h1 className={styles.title()}>{title}</h1>}
      {description && <div className={styles.description()}>{description}</div>}
    </header>
  );
}

interface Props {
  title: string;
  description: string;
}
