import { css, withBem } from "../../../sitches.config";

export const styles = withBem("booking-option2", {
  root: css({
    backgroundColor: "$white",
    borderRadius: "$1",
    padding: "$3",
    "@sm": {
      padding: "$4"
    },
    variants: {
      compact: {
        true: {
          "@sm": {
            height: "100%"
          }
        }
      }
    }
  }),
  wrapper: css({
    display: "flex",
    alignItems: "center",
    gap: "$2",
    flexWrap: "wrap",
    minHeight: "48px",
    "@sm": {
      gap: "$4",
      minHeight: "auto"
    },
    variants: {
      compact: {
        true: {
          "@sm": {
            flexDirection: "column",
            alignItems: "flex-start",

            height: "100%",
            gap: "$3"
          }
        }
      }
    }
  }),

  textWrapper: css({
    display: "flex",
    flexDirection: "column",
    flex: "1 0 50%",
    "@sm": {
      flexDirection: "row",
      justifyContent: "space-between",
      gap: "$3"
    },
    variants: {
      compact: {
        true: {
          "@sm": {
            flexDirection: "column",
            flex: "1 0 auto",
            gap: "$3"
          }
        }
      }
    }
  }),

  titleWrapper: css({
    display: "flex",
    flexDirection: "column",
    gap: "$1"
  }),

  title: css({
    color: "$fontColor",
    fontFamily: "$foundersGrotesk",
    fontSize: "$22",
    fontWeight: 500,
    letterSpacing: "0.01em",
    lineHeight: "20px"
  }),
  description: css({
    color: "$gray",
    lineHeight: 1,
    letterSpacing: "0.02em",
    fontSize: "$16",
    fontFamily: "$foundersGrotesk"
  }),

  priceWrapper: css({
    flex: "0 0 auto"
  }),

  price: css({
    fontWeight: "500",
    lineHeight: "24px",
    color: "$textColor",
    letterSpacing: "0.5px",
    fontSize: "$18",
    fontFamily: "$foundersGrotesk",
    "@sm": {
      // margin-right: 27px;
      fontSize: "22px"
    },
    variants: {
      withDiscount: {
        true: {
          textDecoration: "line-through",
          display: "inline-block"
        }
      }
    }
  }),

  buttonWrapper: css({
    width: "130px"
    // flex: "0 0 130px"
  })
});
