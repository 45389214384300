import React from "react";

import { GiftCardCheckoutContainer } from "./GiftCardCheckout.container";
import CardBackground from "../../components/CardBackground";
import { CheckoutPanel } from "../../panels/CheckoutPanel";
import { CheckoutSteps } from "../../navs/CheckoutSteps";
import { PersonalDetailsForm } from "../../forms/PersonalDetailsForm";
import { StripePayment } from "../StripePayment";

export function GiftCardCheckoutView() {
  const {
    actions,
    amount,
    cover,
    currency,
    deliveryMethod,
    description,
    errors,
    from,
    helpers,
    isStripePaymentReady,
    message,
    to,
    values,
    touched
  } = GiftCardCheckoutContainer.useContainer();

  return (
    <CheckoutSteps.Provider onContinue={actions.onContinue}>
      <div className="giftcard-checkout-popup-view">
        <CheckoutPanel
          cancellationPolicyUrl="/terms"
          currency={currency}
          isOnSales={false}
          title="Gift Card"
          description={description}
          thumbnail={cover}
          discountValue={helpers.getDiscount()}
          discountPercentage={helpers.getDiscountPercentage()}
          isDiscountEnabled={false}
          sidebarOnTop
          items={[
            ...(deliveryMethod === "email"
              ? [
                  {
                    name: "To:",
                    description: to
                  },
                  {
                    name: "Message:",
                    description: <i>{message}</i>
                  },
                  { name: "From:", description: from }
                ]
              : []),
            ...(deliveryMethod === "postbox"
              ? [
                  {
                    name: "Gift card",
                    price: amount * 100
                  },
                  {
                    name: "Packaging & postage fee",
                    price: 300
                  }
                ]
              : [])
          ]}
          total={helpers.getTotal()}
        >
          <CheckoutSteps>
            <CheckoutSteps.Step
              breadcrumb="Your Details"
              title="Your Details"
              description="Enter the following requested information"
              disabled={!helpers.isValid()}
            >
              <CardBackground>
                <PersonalDetailsForm
                  disableMarketingOptIn
                  disablePhoneNumber
                  errors={errors}
                  onChange={(value, name) => actions.onChange(value, name)}
                  onBlur={name => actions.onBlur(name)}
                  values={values}
                  touched={touched}
                />
              </CardBackground>
            </CheckoutSteps.Step>
            <CheckoutSteps.Step
              breadcrumb="Card payment"
              title="Card payment"
              nextButtonText="Pay now"
              onEnter={actions.onCardPaymentEnter}
              disabled={!isStripePaymentReady}
            >
              <StripePayment />
            </CheckoutSteps.Step>
          </CheckoutSteps>
          <div className="py-3">
            <CheckoutSteps.Navigation total={helpers.getTotal()} />
          </div>
        </CheckoutPanel>
      </div>
    </CheckoutSteps.Provider>
  );
}
