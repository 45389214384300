export function String__DecryptedString(string) {
  // replace the last number by '=' characters as many as that last number
  let decoded = string.replace(/\d$/, match =>
    Array(parseInt(match))
      .fill("=", 0, match)
      .join("")
  );
  // replace all dots back to slashes
  decoded = decoded.replace(".", "/");
  let buffer = new Buffer(decoded, "base64");
  return buffer.toString("ascii");
}
