import React, { useMemo } from "react";
import PropTypes from "prop-types";
// Lib
import { Image__ResizedImage } from "@obby/lib";
// Components
import DeleteButton from "../DeleteButton";

import styles from "./image-preview.module.less";

export function ImagePreview(props) {
  const image = useMemo(
    () =>
      props.image
        ? Image__ResizedImage(
            { width: 258, height: 258, crop: "lfill" },
            { url: props.image }
          ).url
        : undefined,
    [props.image]
  );

  function onDelete(e) {
    e.stopPropagation();
    props.onDelete();
  }

  return (
    <div className={styles["image-preview"]}>
      <img src={image} className={styles["image-preview__image"]} alt="" />
      <div className={styles["image-preview__controls"]}>
        <DeleteButton color="ebonyClay" onClick={onDelete} size={24} />
      </div>
    </div>
  );
}

ImagePreview.propTypes = {};
