import { css, withBem } from "../../sitches.config";

export const styles = withBem("shared-box", {
  sharedBox: css({
    backgroundColor: "$white",
    border: "solid var(--box-border-width) var(--box-border-color)",
    borderRadius: "var(--box-border-radius)",
    padding: "var(--box-padding)",
    variants: {
      fullHeight: {
        true: {
          height: "100%"
        }
      },
      variant: {
        info: {
          backgroundColor: "$hummingBird",
          borderColor: "$hummingBird"
        },

        success: {
          backgroundColor: "$lightGreen",
          borderColor: "$lightGreen"
        },

        danger: {
          backgroundColor: "$azalea",
          borderColor: "$azalea"
        }
      },
      shadow: { true: { boxShadow: "$card" } }
    }
  }),
  wrapper: css({
    height: "100%",
    margin: "var(--box-wrapper-margin)"
  })
});
