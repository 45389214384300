import { createContext } from "react";
import { Stripe, StripeElements } from "@stripe/stripe-js";
export const StripePaymentContext = createContext<ProviderValue>({
  isLoading: true,
  isSubmitting: false,
  publicKey: "",
  getPaymentIntent: async () => {},
  setIsLoading: () => {},
  setPaymentIntent: () => {},
  setStripeElements: () => {},
  submitPayment: () => {}
});

interface ProviderValue {
  clientSecret?: string;
  error?: string;
  getPaymentIntent: (data: any) => Promise<void>;
  isLoading: boolean;
  isSubmitting: boolean;
  publicKey: string;
  setIsLoading: (isLoading: boolean) => void;
  setPaymentIntent: (clientSecret?: string, successUrl?: string) => void;
  setStripeElements: (stripe: Stripe, elements: StripeElements) => void;
  submitPayment: () => void;
}
