import { css } from "../../sitches.config";

export const styles = {
  label: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$xxsmParagraph",
    lineHeight: "1",
    margin: "0",
    color: "$robbinsEggBlue",
    textTransform: "uppercase",
    fontWeight: "600",

    "@smd": {
      fontSize: "$xsmParagraph"
    }
  }),

  title: css({
    fontSize: "50px",
    fontFamily: "$foundersGroteskXCondensed",
    lineHeight: "1",
    margin: "0 0 20px",
    color: "$ebonyClay",

    "@smd": {
      fontFamily: "$foundersGroteskCondensed",
      fontSize: "62px",
      marginBottom: "40px"
    }
  }),

  description: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$smParagraph",
    lineHeight: "1.4",
    margin: "0 0 40px",
    color: "$ebonyClay",

    "@smd": {
      fontSize: "$mParagraph"
    }
  })
};
