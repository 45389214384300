import { createContext } from "react";
import { Breakpoint } from "@obby/components";

export const TableContext = createContext<Context>({
  onOrderChange: () => {},
  onOrderClear: () => {},
  setNumberOfRows: () => {},
  setVisibleColumn: () => {},
  visibleColumns: []
});

interface Context {
  numberOfRows?: number;
  onOrderChange: (orderBy: string, order: "asc" | "desc") => void;
  onOrderClear?: () => void;
  orderBy?: string;
  order?: "asc" | "desc";
  setNumberOfRows: (numberOfRows: number) => void;
  setVisibleColumn: (columnIndex: number, breakpoint?: Breakpoint) => void;
  visibleColumns: number[];
}
