import React from "react";
import { bemModule } from "../../configs/bem";
import { SelectBox, Option } from "../../inputs/SelectBox";
import { CurrencyISO__CurrencySymbol, Number__Currency } from "@obby/lib";
import styles from "./ProductSelectionRow.module.less";
import HighlightPrice from "../HighlightPrice";

const cn = bemModule(styles, "product-selection-row");
export function ProductSelectionRow(props) {
  const {
    _id,
    title,
    currency,
    description,
    discount,
    price,
    options,
    selectable = false,
    selected,
    value,
    onClick,
    onChange,
    soldOut,
    disabled
  } = props;

  return (
    <div
      className={cn(
        { disabled, selectable, selected: selected && selectable },
        ["p-3"]
      )}
      onClick={selectable && !disabled ? () => onClick(_id) : null}
    >
      <div className={cn("wrapper", { selected: selected && selectable })}>
        <div className={cn("title")}>{title}</div>
        {description && (
          <div className={cn("description", null, ["mt-1"])}>{description}</div>
        )}

        <div className={cn("footer")}>
          {/*<div className="col-6">*/}
          <div>
            {discount !== undefined && (
              <HighlightPrice
                price={discount}
                currency={currency}
                size="medium"
              />
            )}
            <span className={cn("price", { discount: !!discount })}>
              {Number__Currency(price, CurrencyISO__CurrencySymbol(currency))}
            </span>
          </div>
          {/*</div>*/}
          {!soldOut && options && (
            <div className={cn("options")}>
              <SelectBox
                name="options"
                placeholder={""}
                value={value}
                onChange={onChange}
                disabled={disabled}
              >
                {options.map(({ value, label }) => (
                  <Option key={value} value={value}>
                    {label}
                  </Option>
                ))}
              </SelectBox>
            </div>
          )}
          {soldOut && <div className={cn("sold-out")}>Sold out</div>}
        </div>
      </div>
    </div>
  );
}
