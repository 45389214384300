import { bemModule } from "../../configs/bem";
import React, { useContext } from "react";
import styles from "./TogglePopoutToggle.module.less";
import { TogglePopoutContext } from "./TogglePopoutContext";

const cn = bemModule("toggle-popout-toggle");
export function TogglePopoutToggle(props) {
  const { children, disabled = false } = props;

  const { onToggle } = useContext(TogglePopoutContext);

  return (
    <div className={cn({ disabled })} onClick={disabled ? undefined : onToggle}>
      {children}
    </div>
  );
}
