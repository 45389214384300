import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";

import Icon from "../../components/Icon";

import { styles } from "./AsyncSelectBox.styles";

export function AsyncSelectBox(props) {
  const {
    name,
    value,
    wait = 300,
    onChange,
    onLoad,
    parseOption = option => ({ value: option, label: option }),
    accent = "brightTeal",
    placeholder,
    size = "medium",
    className = "",
    icon,
    rounded = false,
    noOptionsMessage,
    multiple = false,
    clear = false,
    menuPlacement = "bottom"
  } = props;

  const [defaultOptions, setDefaultOptions] = useState(props.defaultOptions);

  useEffect(() => {
    setDefaultOptions(props.defaultOptions);
  }, [props.defaultOptions]);

  async function onLoadOptions(query) {
    if (query.length < 3) return [];
    const options = await onLoad(query);
    setDefaultOptions(options);
    return options;
  }

  function onSelectChange(option) {
    onChange(option, name);
  }

  return (
    <>
      <div
        className={styles.component({
          size,
          icon: icon !== undefined,
          rounded,
          clear,
          menuPlacement,
          className: `async-select-box ${className}`,
          css: {
            ".react-select": {
              "&__control": {
                "&--is-focused": {
                  borderColor: `$${accent}`,
                  "&:hover": {
                    borderColor: `$${accent}`
                  },
                  "+ .react-select__menu ": {
                    borderColor: `$${accent}`
                  }
                }
              },
              "&__dropdown-indicator": {
                color: `$${accent}`,
                "&:hover": {
                  color: `$${accent}`
                }
              },
              "&__option": {
                "&--is-focused": {
                  "&::before": {
                    backgroundColor: `$${accent}`
                  }
                },
                "&--is-selected": {
                  backgroundColor: `$${accent}`
                }
              }
            }
          }
        })}
      >
        <AsyncSelect
          classNamePrefix="react-select"
          onChange={onChange ? onSelectChange : undefined}
          value={value}
          name={name}
          placeholder={placeholder}
          defaultOptions={defaultOptions}
          getOptionLabel={option => parseOption(option)?.label}
          getOptionValue={option => parseOption(option)?.value}
          loadOptions={onLoad ? debounce(onLoadOptions, wait) : undefined}
          noOptionsMessage={
            noOptionsMessage ? () => noOptionsMessage : undefined
          }
          isMulti={multiple}
          menuPlacement={menuPlacement}
          // menuIsOpen
        />
        {icon && (
          <Icon color={accent} className={styles.icon({ size })} icon={icon} />
        )}
      </div>
    </>
  );
}
