import moment from "moment-timezone";
import { TicketType__IsSessionInTimeConstraint } from "./TicketType__IsSessionInTimeConstraint";
import { Session__SessionTicketType } from "./Session__SessionTicketType";

export function Session__IsTicketTypeAvailable(
  session,
  ticketType,
  numberOfAttendants = 1
) {

  if(ticketType.bookingType === "bundle")
    return session.availability >= numberOfAttendants

  const sessionTicketType = Session__SessionTicketType(session, ticketType._id);
  if (!sessionTicketType) return false;

  const ticketAvailability =
    sessionTicketType.maxLimit - sessionTicketType.currentSold;
  // lets bail because this session has not available spots for this ticket type
  if (ticketAvailability < numberOfAttendants) return false;

  if (!TicketType__IsSessionInTimeConstraint(ticketType, session)) return false;

  return true;
}
