export function CloudinaryUrl__TransformedUrl(url, options = {}) {
  const transformations = [
    ...Object.entries(options).map(
      ([option, value]) => `${option[0]}_${value}`
    ),
    "fl_lossy",
    "q_auto",
    "f_auto"
  ].join(",");

  if (transformations.length === 0) return url;

  return url.replace(
    /(images|image\/upload)\/(?:(?:[a-z]+_[a-z0-9]+,?)+\/)?/,
    (match, workspace) => `${workspace}/${transformations}/`
  );
}
