import React from "react";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { styles } from "./FaIcon.styles";

export function FaIcon({
  className,
  icon,
  pristine = false,
  size,
  height = size,
  width = size,
  color,
  ...props
}: Props) {
  const [viewBoxWidth, viewBoxHeight, , , path] = icon.icon;
  const paths: string[] = Array.isArray(path) ? path : [path];

  return (
    <svg
      width={width}
      height={height}
      className={
        pristine
          ? undefined
          : styles.faIcon({
              className,
              css: { "--fa-icon-color": color ? `$colors$${color}` : undefined }
            })
      }
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      {...props}
    >
      {paths.map((d, index) => (
        <path key={index} d={d} />
      ))}
    </svg>
  );
}

interface Props
  extends Omit<React.SVGProps<SVGSVGElement>, "dangerouslySetInnerHTML"> {
  icon: FaIcon.Icon;
  pristine?: boolean;
  size?: number;
}

export namespace FaIcon {
  export type Icon = Pick<IconDefinition, "icon"> &
    Partial<Omit<IconDefinition, "icon">>;
}
