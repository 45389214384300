import React, { useState, useEffect, useRef } from "react";
import { HexColorPicker } from "react-colorful";
import { TextInput } from "../TextInput";

import { styles } from "./ColorPicker.styles";
import { useClickOutside } from "../../hooks/useClickOutside";

export function ColorPicker({
  name,
  onChange = () => {},
  size = "medium",
  width = "full",
  value = null
}: Props) {
  const [open, setOpen] = useState(false);
  const [color, setColor] = useState(value);
  const ref = useClickOutside<HTMLDivElement>(onClickOutside);
  const inputRef = useRef<HTMLInputElement>();
  useEffect(() => {
    setColor(value);
  }, [value]);

  function onInputClick() {
    setOpen(true);
  }

  function onColorChange(color: string) {
    setColor(color);
  }

  function onClickOutside() {
    setOpen(false);
  }

  function onInputChange(value: string) {
    if (/^([0-9a-fA-F]{3}){1,2}$/.test(value)) value = "#" + value;
    if (/^#[0-9a-fA-F]{7,}$/.test(value)) value = value.substr(0, 7);

    if (!/^#[0-9a-fA-F]{0,6}$/.test(value)) return false;
    if (/^#(?:[0-9a-fA-F]{3}){1,2}$/.test(value)) {
      setColor(value);
      onChange(value, name!);
    }
  }

  function onSelect() {
    if (inputRef.current!.selectionStart === 0) {
      inputRef.current?.setSelectionRange(1, inputRef.current!.selectionEnd);
    }
  }

  function onBlur() {
    onChange(color, name!);
  }

  return (
    <div
      ref={ref}
      className={styles.colorPicker({ size, width })}
      onClick={onInputClick}
    >
      <TextInput
        compound="left"
        inputRef={inputRef}
        name={name}
        onChange={onInputChange}
        onSelect={onSelect}
        size={size}
        value={color ?? ""}
      />
      <div
        className={styles.preview({
          size,
          css: { backgroundColor: color, borderColor: color }
        })}
      />
      {open && (
        <div className={styles.popover()}>
          <HexColorPicker
            color={color ?? undefined}
            onChange={onColorChange}
            onMouseUp={onBlur}
          />
        </div>
      )}
    </div>
  );
}

interface Props {
  name?: string;
  onChange?: (value: string | null, name: string) => void;
  size?: "medium" | "small";
  value?: string | null;
  width?: "full" | "square";
}
