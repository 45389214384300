import React from "react";
import Image from "../Image";
import Icons from "./../Icons";
import { IconLabel } from "../IconLabel";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import SecondaryButton from "../SecondaryButton";
import AvatarBlurbWithTags from "../AvatarBlurbWithTags";
import { styles } from "./BookingReceiptCard.styles";
// @ts-ignore
import { Location__GoogleMapsLink } from "@obby/lib";

export default function BookingReceiptCard({
  title,
  image,
  calendarLink,
  onJoinClass,
  borderTopColor = "royalBlue",
  bookingStartDate,
  isJoinClassDisabled,
  isOnline,
  address,
  name,
  avatar,
  onRescheduleBooking,
  onCancelBooking
}: Props) {
  return (
    <div
      className={styles.bookingReceiptCard({
        css: {
          borderColor: `$${borderTopColor}`
        }
      })}
    >
      <div className={styles.header()}>
        <div className={styles.headerContent()}>
          <span className={styles.title()}>{title}</span>
          <div className={`booking-receipt-card__school `}>
            <AvatarBlurbWithTags
              name={name}
              avatar={avatar}
              tags={[{ text: "Teacher", color: "royal-blue" }]}
            />
          </div>
        </div>
        {/* @ts-ignore */}
        <Image src={image} className={styles.avatar()} />
      </div>
      <div
        className={styles.details({
          isOnline
        })}
      >
        {!isOnline && address && (
          <div className={styles.iconLabel()}>
            <IconLabel
              icon={Icons.Location}
              label={
                <>
                  {address.name}, {address.line1} {address.postcode}{" "}
                  <a href={Location__GoogleMapsLink(address)} target="_blank">
                    Get directions
                  </a>
                </>
              }
            />
          </div>
        )}
        <div className={styles.iconLabel()}>
          <IconLabel
            icon={Icons.Time}
            label={
              <>
                {bookingStartDate}{" "}
                <a href={calendarLink} target="_blank">
                  Add to Calendar
                </a>
              </>
            }
          />
        </div>
        {onRescheduleBooking && (
          <div className={styles.iconLabel()}>
            <IconLabel
              icon={Icons.Time}
              label={
                <>
                  <SecondaryButton
                    text="Reschedule booking"
                    onClick={onRescheduleBooking}
                  />
                </>
              }
            />
          </div>
        )}

        {onCancelBooking && (
          <div className={styles.iconLabel()}>
            <IconLabel
              icon={Icons.Time}
              label={
                <>
                  <SecondaryButton
                    text="Cancel booking"
                    onClick={onCancelBooking}
                  />
                </>
              }
            />
          </div>
        )}
      </div>
      {isOnline && (
        <>
          <div className={styles.joinClass()}>
            {isJoinClassDisabled ? (
              <SecondaryButton
                text="Join class"
                uppercase
                disabled
                onClick={onJoinClass}
              />
            ) : (
              <PrimaryButton
                color="emeraldish"
                text="Join class"
                uppercase
                onClick={onJoinClass}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}

interface Props {
  title: string;
  image: string;
  calendarLink: string;
  onJoinClass?: () => void;
  borderTopColor?: string;
  bookingStartDate: string;
  isJoinClassDisabled: boolean;
  isOnline: boolean;
  address?: any;
  name: string;
  avatar: string;
  onRescheduleBooking?: () => void;
  onCancelBooking?: () => void;
}
