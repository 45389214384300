import React from "react";
import { Label } from "../../components/Label";
import { bem } from "../../configs/bem";
import { RadioInput } from "../../common/inputs/RadioInput";

const cn = bem("product-dispatch-form");
export function ProductBulkDispatchForm({ values = {}, onChange }) {
  return (
    <div className={cn()}>
      <div className="row">
        <div className="col-12">
          <Label label="Do you want to send the customer dispatch email?">
            <RadioInput
              value={values.wantsCustomerDispatchEmail}
              name="wantsCustomerDispatchEmail"
              onChange={onChange}
            >
              <RadioInput.Option value={false}>No</RadioInput.Option>
              <RadioInput.Option value={true}>Yes</RadioInput.Option>
            </RadioInput>
          </Label>
        </div>
      </div>
    </div>
  );
}
