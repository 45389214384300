import React, { useContext } from "react";

import styles from "./menu-nav-item-wrapper.module.less";
import { MenuNavItemContext } from "./MenuNavContext";

export function MenuNavItemWrapper(props) {
  const {
    children,
    href,
    border = false,
    padding = "py-4 py-lg-0",
    onClick = () => {},
    target
  } = props;
  const { onItemToggle = () => {}, onItemLeave, ref } = useContext(
    MenuNavItemContext
  );

  function onItemClick(e) {
    onClick(e);
    if (!e.isDefaultPrevented()) onItemToggle();
  }
  return (
    <li
      className={`${styles["menu-nav-item-wrapper"]} ${
        border ? styles["menu-nav-item-wrapper--border"] : ""
      } mx-lg-3 mr-last-0`}
    >
      <a
        className={`${styles["menu-nav-item-wrapper__link"]} ${padding}`}
        href={href}
        onClick={onItemClick}
        onMouseLeave={onItemLeave}
        ref={ref}
        target={target}
      >
        {children}
      </a>
    </li>
  );
}
