import React, { useEffect, useState, useContext } from "react";
import Icon from "../Icon";
import Icons from "../Icons";
import { Overlay } from "../Overlay";
import { RisingPopupContext } from "./RisingPopupContext";
import { bemModule } from "../../configs/bem";

import styles from "./RisingPopupOverlay.module.less";

const cn = bemModule(styles, "rising-popup");

export function RisingPopupOverlay(props) {
  const { title, children, onClose = () => {}, className = "" } = props;

  const [closing, setClosing] = useState(false);

  const { close } = useContext(RisingPopupContext);

  useEffect(() => {
    if (close) onCloseCLick();
  }, [close]);

  function onCloseCLick() {
    setClosing(true);
    setTimeout(onClose, 500);
  }

  return (
    <Overlay onClick={onCloseCLick}>
      <div className={cn({ closing }, [className])}>
        <div className={cn("wrapper")}>
          <div className={cn("container")}>
            {title && <div className={cn("title")}>{title}</div>}
            <div className={cn("content")}>{children}</div>
          </div>
          <div className={cn("close")}>
            <Icon
              className={cn("close-icon")}
              icon={Icons.Cross}
              height={18}
              onClick={onCloseCLick}
            />
          </div>
        </div>
      </div>
    </Overlay>
  );
}
// RisingPopup.Dialog = RisingPopupDialog;
