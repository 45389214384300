import React from "react";
import PropTypes from "prop-types";
import { AspectRatio } from "../../boxes/AspectRatio";
import { CloudinaryImage } from "../CloudinaryImage";
import { ExpandableText } from "../ExpandableText";
import { PrimaryButton } from "../PrimaryButton";
import { Skeleton } from "../Skeleton";
import styles from "./WidgetHeaderBanner.module.less";

export function WidgetHeaderBanner(props) {
  const {
    backgroundColor,
    button = "",
    description,
    imagePosition = "top",
    imageUrl,
    skeleton = false,
    onButtonClick,
    textAlignment = "center",
    textColor,
    title
  } = props;

  const imageColumnClassName = {
    top: "col-12 col-smd-8 order-0 mb-4",
    bottom: "col-12 col-smd-8 order-1 mt-4",
    left: "col-12 col-smd-6 order-smd-0 mb-4 mb-smd-0",
    right: "col-12 col-smd-6 order-smd-1 mb-4 mb-smd-0"
  }[imagePosition];

  return (
    <div className={styles["widget-header-banner"]}>
      <div className={`${styles["widget-header-banner__row"]} row`}>
        {imageUrl && (
          <div className={imageColumnClassName}>
            {skeleton ? (
              <AspectRatio width={584} height={352}>
                <Skeleton
                  color="white"
                  highlightColor="mercury"
                  className={styles["widget-header-banner__image-skeleton"]}
                />
              </AspectRatio>
            ) : (
              <div className={styles["widget-header-banner__image-wrapper"]}>
                <CloudinaryImage
                  className={styles["widget-header-banner__image"]}
                  src={imageUrl}
                  width={584}
                  height={352}
                  alt={title}
                />
              </div>
            )}
          </div>
        )}
        <div
          className={`col-12 ${
            imageUrl && ["left", "right"].includes(imagePosition)
              ? "col-smd-6"
              : "col-smd-8"
          }`}
        >
          <div
            className={`${styles["widget-header-banner__info"]} ${
              !description && !imageUrl
                ? styles["widget-header-banner__info--extra-padding"]
                : ""
            } text-${textAlignment}`}
            style={{ color: textColor }}
          >
            <h1
              className={`${styles["widget-header-banner__title"]}
							${
                !description && !imageUrl
                  ? styles["widget-header-banner__title--highlighted"]
                  : ""
              }
							mt-0 mb-4
							`}
            >
              {skeleton ? <Skeleton light /> : title}
            </h1>
            {skeleton ? (
              <div
                className={`${styles["widget-header-banner__description"]} px-5`}
              >
                <p>
                  <Skeleton light lines={2} />
                </p>
              </div>
            ) : (
              description && (
                <ExpandableText
                  maxWords={30}
                  className={styles["widget-header-banner__description"]}
                  readMoreTextColor={textColor}
                >
                  {description}
                </ExpandableText>
              )
            )}
            {!skeleton && button && (
              <div className={description ? "mt-4" : undefined}>
                <PrimaryButton
                  text={button}
                  colorHex={backgroundColor}
                  backgroundColorHex={textColor}
                  width="auto"
                  onClick={onButtonClick}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

WidgetHeaderBanner.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string
};
