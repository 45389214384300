import {
  Number__Currency,
  CurrencyISO__CurrencySymbol,
  Session__DisplayDate,
  Session__DisplayTime,
  Session__DisplayOffsetName,
  Timezone__IsSameAsLocal
} from "@obby/lib";
import React, { useState } from "react";
import { CloudinaryImage } from "../CloudinaryImage";
import HighlightPrice from "../HighlightPrice";

import TextPill from "../TextPill";
import { Timezones, Currencies } from "@obby/constants";

import { styles } from "./SessionDateRow.styles";

export function SessionDateRow(props) {
  const {
    _id,
    additionalSessions = [],
    currency = Currencies.GBP,
    /** @deprecated - use startDateTime instead */
    date,
    startDateTime = date,
    description,
    disabled: isDisabled,
    discount,
    duration,
    hasMorePrices,
    isEarlyAccess,
    isOnline,
    isTerm,
    location,
    number,
    onClick,
    openWaitingListPopup,
    price,
    selected,
    timezone = Timezones.EUROPE_LONDON,
    tutorName,
    tutorImageUrl,
    tutorUrl,
    waitingList = isDisabled
  } = props;

  const [showAllSessions, setShowAllSessions] = useState(false);

  function onShowAllSessionsClick(e) {
    e.stopPropagation();
    setShowAllSessions(!showAllSessions);
  }

  return (
    <div
      className={styles.sessionDateRow({ isDisabled, isSelected: selected })}
      onClick={isDisabled ? null : () => onClick(_id)}
    >
      {isEarlyAccess && (
        <TextPill text="Early Access" backgroundColor="sun" size="tiny" />
      )}
      <div className={styles.wrapper()}>
        <div className={styles.header({ isDisabled })}>
          <div className={styles.dateContainer()}>
            <div className={styles.date()}>
              {Session__DisplayDate({ startDateTime, isOnline }, timezone)}
            </div>
            {additionalSessions.length > 0 && (
              <>
                <div
                  className={styles.showAllButton()}
                  onClick={onShowAllSessionsClick}
                >
                  Show all sessions
                </div>
                {showAllSessions &&
                  additionalSessions.map(
                    ({ startDateTime, duration }, index) => (
                      <div key={index} className={styles.additionalDate()}>
                        <span>
                          {Session__DisplayDate(
                            { startDateTime, isOnline },
                            timezone
                          )}
                        </span>
                        {", "}
                        <span>
                          {Session__DisplayTime(
                            { startDateTime, duration, isOnline },
                            timezone
                          )}
                        </span>
                      </div>
                    )
                  )}
              </>
            )}
          </div>
          <div className={styles.descriptions()}>
            {isTerm && (
              <div className={styles.description({ bold: true })}>
                {number === 1 ? "Full term" : "Pro-rata term"}
              </div>
            )}
            <span className={styles.description()}>{description}</span>
          </div>
        </div>
        <div className={styles.info()}>
          <div className={styles.location({ isDisabled })}>{location}</div>
          <div className={styles.time({ isDisabled })}>
            <span className="d-inline-block">
              {Session__DisplayTime(
                { startDateTime, duration, isOnline },
                timezone
              )}
            </span>
            {!isOnline && !Timezone__IsSameAsLocal(timezone) && (
              <>
                {" "}
                <small className="d-inline-block">
                  {Session__DisplayOffsetName(
                    { startDateTime, isOnline },
                    timezone
                  )}
                </small>
              </>
            )}
          </div>
          <div className={styles.lineBreak()} />

          <div className={styles.price({ isDisabled })}>
            {price !== undefined && (
              <>
                {discount !== undefined && (
                  <HighlightPrice
                    price={discount}
                    pp={false}
                    currency={currency}
                  />
                )}
                <span
                  className={styles.priceText({
                    isDiscounted: discount !== undefined
                  })}
                >
                  {hasMorePrices && "from "}
                  {Number__Currency(
                    price,
                    CurrencyISO__CurrencySymbol(currency)
                  )}
                </span>
              </>
            )}
          </div>

          <div className={styles.authorOrAction()}>
            {waitingList && typeof openWaitingListPopup === "function" ? (
              <button
                onClick={openWaitingListPopup}
                className={styles.waitingListButton()}
              >
                Waiting List
              </button>
            ) : (
              <a
                href={tutorUrl || undefined}
                target="_blank"
                className={styles.tutor({ isDisabled })}
                onClick={tutorUrl ? e => e.stopPropagation() : undefined}
              >
                {tutorImageUrl && (
                  <CloudinaryImage
                    className={styles.tutorImage()}
                    width={30}
                    height={30}
                    src={tutorImageUrl}
                  />
                )}
                {tutorName && <span>{tutorName}</span>}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
