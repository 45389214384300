import moment from "moment-timezone";
import { Timezones } from "@obby/constants";

export function UTCDate__FormattedDate(
  isoDate: string,
  timezone: string = Timezones.EUROPE_LONDON,
  format: string = "ddd D MMMM[,] h:mma"
) {
  return moment(isoDate)
    .tz(timezone)
    .format(format);
}
