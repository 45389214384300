import { useState } from "react";

export function useDiscount(total, validate, initialDiscount = null) {
  const [discount, setDiscount] = useState(initialDiscount);

  async function applyCode(code, email) {
    try {
      setDiscount(null);
      const discount = await validate(code, email);
      setDiscount(discount);
    } catch (error) {
      setDiscount(false);
    }
  }

  function getDiscount() {
    if (discount === null) return 0;
    const { value, type } = discount;
    switch (type) {
      case "PERCENTAGE":
        return Math.round((total() * Math.min(value, 100)) / 100);
      case "PRICE":
        return Math.min(total(), value);
      default:
        return 0;
    }
  }

  function getDiscountPercentage() {
    return discount?.type === "PERCENTAGE" ? discount.value : undefined;
  }

  function isDiscountInvalid() {
    return discount === false;
  }

  return {
    discountCode: discount?.code || "",
    applyCode,
    getDiscount,
    getDiscountPercentage,
    isDiscountInvalid
  };
}
