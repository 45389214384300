import * as Yup from "yup";

export function LocationFormSchema(defaults = {}) {
  defaults = {
    ...Defaults,
    ...defaults
  };

  return Yup.object({
    name: Yup.string()
      .label("Name")
      .required()
      .default(defaults.name),
    country: Yup.string()
      .label("Country")
      .default(defaults.country),
    area: Yup.string()
      .label("Area")
      .required()
      .default(defaults.area),
    line1: Yup.string()
      .label("Address line 1")
      .required()
      .default(defaults.addressLine1),
    line2: Yup.string()
      .label("Address line 2")
      .default(defaults.addressLine2),
    postcode: Yup.string()
      .label("Post code")
      .required()
      .default(defaults.name),
    city: Yup.string()
      .label("City")
      .required()
      .default(defaults.name)
  });
}

export const Defaults = {
  name: "",
  country: "UK",
  area: "",
  addressLine1: "",
  addressLine2: "",
  postcode: "",
  city: ""
};
