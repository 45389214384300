import React from "react";
import $ from "jquery";
import { styles } from "./Popup.styles";

export default class Popup extends React.Component {
  constructor() {
    super();
    this.closePopup = this.closePopup.bind(this);
  }

  componentWillReceiveProps(props) {
    if (props.show) {
      this.disableScroll();
    } else {
      this.enableScroll();
    }
  }

  componentWillUnmount() {
    this.enableScroll();
  }

  disableScroll() {
    // $(window).on("scroll", function(e) {
    //   e.preventDefault();
    // });
    // $(window).on("touchmove", function(e) {
    //   e.preventDefault();
    // });
  }

  enableScroll() {
    // $(window).off("scroll");
    // $(window).off("touchmove");
  }

  closePopup() {
    this.props.onClose();
  }

  render() {
    return (
      this.props.show && (
        <div className={`popup ${styles.popup}`}>
          <div
            className={`popup__overlay ${styles.overlay}`}
            onClick={this.closePopup}
          />
          <span
            className={`popup__mobile-close-button ${styles.mobileCloseButton}`}
            onClick={this.closePopup}
          >
            ×
          </span>
          <div className={`popup__container ${styles.container}`}>
            <div className={`popup__box ${styles.box}`}>
              <span
                className={`popup__close-button ${styles.closeButton}`}
                onClick={this.closePopup}
              >
                ×
              </span>
              {this.props.children}
            </div>
          </div>
        </div>
      )
    );
  }
}
