import React from "react";
import { CloudinaryImage } from "../CloudinaryImage";

import styles from "./class-list-menu-item.module.less";

export default function ClassListMenuItem({
  title,
  numberOfClasses,
  description,
  thumbnail,
  link
}) {
  return (
    <a className={styles["class-list-menu-item"]} href={link}>
      <div className={styles["class-list-menu-item__thumbnail-wrapper"]}>
        <CloudinaryImage
          src={thumbnail}
          width={180}
          height={180}
          className={styles["class-list-menu-item__thumbnail"]}
        />
      </div>
      <div className={styles["class-list-menu-item__content"]}>
        <span className={styles["class-list-menu-item__title"]}>{title}</span>
        {numberOfClasses && (
          <span className={styles["class-list-menu-item__classes"]}>
            {numberOfClasses} Class{numberOfClasses > 1 ? "es" : ""}
          </span>
        )}
        {description && (
          <span className={styles["class-list-menu-item__classes"]}>
            {description}
          </span>
        )}
      </div>
    </a>
  );
}
