import React from "react";

import { CheckoutCardItem } from "./CheckoutCardItem";
// @ts-ignore
import { Number__Currency } from "@obby/lib";

import { styles } from "./CheckoutCredit.styles";
export function CheckoutCredit({ credit }: Props) {
  return (
    <CheckoutCardItem
      title={
        <>
          Your{" "}
          <span className={styles.value()}>{Number__Currency(credit)}</span> is
          applied
        </>
      }
    />
  );
}

interface Props {
  credit: number;
}
