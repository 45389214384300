import { css } from "../../sitches.config";

export const styles = {
  item: css({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",

    variants: {
      bold: {
        true: {
          paddingY: "$4",
          // marginBottom: 0,
          "&:first-child": {
            paddingTop: 0
          },
          "&:last-child": {
            paddingBottom: 0
          }
        },
        false: {
          marginBottom: "$1"
        }
      }
    }
  }),
  content: css({
    flexBasis: "100%"
  }),

  title: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$16",
    letterSpacing: "0.03em",
    color: "$ebonyClay",
    flex: "1 1",
    variants: {
      bold: {
        true: {
          fontWeight: "500",
          fontSize: "$18"
        }
      }
    }
  }),
  description: css({
    flexBasis: "100%",
    fontFamily: "$foundersGrotesk",
    fontSize: "$16",
    letterSpacing: "0.03em",
    color: "$ebonyClay",
    lineHeight: 1
  }),
  price: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$16",
    letterSpacing: "0.03em",
    color: "$ebonyClay",
    variants: {
      bold: {
        true: {
          fontWeight: "500",
          fontSize: "$18"
        }
      }
    }
  })
};
