import { Timezones } from "@obby/constants";
import { UTCDate__FormattedDate } from "./UTCDate__FormattedDate";

export function Date__DisplayOffsetName(
  date: string,
  timezone: string = Timezones.EUROPE_LONDON,
  forceTimezone: boolean = false
) {
  if (!forceTimezone)
    timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? timezone;

  return UTCDate__FormattedDate(date, timezone, "(z)");
}
