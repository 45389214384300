import React, { Component } from "react";
//import * as Sentry from "@sentry/browser";
/*
let dsn = "";
if (process.env.NODE_ENV === "production") {
  dsn = "https://df12ee7aed41484c9580de51b0ec4e8b@sentry.io/2496127";
}
Sentry.init({
  dsn: dsn,
  environment: process.env.NODE_ENV
});
*/
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log("caught an error ", error);
    /*
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
    */
  }

  render() {
    if (this.state.hasError) {
      //render fallback UI
      return (
        <button
          onClick={() => {
            //Sentry.showReportDialog({ eventId: this.state.eventId })
            console.log("error");
          }}
        >
          Report feedback
        </button>
      );
    }

    //when there's not an error, render children untouched
    return this.props.children;
  }
}

export default ErrorBoundary;
