import Booking__Discount from "./Booking__Discount";
import { Product_Source } from "./Product__Source";
import { Timezones } from "@obby/constants";
import { UTCDate__FormattedDate } from "./UTCDate__FormattedDate";

export function Product__FinancialReportingProductRow(
  productPurchase,
  timezone = Timezones.EUROPE_LONDON,
  isRefunded = false
) {
  const discount = Booking__Discount(productPurchase);
  let transferDate;
  let partnerMoneyStatus = convertPartnerMoneyStatusIntoLabel(
    productPurchase.partnerMoneyStatus
  );
  if (productPurchase.partnerMoneyStatus === "transfered") {
    transferDate = productPurchase.partnerMoneyTransfer.transferDate;
  }
  if (productPurchase.partnerMoneyStatus === "paid") {
    if (productPurchase.manualPaidDate) {
      transferDate = productPurchase.manualPaidDate;
    }
  }
  return {
    id: productPurchase._id,
    class: productPurchase.product.name,
    date: UTCDate__FormattedDate(
      productPurchase.dispatch.dispatchedAt,
      timezone,
      "D MMMM YYYY"
    ),
    ticket: "",
    name: productPurchase.buyerName,
    quantity: [ProductPurchase_QuantityToLabel(productPurchase)],
    type: ProductPurchase_TypeToLabel(productPurchase),
    internalType: "product",
    source: Product_Source(productPurchase),
    // @TODO we dont have a refundedObject in the productPurchase object
    grossTotal: !isRefunded
      ? productPurchase.accounting.rawEarning
      : productPurchase.partnerMoneyTransfer?.reverseAmount,
    discount:
      discount.discount && discount.isFromTeacher ? discount.discount : null,
    netPay: !isRefunded
      ? productPurchase.accounting.netPay
      : productPurchase.partnerMoneyTransfer?.reverseAmount,
    paymentProcessing: productPurchase.accounting.paymentProcessing,
    obbyFee: productPurchase.accounting.obbyFee,
    vat: productPurchase.accounting.vat,
    isMultiple: false,
    allTickets: [],
    partnerMoneyStatus: partnerMoneyStatus,
    payoutID: productPurchase.payoutID,
    transferDate: transferDate
      ? UTCDate__FormattedDate(transferDate, timezone, "D MMMM YYYY")
      : "-",
    currency: productPurchase.currencyUsed,
    customerEmail: productPurchase.customer?.email ?? null,
    customerID: productPurchase.customer?._id ?? null
  };
}

//need a function on its own
function convertPartnerMoneyStatusIntoLabel(partnerMoneyStatus) {
  if (partnerMoneyStatus == "pending") {
    return "Pending";
  }

  if (
    partnerMoneyStatus == "transfered" ||
    partnerMoneyStatus == "paid" ||
    partnerMoneyStatus == "destinationCharge"
  ) {
    return "Paid";
  }

  if (partnerMoneyStatus == "refundedToUser") {
    return "Refunded";
  }

  if (partnerMoneyStatus == "nonAplicable") {
    return "Non Aplicable";
  }
}

function ProductPurchase_QuantityToLabel(productPurchase) {
  if (productPurchase.quantity == 1) {
    return `${productPurchase.quantity} kit`;
  } else {
    return `${productPurchase.quantity} kits`;
  }
}

function ProductPurchase_TypeToLabel(productPurchase) {
  switch (productPurchase.product.productType) {
    case "learning-kit":
      return "Learning Kit";
  }
}
