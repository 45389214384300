import * as Yup from "yup";
export function ContactFormWidgetSchema() {
  const defaults = {
    fullName: "",
    email: "",
    phoneNumber: "",
    enquiry: "",
    message: ""
  };

  return Yup.object({
    fullName: Yup.string()
      .label("Full Name")
      .required()
      .default(defaults.fullName),
    email: Yup.string()
      .label("Email address")
      .email()
      .required()
      .default(defaults.email),
    phoneNumber: Yup.number().label("Phone number"),
    enquiry: Yup.string().label("Enquiry"),
    message: Yup.string()
      .label("Message")
      .min(30)
      .required()
      .default(defaults.message)
  });
}
