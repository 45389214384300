import React, { useState, useEffect } from "react";
// Lib
// @ts-ignore
import { CBookingRunningStatus } from "@obby/lib";
// Components
import Icons from "../Icons";
import { Tabs, Tab } from "../Tabs";
import MediaCard from "../MediaCard";
import GetReadyTab from "../GetReadyTab";
import BannerPopup from "../BannerPopup";
import AvatarBlurbWithTags from "../AvatarBlurbWithTags";
import BookingReceiptCard from "../BookingReceiptCard";
import BookingDetailCard from "../BookingDetailCard";
import PostClassTab from "../PostClassTab";
import PromptCard from "../PromptCard";
import { styles } from "./VirtualClassroom.styles";

interface bannerPopupProps {
  buttonColor?: string;
  buttonText?: string;
  preTitle?: string;
  bannerImage: string;
  bannerImageTablet: string;
  bannerImageMobile: string;
  description: string;
}

interface bookingReceiptCardProps {
  calendarLink: string;
  bookingId: string;
  image: string;
  bookingStartDate: string;
  description: string;
}

interface VirtualClassroomProps {
  runningStatus?: string;
  preTitle: string;
  className: string;
  teacherName: string;
  teacherAvatar: string;
  preClassNotes: string;
  postClassNotes: string;
  whatYouWillNeed?: string[];
  whatYouWillLearn?: string[];
  inspirationTitle: string | undefined;
  isBannerPopupOpen: boolean;
  isReviewEnabled: boolean;
  bannerPopupProps: bannerPopupProps;
  speechBubbleBackground?: string;
  inspirationVideoUrl: string | undefined;
  bookingReceiptCardProps: bookingReceiptCardProps;
  inspirationNotificationCount?: number;
  getReadyNotificationCount?: number;
  helpTabLink: string;
  getReadyTitle: string;
  postClassTitle: string;
  onLeaveReview?: () => void;
  onJoinClass?: () => void;
  onSignIn?: () => void;
  onContactObbyPopup?: () => void;
  onCloseModal: () => void;
  onClickTab?: () => void;
  onBookgAgain: () => void;
  isOnline: boolean;
  address?: any;
  data?: any;
  headerPlaceholder?: boolean;
  onRescheduleBooking?: () => void;
  onCancelBooking?: () => void;
}

const VirtualClassroom = ({
  runningStatus = CBookingRunningStatus.NOT_RUN,
  preTitle,
  className,
  teacherName,
  teacherAvatar,
  preClassNotes,
  postClassNotes,
  whatYouWillNeed,
  whatYouWillLearn,
  inspirationTitle,
  isBannerPopupOpen,
  bannerPopupProps,
  speechBubbleBackground = "https://res.cloudinary.com/obby/image/upload/v1586258476/Page%20Images/background.png",
  inspirationVideoUrl,
  bookingReceiptCardProps,
  inspirationNotificationCount,
  getReadyNotificationCount,
  helpTabLink,
  getReadyTitle,
  postClassTitle,
  onLeaveReview,
  onJoinClass,
  onSignIn,
  onContactObbyPopup,
  onCloseModal,
  onClickTab,
  onBookgAgain,
  isOnline,
  isReviewEnabled,
  address,
  data,
  headerPlaceholder = true,
  onRescheduleBooking,
  onCancelBooking
}: VirtualClassroomProps) => {
  const ReceiptTab = (props: any): any => (
    <div
      className={`virtual-classroom__booking-receipt-wrapper ${
        props.style
      } ${styles.bookingReceiptWrapper()}`}
    >
      {!isClassFinished() && (
        <>
          <BookingReceiptCard
            title="Your class details"
            onJoinClass={onJoinClass}
            isJoinClassDisabled={!isClassJoinable()}
            {...bookingReceiptCardProps}
            name={teacherName}
            avatar={teacherAvatar}
            isOnline={isOnline}
            address={address}
            onRescheduleBooking={onRescheduleBooking}
            onCancelBooking={onCancelBooking}
          />
          <div
            className={`virtual-classroom__bookind-detail d-none d-md-inline`}
          >
            <BookingDetailCard
              title="Your booking details"
              {...bookingReceiptCardProps}
              details={data.ticketDetail}
            />
          </div>
        </>
      )}
      {isClassFinished() && (
        <PromptCard
          title="Why not book another class?"
          text="Enjoy the class? Why not do it again. Book the class again below"
          buttonText="Book Again"
          onClick={onBookgAgain}
        />
      )}
    </div>
  );

  const desktopTabs = [
    <Tab
      key="getReady"
      id={"getReady"}
      label="Get Ready"
      notification={getReadyNotificationCount}
    >
      <GetReadyTab
        onContactObbyPopup={onContactObbyPopup}
        onSignIn={onSignIn}
        whatYouWillLearn={whatYouWillLearn}
        whatYouWillNeed={whatYouWillNeed}
        teacherName={teacherName}
        teacherAvatar={teacherAvatar}
        speechTitle={getReadyTitle}
        speechBubbleText={preClassNotes}
        backgroundBanner={speechBubbleBackground}
        onJoinClass={onJoinClass}
        bookingReceiptCardProps={bookingReceiptCardProps}
        isOnline={isOnline}
        address={address}
        details={data.ticketDetail}
      />
    </Tab>,
    ...(![inspirationTitle, inspirationVideoUrl].includes(undefined)
      ? [
          <Tab
            key="inspiration"
            id="inspiration"
            label="Inspiration"
            notification={inspirationNotificationCount}
          >
            <MediaCard
              title={inspirationTitle}
              type="video"
              url={inspirationVideoUrl}
              mediaWidth="684"
              mediaHeight="375"
            />
          </Tab>
        ]
      : []),
    <Tab
      key="postClass"
      id="postClass"
      label="Post Class"
      disabled={!isClassFinished()}
      icon={!isClassFinished() ? Icons.Lock : undefined}
    >
      <PostClassTab
        onContactObbyPopup={onContactObbyPopup}
        onSignIn={onSignIn}
        isReviewEnabled={isReviewEnabled}
        onLeaveReview={onLeaveReview}
        teacherName={teacherName}
        teacherAvatar={teacherAvatar}
        speechTitle={postClassTitle}
        speechBubbleText={postClassNotes}
        backgroundBanner={speechBubbleBackground}
      />
    </Tab>
  ];

  const mobileTabs = [
    <Tab id="joinClass" key="joinClass" label="Join Class" onClick={onClickTab}>
      <ReceiptTab />
    </Tab>,
    ...desktopTabs
  ];

  const [activeTab, setActiveTab] = useState(
    isClassFinished() ? "postClass" : undefined
  );

  useEffect(() => {
    if (isClassFinished()) {
      setActiveTab("postClass");
    }
  }, [runningStatus]);

  function isClassFinished(): boolean {
    return runningStatus === CBookingRunningStatus.FINISHED;
  }

  function isClassJoinable() {
    return [
      CBookingRunningStatus.STARTING_SOON,
      CBookingRunningStatus.RUNNING
    ].includes(runningStatus);
  }

  return (
    <div
      className={`virtual-classroom ${styles.virtualClassRoom({
        headerPlaceholder
      })}`}
    >
      <div className={`virtual-classroom__container ${styles.container()}`}>
        {/* BODY */}
        <div className={`col-12 col-lg-8`}>
          {/* HEADER */}
          <div
            className={`virtual-classroom__title-wrapper ${styles.titleWrapper()}`}
          >
            {preTitle && (
              <span
                className={`virtual-classroom__title-colored ${styles.titleColored()}`}
              >
                {preTitle}
              </span>
            )}
            <span className={`virtual-classroom__title ${styles.title()}`}>
              {className}
            </span>
          </div>
          <ReceiptTab style={`${isOnline ? "d-none" : "d-md-none"}`} />
          {/* DESKTOP: TABS */}
          <div
            className={`virtual-classroom__tabs-wrapper ${styles.tabsWrapper({
              isOnline
            })}`}
          >
            <Tabs active={activeTab} onTabActive={onClickTab}>
              {desktopTabs}
            </Tabs>
          </div>
          {/* TABLET & MOBILE: TABS */}
          {isOnline && (
            <div
              className={`virtual-classroom__tabs-wrapper ${styles.tabsWrapper({
                variant: "mobile"
              })}`}
            >
              <Tabs active={activeTab} onTabActive={onClickTab}>
                {mobileTabs}
              </Tabs>
            </div>
          )}
        </div>

        {/* DESKTOP: SIDEBAR - RECEIPT & TEACHER AVATAR */}
        <div
          className={`virtual-classroom__sidebar col-12 col-lg-4 ${styles.sideBar()}`}
        >
          <ReceiptTab />
        </div>
      </div>
    </div>
  );
};

export default VirtualClassroom;
