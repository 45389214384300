import { DiscountNatures, DiscountTypes } from "@obby/constants";

export function Booking__DiscountValue(booking) {
  switch (booking.discount?.type) {
    case DiscountTypes.PRICE:
      // get the lower value between the discount value and the total gross. bookings purchased by gift card have a good chance
      // to have a discount value greater than the total gross
      return Math.min(booking.discount.value, booking.totalGross);
    case DiscountTypes.PERCENTAGE:
      return (booking.discount.value * booking.totalGross) / 100;
    default:
      return 0;
  }
}
