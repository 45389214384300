import { FaIcon } from "./FaIcon";

export const EmbedPopover: FaIcon.Icon = {
  icon: [
    300,
    300,
    [],
    "",
    [
      "M150 80 h130 v140 h-130 z",
      "M260 260 m-20, 0 a 20,20 0 1,0 40,0 a20,20 0 1,0 -40,0"
    ]
  ]
};
