import { css, withBem } from "../../../sitches.config";

export const styles = withBem("pricing-option-box", {
  pricingOptionBox: css({
    fontFamily: "$foundersGrotesk",
    color: "$textColor",
    cursor: "pointer",
    variants: {
      compacted: {
        true: {
          cursor: "default"
        }
      }
    }
  }),
  wrapper: css({
    display: "flex",
    flexWrap: "wrap",
    gap: "$2",
    justifyContent: "space-between",
    alignItems: "center",
    "@smd": {
      flexWrap: "nowrap"
    },
    "@md": {
      flexWrap: "wrap"
    },
    "@lg": {
      flexWrap: "nowrap"
    },
    variants: {
      compacted: {
        true: {
          flexWrap: "nowrap"
        }
      }
    }
  }),
  text: css({
    flex: "1 1 100%",
    "@smd": {
      flexBasis: "auto"
    },
    "@md": {
      flexBasis: "100%"
    },
    "@lg": {
      flexBasis: "auto"
    }
  }),
  name: css({
    fontWeight: 500
  }),
  duration: css({
    fontWeight: 500,
    "@smd": {
      flex: "0 0 150px"
    },
    "@md": {
      flex: "unset"
    },
    "@lg": {
      flex: "0 0 150px"
    }
  }),
  price: css({
    fontWeight: 500,
    "@smd": {
      flex: "0 0 50px"
    },
    "@md": {
      flex: "unset"
    },
    "@lg": {
      flex: "0 0 50px"
    },
    variants: {
      isDiscounted: {
        true: {
          textDecoration: "line-through"
        }
      }
    }
    // "@lg": {
    //   flex: "0 0 50px"
    // }
  })
});
