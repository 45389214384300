import React, { ReactElement } from "react";
import { TextInput } from "../TextInput";
import { SelectBox } from "../SelectBox";
import { NumberInput } from "../NumberInput";

import { styles } from "./CompoundInput.styles";

export function CompoundInput({ children, size }: Props) {
  const childrenCount = React.Children.count(children);

  return (
    <div className={styles.compoundInput()}>
      {React.Children.map(children, (child, index) => {
        // this violates open close principle, but I can't find a better approach for this
        if (
          child?.type !== TextInput &&
          child?.type !== SelectBox &&
          child?.type !== NumberInput
        ) {
          let name = (child?.type as any)?.name ?? child?.type;
          if (name) name = `<${name}> component`;
          else name = `"${child}"`;
          throw `Error: Invalid child component. ${name} found on position ${index}`;
        }

        const compound =
          index === 0
            ? "left"
            : index === childrenCount - 1
            ? "right"
            : "middle";

        return React.cloneElement(child, { compound, accent: false, size });
      })}
    </div>
  );
}

interface Props {
  children: Array<ReactElement>;
  size?: "medium" | "small";
}
