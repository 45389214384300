import { EmbedModes } from "./EmbedModes";

export const EmbedModesTemplatePreviews = {
  [EmbedModes.STANDARD]: `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <title>{{title}}</title>
    <style>
    body {
      margin: 0;
    }
    body * {
      box-sizing: border-box;
    }
    .content {
      display: flex;
      flex-direction: column;
      height: 510px;
      padding: 0 8px;
    }
    .block {
      flex: 0 0 20px;
      background-color: #f0f0f0;
      border-radius: 4px;
      margin: 8px 0;
    }
    .preview {
      display: flex;
      justify-content: center;
      flex: 1;
    }
    </style>
  </head>
  <body>
    <div class="content">
      <div class="block"></div>
      <div class="preview">{{{code}}}</div>
      <div class="block"></div>
    </div>
  </body>
</html>`,

  [EmbedModes.FULL_PAGE]: `{{{code}}}`,
  [EmbedModes.POPUP]: `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <title>{{title}}</title>
    <style>
    .block {
      height: 20px;
      background-color: #f0f0f0;
      border-radius: 4px;
      margin: 16px 0;
    }
    .preview {
      text-align: center;
    }
    </style>
  </head>
  <body>
  <div class="block"></div>
  <div class="block"></div>
  <div class="block"></div>
  <div class="block"></div>
  <div class="block"></div>
  <div class="preview">{{{code}}}</div>
  </body>
</html>`,
  [EmbedModes.SLIDER]: `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <title>{{title}}</title>
    <style>
    .block {
      height: 20px;
      background-color: #f0f0f0;
      border-radius: 4px;
      margin: 16px 0;
    }
    .preview {
      text-align: center;
    }
    </style>
  </head>
  <body>
  <div class="block"></div>
  <div class="block"></div>
  <div class="block"></div>
  <div class="block"></div>
  <div class="block"></div>
  <div class="preview">{{{code}}}</div>
  </body>
</html>`,
  [EmbedModes.POPOVER]: `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <title>{{title}}</title>
    <style>
    .block {
      height: 20px;
      background-color: #f0f0f0;
      border-radius: 4px;
      margin: 16px 0;
    }
    .preview {
      text-align: center;
    }
    </style>
  </head>
  <body>
  <div class="block"></div>
  <div class="block"></div>
  <div class="block"></div>
  <div class="block"></div>
  <div class="block"></div>
  <div class="preview">{{{code}}}</div>
  </body>
</html>`,

  [EmbedModes.SIDE_TAB]: `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <title>{{title}}</title>
    <style>
    .block {
      height: 20px;
      background-color: #f0f0f0;
      border-radius: 4px;
      margin: 16px 0;
    }
    .preview {
      text-align: center;
    }
    </style>
  </head>
  <body>
  <div class="block"></div>
  <div class="block"></div>
  <div class="block"></div>
  <div class="block"></div>
  <div class="block"></div>
  <div class="preview">{{{code}}}</div>
  <div class="block"></div>
  <div class="block"></div>
  <div class="block"></div>
  <div class="block"></div>
  </body>
</html>`
};
