import moment from "moment";
import Booking_StartDateTime from "./Booking__StartDateTime";

const CurrentTimeAndBooking__MinutesUntilBookingStarts = (
  currentTime,
  booking
) => {
  var startDateTime = Booking_StartDateTime(booking);
  var minutesRemainingUntilBookedClassStarts = moment
    .duration(moment(startDateTime) - moment(currentTime))
    .asMinutes();

  return minutesRemainingUntilBookedClassStarts < 0
    ? 0
    : minutesRemainingUntilBookedClassStarts;
};

export default CurrentTimeAndBooking__MinutesUntilBookingStarts;
