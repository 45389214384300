import React from "react";

import Icon from "../Icon";
import { Camera } from "../Icons";
import { ProgressBar } from "../ProgressBar";

import styles from "./image-placeholder.module.less";

export function ImagePlaceholder(props) {
  const { icon = Camera, label, progress, color } = props;
  return (
    <div
      className={`${styles["image-placeholder"]} ${
        color ? styles[`image-placeholder--color-${color}`] : ""
      } p-4`}
    >
      <Icon
        className={styles["image-placeholder__icon"]}
        icon={icon}
        height={62}
      />
      <div className="mt-3">
        {progress === undefined ? (
          <div className={styles["image-placeholder__label"]}>{label}</div>
        ) : (
          <div className={styles["image-placeholder__progress-bar"]}>
            <ProgressBar progress={progress} />
          </div>
        )}
      </div>
    </div>
  );
}

ImagePlaceholder.propTypes = {};
