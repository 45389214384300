import React from "react";
import { styles } from "./TableCell.styles";
import { useVisibleColumn } from "./useVisibleColumn";

export function TableCell({
  align = "left",
  children,
  className,
  ...props
}: React.PropsWithChildren<Props>) {
  const visible = useVisibleColumn();
  if (!visible) return null;
  return (
    <td
      className={styles.tableCell({
        className,
        css: {
          "--table-cell-align": align
        }
      })}
      {...props}
    >
      {children}
    </td>
  );
}

interface Props
  extends Omit<
    React.HTMLProps<HTMLTableCellElement>,
    "dangerouslySetInnerHTML"
  > {
  align?: "left" | "center" | "right";
}
