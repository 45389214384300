import React, { useState } from "react";

import { bem } from "../../configs/bem";

import { AccordionBoxContext } from "./AccordionBoxContext";

import { AccordionBoxContainer } from "./AccordionBoxContainer";
import { AccordionBoxToggle } from "./AccordionBoxToggle";

const cn = bem("accordion-box");
export function AccordionBox(props) {
  const { children, className, onToggle } = props;

  const [open, setOpen] = useState(props.open || false);

  return (
    <AccordionBoxContext.Provider value={{ open, setOpen, onToggle }}>
      <div className={cn(null, [className])}>{children}</div>
    </AccordionBoxContext.Provider>
  );
}
AccordionBox.Container = AccordionBoxContainer;
AccordionBox.Toggle = AccordionBoxToggle;
