import React from "react";
import { Label } from "../../components/Label";
import { TextArea } from "../../inputs/TextArea";
import { SelectBox, Option } from "../../inputs/SelectBox";
import { RadioInput } from "../../common/inputs/RadioInput";
import { RefundMethodsLabels } from "@obby/constants";
import { UserCancelBookingFormSchema } from "./UserCancelBookingForm.schema";

export function UserCancelBookingForm(props) {
  const {
    values,
    errors,
    touched,
    onChange,
    onBlur,
    refundMethods = []
  } = props;
  const { reason, message, refundMethod } = values;
  return (
    <div className="cancel-booking-form">
      <Label label="Select the reason" error={touched?.reason && errors.reason}>
        <SelectBox
          name="reason"
          value={reason}
          onChange={onChange}
          onBlur={onBlur}
        >
          {[
            "I booked this class by mistake",
            "I can no longer attend this date (or any others)",
            "I found this class cheaper elsewhere",
            "I found another class that suits me better",
            "I want to bring a friend, but there is no space",
            "My class was cancelled by the Teacher"
          ].map(option => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </SelectBox>
      </Label>

      <Label label="Message" error={touched?.message && errors.message}>
        <TextArea
          name="message"
          accent="brightTeal"
          value={message}
          onChange={onChange}
          onBlur={onBlur}
        />
      </Label>

      {refundMethods.length > 0 && (
        <Label
          label="How would you like to be paid back?"
          error={touched?.refundMethod && errors.refundMethod}
        >
          <RadioInput
            value={refundMethod}
            name="refundMethod"
            onChange={onChange}
            onBlur={onBlur}
          >
            {refundMethods.map(refundMethod => (
              <RadioInput.Option key={refundMethod} value={refundMethod}>
                {RefundMethodsLabels[refundMethod]}
              </RadioInput.Option>
            ))}
          </RadioInput>
        </Label>
      )}
    </div>
  );
}
UserCancelBookingForm.Schema = UserCancelBookingFormSchema;
