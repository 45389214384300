import { css, withBem } from "../../sitches.config";

export const styles = withBem("discount-badge", {
  badge: css({
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    color: "$ebonyClay",
    textTransform: "uppercase",
    fontFamily: "$foundersGrotesk",
    lineHeight: 1,
    variants: {
      size: {
        small: {
          width: "100px",
          height: "100px"
        },
        medium: {
          width: "125px",
          height: "125px"
        },
        large: {
          width: "150px",
          height: "150px"
        }
      }
    }
  }),

  wrapper: css({
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "$hummingBird",
    borderRadius: "50%",
    border: "solid 6px $ebonyClay",
    color: "$ebonyClay",
    textTransform: "uppercase",
    fontFamily: "$foundersGrotesk",
    lineHeight: 1,
    width: "100%",
    height: "100%",
    variants: {
      size: {
        small: {
          fontSize: "16px"
        },
        medium: {
          fontSize: "18px"
        },
        large: {
          fontSize: "20px"
        }
      }
    }
  }),

  value: css({
    fontWeight: "bold",
    variants: {
      size: {
        small: {
          fontSize: "38px"
        },
        medium: {
          fontSize: "50px"
        },
        large: {
          fontSize: "64px"
        }
      }
    }
  })
});
