import React from "react";
import SocialLogin from "react-social-login";
import Icon from "../Icon";
import styles from "./SocialSignInButton.module.less";

function SocialSignInButton(props) {
  const {
    triggerLogin,
    text,
    icon,
    width = "max",
    size = "medium",
    disabled = false,
    className = "",
    onClick = () => {}
  } = props;
  return (
    <button
      className={`
			${styles[`social-sign-in-button`]}
      ${styles[`social-sign-in-button--width-${width}`]}
      ${styles[`social-sign-in-button--${size}`]}
	    ${disabled ? styles[`social-sign-in-button--disabled`] : ""}
	    ${className}
	  `}
      disabled={disabled}
      type="button"
      onClick={
        props.disabled
          ? undefined
          : e => {
              triggerLogin(e);
              onClick(e);
            }
      }
    >
      <span className={styles["social-sign-in-button__text"]}>
        {icon && (
          <span className={styles["social-sign-in-button__icon"]}>
            <Icon icon={icon} height={18} />
          </span>
        )}
        {text}
      </span>
    </button>
  );
}

export default SocialLogin(SocialSignInButton);
