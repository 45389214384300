import React from "react";
import { BookingOptionsSectionRow } from "./BookingOptionsSectionRow";
import { BookingOptions } from "../../lists/BookingOptions";
import { styles } from "./BookingOptionsSection.styles";
import { useAppText } from "../../containers";

export function BookingOptionsSection({
  age,
  displayAge = true,
  course,
  isDBSChecked,
  onAppointmentSelect,
  onDropInSelect,
  onBundleSelect,
  onGroupScheduleSelect,
  onFullTermSelect,
  onGiftCardSelect,
  onGroupSelect,
  onProRataTermSelect,
  onRegularSelect,
  onTrialSelect,
  terms = true
}: Props) {
  const { __ } = useAppText();

  return (
    <div className="booking-options-section">
      <BookingOptions
        course={course}
        onAppointmentSelect={onAppointmentSelect}
        onDropInSelect={onDropInSelect}
        onBundleSelect={onBundleSelect}
        onGroupScheduleSelect={onGroupScheduleSelect}
        onFullTermSelect={onFullTermSelect}
        onGiftCardSelect={onGiftCardSelect}
        onGroupSelect={onGroupSelect}
        onProRataTermSelect={onProRataTermSelect}
        onRegularSelect={onRegularSelect}
        onTrialSelect={onTrialSelect}
      />
      <div className={styles.rows()}>
        {terms && (
          <BookingOptionsSectionRow
            heading="Obby Guarantee"
            sticker="https://res.cloudinary.com/obby/image/upload/v1586168840/Misc%20Stickers/guarantee.svg"
          >
            We ensure quality. If your class doesn't reach your expectations,
            we'll give your money back.
          </BookingOptionsSectionRow>
        )}
        {displayAge && (
          <BookingOptionsSectionRow heading="Age Requirement">
            {age || __("This class is suitable for all ages")}
            {(age === undefined || age < 16) && (
              <div>
                {isDBSChecked
                  ? __(
                      "The teacher of this class has confirmed they have an up to date DBS check. If required, please get in touch for a copy of this certificate."
                    )
                  : __(
                      "All students under the age of 16 must be accompanied by a parent/guardian."
                    )}
              </div>
            )}
          </BookingOptionsSectionRow>
        )}
        {terms && (
          <BookingOptionsSectionRow heading="Flexible cancellation policy">
            Any class can be cancelled and fully refunded within 24 hours of
            purchase.{" "}
            <a
              className={styles.link()}
              target="_blank"
              href="https://obby.co.uk/terms/booking-terms"
            >
              See cancellation policy
            </a>
            .
          </BookingOptionsSectionRow>
        )}
      </div>
    </div>
  );
}

interface Props {
  age?: number;
  displayAge?: boolean;
  course: any;
  isDBSChecked?: boolean;
  onAppointmentSelect?: () => void;
  onDropInSelect?: () => void;
  onBundleSelect?: (bundle: any) => void;
  onFullTermSelect?: () => void;
  onGiftCardSelect?: () => void;
  onGroupScheduleSelect?: () => void;
  onGroupSelect?: () => void;
  onProRataTermSelect?: () => void;
  onRegularSelect?: () => void;
  onTrialSelect?: () => void;
  terms?: boolean;
}
