import React from "react";
import cookie from "js-cookie";
import Icon from "../Icon";
import { Cross } from "../Icons";
import styles from "./CookieToast.module.less";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasBeenAcknowledged: true,
      hasScrolled: false
    };
    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    this.setState({
      hasBeenAcknowledged: this.getHasBeenAcknowledgedFromCookie()
    });
    window.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  onScroll() {
    this.setState({
      hasScrolled: true
    });
    window.removeEventListener("scroll", this.onScroll);
  }
  dismiss() {
    this.setState({
      hasBeenAcknowledged: true
    });
    cookie.set(this.props.cookieName, "isClosed");
  }

  getHasBeenAcknowledgedFromCookie() {
    return cookie.get(this.props.cookieName) !== undefined;
  }

  render() {
    const { color = "ebonyClay", text } = this.props;
    const { hasBeenAcknowledged, hasScrolled } = this.state;
    return (
      <div
        className={`${styles["cookie-toast"]} ${
          styles[`cookie-toast--${color}`]
        } ${
          hasBeenAcknowledged || !hasScrolled
            ? styles["cookie-toast--hidden"]
            : ""
        }`}
      >
        <div
          className={`${styles["cookie-toast__text"]} ${
            styles[`cookie-toast__text--${color}`]
          }`}
        >
          {text}
        </div>
        <div
          className={`${styles["cookie-toast__button"]} ${
            styles[`cookie-toast__button--${color}`]
          }`}
          onClick={this.dismiss.bind(this)}
        >
          <Icon icon={Cross} height="16" />
        </div>
      </div>
    );
  }
}
