import React, { useContext } from "react";

import { MenuNavContext } from "./MenuNavContext";

import { MenuNavItemWrapper } from "./MenuNavItemWrapper";
import styles from "./menu-nav-button.module.less";

export function MenuNavButton(props) {
  const { text, href, onClick } = props;
  const { textColorHover } = useContext(MenuNavContext);
  return (
    <MenuNavItemWrapper href={href} padding="pt-4 pt-lg-0">
      <span
        className={`${styles["menu-nav-button"]} ${
          styles[`menu-nav-button--color-${textColorHover}`]
        } px-4 py-2 py-lg-1 w-100 w-lg-auto`}
        onClick={onClick}
      >
        {text}
      </span>
    </MenuNavItemWrapper>
  );
}
