import * as Yup from "yup";

import { PricingOptionsStepSchema } from "./PricingOptionStep/PricingOptionsStep.schema";
import { PersonalDetailsStepSchema } from "./PersonalDetailsStep/PersonalDetailsStep.schema";

export function AppointmentScheduleCheckoutSchema() {
  return Yup.object({
    pricingOptions: PricingOptionsStepSchema(),
    personalDetails: PersonalDetailsStepSchema(),
    discountCode: Yup.string()
      .when(
        "personalDetails.guests.0.email",
        (email: string, schema: Yup.AnySchema) => {
          if (
            !Yup.string()
              .email()
              .required()
              .isValidSync(email)
          )
            return schema.test(
              "email-address-for-discount",
              "Fill in email address first in Personal details step",
              () => false
            );

          return schema;
        }
      )
      .default("")
  }).required();
}
export type Fields = Yup.InferType<
  ReturnType<typeof AppointmentScheduleCheckoutSchema>
>;
