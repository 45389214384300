import { Timezones } from "@obby/constants";
import { Session__DisplayTime } from "./Session__DisplayTime";
import { Session__DisplayOffsetName } from "./Session__DisplayOffsetName";
import { Session__DisplayDate } from "./Session__DisplayDate";
import { Timezone__IsSameAsLocal } from "./Timezone__IsSameAsLocal";

export function Session__DisplayTimeOffset(
  session: any,
  timezone: string = Timezones.EUROPE_LONDON,
  forceTimezone: boolean = false
) {
  let date = Session__DisplayTime(session, timezone, forceTimezone);
  if (
    (!session.isOnline && !Timezone__IsSameAsLocal(timezone)) ||
    forceTimezone
  )
    date += ` ${Session__DisplayOffsetName(session, timezone, forceTimezone)}`;

  return date;
}
