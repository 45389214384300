import React, { useContext } from "react";
import { TableCell } from "./TableCell";
import { TableContext } from "./TableContext";
import { styles } from "./TableEmpty.styles";
import { Block } from "@obby/components";

export function TableEmpty({
  align = "center",
  children,
  className,
  ...props
}: React.PropsWithChildren<Props>) {
  const { visibleColumns, numberOfRows } = useContext(TableContext);
  if (numberOfRows !== 0) return null;
  return (
    <tbody className={styles.tableEmpty()}>
      <tr>
        <TableCell
          align="center"
          colSpan={visibleColumns.length}
          className={styles.cell({ className })}
          {...props}
        >
          <Block margin={3}>{children}</Block>
        </TableCell>
      </tr>
    </tbody>
  );
}

interface Props
  extends Omit<
    React.HTMLProps<HTMLTableCellElement>,
    "dangerouslySetInnerHTML"
  > {
  align?: "left" | "center" | "right";
}
