import moment from "moment-timezone";
import { Timezones } from "@obby/constants";

export function Session__FormattedStartDateEndTime(
  session,
  timezone = Timezones.EUROPE_LONDON
) {
  const startDateTime = moment(session.startDateTime).tz(timezone);
  const endDateTime = startDateTime.clone().add(session.duration, "minutes");
  return `${startDateTime.format(
    "dddd D MMMM YYYY h:mma"
  )}-${endDateTime.format("h:mma")}`;
}
