import moment from "moment";
import { filter } from "ramda";

import isFullArray from "./isFullArray";

const DateString__MomentDate = date => {
  if (date) {
    return moment(parseInt(date))
      .milliseconds(0)
      .seconds(0)
      .minutes(0)
      .hours(0);
  }
  return null;
};

const SearchQueryParams__SearchState = queryParams => {
  var date = DateString__MomentDate(queryParams.date);
  var dateString = date ? date.valueOf() : undefined;
  var preFilterSearchState = {
    query: queryParams.q,
    date,
    dateString,
    currentPage: queryParams.p,
    postcode: queryParams.postCode,
    radius: queryParams.radius,
    isHidingFlexitime: queryParams.hideFlexi,
    peopleCount: queryParams.people,
    selectedClassFormat: queryParams.classFormat
  };

  return filter(isFullArray, preFilterSearchState);
};

export default SearchQueryParams__SearchState;
