import React from "react";

import Checkbox from "../Checkbox";
import Link from "../Link";
import styles from "./PrivateEventFilterBody.module.less";

export const PrivateEventFilterBody = ({
  onChange,
  hidePrivate,
  size = "small"
}) => {
  const onValueChange = value => {
    onChange({
      hidePrivate,
      ...value
    });
  };
  return (
    <div className={styles["students-filter-body"]}>
      <div>
        <Checkbox
          isChecked={hidePrivate}
          label=" Hide “Private-only” classes"
          onClick={() => onValueChange({ hidePrivate: !hidePrivate })}
          size={size}
        />
      </div>

      <Link
        className={styles["students-filter-body__link"]}
        href="https://obby.helpscoutdocs.com/article/192-what-is-a-flexitime-class"
        target="_blank"
      >
        Learn more
      </Link>
    </div>
  );
};
