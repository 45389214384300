import React from "react";
import {
  String__TruncatedString,
  Number__Currency,
  Discount__DiscountedPrice,
  CurrencyISO__CurrencySymbol
} from "@obby/lib";
import styles from "./ProductCard.module.less";
import HighlightPrice from "../HighlightPrice";
import { CloudinaryImage } from "../CloudinaryImage";
import RatingSummary from "../RatingSummary";

export default function ProductCard({
  thumbnail,
  title,
  price,
  currency,
  stock,
  shortDescription,
  rating,
  discount = false,
  numberOfReviews = 0,
  samePriceAlways = true,
  titleLength = 38,
  onClick = () => {}
}) {
  return (
    <div className={styles["product-card"]} onClick={onClick}>
      <div className={styles["product-card__thumbnail"]}>
        <CloudinaryImage
          className={styles["product-card__thumbnail-image"]}
          src={thumbnail}
          width={450}
          height={300}
        />
      </div>
      {/* DETAILS */}
      <div className={styles["product-card__content"]}>
        {/* TITLE */}
        <span className={styles["product-card__title"]}>
          {" "}
          {String__TruncatedString({ length: titleLength }, title)}
        </span>
        {/* Short Description*/}
        <div className={styles["product-card__description"]}>
          {shortDescription}
        </div>
      </div>
      <div className={styles["product-card__footer"]}>
        {/* PRICE */}

        <div className={styles[`product-card__price`]}>
          {discount && (
            <HighlightPrice
              price={Discount__DiscountedPrice(discount, price)}
              currency={currency}
            />
          )}
          <span
            className={
              discount ? styles["product-card__price--with-discount"] : ""
            }
          >
            {!samePriceAlways && "from "}
            {Number__Currency(price, CurrencyISO__CurrencySymbol(currency))}
          </span>{" "}
        </div>

        {numberOfReviews > 0 && (
          <RatingSummary
            numberOfReviews={numberOfReviews}
            rating={rating}
            starColor="emeraldish"
            starSize="small"
            short
          />
        )}
        {stock !== undefined && (
          <div className={styles[`product-card__stock`]}>
            {stock === 0 ? "Out of stock" : ""}
            {stock > 0 && stock < 5 ? "Low stock" : ""}
            {stock >= 5 ? "In stock" : ""}
          </div>
        )}
      </div>
    </div>
  );
}
ProductCard.propTypes = {};
