import React, { useMemo, useState, ReactNode } from "react";
import ThemedMarkdownText from "../ThemedMarkdownText";
import styles from "./expandable-text.module.less";
//@ts-ignore
import { Markdown__TruncatedMarkdown } from "@obby/lib";

interface ExpandableTextProps {
  children: ReactNode;
  maxWords?: number;
  readMoreTextColor?: string;
  className?: string;
  readMoreText?: string;
  readLessText?: string;
  onExpand?: (expanded: any) => void;
}

export function ExpandableText({
  children: markdownText,
  maxWords = 100,
  readMoreText = "Read more",
  readLessText = "Read less",
  readMoreTextColor,
  className = "",
  onExpand = expanded => {}
}: ExpandableTextProps) {
  const collapsedMarkdown = useMemo(() => {
    return Markdown__TruncatedMarkdown(markdownText, maxWords);
  }, [markdownText, maxWords]);

  const isExpandable = collapsedMarkdown !== markdownText;
  const [isCollapsed, setCollapsed] = useState(isExpandable);

  function onReadMore() {
    setCollapsed(!isCollapsed);
    onExpand(isCollapsed);
  }

  return (
    <div className={`${styles["expandable-text"]} ${className}`}>
      <div
        className={`${styles["expandable-text__text"]} ${
          isCollapsed ? styles["expandable-text__text--collapsed"] : ""
        }`}
      >
        <ThemedMarkdownText
          text={isCollapsed ? collapsedMarkdown + "\u2026" : markdownText}
          classNames={
            className
              ? {
                  link: `${className}-link`,
                  paragraph: `${className}-paragraph`,
                  list: `${className}-list`,
                  listItem: `${className}-list-item`
                }
              : {}
          }
        />
      </div>
      {isExpandable && (
        <span
          className={styles["expandable-text__read-more"]}
          onClick={onReadMore}
          style={{ color: readMoreTextColor }}
        >
          {isCollapsed ? readMoreText : readLessText}
        </span>
      )}
    </div>
  );
}
