import { css } from "../../sitches.config";

export const styles = {
  coursePageFooterBar: css({
    backgroundColor: "$gallery",

    ".course-page-footer-bar__menu-list > * + *": {
      "@media (max-width: 576px)": {
        marginLeft: "14px"
      }
    },

    ".course-page-footer-bar.mobile": {
      display: "none",
      "@media (max-width: 576px)": {
        display: "flex"
      },
      ".course-page-footer-bar__menu-list": {
        display: "flex",
        justifyContent: "space-evenly"
      },
      ".course-page-footer-bar__book-wrapper": {
        display: "none"
      }
    },

    ".course-page-footer-bar__action + .course-page-footer-bar__action": {
      marginLeft: "10px",
      textDecoration: "underline"
    },

    "@sm": {
      background: "white"
    }
  }),

  wrapper: css({
    padding: "10px 15px",
    width: "100%",

    "@md": {
      display: "flex",
      flexDirection: "row",
      justifyItems: "center",
      justifyContent: "space-between"
    }
  }),

  menuList: css({
    display: "none",

    "@md": {
      display: "flex"
    },
    ".course-page-footer-bar__link:link": {
      "@media (max-width: 576px)": {
        marginRight: "0"
      }
    }
  }),

  link: css({
    "&:link,&:visited,&:hover,&:active": {
      fontWeight: "500",
      lineHeight: "110%",
      marginRight: "40px",
      textDecoration: "none",
      letterSpacing: "0.03em",
      fontSize: "$mParagraph",
      color: "$robbinsEggBlue",
      textTransform: "capitalize",
      alignSelf: "center",
      fontFamily: "$foundersGrotesk",

      "@media (max-width: 425px)": {
        fontSize: "15px"
      },

      "&.active": {
        color: "black"
      }
    }
  }),

  bookWrapper: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  }),

  actions: css({
    display: "flex",

    "@media (max-width: 576px)": {
      width: "100%",
      justifyContent: "space-between"
    },
    ".primary-button": {
      "@media (max-width: 576px)": {
        alignSelf: "flex-end"
      }
    }
  }),

  reviews: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginLeft: "10px",

    span: {
      fontSize: "18px",
      fontFamily: "$foundersGrotesk",
      "@media (max-width: 425px)": {
        fontSize: "15px"
      }
    },

    "@sm": {
      display: "none"
    },

    variants: {
      noReviews: {
        true: {
          justifyContent: "center",
          span: {
            fontSize: "22px !important"
          }
        }
      }
    }
  }),

  action: css({
    width: "185px",

    "&.secondary-button": {
      display: "none",

      "@sm": {
        display: "initial"
      }
    }
  }),

  price: css({
    display: "inline-block",

    variants: {
      withDiscount: {
        true: {
          "&, span": {
            textDecoration: "line-through"
          }
        }
      }
    }
  })
};
