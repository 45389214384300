import React from "react";

import { styles } from "./AspectRatio.styles";

export function AspectRatio({
  height,
  width,
  children,
  className
}: React.PropsWithChildren<Props>) {
  const paddingBottom = width
    ? Math.round((height * 10000) / width) / 100
    : height;
  return (
    <div
      className={styles.component({
        className: `aspect-ratio ${className}`,
        css: { paddingBottom: `${paddingBottom}%` }
      })}
    >
      <div className={styles.container()}>{children}</div>
    </div>
  );
}

interface Props {
  width?: number;
  height: number;
  className?: string;
}
