import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import styles from "./location-filter-body.module.less";

import { AsyncSelectBox } from "../../inputs";

export default function KeywordFilterBody({
  tagsSelected = [],
  onChange,
  onSearchTag,
  optionsTags = []
}) {
  async function onLoadOptions(value) {
    const tags = await onSearchTag(value);
    return tags;
  }

  return (
    <div className={styles["location-filter-body"]}>
      <div className={`${styles["location-filter-body__filter"]} d-lg-none`}>
        <AsyncSelectBox
          onChange={onChange}
          value={tagsSelected}
          name="keywords"
          defaultOptions={optionsTags}
          onLoad={onLoadOptions}
          parseOption={todo => todo}
          multiple
          clear
          menuPlacement="top"
        />
      </div>
      <div
        className={`${styles["location-filter-body__filter"]} d-none d-lg-block`}
      >
        <AsyncSelectBox
          onChange={onChange}
          value={tagsSelected}
          name="keywords"
          defaultOptions={optionsTags}
          onLoad={onLoadOptions}
          parseOption={todo => todo}
          multiple
          clear
        />
      </div>
    </div>
  );
}
