import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon";
import styles from "./PillButton.module.less";

const PillButton = ({
  width,
  size,
  color,
  disabled,
  filled,
  onClick,
  icon,
  textMode,
  isSelected,
  textColor,
  text,
  className
}) => (
  <button
    className={`
    ${styles[`pill-button`]}
    ${styles[`pill-button--width-${width || "max"}`]}
    ${styles[`pill-button--${size || "large"}`]}
      ${disabled && ` ${styles["pill-button--disabled"]}`}
      ${isSelected && ` ${styles["pill-button--selected"]}`}
      ${className}
    `}
    type="button"
    onClick={event => {
      if (!disabled) {
        onClick(event);
      }
    }}
  >
    <span>{text}</span>
    {icon && (
      <span
        className={`${styles[`pill-button__icon`]} ${isSelected &&
          styles["pill-button__icon--selected"]}`}
      >
        <Icon icon={icon} width={size && size === "small" ? 12 : 14} />
      </span>
    )}
  </button>
);

PillButton.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.instanceOf(Object),
  text: PropTypes.any,
  className: PropTypes.string,
  isSelected: PropTypes.bool
};

export default PillButton;
