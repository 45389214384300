import React, { useState, useEffect } from "react";
import { TableContext } from "./TableContext";
import { useVisibleColumns } from "./useVisibleColumns";

export function TableProvider({
  children,
  onSort,
  onSortClear,
  ...props
}: React.PropsWithChildren<Props>) {
  const [visibleColumns, setVisibleColumn] = useVisibleColumns();
  const [numberOfRows, setNumberOfRows] = useState<number | undefined>(
    undefined
  );
  const [{ orderBy, order }, setOrder] = useState({
    orderBy: props.orderBy,
    order: props.order
  });
  useEffect(() => {
    setOrder({ orderBy: props.orderBy, order: props.order });
  }, [props.orderBy, props.order]);

  function onOrderClear() {
    setOrder({ orderBy: undefined, order: undefined });
    onSortClear && onSortClear();
  }

  function onOrderChange(orderBy: string, order: "asc" | "desc") {
    setOrder({ orderBy, order });
    onSort && onSort(orderBy, order);
  }
  return (
    <TableContext.Provider
      value={{
        numberOfRows,
        onOrderChange,
        onOrderClear: onSortClear ? onOrderClear : undefined,
        orderBy,
        order,
        setVisibleColumn,
        setNumberOfRows,
        visibleColumns
      }}
    >
      {children}
    </TableContext.Provider>
  );
}

interface Props {
  orderBy?: string | undefined;
  order?: "asc" | "desc" | undefined;
  onSort?: (orderBy: string, order: "asc" | "desc") => void | undefined;
  onSortClear?: () => void | undefined;
}
