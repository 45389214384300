import { css } from "../../sitches.config";

export const styles = {
  virtualClassRoom: css({
    background: "$pampas",
    paddingTop: "47px",
    variants: {
      headerPlaceholder: {
        true: {
          marginTop: "86px"
        }
      }
    }
  }),

  container: css({
    display: "flex",
    maxWidth: "1200px",
    margin: "0 auto",

    "@lg": {
      flexDirection: "row",
      padding: "0 31px"
    },

    "@xl": {
      padding: "0 151px",
      maxWidth: "1700px"
    }
  }),

  titleWrapper: css({
    display: "flex",
    paddingBottom: "40px",
    flexDirection: "column"
  }),

  title: css({
    fontSize: "$42",
    marginTop: "$15",
    lineHeight: "90%",
    color: "$ebonyClay",
    fontWeight: "600",
    textTransform: "uppercase",
    fontFamily: "$foundersGroteskCondensed"
  }),

  titleColored: css({
    lineHeight: "80%",
    color: "$royalBlue",
    fontWeight: "500",
    letterSpacing: "0.03em",
    fontSize: "$mParagraph",
    fontFamily: "$foundersGrotesk"
  }),

  bookingReceiptWrapper: css({
    paddingBottom: "$28",

    "@md": {
      paddingBottom: "$20"
    },

    "@lg": {
      marginTop: "9.2rem"
    }
  }),

  avatarWrapper: css({
    display: "none",

    "@md": {
      display: "block",
      paddingTop: "$26",
      borderTop: "1px solid rgba(40, 40, 60, 0.1)"
    }
  }),

  tabsWrapper: css({
    variants: {
      variant: {
        mobile: {
          display: "block",
          "@lg": {
            display: "none"
          }
        }
      },

      isOnline: {
        true: {
          display: "none",
          "@lg": {
            display: "block"
          }
        }
      }
    }
  }),

  sideBar: css({
    display: "none",

    "@lg": {
      display: "block"
    }
  })
};
