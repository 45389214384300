import moment from "moment-timezone";

export function Time__Diff(
  time1: string,
  time2: string,
  unitOfTime: UnitOfTime,
  precise?: boolean
) {
  return moment(time1, "HH:mm").diff(
    moment(time2, "HH:mm"),
    unitOfTime,
    precise
  );
}

type UnitOfTime =
  | "hour"
  | "hours"
  | "h"
  | "minute"
  | "minutes"
  | "m"
  | "second"
  | "seconds"
  | "s"
  | "millisecond"
  | "milliseconds"
  | "ms";
