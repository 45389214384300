import React, {
  MouseEvent,
  MouseEventHandler,
  MutableRefObject,
  ReactNode
} from "react";

import { styles } from "./RoundedButton.styles";
import Icon from "../../components/Icon";
import LoadingIcon from "../../components/LoadingIcon";
import { Lock } from "../../components/Icons";
import { ResponsiveVariant } from "../../sitches.config";
import { FaIcon } from "../../icons/FaIcon";

export function RoundedButton({
  backgroundColorHex,
  borderColorHex = backgroundColorHex,
  className = "",
  color = "callToAction",
  colorHex,
  disabled = false,
  elem = "div",
  elemProps = {},
  faIcon,
  icon,
  innerRef,
  isLoading = false,
  locked = false,
  onClick,
  onMouseOver,
  outline = false,
  size = "large",
  square = false,
  tag,
  text,
  noPadding = square && !text,
  uppercase = false,
  width = "max"
}: RoundedButton.Props) {
  if (locked) {
    color = "locked";
    icon = Lock;
  }

  function onElementClick(e: MouseEvent) {
    if (disabled) e.preventDefault();
    else if (onClick) {
      onClick(e);
    }
  }

  const HtmlElement = elem;
  return (
    <HtmlElement
      className={styles.component({
        className: `rounded-button ${className}`,
        color,
        disabled,
        noPadding,
        outline,
        size,
        square,
        width,
        uppercase
      })}
      ref={innerRef}
      disabled={disabled}
      onClick={onElementClick}
      onMouseOver={onMouseOver}
      style={{
        borderColor: borderColorHex,
        color: colorHex,
        backgroundColor: backgroundColorHex
      }}
      {...elemProps}
    >
      {faIcon && <FaIcon icon={faIcon} />}
      {icon && <Icon className={styles.icon({ size })} icon={icon} />}
      {isLoading && (
        <span className={styles.loadingWrapper()}>
          <LoadingIcon width="auto" size={15} currentColor />
        </span>
      )}
      {text && <span className={styles.text()}>{text}</span>}
      {tag && <span className={styles.tag()}>{tag}</span>}
    </HtmlElement>
  );
}
export namespace RoundedButton {
  export interface Props {
    backgroundColorHex?: string;
    borderColorHex?: string;
    className?: string;
    color?: Color;
    colorHex?: string;
    disabled?: boolean;
    elem?: Elem;
    elemProps?: { [attribute: string]: any };
    icon?: any;
    faIcon?: FaIcon.Icon;
    innerRef?: MutableRefObject<any>;
    isLoading?: boolean;
    locked?: boolean;
    noPadding?: boolean;
    outline?: boolean;
    onClick?: MouseEventHandler;
    onMouseOver?: MouseEventHandler;
    size?: ResponsiveVariant<Size>;
    square?: boolean;
    tag?: string;
    text?: ReactNode;
    uppercase?: boolean;
    width?: Width;
  }
  export type Color =
    | "accent"
    | "astra"
    | "azalea"
    | "brightTeal"
    | "callToAction"
    | "camelot"
    | "cruise"
    | "ebonyClay"
    | "emerald"
    | "emeraldish"
    | "gallery"
    | "hummingBird"
    | "lightGreen"
    | "locked"
    | "mercury"
    | "prussian"
    | "robbinsEggBlue"
    | "silver"
    | "silverMalibu"
    | "sun"
    | "white";
  export type Elem = "div" | "button" | "a";
  export type Size = "tiny" | "small" | "medium" | "large";
  export type Width = "max" | "auto";
}
