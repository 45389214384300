import React, { ReactElement } from "react";
// @ts-ignore
import { UTCDate__Get, UTCDate__FormattedDate } from "@obby/lib";
import { CalendarListItem } from "./CalendarListItem";
import { styles } from "./CalendarList.styles";

export function CalendarList(props: React.PropsWithChildren<{}>) {
  const { children } = props;

  let currentMonth: number;
  let groupedChildren = (React.Children.toArray(
    children
  ) as ReactElement[]).reduce<{ [index: number]: ReactElement[] }>(
    (children, child, childIndex) => {
      if (child?.type !== CalendarListItem) {
        let name = (child?.type as any)?.name ?? child?.type;
        if (name) name = `<${name}> component`;
        else name = `"${child}"`;
        throw `Error: Every CalendarList child must be a CalendarList.Item component. ${name} found on position ${childIndex}`;
      }

      const { startDateTime, timezone } = child.props;

      const week = UTCDate__Get(startDateTime, timezone, "isoWeek");
      const year = UTCDate__Get(startDateTime, timezone, "isoWeekYear");
      const index = year * 52 + week;

      if (!children[index]) children[index] = [];

      const month = UTCDate__Get(startDateTime, timezone, "month");

      if (month !== currentMonth) {
        children[index].push(
          <header className={styles.header()}>
            {UTCDate__FormattedDate(startDateTime, timezone, "MMMM YYYY")}
          </header>
        );
        currentMonth = month;
      }

      children[index].push(child);
      return children;
    },
    {}
  );

  return (
    <div className="calendar-list">
      {Object.entries(groupedChildren).map(([week, children]) => {
        return (
          <div key={week} className="my-2 mt-first-0 mb-last-0">
            {children.map((child, index) =>
              React.cloneElement(child, { key: index })
            )}
          </div>
        );
      })}
    </div>
  );
}

CalendarList.Item = CalendarListItem;
