import React from "react";
import PropTypes from "prop-types";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import { BulletPointsList } from "../../lists/BulletPointsList";
import AvatarSpeechBubbleBanner from "../AvatarSpeechBubbleBanner";
import BookingDetailCard from "../BookingDetailCard";
import styles from "./get-ready-tab.module.less";

const GetReadyTab = ({
  onContactObbyPopup,
  onSignIn,
  teacherName,
  teacherAvatar,
  speechBubbleText,
  whatYouWillLearn,
  whatYouWillNeed,
  speechTitle,
  backgroundBanner,
  onJoinClass,
  bookingReceiptCardProps,
  isOnline,
  address,
  details
}) => {
  return (
    <div className={styles["get-ready-tab"]}>
      <div className={styles["get-ready-tab__speech-bubble-wrapper"]}>
        <AvatarSpeechBubbleBanner
          personName={teacherName}
          personAvatar={teacherAvatar}
          text={speechBubbleText}
          title={speechTitle}
          backgroundBanner={backgroundBanner}
        />
      </div>
      <div className={`${styles["get-ready-tab__booking-details"]} d-md-none`}>
        <BookingDetailCard
          title="Your booking details"
          onJoinClass={onJoinClass}
          {...bookingReceiptCardProps}
          isOnline={isOnline}
          address={address}
          details={details}
        />
      </div>
      {whatYouWillLearn.length !== 0 && (
        <div className={styles["get-ready-tab__bullet-points-wrapper"]}>
          <div className="row">
            {whatYouWillLearn.length > 0 && (
              <div
                className={`${styles["get-ready-tab__list"]} col-12 col-lg-6`}
              >
                <BulletPointsList
                  title="What you will learn"
                  color="royal-blue"
                  items={whatYouWillLearn}
                />
              </div>
            )}
            {whatYouWillNeed.length > 0 && (
              <div
                className={`${styles["get-ready-tab__list"]} col-12 col-lg-6`}
              >
                <BulletPointsList
                  title="What you will need"
                  color="lavender"
                  items={whatYouWillNeed}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {onContactObbyPopup && (
        <div className={styles["get-ready-tab__single-chat-wrapper"]}>
          <div className={styles["get-ready-tab__make-account-wrapper"]}>
            <div className={styles["get-ready-tab__create-account"]}>
              <PrimaryButton
                onClick={onContactObbyPopup}
                text="Contact Obby"
                color="emeraldish"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

GetReadyTab.propTypes = {
  onContactObbyPopup: PropTypes.func,
  onSignIn: PropTypes.func,
  acquiredSkills: PropTypes.array,
  requiredMaterial: PropTypes.array,
  speechBubbleText: PropTypes.string
};

export default GetReadyTab;
