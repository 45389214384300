import { css } from "../../sitches.config";

export const styles = {
  motivationBanner: css({
    "&__point-description-link": {
      color: "$robbinsEggBlue !important",
      textDecoration: "underline !important",
      "&:hover": {
        color: "$robbinsEggBlue !important",
        textDecoration: "underline !important"
      }
    }
  }),
  container: css({
    backgroundColor: "$white",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    borderRadius: "$2",
    padding: "2.2rem 1.6rem",
    "@sm": {
      padding: "2.2rem 1.9rem"
    },
    "@md": {
      padding: "3.75rem 2.5rem",
      flexDirection: "row"
    },
    "@lg": {
      padding: "4.4rem 3.2rem"
    }
  }),
  headerWrap: css({
    display: "flex",
    flexDirection: "column",
    flex: "0 40%",
    padding: "0",

    "@md": {
      padding: "0 1.9rem 0 0"
    },

    "@lg": {
      padding: "0 3.2rem 0 0"
    }
  }),
  title: css({
    fontSize: "$28"
  }),
  titleStickerPartWrap: css({
    flexDirection: "row",
    display: "inline-flex",
    alignItems: "baseline",
    flexWrap: "wrap",
    paddingRight: "6px"
  }),
  titleStickerPart: css({
    display: "flex",
    height: "28px",
    alignSelf: "flex-end",

    "& svg": {
      height: "100%"
    },

    "@sm": {
      height: "2.5rem"
    },
    "@md": {
      height: "2.813rem"
    },

    svg: {
      height: "100%"
    }
  }),
  embeddedTitlePart: css({
    fontFamily: "$foundersGroteskCondensed",
    fontSize: "$24",
    fontWeight: "600",
    lineHeight: "1",
    textTransform: "uppercase",
    color: "$ebonyClay",
    margin: "0px",
    WebkitFontSmoothing: "antialiased",

    "@sm": {
      fontSize: "42px"
    },
    "@md": {
      fontSize: "54px"
    }
  }),
  titlePart: css({
    display: "inline-flex",
    fontFamily: "$foundersGroteskCondensed",
    fontSize: "$24",
    fontWeight: "600",
    lineHeight: "1",
    textTransform: "uppercase",
    color: "$ebonyClay",
    margin: "0px",
    WebkitFontSmoothing: "antialiased",

    "@sm": {
      fontSize: "42px"
    },
    "@md": {
      fontSize: "54px"
    }
  }),
  description: css({
    fontFamily: "$foundersGrotesk",
    lineHeight: "1.4",
    fontWeight: "400",
    paddingTop: "$24",
    color: "$ebonyClay",
    WebkitFontSmoothing: "antialiased",
    fontSize: "$smParagraph",

    "@sm": {
      fontSize: "$smParagraph"
    },
    "@md": {
      fontSize: "$lParagraph"
    }
  }),
  points: css({
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    padding: "1.9rem 0 0 0",

    "@sm": {
      display: "grid",
      gridTemplateColumns: "45% 45%",
      gridColumnGap: "10%",
      gridRowGap: "50px",
      padding: "$50 0 0 0"
    },
    "@md": {
      padding: "6px 0 0 0",
      width: "60%"
    }
  }),
  point: css({
    display: "flex",
    flex: "100%",
    flexDirection: "column",
    flex: "auto",
    padding: "0 0 1.9rem 0",

    "&:last-child": {
      padding: "0"
    },

    "@sm": {
      padding: "0",
      "&:last-child": {
        padding: "0"
      }
    }
  }),
  pointTitle: css({
    fontFamily: "$foundersGroteskCondensed",
    fontSize: "$mParagraph",
    fontWeight: "600",
    lineHeight: "1.2",
    color: "$ebonyClay",
    textTransform: "uppercase",
    WebkitFontSmoothing: "antialiased",

    "@sm": {
      fontSize: "$mParagraph"
    }
  }),
  pointDivider: css({
    width: "1.9rem",
    height: "3px",
    marginTop: "$10",
    marginBottom: "$10",
    backgroundColor: "$ebonyClay"
  }),
  pointDescription: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$smParagraph",
    fontWeight: "400",
    lineHeight: "21px",
    color: "$ebonyClay",
    WebkitFontSmoothing: "antialiased",

    "@sm": {
      fontSize: "$smParagraph"
    }
  })
};
