import { css } from "../../sitches.config";

export const styles = {
  card: css({}),
  header: css({
    color: "$ebonyClay",
    marginBottom: "$4"
  }),
  heading: css({
    fontFamily: "$foundersGroteskCondensed",
    fontWeight: "bold",
    fontSize: "22px",
    textTransform: "uppercase",
    lineHeight: 1.2,
    "@md": {
      fontSize: "$28"
    }
  }),
  subheading: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$18",
    lineHeight: 1.2
  }),
  footer: css({
    borderTop: "solid 1px $mercury",
    paddingTop: "$3",
    marginTop: "$4"
  }),

  footerLink: css({
    color: "$emeraldish",
    fontFamily: "$foundersGrotesk",
    fontSize: "$18",
    letterSpacing: "0.03em",
    textDecoration: "none"
  })
};
