import { useRef } from "react";

export function useDeferredPromise<T>() {
  const resolveRef = useRef<(value: T | PromiseLike<T>) => void>(() => {});
  const rejectRef = useRef<(reason?: any) => void>(() => {});

  function resolve(value: T) {
    resolveRef.current(value);
  }

  function reject(reason: any) {
    rejectRef.current(reason);
  }

  function defer() {
    return new Promise((resolve, reject) => {
      resolveRef.current = resolve;
      rejectRef.current = reject;
    });
  }

  return {
    defer,
    resolve,
    reject
  };
}
