import React from "react";
import { PrimaryButton } from "./../PrimaryButton";
import Icon from "../Icon";
import Icons from "../Icons";
import Image from "../Image";
import styles from "./image-and-sticker-cta.module.less";

const ImageAndStickerCTA = ({ image, tagline, onClick, text, buttonText }) => {
  return (
    <div className={styles["image-and-sticker-cta"]}>
      <div className={styles["image-and-sticker-cta__container"]}>
        <Image
          isTransparent
          preserve="height"
          className={styles["image-and-sticker-cta__image"]}
          src={image}
        />
        <div className={styles["image-and-sticker-cta__text-wrapper"]}>
          <div className={styles["image-and-sticker-cta__text"]}>
            <h2 className={styles["image-and-sticker-cta__title"]}>{text}</h2>
            <div className={styles["image-and-sticker-cta__tagline"]}>
              {tagline}
            </div>
            <div className={styles["image-and-sticker-cta__button"]}>
              <PrimaryButton onClick={onClick} text={buttonText} />
            </div>
          </div>
          <div className={styles["image-and-sticker-cta__sticker"]}>
            <Icon icon={Icons.Learn} height={60} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageAndStickerCTA;
