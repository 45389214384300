export const difficultyLevels = ["", "Beginner", "Advanced", "Intermediate"];

export const difficultyLevelOptions = [
  {
    label: "Any Level",
    value: ""
  },
  {
    label: "Beginner",
    value: "Beginner"
  },
  {
    label: "Advanced",
    value: "Advanced"
  },
  {
    label: "Intermediate",
    value: "Intermediate"
  }
];

export const difficultyLevelValues = {
  NONE: "",
  BEGGINER: "Beginner",
  ADVANCED: "Advanced",
  INTERMEDIATE: "Intermediate"
};
