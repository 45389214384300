import React, { useRef, useState } from "react";
import { TextInput } from "../TextInput";
import { bemModule } from "../../configs/bem";
import styles from "./TextInputButton.module.less";

const cn = bemModule(styles, "text-input-button");
export function TextInputButton(props) {
  const {
    accent = "alto",
    buttonText = "Apply",
    // backgroundColor,
    buttonBackgroundColor,
    buttonTextColor = "ebonyClay",
    onButtonClick = () => {},
    ...textInputProps
  } = props;

  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  function onInputButtonFocus(e) {
    e.preventDefault();
    // give focus back to input if we stole from them
    if (e.relatedTarget === inputRef.current) e.relatedTarget.focus();
    // if not just blur the button
    else e.currentTarget.blur();
  }

  function onInputButtonClick(e) {
    onButtonClick(inputRef.current.value);
  }

  return (
    <div
      className={cn({
        accent,
        "is-focused": isFocused
      })}
    >
      <TextInput
        inputRef={inputRef}
        {...textInputProps}
        accent={accent}
        className={cn("text-input")}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <button
        className={cn(
          "button",
          {
            "background-color": buttonBackgroundColor,
            color: buttonTextColor
          },
          ["px-2 py-0"]
        )}
        onClick={onInputButtonClick}
        onFocus={onInputButtonFocus}
        tabIndex={-1}
      >
        {buttonText}
      </button>
    </div>
  );
}
