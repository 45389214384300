import React from "react";

import ReviewCard from "../ReviewCard";

import { css } from "../../sitches.config";
import { Timezones } from "@obby/constants";

export const LatestReviewsSection = ({
  reviews,
  onReadMoreClick = () => {},
  maxReviews = 3,
  timezone = Timezones.EUROPE_LONDON
}) => {
  return (
    <div className="latest-reviews-section">
      {reviews.slice(0, maxReviews).map((review, index) => (
        <div
          key={index}
          className={`latest-reviews-section__review ${styles.review}`}
        >
          <ReviewCard {...review} timezone={timezone} />
        </div>
      ))}
      {reviews.length > maxReviews && (
        <span
          className={`latest-reviews-section__view-more ${styles.viewMore}`}
          onClick={onReadMoreClick}
        >
          View more reviews
        </span>
      )}
    </div>
  );
};

const styles = {
  review: css({
    marginBottom: "8px",
    "&:last-child": {
      marginBottom: "0"
    }
  }),

  viewMore: css({
    display: "inline-block",
    fontFamily: "$foundersGrotesk",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "80%",
    letterSpacing: "0.03em",
    color: "$ebonyClay",
    marginTop: "20px",
    paddingBottom: "2px",
    borderBottom: "solid 1px $emeraldish",
    cursor: "pointer"
  })
};
