import { RunningDaysLabels } from "@obby/constants";
import { Number__Currency } from "./Number_Currency";
import { CurrencyISO__CurrencySymbol } from "./CurrencyISO__CurrencySymbol";

export function Postage__Descriptor(postage, currency = "GBP") {
  return `${postage.name} - ${postage.deliveryTime.from} to ${
    postage.deliveryTime.to
  } days (${RunningDaysLabels[
    postage.runningDays
  ].toLowerCase()}) - ${Number__Currency(
    postage.price,
    CurrencyISO__CurrencySymbol(currency)
  )}`;
}
