import React, { useRef } from "react";
import { TogglePopout } from "../../modals/TogglePopout";
import * as Icons from "../../components/Icons";
import Icon from "../../components/Icon";
import { css } from "../../sitches.config";
import { PopoutMenuItem } from "./PopoutMenuItem";

interface PopoutMenuProps {
  onClose?: () => void;
  onOpen?: () => void;
  icon: any;
  children: any;
  iconHeight?: number;
  bottom?: number;
  top?: number;
  left?: number;
  right?: number;
  open?: boolean;
}

export function PopoutMenu({
  onClose,
  onOpen,
  icon,
  children,
  iconHeight = 25,
  bottom,
  top,
  left,
  right,
  open
}: PopoutMenuProps) {
  const closeRef = useRef<SVGElement | null>(null);

  function onCloseItemClick(e: React.MouseEvent<HTMLLIElement>) {
    // the ToglePopoutModel components captures every click to close him self.
    // we want to avoid this behavior when the user clicks in the close item other than the icon
    if (
      closeRef.current !== null &&
      e.target instanceof Element &&
      !closeRef.current.contains(e.target)
    ) {
      e.stopPropagation();
    }
  }

  return (
    <TogglePopout onClose={onClose} onOpen={onOpen} open={open}>
      <TogglePopout.Toogle>
        <Icon
          className={styles.icon({ className: "popout-menu__icon" })}
          icon={icon}
          height={iconHeight}
        />
      </TogglePopout.Toogle>
      <TogglePopout.Modal top={top} right={right} bottom={bottom} left={left}>
        <ul className={styles.list()}>
          <li className={styles.close()} onClick={onCloseItemClick}>
            <Icon
              className={styles.closeIcon()}
              icon={Icons.Cross}
              // @ts-ignore
              ref={closeRef}
            />
          </li>
          {children}
        </ul>
      </TogglePopout.Modal>
    </TogglePopout>
  );
}

const styles = {
  icon: css({
    cursor: "pointer"
  }),

  list: css({
    width: "100%",
    listStyle: "none",
    padding: 0,
    margin: "0 auto",
    borderRadius: 4,
    overflow: "hidden",

    "@sm": {
      width: "auto",
      minWith: 200,
      position: "relative"
    }
  }),

  close: css({
    display: "flex",
    justifyContent: "center",
    padding: 20,
    textAlign: "right",
    backgroundColor: "white",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    border: "solid $alto",
    borderWidth: "0 0 1px 0",

    "@sm": {
      position: "absolute",
      borderWidth: "0 0 1px 1px",
      padding: 5,
      borderRadius: "0 4px",
      top: 1,
      right: 1
    },

    "&:hover": {
      "@sm": {
        backgroundColor: "$pampas"
      }
    }
  }),

  closeIcon: css({
    height: 16,
    cursor: "pointer",

    "@md": {
      height: 12
    }
  })
};

PopoutMenu.Item = PopoutMenuItem;
