import React from "react";
import { bem } from "../../configs/bem";
import Icon from "../Icon";
import * as Icons from "../Icons";
import { ExpandableText } from "../ExpandableText";
import Image from "next/image";
import { styles } from "./TeamMember.styles";

const cn = bem("team-member");

interface TeamMemberProps {
  tutor?: any;
}

export function TeamMember({ tutor: { fullName, profile } }: TeamMemberProps) {
  return (
    <div className={`${cn()} ${styles.teamMember}`}>
      <div className={`${cn("avatar")}`}>
        <Image
          className={`${cn("image")} ${styles.image}`}
          alt=""
          layout="responsive"
          objectFit="cover"
          src={profile.imageUrl}
          height={360}
          width={360}
        />
      </div>
      <div className={`${cn("title")} ${styles.title}`}>
        <div className={`${cn("name")} ${styles.name}`}>{fullName}</div>
        <a
          className={`${cn("social-link")} ${styles.socialLink}`}
          href={profile.links.instagram}
          target="_blank"
        >
          <Icon
            className={`${cn("icon")}`}
            icon={Icons.Instagram}
            height={14}
          />
        </a>
      </div>
      <ExpandableText
        maxWords={10}
        className={`${cn("description")} ${styles.description}`}
      >
        {profile.description ?? ""}
      </ExpandableText>
    </div>
  );
}
