import { css } from "./../../sitches.config";

export const button = css({
  cursor: "pointer",
  variants: {
    // stroke: {
    //   false: {
    //     path: {
    //       stroke: "$white"
    //     }
    //   }
    // },
    checked: {
      true: {}
    }
  },
  compoundVariants: [
    {
      // stroke: false,
      checked: true,
      css: {
        path: {
          stroke: "$camelot"
        }
      }
    }
  ]
});
