import React, { useState, useEffect, createRef, useRef } from "react";
import { ImageUrl__ImageResizedUrl } from "@obby/lib";

import styles from "./image.module.less";

const getDimensionsFromUrl = src => {
  // src = */images/upload/w_400,h_570/*
  const dimensions = (src.match(/(w|h)_[0-9]+/g) || []).map(dimension => ({
    [dimension[0]]: parseInt(dimension.substr(2))
  }));

  if (dimensions.length > 0) {
    const { w, h } = dimensions.reduce((a, b) => ({ ...a, ...b }));
    return { width: w, height: h };
  }
  return {};
};

const Image = ({
  src,
  width,
  height,
  minWidth,
  minHeight,
  className,
  isTransparent,
  asBackground,
  preserve = "width",
  alt = "",
  scale = 0.1,
  root = null,
  ...props
}) => {
  if (!src) {
    return (
      <img
        src={src}
        className={className}
        alt="Provide an image url"
        {...props}
      />
    );
  }
  // UPDATE isCdnImage logic if local image urls no longer contain 'static/media'
  const isCdnImage = src.indexOf("static/media") === -1; // is it an image we can dynamically resize?
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isWrapperVisible, setIsWrapperVisible] = useState(false);
  const observerRef = createRef();
  const imageWrapperRef = useRef();
  const wrapperRef = createRef();
  let [xWidth, xHeight] = [width, height];

  let thumbnailUrl = src,
    imageUrl = src;

  const loadImage = () => {
    if (!isCdnImage) {
      return;
    }
    if (imageWrapperRef.current) {
      imageWrapperRef.current.innerHTML = "";
    }

    const xImage = new window.Image();
    xImage.setAttribute(
      "class",
      `${styles["image__image"]} ${
        preserve === "height" ? styles["image__image--full-height"] : ""
      }`
    );
    xImage.setAttribute("alt", alt);
    xImage.src = imageUrl;
    xImage.onload = () => {
      setHasLoaded(true);
      if (imageWrapperRef.current && asBackground) {
        imageWrapperRef.current.style.backgroundImage = `url(${xImage.src})`;
        return;
      }
      if (imageWrapperRef.current) {
        imageWrapperRef.current.innerHTML = "";
        imageWrapperRef.current.appendChild(xImage);
      }
    };
  };

  if (!xWidth && !xHeight) {
    const { width, height } = getDimensionsFromUrl(src);
    xWidth = width;
    xHeight = height;
  }

  if (isCdnImage && xWidth && xHeight) {
    thumbnailUrl = ImageUrl__ImageResizedUrl(
      {
        width: Math.floor(xWidth * scale),
        height: Math.floor(xHeight * scale)
      },
      src
    );
    // specify either width or height to preserve aspect ratio
    imageUrl = ImageUrl__ImageResizedUrl(
      {
        width: xWidth,
        height: xHeight,
        crop: "fill"
      },
      src
    );
  }

  if (isCdnImage && xWidth && !xHeight) {
    thumbnailUrl = ImageUrl__ImageResizedUrl(
      { width: Math.floor(xWidth * scale) },
      src
    );
    imageUrl = ImageUrl__ImageResizedUrl({ width: xWidth }, src);
  }

  if (isCdnImage && !xWidth && xHeight) {
    thumbnailUrl = ImageUrl__ImageResizedUrl(
      { height: Math.floor(xHeight * scale) },
      src
    );
    imageUrl = ImageUrl__ImageResizedUrl({ height: xHeight }, src);
  }

  useEffect(() => {
    const { current: wrapper } = wrapperRef;

    if ("IntersectionObserver" in window && !observerRef.current && wrapper) {
      observerRef.current = new IntersectionObserver(isWrapperVisibleCallback, {
        root,
        rootMargin: "0px"
      });
      observerRef.current.observe(wrapper);
    } else {
      loadImage();
    }

    return () => {
      observerRef.current.unobserve(wrapper);
      setHasLoaded(false);
    };
  }, [src]);

  useEffect(() => {
    if (isWrapperVisible) {
      loadImage();
    }
  }, [isWrapperVisible, src]);

  const isWrapperVisibleCallback = ([entry]) => {
    if (entry.isIntersecting) {
      setIsWrapperVisible(true);
    }
  };

  return (
    <div
      className={`
				${styles["image"]}
				${className ? className : ""}
				${hasLoaded || !isCdnImage ? styles["image--active"] : ""}
				${isTransparent ? "" : styles["image--has-background"]}
			`}
      style={{
        width,
        height
      }}
      ref={wrapperRef}
      {...props}
      key={src}
    >
      {isCdnImage && (
        <img
          className={`
						${styles["image__thumbnail"]}
						${
              (!xWidth && xHeight) || preserve === "height"
                ? styles["image__thumbnail--full-height"]
                : ""
            }
					`}
          src={thumbnailUrl}
          alt={alt}
        />
      )}
      <div
        className={`${styles["image__image-wrapper"]} ${
          isCdnImage ? styles["image__image-wrapper--abs"] : ""
        } ${
          preserve === "height"
            ? styles["image__image-wrapper--full-height"]
            : ""
        }
				${asBackground ? styles["image__image-wrapper--bg"] : ""}
				`}
        ref={imageWrapperRef}
      >
        {!isCdnImage && (
          <img
            className={styles["image__image"]}
            src={imageUrl}
            alt={alt}
            width={xWidth ? xWidth : "auto"}
            height={xHeight ? xHeight : "auto"}
            loading="lazy"
          />
        )}
      </div>
    </div>
  );
};

export default Image;
