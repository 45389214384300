import { UTCDate__Add } from "./UTCDate__Add";
import { UTCDate__IsAfter } from "./UTCDate__IsAfter";

export function TicketType__IsSessionInTimeConstraint(ticketType, session) {
  if (!ticketType.hasTimeConstraint) return true;

  let date = session.startDateTime;
  if (ticketType.timeConstraintDaysBefore)
    date = UTCDate__Add(date, -ticketType.timeConstraintDaysBefore, "days");
  if (ticketType.timeConstraintHoursBefore)
    date = UTCDate__Add(date, -ticketType.timeConstraintHoursBefore, "hours");

  return UTCDate__IsAfter(date);
}
