import React from "react";
import { styles } from "./AboutUsSection.styles";
import { ExpandableText } from "../ExpandableText";
// @ts-ignore
import { CloudinaryUrl__TransformedUrl } from "@obby/lib";

interface AboutUsSectionProps {
  imageUrl: any;
  description: string;
}

export function AboutUsSection({ imageUrl, description }: AboutUsSectionProps) {
  return (
    <div className={styles.aboutUsSection()}>
      <div
        className={styles.image({
          css: {
            backgroundImage: `url(${CloudinaryUrl__TransformedUrl(imageUrl, {
              width: 544,
              height: 544,
              crop: "fill"
            })})`
          }
        })}
      />
      <div className={styles.content()}>
        <div className={styles.contentDescription()}>
          <ExpandableText maxWords={180}>{description}</ExpandableText>
        </div>
      </div>
    </div>
  );
}
