import React, { useEffect, useState } from "react";
import axios from "axios";

import { styles } from "./TopBanner.styles";

export function TopBanner({
  children,
  backgroundColor = "ebonyClay",
  color = "white"
}) {
  return (
    <div
      className={styles.banner({
        css: { backgroundColor: `${backgroundColor}` },
        className: "top-banner"
      })}
    >
      <div className={styles.content({ css: { color: `${color}` } })}>
        {children}
      </div>
    </div>
  );
}
