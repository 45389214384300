import moment from "moment-timezone";

export function pathToSearchState(filters) {
  let searchState = {
    facets: [],
    resultsPerPage: 22,
    currentPage: 0,
    maxPages: 0,
    query: "",
    tagFilters: []
  };
  // if there is no format filter set the default format which is inPerson
  if (!filters.some(filter => filter.type === "format")) {
    searchState.facets.push(`obbyItem:class`);
    searchState.facets.push(`isOnline:false`);
  }

  for (let filter of filters) {
    //query
    if (filter.type === "q") {
      searchState.query = filter.value;
    }
    //flexitime
    if (filter.type === "hidePrivate") {
      searchState.facets.push(`anytimeClass:false`);
    }
    //format
    if (filter.type === "format") {
      if (filter.value === "online-courses") {
        searchState.facets.push(`obbyItem:product`);
        searchState.facets.push(`productType:online-course`);
      }
      if (filter.value === "online") {
        searchState.facets.push(`obbyItem:class`);
        searchState.facets.push(`isOnline:true`);
      }
      if (filter.value === "kits") {
        searchState.facets.push(`obbyItem:product`);
      }
    }
    //level
    if (filter.type === "level") {
      searchState.facets.push(`level:${filter.value}`);
    }
    //tag selected
    if (["tag", "keyword", "location"].includes(filter.type)) {
      searchState.tagFilters.push(filter.value);
    }

    //date selected
    if (filter.type === "date") {
      searchState.date = moment(filter.value).toISOString();
    }

    //pagination
    if (filter.page) {
      searchState.currentPage = filter.page - 1; // i dont understand this
    }
  }
  return searchState;
}
