import { css, withBem } from "../../sitches.config";

export const styles = withBem("course-page-header", {
  coursePageHeader: css({ paddingBottom: "$3" }),
  breadcrumbs: css({
    paddingBottom: "18px"
  }),
  content: css({
    marginBottom: "25px",
    "@md": {
      marginBottom: "0"
    }
  }),
  pills: css({
    marginBottom: "12px",
    "&:empty": {
      marginBottom: "0"
    }
  }),
  pill: css({
    margin: "10px 10px 0 0",

    "&:last-child": {
      marginRight: "0"
    },

    "@md": {
      margin: "0 10px 10px 0"
    }
  }),

  pillSkeletonWrapper: css({
    width: "130px"
  }),

  pillSkeleton: css({
    height: "100%"
  }),

  slider: css({
    margin: "0 -15px",

    "@md": {
      margin: "0",
      overflow: "hidden",
      borderRadius: "$2"
    }
  }),

  title: css({
    display: "block",
    fontFamily: "$foundersGroteskCondensed",
    fontWeight: "bold",
    fontSize: "$28",
    lineHeight: "90%",
    color: "$ebonyClay",
    textTransform: "uppercase",
    margin: "0 0 25px",

    "@sm": {
      fontSize: "$36"
    },

    "@md": {
      fontSize: "$52"
    }
  }),

  description: css({
    display: "block",
    fontFamily: "$foundersGrotesk",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "$18",
    lineHeight: "144%",
    margin: "18px 0 30px"
  }),

  iconListWrapper: css({
    maxWidth: "588px",

    ".icon-blurb-list": {
      gridRowGap: "10px"
    }
  }),

  favoriteIcon: css({
    position: "absolute",
    top: "$24",
    right: "$30",
    zIndex: "1",
    cursor: "pointer",

    svg: {
      filter: "drop-shadow(1px 1px 0.5px #000)"
    },

    variants: {
      isFavourite: {
        true: {
          path: {
            stroke: "$camelot"
          }
        },
        false: {
          path: {
            stroke: "$lighterGray"
          }
        }
      }
    }
  }),

  nextSessionDate: css({
    fontWeight: "500",
    lineHeight: "100%",
    color: "$ebonyClay",
    letterSpacing: "0.01em",
    fontSize: "$18",
    fontFamily: "$foundersGrotesk"
  }),

  nextSessionDateLabel: css({
    color: "$gray",
    marginRight: "4px"
  }),

  teacher: css({
    display: "flex",
    alignItems: "center"
  }),

  teacherImage: css({
    borderRadius: "50%",
    flex: "0 0",
    width: "48px",
    height: "48px",

    "@md": {
      width: "96px",
      height: "96px"
    }
  }),

  teacherName: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$18",
    fontWeight: "500",
    color: "$ebonyClay",

    "@md": {
      fontSize: "$20"
    }
  }),

  priceWrapper: css({
    variants: {
      discount: {
        true: {
          textDecoration: "line-through"
        }
      }
    }
  }),

  price: css({
    display: "inline-block",
    textDecoration: "inherit"
  }),

  reviewsSkeleton: css({
    fontSize: "$18",
    maxWidth: "200px"
  })
});
