import React from "react";
import { ImageUrl__ImageResizedUrl, String__TruncatedString } from "@obby/lib";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import styles from "./banner-card.module.less";

import { CloudinaryImage } from "../CloudinaryImage";

export default function BannerCard(props) {
  const {
    title,
    description,
    link,
    thumbnail,
    buttonText,
    backgroundColor,
    buttonColor,
    categorySticker,
    titleLength = 38,
    onClick = () => {}
  } = props;

  const resizedThumbnail = ImageUrl__ImageResizedUrl(
    { width: 530, height: 350, crop: "fill" },
    thumbnail
  );

  return (
    <div
      className={`
				${styles["banner-card"]} ${backgroundColor &&
        styles[`banner-card--bg-${backgroundColor}`]}
			`}
    >
      <div className={styles["banner-card__content"]}>
        <span className={styles["banner-card__title"]}>
          {String__TruncatedString({ length: titleLength }, title)}
        </span>
        <span className={styles["banner-card__description"]}>
          {description}
        </span>
        <div className={styles["banner-card__button"]}>
          <PrimaryButton
            text={buttonText}
            className={styles["banner-card__button"]}
            textMode="uppercase"
            color={buttonColor}
            width="auto"
            size="medium"
            onClick={onClick}
          />
        </div>
      </div>
      <div
        className={`${styles["banner-card__picture"]} py-4 px-3`}
        style={{ backgroundImage: `url(${resizedThumbnail})` }}
      >
        <CloudinaryImage
          className={styles["banner-card__sticker"]}
          src={categorySticker}
        />
      </div>
    </div>
  );
}
