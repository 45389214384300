import React from "react";

import styles from "./image-with-label.module.less";

const ImageWithLabel = ({
  imageUrl,
  labelText,
  color = "emeraldish",
  background = "humming-bird"
}) => {
  return (
    <div className={styles["image-with-label"]}>
      <div className={styles["image-with-label__image-wrapper"]}>
        <img
          className={styles["image-with-label__image"]}
          src={imageUrl}
          alt=""
          loading="lazy"
        />
      </div>
      <span
        className={`${styles["image-with-label__text"]} ${
          styles[`image-with-label__text--${color}`]
        } ${styles[`image-with-label__text--bg-${background}`]}`}
      >
        {labelText}
      </span>
    </div>
  );
};

export default ImageWithLabel;
