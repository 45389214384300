import React from "react";

import { styles } from "./WidgetSchoolBanner.styles";
//@ts-ignore
import { CloudinaryUrl__TransformedUrl } from "@obby/lib";
import { ExpandableText } from "../ExpandableText";
import { Skeleton } from "../Skeleton";
import { AspectRatio } from "../../boxes/AspectRatio";

interface WidgetSchoolBannerProps {
  title: string;
  description?: string;
  skeleton?: boolean;
  imageUrl?: string;
  textColor?: string;
}

export function WidgetSchoolBanner({
  description,
  imageUrl,
  skeleton = false,
  textColor,
  title
}: WidgetSchoolBannerProps) {
  const resizedImageUrl = imageUrl
    ? CloudinaryUrl__TransformedUrl(imageUrl, {
        width: 584,
        height: 352,
        crop: "fill"
      })
    : undefined;

  return (
    <div className={styles.widgetSchoolBanner()}>
      <div className="row">
        {resizedImageUrl && (
          <div className="col-12 col-smd-6 order-smd-last">
            {skeleton ? (
              <AspectRatio height={352} width={584}>
                <Skeleton
                  color="white"
                  highlightColor="mercury"
                  className={styles.imageSkeleton()}
                />
              </AspectRatio>
            ) : (
              <div className={styles.imageWrapper()}>
                <img
                  className={styles.image()}
                  src={resizedImageUrl}
                  alt={title}
                />
              </div>
            )}
          </div>
        )}
        <div
          className={`col-12 col-smd-6 ${
            !resizedImageUrl ? "offset-smd-3" : ""
          }`}
        >
          <div className={styles.info()} style={{ color: textColor }}>
            <h1 className={styles.title()}>
              {skeleton ? <Skeleton light /> : title}
            </h1>
            {skeleton ? (
              <div className={styles.description({ css: { paddingX: "$5" } })}>
                <p>
                  <Skeleton light lines={2} />
                </p>
              </div>
            ) : (
              <ExpandableText
                maxWords={30}
                //@ts-ignore
                className={styles.description()}
                readMoreTextColor={textColor}
              >
                {description}
              </ExpandableText>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
