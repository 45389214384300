import React from "react";

import styles from "./avatar-speech-bubble-banner.module.less";
import Image from "../Image";
import ThemedMarkdownText from "../ThemedMarkdownText";

const AvatarSpeechBubbleBanner = ({
  title,
  text,
  personName,
  personAvatar,
  backgroundBanner
}) => {
  return (
    <div className={styles["avatar-speech-bubble-banner"]}>
      <Image
        src={backgroundBanner}
        asBackground
        className={styles["avatar-speech-bubble-banner__background"]}
      />
      <div className={styles["avatar-speech-bubble-banner__main"]}>
        <div className={styles["avatar-speech-bubble-banner__avatar-wrapper"]}>
          <Image
            src={personAvatar}
            className={styles["avatar-speech-bubble-banner__avatar"]}
          />
        </div>
        <div className={styles["avatar-speech-bubble-banner__content"]}>
          <span className={styles["avatar-speech-bubble-banner__title"]}>
            {title}
          </span>
          <ThemedMarkdownText
            className=""
            text={text}
            classNames={{
              paragraph: styles["avatar-speech-bubble-banner__description"]
            }}
          />
          <span className={styles["avatar-speech-bubble-banner__name"]}>
            {personName}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AvatarSpeechBubbleBanner;
