import { Timezones } from "@obby/constants";
import { UTCDate__StartOf } from "./UTCDate__StartOf";
import { UTCDate__EndOf } from "./UTCDate__EndOf";
import { UTCDate__IsBefore } from "./UTCDate__IsBefore";

export function Range__FromTo(
  range: "month" | "isoWeek" | undefined,
  from?: string,
  timezone = Timezones.EUROPE_LONDON
) {
  const now = new Date().toISOString();
  if (!range) return { from: now };
  from = from ? UTCDate__StartOf(from, timezone, range) : now;

  if (UTCDate__IsBefore(from, now)) from = now;

  const to = UTCDate__EndOf(from, timezone, range);
  return { from, to };
}
