import React, { useState, useEffect } from "react";
import Icon from "../../components/Icon";
import Icons from "../../components/Icons";

import * as styles from "./FavouriteButton.styles";

export function FavouriteButton({
  onToggle = () => {},
  className = "",
  onChange = onToggle,
  ...props
}: Props) {
  const [checked, setChecked] = useState(props.value ?? props.checked ?? false);
  useEffect(() => {
    setChecked(props.value ?? props.checked ?? false);
  }, [props.value ?? props.checked ?? false]);

  function onClick() {
    setChecked(!checked);
    onChange && onChange(!checked);
  }

  return (
    <div className={styles.button({ checked, className })} onClick={onClick}>
      <Icon icon={checked ? Icons.HeartFull : Icons.Heart} height={24} />
    </div>
  );
}

interface Props {
  /** @deprecated */
  checked?: boolean;
  className?: string;
  onChange?: (value: boolean) => void;
  /** @deprecated */
  onToggle?: (value: boolean) => void;
  value?: boolean;
}
