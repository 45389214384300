import React from "react";
// @ts-ignore
import { CourseStatuses, CourseTypes } from "@obby/constants";
import {
  Course__DurationString,
  CurrencyISO__CurrencySymbol,
  Number__Currency,
  Course__Price,
  Course__IsPriceFrom
  // @ts-ignore
} from "@obby/lib";
import { BookingOption } from "./BookingOption";
import { useAppText } from "../../containers";

export function BookingOptions({
  compact,
  course,
  onAppointmentSelect,
  onBundleSelect,
  onDropInSelect,
  onFullTermSelect,
  onGiftCardSelect,
  onGroupScheduleSelect,
  onGroupSelect,
  onProRataTermSelect,
  onRegularSelect,
  onTrialSelect
}: Props) {
  const { __, __n } = useAppText();

  const {
    appointmentScheduleBriefing,
    bundles,
    currency,
    discount,
    groupScheduleBriefing,
    summary,
    status
  } = course;
  let currencySymbol = CurrencyISO__CurrencySymbol(currency);

  if (status !== CourseStatuses.APPROVED) return <></>;

  const options: BookingOption.Props[] = [];
  let giftCardPrice: number = 0;
  if (course.type === CourseTypes.TERMLY) {
    if (onFullTermSelect && summary.hasFullTermDates) {
      const numberOfAdditionalDates =
        summary.numberOfAdditionalFullTermDates ?? 0;
      options.push({
        title: "Full Term",
        description: __n(
          "1 session",
          ":count sessions",
          numberOfAdditionalDates + 1
        ),
        price: summary.fullTermPrice,
        isFromPrice: summary.additionalFullTermPrices.length > 0,

        discount,
        onSelect: onFullTermSelect
      });
      giftCardPrice = giftCardPrice || summary.fullTermPrice;
    }

    if (onProRataTermSelect && summary.hasProRataTermDates) {
      const numberOfAdditionalDates =
        summary.numberOfAdditionalProRataDates ?? 0;
      let price = summary.proRataPrice;
      if (summary.additionalProRataPrices.length > 0) {
        price = summary.additionalProRataPrices[0];
      }
      options.push({
        title: "Pro-rata Term",
        description: __n(
          "1 session",
          ":count sessions",
          numberOfAdditionalDates + 1
        ),
        price: price,
        isFromPrice: summary.additionalProRataPrices.length > 0,
        discount,
        onSelect: onProRataTermSelect
      });
      giftCardPrice = giftCardPrice || summary.price;
    }

    if (onTrialSelect && summary.hasAvailableTermTrialDates) {
      options.push({
        title: __("Trial this class"),
        price: summary.trialPrice,
        discount,
        description: "Book a trial session to get a taster of the full term",
        onSelect: onTrialSelect
      });
    }
    if (onDropInSelect && summary.hasAvailableTermDropInDates) {
      options.push({
        title: "Drop-in",
        price: summary.dropInPrice,
        discount,
        description: "1 Session",
        onSelect: onDropInSelect
      });
    }
  } else if (onRegularSelect && summary.hasDates) {
    const numberOfAdditionalDates =
      summary.nextAvailableAdditionalDates?.length ?? 0;
    let pricetoBe = 0;
    let isFromPrice = false;
    // if the isfrom is setup then the teacher wants to override the logic of the price
    // @MM-177
    if (Course__IsPriceFrom(course)) {
      pricetoBe = Course__Price(course);
      isFromPrice = true;
    } else {
      pricetoBe = summary.price;
      isFromPrice = summary.additionalPrices.length > 0;
    }
    options.push({
      title: Course__DurationString(course, __(course.type)),
      description: __n(
        "1 class",
        ":count classes",
        numberOfAdditionalDates + 1
      ),
      price: pricetoBe,
      isFromPrice: isFromPrice,
      discount,
      onSelect: onRegularSelect
    });
    giftCardPrice = giftCardPrice || summary.price;
  }
  // flextime option
  if (onGroupScheduleSelect && course.isGroupScheduleEnabled) {
    const {
      minNumberOfGuests,
      maxNumberOfGuests,
      price
    } = groupScheduleBriefing;
    options.push({
      title: "Private Booking",
      description:
        minNumberOfGuests === maxNumberOfGuests
          ? `Minimum ${minNumberOfGuests} guests to book`
          : `For groups of ${minNumberOfGuests} to ${maxNumberOfGuests} guests`,
      price,
      discount,
      onSelect: onGroupScheduleSelect
    });
    giftCardPrice = giftCardPrice || price;
  }
  // appointment option
  if (onAppointmentSelect && course.isAppointmentScheduleEnabled) {
    options.push({
      title: "By Appointment",
      description: "Book anytime you want",
      price: appointmentScheduleBriefing.price,
      isFromPrice: appointmentScheduleBriefing.additionalPrices.length > 0,
      discount,
      onSelect: onAppointmentSelect
    });
    giftCardPrice = giftCardPrice || appointmentScheduleBriefing.price;
  }

  if (onBundleSelect && summary.hasDates)
    bundles.map((bundle: any) => {
      let defaultDescription = `Save ${Number__Currency(
        summary.price * bundle.numberOfSessions - bundle.price,
        currencySymbol
      )}`;
      if (bundle.ticketTypedescription != "") {
        defaultDescription = bundle.ticketTypedescription;
      }
      options.push({
        title: bundle.ticketTypeName,
        description: defaultDescription,
        price: bundle.price,
        discount,
        onSelect: () => onBundleSelect(bundle)
      });
      giftCardPrice = giftCardPrice || bundle.price;
    });

  // Only show Gift this class button if we have available dates
  if (onGiftCardSelect && summary.hasAvailableDates)
    options.push({
      title: "Gift this class",
      description: "Send to a friend or a loved one",
      price: giftCardPrice, // gift card takes the price of the first option
      discount,
      onSelect: onGiftCardSelect
    });

  if (onGroupSelect && options.length && summary.hasDates)
    options.push({
      title: "Book for a large group",
      description: "Are you looking for a large group/private event?",
      buttonText: "Enquire",
      onSelect: onGroupSelect
    });

  if (options.length === 0) return null;

  return (
    <div className="row">
      {options.map((option, index) => (
        <div
          className={`col-12 ${
            compact ? "col-sm-6 col-lg-4 mt-sm-4" : "mt-sm-1"
          } mt-2`}
          key={index}
        >
          <BookingOption
            compact={compact}
            {...option}
            currency={course.currency}
          />
        </div>
      ))}
    </div>
  );
}

interface Props {
  compact?: boolean;
  course: any;
  onAppointmentSelect?: () => void;
  onBundleSelect?: (bundle: any) => void;
  onDropInSelect?: () => void;
  onFullTermSelect?: () => void;
  onGiftCardSelect?: () => void;
  onGroupScheduleSelect?: () => void;
  onGroupSelect?: () => void;
  onProRataTermSelect?: () => void;
  onRegularSelect?: () => void;
  onTrialSelect?: () => void;
}
