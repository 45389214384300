import { css, withBem } from "../../sitches.config";

export const styles = withBem("shared-text-area", {
  sharedTextArea: css({}),
  input: css({
    width: "100%",
    height: "100%",
    fontFamily: "$foundersGrotesk",
    lineHeight: "1.4",
    color: "$textColor",
    border: "1px solid $inputBorderColor",
    borderRadius: "$input",
    backgroundColor: "white",
    margin: 0,
    minHeight: "100px",
    outline: "none",
    "&::placeholder": {
      color: "$gray_2",
      opacity: 1
    },
    "&:disabled": {
      color: "$disabled"
    },
    variants: {
      size: {
        small: {
          padding: "6px 15px",
          fontSize: "$16"
        },
        medium: {
          padding: "11px 20px",
          fontSize: "$18"
        }
      }
    }
  }),
  characterCount: css({
    paddingTop: "5px",
    fontFamily: "$foundersGrotesk",
    fontSize: "$18",
    lineHeight: 1,
    textAlign: "right",
    color: "$alto"
  })
});
