import React from "react";
import _ from "lodash";
import { Block } from "../../layout/Block";
import { Label } from "../../components/Label";
import { ProductSelectionRow } from "../../components/ProductSelectionRow";
import { TextArea } from "../../inputs/TextArea";
// @ts-ignore
import { Discount__DiscountedPrice } from "@obby/lib";

export function TicketTypesStep({
  course,
  discount,
  onChange,
  ticketTypes,
  slots,
  values
}: Props) {
  const { currency, userInputs = [] } = course;

  return (
    <div className="ticket-types-step">
      <Label label="How many guests are coming?" condensed className="mb-3" />
      {slots.reduce((components, slot) => {
        components.push(
          ...slot.tickets.map((ticket: any) => {
            const [ticketTypeId] = ticket.ticketTypes;
            const ticketType = ticketTypes.find(
              ticketType => ticketType._id === ticketTypeId
            );
            const disabled =
              values.ticket.ticketTypeId &&
              values.ticket.ticketTypeId !== ticketType._id;
            const selected = values.ticket.ticketTypeId === ticketType._id;
            const options = _.range(
              ticketType.minTicketsPerOrder,
              ticketType.maxTicketsPerOrder + 1
            );
            return (
              <Block key={ticketType._id} marginBottom={4} marginBottomLast={0}>
                <ProductSelectionRow
                  title={ticketType.name}
                  description={ticketType.description}
                  price={ticketType.price}
                  currency={currency}
                  discount={
                    discount
                      ? Discount__DiscountedPrice(discount, ticketType.price)
                      : undefined
                  }
                  options={options.map(option => ({
                    label: option,
                    value: option
                  }))}
                  selected={selected}
                  selectable={
                    ticketTypes.length !== 1 &&
                    values.ticket.ticketTypeId !== undefined
                  }
                  value={disabled ? null : values.ticket.numberOfGuests}
                  onChange={(numberOfGuests: any) =>
                    onChange(
                      {
                        numberOfGuests,
                        ticketTypeId: ticketType._id
                      },
                      "ticket"
                    )
                  }
                  onClick={
                    values.ticket.ticketTypeId
                      ? () => onChange(ticketTypeId, "ticket.ticketTypeId")
                      : undefined
                  }
                />
              </Block>
            );
          })
        );
        return components;
      }, [])}
    </div>
  );
}

interface Props {
  course: any;
  discount: object;
  onChange: (value: any, name: string) => void;
  slots: any[];
  ticketTypes: any[];
  values: any;
}
