import React, { useMemo } from "react";
// @ts-ignore
import { CloudinaryUrl__TransformedUrl } from "@obby/lib";

export function CloudinaryImage({
  alt = "",
  crop = "fill",
  width,
  height,
  className = "",
  ...props
}: Props) {
  const src = CloudinaryUrl__TransformedUrl(props.src, {
    ...(width ? { width } : {}),
    ...(height ? { height } : {}),
    crop
  });

  // Normalize media
  const media = normalizeMedia(props.sizes, width, height);

  const srcSet = media
    .map(
      breakpoint =>
        `${CloudinaryUrl__TransformedUrl(props.src, breakpoint.options)} ${
          breakpoint.options.width
        }w`
    )
    .join(", ");

  const sizes = useMemo(
    () =>
      media
        .map(breakpoint => {
          return `${breakpoint.condition || ""} ${breakpoint.options.width}px`;
        })
        .join(", "),
    [media]
  );

  return (
    <img
      loading="lazy"
      className={`cloudinary-image ${className}`}
      src={src}
      srcSet={srcSet}
      sizes={sizes}
      alt={alt}
    />
  );
}

interface Props {
  alt?: string;
  crop?: string;
  width?: number;
  height?: number;
  className?: string;
  src: string;
  sizes?: Size[];
}

interface Size {
  minWidth?: number;
  width: number;
  height?: number;
}

function normalizeMedia(media: Size[] = [], width?: number, height?: number) {
  return media.map(({ minWidth, ...breakpoint }) => ({
    condition: minWidth ? `(min-width: ${minWidth}px)` : "",
    options: {
      crop: "fill",
      ...(height && width
        ? { height: Math.round(breakpoint.width * (height / width)) }
        : {}),
      ...breakpoint
    }
  }));
}
