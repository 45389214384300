import { css } from "../../sitches.config";

export const styles = {
  switchInputOption: css({
    fontFamily: "$foundersGrotesk",
    flex: "1 0 0",
    display: "flex",
    fontStyle: "normal",
    fontWeight: "normal",
    // fontSize: "$18",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "$ebonyClay",
    borderRadius: "5px",
    cursor: "pointer",
    padding: "$2 $3",
    variants: {
      selected: {
        true: {
          backgroundColor: "white"
        }
      },
      color: {},
      size: {
        small: {
          fontSize: "$16"
        },
        medium: {
          fontSize: "$18"
        }
      }
    },
    compoundVariants: [
      {
        selected: true,
        color: "emeraldish",
        css: { backgroundColor: "$emeraldish", color: "$white" }
      },

      {
        selected: true,
        color: "brightTeal",
        css: { backgroundColor: "$brightTeal", color: "$white" }
      }
    ]
  }),

  label: css({
    display: "flex",
    flex: "1",
    justifyContent: "center",
    alignItems: "center"
  })
};
