import { css, withBem } from "../../sitches.config";

export const styles = withBem("checkout-steps-header", {
  root: css({
    marginBottom: "$3",
    "&:empty": {
      marginBottom: 0
    }
  }),

  title: css({
    fontFamily: "$foundersGroteskCondensed",
    fontWeight: "bold",
    fontSize: "32px",
    textTransform: "uppercase",
    color: "$ebonyClay",
    margin: "0 0 $1",
    "@md": {
      fontSize: "48px"
    },
    "&:last-child": {
      marginBottom: 0
    }
  }),
  description: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "$16",
    letterSpacing: "0.01em",
    color: "$ebonyClay"
  })
});
