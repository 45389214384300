import moment from "moment-timezone";

export function Timezone__DisplayOffset(timezone?: string, isoDate?: string) {
  let date = timezone ? moment(isoDate).tz(timezone) : moment(isoDate);
  let offset = date.utcOffset();
  const sign = offset < 0 ? "-" : "+";
  offset = offset * (offset < 0 ? -1 : 1);
  const minutes = offset % 60;
  const hours = (offset - minutes) / 60;
  return `${sign}${hours}${
    minutes ? `:${(minutes + "").padStart(2, "0")}` : ""
  }`;
}
