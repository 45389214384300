import { useState } from "react";
import { useMediaQuery, Breakpoint } from "@obby/components";

export function useVisibleColumns() {
  const [state, setState] = useState<(string | undefined)[]>([]);

  const isSm = useMediaQuery("(min-width: 576px)");
  const isSmd = useMediaQuery("(min-width: 700px)");
  const isMd = useMediaQuery("(min-width: 834px)");
  const isLg = useMediaQuery("(min-width: 1024px)");
  const isXl = useMediaQuery("(min-width: 1440px)");

  function setVisibleColumn(columnIndex: number, breakpoint?: Breakpoint) {
    setState(state => {
      const newState = [...state];
      newState[columnIndex] = breakpoint;
      return newState;
    });
  }

  const visibleColumns = state
    .map((breakpoint, index) => {
      switch (true) {
        case breakpoint === "sm" && isSm:
        case breakpoint === "smd" && isSmd:
        case breakpoint === "md" && isMd:
        case breakpoint === "lg" && isLg:
        case breakpoint === "xl" && isXl:
        case breakpoint === undefined:
          return index;
        default:
          return undefined;
      }
    })
    .filter(index => index !== undefined);

  return [visibleColumns, setVisibleColumn] as [
    number[],
    (columnIndex: number, breakpoint?: Breakpoint) => {}
  ];
}
