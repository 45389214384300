import * as Yup from "yup";
import { CheckoutPersonalDetailsForm } from "../../forms/CheckoutPersonalDetailsForm";

export function BundleCheckoutSchema(isSendingMaterial, defaults = {}) {
  defaults = {
    tickets: [],
    inputs: [],
    personalDetails: [],
    sessionsIds: [],
    discountCode: "",
    ...defaults
  };

  return Yup.object({
    sessionsIds: Yup.array()
      .of(Yup.string().required())
      .min(1)
      .default(defaults.sessionsIds),
    inputs: Yup.array()
      .of(
        Yup.string()
          .required()
          .default("")
      )
      .default(defaults.inputs),
    personalDetails: CheckoutPersonalDetailsForm.Schema(isSendingMaterial),
    discountCode: Yup.string()
      .when("personalDetails.guests.0.email", (email, schema) => {
        if (
          !Yup.string()
            .email()
            .required()
            .isValidSync(email)
        )
          return schema.test(
            "email-address-for-discount",
            "Fill in email address first in Personal details step",
            () => false
          );

        return schema;
      })
      .default(defaults.discountCode)
  });
}
