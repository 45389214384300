import React, { useState } from "react";
// @ts-ignore
import { DiscountNatures } from "@obby/constants";
import { CheckoutSteps } from "../../navs/CheckoutSteps";
import { useStripePayment } from "../StripePayment";
// @ts-ignore
import { Session__DisplayDateTimeOffset } from "@obby/lib";
import { DateTimeStep } from "./DateTimeStep";
import { TicketTypesStep } from "./TicketTypesStep";
import { CheckoutPanel } from "../../panels/CheckoutPanel";
import { CheckoutPersonalDetailsForm } from "../../forms/CheckoutPersonalDetailsForm";
import { Fields } from "./GroupScheduleCheckout.schema";
import { CheckoutFooterBar } from "../../bars/CheckoutFooterBar";
import { StripePayment } from "../StripePayment";
import { UserFormQuestionary } from "../UserFormQuestionary";

export function GroupScheduleCheckoutView({
  cancellationPolicyUrl,
  credit,
  course,
  discount,
  errors,
  getBookingData,
  isDiscountEnabled,
  isLastStep,
  isNewsletterSignupEnabled = true,
  isValid,
  isUserDiscountInvalid,
  onApplyDiscount,
  onBlur,
  onChange,
  onCheckoutStepChange,
  onConfirmFree,
  onPersonalDetailsEnter,
  slots,
  startDateTime,
  ticketType,
  ticketTypes,
  timezone,
  total,
  touched,
  unavailableTimeBlocks,
  userDiscountValue,
  userDiscountPercentage,
  values,
  mandatoryTickBox = false
}: Props) {
  const stripePayment = useStripePayment();

  const [isConfirmingFreeBooking, setIsConfirmingFreeBooking] = useState(false);

  function onContinue() {
    if (total === 0) {
      setIsConfirmingFreeBooking(true);
      onConfirmFree();
    } else stripePayment.submitPayment();
  }

  function getFormattedDate() {
    return (
      startDateTime &&
      Session__DisplayDateTimeOffset(
        {
          startDateTime,
          duration: course.groupScheduleBriefing.duration,
          isOnline: course.isOnline
        },
        timezone
      )
    );
  }

  async function onCardPaymentEnter() {
    await stripePayment.getPaymentIntent(getBookingData());
  }

  console.log("he couse ", course.userQuestionForm);
  const [isUserFormValid, setIsUserFormValid] = useState(false);

  return (
    <CheckoutSteps.Provider
      onContinue={onContinue}
      onNext={onCheckoutStepChange}
      onPrevious={onCheckoutStepChange}
    >
      <div className="container-fluid mb-2 mb-md-0">
        <CheckoutSteps.Breadcrumbs />
      </div>
      <CheckoutPanel
        cancellationPolicyUrl={cancellationPolicyUrl}
        title={course.title}
        description={getFormattedDate()}
        thumbnail={course.thumbnail}
        credit={credit}
        currency={course.currency}
        discountError={touched.discountCode && errors.discountCode}
        discountValue={userDiscountValue}
        discountPercentage={userDiscountPercentage}
        isDiscountEnabled={isDiscountEnabled}
        isDiscountInvalid={isUserDiscountInvalid}
        isOnSales={discount?.nature === DiscountNatures.SALES}
        onApplyDiscount={onApplyDiscount}
        personalDetails={
          isLastStep ? values.personalDetails.guests[0] : undefined
        }
        sidebarOnTop={isLastStep}
        items={
          values.ticket.numberOfGuests && ticketType
            ? [
                {
                  ...ticketType,
                  count: values.ticket.numberOfGuests
                }
              ]
            : []
        }
        total={total}
      >
        <CheckoutSteps>
          <CheckoutSteps.Step
            breadcrumb="Date selection"
            title="Date & Time"
            description="Select a date and time that suits you and your teacher will get back to you to confirm if they are available or suggest alternate times."
            disabled={!isValid("dateTime")}
          >
            <div className="mb-4">
              <DateTimeStep
                course={course}
                errors={errors?.dateTime}
                onBlur={onBlur}
                onChange={(value, name) => onChange(value, `dateTime.${name}`)}
                timezone={timezone}
                touched={touched?.dateTime}
                unavailableTimeBlocks={unavailableTimeBlocks}
                values={values.dateTime}
              />
            </div>
          </CheckoutSteps.Step>

          <CheckoutSteps.Step
            breadcrumb="Tickets"
            title="Tickets"
            disabled={!isValid("ticket")}
          >
            <TicketTypesStep
              course={course}
              discount={discount}
              onChange={onChange}
              slots={slots}
              ticketTypes={ticketTypes}
              values={values}
            />
          </CheckoutSteps.Step>
          <CheckoutSteps.Step
            breadcrumb=" Customer Information"
            title=" Customer Information"
            description="Please provide the necessary information so that we can continue with your booking."
            disabled={
              !(course.userQuestionForm.isEnabled == false || isUserFormValid)
            }
            hidden={!course.userQuestionForm.isEnabled}
          >
            <UserFormQuestionary
              values={values}
              onChange={onChange}
              mode={course.userQuestionForm.mode}
              questions={course.userQuestionForm.questions}
              onFormValidation={setIsUserFormValid}
            />
          </CheckoutSteps.Step>

          <CheckoutSteps.Step
            breadcrumb="Personal details"
            title="Who's coming?"
            disabled={isConfirmingFreeBooking || !isValid("personalDetails")}
            onEnter={onPersonalDetailsEnter}
            nextButtonText={total === 0 ? "Confirm booking" : undefined}
          >
            <CheckoutPersonalDetailsForm
              errors={errors?.personalDetails}
              onChange={(value: any, name: string) =>
                onChange(value, `personalDetails.${name}`)
              }
              onBlur={(name: string) => onBlur(`personalDetails.${name}`)}
              isSendingMaterial={course.requiresSendingPhysicalMaterial}
              disableMarketingOptIn={!isNewsletterSignupEnabled}
              values={values.personalDetails}
              touched={touched?.personalDetails}
              mandatoryTickBox={mandatoryTickBox}
            />
          </CheckoutSteps.Step>
          <CheckoutSteps.Step
            breadcrumb="Card payment"
            title="Card payment"
            nextButtonText="Pay now"
            onEnter={onCardPaymentEnter}
            disabled={!stripePayment.isReady}
            hidden={total === 0}
          >
            <StripePayment />
          </CheckoutSteps.Step>
        </CheckoutSteps>
      </CheckoutPanel>
      <CheckoutFooterBar label="Date Selected" value={getFormattedDate()}>
        <CheckoutSteps.Navigation />
      </CheckoutFooterBar>
    </CheckoutSteps.Provider>
  );
}

interface Props {
  cancellationPolicyUrl: string;
  credit: number;
  course: any;
  discount?: any;
  errors: any;
  getBookingData: () => any;
  isDiscountEnabled: boolean;
  isLastStep: boolean;
  isNewsletterSignupEnabled: boolean;
  isUserDiscountInvalid: boolean;
  isValid: (path: string) => boolean;
  onApplyDiscount: (code: string) => void;
  onBlur: (name: string) => void;
  onChange: (value: any, name: string) => void;
  onCheckoutStepChange: (index: number) => void;
  onConfirmFree: () => void;
  onPersonalDetailsEnter: () => void;
  slots: any[];
  startDateTime?: string;
  ticketType?: any;
  ticketTypes: any[];
  timezone: string;
  total: number;
  touched: any;
  unavailableTimeBlocks: any[];
  userDiscountValue: number;
  userDiscountPercentage?: number;
  values: Fields;
  mandatoryTickBox?: boolean;
}
