import { css, withBem } from "../../../sitches.config";

export const styles = withBem("booking-options-section-row", {
  root: css({
    display: "flex",
    width: "100%",
    borderTop: "solid 1px $gallery",
    padding: "$4 0",
    "@sm": {
      padding: "$3 0"
    }
  }),

  area: css({
    flex: 1,
    order: 1,
    fontFamily: "$foundersGrotesk",
    fontWeight: 400,
    fontSize: "$16",
    lineHeight: 1.4,
    color: "$textColor",
    letterSpacing: "0.3px"
  }),

  heading: css({
    fontFamily: "$foundersGrotesk",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: 1.2,
    color: "$textColor",
    marginBottom: "$1",

    "@md": {
      fontSize: "22px"
    }
  }),
  description: css({
    fontFamily: "$foundersGrotesk",
    fontWeight: 400,
    fontSize: "$18",
    lineHeight: 1.4,
    color: "$textColor",
    margin: 0,
    padding: 0
  }),

  stickerWrapper: css({
    flex: "0 0 80px",
    order: 1,
    marginLeft: "$3",
    "@md": {
      order: 0,
      marginLeft: 0,
      marginRight: "$3"
    }
  }),
  sticker: css({
    width: "100%"
  })
});
