import { merge, curry } from "ramda";

const SenderDetailsAndSingleChatMessages__SingleChatMessages = curry(
  (senders, messages) => {
    return messages.map(message =>
      merge(message, {
        avatar: senders[message.direction].avatar,
        username: senders[message.direction].username
      })
    );
  }
);

export default SenderDetailsAndSingleChatMessages__SingleChatMessages;
