const FormStatuses = {
  IS_NOT_BEING_USED: "formIsNotBeingUsed",
  IS_LOADING: "formIsLoadingData",
  HAS_FAILED_TO_LOAD: "formHasFailedToLoadData",
  IS_BEING_FILLED_IN: "formIsBeingFilledIn",
  HAS_VALIDATION_ISSUES: "formHasValidationIssues",
  IS_SUBMITTING: "formIsSubmitting",
  HAS_SUCCESSFULLY_SUBMITTED: "formHasSuccessfulyFilledIn",
  HAS_FAILED_TO_SUBMIT: "formHasFailedToSubmit"
};

export default FormStatuses;
