import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { SortableItemContext } from "./SortableItemContext";

export function SortableItem({
  id,
  children,
  className
}: React.PropsWithChildren<Props>) {
  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition
  } = useSortable({
    id
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 999 : 1
  };

  return (
    <div
      ref={setNodeRef}
      className={className}
      style={style}
      {...attributes}
      {...listeners}
    >
      <SortableItemContext.Provider value={{ isDragging }}>
        {children}
      </SortableItemContext.Provider>
    </div>
  );
}

interface Props {
  id: string | number;
  className?: string;
}
