import React, { useEffect, useState, RefObject } from "react";
import Icon from "../Icon";
import Icons from "../Icons";
import { useNoBodyScroll } from "../../hooks/useNoBodyScroll";

import { styles } from "./WidePopup.styles";

export function WidePopup({
  children,
  onClose,
  showCloseButton = true,
  close,
  title,
  wrapperRef
}: React.PropsWithChildren<Props>) {
  const [closing, setClosing] = useState(false);

  useNoBodyScroll();

  useEffect(() => {
    setTimeout(() => {
      // We need to focus the wrapper right after full opening in order to be scrollable by wheel due to a Firefox issue.
      // otherwise I need to click on the scrollbar first
      wrapperRef?.current?.focus();
    }, 500);
  }, []);

  useEffect(() => {
    if (close) onCloseCLick();
  }, [close]);

  function onCloseCLick() {
    setClosing(true);
    onClose && setTimeout(onClose, 500);
  }

  return (
    <div className={styles.root()}>
      <div className={styles.overlay({ closing })}>
        <div ref={wrapperRef} className={styles.wrapper()}>
          <header className={styles.header({ className: "px-4 my-3 my-md-4" })}>
            {showCloseButton && (
              <Icon
                className={styles.closeIcon()}
                icon={Icons.Cross}
                onClick={onCloseCLick}
              />
            )}
            {title && <h2 className={styles.headerTitle()}>{title}</h2>}
          </header>
          <div className={styles.container({ className: "mt-md-4" })}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

interface Props {
  close?: boolean;
  onClose?: () => void;
  showCloseButton?: boolean;
  title?: string;
  wrapperRef?: RefObject<HTMLDivElement>;
}
