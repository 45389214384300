import React from "react";
import Icon from "./../Icon";
import Icons from "./../Icons";
import { ratingStarsStyles as styles } from "./RatingStars.styles";

const Rating__SingleRatingStar = (rating: number) => {
  if (rating >= 0.5) {
    return "full";
  } else {
    return "empty";
  }
};

const Rating__RatingStarArray = (
  ratingStarArray: Array<"full" | "empty">,
  rating: number,
  iterations: number
): Array<"full" | "empty"> => {
  if (iterations > 0) {
    ratingStarArray.push(Rating__SingleRatingStar(rating));
    rating = rating - 1;
    iterations = iterations - 1;

    return Rating__RatingStarArray(ratingStarArray, rating, iterations);
  }
  return ratingStarArray;
};

const Rating__StarIcon = (starValue: "full" | "empty") => {
  if (starValue === "full") {
    return Icons.Star;
  }
  return Icons.EmptyStar;
};

interface RatingStarProps {
  rating: number;
  label?: string;
  color?: "sun" | "emeraldish" | "robbinsEggBlue" | "camelot";
  size?: "small" | "large" | "huge";
  showScore?: boolean;
  reviewCount?: number;
}

const RatingStars = ({
  rating,
  label,
  color = "camelot",
  size = "large",
  showScore = false,
  reviewCount
}: RatingStarProps) => (
  <div className={styles.container()}>
    {Rating__RatingStarArray([], rating / 2, 5).map((starValue, index) => (
      <div
        key={index}
        className={styles.star({
          size,
          color: starValue === "full" ? color : undefined
        })}
      >
        {size === "small" && (
          <Icon icon={Rating__StarIcon(starValue)} height={11} />
        )}
        {size === "huge" && (
          <Icon icon={Rating__StarIcon(starValue)} height={22} />
        )}
        {(!size || size === "large") && (
          <Icon icon={Rating__StarIcon(starValue)} height={15} />
        )}
      </div>
    ))}
    {showScore && (
      <span className={styles.score({ size, color })}>
        {Math.round(rating) / 2}
      </span>
    )}
    {reviewCount && (
      <span className={styles.reviewCount({ size })}>
        ({reviewCount} {reviewCount === 1 ? "review" : "reviews"})
      </span>
    )}
    {label && <span className={styles.label({ size, color })}>{label}</span>}
  </div>
);

export default RatingStars;
