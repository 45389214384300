import React, { useState, useEffect, useRef } from "react";
// Components
import { Drawer } from "../Drawer";
import Icon from "../Icon";
import Icons from "../Icons";
import { MenuNavDropdown } from "./MenuNavDropdown";
import { MenuNavMobile } from "./MenuNavMobile";
import { MenuNavPlaceholder } from "./MenuNavPlaceholder";
// Contexts
import { MenuNavContext } from "./MenuNavContext";
import { MenuNavItem } from "./MenuNavItem";
import { MenuNavUser } from "./MenuNavUser";
import { MenuNavBarSpace } from "./MenuNavBarSpace";
import { MenuNavButton } from "./MenuNavButton";
import { MenuNavDesktop } from "./MenuNavDesktop";
import { MenuNavMobileBar } from "./MenuNavMobileBar";
import styles from "./menu-nav.module.less";

export function MenuNav(props) {
  const {
    children,
    textColor = "ebonyClay",
    textColorHover = "emeraldish",
    active,
    onSignOut,
    isSignedIn,
    avatarUrl = "https://res.cloudinary.com/obby/image/upload/v1587118124/Misc%20Stickers/AvatarUni_1.svg"
  } = props;

  const [activeItem, setActiveItem] = useState(active);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const dropdownRef = useRef();
  const selectedItemRef = useRef();

  useEffect(() => {
    setActiveItem(active);
  }, [active]);

  function onItemToggle(id) {
    setActiveItem(id !== activeItem ? id : undefined);
  }

  // this callback will only be called when we leave the menu item if opened.
  function onItemLeave(e) {
    // if the related target is not the dropdown, means that we left the menu item to somewhere else
    // so lets close the dropdown
    if (e.relatedTarget === window) setActiveItem(undefined);
  }

  // this callback will be called everytime we leave the dropdown container.
  function onDropdownLeave(e) {
    // lets keep the dropdown opened if the related target is the selected item
    if (
      e.relatedTarget !== window &&
      (!selectedItemRef.current ||
        !selectedItemRef.current.contains(e.relatedTarget))
    )
      setActiveItem(undefined);
  }

  return (
    <MenuNavContext.Provider
      value={{
        isSignedIn,
        onSignOut,
        avatarUrl,
        textColor: activeItem !== undefined ? "ebonyClay" : textColor,
        textColorHover:
          activeItem !== undefined ? "emeraldish" : textColorHover,
        activeItem,
        onItemToggle
      }}
    >
      <div
        className={`${styles["menu-nav"]} ${
          activeItem !== undefined ? styles["menu-nav--is-active"] : ""
        }`}
      >
        <div className={styles["menu-nav__wrapper"]}>
          <a
            className={styles["menu-nav__logo"]}
            href="/"
            title="Obby - Discover London's favourite courses, classes and workshops."
          >
            <Icon
              className={`menu-nav__logo-icon ${styles["menu-nav__logo-icon"]}`}
              icon={Icons.Obby}
            />
          </a>
          <MenuNavDesktop
            onItemLeave={onItemLeave}
            selectedItemRef={selectedItemRef}
          >
            {children}
          </MenuNavDesktop>

          {isSignedIn && (
            <MenuNavUser avatarUrl={avatarUrl} onSignOut={onSignOut} />
          )}
          <MenuNavMobileBar onHamburgerClick={() => setMobileMenuOpen(true)}>
            {children}
          </MenuNavMobileBar>
        </div>
        <MenuNavDropdown
          active={activeItem}
          dropdownRef={dropdownRef}
          onMouseLeave={onDropdownLeave}
        >
          {children}
        </MenuNavDropdown>
        <Drawer
          position="right"
          open={isMobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
        >
          <MenuNavMobile>{children}</MenuNavMobile>
        </Drawer>
      </div>
    </MenuNavContext.Provider>
  );
}

MenuNav.Item = MenuNavItem;
MenuNav.Space = MenuNavBarSpace;
MenuNav.Button = MenuNavButton;
MenuNav.Placeholder = MenuNavPlaceholder;
