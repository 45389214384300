import React from "react";
import { styles } from "./Col.styles";

export function Col({
  col = 12,
  xs,
  sm,
  smd,
  md,
  lg,
  xl,
  offset,
  offsetSx,
  offsetSm,
  offsetSmd,
  offsetMd,
  offsetLg,
  offsetXl,
  order,
  orderXs,
  orderSm,
  orderSmd,
  orderMd,
  orderLg,
  orderXl,
  children
}: React.PropsWithChildren<Props>) {
  return (
    <div
      className={styles.col({
        col,
        xs,
        sm,
        smd,
        md,
        lg,
        xl,
        offset,
        offsetSx,
        offsetMd,
        offsetLg,
        offsetSm,
        offsetSmd,
        offsetXl,
        order,
        orderXs,
        orderLg,
        orderMd,
        orderSm,
        orderSmd,
        orderXl
      })}
    >
      {children}
    </div>
  );
}

interface Props {
  col?: Cols;
  xs?: Cols;
  sm?: Cols;
  smd?: Cols;
  md?: Cols;
  lg?: Cols;
  xl?: Cols;
  offset?: Cols;
  offsetSx?: Cols;
  offsetSm?: Cols;
  offsetSmd?: Cols;
  offsetMd?: Cols;
  offsetLg?: Cols;
  offsetXl?: Cols;
  order?: Cols;
  orderXs?: Cols;
  orderSm?: Cols;
  orderSmd?: Cols;
  orderMd?: Cols;
  orderLg?: Cols;
  orderXl?: Cols;
}

type Cols = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
