import React, { useContext } from "react";
import SecondaryButton from "../../components/SecondaryButton";
import { CheckoutStepsContext } from "./CheckoutStepsContext";

export function CheckoutStepsBack({ size }: Props) {
  const { isFirstStep, onPrevious } = useContext(CheckoutStepsContext);

  return (
    !isFirstStep() && (
      <SecondaryButton
        size={size}
        text="Back"
        color="emeraldish"
        textMode="uppercase"
        width="auto"
        onClick={onPrevious}
      />
    )
  );
}

interface Props {
  size?: SecondaryButton.Props["size"];
}
