import React from "react";
import { styles } from "./Row.styles";

export function Row({
  children,
  gap,
  columnGap = gap,
  rowGap = gap,
  noGutters = false
}: React.PropsWithChildren<Props>) {
  return (
    <div
      className={styles.row({
        noGutters,
        rowGap,
        columnGap
      })}
    >
      {children}
    </div>
  );
}

interface Props {
  columnGap?: 1 | 2 | 3 | 4;
  gap?: 1 | 2 | 3 | 4;
  noGutters?: boolean;
  rowGap?: 1 | 2 | 3 | 4;
}
