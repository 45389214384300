import React, { useState, useEffect, ReactNode } from "react";
import { styles } from "./ToggleInput.styles";

export function ToggleInput({
  disabled = false,
  label,
  isLocked = false,
  locked = isLocked,
  name,
  off,
  on,
  onChange = () => {},
  onIsLocked = () => {},
  size = "medium",
  ...props
}: Props) {
  function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (locked) onIsLocked();
    else {
      const value = e.target.checked;
      setValue(value);
      onChange(value, name!);
    }
  }

  const [value, setValue] = useState(props.value ?? props.isOn);
  useEffect(() => {
    setValue(props.value ?? props.isOn);
  }, [props.value ?? props.isOn]);
  return (
    <label className={styles.toggleInput()}>
      <input
        className={styles.checkbox()}
        name={name}
        type="checkbox"
        checked={value}
        onChange={disabled ? undefined : onInputChange}
        disabled={disabled}
      />
      <div className={styles.container()}>
        {off && (
          <span className={styles.label({ disabled, highlight: !value })}>
            {off}
          </span>
        )}
        <div className={styles.toggle({ locked, disabled, size })}>
          <div className={styles.switch({ value, locked, disabled, size })} />
        </div>
        {on && (
          <span className={styles.label({ disabled, highlight: value })}>
            {on}
          </span>
        )}
        {label && <span className={styles.label({ disabled })}>{label}</span>}
      </div>
    </label>
  );
}

interface Props {
  disabled?: boolean;
  /** @deprecated - use value instead */
  isOn?: boolean;
  /** @deprecated - use locked instead */
  isLocked?: boolean;
  label?: ReactNode;
  locked?: boolean;
  name?: string;
  on?: string;
  off?: string;
  onChange?: (a: boolean, name: string) => void;
  onIsLocked?: () => void;
  size?: "medium" | "small";
  value?: boolean;
}
