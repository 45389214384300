import React from "react";

import styles from "./highlight-discount.module.less";

export default function HighlightDiscount(props) {
  const { discount, size = "medium", className = "" } = props;
  return (
    <div
      className={`${styles["highlight-discount"]} ${
        styles[`highlight-discount--${size}`]
      } ${className}`}
    >
      {discount}% off
    </div>
  );
}
