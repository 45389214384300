import React from "react";

import { styles } from "./TextPill.styles";

export default function TextPill({
  text,
  color = "white",
  backgroundColor = "coral",
  className = "",
  size = "medium"
}) {
  return (
    <span
      className={styles.pill({
        size,
        className: `text-pill ${className}`,
        css: {
          backgroundColor: `$${backgroundColor}`,
          color: `$${color}`
        }
      })}
    >
      {text}
    </span>
  );
}
