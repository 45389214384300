import React from "react";
//@ts-ignore
import _ from "lodash";
// @ts-ignore
import { Timezones } from "@obby/constants";
// @ts-ignore
import { UTCDate__Add, UTCDate__DiffOf, UTCDate__StartOf } from "@obby/lib";
import { ArrayElement } from "@obby/types";

import { Label } from "../../components/Label";
import { TextArea } from "../../components/TextArea";
import { TextInput } from "../../components/TextInput";
import { ArrayInput } from "../../components/ArrayInput";
import { DatePickerInput } from "../../components/DatePickerInput";
import { SelectBox } from "../../inputs/SelectBox";
import { TimeSelection } from "../../inputs/TimeSelection";

import {
  RescheduleFlexitimeBookingFormSchema,
  Fields
} from "./RescheduleFlexitimeBookingForm.schema";
import { Tips } from "../../boxes/Tips";

export function RescheduleFlexitimeBookingForm({
  canConfirm = false,
  currentDate,
  errors,
  isOnline,
  onAddDate,
  onBlur,
  onChange,
  onRemoveDate,
  timezone = Timezones.EUROPE_LONDON,
  touched,
  values
}: Props) {
  const reschedulingMode = canConfirm ? values.reschedulingMode : "suggest";

  function onAddSuggestedDate() {
    let isoDate = UTCDate__Add(
      UTCDate__StartOf(currentDate, timezone, "day"),
      values.suggestedDates.length + 1,
      "days"
    );
    isoDate = UTCDate__Add(
      isoDate,
      UTCDate__DiffOf(currentDate, timezone, "minutes", "day"),
      "minutes"
    );
    onAddDate(isoDate);
  }

  function onConfirmationDateChange(date: string | null) {
    if (!values.confirmationDate) {
      const minutes = UTCDate__DiffOf(currentDate, timezone, "minutes", "day");
      date = UTCDate__Add(date, minutes, "minutes");
    }
    onChange(date ? new Date(date) : null, `confirmationDate`);
  }
  function onConfirmationTimeChange(date: string | null) {
    if (!values.confirmationDate) {
      const minutes = UTCDate__DiffOf(date, timezone, "minutes", "day");
      date = UTCDate__Add(
        UTCDate__StartOf(currentDate, timezone, "day"),
        minutes,
        "minutes"
      );
    }

    onChange(date ? new Date(date) : null, `confirmationDate`);
  }

  function onSuggestedDateChange(index: number, date: string) {
    onChange(new Date(date), `suggestedDates.${index}`);
  }

  return (
    <div>
      {canConfirm && (
        <Label>
          <SelectBox
            name="reschedulingMode"
            value={reschedulingMode}
            onChange={onChange}
            onBlur={onBlur}
          >
            <SelectBox.Option value="suggest">
              Suggest alternative dates & times
            </SelectBox.Option>
            <SelectBox.Option value="confirm">
              Select new date & time and confirm reschedule
            </SelectBox.Option>
          </SelectBox>
        </Label>
      )}
      {reschedulingMode === "suggest" && (
        <Label>
          <Tips>
            <Tips.Item>
              Please add a minimum of 3 alternative dates for the customer to
              choose from.
            </Tips.Item>
            <Tips.Item>
              The 1st date has been pre-filled for you, but if this is not an
              option that works for you please make sure to change it.
            </Tips.Item>
            <Tips.Item>
              Please also make sure the time you suggest is correct.
            </Tips.Item>
            <Tips.Example>2:00 = 2am, 14:00 = 2pm</Tips.Example>
          </Tips>
        </Label>
      )}

      <Label label="Message" error={touched?.message && errors.message}>
        <TextArea
          name="message"
          placeholder="We would love to accept the booking but..."
          value={values.message}
          onChange={onChange}
          onBlur={onBlur}
        />
      </Label>
      {isOnline && (
        <Label
          label="Zoom Link"
          error={touched?.zoomLink && errors.zoomLink}
          description="Add a Zoom link for this booking and it will automatically be linked to the time and date the student confirms."
        >
          <TextInput
            name="zoomLink"
            placeholder="https://us02web.zoom.us/j/82927876274?pwd=ha81das1"
            value={values.zoomLink}
            onChange={onChange}
            onBlur={onBlur}
          />
        </Label>
      )}
      {reschedulingMode === "suggest" && (
        <Label
          label="Dates"
          error={touched?.suggestedDates && errors.suggestedDates}
          note="If the customer agrees with any of these dates you'll automatically see the confirmation on your Bookings. Otherwise they will be able to cancel the booking."
        >
          <ArrayInput
            name="suggestedDates"
            items={values.suggestedDates || []}
            addButtonLabel="Add another date option"
            onAdd={onAddSuggestedDate}
            onRemove={onRemoveDate}
          >
            {(item: ArrayElement<Fields["suggestedDates"]>, index: number) => {
              return (
                <div className="row">
                  <div className="col-6">
                    <Label
                      error={
                        touched?.suggestedDates?.[index]?.date &&
                        errors?.suggestedDates?.[index]?.date
                      }
                    >
                      <DatePickerInput
                        name={`suggestedDates.${index}`}
                        displayFormat="dddd, MMM D"
                        value={item.toISOString()}
                        onChange={date => onSuggestedDateChange(index, date!)}
                        accent={false}
                        timezone={timezone}
                      />
                    </Label>
                  </div>
                  <div className="col-6">
                    <Label
                      error={
                        touched?.suggestedDates?.[index]?.time &&
                        errors?.suggestedDates?.[index]?.time
                      }
                    >
                      <TimeSelection
                        name={`suggestedDates.${index}`}
                        value={item.toISOString()}
                        timezone={timezone}
                        onChange={date => onSuggestedDateChange(index, date)}
                      />
                    </Label>
                  </div>
                </div>
              );
            }}
          </ArrayInput>
        </Label>
      )}
      {reschedulingMode === "confirm" && (
        <>
          <Label
            label="Date"
            error={touched?.confirmationDate && errors?.confirmationDate}
          >
            <DatePickerInput
              name="confirmationDate"
              displayFormat="dddd, MMM D"
              value={values.confirmationDate?.toISOString() ?? null}
              onChange={onConfirmationDateChange}
              accent={false}
              timezone={timezone}
            />
          </Label>
          <Label
            label="Time"
            error={touched?.confirmationTime && errors?.confirmationTime}
            note="Please note, this option should only be used if a new date & time has already been agreed between you and the customer. This will automatically confirm and update the booking in your Timetable."
          >
            <TimeSelection
              name="confirmationDate"
              value={values.confirmationDate?.toISOString()}
              timezone={timezone}
              onChange={onConfirmationTimeChange}
            />
          </Label>
        </>
      )}
    </div>
  );
}

RescheduleFlexitimeBookingForm.Schema = RescheduleFlexitimeBookingFormSchema;

interface Props {
  canConfirm: boolean;
  currentDate: string;
  errors: any;
  isOnline?: boolean;
  onAddDate: (isoDate: string) => void;
  onRemoveDate: (index: number) => void;
  onChange: (value: any, name: string) => void;
  onBlur: (name: string) => void;
  timezone?: string;
  touched: any;
  values: Fields;
}
