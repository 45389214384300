import React from 'react';
import LinkElementContext from './../../contexts/LinkElementContext';

export default function Link(props) {
	return (
		<LinkElementContext.Consumer>
			{(LinkElement) => <LinkElement {...props}>{props.children}</LinkElement>}
		</LinkElementContext.Consumer>
	);
}
