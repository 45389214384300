import React from "react";
import Icon from "../Icon";
import { bemModule } from "../../configs/bem";
import styles from "./icon-blurb.module.less";

const cn = bemModule(styles, "icon-blurb");
export default function IconBlurb(props) {
  const {
    mobileClasses,
    icon,
    text,
    iconColor = "ebonyClay",
    direction,
    inline = direction === "rtl",
    size = "md",
    iconSize
  } = props;
  return (
    <div className={mobileClasses ? `${cn()} ${mobileClasses}` : cn()}>
      <div className={cn("wrapper", { inline })}>
        <Icon
          width={iconSize}
          className={cn("icon", { size, color: iconColor })}
          icon={icon}
        />
        <div className={cn("text", { size })}>{text}</div>
      </div>
    </div>
  );
}
