import { css, withBem } from "../../sitches.config";

export const styles = withBem("icon-button", {
  iconButton: css({
    border: "none",
    backgroundColor: "transparent",
    color: "var(--icon-button-color, $textColor)",
    margin: 0,
    padding: 0,
    cursor: "pointer",
    variants: {
      outline: {
        true: {
          padding: "$2",
          backgroundColor: "$white",
          border: "solid 1px $gallery",
          borderRadius: "$1"
        }
      },
      size: {
        tiny: {
          fontSize: "$16"
        },
        small: {
          fontSize: "$20"
        },
        medium: {
          fontSize: "$24"
        }
      },
      disabled: {
        true: {
          cursor: "default",
          color: "$mercury"
        }
      }
    }
  }),
  icon: css({
    display: "block"
  })
});
