import React from "react";

import styles from "./landing-header.module.less";
import CategorySticker from "../CategorySticker";
import NuovoBreadcrumbs from "../NuovoBreadcrumbs/NuovoBreadcrumbs";
import Image from "../Image";

const ColorToBreadcrumbsColorMap = {
  sun: "white",
  "tulip-tree": "white"
};

const LandingHeader = props => (
  <div
    className={`
  ${styles["landing-header"]}
  ${styles[`landing-header--${props.color}`]}
  ${
    props.secondaryColor
      ? styles[`landing-header--md-${props.secondaryColor}`]
      : ""
  }
`}
  >
    <div className={styles["landing-header__body"]}>
      <div className={styles["landing-header__text-column"]}>
        <div className={styles["landing-header__column-body"]}>
          {props.breadcrumbs && (
            <NuovoBreadcrumbs
              className={styles["landing-header__breadcrumbs"]}
              breadcrumbs={props.breadcrumbs}
              onClick={props.onBreadcrubsClick}
              color={ColorToBreadcrumbsColorMap[props.color]}
              condensed={false}
            />
          )}
          <div
            className={`
          ${styles["landing-header__title"]}
          ${styles[`landing-header__title--${props.color}`]}
        `}
          >
            {props.title}
          </div>
          {props.description && (
            <div
              className={`
            ${styles["landing-header__description"]}
            ${styles[`landing-header__description--${props.color}`]}
          `}
            >
              {props.description}
            </div>
          )}
          {props.children && (
            <div className={styles["landing-header__input"]}>
              {props.children}
            </div>
          )}
        </div>
      </div>
      {/* EMBEDED IMAGE COLUMN */}
      {props.embedImage && props.image && (
        <div
          className={`
        ${styles["landing-header__image-column"]}
        ${
          props.secondaryColor
            ? styles[`landing-header__image-column--${props.secondaryColor}`]
            : ""
        }
      `}
        >
          <div className={styles["landing-header__column-body"]}>
            <div className={styles["landing-header__image-wrap"]}>
              <img
                className={styles["landing-header__image"]}
                src={props.image}
              />
            </div>
          </div>
        </div>
      )}
      {props.popoutImage && (
        <div className={styles["landing-header__popout-image-wrapper"]}>
          <img
            className={styles["landing-header__popout-image"]}
            src={props.popoutImage}
          />
        </div>
      )}
    </div>
    <div
      className={`
    ${styles["landing-header__right-background"]}
    ${styles[`landing-header__right-background--${props.secondaryColor}`]}
`}
    >
      {!props.embedImage && props.category && (
        <div
          className={`
        ${styles["landing-header__sticker"]}
        ${
          styles[
            `landing-header__sticker--${props.stickerPosition ||
              "bottom-right"}`
          ]
        }
      `}
        >
          <CategorySticker category={props.category} height={135} />
        </div>
      )}
      {!props.embedImage && (
        <Image
          isTransparent
          asBackground
          src={props.image}
          className={styles["landing-header__full-image"]}
        />
      )}
    </div>
  </div>
);

export default LandingHeader;
