import React from "react";
import styles from "./withSuperPosition.module.less";
import useMediaQuery from "../../hooks/useMediaQuery";

const withSuperPosition = (
  WrappedComponent,
  TopComponent,
  position = {},
  transform
) => {
  const isMobile = useMediaQuery("(max-width: 576px)");
  const defaultPosition = {
    position: "absolute",
    left: "auto",
    right: "auto",
    bottom: "auto"
  };

  const activePosition = { ...defaultPosition, ...position };

  const withSuperPosition = props => {
    return (
      <div className={styles["with-super-position"]}>
        <div className={styles["with-super-position__child"]}>
          <WrappedComponent {...props} />
        </div>
        <span
          className={styles[`with-super-position__top`]}
          style={{
            position: defaultPosition.position,
            top: activePosition.top,
            bottom: activePosition.bottom,
            left:
              isMobile && activePosition.left !== "auto"
                ? 0
                : activePosition.left,
            right:
              isMobile && activePosition.right !== "auto"
                ? 0
                : activePosition.right,
            transform
          }}
        >
          {React.isValidElement(TopComponent) ? TopComponent : <TopComponent />}
        </span>
      </div>
    );
  };

  withSuperPosition.displayName = `withSuperPosition(${WrappedComponent.displayName ||
    WrappedComponent.name})`;

  return withSuperPosition;
};

export default withSuperPosition;
