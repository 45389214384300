import React, { useState } from "react";
import { bem } from "../../configs/bem";
import { styles } from "./ProductFaqSection.styles";
import Icon from "../Icon";
import * as Icons from "../Icons";

const cn = bem("product-faq-section");
export function ProductFaqSection(props) {
  const { faq = [] } = props;

  const [open, setOpen] = useState(() => faq.map(() => false));

  function onQuestionClick(index) {
    const state = [...open];
    state[index] = !state[index];
    setOpen(state);
  }

  return (
    <div className={cn()}>
      <div className={`${cn("faq-wrapper")} ${styles.faqWrapper}`}>
        {faq.map((faq, index) => (
          <div
            key={index}
            className={`${cn("faq", { open: open[index] })} ${styles.faq({
              isOpen: open[index]
            })}`}
          >
            <div
              className={`${cn("question")} ${styles.question}`}
              onClick={() => onQuestionClick(index)}
            >
              {faq.question}
              <Icon
                className={`${cn("question-tick")} ${styles.questionTick}`}
                icon={Icons.ChevronLeft}
                height={22}
              />
            </div>
            <div className={`${cn("answer")} ${styles.answer}`}>
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
