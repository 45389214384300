import _ from "lodash";

const Booking_StartDateTime = booking => {
  if (booking.bookingType === "block") {
    if (!_.isNil(booking.blockBookingStartDate)) {
      return booking.blockBookingStartDate;
    }
    return false;
  }
  if (booking.newStartDateTime) {
    return booking.newStartDateTime;
  }
  return booking.session.startDateTime;
};
export default Booking_StartDateTime;
