import React from "react";
import PropTypes from "prop-types";
// Components
import RadioButtons from "../RadioButtons";
// Constants
import { CCourseFormatsOptions } from "@obby/lib";


export function CourseFormatFilter({
  format, 
  onChange, 
  color = "robbinsEggBlue", 
  options = [
  { label: "All" },
  ...Object.keys(CCourseFormatsOptions).map(type => ({
    label: CCourseFormatsOptions[type],
    value: type
  }))]}) {
  return (
    <div className="course-format-filter">
      <RadioButtons
        value={format}
        color={color}
        options={options}
        onChange={onChange}
      />
    </div>
  );
}

CourseFormatFilter.propTypes = {
  format: PropTypes.string,
  onChange: PropTypes.func,
  color: PropTypes.string
};
