import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
// @ts-ignore
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
// @ts-ignore
import { Timezones } from "@obby/constants";

import Icons from "../Icons";
import Icon from "../Icon";
import styles from "./date-picker-input.module.less";
// @ts-ignore
import { UTCDate__DiffOf } from "@obby/lib";

export function DatePickerInput({
  name,
  value,
  onChange = () => {},
  fullScreen = false,
  format,
  displayFormat = format || "YYYY-MM-DD",
  position = "bottom",
  hideIcon = false,
  placeholder,
  isOutsideRange,
  accent = "brightTeal",
  timezone = Timezones.EUROPE_LONDON
}: Props) {
  const [focused, setFocused] = useState(false);

  const [date, setDate] = useState(
    value ? moment(value, format).tz(timezone) : null
  );

  useEffect(() => {
    setDate(value ? moment(value, format).tz(timezone) : null);
  }, [value]);

  function onDateChange(date: any | null) {
    let newValue = null;
    if (date) {
      date.tz(timezone).startOf("day");
      if (value) {
        const minutes = UTCDate__DiffOf(value, timezone, "minutes", "day");
        date.add(minutes, "minutes");
      }
      newValue = format ? date.format(format) : date.toISOString();
    }
    setDate(date);
    onChange(newValue, name);
  }

  function onFocusChange(e: { focused: boolean }) {
    setFocused(e.focused);
  }

  return (
    <div
      className={`${styles["date-picker-input"]} ${
        styles[`date-picker-input--position-${position}`]
      } ${hideIcon ? styles["date-picker-input--no-icon"] : ""}${
        accent
          ? `${styles["date-picker-input"]} ${
              styles[`date-picker-input--accent-${accent}`]
            }`
          : ""
      }`}
    >
      <SingleDatePicker
        id={name}
        date={date}
        placeholder={placeholder}
        onDateChange={onDateChange}
        focused={focused}
        onFocusChange={onFocusChange}
        numberOfMonths={1}
        noBorder={true}
        hideKeyboardShortcutsPanel={true}
        enableOutsideDays={true}
        showClearDate={false}
        displayFormat={displayFormat}
        withFullScreenPortal={fullScreen}
        openDirection={positionToDirection(position)}
        isOutsideRange={isOutsideRange}
      />
      {!hideIcon && (
        <Icon
          className={styles["date-picker-input__icon"]}
          icon={Icons.newCalendar}
          height={20}
        />
      )}
    </div>
  );
}

function positionToDirection(position: string) {
  switch (position) {
    case "top":
      return "up";
    case "bottom":
      return "down";
    default:
      return undefined;
  }
}

interface Props {
  required?: boolean;
  readOnly?: any;
  name?: any;
  value: string | null;
  onChange?: (date: string | null, name: string) => void;
  fullScreen?: boolean;
  format?: string;
  displayFormat?: string;
  position?: "bottom" | "top";
  hideIcon?: boolean;
  placeholder?: string;
  isOutsideRange?: (day: any) => boolean;
  accent?: string | false;
  timezone?: string;
}
