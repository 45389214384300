import { Currencies } from "./Currencies";
export const CurrenciesLabels = {
  [Currencies.GBP]: "British Pound",
  [Currencies.EUR]: "Euro",
  [Currencies.USD]: "United States Dollar",
  [Currencies.AED]: "United Arab Emirates Dirham",
  [Currencies.AFN]: "Afghan Afghani",
  [Currencies.ALL]: "Albanian Lek",
  [Currencies.AMD]: "Armenian Dram",
  [Currencies.ANG]: "Netherlands Antillean Gulden",
  [Currencies.AOA]: "Angolan Kwanza",
  [Currencies.ARS]: "Argentine Peso",
  [Currencies.AUD]: "Australian Dollar",
  [Currencies.AWG]: "Aruban Florin",
  [Currencies.AZN]: "Azerbaijani Manat",
  [Currencies.BAM]: "Bosnia & Herzegovina Convertible Mark",
  [Currencies.BBD]: "Barbadian Dollar",
  [Currencies.BDT]: "Bangladeshi Taka",
  [Currencies.BGN]: "Bulgarian Lev",
  [Currencies.BMD]: "Bermudian Dollar",
  [Currencies.BND]: "Brunei Dollar",
  [Currencies.BOB]: "Bolivian Boliviano",
  [Currencies.BRL]: "Brazilian Real",
  [Currencies.BSD]: "Bahamian Dollar",
  [Currencies.BWP]: "Botswana Pula",
  [Currencies.BYN]: "Belarusian Ruble",
  [Currencies.BZD]: "Belize Dollar",
  [Currencies.CAD]: "Canadian Dollar",
  [Currencies.CDF]: "Congolese Franc",
  [Currencies.CHF]: "Swiss Franc",
  [Currencies.COP]: "Colombian Peso",
  [Currencies.CRC]: "Costa Rican Colón",
  [Currencies.CVE]: "Cape Verdean Escudo",
  [Currencies.CZK]: "Czech Koruna",
  [Currencies.DKK]: "Danish Krone",
  [Currencies.DOP]: "Dominican Peso",
  [Currencies.DZD]: "Algerian Dinar",
  [Currencies.EGP]: "Egyptian Pound",
  [Currencies.ETB]: "Ethiopian Birr",
  [Currencies.FJD]: "Fijian Dollar",
  [Currencies.FKP]: "Falkland Islands Pound",
  [Currencies.GEL]: "Georgian Lari",
  [Currencies.GIP]: "Gibraltar Pound",
  [Currencies.GMD]: "Gambian Dalasi",
  [Currencies.GTQ]: "Guatemalan Quetzal",
  [Currencies.GYD]: "Guyanese Dollar",
  [Currencies.HKD]: "Hong Kong Dollar",
  [Currencies.HNL]: "Honduran Lempira",
  [Currencies.HRK]: "Croatian Kuna",
  [Currencies.HTG]: "Haitian Gourde",
  [Currencies.HUF]: "Hungarian Forint",
  [Currencies.IDR]: "Indonesian Rupiah",
  [Currencies.ILS]: "Israeli New Sheqel",
  [Currencies.INR]: "Indian Rupee",
  [Currencies.ISK]: "Icelandic Króna",
  [Currencies.JMD]: "Jamaican Dollar",
  [Currencies.KES]: "Kenyan Shilling",
  [Currencies.KGS]: "Kyrgyzstani Som",
  [Currencies.KHR]: "Cambodian Riel",
  [Currencies.KYD]: "Cayman Islands Dollar",
  [Currencies.KZT]: "Kazakhstani Tenge",
  [Currencies.LAK]: "Lao Kip",
  [Currencies.LBP]: "Lebanese Pound",
  [Currencies.LKR]: "Sri Lankan Rupee",
  [Currencies.LRD]: "Liberian Dollar",
  [Currencies.LSL]: "Lesotho Loti",
  [Currencies.MAD]: "Moroccan Dirham",
  [Currencies.MDL]: "Moldovan Leu",
  [Currencies.MKD]: "Macedonian Denar",
  [Currencies.MMK]: "Myanmar Kyat",
  [Currencies.MNT]: "Mongolian Tögrög",
  [Currencies.MOP]: "Macanese Pataca",
  [Currencies.MRO]: "Mauritanian Ouguiya",
  [Currencies.MUR]: "Mauritian Rupee",
  [Currencies.MVR]: "Maldivian Rufiyaa",
  [Currencies.MWK]: "Malawian Kwacha",
  [Currencies.MXN]: "Mexican Peso",
  [Currencies.MYR]: "Malaysian Ringgit",
  [Currencies.MZN]: "Mozambican Metical",
  [Currencies.NAD]: "Namibian Dollar",
  [Currencies.NGN]: "Nigerian Naira",
  [Currencies.NIO]: "Nicaraguan Córdoba",
  [Currencies.NOK]: "Norwegian Krone",
  [Currencies.NPR]: "Nepalese Rupee",
  [Currencies.NZD]: "New Zealand Dollar",
  [Currencies.PAB]: "Panamanian Balboa",
  [Currencies.PEN]: "Peruvian Nuevo Sol",
  [Currencies.PGK]: "Papua New Guinean Kina",
  [Currencies.PHP]: "Philippine Peso",
  [Currencies.PKR]: "Pakistani Rupee",
  [Currencies.PLN]: "Polish Złoty",
  [Currencies.QAR]: "Qatari Riyal",
  [Currencies.RON]: "Romanian Leu",
  [Currencies.RSD]: "Serbian Dinar",
  [Currencies.SAR]: "Saudi Riyal",
  [Currencies.SBD]: "Solomon Islands Dollar",
  [Currencies.SCR]: "Seychellois Rupee",
  [Currencies.SEK]: "Swedish Krona",
  [Currencies.SGD]: "Singapore Dollar",
  [Currencies.SHP]: "Saint Helenian Pound",
  [Currencies.SLL]: "Sierra Leonean Leone",
  [Currencies.SOS]: "Somali Shilling",
  [Currencies.SRD]: "Surinamese Dollar",
  [Currencies.STD]: "São Tomé and Príncipe Dobra",
  [Currencies.SZL]: "Swazi Lilangeni",
  [Currencies.THB]: "Thai Baht",
  [Currencies.TJS]: "Tajikistani Somoni",
  [Currencies.TOP]: "Tongan Paʻanga",
  [Currencies.TRY]: "Turkish Lira",
  [Currencies.TTD]: "Trinidad and Tobago Dollar",
  [Currencies.TWD]: "New Taiwan Dollar",
  [Currencies.TZS]: "Tanzanian Shilling",
  [Currencies.UAH]: "Ukrainian Hryvnia",
  [Currencies.UYU]: "Uruguayan Peso",
  [Currencies.UZS]: "Uzbekistani Som",
  [Currencies.WST]: "Samoan Tala",
  [Currencies.XCD]: "East Caribbean Dollar",
  [Currencies.YER]: "Yemeni Rial",
  [Currencies.ZAR]: "South African Rand",
  [Currencies.ZMW]: "Zambian Kwacha"
};
