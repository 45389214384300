import * as Yup from "yup";

export function PersonalDetailsFormSchema(disablePhoneNumber: boolean = false) {
  return Yup.object({
    firstName: Yup.string()
      .label("First name")
      .required()
      .min(2, "First name is required")
      .default(""),
    lastName: Yup.string()
      .label("Surname")
      .required()
      .min(2, "Surname is required")
      .default(""),
    guest: Yup.boolean().default(false),
    email: Yup.string()
      .label("Email")
      .email()
      .when("guest", { is: false, then: Yup.string().required() })
      .default(""),
    ...(disablePhoneNumber
      ? {}
      : {
          phoneNumber: Yup.string()
            .label("Phone number")
            .when("guest", { is: false, then: Yup.string().required() })
            .default("")
        }),
    marketingOptIn: Yup.boolean()
      .label("Marketing Opt in")
      .default(false),
    mandatoryTickBox: Yup.boolean()
      .label("Mandatory tick box")
      .default(false)
  }).required();
}

export type Fields = Yup.InferType<
  ReturnType<typeof PersonalDetailsFormSchema>
>;

let sdf: Fields;
