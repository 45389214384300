import { css } from "../../sitches.config";

export const styles = {
  teacherCard: css({
    color: "$ebonyClay"
  }),

  image: css({
    display: "block",
    width: "100%",
    ".cloudinary-image": {
      width: "100%"
    },

    variants: {
      variant: {
        landscape: {
          display: "block",
          "@smd": {
            display: "none"
          }
        },
        portrait: {
          display: "none",
          "@smd": {
            display: "block"
          }
        }
      }
    }
  }),

  title: css({
    fontFamily: "$foundersGrotesk",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "21px",
    lineHeight: "100%",
    letterSpacing: "0.01em",
    cursor: "pointer"
  }),

  titleLink: css({
    color: "$ebonyClay",
    textDecoration: "none",

    "&:hover, &:active, &:focus, &:hover, &:visited": {
      color: "$ebonyClay",
      textDecoration: "underline"
    }
  }),

  actions: css({
    display: "flex"
  })
};
