import { css } from "../../sitches.config";

export const styles = {
  nuovoBreadcrumbs: css({
    fontFamily: "$foundersGrotesk",
    fontSize: "14px",
    lineHeight: "1.4",
    letterSpacing: "0.1em",
    textTransform: "uppercase",

    "&--condensed": {
      fontFamily: "$foundersGroteskCondensed !important"
    },
    "&--silver": {
      color: "$silver"
    },
    "&--white": {
      color: "white"
    }
  }),

  crumb: css({
    cursor: "pointer",
    display: "inline-block",
    marginRight: "8px",

    "&::after": {
      content: "/",
      marginLeft: "8px"
    },

    "&:last-child": {
      "&::after": {
        content: ""
      }
    }
  })
};
