import { css } from "../../sitches.config";

export const styles = {
  component: css({
    position: "relative",
    fontFamily: "$foundersGrotesk",
    ".react-select": {
      color: "$ebonyClay",
      "&__control": {
        borderColor: "$gallery",
        minHeight: "45px",
        boxShadow: "none",
        fontSize: "$20",
        cursor: "pointer",
        "&:hover": {
          borderColor: "$gallery"
        },
        "&--menu-is-open": {
          borderRadius: "$1 $1 0 0",
          ".react-select__dropdown-indicator": {
            transform: "rotate(180deg)"
          }
        }
      },
      "&__indicator-separator": {
        display: "none"
      },
      "&__dropdown-indicator": {
        transition: "transform 250ms",
        alignSelf: "flex-end",
        margin: "3px 0"
      },
      "&__placeholder": {
        color: "$alto",
        fontSize: "$20",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
      },
      "&__menu": {
        margin: 0,
        borderRadius: "0 0 $1 $1",
        boxShadow: "none",
        borderStyle: "solid",
        borderColor: "$gallery",
        borderWidth: "0 1px 1px",
        overflow: "hidden"
      },
      "&__menu-list": {
        padding: 0,
        fontSize: "$20"
      },
      "&__option": {
        position: "relative",
        minHeight: "45px",
        "&--is-focused": {
          backgroundColor: "transparent",
          "&::before": {
            position: "absolute",
            content: "",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0.12,
            zIndex: -1
          }
        },
        "&--is-selected": {
          color: "$white"
        }
      },
      "&__multi-value": {
        padding: "0 4px 0 8px",
        height: "28px",
        margin: "0 4px",
        borderRadius: "4px",
        alignItems: "center",
        "&__label": {
          fontSize: "inherit",
          padding: 0,
          lineHeight: 1
        },

        "&__remove": {
          color: "$silver",
          cursor: "pointer",
          "&:hover": {
            color: "#999999",
            backgroundColor: "transparent"
          },
          svg: {
            height: "23px",
            width: "23px",
            "@md": {
              height: "20px",
              width: "20px"
            }
          }
        }
      },
      "&__clear-indicator": {
        display: "none",
        color: "$silver",
        svg: {
          height: "23px",
          width: "23px",
          "@md": {
            height: "20px",
            width: "20px"
          }
        }
      },
      "&__indicators": {
        flexShrink: "unset"
      },
      "&__input input": {
        fontFamily: "$founders-grotesk"
      }
    },
    variants: {
      size: {
        small: {
          ".react-select": {
            "&__control": {
              minHeight: "34px",
              fontSize: "$18"
            },

            "&__placeholder": {
              fontSize: "$18"
            },

            "&__indicator": {
              padding: "3px"
            },

            "&__menu-list": {
              fontSize: "$18"
            },

            "&__option": {
              position: "relative",
              minHeight: "34px",
              padding: "7px 12px 6px"
            }
          }
        },
        medium: {
          ".react-select": {
            "&__control": {
              minHeight: "45px",
              fontSize: "$20"
            },

            "&__placeholder": {
              fontSize: "$20"
            },

            "&__indicator": {
              padding: "8px"
            },

            "&__menu-list": {
              fontSize: "$20"
            },

            "&__option": {
              position: "relative",
              minHeight: "45px",
              padding: "8px 12px 7px"
            }
          }
        }
      },
      menuPlacement: {
        top: {
          ".react-select": {
            "&__control": {
              "&--menu-is-open": {
                borderRadius: "0 0 $1 $1"
              },
              "$--menu-is-open.$--is-focused": {
                borderTopColor: "$gallery"
              }
            },

            "&__menu": {
              borderRadius: "$1 $1 0 0",
              borderWidth: "1px 1px 0"
            }
          }
        },
        bottom: {
          ".react-select": {
            "&__control": {
              "&--menu-is-open": {
                borderRadius: "$1 $1 0 0"
              },
              "$--menu-is-open.$--is-focused": {
                borderBottomColor: "$gallery"
              }
            }
          }
        }
      },
      rounded: {},
      icon: {},
      clear: {
        true: {
          ".react-select__clear-indicator": {
            display: "flex !important"
          }
        }
      }
    },
    compoundVariants: [
      {
        size: "small",
        rounded: true,
        css: {
          ".react-select": {
            "&__control": {
              borderRadius: "17px",
              "&--menu-is-open": {
                borderRadius: "17px 17px 0 0"
              }
            }
          }
        }
      },
      {
        size: "medium",
        rounded: true,
        css: {
          ".react-select": {
            "&__control": {
              borderRadius: "22.5px",
              "&--menu-is-open": {
                borderRadius: "22.5px 22.5px 0 0"
              }
            }
          }
        }
      },
      {
        size: "small",
        icon: true,
        css: {
          ".react-select__value-container": {
            paddingLeft: "34px"
          }
        }
      },
      {
        size: "medium",
        icon: true,
        css: {
          ".react-select__value-container": {
            paddingLeft: "45px"
          }
        }
      }
    ]
  }),
  icon: css({
    position: "absolute",
    top: 0,
    left: 0,
    variants: {
      size: {
        small: {
          margin: "10px",
          width: "14px",
          height: "14px"
        },

        medium: {
          margin: "14.5px",
          width: "16px",
          height: "16px"
        }
      }
    }
  })
};
