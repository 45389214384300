import React, { useState, useEffect } from "react";
import { Box } from "../../../layout/Box";
import { styles } from "./PricingOptionBox.styles";

import {
  CurrencyISO__CurrencySymbol,
  Number__Currency,
  Duration__DurationString
  // @ts-ignore
} from "@obby/lib";
import HighlightPrice from "../../../components/HighlightPrice";

export function PricingOptionBox({
  compacted = false,
  currency,
  description,
  duration,
  name,
  onSelect,
  price,
  optionsTotal = 0,
  isDiscounted = true,
  discountPrice = 0,
  ...props
}: Props) {
  const [selected, setSelected] = useState(props.selected);
  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  function onBoxClick() {
    if (selected) return;
    setSelected(true);
    onSelect();
  }

  return (
    <Box
      borderColor={selected ? "emeraldish" : undefined}
      borderRadius={2}
      borderWidth={selected ? 2 : 1}
      className={styles.pricingOptionBox({ compacted })}
      onClick={compacted ? undefined : onBoxClick}
    >
      <div className={styles.wrapper({ compacted })}>
        <div className={styles.text()}>
          <div className={styles.name()}>{name}</div>
          {!compacted && description && <div>{description}</div>}
        </div>
        <div className={styles.duration()}>
          {Duration__DurationString(duration, {
            hour: " hour",
            hours: " hours",
            minute: " min",
            minutes: " mins"
          })}
        </div>
        {price > 0 && (
          <div className={styles.price({ isDiscounted })}>
            {isDiscounted == true && (
              <HighlightPrice
                price={discountPrice}
                pp={false}
                currency={currency}
              />
            )}

            {Number__Currency(price, CurrencyISO__CurrencySymbol(currency))}
          </div>
        )}
        {optionsTotal > 1 && price == 0 && (
          <div className={styles.price()}>Free</div>
        )}
      </div>
    </Box>
  );
}

interface Props {
  compacted?: boolean;
  currency: string;
  description?: string;
  duration: number;
  name: string;
  onSelect: () => void;
  price: number;
  selected: boolean;
  optionsTotal: number;
  isDiscounted?: boolean;
  discountPrice?: number;
}
