// this is import from the meteor methods to generate the random id that use on the model creation
const crypto = require("crypto");
const UNMISTAKABLE_CHARS =
  "23456789ABCDEFGHJKLMNPQRSTWXYZabcdefghijkmnopqrstuvwxyz";
export function randomID(charsCount = 17) {
  return _randomString(charsCount, UNMISTAKABLE_CHARS);
}

function _randomString(charsCount, alphabet) {
  var digits = [];
  for (var i = 0; i < charsCount; i++) {
    digits[i] = _choice(alphabet);
  }
  return digits.join("");
}

function _choice(arrayOrString) {
  var index = Math.floor(_fraction() * arrayOrString.length);
  if (typeof arrayOrString === "string") return arrayOrString.substr(index, 1);
  else return arrayOrString[index];
}

function _fraction() {
  var numerator = parseInt(_hexString(8), 16);
  return numerator * 2.3283064365386963e-10; // 2^-32
}

function _hexString(digits) {
  var numBytes = Math.ceil(digits / 2);
  var bytes;
  // Try to get cryptographically strong randomness. Fall back to
  // non-cryptographically strong if not available.
  try {
    bytes = crypto.randomBytes(numBytes);
  } catch (e) {
    // XXX should re-throw any error except insufficient entropy
    bytes = crypto.pseudoRandomBytes(numBytes);
  }
  var result = bytes.toString("hex");
  // If the number of digits is odd, we'll have generated an extra 4 bits
  // of randomness, so we need to trim the last digit.
  return result.substring(0, digits);
}
