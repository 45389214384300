import React from "react";

import Label from "../Label";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import { TextInput } from "../TextInput";

import styles from "./ResetPasswordForm.module.less";

export function ResetPasswordForm(props) {
  const {
    values,
    touched,
    errors,
    isResetting,
    resetPasswordError,
    onChange,
    onBlur,
    onSubmit
  } = props;
  const { email, newPassword, confirmNewPassword } = values;

  return (
    <div className={styles["reset-password-form"]}>
      <div className={`${styles["reset-password-form__heading"]} text-center`}>
        Set a new password for your Obby account.
      </div>
      <Label label="Email" error={touched.email && errors.email}>
        <TextInput
          name="email"
          value={email}
          onChange={onChange}
          onBlur={onBlur}
          disabled={isResetting}
        />
      </Label>
      <Label
        label="New password"
        error={touched.newPassword && errors.newPassword}
      >
        <TextInput
          name="newPassword"
          type="password"
          value={newPassword}
          onChange={onChange}
          onBlur={onBlur}
          disabled={isResetting}
        />
      </Label>
      <Label
        label="Confirm new password"
        error={touched.confirmNewPassword && errors.confirmNewPassword}
      >
        <TextInput
          name="confirmNewPassword"
          type="password"
          value={confirmNewPassword}
          onChange={onChange}
          onBlur={onBlur}
          disabled={isResetting}
        />
      </Label>
      <Label error={resetPasswordError}>
        <PrimaryButton
          text={isResetting ? "Updating password..." : "Update password"}
          color="emeraldish"
          onClick={onSubmit}
          disabled={isResetting}
        />
      </Label>
    </div>
  );
}
