import { css } from "../../sitches.config";

export const styles = {
  menuList: css({
    display: "flex",
    flexDirection: "column"
  }),

  menuItemWrapper: css({
    display: "flex",
    alignItems: "center",
    textDecoration: "none",

    "&:link, &:visited, &:hover, &:active": {
      textDecoration: "none"
    }
  }),

  menuHeader: css({
    fontSize: "18px",
    lineHeight: "80%",
    fontWeight: "500",
    color: "$ebonyClay",
    marginBottom: "17px",
    letterSpacing: "0.01em",
    fontFamily: "$foundersGrotesk"
  }),

  menuItem: css({
    lineHeight: "32px",
    color: "$ebonyClay",
    letterSpacing: "1px",
    fontSize: "$smParagraph",
    textTransform: "capitalize",
    fontFamily: "$foundersGrotesk"
  }),

  menuItemIcon: css({
    marginRight: "5px"
  })
};
