import React from "react";

import * as Icons from "../Icons";
import SocialSignInButton from "../SocialSignInButton/SocialSignInButton";

import styles from "./google-sign-in-button.module.less";

export default function GoogleSignInButton(props) {
  const {
    appId,
    text = "Login with Google",
    onClick = () => {},
    onLoginSuccess = () => {},
    onLoginFailure = () => {},
    width = "max",
    size = "medium"
  } = props;
  return (
    <SocialSignInButton
      className={styles["google-sign-in-button"]}
      provider={"google"}
      icon={Icons.GoogleG}
      appId={appId}
      text={text}
      onLoginSuccess={onLoginSuccess}
      onLoginFailure={onLoginFailure}
      width={width}
      size={size}
      onClick={onClick}
    />
  );
}
