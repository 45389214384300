import React, { useMemo } from "react";
import * as Icons from "../../components/Icons";
import { PopoutMenu } from "../PopoutMenu";
import { BookingSources, BookingTypes } from "@obby/constants";
import {
  CBookingPaymentStatuses,
  UTCDate__IsAfter,
  UTCDate__IsBefore
} from "@obby/lib";

export function BookingMenuPopout(props) {
  const {
    booking,
    canUseExternalBookings,
    canCancel,
    canRemoveGuests,
    canReschedule,
    canResendEmails,
    canEmail,
    onClose,
    sessionNumber = 1,
    startDateTime
  } = props;
  const isUpcoming = UTCDate__IsAfter(startDateTime);

  const actions = useMemo(() => {
    const actions = [];
    if (isEmailEnabled()) actions.push(["Email", props.onEmail, !canEmail]);
    if (isConfirmEnabled()) actions.push(["Confirm", props.onConfirm]);
    if (isRescheduleEnabled())
      actions.push([
        "Reschedule",
        props.onReschedule,
        booking.bookingType === BookingTypes.EXTERNAL
          ? !canUseExternalBookings
          : !canReschedule
      ]);
    if (isEditExternalBookingEnabled())
      actions.push([
        booking.buyerEmail ? "Edit" : "Edit details",
        props.onEditExternalBooking,
        !canUseExternalBookings
      ]);
    if (isCancelEnabled())
      actions.push([
        "Cancel",
        props.onCancel,
        booking.bookingType === BookingTypes.EXTERNAL
          ? !canUseExternalBookings
          : !canCancel
      ]);
    if (isRefundEnabled()) actions.push(["Refund", props.onRefund, !canCancel]);
    if (isEditNotesEnabled()) actions.push(["Notes", props.onEditNotes]);
    if (isResendEmailsEnabled())
      actions.push([
        "Resend auto-emails",
        props.onResendEmails,
        !canResendEmails
      ]);
    if (isRejectEnabled()) actions.push(["Reject", props.onReject]);
    if (isRemoveGuestEnabled())
      actions.push([
        "Remove guest",
        props.onRemoveGuest,
        booking.bookingType === BookingTypes.EXTERNAL
          ? !canUseExternalBookings
          : !canRemoveGuests && booking.source === BookingSources.OBBY
      ]);
    if (isSelectBundleDatesEnabled())
      actions.push(["Select bundle sessions", props.onSelectBundleDates]);
    if (isSelectBundle()) {
      actions.push(["Edit bundle expiry date", props.onSelectBundleExpiryDate]);
    }
    if (isUpdateBundleNumberOfTicketsEnabled())
      actions.push([
        "Update number of sessions",
        props.onUpdateBundleNumberOfTickets
      ]);
    return actions;
  }, [props]);

  function isCancelEnabled() {
    // It's not enabled if its already cancelled...
    if (booking.cancelled) return false;
    // ...or if it's a bundle booking and it's not the first booking
    if (booking.bookingType === "bundle_booking" && booking.bundleIndex !== 0)
      return false;
    // It's enabled if the booking is confirmed...
    if (booking.confirmation === "confirmed") return true;
    // ...or if the booking was never confirmed but already took place
    if (!booking.confirmation && !isUpcoming) return true;
    // otherwise it's not enabled
    return false;
  }

  function isConfirmEnabled() {
    return !booking.cancelled && !booking.confirmation && isUpcoming;
  }

  function isEditExternalBookingEnabled() {
    return (
      !booking.cancelled &&
      (booking.confirmation === "confirmed" ||
        (!booking.confirmation && !isUpcoming)) &&
      booking.bookingType === BookingTypes.EXTERNAL
    );
  }

  function isEditNotesEnabled() {
    return (
      (booking.confirmation !== "pending" ||
        (!booking.confirmation && !isUpcoming)) &&
      (booking.bookingType !== BookingTypes.BUNDLE_BOOKING ||
        booking.bundleIndex === 0)
    );
  }

  function isEmailEnabled() {
    return (
      !booking.cancelled &&
      (booking.confirmation === "confirmed" ||
        (!booking.confirmation && !isUpcoming)) &&
      booking.buyerEmail &&
      booking.status !== CBookingPaymentStatuses.PENDING
    );
  }

  function isRejectEnabled() {
    return (
      !booking.cancelled &&
      !booking.confirmation &&
      isUpcoming &&
      (booking.bookingType !== "bundle_booking" || booking.bundleIndex === 0)
    );
  }

  function isRemoveGuestEnabled() {
    return (
      (booking.bookingType === BookingTypes.REGULAR ||
        booking.bookingType === BookingTypes.EXTERNAL) &&
      booking.allTickets &&
      booking.allTickets.length > 1
    );
  }

  function isRescheduleEnabled() {
    // if it is not a private booking or it is refunded...
    if (booking.bookingType === BookingTypes.PRIVATE) return false;
    // if it's not an additional date and not a trial at the same time
    if (sessionNumber > 1 && !booking.isTrial) return false;
    // and it is confirmed or cancelled
    if (booking.confirmation === "confirmed" || booking.cancelled) return true;
    // or its either flexitime booking or it's in the past
    if (!booking.confirmation && (!isUpcoming || booking.anytimeClass))
      return true;
    return false;
  }

  function isRefundEnabled() {
    // Its disabled if it's not a regular booking...
    if (
      ![
        BookingTypes.REGULAR,
        BookingTypes.ANYTIME,
        BookingTypes.BUNDLE_BOOKING
      ].includes(booking.bookingType)
    )
      return false;
    // ...or if it's a bundle booking and it's not the first booking
    if (
      booking.bookingType === BookingTypes.BUNDLE_BOOKING &&
      booking.bundleIndex !== 0
    )
      return false;
    // ...or if its already refunded
    if (booking.refundedObject?.isRefunded) return false;
    // if it's not an additional date and not a trial at the same time
    if (sessionNumber > 1 && !booking.isTrial) return false;
    // it is enabled if the booking already got some action or is in the past
    if (booking.confirmation || !isUpcoming) return true;
    // otherwise it's not enabled
    return false;
  }

  function isResendEmailsEnabled() {
    // not enabled for private bookings
    if (booking.bookingType === BookingTypes.PRIVATE) return false;
    // disabled for bookings pending to be confirmed
    if (!booking.confirmation) return false;
    // enabled for everything else
    return true;
  }

  function isSelectBundle() {
    return booking.bookingType == BookingTypes.BUNDLE_BOOKING;
  }

  function isSelectBundleDatesEnabled() {
    // not enabled for non bundle bookings
    if (booking.bookingType !== BookingTypes.BUNDLE_BOOKING) return false;
    // not enabled for bookings that are not confirmed
    if (booking.confirmation !== "confirmed") return false;
    // enabled if we still have dates to fulfill
    return booking.bundleTotalTickets > booking.bundleRecords.length;
  }

  function isUpdateBundleNumberOfTicketsEnabled() {
    // not enabled for non bundle bookings
    if (booking.bookingType !== BookingTypes.BUNDLE_BOOKING) return false;
    // not enabled for bookings that are not confirmed
    if (booking.confirmation !== "confirmed") return false;
    // not enabled if bundle is expired
    if (UTCDate__IsBefore(booking.bundleExpireDate)) return false;
    // otherwise we can update number of sessions
    return true;
  }

  return (
    actions.length > 0 && (
      <PopoutMenu icon={Icons.MoreOptions} iconHeight={16} onClose={onClose}>
        {actions.map(([label, onClick, restricted], index) => (
          <PopoutMenu.Item
            key={index}
            restricted={restricted}
            onClick={onClick}
          >
            {label}
          </PopoutMenu.Item>
        ))}
      </PopoutMenu>
    )
  );
}
