import React, { useContext } from "react";

import { MenuNavBarSpace } from "./MenuNavBarSpace";
import styles from "./menu-nav-mobile-bar.module.less";
import Icon from "../Icon";
import Icons from "../Icons";
import { MenuNavContext } from "./MenuNavContext";

export function MenuNavMobileBar(props) {
  const { children, onHamburgerClick } = props;
  const { textColor, textColorHover } = useContext(MenuNavContext);
  return (
    <ul className={`${styles["menu-nav-mobile-bar"]} pl-2`}>
      {React.Children.map(children, child => {
        if (!child) return <></>;
        const { desktop = false } = child.props;
        if (desktop) return <></>;
        // early return if the child is not a bar space
        if (child.type !== MenuNavBarSpace) return <></>;
        return child;
      })}
      <div className={`${styles["menu-nav-mobile-bar__tools"]} pl-4`}>
        <Icon
          className={`${styles["menu-nav-mobile-bar__hamburger"]} ${
            styles[`menu-nav-mobile-bar__hamburger--color-${textColor}`]
          } ${
            styles[
              `menu-nav-mobile-bar__hamburger--hover-color-${textColorHover}`
            ]
          }`}
          icon={Icons.Hamburger}
          onClick={onHamburgerClick}
        />
      </div>
    </ul>
  );
}
