import { store } from "statorgfc";
import { Subject } from "rxjs";
import { merge, mergeDeepRight, without } from "ramda";
import AuthenticationStatuses from "../constants/AuthenticationStatuses";
import authInstance from "../config/auth";
import isValidEmail from "../lib/isValidEmail";
import isNestedObjectEmpty from "../lib/isNestedObjectEmpty";
import axios from "../config/axios";

const modelName = "authentication";

const getDefaultModel = initialData => {
  return {
    categories: initialData.categories || getDefaultCategoriesModel(),
    authentication: {
      status: AuthenticationStatuses.SIGNED_OUT,
      firstName: "",
      lastName: "",
      signInEmail: "",
      signInPassword: "",
      isSignedUpToNewsletter: true,
      errors: {
        general: "",
        inputs: {
          firstName: "",
          lastName: "",
          email: "",
          password: ""
        }
      },
      user: null,
      school: {},
      isCurrentUserTeacher: false
    },
    sales: initialData.sales || undefined
  };
};

function getDefaultCategoriesModel() {
  return {
    categoryNames: [],
    hierarchical: [],
    labelByName: {},
    subcategoryNames: []
  };
}

const events = {
  start: new Subject(),
  beginSignIn: new Subject(),
  beginSignUp: new Subject(),
  beginEmailSignUp: new Subject(),
  beginResetingPassword: new Subject(),
  endSignIn: new Subject(),
  typeFirstName: new Subject(),
  typeLastName: new Subject(),
  typeEmail: new Subject(),
  typePassword: new Subject(),
  toggleNewsletterSignUp: new Subject(),
  signUp: new Subject(),
  signUpSuccess: new Subject(),
  signUpFaliure: new Subject(),
  signIn: new Subject(),
  signOut: new Subject(),
  socialSignIn: new Subject(),
  socialSignInFailed: new Subject(),
  checkoutAsGuest: new Subject(),
  resetPassword: new Subject(),
  resetPasswordSuccess: new Subject(),
  resetPasswordFaliure: new Subject(),
  signInSuccess: new Subject(),
  signInFailure: new Subject(),
  addClassToWishList: new Subject(),
  addClassToWishListSuccess: new Subject(),
  addClassToWishListFaliure: new Subject(),
  removeClassFromWishList: new Subject(),
  removeClassFromWishListSuccess: new Subject(),
  removeClassFromWishListFaliure: new Subject(),
  userUpdate: new Subject()
};

events.start.subscribe(start);
events.beginSignIn.subscribe(beginSignIn);
events.endSignIn.subscribe(endSignIn);
events.typeFirstName.subscribe(typeFirstName);
events.typeLastName.subscribe(typeLastName);
events.typePassword.subscribe(typePassword);
events.typeEmail.subscribe(typeEmail);
events.toggleNewsletterSignUp.subscribe(toggleNewsletterSignUp);
events.signIn.subscribe(signIn);
events.signInSuccess.subscribe(signInSuccess);
events.signInSuccess.subscribe(trackSignIn);
events.signInFailure.subscribe(signInFailure);
events.socialSignIn.subscribe(socialSignIn);
events.socialSignInFailed.subscribe(socialSignInFailed);
events.checkoutAsGuest.subscribe(checkoutAsGuest);
events.beginResetingPassword.subscribe(beginResetingPassword);
events.resetPassword.subscribe(resetPassword);
events.resetPasswordSuccess.subscribe(resetPasswordSuccess);
events.resetPasswordFaliure.subscribe(resetPasswordFaliure);

events.beginSignUp.subscribe(beginSignUp);
events.beginEmailSignUp.subscribe(beginEmailSignUp);
events.signUp.subscribe(signUp);
events.signUpSuccess.subscribe(signUpSuccess);
events.signUpSuccess.subscribe(trackSignUp);
events.signUpFaliure.subscribe(signUpFaliure);
events.signOut.subscribe(signOut);

events.addClassToWishList.subscribe(addClassToWishList);
events.removeClassFromWishList.subscribe(removeClassFromWishList);
events.addClassToWishListSuccess.subscribe(addClassToWishListSuccess);
events.addClassToWishListFaliure.subscribe(addClassToWishListFaliure);
events.removeClassFromWishListSuccess.subscribe(removeClassFromWishListSuccess);
events.removeClassFromWishListFaliure.subscribe(removeClassFromWishListFaliure);
events.userUpdate.subscribe(onUserUpdate);

function start({ user, school, isCurrentUserTeacher, sales }) {
  var model = store.get(modelName);
  store.set(modelName, {
    ...model,
    user,
    school,
    isCurrentUserTeacher,
    status: user
      ? AuthenticationStatuses.SIGNED_IN
      : AuthenticationStatuses.SIGNED_OUT
  });
  // store.set("sales", sales);
}

async function socialSignIn({ service, token }) {
  const response = await authInstance.socialLogin(service, token);
  events.signInSuccess.next(response);
}

function socialSignInFailed({ service, error }) {
  console.info(`${service} social sign in failed`);
  console.info(error);
}

function beginSignIn(forCheckout = false) {
  var model = store.get(modelName);
  var newState = {
    status: forCheckout
      ? AuthenticationStatuses.SIGNING_IN_FOR_CHECKOUT
      : AuthenticationStatuses.SIGNING_IN
  };
  store.set(modelName, merge(model, newState));
}

function endSignIn() {
  var defaultModel = getDefaultModel({})[modelName];
  var model = store.get(modelName);
  var newState = {
    errors: defaultModel.errors,
    signInEmail: "",
    signInPassword: "",
    firstName: "",
    lastName: "",
    status: AuthenticationStatuses.SIGNED_OUT
  };
  store.set(modelName, merge(model, newState));
}

function typeLastName(lastName) {
  var model = store.get(modelName);
  var newState = { lastName };
  store.set(modelName, merge(model, newState));
}

function typeFirstName(firstName) {
  var model = store.get(modelName);
  var newState = { firstName };
  store.set(modelName, merge(model, newState));
}

function typeEmail(signInEmail) {
  var model = store.get(modelName);
  var newState = { signInEmail };
  store.set(modelName, merge(model, newState));
}

function typePassword(signInPassword) {
  var model = store.get(modelName);
  var newState = { signInPassword };
  store.set(modelName, merge(model, newState));
}

function onUserUpdate(user) {
  var model = store.get(modelName);
  var newState = { user };

  const mergedState = merge(model, newState);
  store.set(modelName, mergedState);
}

function toggleNewsletterSignUp() {
  var model = store.get(modelName);
  var newState = { isSignedUpToNewsletter: !model.isSignedUpToNewsletter };

  const mergedState = merge(model, newState);
  store.set(modelName, mergedState);
}

async function signIn() {
  var model = store.get(modelName);
  var response = await authInstance.login(
    model.signInEmail,
    model.signInPassword
  );
  if (response.error) {
    events.signInFailure.next(response);
  } else {
    events.signInSuccess.next(response);
  }
}

function signInSuccess(data) {
  var model = store.get(modelName);
  var newState = {
    signInEmail: "",
    signInPassword: "",
    user: data.user,
    isCurrentUserTeacher: data.isCurrentUserTeacher,
    school: data.school,
    status: AuthenticationStatuses.SIGNED_IN
  };
  var mergedState = merge(model, newState);
  store.set(modelName, mergedState);
}

function trackSignIn(data) {
  if (window.rudderanalytics)
    window.rudderanalytics.track("Logged In", {
      user_id: data.user._id,
      email: data.user.renderEmail,
      invite_id: data.user.referrer ? data.user.referrer._id : "",
      referal_code: data.user.referrer
        ? data.user.referrer.profile.referralCode
        : "",
      created_at: data.user.createdAt,
      authentication: "email",
      school_id: data.user.schoolId,
      bookings: data.user.bookings ? data.user.bookings.length : "",
      gmv: "£" + (data.user.totalSpent / 100).toFixed(2)
    });
  if (/^\/bookings.*/.test(window.location.pathname)) {
    window.location.reload();
  }
}

function signInFailure(data) {
  var model = store.get(modelName);
  var newState = {
    errors: {
      general: data.error.message
    }
  };
  store.set(modelName, mergeDeepRight(model, newState));
}

async function resetPassword() {
  var model = store.get(modelName);
  var response = await authInstance.resetPassword(model.signInEmail);
  if (response.error) {
    events.resetPasswordFaliure.next(response);
  } else {
    events.resetPasswordSuccess.next(response);
  }
}

function resetPasswordSuccess() {
  var model = store.get(modelName);
  var newState = {
    status: AuthenticationStatuses.PASSWORD_RESET
  };
  store.set(modelName, merge(model, newState));
}

function resetPasswordFaliure(data) {
  var model = store.get(modelName);
  var newState = {
    errors: {
      general: data.error.message
    }
  };
  store.set(modelName, mergeDeepRight(model, newState));
}

function beginSignUp() {
  var model = store.get(modelName);
  var newState = {
    status: AuthenticationStatuses.SIGNING_UP_VIA_SOCIAL
  };
  store.set(modelName, merge(model, newState));
}

function beginEmailSignUp() {
  var model = store.get(modelName);
  var newState = {
    status: AuthenticationStatuses.SIGNING_UP_VIA_EMAIL
  };
  store.set(modelName, merge(model, newState));
}

function beginResetingPassword() {
  var model = store.get(modelName);
  var newState = {
    status: AuthenticationStatuses.RESETING_PASSWORD
  };
  store.set(modelName, merge(model, newState));
}

function checkoutAsGuest() {
  var model = store.get(modelName);
  var newState = {
    status: AuthenticationStatuses.SIGNED_OUT
  };
  store.set(modelName, merge(model, newState));
}

async function signUp() {
  var model = validateEmailSignUpModel(store.get(modelName));
  var isValid = isNestedObjectEmpty(model.errors);
  store.set(modelName, model);

  if (isValid) {
    await postEmailSignUp();
  }
}

function validateEmailSignUpModel(model) {
  var defaultModel = getDefaultModel({});
  model.errors = defaultModel[modelName].errors;

  if (model.firstName.length < 2) {
    model.errors.inputs.firstName = "Please enter a valid first name";
  }

  if (model.lastName.length < 2) {
    model.errors.inputs.lastName = "Please enter a valid last name";
  }

  if (!isValidEmail(model.signInEmail)) {
    model.errors.inputs.email = "Please enter a valid email";
  }

  if (model.signInPassword.length === 0) {
    model.errors.inputs.password = "Please enter a password";
  }

  return model;
}

async function postEmailSignUp() {
  var model = store.get(modelName);
  var response = await authInstance.signUp({
    email: model.signInEmail,
    password: model.signInPassword,
    firstName: model.firstName,
    lastName: model.lastName,
    isSignedUpToNewsletter: model.isSignedUpToNewsletter
  });
  if (response.error) {
    events.signUpFaliure.next(response);
  } else {
    events.signUpSuccess.next(response);
  }
}

function signUpSuccess(data) {
  var model = store.get(modelName);
  var newState = {
    errors: getDefaultModel({})[modelName].errors,
    status: AuthenticationStatuses.SIGNED_IN,
    user: data.user,
    isCurrentUserTeacher: data.isCurrentUserTeacher,
    school: data.school
  };
  var mergedState = merge(model, newState);
  store.set(modelName, mergedState);
}

function signUpFaliure(data) {
  var model = store.get(modelName);
  var newState = {
    errors: {
      general: data.error.message
    }
  };
  store.set(modelName, mergeDeepRight(model, newState));
}

function trackSignUp(data) {
  if (window.rudderanalytics)
    window.rudderanalytics.track("Account Created", {
      user_id: data.user._id,
      email: data.user.renderEmail,
      invite_id: data.user.referrer ? data.user.referrer._id : "",
      referal_code: data.user.referrer
        ? data.user.referrer.profile.referralCode
        : "",
      created_at: data.user.createdAt,
      authentication: "Obby",
      source: "email"
    });
}

function signOut() {
  authInstance.logout();
  store.set(modelName, getDefaultModel({})[modelName]);
}

function makeSureUserIsSignedIn() {
  return new Promise((resolve, reject) => {
    if (isSignedIn()) return resolve();

    const subscriptions = {};

    function onSignInSuccess() {
      unSubscribeEvents();
      resolve();
    }

    function onEndSignIn() {
      unSubscribeEvents();
      reject();
    }

    function unSubscribeEvents() {
      subscriptions.signInSuccess.unsubscribe();
      subscriptions.endSignIn.unsubscribe();
    }

    subscriptions.signInSuccess = events.signInSuccess.subscribe(
      onSignInSuccess
    );
    subscriptions.endSignIn = events.endSignIn.subscribe(onEndSignIn);
    events.beginSignIn.next();
  });
}

async function addClassToWishList(classId) {
  try {
    await makeSureUserIsSignedIn();
    const model = store.get(modelName);
    await axios.post("AddClassToWishlist", {
      courseId: classId,
      userId: model.user._id
    });
    events.addClassToWishListSuccess.next(classId);
  } catch (e) {
    events.addClassToWishListFaliure.next([e, classId]);
  }
}

function addClassToWishListSuccess(classId) {
  var model = store.get(modelName);
  var newState = {
    user: {
      favourites: model.user.favourites.concat(classId)
    }
  };
  var mergedState = mergeDeepRight(model, newState);
  store.set(modelName, mergedState);
}

function addClassToWishListFaliure() {
  // how we handle wishlist addition faliures
  // should be discussed with the wider product team
}

async function removeClassFromWishList(classId) {
  const model = store.get(modelName);
  try {
    await axios.post("RemoveClassFromWishlist", {
      courseId: classId,
      userId: model.user._id
    });
    events.removeClassFromWishListSuccess.next(classId);
  } catch (e) {
    events.removeClassFromWishListFaliure.next([e, classId]);
  }
}

function removeClassFromWishListSuccess(classId) {
  var model = store.get(modelName);
  var newState = {
    user: {
      favourites: without([classId], model.user.favourites)
    }
  };
  var mergedState = mergeDeepRight(model, newState);
  store.set(modelName, mergedState);
}

function removeClassFromWishListFaliure() {
  // how we handle wishlist removal faliures
  // should be discussed with the wider product team
}

export function getUser() {
  if (!isSignedIn()) return undefined;
  const model = store.get(modelName);
  return model.user;
}

export function getSchool() {
  if (!isSignedIn()) return undefined;
  const model = store.get(modelName);
  return model.school;
}

function isCurrentUserTeacher() {
  if (!isSignedIn()) return undefined;
  const model = store.get(modelName);
  return model.isCurrentUserTeacher;
}

export function isSignedIn() {
  const model = store.get(modelName);
  return model.status === AuthenticationStatuses.SIGNED_IN;
}

export function isFavouriteCourse(courseId) {
  const model = store.get(modelName);
  return isSignedIn() && model.user.favourites.indexOf(courseId) > -1;
}

export function isOnSales() {
  return this.getSales() !== undefined;
}
export function getSales() {
  return store.get("sales");
}

export default {
  events,
  getDefaultModel,
  helpers: {
    isOnSales,
    isSignedIn,
    isFavouriteCourse,
    getSales,
    getUser,
    getSchool,
    isCurrentUserTeacher
  }
};
