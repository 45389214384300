import { css, withBem } from "../../../sitches.config";

export const styles = withBem("date-time-box", {
  dateTimeBox: css({
    marginBottom: "$3",
    "&:last-child": {
      marginBottom: 0
    }
  }),
  wrapper: css({
    display: "flex",
    justifyContent: "space-between",
    padding: "$3 $4",
    color: "$textColor",
    variants: {
      disabled: {
        true: {
          color: "$disabled"
        }
      }
    }
  }),
  label: css({
    color: "inherit",
    fontFamily: "$foundersGroteskCondensed",
    fontSize: "$20",
    textTransform: "uppercase",
    lineHeight: 1
  }),
  children: css({
    borderTop: "solid 1px $gallery"
  })
});
