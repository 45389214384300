import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
// Lib
import { CloudinaryUrl__TransformedUrl } from "@obby/lib";
// Components
import { ImagePlaceholder } from "./ImagePlaceholder";
import { ImagePreview } from "./ImagePreview";
import { Camera } from "../Icons";

import styles from "./image-uploader.module.less";

export function ImageUploader(props) {
  const {
    progress,
    isUploading,
    label,
    error,
    onDrop,
    onDelete = () => {},
    multiple = false
  } = props;

  const image = props.image
    ? CloudinaryUrl__TransformedUrl(props.image, {
        width: 260,
        height: 180,
        crop: "fill"
      })
    : undefined;

  const onDropzoneDrop = useCallback(files => {
    if (files.length === 0) return;
    onDrop(multiple ? files : files[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/jpeg,image/png",
    multiple: multiple,
    onDrop: onDropzoneDrop
  });

  return (
    <div {...getRootProps()} className={styles["image-uploader"]}>
      <input {...getInputProps()} />

      {image && !isUploading && (
        <ImagePreview image={image} onDelete={onDelete} />
      )}

      {(!image || isUploading) && (
        <ImagePlaceholder
          icon={Camera}
          color="camelot"
          label={error || label}
          progress={isUploading ? progress : undefined}
        />
      )}
    </div>
  );
}
