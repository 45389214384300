import React from "react";
import IconBlurb from "../IconBlurb";

import { css } from "../../sitches.config";

const IconBlurbList = ({ iconBlurbProps, direction }) => {
  return (
    <div className={`icon-blurb-list ${styles.iconBlurbList}`}>
      {iconBlurbProps.map(iconBlurbProp => (
        <IconBlurb
          key={iconBlurbProp.text}
          {...iconBlurbProp}
          direction={direction}
        />
      ))}
    </div>
  );
};

export default IconBlurbList;

const styles = {
  iconBlurbList: css({
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridColumnGap: "20px",
    gridRowGap: "38px",

    "@sm": {
      gridTemplateColumns: "repeat(4, 1fr)",
      gridColumnGap: "20px",
      gridRowGap: "0"
    }
  })
};
