import * as Yup from "yup";
import { PersonalDetailsForm } from "../../../forms/PersonalDetailsForm";

export function PersonalDetailsStepSchema() {
  return Yup.object({
    guests: Yup.array()
      .of(PersonalDetailsForm.Schema())
      .required()
      .default([])
  }).required();
}
export type Fields = Yup.InferType<
  ReturnType<typeof PersonalDetailsStepSchema>
>;
