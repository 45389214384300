import React from "react";
import { contains } from "ramda";
import {
  isFullArray,
  isValidTwoPointDecimalNumber,
  callFunctionIfExists
} from "@obby/lib";

import styles from "./label-input.module.less";

const LabelInput = props => (
  <div className={styles["label-input"]}>
    {/* LABEL */}
    {props.label && (
      <div className={styles["label-input__label"]}>{props.label}</div>
    )}

    <div className={styles["label-input__input-wrap"]}>
      {/* IF TYPE: TEXTAREA */}
      {props.type === "textarea" && (
        <textarea
          className={styles["label-input__textarea"]}
          placeholder={props.placeholder}
          name={props.name}
          maxLength={props.max}
          value={props.value}
          onChange={event => props.onChange(event.target.value)}
          onBlur={event =>
            callFunctionIfExists(props.onBlur, [event.target.name])
          }
        />
      )}
      {/* IF TYPE: MONEY */}
      {props.type === "money" && (
        <div className={styles["label-input__icon-input"]}>
          {props.value && (
            <span className={styles["label-input__input-icon"]}>
              {props.moneyCharacter || "£"}
            </span>
          )}
          <input
            className={`
          label-input__input
          label-input__input--${props.value && "icon"}
        `}
            type="number"
            min={0}
            name={props.name}
            placeholder={props.placeholder}
            value={props.value}
            onChange={event => {
              if (
                !isFullArray(event.target.value) ||
                isValidTwoPointDecimalNumber(event.target.value)
              ) {
                props.onChange(event.target.value);
              } else {
                props.onChange(props.value);
              }
            }}
            onBlur={event =>
              callFunctionIfExists(props.onBlur, [event.target.name])
            }
          />
        </div>
      )}
      {/* IF TYPE: LOCKED */}
      {props.type === "locked" && (
        <div className={styles["label-input__locked-text"]}>{props.value}</div>
      )}
      {/* IF TYPE: REGULAR */}
      {!contains(props.type, ["textarea", "money", "locked"]) && (
        <input
          className={styles["label-input__input"]}
          name={props.name}
          type={props.type || "text"}
          step={props.step || "any"}
          min={0}
          placeholder={props.placeholder}
          maxLength={props.max}
          disabled={props.disabled}
          value={props.value}
          onChange={event => props.onChange(event.target.value)}
          onBlur={event =>
            callFunctionIfExists(props.onBlur, [event.target.name])
          }
        />
      )}
    </div>

    {/* CHARACTER COUNT */}
    {props.max && (
      <div className={styles["label-input__character-count"]}>
        {props.max - props.value.length} characters left
      </div>
    )}

    {/* NOTE */}
    {props.note && (
      <div className={styles["label-input__note"]}>{props.note}</div>
    )}

    {/* ERROR */}
    {props.error && (
      <div className={styles["label-input__error"]}>{props.error}</div>
    )}
  </div>
);

export default LabelInput;
