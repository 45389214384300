import React from "react";
import PropTypes from "prop-types";

const EmbededVideoPlayer = ({ url, width, height, className }) => {
	return (
		<iframe
			className={className}
			src={url}
			width={width}
			height={height}
			frameBorder="0"
			allow="autoplay; fullscreen"
			allowFullScreen
		></iframe>
	);
};

EmbededVideoPlayer.defaultProps = {
	width: 640,
	height: 360
};

EmbededVideoPlayer.propTypes = {
	url: PropTypes.string.isRequired,
	width: PropTypes.string,
	height: PropTypes.string,
	className: PropTypes.string
};

export default EmbededVideoPlayer;
