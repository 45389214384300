import { css, withBem } from "../../../sitches.config";

export const styles = withBem("pricing-options-step", {
  pricingOptionsStep: css({}),
  label: css({
    display: "flex",
    gap: "$2",
    justifyContent: "space-between",
    marginTop: "$4",
    marginBottom: "$3",
    "&:first-child": {
      marginTop: 0
    }
    // alignItems: "center"
  }),
  labelChevron: css({
    cursor: "pointer",
    marginTop: "$3",
    transition: "transform 250ms",
    variants: {
      close: {
        true: {
          transform: "rotate(180deg)"
        }
      }
    }
  })
});
