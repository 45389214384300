import { BookingExternalSources } from "./BookingExternalSources";

export const BookingExternalSourcesLabels = {
  [BookingExternalSources.WEBSITE]: "My website",
  [BookingExternalSources.AIRBNB]: "Airbnb",
  [BookingExternalSources.EVENTBRITE]: "Eventbrite",
  [BookingExternalSources.BANK_TRANSFER]: "Bank transfer",
  [BookingExternalSources.CASH]: "Cash/cheque",
  [BookingExternalSources.VOUCHER]: "Third-party voucher website",
  [BookingExternalSources.CRAFT_COURSES]: "Craft courses",
  [BookingExternalSources.OTHER]: "Other",
  [BookingExternalSources.PHONE]: "Phone",
  [BookingExternalSources.EMAIL]: "Email",
  [BookingExternalSources.IN_PERSON]: "In-Person"
};
