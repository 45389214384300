import React, { useState } from "react";
import { Stripe, StripeElements } from "@stripe/stripe-js";

import { StripePaymentContext } from "./StripePayment.context";

interface State {
  clientSecret?: string;
  elements?: StripeElements;
  stripe?: Stripe;
  error?: string;
  isLoading: boolean;
  isSubmitting: boolean;
  successUrl?: string;
}

export function StripePaymentProvider({
  children,
  publicKey,
  onGetPaymentIntent
}: React.PropsWithChildren<Props>) {
  const [state, setState] = useState<State>({
    isLoading: true,
    isSubmitting: false
  });
  const {
    clientSecret,
    successUrl,
    stripe,
    elements,
    error,
    isLoading,
    isSubmitting
  } = state;

  async function getPaymentIntent(data: any) {
    try {
      if (!onGetPaymentIntent)
        throw "onGetPaymentIntent prop not defined on StripePaymentProvider";

      const { clientSecret, successUrl } = await onGetPaymentIntent(data);
      setPaymentIntent(clientSecret, successUrl);
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
        error:
          typeof error === "string"
            ? error
            : (error as any).message ?? "An unexpected error occurred."
      });
    }
  }

  function setIsLoading(isLoading: boolean) {
    setState({
      ...state,
      isLoading
    });
  }

  function setPaymentIntent(clientSecret?: string, successUrl?: string) {
    setState({
      ...state,
      clientSecret,
      successUrl
    });
  }

  function setStripeElements(stripe: Stripe, elements: StripeElements) {
    setState({
      ...state,
      stripe,
      elements
    });
  }

  async function submitPayment() {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setState({
      ...state,
      error: undefined,
      isSubmitting: true
    });

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: successUrl!
      }
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`.
    setState({
      ...state,
      error:
        error && error.type !== "validation_error"
          ? error.message || "An unexpected error occurred."
          : undefined,
      isSubmitting: false
    });
  }

  return (
    <StripePaymentContext.Provider
      value={{
        clientSecret,
        error,
        isLoading,
        isSubmitting,
        publicKey,
        getPaymentIntent,
        setIsLoading,
        setPaymentIntent,
        setStripeElements,
        submitPayment
      }}
    >
      {children}
    </StripePaymentContext.Provider>
  );
}

interface Props {
  publicKey: string;
  onGetPaymentIntent?: (
    data: any
  ) => Promise<{ clientSecret: string; successUrl: string }>;
}
