import React, { useContext } from "react";
import { AppTextContext } from "./AppTextContext";

export function AppText({ children, content }: React.PropsWithChildren<Props>) {
  const getAncestorContent = useContext(AppTextContext);

  function getContent(key: string) {
    return content[key] ?? getAncestorContent(key);
  }

  return (
    <AppTextContext.Provider value={getContent}>
      {children}
    </AppTextContext.Provider>
  );
}

interface Props {
  content: { [key: string]: string };
}
