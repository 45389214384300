import React, { useState, useEffect } from "react";

import { TextInputButton } from "../../components/TextInputButton";
import { Label } from "../../components/Label";
import { CheckoutCardItem } from "./CheckoutCardItem";

import { styles } from "./CheckoutDiscount.styles";

export function CheckoutDiscount({
  currency,
  error,
  isInvalid = false,
  onApply,
  percentage,
  value
}: Props) {
  // const { onApply, error, isInvalid, value, percentage } = props;
  const [showDiscountField, setShowDiscountField] = useState(false);

  useEffect(() => {
    if (value) setShowDiscountField(false);
  }, [value]);

  return (
    <CheckoutCardItem
      title={
        onApply && (
          <span
            className={styles.button()}
            onClick={() => setShowDiscountField(!showDiscountField)}
          >
            Add Promo/Voucher Code
          </span>
        )
      }
      price={value ? -value : undefined}
      percentage={percentage}
      currency={currency}
    >
      {showDiscountField && (
        <div className="pt-2">
          <Label error={error}>
            <TextInputButton
              placeholder="Code"
              onButtonClick={onApply}
              {...(isInvalid
                ? {
                    buttonBackgroundColor: "red",
                    accent: "red",
                    buttonText: "Invalid",
                    buttonTextColor: "white"
                  }
                : {})}
            />
          </Label>
        </div>
      )}
    </CheckoutCardItem>
  );
}

interface Props {
  currency?: string;
  error?: string;
  isInvalid?: boolean;
  percentage?: number;
  onApply?: (code: string) => void;
  value?: number;
}
