import React from "react";
import { styles } from "./Flex.styles";
import { Property } from "csstype";

export function Flex({
  alignItems,
  alignSelf,
  children,
  className,
  column,
  direction = column ? "column" : undefined,
  gap,
  grow,
  columnGap = gap,
  inline,
  item = false,
  justifyContent,
  justifySelf,
  rowGap = gap,
  wrap,
  ...props
}: React.PropsWithChildren<Props>) {
  return (
    <div
      className={styles.flex({
        css: {
          "--flex-align-items": alignItems,
          "--flex-align-self": alignSelf,
          "--flex-column-gap": columnGap ? `$space$${columnGap}` : "initial",
          "--flex-direction": direction,
          "--flex-grow": grow,
          "--flex-justify-content": justifyContent,
          "--flex-justify-self": justifySelf,
          "--flex-row-gap": rowGap ? `$space$${rowGap}` : "initial",
          "--flex-wrap": wrap
        },

        item,
        inline,
        className
      })}
      {...props}
    >
      {children}
    </div>
  );
}

interface Props
  extends Omit<
    React.HTMLProps<HTMLDivElement>,
    "wrap" | "dangerouslySetInnerHTML"
  > {
  alignItems?: Property.AlignItems;
  alignSelf?: Property.AlignSelf;
  column?: boolean;
  columnGap?: 0 | 1 | 2 | 3 | 4;
  direction?: Property.FlexDirection;
  gap?: 0 | 1 | 2 | 3 | 4;
  grow?: Property.FlexGrow;
  inline?: boolean;
  item?: boolean;
  justifyContent?: Property.JustifyContent;
  justifySelf?: Property.JustifySelf;
  rowGap?: 0 | 1 | 2 | 3 | 4;
  wrap?: Property.FlexWrap;
}
