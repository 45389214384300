import React from "react";

import { BundleCheckoutContainer } from "./BundleCheckout.container";
import { BundleCheckoutView } from "./BundleCheckout.view";
import { StripePaymentProvider } from "../StripePayment";
import { Timezones } from "@obby/constants";

export function BundleCheckout({
  course,
  bundle,
  cancellationPolicyUrl = "/terms",
  courseId,
  courseThumbnail,
  courseTitle,
  discount,
  tutors,
  ticketType,
  locations,
  isNewsletterSignupEnabled = true,
  isSendingMaterial,
  logoUrl,
  onClose,
  onConfirmFreeBooking,
  onGetPaymentIntent,
  onLoadDates,
  onLogin,
  onStepChange,
  onValidateDiscount,
  sessions,
  stripePublicKey,
  timezone = Timezones.EUROPE_LONDON,
  user,
  userInputs,
  currency = "GBP"
}) {
  return (
    <StripePaymentProvider
      publicKey={stripePublicKey}
      onGetPaymentIntent={onGetPaymentIntent}
    >
      <BundleCheckoutContainer.Provider
        initialState={{
          course,
          bundle,
          cancellationPolicyUrl,
          courseId,
          courseThumbnail,
          courseTitle,
          currency,
          discount,
          tutors,
          ticketType,
          locations,
          isNewsletterSignupEnabled,
          isSendingMaterial,
          logoUrl,
          onClose,
          onConfirmFreeBooking,
          onLoadDates,
          onLogin,
          onStepChange,
          onValidateDiscount,
          sessions,
          timezone,
          user,
          userInputs
        }}
      >
        <BundleCheckoutView />
      </BundleCheckoutContainer.Provider>
    </StripePaymentProvider>
  );
}
