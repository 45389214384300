import React from "react";
import Link from "../Link";
import RadioButtons from "../RadioButtons";
import styles from "./class-format-body.module.less";
import { CClassFormat } from "@obby/lib";

export default function LearnFormatBody({
  selectedClassFormat = "",
  onChange,
  color = "robbinsEggBlue"
}) {
  return (
    <div className={styles["class-format"]}>
      <div className={styles["class-format__wrapper"]}>
        <div className={styles["class-format__radio"]}>
          <RadioButtons
            value={selectedClassFormat}
            color={color}
            options={[
              {
                label: "All",
                value: CClassFormat.ALL
              },
              {
                label: "In Person",
                value: CClassFormat.IN_PERSON
              },
              {
                label: "At Home",
                value: "atHome"
              },
              {
                label: "Live Online",
                value: CClassFormat.ONLINE
              },
              {
                label: "Learning Kits",
                value: CClassFormat.KITS
              }
            ]}
            onChange={onChange}
          />
        </div>
        <Link
          className={`${styles["class-format__link"]} ${
            styles[`class-format__link--${color}`]
          }`}
          href="https://obby.helpscoutdocs.com/article/109-in-person-vs-live-online-classes"
          target="_blank"
        >
          Learn more about online classes
        </Link>
      </div>
    </div>
  );
}
