import { store } from "statorgfc";
import { Subject } from "rxjs";

import axios from "./../config/axios";
import ErrorState from "./ErrorState";
import AuthenticationState from "./AuthenticationState";

import auth from "../config/auth";
const modelName = "userPassword";

const getDefaultModel = initialData => {
  return {
    userPassword: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      changed: false
    }
  };
};

const events = {
  componentDidMount: new Subject(),
  componentWillUnmount: new Subject(),
  currentPasswordChange: new Subject(),
  newPasswordChange: new Subject(),
  confirmPasswordChange: new Subject(),
  passwordChanged: new Subject(),
  submitChanges: new Subject()
};

events.componentDidMount.subscribe(onComponentDidMount);
events.componentWillUnmount.subscribe(onComponentWillUnMount);

const subscriptions = {};

function onComponentDidMount() {
  subscriptions.onCurrentPasswordChange = events.currentPasswordChange.subscribe(
    onCurrentPasswordChange
  );
  subscriptions.onNewPasswordChange = events.newPasswordChange.subscribe(
    onNewPasswordChange
  );
  subscriptions.onCurrentPasswordChange = events.confirmPasswordChange.subscribe(
    onConfirmPasswordChange
  );
  subscriptions.onSubmitChanges = events.submitChanges.subscribe(
    onSubmitChanges
  );
  subscriptions.onPasswordChanged = events.passwordChanged.subscribe(
    onPasswordChanged
  );
}

function onComponentWillUnMount() {
  subscriptions.onCurrentPasswordChange.unsubscribe();
  subscriptions.onNewPasswordChange.unsubscribe();
  subscriptions.onCurrentPasswordChange.unsubscribe();
  subscriptions.onSubmitChanges.unsubscribe();
  subscriptions.onPasswordChanged.unsubscribe();
}

function onCurrentPasswordChange(password) {
  const model = store.get(modelName);
  store.set(modelName, { ...model, currentPassword: password });
}

function onNewPasswordChange(password) {
  const model = store.get(modelName);
  store.set(modelName, { ...model, newPassword: password });
}

function onConfirmPasswordChange(password) {
  const model = store.get(modelName);
  store.set(modelName, { ...model, confirmPassword: password });
}

function onPasswordChanged(user) {
  AuthenticationState.events.userUpdate.next(user);
  const model = store.get(modelName);
  model.currentPassword = model.newPassword = model.confirmPassword = "";
  store.set(modelName, { ...model, changed: true });
  window.setTimeout(() => {
    store.set(modelName, { ...model, changed: false });
  }, 3000);
}

async function onSubmitChanges() {
  const model = store.get(modelName);
  const { currentPassword, newPassword, confirmPassword } = model;

  try {
    if (newPassword !== confirmPassword)
      throw new Error("Passwords do not match.");

    const user = await auth.changePassword(currentPassword, newPassword);
    events.passwordChanged.next(user);
  } catch (e) {
    ErrorState.events.userPassword.next(e.message);
  }
}

export default {
  events,
  getDefaultModel
};
