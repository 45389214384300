import moment from "moment-timezone";
import { Timezones } from "@obby/constants";

export function UTCDate__IsSameOrAfter(
  isoDate: string,
  afterDate?: string,
  granularity?: Granularity,
  // timezone is only useful if granularity is specified.
  // e.g 2022-05-31T23:00:00.000Z and 2022-06-01T00:00:00.000Z happens in the same day in Amsterdam timezone
  timezone: string = Timezones.EUROPE_LONDON
) {
  return moment(isoDate)
    .tz(timezone)
    .isSameOrAfter(afterDate, granularity);
}

type Granularity =
  | "year"
  | "years"
  | "y"
  | "month"
  | "months"
  | "M"
  | "week"
  | "weeks"
  | "w"
  | "day"
  | "days"
  | "d"
  | "hour"
  | "hours"
  | "h"
  | "minute"
  | "minutes"
  | "m"
  | "second"
  | "seconds"
  | "s"
  | "millisecond"
  | "milliseconds"
  | "ms";
