import { css } from "../../sitches.config";

export const styles = {
  component: css({
    padding: "73px 0 36%",
    backgroundColor: "$hummingBird",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom",
    backgroundSize: "calc(100% - 30px)",
    backgroundImage:
      "url(https://res.cloudinary.com/obby/image/upload/v1635333029/Page%20Images/background-mobile.png)",
    "@sm": {
      backgroundSize: "cover",
      backgroundPosition: "center",
      padding: "111px 0 40px"
    },
    "@md": {
      paddingTop: "128px"
    },
    "@lg": {
      backgroundSize: "cover",
      paddingBottom: "78px"
    }
  }),

  wrapper: css({
    paddingTop: "10px",
    display: "flex",
    justifyContent: "space-evenly",
    flexDirection: "column",
    height: "100%",
    "@lg": {
      paddingTop: "40px"
    }
  }),

  heading: css({
    fontFamily: "$foundersGroteskCondensed",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "$24",
    lineHeight: "29px",
    textAlign: "center",
    color: "$ebonyClay",
    textTransform: "uppercase",
    margin: "0 0 10px",
    "@sm": {
      fontSize: "$36",
      lineHeight: 1.2
    },
    "@lg": {
      fontSize: "$46"
    }
  }),

  subheading: css({
    fontFamily: "$foundersGrotesk",
    fontStyle: "normal",
    fontSize: "$20",
    lineHeight: "24px",
    fontWeight: 400,
    textAlign: "center",
    color: "$ebonyClay",
    margin: "$4 auto",
    maxWidth: "834px",
    "@sm": {
      fontWeight: "bold",
      fontSize: "$24",
      lineHeight: 1.2,
      margin: "0 auto"
    },
    "@lg": {
      fontSize: "$36",
      maxWidth: "1024px"
    }
  }),

  searchInput: css({
    textAlign: "center",
    position: "relative",
    backgroundColor: "$white",
    fontFamily: "$foundersGrotesk",
    borderRadius: "$3",
    paddingBlock: "12px",
    marginTop: "15px",
    width: "100%",
    maxWidth: "600px",
    alignSelf: "center",
    "@lg": {
      marginTop: "30px"
    }
  }),

  inputFilter: css({
    maxWidth: "400px",
    margin: "0 auto",
    position: "relative",
    "@sm": {
      maxWidth: "325px"
    },
    ".async-select-box .react-select": {
      "&__control, &__menu": {
        borderStyle: "none"
      }
    }
  }),

  tagFilters: css({
    listStyle: "none",
    display: "flex",
    paddingLeft: 0,
    justifyContent: "space-evenly"
  }),
  tagFilter: css({
    fontSize: "$18",
    marginTop: "12px",
    position: "relative",
    cursor: "pointer",
    "&::after": {
      content: " ",
      height: "2px",
      width: "0%",
      bottom: "-2px",
      left: 0,
      position: "absolute",
      backgroundColor: "$sun",
      "@sm": {
        backgroundColor: "$emeraldish"
      }
    },
    variants: {
      active: {
        true: {
          "&:after": {
            transition: "width .3s ease",
            width: "100%"
          }
        }
      }
    }
  })
};
