import { css, withBem } from "../../sitches.config";

export const styles = withBem("color-picker", {
  colorPicker: css({
    display: "flex",
    alignItems: "stretch",
    position: "relative"
  }),
  preview: css({
    // height: "100%",
    flex: "0 0",
    borderRadius: "0 $input $input 0",
    border: "solid 1px $inputBorderColor",
    borderLeftWidth: "0",
    variants: {
      size: {
        small: {
          flexBasis: "$inputSmallHeight"
        },
        medium: {
          flexBasis: "$inputMediumHeight"
        }
      }
    }
  }),
  popover: css({
    position: "absolute",
    top: "100%",
    left: 0,
    marginTop: "2px",
    zIndex: "$popout",
    ".react-colorful": {
      "&__saturation": {
        borderRadius: "$input $input 0 0"
      },
      "&__last-control": {
        borderRadius: "0 0 $input $input"
      }
    }
    // box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  })
});
