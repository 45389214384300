import { css } from "../../sitches.config";

export const styles = {
  iconLabel: css({
    display: "inline-flex",
    alignItems: "center",
    marginRight: "$3",

    "&:last-child": {
      marginRight: 0
    }
  }),
  iconWrapper: css({
    marginRight: "$2",
    marginTop: "3px",
    "@lg": {
      marginTop: 0
    }
  }),
  icon: css({
    display: "block",
    '[fill^="#"]': {
      fill: "currentColor"
    },
    '[stroke^="#"]': {
      stroke: "currentColor"
    }
  }),

  label: css({
    fontFamily: "$foundersGrotesk",
    fontSize: 18,
    fontWeight: "medium",
    lineHeight: 1.5,
    color: "$ebonyClay",
    margin: 0,
    cursor: "inherit",
    a: {
      color: "$emeraldish",
      fontWeight: 500,
      textDecoration: "none !important",
      "&:hover, &:active, &:visited, &:focus ": {
        textDecoration: "none !important"
      },
      "$::before": {
        content: "a",
        whiteSpace: "pre",
        "@sm": {
          display: "none"
        }
      }
    }
  })
};
