import React from "react";

import Label from "../../components/Label";
import TextArea from "../../components/TextArea";
import { TextInput } from "../../components/TextInput";

import { ContactFormSchema } from "./ContactForm.schema";

export function ContactForm(props) {
  const { values, errors, touched, onChange, onBlur } = props;

  return (
    <div className="contact-form">
      <Label
        label="What can we help you with?"
        error={touched?.message && errors.message}
      >
        <TextArea
          name="message"
          value={values.message}
          onChange={onChange}
          onBlur={onBlur}
        />
      </Label>
      <Label label="Email" error={touched?.email && errors.email}>
        <TextInput
          name="email"
          value={values.email}
          onChange={onChange}
          onBlur={onBlur}
        />
      </Label>
    </div>
  );
}
ContactForm.Schema = ContactFormSchema;
