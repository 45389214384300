import * as Yup from "yup";
import { CheckoutPersonalDetailsForm } from "../../forms/CheckoutPersonalDetailsForm";
import { GiftForm } from "../../forms/GiftForm";

export function ProductCheckoutSchema(isSendingMaterial, defaults = {}) {
  defaults = {
    quantity: null,
    postageId: undefined,
    inputs: [],
    personalDetails: [],
    isGift: false,
    discountCode: "",
    ...defaults
  };
  return Yup.object({
    quantity: Yup.number()
      .label("Quantity")
      .min(1)
      .required()
      .nullable(true)
      .default(defaults.quantity),
    postageId: Yup.string()
      .required()
      .default(defaults.postageId),
    inputs: Yup.array()
      .of(
        Yup.string()
          .required()
          .default("")
      )
      .default(defaults.inputs),
    isGift: Yup.boolean().default(defaults.isGift),
    giftingDetails: GiftForm.Schema(),
    personalDetails: CheckoutPersonalDetailsForm.Schema(isSendingMaterial),
    discountCode: Yup.string()
      .when("personalDetails.guests.0.email", (email, schema) => {
        if (
          !Yup.string()
            .email()
            .required()
            .isValidSync(email)
        )
          return schema.test(
            "email-address-for-discount",
            "Fill in email address first in Personal details step",
            () => false
          );

        return schema;
      })
      .default(defaults.discountCode)
  });
}
