import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";

// Components
import Rail from "../Rail";
// Contexts
import * as TabsContext from "./TabsContext";

import styles from "./Tabs.module.less";

export function Tabs(props) {
  const {
    active,
    children,
    accentColor = "emeraldish",
    notificationColor = "coral",
    onTabActive = () => {}
  } = props;

  const [activeTab, setActiveTab] = useState(active);

  useEffect(() => {
    if (active) setActiveTab(active);
  }, [active]);

  function onTabClick(tabId) {
    if (tabId) setActiveTab(tabId);
    onTabActive(tabId);
  }

  function isActive(tabId, index) {
    return (
      (activeTab === undefined && index === 0) ||
      (tabId !== undefined && tabId === activeTab)
    );
  }

  return (
    <div className={styles["tabs"]}>
      <Rail className={styles["tabs__rail"]}>
        <nav className={styles["tabs__nav"]}>
          {React.Children.map(children, (tab, index) => {
            const { props } = tab;
            return (
              <TabsContext.Provider
                key={index}
                value={{
                  accentColor,
                  notificationColor,
                  onClick: () => {
                    if (props.children) onTabClick(props.id);
                  },
                  isActive: isActive(props.id, index)
                }}
              >
                {tab}
              </TabsContext.Provider>
            );
          })}
        </nav>
      </Rail>
      <div className={styles["tabs__content"]}>
        {React.Children.map(children, (tab, index) => {
          const { props } = tab;
          return isActive(props.id, index) ? props.children : <></>;
        })}
      </div>
    </div>
  );
}

Tabs.defaultProps = {
  tabsRailWidth: "100%"
};

Tabs.propTypes = {
  tabsRailWidth: PropTypes.string,
  activeTabColor: PropTypes.string,
  children: PropTypes.any,
  active: PropTypes.any,
  onTabActive: PropTypes.func
};
