import React from "react";
import SecondaryButton from "../SecondaryButton";
import { PrimaryButton } from "../PrimaryButton";
import GoogleSignInButton from "../GoogleSignInButton";
import FacebookSignInButton from "../FacebookSignInButton";
import styles from "./SignUpForm.module.less";

export default class SignUpForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={styles["sign-up-form"]}>
        <div className={styles["sign-up-form__body"]}>
          <div className={styles["sign-up-form__title"]}>
            Book faster by signing up
          </div>

          {/* SOCIAL SIGN IN */}
          <div className={styles["sign-up-form__social-buttons"]}>
            {this.props.isCheckout && (
              <div className={styles["sign-up-form__social-button"]}>
                <SecondaryButton
                  text="Checkout as a guest"
                  onClick={this.props.onCheckoutAsGuest}
                />
              </div>
            )}
            <div className={styles["sign-up-form__social-button"]}>
              <FacebookSignInButton
                appId={this.props.facebookAppId}
                text="Sign up with Facebook"
                onLoginSuccess={this.props.onFacebookSignInSuccess}
                onLoginFailure={this.props.onFacebookSignInFailure}
              />
            </div>
            <div className={styles["sign-up-form__social-button"]}>
              <GoogleSignInButton
                appId={this.props.googleAppId}
                text="Sign up with Google"
                onLoginSuccess={this.props.onGoogleSignInSuccess}
                onLoginFailure={this.props.onGoogleSignInFailure}
              />
            </div>
            {this.props.error && (
              <div className={styles["sign-up-form__error"]}>
                {this.props.error}
              </div>
            )}

            <div className={styles["sign-up-form__divider"]}>or</div>

            <div className={styles["sign-up-form__social-button"]}>
              <PrimaryButton
                text="Sign up with email"
                onClick={this.props.onBeginSignUp}
              />
            </div>
          </div>

          <div className={styles["sign-up-form__label"]}>
            By signing up, you agree to our
            <a
              href={this.props.termsLink}
              target="_blank"
              className={styles["sign-up-form__link"]}
            >
              terms of service
            </a>
            and
            <a
              href={this.props.privacyPolicyLink}
              target="_blank"
              className={styles["sign-up-form__link"]}
            >
              privacy policy.
            </a>
          </div>
        </div>

        {/* FOOTER */}
        <div className={styles["sign-up-form__footer"]}>
          <span className={styles["sign-up-form__footer-text"]}>
            Already have an Obby account?
          </span>
          <span className={styles["sign-up-form__footer-button"]}>
            <SecondaryButton
              text="Login"
              size="small"
              onClick={this.props.onBeginSignIn}
            />
          </span>
        </div>
      </div>
    );
  }
}
