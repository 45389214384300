import React, { useState, useEffect } from "react";
export const CheckoutStepsContext = React.createContext<Value>({
  currentStep: 0,
  isFirstStep: () => true,
  isLastStep: () => true,
  lastIndex: 0,
  onGoToStep: () => {},
  onNext: () => {},
  onPrevious: () => {},
  onStepChange: () => {},
  steps: []
});

interface Value {
  currentStep: number;
  isFirstStep: () => boolean;
  isLastStep: () => boolean;
  lastIndex: number;
  onGoToStep: (index: number) => void;
  onNext: () => void;
  onPrevious: () => void;
  onStepChange: (step: Step, index: number) => void;
  steps: Step[];
}

interface Step {
  breadcrumb: string;
  disabled: boolean;
  hidden: boolean;
  index: number;
  nextButtonText?: string;
  pass: boolean;
}

export function Provider({
  onContinue,
  ...props
}: React.PropsWithChildren<Props>) {
  const [state, setState] = useState<{ steps: Step[]; currentStep: number }>({
    steps: [],
    currentStep: 0
  });
  const { currentStep, steps } = state;

  useEffect(() => {
    setState(state => {
      const { steps } = state;
      const currentStep = steps.findIndex(step => !step.pass && !step.hidden);
      return {
        ...state,
        currentStep
      };
    });
  }, []);

  function isFirstStep() {
    return steps.findIndex(step => !step.hidden) === currentStep;
  }

  function isLastStep() {
    let lastIndex = steps.length - 1;
    // decreases the last index if there are hidden steps at the end;
    lastIndex -= [...steps].reverse().findIndex(step => !step.hidden);
    return lastIndex === currentStep;
  }

  async function onNext() {
    if (currentStep < steps.length - 1) {
      let currentStep = state.currentStep + 1;
      // skip hidden steps
      currentStep += steps.slice(currentStep).findIndex(step => !step.hidden);
      try {
        props.onBeforeNext && (await props.onBeforeNext(currentStep));
        setState(state => ({
          ...state,
          currentStep
        }));
        props.onNext && props.onNext(currentStep);
      } catch (e) {}
    }
    if (isLastStep()) onContinue();
  }

  function onPrevious() {
    if (currentStep > 0) {
      let currentStep = state.currentStep - 1;
      // skip hidden steps
      currentStep -= steps
        .slice(0, currentStep + 1)
        .reverse()
        .findIndex(step => !step.hidden);

      setState(state => ({
        ...state,
        currentStep
      }));

      props.onPrevious && props.onPrevious(currentStep);
    }
  }

  function onGoToStep(index: number) {
    setState(state => ({
      ...state,
      currentStep: index
    }));
  }

  function onStepChange(step: Step, index: number) {
    setState(state => {
      const steps = [...state.steps];

      steps[index] = {
        ...steps[index],
        ...step
      };
      return { ...state, steps };
    });
  }

  return (
    <CheckoutStepsContext.Provider
      value={{
        currentStep,
        lastIndex:
          steps.length -
          [...steps].reverse().findIndex(step => !step.hidden) -
          1,
        isFirstStep,
        isLastStep,
        onGoToStep,
        onNext,
        onPrevious,
        onStepChange,
        steps
      }}
    >
      {props.children}
    </CheckoutStepsContext.Provider>
  );
}

interface Props {
  onBeforeNext?: (currentStep: number) => Promise<void>;
  onContinue: () => void;
  onNext?: (currentStep: number) => void;
  onPrevious?: (currentStep: number) => void;
}
