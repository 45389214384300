import React from "react";
import styles from "./image-blurb.module.less";

import CardBackground from "../CardBackground";
import SecondaryButton from "../SecondaryButton";

export default function ImageBlurb(props) {
  const {
    title,
    description,
    color,
    thumbnail,
    thumbnailPosition,
    thumbnailCols,
    backgroundColor,
    cta,
    onCtaClick
  } = props;

  return (
    <div className={styles["image-blurb"]}>
      <CardBackground
        thumbnail={thumbnail}
        thumbnailPosition={thumbnailPosition}
        thumbnailCols={thumbnailCols}
        color={backgroundColor}
      >
        <div
          className={`${styles["image-blurb__content"]} ${
            color ? styles[`image-blurb__content--color-${color}`] : ""
          }`}
        >
          <h2 className={styles["image-blurb__title"]}>{title}</h2>
          <div className={styles["image-blurb__description"]}>
            {description}
          </div>
          {cta && (
            <SecondaryButton
              className={`${styles["image-blurb__button"]} mt-4`}
              width="auto"
              size="small"
              text={cta}
              onClick={onCtaClick}
              color={color}
            />
          )}
        </div>
      </CardBackground>
    </div>
  );
}
