import UniversalCookie from "universal-cookie";

class Cookies {
  constructor() {
    this.cookies = new UniversalCookie();
  }

  addChangeListener(callback) {
    this.cookies.addChangeListener(callback);
  }

  get(name, options) {
    return this.cookies.get(name, options);
  }

  getAll(options) {
    return this.cookies.getAll(options);
  }

  load(cookies) {
    this.cookies = new UniversalCookie(cookies);
  }

  remove(name, options = {}) {
    this.cookies.remove(name, { path: "/", ...options });
  }

  removeChangeListener(callback) {
    this.cookies.removeChangeListener(callback);
  }

  set(name, value, options) {
    this.cookies.set(name, value, options);
  }
}

export default new Cookies();
