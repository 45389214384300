import moment from "moment";

import Booking__StartDateTime from "./Booking__StartDateTime";
import Booking__Duration from "./Booking__Duration";

const Booking_EndDateTime = booking => {
	const startDate = Booking__StartDateTime(booking);
	return moment(startDate)
		.add(Booking__Duration(booking), "minutes")
		.toISOString();
};
export default Booking_EndDateTime;
