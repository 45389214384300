import React from "react";
import { CheckoutSteps } from "../../navs/CheckoutSteps";
import { styles } from "./BundleDatesSelectionFooter.styles";
/** @ts-ignore */
import { UTCDate__FormattedDate } from "@obby/lib";

export function BundleDatesSelectionFooter({
  selectedSessions,
  timezone
}: Props) {
  return (
    <footer className={styles.footer({ className: "py-3" })}>
      <div className="container-fluid">
        <div className={styles.wrapper()}>
          <div className={styles.datesWrapper()}>
            Dates Selected:
            {selectedSessions.length > 0 && (
              <span className={styles.dates()}>
                {selectedSessions
                  .map(session =>
                    UTCDate__FormattedDate(
                      session.startDateTime,
                      timezone,
                      "dddd Do MMMM"
                    )
                  )
                  .join(", ")}
              </span>
            )}
          </div>
          <CheckoutSteps.Continue />
        </div>
      </div>
    </footer>
  );
}

interface Props {
  selectedSessions: any[];
  timezone?: string;
}
