import { css } from "../../sitches.config";

export const styles = {
  component: css({
    "& .rti": {
      "&--container": {
        "--rti-border": "$colors$inputBorderColor",
        "--rti-main": "$colors$inputBorderColor",
        "--rti-radius": "$radii$input",
        "--rti-s": "$space$8",
        "--rti-tag": "$colors$mercury",
        "--rti-tag-padding": "0 $space$2",
        "--rti-tag-remove": "$ebonyClay",
        fontFamily: "$foundersGrotesk",
        fontSize: "$input",
        padding: "0 $inputPaddingX",
        transition: "all 250ms",
        "&:focus-within": {
          borderColor: "var(--rti-main)",
          boxShadow: "none"
        }
      },
      "&--input": {
        flex: "1 1",
        fontFamily: "$foundersGrotesk"
      },
      "&--tag": {
        color: "$textColor",
        gap: "$2",
        lineHeight: 1,
        button: {
          padding: 0
        }
      }
    },
    variants: {
      size: {
        small: {
          "& .rti": {
            "&--container": {
              minHeight: "$inputSmallHeight"
            },
            "&--tag": {
              height: "23px",
              span: {
                paddingBottom: "3px"
              }
            }
          }
        },
        medium: {
          "& .rti": {
            "&--container": {
              paddingY: "$2",
              minHeight: "$inputMediumHeight"
            },
            "&--tag": {
              height: "27px",
              span: {
                paddingBottom: "3px"
              }
            }
          }
        }
      }
    }
  })
};
