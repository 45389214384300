import React from "react";
import { RisingPopupDialog } from "./RisingPopupDialog";
import { RisingPopupProvider } from "./RisingPopupContext";
import { RisingPopupOverlay } from "./RisingPopupOverlay";

export function RisingPopup(props) {
  const { title, children, onClose = () => {}, className = "" } = props;

  return (
    <RisingPopupProvider>
      <RisingPopupOverlay title={title} onClose={onClose} className={className}>
        {children}
      </RisingPopupOverlay>
    </RisingPopupProvider>
  );
}
RisingPopup.Dialog = RisingPopupDialog;
